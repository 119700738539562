import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import JournalPrivacyCard from './JournalPrivacyCard';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import privacyService from '../../../../../../services/Public/Renewal/LSI/privacy.service';
import Swal from 'sweetalert2';
import renewalService from '../../../../../../services/Public/Renewal/LSI/lsiRenewal.service';
import Information from '../../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/LsiInformation';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../../Shared/Card/Issues';

function Privacy() {
    const [sectionData, setSectionData] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const routes = useSelector(x => x.currentRoutes);
    const renewalId = useSelector(x => x.currentApplicationId);
    const { push } = useHistory();
    const sectionId = 6;
    const entityId = useSelector(x => x.userInfo.EntityId)

    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        emailPhonePrivacy: BooleanType().isRequired('This field is required.'),
        receiveMethod: StringType().isRequired('This field is required.')
    });

    const [formData, updateFormData] = useState({
        id: 0,
        entityId: entityId,
        emailPhonePrivacy: null,
        journalEmail: null,
        journalMail: null,
        journalDoNotSend: null,
        userCrte: "",
        dateCrte: new Date(),
        deleted: false,
        receiveMethod: ""
    });

    useEffect(() => {
        renewalService.lsiGetSection(renewalId, sectionId).then((data) => {
            setSectionData(data.data)
            privacyService.getPrivacyData().then((response) => {
                if (response.data) {
                    updateFormData({
                        ...response.data,
                        receiveMethod: response.data.journalEmail
                            ? "email"
                            : response.data.journalMail
                                ? "mail"
                                : response.data.journalDoNotSend
                                    ? "none"
                                    : null
                    })
                }
                setHasLoaded(true);
            })
        })
    }, [])

    const handleSubmit = (val, event) => {

        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        if (val) {
            submitData(true);
        }
    }
    const handleSave = () => {
        submitData();
    }
    const submitData = (isForSubmit) => {
        var dataToTransmit = {
            ...formData,
            journalEmail: formData.receiveMethod === "email" ? true : null,
            journalMail: formData.receiveMethod === "mail" ? true : null,
            journalDoNotSend: formData.receiveMethod === "none" ? true : null,
        }

        privacyService.postPrivacyData(dataToTransmit).then((response) => {
            if (response.data.id) {
                if (isForSubmit)
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                else
                    Swal.fire("Saved!");
            }
        })
    }
    return (
        <div className="lsiRenewalPrivacy">
            <Container fluid>
                <strong>Land Surveyor Intern Renewal</strong>
                <h4 className="mb-3">Journal & Privacy Option</h4>
                <Information content={GetRenewalInformationContent("privacy")} />
                <br />
                { hasLoaded ?
                    <Form model={model} readOnly={!sectionData.isUserEditable} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                        <JournalPrivacyCard />
                        <br />
                        <Issues isUserEditable={true} type={3} sectionId={ sectionData.id}/>
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} handleSave={handleSave} backToSectionLink="/individual/lsiRenewal/sections" />
                    </Form> : <LoadingSpinner/>
                }
            </Container>
        </div>
    )
}
export default Privacy;