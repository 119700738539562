import axios from "axios";
import authHeader from '../../../authheader'

const INTERNALREINSTATEMENT = process.env.REACT_APP_APIURL + "/api/IndividualReinstatement";

const getIndividualReinstatement = async (reinstatementId) => {
    return axios.get(`${INTERNALREINSTATEMENT}/reinstatement`, { headers: authHeader(), params: { reinstatementId } });;
};

const peGetSection = (reinstatementId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPeReinstatement/section`, { headers: authHeader(), params: { reinstatementId, sectionId } });
}
const pePostSection = (reinstatementId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeReinstatement/signsection`, null, { headers: authHeader(), params: { reinstatementId, sectionId, electronicSignature } });
}
const pePostSectionHasRef = (reinstatementId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeReinstatement/setsectionhasreferences`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasReferences } });
}
const pePostSectionHasCpd = (reinstatementId, sectionId, hasCpd) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeReinstatement/setsectionhascpd`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasCpd } });
}
const pePostSectionHasExperience = (reinstatementId, sectionId, hasMyNceesExperience) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeReinstatement/setsectionhasmynceesexperience`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasMyNceesExperience } });
}
const peMarkSectionComplete = (reinstatementId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeReinstatement/marksectioncomplete`, null, { headers: authHeader(), params: { reinstatementId, sectionId, complete } })
}
const postStaffApproval = (reinstatementId, isApproved) => {
    return axios.post(`${INTERNALREINSTATEMENT}/staffApproval`, null, { headers: authHeader(), params: { reinstatementId, isApproved } });
}
const postReinstatementPdf = (reinstatementId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachReinstatementPdf`, null, { headers: authHeader(), params: { reinstatementId, isApproved } });
}
export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Ensure your contact information, including your mailing and public addresses are up to date.  Ensure you put LAPELS emails on your safe sender list.",
    },
    {
        id: 15,
        sectionTitle: "Building Design ",
        sectionContent: "Complete this section verifing your building design experience.",
    },
    {
        id: 2,
        sectionTitle: "Employment",
        sectionContent: "Verify Louisiana Employment Firm licensure",
    },
    {
        id: 3,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 4,
        sectionTitle: "Quizzes",
        sectionContent: "Upload the confirmation for Online Laws and Rules, the Online Professionalism & Ethics and the Online Louisiana Standards of Practice for Boundary Surveys quizzes.",
    },
    {
        id: 5,
        sectionTitle: "CPD Requirements",
        sectionContent: "Verify CPD requirements for Active status for each of the calendar years.",
    },
    {
        id: 6,
        sectionTitle: "Personal References",
        sectionContent: "Complete this section listing all personal references.",
    },
    {
        id: 7,
        sectionTitle: "Experience Record",
        sectionContent: "Include a GRAND TOTAL of all experience",
    },
    {
        id: 8,
        sectionTitle: "Disclosure",
        sectionContent: "Authorize LAPELS staff to procure a consumer report.",
    },
    {
        id: 9,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    }
    ,
    {
        id: 10,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    },
    {
        id: 11,
        sectionTitle: "Board/Staff Recommendations",
        sectionContent: 'Waiting on text',
    }
])

export default {
    peGetSection,
    pePostSection,
    pePostSectionHasRef,
    pePostSectionHasCpd,
    pePostSectionHasExperience,
    peMarkSectionComplete,
    getIndividualReinstatement,
    postStaffApproval,
    postReinstatementPdf
}