import React, { useEffect, useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PeInformation'
import Information from '../../../../../Shared/Card/Information';
import { useHistory } from 'react-router-dom';
import PersonalInformation from '../../../../../Shared/Card/Contact/PersonalInformation';
import MailingAddress from '../../../../../Shared/Card/Contact/MailingAddress';
import PublicAddress from '../../../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import applicationService from '../../../../../../services/Public/Application/PE/peApplication.service';
import { useSelector } from 'react-redux';
import contactService from '../../../../../../services/Public/Application/PE/contact.service';
import Swal from 'sweetalert2';
import Issues from '../../../../../Shared/Card/Issues';
import dayjs from 'dayjs';

function ContactInformationPage() {
    const sectionId = 1;
    const routes = useSelector(x => x.currentRoutes);
    const { push } = useHistory();
    const [hasLoaded, setHasLoaded] = useState(false);
    const appId = useSelector(x => x.currentApplicationId);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const [formModel, setFormModel] = useState({});
    const [tabPane, setTabPane] = useState("personalInformation")
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        contactService.getContactInformation(appId).then((response) => {
            var data = response.data;

            setFormModel({
                ...data,
                dateOfBirth: data.dateOfBirth != null ? dayjs(data.dateOfBirth).format('YYYY-MM-DD') : null,
                altEmail: data.alternateEmailAddress?.emailAddress,
                primaryEmail: data.primaryEmailAddress?.emailAddress,
                cellPhoneNum: data.cellPhone?.phoneNumber,
                workPhoneNum: data.workPhone?.phoneNumber,
                homePhoneNum: data.homePhone?.phoneNumber,
                isActiveMilitary: data.isActiveMilitary == "True" ? true : data.isActiveMilitary == "False" ? false : null,
                isActiveMilitarySpouse: data.isActiveMilitarySpouse == "True" ? true : data.isActiveMilitarySpouse == "False" ? false : null,
            })
            applicationService.peGetSection(appId, sectionId).then((response) => {
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })
    }, [])

    const formSubmit = (validation, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (appTypeId == 3 && validation)
                submitData(true);
            else if (appTypeId == 4)
                submitData(true);
        }
    }
    const { BooleanType } = Schema.Types;
    const validationModel = Schema.Model({
        isActiveMilitary: BooleanType().isRequired("This field is required."),
        isActiveMilitarySpouse: BooleanType().isRequired("This field is required."),
    })
    const formSave = (event) => {
        submitData();
    }

    const submitData = (isForSubmit) => {
        contactService.postContactInformation(appId, formModel.certificateName, formModel.isActiveMilitary, formModel.baseStationed, formModel.isActiveMilitarySpouse, formModel.spouseBaseStationed).then((response) => {
            if (isForSubmit && response.data) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <strong>Professional Engineer Application {appType}</strong>
                <h4 className="mb-3">Contact</h4>
                <Information content={GetAppInformationContent("contact")} />
                <br />
                <Card className="mb-3">
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="personalInformation" title="Personal Information">
                                    <br />
                                    <br />
                                    <Form id="personalInformationForm" model={validationModel} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} >
                                        <PersonalInformation
                                            isNonUsBirthState={formModel.birthCountry?.country != 'United States'}
                                            isUserEditable={sectionData.isUserEditable}
                                            isReadOnly={true}
                                            isForApp={true}
                                            isPeByComity={appTypeId == 3}
                                            isExternal={true}
                                        />
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress isReadOnly={true} boundValues={formModel.mailingAddress} contactInfoLink={"/individual/contactInformation"} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress isReadOnly={true} boundValues={formModel.publicAddress} contactInfoLink={"/individual/contactInformation"} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                {hasLoaded &&
                    <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                }
                <FormActionButtons submitFormName="personalInformationForm" isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/peapp/sections" handleSave={formSave} />
            </Container>
        </div>
    );
}
export default ContactInformationPage