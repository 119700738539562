import axios from "axios";
import authHeader from '../../../authheader'

const EIREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualEiReinstatement";

const getExamination = (reinstatementId) => {
    return axios.get(`${EIREINSTATEMENTURL}/examinationverification`, { headers: authHeader(), params: { reinstatementId } });
}
const postExamination = (data) => {
    return axios.post(`${EIREINSTATEMENTURL}/examinationverification`, data, { headers: authHeader() });
}


export default {
    getExamination,
    postExamination
}