import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import staticInformationService from '../../../services/Internal/Staff/dashboardInformation.service';

function TopCards() {
    const { push } = useHistory();

    const [applications, setApplications] = useState(
        {
            total: 0,
            individual: 0,
            firm: 0
        });
    const [reinstatements, setReinstatements] = useState(
        {
            total: 0,
            individual: 0,
            firm: 0
        });
    const [renewals, setRenewals] = useState(
        {
            total: 0,
            individual: 0,
            firm: 0
        });
    const [individualLicenses, setIndividualLicenses] = useState();
    const [firmLicenses, setFirmLicenses] = useState();
    const [askLola, setAskLola] = useState();
    const [cases, setCases] = useState();

    useEffect(() => {
        staticInformationService.getDashboardNumbers().then((response) => {
            if (response.data) {
                setApplications(
                    {
                        total: (response.data.pendingIndividualApplications + response.data.pendingFirmApplications).toLocaleString("en-US"),
                        individual: response.data.pendingIndividualApplications.toLocaleString("en-US"),
                        firm: response.data.pendingFirmApplications.toLocaleString("en-US")
                    }
                );
                setReinstatements(
                    {
                        total: (response.data.pendingIndividualReinstatements + response.data.pendingFirmReinstatements).toLocaleString("en-US"),
                        individual: response.data.pendingIndividualReinstatements.toLocaleString("en-US"),
                        firm: response.data.pendingFirmReinstatements.toLocaleString("en-US")
                    }
                );
                setRenewals(
                    {
                        total: (response.data.pendingIndividualRenewals + response.data.pendingFirmRenewals).toLocaleString("en-US"),
                        individual: response.data.pendingIndividualRenewals.toLocaleString("en-US"),
                        firm: response.data.pendingFirmRenewals.toLocaleString("en-US")
                    }
                );
                setIndividualLicenses(response.data.individualLicenses.toLocaleString("en-US"))
                setFirmLicenses(response.data.firmLicenses.toLocaleString("en-US"))
                setAskLola(response.data.askLolaUnanswered.toLocaleString("en-US"))
                setCases(response.data.enforcementCases.toLocaleString("en-US"));
            };
        })
    }, [])

    //const handleApplicationCardClick = (e) => {
    //    navigateToPage('applications')
    //    //Application Card was clicked
    //}
    //const handleInvLicensesCardClick = (e) => {
    //    navigateToPage('invLicenses')
    //    //Individual Licenses Card was clicked
    //}
    //const handleFirmLicensesCardClick = (e) => {
    //    navigateToPage('firmLicenses')
    //    //Firm Licenses Card was clicked
    //}
    //const handleAskLolaCardClick = (e) => {
    //    navigateToPage('askLola')
    //    //Ask LOLA Card was clicked
    //}
    //const navigateToPage = (page) => {
    //    switch (page) {
    //        case 'applications':
    //            console.log("nav to applications");
    //            break;
    //        case 'invLicenses':
    //            console.log("nav to individual licenses");
    //            break;
    //        case 'firmLicenses':
    //            console.log("nav to firm licenses");
    //            break;
    //        case 'askLola':
    //            console.log("nav to ask lola");
    //            break;
    //        default:
    //            break;
    //    }
    //}

    return (
        <>
            <Row>
                <Col>
                    <Card id="internalDashboardApplicationCard">
                        <Card.Header style={{border:'0'}}>
                            <h5>Applications ({applications.total})
                            </h5>
                        </Card.Header>
                        <Card.Body style={{ padding: '0 1rem' }}>
                            <Row>
                                <Col style={{ padding: '0', borderRight: '1px solid rgba(0, 0, 0, 0.125)'}}>
                                    <Card.Header style={{ padding: '0.5rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                        <h5>Individual</h5>
                                    </Card.Header>
                                    <Card.Body style={{ padding: '.25rem' }}>
                                        <h2>{applications.individual}</h2>
                                    </Card.Body>
                                </Col>
                                <Col style={{ padding: '0' }}>
                                    <Card.Header style={{ padding: '0.5rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                        <h5>Firm</h5>
                                    </Card.Header>
                                    <Card.Body style={{ padding: '.25rem .25rem 1.75rem .25rem' }}>
                                        <h2>{ applications.firm}</h2>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card id="internalDashboardInvLicensesCard">
                        <Card.Header style={{ border: '0' }}>
                            <h5>Reinstatements ({reinstatements.total})
                            </h5>
                        </Card.Header>
                        <Card.Body style={{ padding: '0 1rem' }}>
                            <Row>
                                <Col style={{ padding: '0', borderRight: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                    <Card.Header style={{ padding: '0.5rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                        <h5>Individual</h5>
                                    </Card.Header>
                                    <Card.Body style={{ padding: '.25rem' }}>
                                        <h2>{reinstatements.individual}</h2>
                                    </Card.Body>
                                </Col>
                                <Col style={{ padding: '0' }}>
                                    <Card.Header style={{ padding: '0.5rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                        <h5>Firm</h5>
                                    </Card.Header>
                                    <Card.Body style={{ padding: '.25rem .25rem 1.75rem .25rem' }}>
                                        <h2>{reinstatements.firm}</h2>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card id="internalDashboardFirmLicensesCard">
                        <Card.Header style={{ border: '0' }}>
                            <h5>Renewals ({renewals.total})
                            </h5>
                        </Card.Header>
                        <Card.Body style={{ padding: '0 1rem' }}>
                            <Row>
                                <Col style={{ padding: '0', borderRight: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                    <Card.Header style={{ padding: '0.5rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                        <h5>Individual</h5>
                                    </Card.Header>
                                    <Card.Body style={{ padding: '.25rem .25rem 1.25rem .25rem'  }}>
                                        <h2>{renewals.individual}</h2>
                                    </Card.Body>
                                </Col>
                                <Col style={{ padding: '0' }}>
                                    <Card.Header style={{ padding: '0.5rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                        <h5>Firm</h5>
                                    </Card.Header>
                                    <Card.Body style={{ padding: '.25rem .25rem 1.75rem .25rem' }}>
                                        <h2>{renewals.firm}</h2>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card id="internalDashboardAskLolaCard"  >
                        <Card.Header>
                            <h5>
                                Individual Licenses
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <h1>{individualLicenses}</h1>
                            <b>All Active Licenses</b>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card id="internalDashboardCasesCard" >
                        <Card.Header>
                            <h5>
                                Firm Licenses
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <h1>{firmLicenses}</h1>
                            <b>All Active Licenses</b>
                        </Card.Body>
                    </Card>
                </Col>
                {/*<Col >*/}
                {/*    <Card id="internalDashboardAskLolaCard">*/}
                {/*        <Card.Header>*/}
                {/*            <h5>*/}
                {/*                Ask LOLA*/}
                {/*                </h5>*/}
                {/*        </Card.Header>*/}
                {/*        <Card.Body>*/}
                {/*            <h1>{askLola}</h1>*/}
                {/*            <b>Unanswered</b>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <Card id="internalDashboardCasesCard">*/}
                {/*        <Card.Header>*/}
                {/*            <h5>*/}
                {/*                Cases*/}
                {/*                </h5>*/}
                {/*        </Card.Header>*/}
                {/*        <Card.Body>*/}
                {/*            <h1>{cases}</h1>*/}
                {/*            <b>Open</b>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </>
    )
}

export default TopCards;