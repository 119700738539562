import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import EducationRecord from './EducationRecords';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/LsiInformation';
import { useSelector } from 'react-redux';
import publicApplicationService from '../../../../../services/Public/Application/LSI/lsiApplication.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import lsiApplicationService from '../../../../../services/Internal/Application/LSI/lsiApplication.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function EducationPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);

    const { push } = useHistory();
    const appEntityId = useSelector(x => x.currentApplicationEntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 2;
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Land Surveyor Intern Application",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Education"
        }
    );
    useEffect(() => {
        setHasLoaded(false);
        publicApplicationService.lsiGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete);
            applicationService.getIndividualApplication(appId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesEducation: BooleanType().isRequired('This field is required.'),
        hasAnsacDegree: BooleanType().isRequired('This field is required.'),
        hasFourYearDegree: BooleanType().isRequired('This field is required.')
    });

    const submitForm = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var validationObject = model.check(sectionData);
            for (const key in validationObject) {
                if (validationObject[key].hasError) {
                    if (key == "hasFourYearDegree" && sectionData.hasAnsacDegree == true) {
                        continue;
                    }
                    else {
                        return;
                    }
                }
            }
            //if (sectionData.hasFourYearDegree && !hasFiles) {
            //    return;
            //}
            publicApplicationService.lsiPostEducationSection(appId, sectionId, sectionData.hasMyNceesEducation, sectionData.hasAnsacDegree, sectionData.hasFourYearDegree).then(() => {
                lsiApplicationService.lsiMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                })
            })
        }
    }
    return (
        <div className="lsiAppEducationPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("education")} />
                <br />
                {hasLoaded ? <>
                    <Form
                        model={model}
                        fluid
                        formValue={sectionData}
                        onSubmit={submitForm}
                        onChange={data => setSectionData(data)}
                    >
                        <EducationRecord
                            applicationId={appId}
                            isReadOnly={!isUserEditable}
                            hasMyNceesEducation={sectionData.hasMyNceesEducation}
                            hasFourYearDegree={sectionData.hasFourYearDegree}
                            hasAnsacDegree={sectionData.hasAnsacDegree}
                            hasFiles={hasFiles}
                            setHasFiles={setHasFiles}
                            entityId={entityInfo.entityId}
                            sectionId={sectionId}
                            sectionDataId={sectionData.id}
                        />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true}
                            backToSectionLink="/internal/lsiApp/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default EducationPage;