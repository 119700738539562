import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Form } from 'rsuite';
import Information from '../../../../Shared/Card/Information';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/EiInformation';

import Recommendation from './Recommendation';

import recommendationService from '../../../../../services/Internal/Application/EI/recommendation.service'
import eiApplicationService from '../../../../../services/Internal/Application/EI/eiApplication.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import Swal from 'sweetalert2';
import AddEditRecommendationModal from './AddEditRecommendationModal';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import ExternalFormModal from '../../../../Shared/Modal/ExternalFormModal';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function RecommendationPage() {
    const { push } = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(true);
    const [verifierModalShow, setVerifierModalShow] = useState(false);
    const [verifierId, setVerifierId] = useState(0);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const applicationId = useSelector(x => x.currentApplicationId);
    const sectionId = 12; // Revisit
    const routes = useSelector(x => x.currentRoutes);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Application",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Recommendations"
        }
    );
    const [modalData, setModalData] = useState({
        applicationId: 0,
        dateCrte: null,
        deleted: false,
        email: "",
        entityId: 0,
        id: 0,
        firstName: "",
        lastName: "",
        sentDate: null,
        responseDate: null,
        userCrte: "",
        verificationStatus: null,
        modalType: "Add"
    });
    const [gridData, setGridData] = useState([]);
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        setHasLoaded(false)
        eiApplicationService.eiGetSection(applicationId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete);
            applicationService.getIndividualApplication(applicationId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                    updateGridData();
                    setHasLoaded(true);
                });
            })
        })
    }, [])


    const updateGridData = () => {
        recommendationService.getRecommendations(applicationId).then((response) => {
            setGridData(response.data);
        });
    }
    const openAddEditModal = (values) => {
        if (values.id) {
            var data = {
                ...values,
                modalType: "Edit"
            }
            setModalData(data);
        }
        else {
            setModalData({
                applicationId: applicationId,
                dateCrte: null,
                deleted: false,
                email: "",
                entityId: entityId,
                id: 0,
                firstName: "",
                lastName: "",
                sentDate: null,
                responseDate: null,
                userCrte: "",
                verificationStatus: null,
                modalType: "Add"
            })
        }
        setModalShow(true);
    }

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            eiApplicationService.eiMarkSectionComplete(applicationId, sectionId, isComplete).then((data) => {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
            })
        }
    }
    const openVerifierModal = (id) => {
        setVerifierId(id);
        setVerifierModalShow(true);
    }

    const deleteRow = (rowData) => {
        if (isUserEditable) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    recommendationService.postRecommendation({ ...rowData, deleted: true }).then((response) => {
                        updateGridData();
                    })
                }
            })
        }
    }
    const emailReference = (rowData) => {
        if (isUserEditable) {
            recommendationService.sendRecommendationEmail(rowData.id).then((response) => {
                if (response.data) {
                    Swal.fire(`You have successfully sent an email to ${rowData.firstName} ${rowData.lastName} at ${rowData.email}!`);
                    updateGridData();
                }
                else
                    Swal.fire(`Failed to send email: ${response.data.message}`);
            })
        }
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("recommendation")} />
                <br />
                {hasLoaded ? <>
                    <AddEditRecommendationModal
                        updategrid={updateGridData}
                        modaldata={modalData}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onsave={recommendationService.postRecommendation}
                    />
                    {verifierModalShow &&
                        <ExternalFormModal show={verifierModalShow} onHide={() => setVerifierModalShow(false)} id={verifierId} type="recommendationApplication" />
                    }
                    <Form
                        fluid
                        formValue={sectionData}
                        onChange={data => { setSectionData(data) }}
                        onSubmit={formSubmit}  >
                        <Recommendation
                            isUserEditable={isUserEditable}
                            updateGrid={updateGridData}
                            openModal={openAddEditModal}
                            griddata={gridData}
                            deleteRow={deleteRow}
                            emailReference={emailReference}
                            openVerifierModal={openVerifierModal}
                        />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            backToSectionLink="/internal/eiapp/sections"
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true} />
                    </Form>
                </> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}

export default RecommendationPage