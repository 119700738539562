import axios from "axios";
import authHeader from '../../../authheader'

const VFRENEWALURL = process.env.REACT_APP_APIURL + "/api/FirmVfRenewal";

const getContactInformation = (renewalId) => {
    return axios.get(`${VFRENEWALURL}/contactinformation`, { headers: authHeader(), params: { renewalId } });
}

export default {
    getContactInformation
}