import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import recentUpdateService from '../../../services/Shared/recentUpdate.service';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import PendingTasks from './PendingTasks';
import RecentUpdates from './RecentUpdates';
import TopCards from './TopCards';



function DashboardPage() {
    const [recentUpdate, setRecentUpdate] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        recentUpdateService.getRecentUpdate().then((response) => {
            setRecentUpdate(response.data);
            setHasLoaded(true);
        });

    }, [])
    return (
        <Container fluid>
            <TopCards />
            <br />
            <Row>
                <Col>
                    {hasLoaded ?
                        <RecentUpdates updates={recentUpdate} /> : <LoadingSpinner />
                    }
                </Col>
            </Row>
        </Container>
    )
}
export default DashboardPage;