import axios from "axios";
import authHeader from '../../../authheader'

const PLSAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsApplication";

const getEmployment = (applicationId) => {
    return axios.get(`${PLSAPPLICATIONURL}/employment`, { headers: authHeader(), params: { applicationId }   });
}
const postEmployment = (data) => {
    return axios.post(`${PLSAPPLICATIONURL}/employment`, data, { headers: authHeader() });
}
export default {
    getEmployment,
    postEmployment
}