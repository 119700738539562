import React, { useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'
import { Form } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import { useHistory } from 'react-router-dom';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/LsiInformation';
import Information from '../../../../../Shared/Card/Information';
import contactService from '../../../../../../services/Public/Renewal/LSI/contact.service';
import renewalService from '../../../../../../services/Public/Renewal/LSI/lsiRenewal.service';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import PersonalInformation from '../../../../../Shared/Card/Contact/PersonalInformation';
import MailingAddress from '../../../../../Shared/Card/Contact/MailingAddress';
import PublicAddress from '../../../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import dayjs from 'dayjs';
import Issues from '../../../../../Shared/Card/Issues';

function ContactInformationPage() {

    const { push } = useHistory();
    const [hasLoaded, setHasLoaded] = useState(false);
    const renewalId = useSelector(x => x.currentApplicationId);
    const sectionId = 1;
    const routes = useSelector(x => x.currentRoutes);
    const [formModel, setFormModel] = useState({});
    const [tabPane, setTabPane] = useState("personalInformation")
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        contactService.getContactInformation(renewalId).then((response) => {
            var data = response.data;

            setFormModel({
                ...data,
                dateOfBirth: data.dateOfBirth != null ? dayjs(data.dateOfBirth).format('YYYY-MM-DD') : null,
                altEmail: data.alternateEmailAddress?.contactEmailAddress,
                primaryEmail: data.primaryEmailAddress?.contactEmailAddress,
                cellPhoneNum: data.cellPhone?.contactPhoneNumber,
                workPhoneNum: data.workPhone?.contactPhoneNumber,
                homePhoneNum: data.homePhone?.contactPhoneNumber
            })
            renewalService.lsiGetSection(renewalId, sectionId).then((response) => {
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })
    }, [])

    const formSubmit = (validation, event) => {
        if (!sectionData?.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            submitData(true);
        }
    }

    const formSave = (event) => {
        submitData();
    }

    const submitData = (isForSubmit) => {

        if (isForSubmit) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            Swal.fire("Saved!");
        }
    }

    return (
        <div>
            <Container fluid>
                <strong>Land Surveyor Intern Renewal</strong>
                <h4 className="mb-3">Contact</h4>
                <Information content={GetRenewalInformationContent("contact")} />
                <br />

                <Card className="mb-3">
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="personalInformation" title="Personal Information">
                                    <br />
                                    <br />
                                    <Form id="personalInformationForm" fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} >
                                        <PersonalInformation isNonUsBirthState={formModel.birthCountry?.country != 'United States'} isUserEditable={sectionData.isUserEditable} isReadOnly={true} isExternal={true}/>
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress isUserEditable={sectionData.isUserEditable} isReadOnly={true} boundValues={formModel.mailingAddress} contactInfoLink={"/individual/contactInformation"}/>
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress isUserEditable={sectionData.isUserEditable} isReadOnly={true} boundValues={formModel.publicAddress} contactInfoLink={"/individual/contactInformation"}/>
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                {hasLoaded && <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />}
                <FormActionButtons submitFormName="personalInformationForm" isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/lsiRenewal/sections" handleSave={formSave} />
            </Container>
        </div>
    );
}
export default ContactInformationPage