import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/VFInformation';
import reinstatementService from '../../../../../../services/Public/Reinstatement/VF/vfReinstatement.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import WavierCertificate from '../../../../../Shared/Card/WaiverCertificate';
import Issues from '../../../../../Shared/Card/Issues';

function WaiverCertificatePage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 12;
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        setHasLoaded(false);
        reinstatementService.vfGetSection(store.currentApplicationId, sectionId).then((response) => {
            setSectionData(response.data);
            setHasLoaded(true);
        })


    }, [])
    const store = useSelector(x => x);

    const { push } = useHistory();
    //const showAdditionalFields = sectionData.hasWordOfferingProfessionalServices;

    const handleSubmit = (val, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (val) {
                reinstatementService.vfPostSection(store.currentApplicationId, sectionId, sectionData.electronicSignature).then((response) => {
                    if (response.data)
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            }

        }
    }

    return (
        <div>
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={setSectionData} fluid formValue={sectionData}>
                    <strong>Surveying Firm Reinstatement</strong>
                    <h4 className="mb-3">Certificate of Authority/Good Standing</h4>
                    <Row className="mb-3">
                        <Col> <Information content={GetReinstatementInformationContent("waiver")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <WavierCertificate
                                        isReadOnly={!sectionData.isUserEditable}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        parentTypeId={2}
                                        credentialTypeId={2}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={true} type={2} sectionId={sectionData.id} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/firm/vfReinstatement/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    );
}
export default WaiverCertificatePage