import axios from "axios";
import authHeader from '../../../authheader'

const EFREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/FirmEfReinstatement";

const getViolation = (reinstatementId) => {
    return axios.get(`${EFREINSTATEMENTURL}/violation`, { headers: authHeader(), params: { reinstatementId } });
}
const postViolation = (data) => {
    return axios.post(`${EFREINSTATEMENTURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}