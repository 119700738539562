import React from 'react';

// action url is javascript function
function GenericGridStatus({ status, extraInfo }) {
    let markUp = "";
    if (status) {
        markUp = <span data-status={ status } className="badge">{status}</span>;
        if (extraInfo)
            markUp = <><span data-status={ status } className="badge">{status}</span> <span className="badge badge-danger">{extraInfo}</span></>;
    }

    return (
        markUp
    )
}

export default GenericGridStatus;