import dayjs from 'dayjs';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import RedStar from '../../../Shared/GenericComponents/RedStar';

function AddEditEmployementModal(props) {
    const [formModel, setFormModel] = useState({});
    useEffect(() => {
        setFormModel({
            ...props.model,
            startDate: props.model?.startDate != null ? dayjs(props.model.startDate).format('YYYY-MM-DD') : '',
            endDate: props.model?.endDate != null ? dayjs(props.model.endDate).format('YYYY-MM-DD') : '',
        });
    }, [props.model])
    const { StringType } = Schema.Types;
    const validationModel = Schema.Model({
        startDate: StringType().isRequired('This field is required'),
        occupation: StringType().isRequired('This field is required'),
        organization: StringType().isRequired('This field is required'),

    })
    const formSubmit = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...formModel,
                startDate: formModel.startDate != '' ? new Date(formModel.startDate) : null, 
                endDate: formModel.endDate != '' ? new Date(formModel.endDate) : null, 
            }
            if (formModel.id > 0) {
                credentialService.updateWorkHistory(dataToTransmit).then((response) => {
                    if (response.data) {
                        props.onHide();
                        Swal.fire('Success!', 'You have successfully updated a record to employment.', 'success');
                    }

                })
            }
            else if (formModel.id == 0) {
                credentialService.createWorkHistory(dataToTransmit).then((response) => {
                    if (response.data) {
                        props.onHide();
                        Swal.fire('Success!', 'You have successfully added a record to employment.', 'success');
                    }

                })
            }
            //service call
        }
    }

    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4>Employment Information</h4>
            </Modal.Header>
            <Modal.Body>
                <Form id="employementForm" model={validationModel} formValue={formModel} onChange={setFormModel} onSubmit={formSubmit} >
                    <Row className="mb-3">
                        <Col>
                            <ControlLabel><b>Start Date</b> <RedStar /></ControlLabel>
                            <FormControl name="startDate" type="date" />
                        </Col>
                        <Col>
                            <ControlLabel><b>End Date</b></ControlLabel>
                            <FormControl name="endDate" type="date" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <ControlLabel><b>Occupation</b> <RedStar /></ControlLabel>
                            <FormControl name="occupation" />
                        </Col>
                        <Col>
                            <ControlLabel><b>Organization</b><RedStar /></ControlLabel>
                            <FormControl name="organization" />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="mr-2" variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
                <Button type="submit" form="employementForm" >Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditEmployementModal;
