import React, { useEffect, useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Schema } from 'rsuite';
import dayjs from 'dayjs';
import { GetFirmGeneralContactInformationInstructions } from '../../../../../Shared/InformationContent/GeneralInformation'
import Information from '../../../../../Shared/Card/Information';
import contactService from '../../../../../../services/Public/Renewal/VF/contact.service';
import renewalService from '../../../../../../services/Public/Renewal/VF/vfRenewal.service';
import MailingAddress from '../../../../../Shared/Card/Contact/MailingAddress';
import FirmInformation from '../../../../../Shared/Card/Contact/FirmInformation';
import PublicAddress from '../../../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../../Shared/Card/Issues';

function ContactInformationPage() {
    const sectionId = 1;
    const routes = useSelector(x => x.currentRoutes);
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const appId = useSelector(x => x.currentApplicationId);
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        contactService.getContactInformation(appId).then((response) => {
            var data = response.data;
            setFormModel({
                ...data,
                dateOfFormation: data.dateOfFormation != null ? dayjs(data.dateOfFormation).format('YYYY-MM-DD') : null,
                orgType: data.organizationType?.organizationType,
                primaryEmail: data.primaryEmailAddress?.contactEmailAddress,
                phoneNum: data.phone?.contactPhoneNumber

            })

            renewalService.vfGetSection(appId, sectionId).then((response) => {
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })
    }, [])

    const [formModel, setFormModel] = useState({})

    const { StringType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        federalId: StringType().isRequired('This field is required.'),
        orgType: StringType().isRequired('This field is required.'),
        primaryEmail: StringType().isRequired('This field is required.'),
        dateOfFormation: StringType().isRequired('This field is required.'),
        name: StringType().isRequired('This field is required.'),
        domicileState: ObjectType().isRequired('This field is required.'),
    });
    const addressModel = Schema.Model({
        streetAddress: StringType().isRequired('This field is required.'),
        country: ObjectType().isRequired('This field is required.'),
        city: StringType().isRequired('This field is required.'),
        postalCode: StringType().isRequired('This field is required.'),
        state: ObjectType().isRequired('This field is required.'),
        provinceRegion: StringType().isRequired('This field is required.'),
    })

    const [tabPane, setTabPane] = useState("firmInformation")

    const submitData = () => {
        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)

    }

    return (
        <div>
            <Container fluid>
                <strong>Contact Information</strong>
                <h4 className="mb-3">Contact</h4>
                <Information content={GetFirmGeneralContactInformationInstructions()} />
                <br />
                <Card className="mb-3">
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="firmInformation" title="Firm Information">
                                    <br />
                                    <br />
                                    <Form id="firmInformationForm"
                                        model={model}
                                        fluid
                                        formValue={formModel}
                                        onChange={formModel => setFormModel(formModel)}
                                        onSubmit={submitData} >
                                        <FirmInformation isReadOnly={true} isUserEditable={sectionData.isUserEditable} isForApp={true} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        model={addressModel}
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress isReadOnly={true} isUserEditable={sectionData.isUserEditable} boundValues={formModel.mailingAddress} contactInfoLink={"/firm/contactInformation"} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        model={addressModel}
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress isReadOnly={true} isUserEditable={sectionData.isUserEditable} boundValues={formModel.publicAddress} contactInfoLink={"/firm/contactInformation"} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                {hasLoaded &&
                    <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />
                }
                <FormActionButtons
                    submitFormName="firmInformationForm"
                    backToSectionLink="/firm/vfRenewal/sections"

                />
            </Container>
        </div>
    );
}
export default ContactInformationPage