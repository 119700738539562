import React from "react"
import { FormGroup, RadioGroup, Radio, FormControl } from "rsuite";

function YesNoRadioOption(props) {
    return(
        <FormGroup controlId="formBasicCheckbox">
            <FormControl
                plaintext={props.plaintext}
                readOnly={props.isReadOnly}
                name={props.radioName}
                inline
                onChange={props.handleChange}
                accepter={RadioGroup}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
            </FormControl>
        </FormGroup>
        )
}

export default YesNoRadioOption