import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ControlLabel, Form, FormControl, FormGroup, Schema } from 'rsuite';
import coopExperienceService from '../../../../../../services/Public/Application/PE/coopExperience.service';
import Information from '../../../../../Shared/Card/Information';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import RedStar from '../../../../../Shared/GenericComponents/RedStar';
import applicationService from '../../../../../../services/Public/Application/PE/peApplication.service';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import YesNoRadioOption from '../../../../../Shared/RadioButton/YesNoRadioOption';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import Issues from '../../../../../Shared/Card/Issues';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
function CoopExperiencePage() {
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const [model, setModel] = useState({
        hasCoopExperience: '',
        months: '',
        years: '',
        entityId: entityId,
        applicationId: appId
    });
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const sectionId = 16;
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        setHasLoaded(false);
        applicationService.peGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            coopExperienceService.getCoopExperience(appId).then((data) => {
                if (data.data)
                    setModel({
                        ...data.data,
                        years: !data.data.years ? '' : data.data.years,
                        months: !data.data.months ? '' : data.data.months
                    });
            })
            setHasLoaded(true);
        })
    }, [])

    const { BooleanType } = Schema.Types;
    const validationModel = Schema.Model({
        hasCoopExperience: BooleanType().isRequired('This field is required.')

    });

    const formSubmit = (val, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (val) {
                var dataToTransmit = {
                    ...model,
                    years: model.years == "" ? 0 : model.years,
                    months: model.months == "" ? 0 : model.months,
                }
                coopExperienceService.postCoopExperience(dataToTransmit).then((response) => {
                    if (response.data)
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            }
        }
    }

    return (
        <Container fluid>
            <strong>Professional Engineer Application { appType}</strong>
            <h4 className="mb-3">Co-op Experience</h4>
            <Information content={GetAppInformationContent("coopExperience")} />
            <br />{hasLoaded ? 
            <Form readOnly={!sectionData.isUserEditable} formValue={model} onChange={setModel} onSubmit={formSubmit} model={validationModel}>
                <Card>
                    <Card.Header>
                        <h6>Co-op Experience</h6>
                    </Card.Header>
                    <Card.Body>
                        <RedStar /> I have Co-op Experience listed on my transcript
                            <YesNoRadioOption radioName="hasCoopExperience" />
                        {model.hasCoopExperience === true &&
                            <>
                                <hr />
                                <Row>
                                    <Col sm={3}>
                                        <b>TOTAL OF CO-OP EXPERIENCE:</b>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <FormControl className="mr-3"
                                                name="months"
                                                placeholder="Month(s)"
                                                type="number"
                                            />
                                            <FormControl
                                                name="years"
                                                placeholder="Year(s)"
                                                type="number"
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Card.Body>
                </Card>
                <br />
                <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                <FormActionButtons backToSectionLink="/individual/peapp/sections" />
            </Form> : <LoadingSpinner/>}
        </Container>
    );
}
export default CoopExperiencePage;