import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { AutoComplete, ControlLabel, Form, FormControl, FormGroup, InputPicker, SelectPicker } from 'rsuite';
import { getAllCredentials, getCountries, getCredStatus, getCredStatusReason, getCredTypes, getEfCredentials } from '../../../services/Shared/codeTable.service';
import searchService from '../../../services/Internal/Search/search.service';
import EntitySearchGrid from './EntitySearchGrid';
import * as dayjs from 'dayjs';
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';
import PhoneNumberMask from '../../Shared/GenericComponents/PhoneNumberMask';

const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};

function EntitySearchPage() {
    const [searchModel, setSearchModel] = useState({
        lolaNumber: '',
        credentialNumber: '',
        credentialType: null,
        credentialStatus: null,
        credentialStatusReason: null,
        firstName: '',
        lastName: '',
        firmName: '',
        email: '',
        federalId: '',
        dateOfBirth: '',
        phone: '',
        stateProvince: '',
        postalCode: '',
        country: null,
        userId: '',
        nceesNumber: ''
    })
    const [countries, setCountries] = useState([])
    const [creds, setCreds] = useState();
    const [credType, setCredType] = useState([])
    const [credStaus, setCredStaus] = useState([])
    const [credReason, setCredReason] = useState([])
    const [gridData, setGridData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [inputType, setInputType] = useState("text");


    useEffect(() => {
        getCountries().then(setCountries);
        getCredStatus().then(setCredStaus);
        getCredTypes().then(setCredType);
        const searchCache = JSON.parse(localStorage.getItem("searchCache"));
        if (searchCache) {
            if (searchCache.credentialStatus) {
                getCredStatusReason(searchCache.credentialStatus?.id).then(setCredReason);
            }
            setSearchModel(searchCache)
            cacheSearch(searchCache);
        }
    }, [])
    const cacheSearch = (searchCache) => {
        if (searchCache) {
            localStorage.removeItem("searchCache");
            var credentialNumber = null;
            var dateOfBirth = searchCache.dateOfBirth ? dayjs(searchCache.dateOfBirth).format('YYYY-MM-DD') : null;
            if (searchCache.credentialNumber?.includes("(") && searchCache.credentialNumber?.includes(")"))
                credentialNumber = searchCache.credentialNumber?.split("(")[1].split(")")[0];
            else if (searchCache.credentialNumber) {
                Swal.fire("Please select a credential from the credential autocomplete.");
                return false;
            }

            setIsSearching(true);
            var fedId = searchCache.federalId == '' ? null : searchCache.federalId
            localStorage.setItem("searchCache", JSON.stringify({ ...searchCache }));
            searchService.postEntitySearch({ ...searchCache, dateOfBirth: dateOfBirth, federalId: fedId, credentialNumber }).then((response) => {
                setGridData(response.data);

            })
        }
    }
    const feinSsnChange = (val) => {
        var newSearchModel = searchModel;
        var correctedText = val.replace(/[^\d-]*/g, "");
        newSearchModel.federalId = correctedText;
        setSearchModel(newSearchModel);
    }
    const nceesChange = (val) => {
        var newSearchModel = searchModel;
        var correctedText = val.replace(/[^\d-]*/g, "");
        newSearchModel.nceesNumber = correctedText;
        setSearchModel(newSearchModel);
    }
    const handleCredentialStatusChange = (val) => {
        setSearchModel({ ...searchModel, credentialStatus: val, credentialStatusReason: null })
        getCredStatusReason(val?.id).then(setCredReason);
    }
    const updateCreds = (value) => {
        getAllCredentials(value).then(setCreds);
    }
    const submitSearch = () => {
        var credentialNumber = null;
        var dateOfBirth = searchModel.dateOfBirth ? dayjs(searchModel.dateOfBirth).format('YYYY-MM-DD') : null;
        if (searchModel.credentialNumber?.includes("(") && searchModel.credentialNumber?.includes(")"))
            credentialNumber = searchModel.credentialNumber?.split("(")[1].split(")")[0];
        else if (searchModel.credentialNumber) {
            Swal.fire("Please select a credential from the credential autocomplete.");
            return false;
        }

        setIsSearching(true);
        var fedId = searchModel.federalId == '' ? null : searchModel.federalId
        localStorage.setItem("searchCache", JSON.stringify({ ...searchModel }));
        localStorage.setItem("currentPageCache", JSON.stringify(1));
        searchService.postEntitySearch({ ...searchModel, dateOfBirth: dateOfBirth, federalId: fedId, credentialNumber }).then((response) => {
            setGridData(response.data);
        })
    }
    const clearSearchModel = () => {
        setInputType("text");
        setSearchModel({
            lolaNumber: '',
            credentialNumber: '',
            credentialType: null,
            credentialStatus: null,
            credentialStatusReason: null,
            firstName: '',
            lastName: '',
            firmName: '',
            email: '',
            federalId: '',
            dateOfBirth: '',
            phone: '',
            stateProvince: '',
            postalCode: '',
            country: null,
            userId: '',
            nceesNumber: ''
        });
        localStorage.removeItem("searchCache");
    }
    return (
        <Container fluid>
            <h3 className="mb-3">Contact Search</h3>
            <Card className="mb-3">
                <Card.Header>
                    <h5>Search Criteria</h5>
                </Card.Header>
                <Card.Body>
                    <BlockUi blocking={isSearching}>
                        <Form fluid formValue={searchModel} onChange={setSearchModel} onSubmit={submitSearch} >
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>LOLA #</b></ControlLabel>
                                        <FormControl
                                            name="lolaNumber"
                                            placeholder="Enter LOLA #"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>First Name</b></ControlLabel>
                                        <FormControl
                                            name="firstName"
                                            placeholder="Enter Name"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Last Name</b></ControlLabel>
                                        <FormControl
                                            name="lastName"
                                            placeholder="Enter Name"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Organization Name</b></ControlLabel>
                                        <FormControl
                                            name="firmName"
                                            placeholder="Enter Name"
                                        />
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Credential Type</b></ControlLabel>
                                        <FormControl
                                            name="credentialType"
                                            block="true"
                                            data={credType}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            placeholder="Select Type"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Credential #</b></ControlLabel>
                                        <FormControl
                                            accepter={AutoComplete}
                                            block="true"
                                            data={creds}
                                            name="credentialNumber"
                                            placeholder="Credential #"
                                            onChange={updateCreds}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Credential Status</b></ControlLabel>
                                        <FormControl
                                            name="credentialStatus"
                                            block="true"
                                            data={credStaus}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            placeholder="Select Status"
                                            onChange={handleCredentialStatusChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Credential Status Reason</b></ControlLabel>
                                        <FormControl
                                            name="credentialStatusReason"
                                            block="true"
                                            data={credReason}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            readOnly={!searchModel.credentialStatus}
                                            placeholder="Select Status Reason"
                                        />
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Email</b></ControlLabel>
                                        <FormControl
                                            name="email"
                                            placeholder="Enter Email"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>SSN/FEIN</b></ControlLabel>
                                        <FormControl
                                            name="federalId"
                                            placeholder="Enter SSN/FEIN"
                                            type="text"
                                            onChange={feinSsnChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Date of Birth</b></ControlLabel>
                                        <FormControl
                                            type={inputType}
                                            name="dateOfBirth"
                                            onFocus={() => setInputType("date")}
                                            onBlur={(e) => { if (!e.target.value) { setInputType("text") } }}
                                            placeholder="Date of Birth"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="phone"
                                            format="(###) ###-####"
                                            mask="_"
                                            placeholder="Enter Phone"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <ControlLabel><b>State/Province/Region</b></ControlLabel>
                                        <FormControl
                                            name="stateProvince"
                                            placeholder="State/Province/Region"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <ControlLabel><b>Postal Code</b></ControlLabel>
                                        <FormControl
                                            name="postalCode"
                                            placeholder="Enter Postal Code"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <ControlLabel><b>Country</b></ControlLabel>
                                        <FormControl
                                            block="true"
                                            name="country"
                                            accepter={InputPicker}
                                            data={countries}
                                            placeholder="Select Country"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <ControlLabel><b>User Id</b></ControlLabel>
                                        <FormControl
                                            name="userId"
                                            placeholder="Enter UserId"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <ControlLabel><b>NCEES</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            format="##-###-##"
                                            mask="_"
                                            name="nceesNumber"
                                            placeholder="NCEES ID#"
                                            onChange={nceesChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                                <Button type="submit">Search</Button>
                            </div>
                        </Form>
                    </BlockUi>
                </Card.Body>
            </Card>

            <EntitySearchGrid gridData={gridData} setIsSearchingCallback={setIsSearching} />
        </Container>

    )
}

export default EntitySearchPage;