import axios from "axios";
import authHeader from '../../authheader.js'

const FIRMCREDENTIALURL = process.env.REACT_APP_APIURL + "/api/Firm";
const INDCREDENTIALURL = process.env.REACT_APP_APIURL + "/api/Individual";
const COMMENTURL = process.env.REACT_APP_APIURL + "/api/Comment";

const getFirmGeneralInfo = async (entityId) => {
    return axios.get(`${FIRMCREDENTIALURL}/contact`, { headers: authHeader(), params: { entityId } });
}
const postFirmGeneralInfo = async (data) => {
    return axios.post(`${FIRMCREDENTIALURL}/updatecontact`, data, { headers: authHeader() });;
}

const getFirmCredentialInfo = async (entityId) => {
    return axios.get(`${FIRMCREDENTIALURL}/credentials`, { headers: authHeader(), params: { entityId } });
}
const getFirmCredentialIds = async (entityId) => {
    return axios.get(`${FIRMCREDENTIALURL}/credentialids`, { headers: authHeader(), params: { entityId } });

}
const updateEfCredential = async (data) => {
    return axios.post(`${FIRMCREDENTIALURL}/updateefcredential`, data, { headers: authHeader() });;
}
const updateVfCredential = async (data) => {
    return axios.post(`${FIRMCREDENTIALURL}/updatevfcredential`, data, { headers: authHeader() });;
}
const updatePlsCredential = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updateplscredential`, data, { headers: authHeader() });;
}
const updatePeCredential = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updatepecredential`, data, { headers: authHeader() });;
}
const updateLsiCredential = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updatelsicredential`, data, { headers: authHeader() });;
}
const updateEiCredential = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updateeicredential`, data, { headers: authHeader() });;
}
const updateTpCredential = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updatetpcredential`, data, { headers: authHeader() });;
}
const updateCpdCredential = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updatecpdcredential`, data, { headers: authHeader() });;
}
const updateWgCredential = async (data) => {
    return axios.post(`${FIRMCREDENTIALURL}/updatewgcredential`, data, { headers: authHeader() });;
}
const activatefirmpendingcredential = async (credentialId) => {
    return axios.post(`${FIRMCREDENTIALURL}/activatependingcredential`, null, { headers: authHeader(), params: { credentialId } });;
}
const activateindpendingcredential = async (credentialId) => {
    return axios.post(`${INDCREDENTIALURL}/activatependingcredential`, null, { headers: authHeader(), params: { credentialId } });;
}

const getIndGeneralInfo = async (entityId) => {
    return axios.get(`${INDCREDENTIALURL}/contact`, { headers: authHeader(), params: { entityId } });
}
const postIndGeneralInfo = async (data) => {
    return axios.post(`${INDCREDENTIALURL}/updatecontact`, data, { headers: authHeader() });;
}


const createComment = async (comment, entityId) => {
    return axios.post(`${COMMENTURL}/createcomment`, null, { headers: authHeader(), params: { comment, entityId } });;
}
const updateComment = async (commentId, comment) => {
    return axios.post(`${COMMENTURL}/updatecomment`, null, { headers: authHeader(), params: { commentId, comment} });;
}
const deleteComment = async (commentId) => {
    return axios.post(`${COMMENTURL}/deletecomment`, null, { headers: authHeader(), params: { commentId} });;
}
const getComments = async (entityId) => {
    return axios.get(`${COMMENTURL}/comments`, { headers: authHeader(), params: { entityId } });
}

const removePendingSupervisingProfessional = async (supervisingProfessionalId) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/SupervisingProfessional/removependingsupervisingprofessional`, null, { headers: authHeader(), params: { supervisingProfessionalId } });
}
const getFirmSupervisedBy = async (firmEntityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/SupervisingProfessional/individualsforfirm`, { headers: authHeader(), params: { firmEntityId } });
}
const getIndSupervises = async (individualEntityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/SupervisingProfessional/firmsforindividual`, { headers: authHeader(), params: { individualEntityId } });
}
const updateRelationshipStatus = async (relationshipId) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/Relationship/inactivate`, null, { headers: authHeader(), params: { relationshipId } });;
}


const getIndividualCredentialInfo = async (entityId) => {
    return axios.get(`${INDCREDENTIALURL}/credentials`, { headers: authHeader(), params: { entityId } });
}

const getWorkHistoryData = async (entityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/WorkHistory`, { headers: authHeader(), params: { entityId } });
}
const createWorkHistory = async (data) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/WorkHistory/create`, data, { headers: authHeader() });
}
const updateWorkHistory = async (data) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/WorkHistory/update`, data, { headers: authHeader() });
}
const deleteWorkHistory = async (data) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/WorkHistory/delete`, data, { headers: authHeader() });
}

const getCredHistory = async (entityId, credId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/CredentialHistory/getcredhistory`, { headers: authHeader(), params: { entityId, credId } });
}
const getEntityCredHistory = async (entityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/CredentialHistory/getentitycredhistory`, { headers: authHeader(), params: { entityId } });
}
const checkHasPending = async (entityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/CredentialHistory/doesentityhavependingapps`, { headers: authHeader(), params: { entityId } });
}
const getPendingCreds = async (entityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/CredentialHistory/getentitypendingapps`, { headers: authHeader(), params: { entityId } });
}
export default {
    getFirmGeneralInfo,
    postFirmGeneralInfo,
    getComments,
    createComment,
    getFirmCredentialInfo,
    getFirmCredentialIds,
    updateEfCredential,
    updateVfCredential,
    activatefirmpendingcredential,
    activateindpendingcredential,
    getFirmSupervisedBy,
    getIndSupervises,
    updateRelationshipStatus,
    getIndGeneralInfo,
    postIndGeneralInfo,
    getIndividualCredentialInfo,
    updatePlsCredential,
    updatePeCredential,
    updateEiCredential,
    updateLsiCredential,
    updateTpCredential,
    updateCpdCredential,
    updateWgCredential,
    getWorkHistoryData,
    updateWorkHistory,
    createWorkHistory,
    deleteWorkHistory,
    getCredHistory,
    getEntityCredHistory,
    checkHasPending,
    getPendingCreds,
    removePendingSupervisingProfessional,
    updateComment,
    deleteComment
}