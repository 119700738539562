import axios from "axios";
import authHeader from '../../../authheader'

const VFREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/FirmVfReinstatement";

const getUnlicensedActivity = (reinstatementId) => {
    return axios.get(`${VFREINSTATEMENTURL}/unlicensedActivity`, { headers: authHeader(), params: { reinstatementId } });
}
const postUnlicensedActivity = (data) => {
    return axios.post(`${VFREINSTATEMENTURL}/unlicensedActivity`, data, { headers: authHeader() });
}
export default {
    getUnlicensedActivity,
    postUnlicensedActivity
}