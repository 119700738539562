import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import seal from '../../images/LAPELS-LOLA-Logo.png'
import { FormGroup, Radio, RadioGroup, Form, FormControl, Input, Schema, AutoComplete } from 'rsuite';
import AuthService from "../../services/auth.service"
import { getFirmCredential } from '../../services/Shared/codeTable.service';
import Swal from 'sweetalert2';
function ForgotUserId() {
    const [inputType, setInputType] = useState("text");
    const [showAccountRecoverEmail, setShowAccountRecoverEmail] = useState(false);
    const { push } = useHistory();
    const [firmCreds, setFirmCreds] = useState();
    const updateCreds = (value) => {
        getFirmCredential(value).then(setFirmCreds);
    }
    const [state, setState] = useState({
        isIndividual: "",
        email: "",
        dateOfBirth: null,
        credential: ''
    })
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        dateOfBirth: StringType().isRequired('This field is required.'),
        isIndividual: BooleanType().isRequired('This field is required.'),
        email: StringType().isRequired('This field is required.'),
        credential: StringType().isRequired('This field is required.').pattern(/^\(.*\.[0-9]{7}\).*$/, "Please select from the dropdown list."),
    })
    const handleSubmitClick = (validation, event) => {
        var hasErrors = false;
        var validationObject = model.check(state);
        for (const key in validationObject) {
            //make sure that ind or firm was chosen
            if (validationObject[key].hasError && key.includes('isIndividual')) {
                hasErrors = true;
                return;
            }
            //individaul validation
            else if (validationObject[key].hasError && !key.includes("credential") && state.isIndividual === true) {
                hasErrors = true;
                return;
            }
            //firm validation
            else if (validationObject[key].hasError && !key.includes("dateOfBirth") && state.isIndividual === false) {
                hasErrors = true;
                return;
            }
        }
        if (!hasErrors) {
            if (state.isIndividual) {
                AuthService.forgotIndividualUserId(state.email, new Date(state.dateOfBirth).toISOString()).then((response) => {
                    if (response.data.isSuccess) {
                        push("/forgotuseridsuccess");
                    }
                    else {
                        const toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        toast.fire({
                            icon: 'error',
                            title: "Date of birth does not match email. Please try again."
                        });

                        setShowAccountRecoverEmail(true);
                    }
                }, (error) => {
                })
            }
            else {
                AuthService.forgotFirmUserId(state.email, state.credential).then((response) => {
                    if (response.data.isSuccess) {
                        push("/forgotuseridsuccess");
                    }
                    else {
                        const toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        toast.fire({
                            icon: 'error',
                            title: "License Number does not match email. Please try again."
                        });

                        setShowAccountRecoverEmail(true);
                    }
                }, (error) => {
                })
            }

        }
    }
    const imageStyle = {
        height: '10rem',
        width: '10rem',
        margin: 'auto'
    };
    const centerText = {
        textAlign: 'center'
    }
    const buttonStyle = {
        width: "100%"
    }

    return (
        <div className="loginPage">
            <Card style={{ width: '25rem', paddingTop: '1rem' }}>
                <Card.Img variant="top" src={seal} alt="Lapels Seal" style={imageStyle} />
                <Card.Body>
                    <Card.Title style={centerText}>Forgot your User Id? Enter email to recover your User Id.</Card.Title>
                    <Form fluid model={model} formValue={state} onChange={state => setState(state)} onSubmit={handleSubmitClick}>
                        <Row className="text-center mb-3">
                            <Col>
                                <FormGroup>
                                    <FormControl name="isIndividual" inline accepter={RadioGroup}>
                                        <Radio value={true}>Individual</Radio>
                                        <Radio value={false}>Firm</Radio>
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        {state.isIndividual !== '' && state.isIndividual &&
                            <>
                                <FormGroup>
                                    <FormControl
                                        accepter={Input}
                                        placeholder="Email"
                                        name="email"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControl
                                        type={inputType}
                                        name="dateOfBirth"
                                        onFocus={() => setInputType("date")}
                                        onBlur={(e) => { if (!e.target.value) { setInputType("text") } }}
                                        placeholder="Date of Birth"
                                    />
                                </FormGroup>
                            </>
                        }
                        {state.isIndividual !== '' && !state.isIndividual &&
                            <>
                                <FormGroup>
                                    <FormControl
                                        accepter={Input}
                                        placeholder="Email"
                                        name="email"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControl
                                        accepter={AutoComplete}
                                        block
                                        data={firmCreds}
                                        name="credential"
                                        placeholder="License #"
                                        onChange={updateCreds}
                                    />
                                </FormGroup>
                            </>
                        }
                        <Button
                            variant="primary"
                            type="submit"
                            style={buttonStyle}>
                            Send
                        </Button>
                    </Form>
                    <br />
                    {showAccountRecoverEmail == true &&
                        <div className="loginFooter centerText">
                            <p><b>Still can't log in?</b> Please email <a href="mailto:AccountRecovery@lapels.com">AccountRecovery@lapels.com</a></p>
                        </div>}
                    <div className="text-center mt-2">
                        <Card.Link as={Link} to="/login">Back to Login</Card.Link>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ForgotUserId;