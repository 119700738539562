import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Table } from 'rsuite';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../../Shared/Grid/GenericPagination';
import { sort_by } from '../../../Shared/HelperFunctions';
import PaymentInfoModal from '../../Shared/PaymentInfoModal';

// requires gridData

function PaymentSearchGrid(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [show, setShow] = useState();
    const [sortColumn, setSortColumn] = useState("paymentId");
    const [sortType, setSortType] = useState("desc");
    const [gridData, setGridData] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setGridData(props.gridData)
        setHasLoaded(true);
        props.setIsSearchingCallback(false)
        setPage(1)
    }, [props.gridData])

    //const navigateToEntity = (rowData) => {
    //    if (rowData.entityType.id == 1)
    //        push("/internal/indCredential?entityId=" + rowData.entityId);
    //    else if (rowData.entityType.id == 2)
    //        push("/internal/firmCredential?entityId=" + rowData.entityId);
    //    else
    //        Swal.fire("Something went wrong")
    //}

    const getData = () => {
        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    const openModal = (paymentId) => {
        setPaymentId(paymentId);
        setShow(true);
    }

    const filteredData = getData();
    return (
        <div className="searchListing">
            <PaymentInfoModal paymentId={paymentId} show={show} onHide={() => setShow(false)} />
            <Card className="mb-3">
                <Card.Header><h6>Search Results</h6></Card.Header>
                <Card.Body>
                    {hasLoaded ?
                        <>
                            <Table wordWrap autoHeight data={filteredData}
                                onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                                sortColumn={sortColumn}
                                sortType={sortType}                            >
                                <Table.Column width={100}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (
                                            <Button onClick={() => openModal(rowData.paymentId)}>View</Button>
                                        )}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Payment ID</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="paymentId" >{rowData => (rowData.paymentId)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Invoice ID</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="invoiceId" >{rowData => (rowData.invoiceId)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Payment Type</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="paymentType">{rowData => (rowData.paymentType)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Payment Date Created</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="dateCrte">{rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={2} sortable>
                                    <Table.HeaderCell>
                                        <b>Contact</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="formattedName">{rowData => (`${rowData.formattedName} ${rowData.formattedLicenseNumber ? "| " + rowData.formattedLicenseNumber : ""} | ${rowData.lolaNumber}`)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Remitter</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="remitter">{rowData => (rowData.remitter)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Amount</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="amount">{rowData => (rowData.amount ? rowData.amount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) : rowData.amount == 0 ? '$0.00' : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Available</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="availableAmount">{rowData => (rowData.availableAmount ? rowData.availableAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) : rowData.availableAmount == 0 ? '$0.00' : '')}</Table.Cell>
                                </Table.Column>
                            </Table>
                            <GenericPagination dataLength={gridData.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                        </> : <LoadingSpinner />
                    }
                </Card.Body>
            </Card>
        </div>
    )
}



export default PaymentSearchGrid;