import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getResidency = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/residency`, { headers: authHeader(), params: { applicationId } });
}
const postResidency = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/residency`, data, { headers: authHeader() });
}
export default {
    getResidency,
    postResidency
}