import React, { useState } from 'react';
import { Badge, Button, Card, Col, Container, FormGroup, Row } from "react-bootstrap";
import { FormControl, Uploader } from 'rsuite';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';
import RedStar from "../GenericComponents/RedStar";
import GenericAttachmentGrid from '../Grid/GenericAttachmentGrid';
import YesNoRadioOption from '../RadioButton/YesNoRadioOption';
import attachmentService from '../../../services/Shared/attachment.service';
import DocumentUpload from '../Upload/DocumentUpload';
function WavierCertificate(props) {
    const waiverTitle = (parentTypeId) => {
        switch (parentTypeId) {
            case 1: // Application
                return "Waiver/Certificate of Authority Information";
            case 2: // Reinstatement
                return "Certificate of Authority/Good Standing Information";
            case 3: // Renewal
                return "Certificate of Authority Information"
            default: return "";
        }
    }
    const uploadTitle = (parentTypeId) => {
        switch (parentTypeId) {
            case 1: // Application
                return "Please select the document type from the dropdown list below to upload your Certificate of Authority and Waiver Request (if needed) into the document window";
            case 2: // Reinstatement
                return "Please upload your Certificate of Good Standing or a Certificate of Authority from the Louisiana Secretary of State into the document window below.";
            case 3: // Renewal
                return "Please upload your Certificate of Good Standing or a Certificate of Authority from the Louisiana Secretary of State";
            default: return "";
        }
    }
    return (
        <div className="vfAppWaiverCertificate">
            <Card>
                <Card.Header><h6>{waiverTitle(props.parentTypeId)}</h6></Card.Header>
                <Card.Body>
                    {props.isApp && <>
                        <p>
                            LAC Title 46:LXI§2301(b)(1) A firm which has in its title the word engineering or surveying or any derivative thereof shall be construed
                            to be offering to provide professional services and therefore-must be licensed with the board before doing business in the state of Louisiana,
                            unless it has in its title modifying or explanatory words which would, in their ordinary meaning, negate the inference of the professional practice of
                            engineering or land surveying.
                        </p>
                        <hr />
                        <p><RedStar />Does the firm have the word engineering or surveying or any derivative thereof offering to provide professional services?</p>
                        <br />
                        <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasWordOfferingProfessionalServices" />



                        {props.showAdditionalFields ?
                            <>
                                <Container>
                                    <Card className="bg-danger text-white" >
                                        <Card.Body>
                                            <Row>
                                                <Col xs={12} md={10} className="text-center mb-3">
                                                    <p><b>Wait!</b> An approval letter is needed from the LAPELS Board to complete this section. <br />Please provide the information
                                                        requested in the form and submit this form via email to <a style={{ color: 'white', textDecoration: 'underline' }} href={'mailto:cande@lapels.com'}>cande@lapels.com</a> to get approval</p>
                                                </Col>
                                                <Col md={2} className="d-flex justify-content-center">
                                                    <Button variant="light" onClick={() => { window.open("https://www.lapels.com/docs/Applications/firm/firm_application_waiver_request.pdf") }}>Download Form</Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Container>
                            </>
                            :
                            null}
                        <hr />
                    </>
                    }
                    <DocumentUpload
                        isUserEditable={!props.isReadOnly}
                        credentialTypeId={props.credentialTypeId}
                        sectionId={props.sectionId}
                        appTypeId={props.appTypeId}
                        parentId={props.sectionDataId}
                        parentTypeId={props.parentTypeId}
                        entityId={props.entityId}
                        fileUploadTitle={uploadTitle(props.parentTypeId)}
                    />
                    <br />
                    <p>
                        “Pursuant to La. R.S. 37:689(B), within thirty (30) days of receiving from the Louisiana Secretary of State your Certificate of Authority/Incorporation to transact business in Louisiana, you must apply for,
                        and receive from the Board, a license prior to offering professional engineering or land surveying services in Louisiana.”
                        <br />
                        <br />
                        I certify that the information above is true and correct.
                    </p>
                    <hr />
                    <Row>
                        <Col sm={4}>
                            <h5>Electronic Signature of Applicant:<RedStar /></h5>
                            <span>(Type full name)</span>
                        </Col>
                        <Col sm={8}><FormControl readOnly={props.isReadOnly} name="electronicSignature" /></Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
export default WavierCertificate;