import React from 'react';
import Individual from './components/Public/Main/Individual'


import './css/leo.css'
import './css/brian.css'
import './css/blake.css'
import MainInternal from './components/Internal/Main/Main';
import PublicIndividual from './components/Public/Main/Individual';
import Main from './components/Public/Main/Main';
import reducer from './components/Shared/CustomReducer';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import withClearCache from "./ClearCache";
import axios from "axios";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />
}

function MainApp(props) {
    axios.interceptors.response.use(function (res) {
        return res;
    },
        (err) => {
            if (err.response?.status == 503 || err.message == "Network Error") {
                //handle error of type
                fetch("/meta.json?ms=" + Date.now())
                    .then((response) => { return response.json(); })
                    .then((meta) => {
                        if (!meta || meta.offline == true)
                        {
                            window.location.href = "https://www.lapels.com/Sched_Maint.html";
                        }
                    });
            }
            return Promise.reject(err);
        });
    const persistConfig = {
        key: 'root',
        storage: storageSession,
        blacklist: ['userInfo']
    }
    const userConfig = {
        key: 'user',
        storage
    }
    const rootReducer = combineReducers({
        userInfo: persistReducer(userConfig, reducer.userInfo),
        currentApplicationId: reducer.currentApplicationId,
        currentApplicationEntityId: reducer.currentApplicationEntityId,
        currentApplicationTypeId: reducer.currentApplicationTypeId,
        currentRoutes: reducer.currentRoutes,
        refreshToken: reducer.refreshToken,
        currentInvoiceId: reducer.currentInvoiceId,
        refreshRecentQueue: reducer.refreshRecentQueue,
        refreshRecentContacts: reducer.refreshRecentContacts
    });

    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(persistedReducer);
    const persistor = persistStore(store);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Main />
            </PersistGate>
        </Provider>
    );
}

export default App;
