import axios from "axios";
import authHeader from '../../../authheader'

const PLSAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsApplication";
const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getExperienceRecords = (applicationId) => {
    return axios.get(`${PLSAPPLICATIONURL}/experiencerecords`, { headers: authHeader(), params: { applicationId } });
}
const postExperienceRecord = (data) => {
    return axios.post(`${PLSAPPLICATIONURL}/experiencerecord`, data, { headers: authHeader() });
}
const sendVerifierEmail = (experienceId) => {
    return axios.post(`${PLSAPPLICATIONURL}/sendexperienceverificationemail`, null, { headers: authHeader(), params: { experienceId } });
}
const peGetExperienceRecords = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/experiencerecords`, { headers: authHeader(), params: { applicationId } });
}
const pePostExperienceRecord = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/experiencerecord`, data, { headers: authHeader() });
}
const peSendVerifierEmail = (experienceId) => {
    return axios.post(`${PEAPPLICATIONURL}/sendexperienceverificationemail`, null, { headers: authHeader(), params: { experienceId } });
}
export default {
    getExperienceRecords,
    postExperienceRecord,
    sendVerifierEmail,
    peGetExperienceRecords,
    pePostExperienceRecord,
    peSendVerifierEmail
}