import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import BlockUi from 'react-block-ui';
import { ControlLabel, Form, Icon, Table, FormControl, FormGroup, SelectPicker } from 'rsuite';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import batchService from '../../../services/Internal/Revenue/batch.service';
import { useHistory } from 'react-router-dom';
import { getBatchStatusType } from '../../../services/Shared/codeTable.service';
import entityService from '../../../services/Internal/Staff/entity.service';
import { useSelector } from 'react-redux';
import { sort_by } from '../../Shared/HelperFunctions';

function BatchSearchPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [statusType, setStatusType] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [searchModel, setSearchModel] = useState({
        beginDate: '',
        endDate: '',
        batchId: '',
        user: '',
        status: 'Open'
    })
    const formattedName = useSelector(x => x.userInfo.FormattedName);
    const [searchResults, setSearchResults] = useState([]);
    const { push } = useHistory();
    useEffect(() => {
        getBatchStatusType().then(setStatusType);
        entityService.plsGetStaffAndAdmin(false).then((response) => {
            var staffList = response.data.map(
                employee => {
                    return { "label": `${employee.formattedName}`, "value": `${employee.formattedName}` }
                }).filter((item, index, self) =>
                    index === self.findIndex((i) => (
                        i.value === item.value
                    ))
                );
            setEmployeeList(staffList);
        });
        // Initial search
        batchService.searchBatch({
            beginDate: null,
            endDate: null,
            batchId: null,
            user: null,
            status: 'Open'
        }).then((response) => {
            if (response.data.length > 0)
                setSearchResults(response.data);
            setHasLoaded(true);
        });
    }, [])

    const [hasLoaded, setHasLoaded] = useState(false);
    const [sortColumn, setSortColumn] = useState("batchDate");
    const [sortType, setSortType] = useState("desc");
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const clearSearchModel = () => {
        setSearchModel({
            beginDate: '',
            endDate: '',
            batchId: '',
            user: '',
            status: 'Open'
        })
    }
    const submitSearch = () => {
        var dataToTransmit = {
            beginDate: searchModel.beginDate ? searchModel.beginDate : null,
            endDate: searchModel.endDate ? searchModel.endDate : null,
            batchId: searchModel.batchId > 0 ? searchModel.batchId : null,
            user: searchModel.user ? searchModel.user : null,
            status: searchModel.status
        };
        setHasLoaded(false);
        batchService.searchBatch(dataToTransmit).then((response) => {
            setSearchResults(response.data);
            setHasLoaded(true);
        });
    }

    const getData = () => {
        var sortedData = searchResults.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    return (
        <>
            <Card className="w-100 mb-3">
                <Card.Body>
                    <BlockUi blocking={isSearching}>
                        <Form fluid formValue={searchModel} onChange={setSearchModel} onSubmit={submitSearch} >
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Batch Date Range</b></ControlLabel>
                                        <FormControl
                                            name="beginDate"
                                            type="date"
                                            block="true"
                                        />
                                    </FormGroup>
                                </Col>
                                <span style={{ marginTop: '2rem' }}>to</span>
                                <Col>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <FormControl
                                        name="endDate"
                                        type="date"
                                        block="true"
                                    />
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Batch ID</b></ControlLabel>
                                        <FormControl
                                            name="batchId"
                                            placeholder="Batch ID"
                                            type="number"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>User</b></ControlLabel>
                                        <FormControl
                                            name="user"
                                            placeholder="Select Type"
                                            block="true"
                                            data={employeeList}
                                            accepter={SelectPicker}
                                            searchable={false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <ControlLabel><b>Status</b></ControlLabel>
                                    <FormControl
                                        name="status"
                                        placeholder="Select Status"
                                        block="true"
                                        data={statusType}
                                        accepter={SelectPicker}
                                        searchable={false}
                                    />
                                </Col>
                            </Row>

                            <hr />
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                                <Button type="submit">Search</Button>
                            </div>
                        </Form>
                    </BlockUi>
                </Card.Body>
            </Card >

            <Card className="w-100 mb-3">
                <Card.Header>
                    <h6>Search Results</h6>
                </Card.Header>
                <Card.Body>
                    <br />
                    {hasLoaded ?
                        <>
                            <Table
                                onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                wordWrap autoHeight data={filteredData}>
                                <Table.Column width={150}>
                                    <Table.HeaderCell>
                                        <b>Action(s)</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">
                                        {rowData => {
                                            function viewBatch() {
                                                push("/internal/revenue/createbatch?batchId=" + rowData.id);
                                            }
                                            return (
                                                (rowData.batchStatus == "Closed" || (rowData.batchOwnerName == formattedName)) && <Button onClick={viewBatch} variant="primary">View</Button>
                                            )
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Batch Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="batchDate">{rowData => (rowData.batchDate ? new Date(rowData.batchDate).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Batch ID</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">{rowData => (rowData.id)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>User</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="batchOwnerName">{rowData => (rowData.batchOwnerName)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Item Count</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="batchItemCount">{rowData => (rowData.batchItemCount)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Amount</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="batchAmount">{rowData => (rowData.batchAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }))}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Status</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="batchStatus">{rowData => (rowData.batchStatus)}</Table.Cell>
                                </Table.Column>
                            </Table>
                            <GenericPagination dataLength={searchResults.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                        </>
                        : <LoadingSpinner />
                    }
                </Card.Body>
            </Card >
        </>
    )
}


export default BatchSearchPage;