import React, { useState } from 'react'
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import { Container } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Acknowledgement from '../../../../../Shared/Card/Acknowledgement';
import Information from '../../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import { useSelector } from 'react-redux';
import renewalService from '../../../../../../services/Public/Renewal/PLS/plsRenewal.service';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import Issues from '../../../../../Shared/Card/Issues';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';

function AcknowledgementPage() {
    const [hasLoaded, setHasLoaded] = useState(false)
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const sectionId = 7;
    const routes = useSelector(x => x.currentRoutes);
    const renewalId = useSelector(x => x.currentApplicationId);
    const [formModel, setFormModel] = useState({})
    useEffect(() => {
        renewalService.plsGetSection(renewalId, sectionId).then((response) => {
            setFormModel(response.data);
            setHasLoaded(true);
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!formModel.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }
    const formSave = (event) => {
        submitData();
    }
    const submitData = (isForSubmit) => {
        renewalService.plsPostSection(renewalId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <b>Professional Land Surveyor Renewal</b>
                <h4 className="mb-3">Acknowledgement</h4>
                <Information content={GetRenewalInformationContent("acknowledgement")} className="mb-3" />
                <br />
                {hasLoaded ?
                    <Form model={model} readOnly={!formModel.isUserEditable} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <Acknowledgement appType="renewal" />
                        <br />
                        <Issues isUserEditable={true} type={3} sectionId={formModel.id} />
                        <FormActionButtons isReadOnly={!formModel.isUserEditable} backToSectionLink="/individual/plsRenewal/sections" handleSave={formSave} />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default AcknowledgementPage