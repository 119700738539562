import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import BoardApproval from './BoardApproval';
import BoardApprovalModal from './BoardApprovalModal';
import reinstatementService from '../../../../../services/Internal/Reinstatement/reinstatement.service';
import { useSelector } from 'react-redux';
import boardApprovalService from '../../../../../services/Internal/Reinstatement/PLS/boardApproval.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function BoardApprovalPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const entityId = useSelector(x => x.currentApplicationEntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const sectionId = 11;
    const [modalShow, setModalShow] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [gridData, setGridData] = useState([]);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Board/Staff Recommendations"
        }
    );

    const { BooleanType, StringType } = Schema.Types;
    const model = Schema.Model({
        //education: StringType().isRequired('This field is required'),
        boardApproval: BooleanType().isRequired('This field is required'),
        examsVerifiedInMyNcees: BooleanType().isRequired('This field is required')
    })

    const [formModel, setFormModel] = useState({
        reinstatementId: appId,
        boardApproval: null,
        dateCrte: null,
        deleted: false,
        entityId: entityId,
        examsVerifiedInMyNcees: null,
        education: "",
        additionalComments: "",
        id: 0,
        userCrte: "",
        files: []

    })
    const [boardApprovalSectionId, setBoardApprovalSectionId] = useState();
    const [modalData, setModalData] = useState({
        comments: "",
        dateCrte: new Date(),
        dateReceived: new Date(),
        dateSent: new Date(),
        deleted: false,
        id: 0,
        userCrte: "",
        boardMemberDecision: null,
        boardMember: {
            appointmentYear: 0,
            credentialSuffix: "",
            expirationYear: 0,
            firstName: "",
            id: 0,
            lastName: "",
            middleName: "",
            representation: "",
            suffix: ""
        },
        entityId: entityId,
        reinstatementId: appId,
        boardStaffApprovalId: 0,
        files: [],
        filesForDisplay: [],
        filesLoading: false
    })

    useEffect(() => {
        reinstatementService.plsGetSection(appId, sectionId).then((response) => {
            boardApprovalService.getBoardRecommendations(appId).then((data) => {
                setFormModel(data.data);
                setModalData({ ...modalData, boardStaffApprovalId: data.data.id })
                updateGrid()
                setBoardApprovalSectionId(response.data.id);
                setIsComplete(response.data.staffReviewComplete);
                reinstatementService.getIndividualReinstatement(appId).then((reinstatementData) => {
                    setIsUserEditable(reinstatementData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: reinstatementData.data?.credential?.formattedLicenseNumber,
                                applicationDate: reinstatementData.data?.dateSubmitted
                            })
                        setHasLoaded(true);

                    });
                });
            })
        })
    }, [])

    const updateGrid = () => {
        boardApprovalService.getBoardResponses(appId).then((vals) => {
            if (vals.data?.length)
                setGridData(
                    vals.data.map((value, i) => { return { ...value.boardMemberResponse, files: [...value.attachments] } }))
        })
    }
    const submitData = (val) => {
        if (val) {
            boardApprovalService.postBoardRecommendation(formModel).then((val) => {
                if (val.data) {
                    reinstatementService.plsMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                        if (data.data)
                            Swal.fire("Saved!", "You have successfully saved.", 'success');
                        //push("/internal/plsApp/sections")
                    })
                }
            })
        }
    }

    const openAddEditModal = (values) => {
        if (values.id) {
            setModalData({ ...values, dateReceived: values.dateReceived != null ? dayjs(values.dateReceived).format('YYYY-MM-DD') : null, dateSent: values.dateSent != null ? dayjs(values.dateSent).format('YYYY-MM-DD') : null })
        }
        else {
            setModalData({
                comments: "",
                dateCrte: new Date(),
                dateReceived: null,
                dateSent: null,
                deleted: false,
                id: 0,
                userCrte: "",
                boardMemberDecision: null,
                boardMember: null,
                entityId: entityId,
                reinstatementId: appId,
                boardStaffApprovalId: formModel.id,
                files: [],
                filesForDisplay: []

            })
        }
        setModalShow(true);
    }

    return (
        <div>
            <Container fluid>
                <BoardApprovalModal updateGrid={updateGrid} modalData={modalData} show={modalShow} onHide={() => setModalShow(false)} />
                <Form fluid formValue={formModel} model={model} onChange={formModel => setFormModel(formModel)} onSubmit={submitData}>
                    <FormEntityInformation {...entityInfo} />
                    {hasLoaded ? <>
                        <BoardApproval
                            isUserEditable={isUserEditable}
                            openModal={openAddEditModal}
                            setGridData={setGridData}
                            gridData={gridData}
                            sectionId={sectionId}
                            sectionDataId={boardApprovalSectionId}
                            entityId={entityId}
                        />
                        <Container fluid>
                            <Row className="mt-3">
                                <Col style={{ display: "flex", alignItems: "center" }}>
                                    <label className="switch">
                                        <input id="completeSectionCheckbox" checked={isComplete} onChange={() => { if (isUserEditable) setIsComplete(!isComplete); }} type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>&nbsp;
                                    <label htmlFor="completeSectionCheckbox">
                                        <b>Mark Section as Complete</b>
                                    </label>
                                    &nbsp;&nbsp;
                                    <Button name="backToSections" as={Link} to="/internal/plsreinstatement/sections" variant="primary" className="mr-2">Back to Sections</Button>
                                    {isUserEditable &&
                                        <Button name="save" type="submit" variant="danger" className="mr-2">Save</Button>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    );
}
export default BoardApprovalPage