import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import { useHistory } from 'react-router-dom';
import DisclosureAuthorization from '../../../../../Shared/Card/DisclosureAuthorization';
import Information from '../../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import { useSelector } from 'react-redux';
import reinstatementService from '../../../../../../services/Public/Reinstatement/PLS/plsReinstatement.service';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../../Shared/Card/Issues';
import dayjs from 'dayjs';

function DisclosurePage() {
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const [hasLoaded, setHasLoaded] = useState(false);
    const sectionId = 8;
    const routes = useSelector(x => x.currentRoutes);
    const [formModel, setFormModel] = useState({})
    useEffect(() => {
        reinstatementService.plsGetSection(reinstatementId, sectionId).then((response) => {
            setFormModel({
                ...response.data,
                dateOfBirth: response.data.dateOfBirth != null ? dayjs(response.data.dateOfBirth).format('YYYY-MM-DD') : null,
                dateSigned: response.data.dateSigned != null ? dayjs(response.data.dateSigned).format('YYYY-MM-DD') : null
            });
            setHasLoaded(true)
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!formModel.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }
    const formSave = (event) => {
        submitData();
    }
    const submitData = (isForSubmit) => {
        reinstatementService.plsPostSection(reinstatementId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
               push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div className="plsReinstatementDisclosureInfo">
            <Container fluid>
                    <strong>Professional Land Surveyor Reinstatement</strong>
                    <br />
                    <h4>Disclosure</h4>
                    <br />
                    <Information content={GetReinstatementInformationContent("disclosure")} />
                <br />
                { hasLoaded ?
                    <Form readOnly={ !formModel.isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <DisclosureAuthorization />
                        <br />
                        <Issues isUserEditable={true} type={2} sectionId={ formModel.id}/>
                        <FormActionButtons isReadOnly={!formModel.isUserEditable} backToSectionLink="/individual/plsReinstatement/sections" handleSave={formSave} />
                    </Form> : <LoadingSpinner/>
                }
            </Container>
        </div >
    )
}

export default DisclosurePage;