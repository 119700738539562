import axios from "axios";
import authHeader from '../../../authheader'

const eiGetSection = (applicationId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualEiApplication/section`, { headers: authHeader(), params: { applicationId, sectionId } });
}
const eiPostSection = (applicationId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualEiApplication/signsection`, null, { headers: authHeader(), params: { applicationId, sectionId, electronicSignature } });
}

const eiPostSectionHasMyNceesEducation = (applicationId, sectionId, hasMyNceesEducation) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualEiApplication/setsectionhasmynceeseducation`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation } });
}

const eiPostSectionHasExperience = (applicationId, sectionId, hasMyNceesExperience) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualEiApplication/setsectionhasmynceesexperience`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesExperience } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 11,
        sectionTitle: "Examination Verification in Louisiana or Other Jurisdictions",
        sectionContent: "This must be uploaded to your MyNCEES account.",
    },
    {
        id: 12,
        sectionTitle: "Recommendation",
        sectionContent: "You must be recommended for certification by a licensed Professional Engineer.",
    },
    {
        id: 7,
        sectionTitle: "Experience Record",
        sectionContent: "Include a GRAND TOTAL of all experience",
    },
    {
        id: 8,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 9,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    eiGetSection,
    eiPostSection,
    eiPostSectionHasMyNceesEducation,
    eiPostSectionHasExperience
}