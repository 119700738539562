import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Table } from 'rsuite';
import attachmentService from '../../../../services/Shared/attachment.service';
import cpdReqService from '../../../../services/Shared/cpdReq.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../../Shared/Grid/GenericPagination';

function CpdLockerCard(props) {
    const [displayLength, setDisplayLength] = useState(20);
    const [bulkDisplayLength, setBulkDisplayLength] = useState(20);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [bulkPage, setBulkPage] = useState(1);
    const [data, setData] = useState([])
    const [bulkData, setBulkData] = useState([])
    const getData = () => {

        return data.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const getBulkData = () => {

        return bulkData.filter((v, i) => {
            const start = bulkDisplayLength * (bulkPage - 1);
            const end = start + bulkDisplayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    const filteredBulkData = getBulkData();

    useEffect(() => {
        setHasLoaded(false);
        attachmentService.getFiles(props.entityId, 11).then((data) => {
            if (data.data) {
                var tempData = [];
                for (var i = 0; i < data.data.length; i++) {
                    tempData.push({
                        id: data.data[i].id,
                        timestamp: new Date(data.data[i].uploadDate).toLocaleDateString(),
                        file: data.data[i].fileName,
                        attachmentType: data.data[i].documentType,
                        fileLink: '',
                        hideDelete: data.data[i].isExternalUpload
                    });
                }
                setBulkData([
                    ...tempData
                ])
            }
        })
        cpdReqService.getCpd(props.entityId).then((response) => {
            if (response.data) {
                setData(response.data.map(courseRecord => {
                    var temp = courseRecord.courseRecord;
                    return {
                        area: temp.area,
                        classType: temp.classType,
                        date: temp.courseDate != null ? dayjs(temp.courseDate).format('YYYY-MM-DD') : null,
                        title: temp.courseTitle,
                        description: temp.description,
                        hours: temp.hours,
                        id: temp.id,
                        instructor: temp.instructor,
                        files: [...courseRecord.attachments]
                    }
                }));
            }
            setHasLoaded(true);
        })
    }, [])

    const openFile = (item) => {
        attachmentService.openFile(item.id);
    }





    return (
        <Card className="w-100">
            <Card.Header>
                <h5>CPD LOCKER</h5>
            </Card.Header>
            <Card.Body>
                {hasLoaded ? <>
                    <h6>Records Upload (Logs and Certificates)</h6>
                    <Table headerHeight={60} data={filteredBulkData} wordWrap autoHeight>
                        <Table.Column flexGrow={1} minwidth={100} verticalAlign="middle">
                            <Table.HeaderCell><b>Document</b></Table.HeaderCell>
                            <Table.Cell>
                                {rowData => {
                                    return (<>
                                        <Button onClick={() => { openFile(rowData) }} variant="link" size="sm">{rowData.file}</Button>
                                    </>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={0.5} verticalAlign="middle" >
                            <Table.HeaderCell><b>Date</b></Table.HeaderCell>
                            <Table.Cell>
                                {
                                    rowData => {
                                        return (<span>{new Date(rowData.timestamp).toLocaleDateString()}</span>)
                                    }
                                }
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                    <GenericPagination dataLength={bulkData.length} setPage={setBulkPage} setDisplayLength={setBulkDisplayLength} />
                    <br />
                    <h6>Individual Course Records</h6>
                    <Table headerHeight={60} data={filteredData} wordWrap autoHeight>
                        <Table.Column flexGrow={1} minwidth={100} verticalAlign="middle">
                            <Table.HeaderCell><b>Document</b></Table.HeaderCell>
                            <Table.Cell>
                                {rowData => {
                                    return (<>
                                        {rowData.files.map((item, i) => {
                                            return (
                                                <Row key={i} style={{ padding: '0' }}>
                                                    <Button onClick={() => { openFile(item) }} variant="link" size="sm">{item.fileName}</Button>
                                                </Row>
                                            )
                                        })}
                                    </>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={0.5} verticalAlign="middle" >
                            <Table.HeaderCell><b>Date</b></Table.HeaderCell>
                            <Table.Cell>
                                {
                                    rowData => {
                                        return (<span>{new Date(rowData.date).toLocaleDateString()}</span>)
                                    }
                                }
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={0.8} verticalAlign="middle">
                            <Table.HeaderCell><b>Course Title</b></Table.HeaderCell>
                            <Table.Cell dataKey="title" />
                        </Table.Column>
                        <Table.Column flexGrow={0.8} verticalAlign="middle">
                            <Table.HeaderCell><b>Area</b></Table.HeaderCell>
                            <Table.Cell>
                                {
                                    rowData => {
                                        return (<span>{rowData.area.area}</span>)
                                    }
                                }
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={0.5} verticalAlign="middle">
                            <Table.HeaderCell><b>Hours</b></Table.HeaderCell>
                            <Table.Cell dataKey="hours" />
                        </Table.Column>
                    </Table>
                    <GenericPagination dataLength={data.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                </> : <LoadingSpinner />}
            </Card.Body>
        </Card >
    )
}


export default CpdLockerCard;