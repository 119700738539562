import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, FormGroup, SelectPicker, Table } from 'rsuite';
import Swal from 'sweetalert2';
import queueService from '../../../services/Internal/Queue/queue.service';
import { getInternalStatuses } from '../../../services/Shared/codeTable.service';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import { sort_by } from '../../Shared/HelperFunctions';
import ReassignButton from './ReassignButton';

function InternalTaskInfo({ parentTypeId, parentId }) {
    const [gridData, setGridData] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [taskId, setTaskId] = useState();
    const [sortColumn, setSortColumn] = useState("dateSubmitted");
    const [sortType, setSortType] = useState("desc");
    const [displayLength, setDisplayLength] = useState(10);
    const [page, setPage] = useState(1);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [model, setModel] = useState({
        internalStatus: null,
        comments: ''
    })
    useEffect(() => {
        getInternalStatuses().then(setStatuses);
        queueService.getInternalTaskStatusAndComments(parentTypeId, parentId).then((response) => { setModel(response.data); });
        queueService.getTaskId(parentTypeId, parentId).then((response) => {
            setTaskId(response.data);
            refreshGrid(response.data);
            setHasLoaded(true);
        });

    }, [])

    const submitForm = () => {
        queueService.saveInternalTaskStatusAndComments(parentTypeId, parentId, model.internalStatus?.id, model.comments).then((response) => {
            if (response.data) {
                Swal.fire("Success!", 'You have saved your internal comments and status.', 'success');
            }
        })
    }

    const getData = () => {
        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const refreshInternalStatus = () => {
        queueService.getInternalTaskStatusAndComments(parentTypeId, parentId).then((response) => {
            setModel(response.data);
            refreshGrid();
        });
    }

    const refreshGrid = (initTaskId) => {
        setHasLoaded(false);
        queueService.getAssignmentHistory(initTaskId ?? taskId).then((response) => {
            setGridData(response.data);
        });
    }

    const filteredData = getData();

    return (
        <>
            <Form fluid formValue={model} onChange={setModel} onSubmit={submitForm}>
                <br />
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <ControlLabel className="text-left"><b>Comments</b></ControlLabel>
                            <FormControl
                                rows={3}
                                placeholder="Enter Comments"
                                componentClass="textarea"
                                name="comments" />
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <ControlLabel className="text-left"><b>Internal Status</b></ControlLabel>
                            <FormControl
                                block
                                accepter={SelectPicker}
                                searchable={false}
                                data={statuses}
                                name="internalStatus" />
                        </FormGroup>
                    </Col>
                    <Col sm={1}>
                        <FormGroup>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <Button type='submit' variant="danger">Save</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            wordWrap autoHeight data={filteredData}>
                            <Table.Column minWidth={100} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Assigned By</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="assignedBy" />
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Assigned To</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="assignedTo" />
                            </Table.Column>
                            <Table.Column minWidth={125} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Date</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="dateSubmitted">{rowData => (new Date(rowData.dateSubmitted).toLocaleDateString())}</Table.Cell>
                            </Table.Column>
                        </Table>
                        <GenericPagination page={page} dataLength={gridData.length} displayLength={displayLength} setDisplayLength={setDisplayLength} setPage={setPage} discludeAllLength={true} />
                    </Col>
                    <Col sm={1}>
                        <ReassignButton taskId={taskId} refreshInternalStatus={refreshInternalStatus} />
                    </Col>
                </Row>
                <br />
            </Form>
        </>
    );
}
export default InternalTaskInfo;