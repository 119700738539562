import React, { useState } from 'react'
import { Table } from 'rsuite'

function GenericPagination({ dataLength, displayLength, page, setDisplayLength, setPage, discludeAllLength }) {
    const [defaultDisplayLength, setDefaultDisplayLength] = useState(20);
    const [defaultPage, setDefaultPage] = useState(1);
    const handleChangePage = (dataKey) => {
        if (!page) {
            setDefaultPage(dataKey);
        }
        setPage(dataKey);
    }
    const handleChangeLength = (dataKey) => {
        if (!(page && displayLength)) {
            setDefaultPage(1);
            setDefaultDisplayLength(dataKey);
        }
        setPage(1);
        setDisplayLength(dataKey);
    }
    return (
        <Table.Pagination lengthMenu={discludeAllLength ?
            [
                {
                    value: 10,
                    label: 10
                },
                {
                    value: 20,
                    label: 20
                },
                {
                    value: 50,
                    label: 50
                }
            ]
            :
            [
                {
                    value: 10,
                    label: 10
                },
                {
                    value: 20,
                    label: 20
                },
                {
                    value: 50,
                    label: 50
                },
                {
                    value: dataLength,
                    label: "All"
                }
            ]
        }
            activePage={page ?? defaultPage}
            displayLength={displayLength ?? defaultDisplayLength}
            total={dataLength}
            onChangePage={handleChangePage}
            onChangeLength={handleChangeLength} />
    )
}
export default GenericPagination;