import React from 'react'
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, FormGroup, Schema, InputPicker } from 'rsuite';
import paymentService from '../../../../services/Internal/Revenue/payment.service';
import { getFeeDefinitions } from '../../../../services/Shared/codeTable.service';
import invoiceService from '../../../../services/Shared/invoice.service';
import CurrencyMask from '../../../Shared/GenericComponents/CurrencyMask';
import RedStar from '../../../Shared/GenericComponents/RedStar';

function AddLineItem({ show, onHide, entityId, invoiceId }) {
    const [lineItemModel, setLineItemModel] = useState({
        feeDefinition: null,
        amount: 0
    });
    const [feeDefinitions, setFeeDefinitions] = useState();

    const { NumberType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        feeDefinition: ObjectType().isRequired('This field is required.'),
        amount: NumberType().isRequired('This field is required.').min(.01, "Please enter an amount greater than 0"),
    })

    useState(() => {
        getFeeDefinitions().then(setFeeDefinitions);
    }, [])


    const onFeeDefinitionSelect = (value) => {
        setLineItemModel({ feeDefinition: { ...value }, amount: value.amount })
    }
    const formSubmit = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                amount: lineItemModel.amount,
                entityId: entityId,
                feeDefinition: lineItemModel.feeDefinition,
                invoiceId: invoiceId,
                parentId: entityId
            }
            invoiceService.addLineItem(dataToTransmit).then((responsse) => {
                if (responsse.data) {
                    onHide(true);
                }
            })
        }
    }


    return (
        <Modal
            show={show}
            onHide={onHide}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Line Item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form fluid
                    id="addLineItemForm"
                    model={model}
                    formValue={lineItemModel}
                    onChange={values => setLineItemModel(values)}
                    onSubmit={formSubmit}>
                    <FormGroup>
                        <ControlLabel>
                            <strong>
                                GL Account&nbsp;<RedStar />
                            </strong>
                        </ControlLabel>
                        <FormControl
                            block="true"
                            name="feeDefinition"
                            placeholder="-- Select --"
                            accepter={InputPicker}
                            searchable={true}
                            data={feeDefinitions}
                            onChange={onFeeDefinitionSelect}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <strong>
                                Amount&nbsp;<RedStar />
                            </strong>
                        </ControlLabel>
                        <FormControl
                            name="amount"
                            accepter={CurrencyMask}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button form="addLineItemForm" type="submit">Add</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddLineItem;