import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import generalEntityInfoService from '../../../../services/Internal/generalEntityInfo.service';
function EntityInformationPage({ entityId }) {
    const [generalInfoModel, setGeneralInfoModel] = useState({
        name: '',
        lolaNumber: '',
        creds: ''
    })
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        generalEntityInfoService.getEntityInfo(entityId).then((response) => {
            if (response.data.entityInfo) {
                setGeneralInfoModel({
                    name: response.data.entityInfo.formattedName,
                    lolaNumber: response.data.entityInfo.lolaNumber,
                    creds: response.data.credentials.map((item, index) => (item.formattedLicenseNumber)).join(", ")
                })
            }
            setHasLoaded(true);
        });
    }, [])


    return (
        <>
            {hasLoaded &&
                <Row>
                    <Col>
                        <span>Name</span>
                        <br />
                        <h6>{generalInfoModel.name}</h6>
                    </Col>
                    <Col>
                        <span>LOLA #</span>
                        <br />
                        <h6>{generalInfoModel.lolaNumber}</h6>
                    </Col>
                    <Col>
                        <span>Credential #(s)</span>
                        <br />
                        <h6>{generalInfoModel.creds}</h6>
                    </Col>
                </Row>
            }
        </>
    )
}
export default EntityInformationPage;