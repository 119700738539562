import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import recentContactService from "../../../services/Internal/Recent/recentContacts.service"
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';

function RecentContacts() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [recentContactList, setRecentContactList] = useState([])
    const updateSelector = useSelector(x => x.refreshRecentContacts);
    const updateContacts = () => {
        setHasLoaded(false);
        recentContactService.getRecentContacts().then((response) => {
            if (response.data)
                setRecentContactList(response.data)
            setHasLoaded(true);
        })
    }

    useEffect(() => {
        updateContacts();
        function checkForUpdate() {
            const item = localStorage.getItem('updateRecentContacts')

            if (item) {
                updateContacts();
            }
        }

        window.addEventListener('storage', checkForUpdate)

        return () => {
            window.removeEventListener('storage', checkForUpdate)
        }
    }, [])
    useEffect(() => {
        updateContacts();
    }, [updateSelector])
    return (
        <>
            <hr />
            <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
                <a href="internal/allRecentContacts" target="_blank" style={{ color: "black" }}><h4 style={{ paddingBottom: "5px" }}>Recent Contacts</h4></a>
                {hasLoaded ?
                    recentContactList.map((val, i) => {
                        return (<>
                            <h6 style={{ paddingBottom: "12px" }}><a style={{ fontSize: "15px" }} href={(val.isFirm ? "internal/firmCredential?entityId=" + val.entityId : "internal/indCredential?entityId=" + val.entityId)}>{val.entityName}</a></h6>
                        </>)
                    })
                    : <LoadingSpinner />}
            </div>
        </>);
}

export default RecentContacts;