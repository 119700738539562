import { useState, useEffect } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import React from 'react'
import { ControlLabel, Schema, Form, FormControl, FormGroup, InputPicker, Uploader } from 'rsuite'
import RedStar from '../../../../Shared/GenericComponents/RedStar';
import GenericAttachmentGrid from '../../../../Shared/Grid/GenericAttachmentGrid';
import { getBoardMembers } from '../../../../../services/Shared/codeTable.service';
import boardApprovalService from '../../../../../services/Internal/Reinstatement/LSI/boardApproval.service';
import attachmentService from '../../../../../services/Shared/attachment.service';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function BoardApprovalModal(props) {

    const { ObjectType, DateType } = Schema.Types;
    const [blockUi, setBlockUi] = useState(false);
    const model = Schema.Model({
        dateSent: DateType().isRequired('This field is required.'),
        boardMember: ObjectType().isRequired('This field is required.'),
    })
    const decisionData = [
        { "label": "Approved", "value": { id: 1, boardMemberDecision: "Approved" } },
        { "label": "Declined", "value": { id: 2, boardMemberDecision: "Declined" } }];

    const [boardMemebers, setBoardMembers] = useState([])
    const [approvalModel, setApprovalModel] = useState()
    useEffect(() => {
        setApprovalModel(props.modalData);
        getBoardMembers().then(setBoardMembers);
    }, [props.modalData])

    const formSubmit = (validation, event) => {
        if (validation) {
            setBlockUi(true);
            var formData = new FormData()
            var dataToTransmit = {
                ...approvalModel,
                dateSent: new Date(approvalModel.dateSent).toISOString(),
                dateReceived: approvalModel.dateReceived ? new Date(approvalModel.dateReceived).toISOString() : null
            }
            formDataAppender('boardMemberResponse', dataToTransmit, formData);
            for (var i = 0; i < approvalModel.files.length; i++) {
                formData.append('files', approvalModel.files[i].blobFile);
            }
            boardApprovalService.postBoardResponse(formData).then((response) => {
                setBlockUi(false);
                if (response.data.boardMemberResponse.id) {
                    props.updateGrid();
                    props.onHide();
                }

            })
        }

    }
    const formDataAppender = (passedKey, model, formData) => {
        for (let key in model) {
            if (typeof (model[key]) === 'object') {
                formDataAppender(`${passedKey}.${key}`, model[key], formData); // Recursive key for objects
            }
            else {
                if (passedKey)
                    formData.append(`${passedKey}.${key}`, model[key]);
                else
                    formData.append(`${key}`, model[key]);
            }
        }
    }
    const handleChange = (val, e) => {
        if (!e) {
            var newFiles = approvalModel.files;
            newFiles.push(val);
            setApprovalModel({ ...approvalModel, files: [...newFiles] })
        }
    };
    const deleteFile = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setApprovalModel({ ...approvalModel, files: approvalModel.files.filter(x => x.fileKey != id) })
            }
        })
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <BlockUi blocking={blockUi}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Board/Staff Member Response
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form model={model} id="boardForm" formValue={approvalModel} onChange={approvalModel => setApprovalModel(approvalModel)} onSubmit={formSubmit} fluid>
                        <FormGroup className='mx-3'>
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Date Sent&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl

                                    name="dateSent"
                                    block="true"
                                    type="date"
                                />
                            </Row>
                        </FormGroup>
                        <FormGroup className='mx-3'>
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Board/Staff Member&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl
                                    block="true"
                                    name="boardMember"
                                    placeholder="Pick Board Member"
                                    accepter={InputPicker}
                                    data={boardMemebers} />
                            </Row>
                        </FormGroup>
                        <hr />
                        <FormGroup className='mx-3'>
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Date Received
                                    </strong>

                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl
                                    name="dateReceived"
                                    block="true"
                                    type="date"
                                />
                            </Row>
                        </FormGroup>
                        <FormGroup className='mx-3'>
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Decision
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl
                                    block="true"
                                    name="boardMemberDecision"
                                    placeholder="Choose..."
                                    accepter={InputPicker}
                                    data={decisionData} />
                            </Row>
                        </FormGroup>
                        <FormGroup className='mx-3'>
                            <Row>
                                <Col sm="12">
                                    <strong>File Upload&nbsp;</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormControl
                                        accepter={Uploader}
                                        listType="text"
                                        onUpload={handleChange}
                                        fileListVisible={false}
                                        multiple
                                        draggable
                                        accept={attachmentService.ATTACHMENTTYPES}>
                                        <div style={{ width: '100%', lineHeight: '150px' }}>Click or Drag files to this area to upload</div>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    {approvalModel?.files?.length > 0 &&
                                        <div>
                                            <br />
                                            <FormControl
                                                accepter={GenericAttachmentGrid}
                                                deleteFileCallback={deleteFile}
                                                data={approvalModel.files.map((value, i) => {
                                                    return {
                                                        id: value.fileKey,
                                                        timestamp: new Date().toLocaleDateString(),
                                                        file: value.blobFile?.name
                                                    }
                                                })}
                                                isSync={true}
                                                tStyle="table-bordered"
                                                name="filesForDisplay" />
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup className='mx-3'>
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Comments
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl name="comments" componentClass="textarea" rows={4} />
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" form="boardForm" variant="primary">Submit</Button>
                    <Button variant="danger" onClick={props.onHide}>Cancel</Button>
                </Modal.Footer>
            </BlockUi>
        </Modal>
    );
}

export default BoardApprovalModal;