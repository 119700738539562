import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import Swal from 'sweetalert2';
import askLolaService from '../../../services/Shared/askLola.service';
import AskLolaComment from '../Comments/AskLolaComment';
import { sort_by } from '../HelperFunctions';

function AskLolaPage({ isInternal }) {
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        refreshGrid();
    }, [])

    const refreshGrid = () => {
        askLolaService.getAskLola().then((response) => {
            var data = response.data.sort(sort_by("dateCrte",true));
            setQuestions(data);
        })
    }

    const addQuestion = () => {
        Swal.fire({
            title: 'Ask a question about LOLA',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                return !value && 'Please enter a question.'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    askLolaService.createAskLola(result.value).then((response) => {
                        if (response.status == 200) {
                            refreshGrid();
                            Swal.fire(
                                'Question Successfully Submitted!',
                                `You submitted this question:  ${result.value}`,
                                'success'
                            )
                        }
                        else {
                            Swal.fire(
                                'Question Failed to Submit!',
                                `Failed to submit new question!`,
                                'warning'
                            )
                        }
                    });
                }
            }
        })
    }

    const issuesMarkup = questions.map((item, i) => {
        return <div key={i}>
            <Row className="mb-3">
                <Col>
                    <h5>{item.formattedName}</h5>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <strong className="blueCommentDate">Question created on {new Date(item.dateCrte).toLocaleString()}</strong>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <strong>{item.text}</strong>
                </Col>
            </Row>
            <div className="issuesCommentSection"><AskLolaComment comments={item.askLolaResponses} askLolaId={item.id}/></div>
            <hr />
        </div>
    });

    return (
        <Card className="w-100">
            <Card.Header>
                <h5>Ask LOLA
                    {!isInternal &&
                        <span><Button variant="primary" className="float-right" onClick={addQuestion}>Ask a Question</Button></span>
                    }
                </h5>
            </Card.Header>
            <Card.Body>
                {issuesMarkup}
            </Card.Body>
        </Card>
    )
}
export default AskLolaPage;