import axios from "axios";
import authHeader from '../../../authheader'

const efGetSection = (reinstatementId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/FirmEfReinstatement/section`, { headers: authHeader(), params: { reinstatementId, sectionId } });
}
const getFirmReinstatement = async (reinstatementId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/FirmReinstatement/reinstatement`, { headers: authHeader(), params: { reinstatementId } });;
};
const validateFirmReinstatement = (reinstatementId) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmReinstatement/validate`, null, { headers: authHeader(), params: { reinstatementId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const postStaffApproval = (reinstatementId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmReinstatement/staffapproval`, null, { headers: authHeader(), params: { reinstatementId, isApproved } })
}
const postReinstatementPdf = (reinstatementId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachReinstatementPdf`, null, { headers: authHeader(), params: { reinstatementId, isApproved } });
}

const efPostSection = (reinstatementId, sectionId, electronicSignature) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmEfReinstatement/signsection`, null, { headers: authHeader(), params: { reinstatementId, sectionId, electronicSignature } });
}

const efPostSectionHasWordOffering = (reinstatementId, sectionId, hasWordOfferingProfessionalServices) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmEfReinstatement/setsectionhaswordofferingprofessionalservices`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasWordOfferingProfessionalServices } });
}
const efPostSectionHasFirmSubmittedSupervisingProfessional = (reinstatementId, sectionId, hasFirmSubmittedSupervisingProfessional) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmEfReinstatement/setsectionhasfirmsubmittedsupervisingprofessional`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasFirmSubmittedSupervisingProfessional } });
}
const efMarkSectionComplete = (reinstatementId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmEfReinstatement/marksectioncomplete`, null, { headers: authHeader(), params: { reinstatementId, sectionId, complete } })
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Verify personal and contact information is correct and accurate.",
    },
    {
        id: 12,
        sectionTitle: "Certificate of Authority/Good Standing Forms",
        sectionContent: "Verify information requested and upload any supporting documents.",
    },
    {
        id: 13,
        sectionTitle: "Firm Supervising Professionals",
        sectionContent: "Certify any supervising professionals working on behalf of a firm.",
    },
    {
        id: 3,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 14,
        sectionTitle: "Unlicensed Activity",
        sectionContent: "Question if the firm has ever practiced or offered to practice engineering or land surveying in Louisiana.",
    },
    {
        id: 9,
        sectionTitle: "Acknowledgment",
        sectionContent: "Verify you have read the Rules for Professional Engineers and Land Surveyors (LAC Title 46, Part LXI), and agree.",
    },
    {
        id: 10,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    },
    {
        id: 11,
        sectionTitle: "Board/Staff Recommendations",
        sectionContent: 'Waiting on text',
    }
])

export default {
    efGetSection,
    efPostSection,
    efPostSectionHasWordOffering,
    efPostSectionHasFirmSubmittedSupervisingProfessional,
    efMarkSectionComplete,
    getFirmReinstatement,
    postStaffApproval,
    validateFirmReinstatement,
    postReinstatementPdf
}