import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import GenericAttachmentGrid from '../Grid/GenericAttachmentGrid';
import attachmentService from '../../../services/Shared/attachment.service';
import { FormControl, InputPicker, Uploader } from 'rsuite';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';
import { sort_by } from '../HelperFunctions';

function DocumentUpload(props) {
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [attachmentType, setAttachmentType] = useState();
    const [hasLoaded, setHasLoaded] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [attachmentTypeDropdown, setAttachmentTypeDropdown] = useState();
    const attachmentTypeChange = (e) => {
        setAttachmentType(e);
        setAttachmentTypeDropdown(e);
    }

    useEffect(() => {
        if (props.isIndividual !== undefined) {
            attachmentService.getAllDocTypes(props.isIndividual).then((data) => {
                setAttachmentTypes(data);
            });
        } else {
            attachmentService.getDocTypes(props.parentTypeId, props.credentialTypeId, props.sectionId, (props.appTypeId == 3 || props.appTypeId == 5), (props.appTypeId == 4 || props.appTypeId == 6), props.attachmentSection).then((data) => {
                setAttachmentTypes(data);
                if (data.length == 1) {
                    setAttachmentType(data[0].value);
                }
            }
            );
        }
    }, [])

    useEffect(() => { refreshGrid(); }
        , [attachmentTypes])

    useEffect(() => { refreshGrid(); }
        , [props.refreshData])

    const [formData, updateFormData] = useState({
        files: [],
        filesForDisplay: [],
        loading: false,
    });

    const refreshGrid = () => {
        setHasLoaded(false)
        updateFormData({
            ...formData,
            loading: true,
        })
        if (props.isSpLocker) {
            attachmentService.getFilesForExternalSp().then((data) => {
                var tempData = [];
                var sortedData = data.data.sort(sort_by('uploadDate', true));
                for (var i = 0; i < sortedData.length; i++) {
                    tempData.push({
                        id: sortedData[i].id,
                        timestamp: new Date(sortedData[i].uploadDate).toLocaleDateString(),
                        file: sortedData[i].fileName,
                        attachmentType: sortedData[i].documentType,
                        fileLink: '',
                        hideDelete: sortedData[i].isExternalUpload
                    });
                }
                updateFormData({
                    filesForDisplay: tempData,
                    files: [],
                    loading: false,
                })
                setHasLoaded(true)
            })
        }
        else {

            attachmentService.getFiles(props.parentId, props.parentTypeId).then((data) => {
                if (props.setHasFiles) {
                    if (data.data.length > 0) {
                        props.setHasFiles(true);
                    }
                    else {
                        props.setHasFiles(false);
                    }
                }
                var attachmentTypeIds = attachmentTypes.map((attachmentType, index) => {
                    return attachmentType.value;
                })
                var tempData = [];
                var sortedData = data?.data.sort(sort_by('uploadDate', true));
                tempData = sortedData?.filter(tempData => attachmentTypeIds.includes(tempData.documentTypeId)).map((tempData, index) => {
                    return {
                        id: tempData.id,
                        timestamp: new Date(tempData.uploadDate).toLocaleDateString(),
                        file: tempData.fileName,
                        attachmentType: tempData.documentType,
                        fileLink: ''
                    };
                })
                updateFormData({
                    filesForDisplay: tempData,
                    files: [],
                    loading: false,
                })
                setHasLoaded(true)
            })
        }
    }

    const deleteFile = (id) => {
        if (props.isUserEditable) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    attachmentService.deleteFile(id).then((response) => {
                        refreshGrid();
                    })
                }
            })
        }
    }

    const handleChange = (val, e) => {
        setIsUploading(true);
        if (!e) {
            updateFormData({
                ...formData,
                loading: true,
            })
            attachmentService.postFile(val, props.parentTypeId, props.parentId, attachmentType, props.entityId).then((response) => {
                setIsUploading(false);
                refreshGrid();
                if (attachmentTypes.length > 1) { // Only clear attachment type when there is a dropdown list of attachments on post
                    setAttachmentType("");
                    setAttachmentTypeDropdown("");
                }
            })
        }
    };

    return (
        <div className="barrierHolder">
            {
                isUploading &&
                <div className="barrier"></div>
            }
            <Row>
                {props.warningMessage ?
                    <Col sm="12">
                        {props.fileUploadTitle ? (<><strong>{props.fileUploadTitle}</strong> {props.warningMessage}</>) : <><strong>File Upload</strong> {props.warningMessage}</>}
                    </Col>
                    :
                    <Col sm="12">
                        {props.fileUploadTitle ? <strong>{props.fileUploadTitle}&nbsp;</strong> : <strong>File Upload&nbsp;</strong>}
                    </Col>
                }

            </Row>
            {attachmentTypes.length > 1 &&
                <Row className="mt-2">
                    <Col sm={props.isIndividual !== undefined ? "12" : "4"}>
                        <FormControl
                            accepter={InputPicker}
                            placeholder="Please Select"
                            block
                            data={attachmentTypes}
                            name="attachmentType"
                            onChange={attachmentTypeChange}
                            readOnly={!props.isUserEditable}
                            value={attachmentTypeDropdown}
                        />
                    </Col>
                </Row>
            }
            {!isUploading ?
                <Row className="mt-2">
                    <Col sm="12">
                        <FormControl
                            name="files"
                            accepter={Uploader}
                            listType="text"
                            onUpload={handleChange}
                            disabled={(!props.isUserEditable || !attachmentType)}
                            fileListVisible={false}
                            multiple
                            draggable
                            accept={attachmentService.ATTACHMENTTYPES}><div style={{ lineHeight: '200px' }}>Click or Drag files to this area to upload</div>
                        </FormControl>
                    </Col>
                </Row>
                :
                <LoadingSpinner />
            }
            <Row>
                <Col sm="12">
                    {hasLoaded ?
                        formData.filesForDisplay.length > 0 &&
                        <div>
                            <br />
                            <FormControl
                                accepter={GenericAttachmentGrid}
                                deleteFileCallback={deleteFile}
                                data={formData.filesForDisplay}
                                tStyle="table-bordered"
                                name="filesForDisplay" />
                        </div>
                        :
                        <LoadingSpinner />
                    }
                </Col>
            </Row>
        </div>
    )
}
export default DocumentUpload;