import axios from "axios";
import authHeader from '../../authheader'

const getEntity = (entityId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/Staff/entity`, { headers: authHeader(), params: { entityId } });
}

const plsGetStaffAndAdmin = (isForAssignment) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/Staff/staffandadmin`, { headers: authHeader(), params: { isForAssignment } });
}

const plsGetStaffAndAdminMyTask = () => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/Staff/staffandadminmytask`, { headers: authHeader() });;
}

const getStaffAndAdminReassignList = (taskId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/Staff/staffandadminreassignlist`, { headers: authHeader(), params: {taskId} });
}

const getUnassignedQueuePermission = () => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/Staff/unassignedqueuepermission`, { headers: authHeader()});
}
export default {
    getEntity,
    plsGetStaffAndAdmin,
    plsGetStaffAndAdminMyTask,
    getStaffAndAdminReassignList,
    getUnassignedQueuePermission
}