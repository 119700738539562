import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap'
import { FaEdit, FaToolbox } from 'react-icons/fa';
import EditSupervisedByModal from './EditSupervisedByModal';
import SupervisedByTable from './SupervisedByTable';

function SupervisedByCard(props) {
    const [updateTable, setUpdateTable] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => { setUpdateTable(updateTable + 1) }, [props.updateData])
    const [modalData, setModalData] = useState({
        individualCredential: "",
        ownerOfFirm: "",
        modalType: "Add"
    });

    const openAddEditModal = (values) => {
        if (values) {
            var data = { ...values, modalType: "Edit" }
            setModalData(data);
        }
        else {
            setModalData({
                professionalCredentialId: "",
                isOwner: "",
                firmEntityId: props.entityId,
                modalType: "Add"
            })
        }
        setModalShow(true);
    }

    return (
        <Card className="w-100">
            <Card.Header className="text-center" style={{ background: 'white' }}>
                <Button className="float-right" onClick={() => { openAddEditModal(); }} variant='primary'>Add New</Button>
                <div>
                    <h5>SUPERVISED BY</h5>
                    <FaToolbox fontSize='42' />
                </div>
            </Card.Header>
            <Card.Body>
                <EditSupervisedByModal modaldata={modalData} entityid={props.entityId} show={modalShow} onHide={() => { setUpdateTable(updateTable + 1); setModalShow(false) }} />
                <SupervisedByTable entityid={props.entityId} openAddEditModal={openAddEditModal} updateTable={updateTable} editMode={true} />
            </Card.Body>
        </Card >
    )
}


export default SupervisedByCard;