import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/VFInformation';
import unlicensedActivityService from '../../../../../services/Internal/Reinstatement/VF/unlicensedActivity.service';
import reinstatementService from '../../../../../services/Internal/Reinstatement/VF/vfReinstatement.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { Form, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import UnlicensedActivity from '../../../../Shared/Card/FirmUnlicensedActivity';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';


function UnlicensedActivityPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Surveying Firm Reinstatement",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Unlicensed Activity"
        }
    );
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 14;
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasUnlicensedActivity: BooleanType().isRequired('This field is required.'),
        explanation: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        setHasLoaded(false);
        reinstatementService.vfGetSection(store.currentApplicationId, sectionId).then((response) => {
            unlicensedActivityService.getUnlicensedActivity(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, files: formData.files, filesForDisplay: formData.filesForDisplay });
                }
                setIsComplete(response.data.staffReviewComplete);
                setSectionData(response.data);
                reinstatementService.getFirmReinstatement(store.currentApplicationId).then((reinstatementData) => {
                    setIsUserEditable(reinstatementData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: reinstatementData.data?.credential?.formattedLicenseNumber,
                                applicationDate: reinstatementData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        hasUnlicensedActivity: undefined,
        explanation: "",
        files: [],
        filesForDisplay: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData.hasUnlicensedActivity;

    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("explanation") || (showAdditionalFields && key.includes("explanation")))) {
                    return;
                }

            }
            if (showAdditionalFields === false || val) {
                var dataTransfer = { ...formData, reinstatementId: store.currentApplicationId, entityId: store.currentApplicationEntityId };
                unlicensedActivityService.postUnlicensedActivity(dataTransfer).then((response) => {
                    if (response.data) {
                        reinstatementService.vfMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((data) => {
                            if (data.data.id) {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                            }
                            else {
                                Swal.fire("Failed to mark section as complete!");
                            }
                        })
                    }
                    else {
                        Swal.fire("Failed to save unlicensed activity");
                    }
                });
            }
        }
    }

    return (
        <div>
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col> <Information content={GetReinstatementInformationContent("unlicensedActivity")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <UnlicensedActivity
                                        isReadOnly={!isUserEditable}
                                        isInternal={true}
                                        prefix={"VF"}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        parentTypeId={2}
                                        entityId={entityInfo.entityId}
                                        showAdditionalFields={showAdditionalFields}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                showSectionComplete={true}
                                backToSectionLink="/internal/vfReinstatement/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    );
}
export default UnlicensedActivityPage