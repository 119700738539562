import React from 'react'
import { Button, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ForgotPasswordSuccess() {
    return (
        <div className="forgotPassSuccessPage">
            <Card className='forgotPassSuccessCard'>
                <Card.Body>
                    <Card.Title className='centerText'>Password Reset Email Sent</Card.Title>
                    <Row className="mb-3 px-3"><p>Please check your email for instructions to reset your password.</p></Row>
                    <Row className="px-3 forgotPassSuccessButtonRow"><Button name="backToLogin" as={Link} to="/login" variant="primary">Return to Login</Button></Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ForgotPasswordSuccess;