import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../../Shared/Card/Issues';
import { useSelector } from 'react-redux';
import ViolationService from '../../../../../../services/Public/Application/PE/violation.service';
import applicationService from '../../../../../../services/Public/Application/PE/peApplication.service';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Swal from 'sweetalert2';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import Information from '../../../../../Shared/Card/Information';
import { getCodeViolationAnswers } from '../../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../../Shared/Card/Violations';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const sectionId = 4;
    const [showFormErrors, setShowFormErrors] = useState(false);

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        signature: StringType().isRequired('This field is required.'),
        yesAnswerExplanation: StringType().isRequired('This field is required.'),
    });
    useEffect(() => {
        setHasLoaded(false);
        applicationService.peGetSection(store.currentApplicationId, sectionId).then((response) => {
            ViolationService.getViolation(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, issues: formData.issues, files: formData.files, filesForDisplay: formData.filesForDisplay, signature: response.data.electronicSignature });
                }
                else {
                    getCodeViolationAnswers(appTypeId).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        signature: undefined,
        yesAnswerExplanation: "",
        files: [],
        filesForDisplay: [],
        issues: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true) >= 0;

    const handleSubmit = (val, event) => {
        setShowFormErrors(true)
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }

            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            if (formData.signature) {
                if (showAdditionalFields === false || val) {
                    var dataTransfer = { ...formData, applicationId: store.currentApplicationId, entityId: store.userInfo.EntityId };
                    ViolationService.postViolation(dataTransfer).then((response) => {
                        applicationService.pePostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                            if (response.data) {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                            }
                        })
                    });
                }
            }
        }
    }
    const formSave = () => {
        var dataTransfer = { ...formData, applicationId: store.currentApplicationId, entityId: store.userInfo.EntityId };
        ViolationService.postViolation(dataTransfer).then((response) => {
            updateFormData({ ...response.data, signature: formData.signature });
            applicationService.pePostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                Swal.fire("Saved!")
            })
        });
    }

    return (
        <div className="peAppViolationsPage">
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <strong>Professional Engineer Application {appType}</strong>
                    <h4 className="mb-3">Violations</h4>
                    <Row className="mb-3">
                        <Col> <Information content={GetAppInformationContent("violations")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <GenericViolations
                                        showFormErrors={showFormErrors}
                                        showAdditionalFields={showAdditionalFields}
                                        appTypeId={appTypeId}
                                        isReadOnly={!sectionData.isUserEditable}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        parentTypeId={1}
                                        credentialTypeId={6}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} handleSave={formSave} backToSectionLink="/individual/peApp/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    )
}
export default Violations;