import { Checkbox, Form, FormControl, FormGroup, Input, InputPicker, Schema } from 'rsuite'
import React, { useState } from 'react';
import AuthService from "../../../services/auth.service"
import { useHistory } from "react-router-dom"
import { Col, Row } from 'react-bootstrap';
import { titleData, suffixData } from '../../../fakeapi';
import YesNoRadioOption from '../../Shared/RadioButton/YesNoRadioOption';
import RedStar from '../../Shared/GenericComponents/RedStar';
import { getCountries, getStates } from '../../../services/Shared/codeTable.service'
import { useEffect } from 'react';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import NumberFormat from 'react-number-format';

const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};

function IndividualForm() {
    const [state, setState] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        title: '',
        suffix: '',
        social: '',
        confirmSocial: '',
        userName: '',
        email: "",
        confirmEmail: "",
        dateOfBirth: null,
        birthCity: '',
        birthState: null,
        stateProvinceRegion: '',
        maidenName: '',
        birthCountry: { id: 221, code: 'US', country: "United States" }, //US Default
        password: "",
        confirmPassword: "",
        isUsCitizen: '',
        citizenshipCountry: null,
    });
    const [countries, setCountries] = useState([])
    const [inputType, setInputType] = useState("text");
    const [showPass, toggleShowPass] = useState(false);
    const [states, setStates] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    useEffect(() => {
        getCountries().then(setCountries);
        getStates().then(setStates);
        setHasLoaded(true)
    }, [])
    const history = useHistory();
    const { StringType, BooleanType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        firstName: StringType().isRequired('This field is required.'),
        lastName: StringType().isRequired('This field is required.'),
        dateOfBirth: StringType().isRequired('This field is required.'),
        birthCity: StringType().isRequired('This field is required.'),
        birthCountry: ObjectType().isRequired('This field is required.'),
        isUsCitizen: BooleanType().isRequired('This field is required.'),
        title: StringType().isRequired('This field is required.'),
        social: StringType().isRequired('This field is required.').pattern(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/, "Please enter a vaild SSN"),
        confirmSocial: StringType().isRequired('This field is required.').addRule((value, data) => {
            if (value !== data.social) {
                return false;
            }
            return true;
        }, "SSNs don't match"),
        userName: StringType().addRule((value, data) => {
            //return AuthService.checkUsername(value).then((response) => {
            //    return response.data;//assuming this is a bool value
            //})
            return true;
        }, 'This username is already in use').addRule((value, data) => {
            var alphaNumeric = /^[0-9a-zA-Z]+$/;
            if (value.match(alphaNumeric))
                return true;
            else {
                return false;
            }
        }, "Invalid characters in username. Please use numbers or letters only.").isRequired('This field is required.'),
        email: StringType().isRequired('This field is required.').isEmail("This is not a valid Email."),
        confirmEmail: StringType().isRequired('This field is required.').addRule((value, data) => {
            if (value !== data.email) {
                return false;
            }
            return true;
        }, "Emails don't match").isEmail("This is not a valid Email."),
        password: StringType().pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*\-_]).{8,}$/, 'Password requires a minimum of 8 characters using upper and lower case letters, and at least 1 number and 1 symbol').isRequired('This field is required.'),
        confirmPassword: StringType().isRequired('This field is required.').addRule((value, data) => {
            if (value !== data.password) {
                return false;
            }
            return true;
        }, "Passwords don't match"),
        birthState: ObjectType().isRequired('This field is required.'),
        stateProvinceRegion: StringType().isRequired('This field is required.'),
        citizenshipCountry: ObjectType().isRequired('This field is required.'),

    })

    const handleIndividualFormSubmit = (validation, event) => {
        var validationObject = model.check(state);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                if (key.includes("birthState") || key.includes("stateProvinceRegion")) {
                    if (
                        (state.birthCountry?.country !== "United States" && !state.birthState?.state)
                        || (state.birthCountry?.country === "United States" && state.stateProvinceRegion === '')) {
                        continue;
                    }

                }
                else if (key.includes("citizenshipCountry")) {
                    if (state.isUsCitizen)
                        continue;
                }
                return;
            }
        }

        AuthService.registerIndividual(state).then(
            (data) => {
                if (data.data.isSuccess) {
                    history.push("/registerSuccess");
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(resMessage);
            }
        );
    }
    const marginAuto = {
        margin: 'auto'
    }
    return (


        <Form id="individualForm" fluid model={model} formValue={state} onChange={state => setState(state)} onSubmit={handleIndividualFormSubmit}>
            {hasLoaded ? <>
                <Row className="mb-4 pb-1">
                    <Col>
                        <FormGroup>
                            <FormControl name="firstName" placeholder="First Name" tabIndex="1" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl
                                type={inputType}
                                name="dateOfBirth"
                                onFocus={() => setInputType("date")}
                                onBlur={(e) => { if (!e.target.value) { setInputType("text") } }}
                                placeholder="Date of Birth"
                                tabIndex="7"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl type="email" placeholder="Email" name="email" checkTrigger="blur" tabIndex="12" />
                        </FormGroup>
                    </Col>

                </Row>
                <Row className="mb-4 pb-1">
                    <Col>
                        <FormGroup>
                            <FormControl name="middleName" placeholder="Middle Name (birth name)" tabIndex="2" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl name="birthCity" placeholder="City of Birth" tabIndex="8" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl type="email" placeholder="Confirm Email" name="confirmEmail" checkTrigger="blur" tabIndex="13" />
                        </FormGroup>
                    </Col>

                </Row>
                <Row className="mb-4 pb-1">
                    <Col>
                        <FormGroup>
                            <FormControl name="lastName" placeholder="Last Name (Surname/Family Name)" tabIndex="3" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl
                                block="true"
                                name="birthCountry"
                                placeholder="Country of Birth"
                                accepter={InputPicker}
                                data={countries}
                                tabIndex="9" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl
                                accepter={InputMask}
                                placeholder="SSN"
                                name="social"
                                format="###-##-####"
                                mask="_"
                                tabIndex="13"
                            />
                        </FormGroup>
                    </Col>

                </Row>
                <Row className="mb-4 pb-1">
                    <Col>
                        <FormGroup>
                            <FormControl
                                placeholder="Maiden Name (if different from last name)"
                                name="maidenName" tabIndex="4" />

                        </FormGroup>
                    </Col>

                    <Col>

                        <FormGroup>
                            {state.birthCountry?.id === 221 &&
                                <FormControl
                                    accepter={InputPicker}
                                    placeholder="State of Birth"
                                    block="true"
                                    data={states}
                                    searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                    name="birthState"
                                    tabIndex="10" />
                            }
                            {state.birthCountry?.id !== 221 &&
                                <FormControl placeholder="State/Province/Region of Birth" name="stateProvinceRegion" tabIndex="10" />
                            }
                        </FormGroup>

                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl
                                accepter={InputMask}
                                placeholder="Confirm SSN"
                                name="confirmSocial"
                                format="###-##-####"
                                mask="_"
                                tabIndex="14"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <FormGroup>
                            <FormControl
                                accepter={InputPicker}
                                placeholder="Title"
                                data={titleData}
                                block="true"
                                name="title"
                                tabIndex="5" />
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <FormControl
                                placeholder="Suffix"
                                data={suffixData}
                                name="suffix"
                                block="true"
                                accepter={InputPicker}
                                tabIndex="6"
                            />

                        </FormGroup>
                    </Col>
                    <Col>
                        <Row>
                            <Col sm={5}>
                                <strong>
                                    Are you a <br />U.S. Citizen?&nbsp;<RedStar />
                                </strong>
                            </Col>
                            <Col>
                                <YesNoRadioOption radioName="isUsCitizen" tabIndex="11" />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {state.isUsCitizen === false &&
                            <FormControl
                                block="true"
                                name="citizenshipCountry"
                                placeholder="If no, what country?"
                                accepter={InputPicker}
                                data={countries} />}
                    </Col>
                </Row>

                <br />
                <hr />
                <Row>
                    <Col>
                        <FormGroup>
                            <FormControl placeholder="User Id" name="userName" tabIndex="15" errorPlacement="topStart" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl type={showPass ? "text" : "password"} placeholder="Password" checkTrigger="blur" name="password" tabIndex="16" title="Password requires a minimum of 8 characters using upper and lower case letters, and at least 1 number and 1 symbol" errorPlacement="topStart" />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormControl type={showPass ? "text" : "password"} placeholder="Confirm Password" checkTrigger="blur" name="confirmPassword" tabIndex="17" errorPlacement="bottomStart" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Checkbox onChange={() => { toggleShowPass(!showPass) }} tabIndex={18}>Show Password</Checkbox>
                    </Col>
                    <Col></Col>
                </Row>
            </> : <LoadingSpinner />}
        </Form>

    );
}
export default IndividualForm;






