import React, { useState } from 'react'
import { useEffect } from 'react';
import { Badge, Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import cpdReqService from '../../../../services/Shared/cpdReq.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import AddEditCoursesModal from './AddEditCoursesModal';
import Courses from './Courses'
import dayjs from 'dayjs';
import BulkCourseUpload from './BulkCourseUpload';

function CpdRequirementsPage() {
    const [modalShow, setModalShow] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalData, setModalData] = useState();
    const [isModalAdd, setIsModalAdd] = useState(true);
    const [coursesModel, setCoursesModel] = useState([]);
    const entityId = useSelector(x => x.userInfo.EntityId);
    useEffect(() => {
        refreshGrid();
    }, [])
    const refreshGrid = () => {
        setHasLoaded(false);
        cpdReqService.getCpd(entityId).then((response) => {
            setCoursesModel(response.data.map(courseRecord => {
                var temp = courseRecord.courseRecord;
                return {
                    area: temp.area,
                    classType: temp.classType,
                    date: temp.courseDate != null ? dayjs(temp.courseDate).format('YYYY-MM-DD') : null,
                    title: temp.courseTitle,
                    description: temp.description,
                    hours: temp.hours,
                    id: temp.id,
                    instructor: temp.instructor,
                    files: [...courseRecord.attachments]
                }
            }));
            setHasLoaded(true);
        });
    }
    const openAddEditModal = (values) => {
        if (values.id) {
            var shapedData = { ...values, date: values.date != null ? dayjs(values.date).format('YYYY-MM-DD') : null }
            shapedData.files = []; // Cleared for fakesync
            setModalData(shapedData);
            setIsModalAdd(false);
        }
        else {
            setModalData({
                id: 0,
                date: null,
                dateCrte: null,
                deleted: false,
                userCrte: "",
                title: "",
                instructor: "",
                area: null,
                hours: "",
                classType: null,
                description: "",
                files: [],
                filesForDisplay: []
            });
            setIsModalAdd(true);
        }
        setModalShow(true);
    }
    return (
        <div>
            <Container fluid>
                <h4 className="mb-3">
                    CPD Locker
                </h4>
                <Card>
                    <Card.Header>
                        <h6>
                            Requirements/Information
                        </h6>
                    </Card.Header>
                    <Card.Body>
                        <p className="mb-2">
                            Once you have obtained the requisite CPD hours earned in the previous two calendar years, download the LAPELS CPD Log found <a href="https://www.lapels.com/docs/CPD/CPD_Log_v2.pdf" target="_blank">here</a><br /><br />

                            Upload your CPD log and all certificates of completion substantiating the hours earned to the CPD locker below.
                            <br />
                            <br />
                            Use the <b>"Records Uploads (Logs and Certificates)"</b> window to upload your logs and certificates from the past two calendar years in one PDF file. Upload separate logs for each calendar year.
                            The uploaded certificates must be in the same order as entered in each log and included in the same PDF.
                            <br />
                            <br />
                            Use the <b>"Individual Course Records"</b> window to upload your future certificates individually.
                            This is available to you year-round for your convenience to upload certificates after completing courses, functions, etc.

                            <div className="py-1"><hr /></div>

                            <b className="text-uppercase">CPD Requirements for Engineers and Surveyors:</b>
                            <br />
                            <br />
                            A <b>Professional Land Surveyor (PLS)</b> must complete 8 CPD hours each calendar year (January - December). Of the 8 hours, one hour must be in the Standards of Practice, and one hour must be in Professional Ethics.
                            In the event you have earned more than 8 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year. You may not earn more than 8 CPD hours in a calendar day.
                            <br />
                            <br />
                            A <b>Professional Engineer (PE)</b> must complete 15 CPD hours each calendar year (January - December). Of the 15 hours, one hour must be in Professional Ethics. If you design buildings or building systems, of the 15 hours, four hours must be in life safety/ADA compliance.
                            In the event you have earned more than 15 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year. You may not earn more than 8 CPD hours in a calendar day.
                            <br />
                            <br />
                            A <b>PE/PLS dual licensee</b> must complete 15 CPD hours each calendar year (January - December). Of the 15 hours, five hours must be earned in each profession, one hour must be in Professional Ethics, and one hour must be in the Standards of Practice. If you design buildings or building systems, of the 15 hours, four hours must be in life safety/ADA compliance.
                            In the event you have earned more than 15 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year. You may not earn more than 8 CPD hours in a calendar day.
                            <br />
                            <br />
                        </p>
                        <Badge variant="danger" style={{ padding: '.5rem' }}>Partial CPD Hours must be in quarter hour increments (such as .25, .5, .75) to be considered acceptable credit.</Badge>
                    </Card.Body>
                </Card>
                <br />
                <BulkCourseUpload entityId={entityId} />
                <br />
                <AddEditCoursesModal modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} entityId={entityId} isModalAdd={isModalAdd} refreshGrid={refreshGrid} />
                {hasLoaded ?
                    <Courses openModal={openAddEditModal} entityId={entityId} coursesModel={coursesModel} setCoursesModel={setCoursesModel} />
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}

export default CpdRequirementsPage