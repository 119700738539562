import React from 'react'
import { Row, Col } from 'react-bootstrap'
function ViolationIssueCommentItem(props) {
    return (
        <>
            <Row className="mb-1">
                <Col>
                    <strong>{props.person}</strong>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className="blueCommentDate">
                    Commented on {props.commentDate}
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <p>{props.comment}</p>
                </Col>
            </Row>
            <hr />
        </>
    )
}

export default ViolationIssueCommentItem;