import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Icon, Table } from 'rsuite';
import Swal from 'sweetalert2';
import experienceService from '../../../services/Public/Application/PLS/experience.service';
import reinstatementExperienceService from '../../../services/Public/Reinstatement/PLS/experience.service';
import GenericBadge from '../Badge/GenericBadge';
import RedStar from '../GenericComponents/RedStar';
import YesNoRadioOption from '../RadioButton/YesNoRadioOption';


function Experience(props) {
    const [hideExperienceGrid, setHideExperienceGrid] = useState();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }
    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }

    useEffect(() => {
        if (props.isEi)
            setHideExperienceGrid(!props.hasMyNceesExperience ?? false);
        else
            setHideExperienceGrid(props.hasMyNceesExperience ?? true);
        setGridData(props.griddata)
        setHasLoaded(true);
    }, [props.griddata])

    const getData = () => {
        return gridData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const handleMyNceesExperienceChange = (val) => {
        if (props.isEi)
            setHideExperienceGrid(!val);
        else
            setHideExperienceGrid(val);
    }

    const filteredData = getData();
    return (
        <div className="plsExperience">
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>Experience Records</h6>
                        </Col>
                        {(!props.isReadOnly && hideExperienceGrid == false && !props.isInternal) &&
                            <Col>
                                <Button className="float-right" onClick={props.openModal}>
                                    <Icon icon="plus" /> Add New</Button>
                            </Col>
                        }
                        {
                            props.isInternal && props.downloadExperiences &&
                            <Col className="text-right"><Button onClick={props.downloadExperiences}>Download PDF Version</Button></Col>
                        }
                    </Row>
                </Card.Header>
                <Card.Body>
                    <p>
                        {props.isEi ?
                            <>
                                <RedStar /> Have you been conferred in a Non-EAC/ABET undergrad degree that has not been coupled with
                                a Master's Degree from a College or University with an EAC/ABET Bachelors program?
                            </>
                            :
                            <>
                                <RedStar /> I have experience records listed in MyNCEES account.
                            </>
                        }
                    </p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasMyNceesExperience" handleChange={handleMyNceesExperienceChange} />
                    {(hideExperienceGrid === false && hasLoaded) &&
                        <>
                            <Table wordWrap autoHeight data={filteredData}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Title</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="position" />
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Employer Name</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="employerName" />
                                </Table.Column>
                                <Table.Column flexGrow={2} >
                                    <Table.HeaderCell>
                                        <b>Employment Type</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.employmentType?.employmentType)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Dates From/To</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (`${dayjs(rowData.dateFrom).format('MMM YYYY')} - ${dayjs(rowData.dateTo).format('MMM YYYY')}`)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Sent Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.sentDate ? new Date(rowData.sentDate).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Response Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.responseDate ? new Date(rowData.responseDate).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Verification Status</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.verificationStatus ? <GenericBadge status={rowData.verificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                                </Table.Column>
                                {!props.isReadOnly &&
                                    <Table.Column flexGrow={1}>
                                        <Table.HeaderCell>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="id">
                                            {rowData => {
                                                const verificationStatusId = rowData.verificationStatus?.id;
                                                function handleAction() {
                                                    props.openModal({ ...rowData });
                                                }
                                                function openVerifierModal() {
                                                    props.openVerifierModal(rowData.id);
                                                }
                                                function emailVerifier(rowData) {
                                                    if (props.appType === "reinstatement") {
                                                        if (props.type == "PE")
                                                            reinstatementExperienceService.peSendVerifierEmail(rowData.id).then((response) => {
                                                                if (response.data) {
                                                                    var verifierEmail = rowData.emailAddresses.find(x => x.emailAddressParentType?.id == 11 || x.emailAddressParentType?.id == 13);
                                                                    Swal.fire(`You have successfully sent an email to ${rowData.verifierName} at ${verifierEmail.contactEmailAddress}!`);
                                                                    props.updateGrid();
                                                                }
                                                                else
                                                                    Swal.fire(`Failed to send email: ${response.data.message}`);
                                                            })
                                                        else {
                                                            reinstatementExperienceService.sendVerifierEmail(rowData.id).then((response) => {
                                                                if (response.data) {
                                                                    var verifierEmail = rowData.emailAddresses.find(x => x.emailAddressParentType?.id == 11 || x.emailAddressParentType?.id == 13);
                                                                    Swal.fire(`You have successfully sent an email to ${rowData.verifierName} at ${verifierEmail.contactEmailAddress}!`);
                                                                    props.updateGrid();
                                                                }
                                                                else
                                                                    Swal.fire(`Failed to send email: ${response.data.message}`);
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        if (props.type == "PE")
                                                            experienceService.peSendVerifierEmail(rowData.id).then((response) => {
                                                                if (response.data) {
                                                                    var verifierEmail = rowData.emailAddresses.find(x => x.emailAddressParentType?.id == 11 || x.emailAddressParentType?.id == 13);
                                                                    Swal.fire(`You have successfully sent an email to ${rowData.verifierName} at ${verifierEmail.contactEmailAddress}!`);
                                                                    props.updateGrid();
                                                                }
                                                                else
                                                                    Swal.fire(`Failed to send email: ${response.data.message}`);
                                                            })
                                                        else {
                                                            experienceService.sendVerifierEmail(rowData.id).then((response) => {
                                                                if (response.data) {
                                                                    var verifierEmail = rowData.emailAddresses.find(x => x.emailAddressParentType?.id == 11 || x.emailAddressParentType?.id == 13);
                                                                    Swal.fire(`You have successfully sent an email to ${rowData.verifierName} at ${verifierEmail.contactEmailAddress}!`);
                                                                    props.updateGrid();
                                                                }
                                                                else
                                                                    Swal.fire(`Failed to send email: ${response.data.message}`);
                                                            })
                                                        }
                                                    }
                                                }
                                                function handleDelete() {
                                                    Swal.fire({
                                                        title: 'Are you sure?',
                                                        text: "You won't be able to revert this!",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: 'Yes, delete it!'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            if (props.appType === "reinstatement") {
                                                                reinstatementExperienceService.postExperienceRecord({ ...rowData, deleted: true }).then((response) => {
                                                                    if (response.data)
                                                                        props.updateGrid();
                                                                })
                                                            }
                                                            else {
                                                                experienceService.postExperienceRecord({ ...rowData, deleted: true }).then((response) => {
                                                                    if (response.data)
                                                                        props.updateGrid();
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                                if (verificationStatusId > 1) {
                                                    if (verificationStatusId == 4 && props.isInternal) {
                                                        return (
                                                            <span>
                                                                <Icon icon="file-text" size="2x" title="View verifier response" onClick={openVerifierModal} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                                <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />
                                                            </span>
                                                        );
                                                    }
                                                }
                                                else {
                                                    return (
                                                        <span>
                                                            <Icon icon="send" size="2x" title="Send email for verification" onClick={() => { emailVerifier(rowData) }} style={{ cursor: "pointer" }} /> &nbsp;&nbsp;
                                                            <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                            <Icon icon="trash" size="2x" onClick={handleDelete} style={{ cursor: "pointer" }} />
                                                        </span>
                                                    );
                                                }

                                            }
                                            }
                                        </Table.Cell>
                                    </Table.Column>
                                }
                            </Table>
                            <Table.Pagination lengthMenu={[
                                {
                                    value: 10,
                                    label: 10
                                },
                                {
                                    value: 20,
                                    label: 20
                                }
                            ]}
                                activePage={page}
                                displayLength={displayLength}
                                total={gridData.length}
                                onChangePage={handleChangePage}
                                onChangeLength={handleChangeLength} />
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}



export default Experience;