import React, { useState, useEffect } from 'react'
import EmploymentLicensure from '../../../../Shared/Card/EmploymentLicensure'
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import employmentService from '../../../../../services/Internal/Application/PLS/employment.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import { getVfCredential } from '../../../../../services/Shared/codeTable.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function EmploymentPage() {
    const [sectionData, setSectionData] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const { StringType, NumberType, ObjectType } = Schema.Types;
    const [option, setOption] = useState();
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 5 ? "By Comity" : appTypeId == 6 ? "By Exam" : "";
    const sectionId = 5;
    const store = useSelector(x => x);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Employment"
        }
    );
    const [formModel, setFormModel] = useState({
        id: 0,
        employedBy: '',
        licensedFirmType: '',
        firmNumber: null,
        comment: ''
    })
    const model = Schema.Model({
        licensedFirmType: StringType().isRequired('This field is required'),
        comment: StringType().isRequired('This field is required'),
        employedBy: StringType().isRequired('This field is required'),
        firmNumber: ObjectType().isRequired("This field is required"),
    });

    useEffect(() => {
        setHasLoaded(false);
        employmentService.getEmployment(store.currentApplicationId).then((response) => {
            applicationService.plsGetSection(store.currentApplicationId, sectionId).then((data) => {
                setSectionData(data.data);
                setIsComplete(data.data.staffReviewComplete)
                if (response.data) {
                    var licensedFirmType = "";
                    if (response.data.firmIsLicensed)
                        licensedFirmType = "licensed";
                    else if (response.data.firmIsNotLicensed)
                        licensedFirmType = "unlicensed";
                    else if (response.data.soleProprietor)
                        licensedFirmType = "soleProprietor";
                    else if (response.data.unemployed)
                        licensedFirmType = "unemployed"
                    if (response.data.credentialId) {
                        getVfCredential(response.data.credentialId).then((cred) => {
                            setFormModel({
                                id: response.data.id,
                                employedBy: response.data.employedByName,
                                firmNumber: cred?.value,
                                comment: response.data.comment,
                                licensedFirmType: licensedFirmType,
                                signature: data.data.electronicSignature
                            });
                            setOption(licensedFirmType);
                        })
                    }
                    else {
                        setFormModel({
                            id: response.data.id,
                            employedBy: response.data.employedByName,
                            firmNumber: response.data.credentialId,
                            comment: response.data.comment,
                            licensedFirmType: licensedFirmType,
                            signature: data.data.electronicSignature
                        });
                        setOption(licensedFirmType);
                    }
                }
                applicationService.getIndividualApplication(store.currentApplicationId).then((appData) => {
                    setIsUserEditable(appData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                                applicationDate: appData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                })
            })
        })

    }, [])

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var hasErrors = false;
            var validationObject = model.check(formModel);
            for (const key in validationObject) {
                if (validationObject[key].hasError) {
                    if (key === "firmNumber" && formModel.licensedFirmType === "licensed") {
                        hasErrors = true;
                        return
                    }
                    else if (key === "employedBy" && formModel.licensedFirmType === "unlicensed") {
                        hasErrors = true;
                        return
                    }
                    else if (key === "comment" && formModel.licensedFirmType === "soleProprietor") {
                        hasErrors = true;
                        return
                    }
                    else if (key === "licensedFirmType" || key === "signature") {
                        hasErrors = true;
                        return
                    }
                }
            }
            if (!hasErrors) {
                submitData(true);
            }
        }
    }

    const formSave = (event) => {
        submitData();
    }
    const clearFirmNumber = () => {
        setFormModel({ ...formModel, firmNumber: null });
    }
    const submitData = (isForSubmit) => {
        var dataTransfer = {
            id: formModel.id,
            applicationId: store.currentApplicationId,
            comment: formModel.comment === "" ? null : formModel.comment,
            credentialId: formModel.firmNumber ? formModel.firmNumber.licenseNumber : null,
            employedByName: formModel.employedBy === "" ? null : formModel.employedBy,
            entityId: store.currentApplicationEntityId,
            firmIsLicensed: formModel.licensedFirmType ? formModel.licensedFirmType == "licensed" ? true : false : null,
            firmIsNotLicensed: formModel.licensedFirmType ? formModel.licensedFirmType == "unlicensed" ? true : false : null,
            soleProprietor: formModel.licensedFirmType ? formModel.licensedFirmType == "soleProprietor" ? true : false : null,
            unemployed: formModel.licensedFirmType ? formModel.licensedFirmType == "unemployed" ? true : false : null
        };
        employmentService.postEmployment(dataTransfer).then((response) => {
            applicationService.plsPostSection(store.currentApplicationId, sectionId, formModel.signature).then((data) => {
                applicationService.plsMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((sectionData) => {
                    if (response.data && sectionData.data && isForSubmit) {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    }
                    else if (response.data && sectionData.data) {
                        var licensedFirmType = "";
                        if (response.data.firmIsLicensed)
                            licensedFirmType = "licensed";
                        else if (response.data.firmIsNotLicensed)
                            licensedFirmType = "unlicensed";
                        else if (response.data.soleProprietor)
                            licensedFirmType = "soleProprietor";
                        else if (response.data.unemployed)
                            licensedFirmType = "unemployed"
                        if (response.data.credentialId) {
                            getVfCredential(response.data.credentialId).then((cred) => {
                                setFormModel({
                                    id: response.data.id,
                                    employedBy: response.data.employedByName,
                                    firmNumber: cred,
                                    comment: response.data.comment,
                                    licensedFirmType: licensedFirmType,
                                    signature: data.data.electronicSignature
                                });
                                setOption(licensedFirmType);
                                Swal.fire("Saved!");
                            })
                        }
                        else {
                            setFormModel({
                                id: response.data.id,
                                employedBy: response.data.employedByName,
                                firmNumber: response.data.credentialId,
                                comment: response.data.comment,
                                licensedFirmType: licensedFirmType,
                                signature: data.data.electronicSignature
                            });
                            setOption(licensedFirmType);
                            Swal.fire("Saved!");
                        }

                    }
                })
            })
        });
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("employment")} />
                <br />
                {hasLoaded ?
                    <Form fluid model={model} formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <EmploymentLicensure
                            isReadOnly={!isUserEditable}
                            firmNumber={formModel.firmNumber}
                            licensedFirmType={formModel.licensedFirmType}
                            option={option}
                            setOption={setOption}
                            clearFirmNumber={clearFirmNumber} />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            handleSave={formSave}
                            showSectionComplete={true}
                            backToSectionLink="/internal/plsApp/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default EmploymentPage