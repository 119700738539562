import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap'
import { FaBriefcase, FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { Table } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import AddEditEmployementModal from './AddEditEmploymentModal';

function EmploymentCard(props) {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});
    useEffect(() => {
        updateGrid()
    }, [])

    const updateGrid = () => {
        setHasLoaded(false);
        credentialService.getWorkHistoryData(props.entityId).then((response) => {
            if (response.data)
                setGridData(response.data);
            setHasLoaded(true);
        })
    }
    const openModal = (data) => {
        if (data?.id) {
            setModalData(data)
        }
        else {
            setModalData({
                startDate: null,
                endDate: null,
                occupation: '',
                organization: '',
                id: 0,
                entityId: props.entityId
            });
        }
        setModalShow(true);
    }
    return (
        <Card className="w-100 h-100">
            <Card.Header className="text-center" style={{ background: 'white' }}>
                <h5>EMPLOYMENT
                </h5>
                <FaBriefcase fontSize='42' />
            </Card.Header>
            <Card.Body>
                <AddEditEmployementModal model={modalData} show={modalShow} onHide={() => { updateGrid(); setModalShow(false) }} />
                {
                    hasLoaded ?
                        <Table bordered wordWrap autoHeight data={gridData}>
                            <Table.Column width={100}>
                                <Table.HeaderCell><b>Start Date</b></Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.startDate != null ? new Date(rowData.startDate).toLocaleDateString() : null)}</Table.Cell>
                            </Table.Column>
                            <Table.Column width={100}>
                                <Table.HeaderCell><b>End Date</b></Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.endDate != null ? new Date(rowData.endDate).toLocaleDateString() : null)}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell><b>Occupation</b></Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.occupation)}</Table.Cell>
                            </Table.Column>
                            <Table.Column width={150}>
                                <Table.HeaderCell><b>Organization</b></Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.organization)}</Table.Cell>
                            </Table.Column>
                            <Table.Column width={100}>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.Cell>{rowData => {
                                    function handleAction() {
                                        openModal(rowData);
                                    }
                                    function handleDelete() {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Yes, delete it!'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                var dataToTransmit = {
                                                    ...rowData,
                                                    startDate: rowData.startDate != '' ? new Date(rowData.startDate) : null,
                                                    endDate: rowData.endDate != '' ? new Date(rowData.endDate) : null,
                                                }
                                                credentialService.deleteWorkHistory(dataToTransmit).then((response) => {
                                                    if (response.data)
                                                        updateGrid();
                                                })
                                            }
                                        })
                                    }
                                    return (<span>
                                        <FaEdit onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                        <FaTrash onClick={handleDelete} style={{ cursor: "pointer" }} />
                                    </span>)
                                }
                                }
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                        : <LoadingSpinner />
                }
            </Card.Body>
            <Card.Footer className='text-right'>
                <Button onClick={() => { openModal(); }} variant='light'><FaPlus /></Button>
            </Card.Footer>
        </Card >
    )
}


export default EmploymentCard;