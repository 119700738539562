import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './components/Shared/GenericComponents/ScrollToTop';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <ScrollToTop />
                    <App />
        </React.StrictMode>
    </BrowserRouter>,
  rootElement);

registerServiceWorker();

