import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import AdditionalEngineeringInformationForm from './AdditionalEngineeringInformationForm';
import AdditionalSurveyorInformationForm from './AdditionalSurveyorInformationForm';

function AdditionalInformationPage({ entityId, activeKey }) {
    const [activeSection, setActiveSection] = useState('engineer')
    useEffect(() => {
        if (activeKey == 'additionalInformation') {

        }
    }, [activeKey])
    return (
        <Tabs activeKey={activeSection} transition={false} onSelect={(selected) => { setActiveSection(selected) }}>
            <Tab eventKey="engineer" title="EI/PE">
                <br />
                <AdditionalEngineeringInformationForm entityId={entityId} active={activeSection == 'engineer'} />
            </Tab>
            <Tab eventKey="surveyor" title="LSI/PLS">
                <br />
                <AdditionalSurveyorInformationForm entityId={entityId} active={activeSection == 'surveyor'} />
            </Tab>
        </Tabs>
    );
}
export default AdditionalInformationPage;