import axios from "axios";
import authHeader from '../../../authheader'

const VFAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/FirmVfApplication";

const getViolation = (applicationId) => {
    return axios.get(`${VFAPPLICATIONURL}/violation`, { headers: authHeader(), params: { applicationId } });
}
const postViolation = (data) => {
    return axios.post(`${VFAPPLICATIONURL}/violation`, data, { headers: authHeader() });
}
const downloadViolationFile = (applicationId) => {
    axios.get(`${process.env.REACT_APP_APIURL}/Reports/GetFirmApplicationViolationsForm`, { headers: authHeader(), params: { applicationId }, responseType: 'arraybuffer' }).then((response) => {
        var name = new String(response.headers['content-disposition']);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name.slice(name.indexOf('filename=') + 9, name.lastIndexOf(';')));
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}
export default {
    getViolation,
    postViolation,
    downloadViolationFile
}