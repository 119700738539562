import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/EFInformation';
import unlicensedActivityService from '../../../../../../services/Public/Application/EF/unlicensedActivity.service';
import applicationService from '../../../../../../services/Public/Application/EF/efApplication.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import UnlicensedActivity from '../../../../../Shared/Card/FirmUnlicensedActivity';
import Issues from '../../../../../Shared/Card/Issues';

function UnlicensedActivityPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 15;
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasUnlicensedActivity: BooleanType().isRequired('This field is required.'),
        explanation: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        setHasLoaded(false);
        applicationService.efGetSection(store.currentApplicationId, sectionId).then((response) => {
            unlicensedActivityService.getUnlicensedActivity(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, files: formData.files, filesForDisplay: formData.filesForDisplay });
                }
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        hasUnlicensedActivity: undefined,
        explanation: "",
        files: [],
        filesForDisplay: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData.hasUnlicensedActivity;

    const handleSubmit = (val, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("explanation") || (showAdditionalFields && key.includes("explanation")))) {
                    return;
                }

            }
            if (showAdditionalFields === false || val) {
                var dataTransfer = { ...formData, applicationId: store.currentApplicationId, entityId: store.userInfo.EntityId };
                unlicensedActivityService.postUnlicensedActivity(dataTransfer).then((response) => {
                    if (response.data) {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    }
                });
            }
        }
    }

    return (
        <div>
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <strong>Engineering Firm Application</strong>
                    <h4 className="mb-3">Unlicensed Activity</h4>
                    <Row className="mb-3">
                        <Col> <Information content={GetAppInformationContent("unlicensedActivity")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <UnlicensedActivity
                                        isReadOnly={!sectionData.isUserEditable}
                                        prefix={"EF"}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        parentTypeId={1}
                                        showAdditionalFields={showAdditionalFields}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/firm/efapp/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    );
}
export default UnlicensedActivityPage