import axios from "axios";
import authHeader from '../../../authheader'

const LSIREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualLsiReinstatement";

const getExamination = (reinstatementId) => {
    return axios.get(`${LSIREINSTATEMENTURL}/examinationverification`, { headers: authHeader(), params: { reinstatementId } });
}
const postExamination = (data) => {
    return axios.post(`${LSIREINSTATEMENTURL}/examinationverification`, data, { headers: authHeader() });
}


export default {
    getExamination,
    postExamination
}