import axios from "axios";
import authHeader from '../../authheader'

const INTERNALREINSTATEMENT = process.env.REACT_APP_APIURL + "/api/IndividualReinstatement";

const getPendingReinstatements = async () => {
    return axios.get(`${INTERNALREINSTATEMENT}/pendingreinstatements`, { headers: authHeader() });;
};
const getIndividualReinstatement = async (reinstatementId) => {
    return axios.get(`${INTERNALREINSTATEMENT}/reinstatement`, { headers: authHeader(), params: { reinstatementId } });;
};

const plsGetSection = (reinstatementId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPlsReinstatement/section`, { headers: authHeader(), params: { reinstatementId, sectionId } });
}
const plsMarkSectionComplete = (applicationId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsReinstatement/marksectioncomplete`, null, { headers: authHeader(), params: { applicationId, sectionId, complete } })
}
const plsPostSection = (reinstatementId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsReinstatement/signsection`, null, { headers: authHeader(), params: { reinstatementId, sectionId, electronicSignature } });
}
const plsPostSectionHasRef = (reinstatementId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsReinstatement/setsectionhasreferences`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasReferences } });
}
const plsPostSectionHasCpd = (reinstatementId, sectionId, hasCpd) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsReinstatement/setsectionhascpd`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasCpd } });
}
const plsPostSectionHasExperience = (reinstatementId, sectionId, hasMyNceesExperience) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsReinstatement/setsectionhasmynceesexperience`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasMyNceesExperience } });
}
const postStaffApproval = (reinstatementId, isApproved) => {
    return axios.post(`${INTERNALREINSTATEMENT}/staffApproval`, null, { headers: authHeader(), params: { reinstatementId, isApproved } });
}
const postReinstatementPdf = (reinstatementId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachReinstatementPdf`, null, { headers: authHeader(), params: { reinstatementId, isApproved } });
}
export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "Employment",
        sectionContent: "Verify Louisiana Employment Firm licensure",
    },
    {
        id: 3,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 4,
        sectionTitle: "Quizzes",
        sectionContent: "Quiz content here",
    },
    {
        id: 5,
        sectionTitle: "CPD Requirements",
        sectionContent: "If you answer yes to any of the questions in this section, you will need to submit a detailed description for each violation" +
            "and you may be required to provide court documents.",
    },
    {
        id: 6,
        sectionTitle: "Personal References",
        sectionContent: "*only for 5 years or more",
    },
    {
        id: 7,
        sectionTitle: "Experience Record",
        sectionContent: "*only for 5 years or more",
    },
    {
        id: 8,
        sectionTitle: "Disclosure",
        sectionContent: "Authorize LAPELS staff to procure a consumer report.",
    },
    {
        id: 9,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    }
    ,
    {
        id: 10,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    },
    {
        id: 11,
        sectionTitle: "Board/Staff Recommendations",
        sectionContent: 'Waiting on text',
    }
])
export default {
    plsPostSection,
    plsPostSectionHasRef,
    plsPostSectionHasCpd,
    plsPostSectionHasExperience,
    getPendingReinstatements,
    getIndividualReinstatement,
    plsGetSection,
    plsMarkSectionComplete,
    postStaffApproval,
    postReinstatementPdf
}