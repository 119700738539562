import React from 'react';
import { Card } from 'react-bootstrap';
import { Form, } from 'rsuite';
import DocumentUpload from '../../../Shared/Upload/DocumentUpload';

function SupervisingProfessionalDocumentUpload() {
    return (
        <>
            <Card>
                <Card.Body>
                    <Card style={{ padding: '1rem' }}>
                        <Form>
                            <DocumentUpload
                                isUserEditable={true}
                                attachmentSection={"SP Locker"}
                                parentId={0}
                                parentTypeId={10}
                                fileUploadTitle={"Document Uploads"}
                                isSpLocker={true}
                            />
                        </Form>
                    </Card>
                </Card.Body>
            </Card>
        </>
    )
}

export default SupervisingProfessionalDocumentUpload;