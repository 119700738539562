import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import seal from '../../images/LAPELS-LOLA-Logo.png'
import AuthService from "../../services/auth.service"
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';

function ForgotPassword() {
    const { push } = useHistory();
    const [userId, setUserId] = useState('');
    const handleChange = (e) => {
        setUserId(e.target.value);
    };
    const [showAccountRecoverEmail, setShowAccountRecoverEmail] = useState(false);

    const [validated, setValidated] = useState(false);
    const handleSubmitClick = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {

            e.stopPropagation();
        }
        //setValidated(true);
        sendDetailsToServer();
    }
    function sendDetailsToServer() {
        AuthService.forgotPassword(userId).then(
            (response) => {
                if (response.data.isSuccess === true) {
                    push("/forgotpasswordsuccess");
                } else {
                    Swal.fire("Add failure handler here.")
                }
            }, (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                const toast = Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                toast.fire({
                    icon: 'error',
                    title: resMessage
                })
                setShowAccountRecoverEmail(true);
            }
        )
    }

    const imageStyle = {
        height: '10rem',
        width: '10rem',
        margin: 'auto'
    };
    const centerText = {
        textAlign: 'center'
    }
    const buttonStyle = {
        width: "100%"
    }


    return (
        <div className="loginPage">
            <Card style={{ width: '25rem', paddingTop: '1rem' }}>
                <Card.Img variant="top" src={seal} alt="Lapels Seal" style={imageStyle} />
                <Card.Body>
                    <Card.Title style={centerText}>Forgot your password? Enter User Id to reset your password.</Card.Title>
                    <Form noValidate validated={validated} onSubmit={handleSubmitClick}>
                        <Form.Group controlId="userId">
                            <Form.Control
                                required
                                type="input"
                                placeholder="User Id"
                                onChange={handleChange}
                                value={userId}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter User Id.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            style={buttonStyle}>
                            Send
                        </Button>
                    </Form>
                    <br />
                    {showAccountRecoverEmail == true &&
                        <div className="loginFooter centerText">
                            <p><b>Still can't log in?</b> Please email <a href="mailto:AccountRecovery@lapels.com">AccountRecovery@lapels.com</a></p>
                        </div>}
                    <div>
                        <Card.Link as={Link} to="/login">Back to Login</Card.Link>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
export default ForgotPassword;