import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Address from '../../GenericComponents/Address';
function PublicAddress({ isReadOnly, boundValues, isUserEditable, copyFromMailing, contactInfoLink}) {
    return (
        <Card>
            <Card.Header>


                

                <Row>
                    <Col>
                        <h6>Verify Public Address</h6>
                    </Col>
                    {(isReadOnly && isUserEditable) &&
                        <Col>
                            <Col>
                            <Button as={Link} to={contactInfoLink} variant="dark" className="float-right" target="_blank">
                                    Update Contact Information</Button>
                            </Col>
                        </Col>
                    }
                    {(copyFromMailing) &&
                        <Col>
                            <Col>
                                <Button onClick={copyFromMailing} variant="dark" className="float-right" >
                                    Copy From Mailing</Button>
                            </Col>
                        </Col>
                    }
                    
                </Row>
            </Card.Header>
            <Card.Body>
                <p style={{ color: 'red' }} className="mb-3"><strong>* THIS ADDRESS WILL BE POSTED ON THE WEBSITE</strong></p>
                <Address
                    boundValues={boundValues}
                    readOnly={isReadOnly}
                />

            </Card.Body>
        </Card>

    );
}
export default PublicAddress;