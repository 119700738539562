import axios from "axios";
import authHeader from '../../../authheader'

const EIREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualEiReinstatement";

const getViolation = (reinstatementId) => {
    return axios.get(`${EIREINSTATEMENTURL}/violation`, { headers: authHeader(), params: { reinstatementId } });
}
const postViolation = (data) => {
    return axios.post(`${EIREINSTATEMENTURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}