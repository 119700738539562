import React, { useEffect, useState } from 'react'
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import AddEditExperienceRecordsModal from './ExperienceRecordModal';
import Experience from '../../../../Shared/Card/Experience';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import applicationService from '../../../../../services/Internal/Application/application.service';
import experienceService from '../../../../../services/Internal/Application/PLS/experience.service';
import Issues from '../../../../Shared/Card/Issues';
import dayjs from 'dayjs';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import ExternalFormModal from '../../../../Shared/Modal/ExternalFormModal';
import FormEntityInformation from '../../../Shared/FormEntityInformation';
import Swal from 'sweetalert2';

function ExperiencePage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [verifierModalShow, setVerifierModalShow] = useState(false);
    const [verifierExperienceId, setVerifierExperienceId] = useState(0);
    const [gridData, setGridData] = useState([]);
    const entityId = useSelector(x => x.currentApplicationEntityId)
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 5 ? "By Comity" : appTypeId == 6 ? "By Exam" : "";
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 7;
    const [modalData, setModalData] = useState({});
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Experience"
        }
    );
    useEffect(() => {
        setHasLoaded(false)
        applicationService.plsGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete);
            applicationService.getIndividualApplication(appId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                    updateGridData();
                    setHasLoaded(true);
                });
            });
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesExperience: BooleanType().isRequired('This field is required.')
    });
    const openVerifierModal = (id) => {
        setVerifierExperienceId(id);
        setVerifierModalShow(true);
    }
    const openAddEditModal = (values) => {
        if (isUserEditable) {
            if (values.id) {
                setModalData(
                    {
                        ...values,
                        verifierEmail: values.emailAddresses[1].contactEmailAddress,
                        verifierPhone: values.phoneNumbers[1].contactPhoneNumber,
                        employerEmail: values.emailAddresses[0].contactEmailAddress,
                        employerPhone: values.phoneNumbers[0].contactPhoneNumber,
                        dateFrom: values.dateFrom ? dayjs(values.dateFrom).format('YYYY-MM') : null,
                        dateTo: values.dateTo ? dayjs(values.dateTo).format('YYYY-MM') : null,
                        modalType: 'Edit'
                    });
            }
            else {
                setModalData({
                    applicationId: appId,
                    comments: "",
                    dateCrte: null,
                    dateFrom: null,
                    dateTo: null,
                    deleted: false,
                    employerName: "",
                    employerPosition: "",
                    verifierName: "",
                    verifierPosition: '',
                    verifierEmail: "",
                    verifierPhone: "",
                    employerEmail: "",
                    employerPhone: "",
                    employmentType: null,
                    entityId: entityId,
                    id: 0,
                    userCrte: "",
                    position: "",
                    location: "",
                    description: "",
                    acceptableDescription: "",
                    emailAddresses: [
                        {
                            contactEmailAddress: '',
                            emailAddressParentType: {
                                id: 10,
                                emailAddressParentType: "Application Experience Employer"
                            },
                            emailAddressType: {
                                id: 1,
                                emailAddressType: ''
                            },
                            id: 0,
                            entityId: entityId,
                            parentId: 0,
                            userCrte: '',
                            dateCrte: null
                        },
                        {
                            contactEmailAddress: '',
                            emailAddressParentType: {
                                id: 11,
                                emailAddressParentType: "Application Experience Verifier"
                            },
                            emailAddressType: {
                                id: 1,
                                emailAddressType: ''
                            },
                            id: 0,
                            entityId: entityId,
                            parentId: 0,
                            userCrte: '',
                            dateCrte: null
                        }
                    ],
                    phoneNumbers: [
                        {
                            contactPhoneNumber: '',
                            parentType: {
                                id: 10,
                                phoneParentType: "Application Experience Employer"
                            },
                            phoneType: {
                                id: 3,
                                phoneType: ''
                            },
                            id: 0,
                            entityId: entityId,
                            parentId: 0,
                            userCrte: '',
                            dateCrte: null
                        },
                        {
                            contactPhoneNumber: '',
                            parentType: {
                                id: 11,
                                phoneParentType: "Application Experience Verifier"
                            },
                            phoneType: {
                                id: 3,
                                phoneType: ''
                            },
                            id: 0,
                            entityId: entityId,
                            parentId: 0,
                            userCrte: '',
                            dateCrte: null
                        }
                    ],
                    modalType: "Add"
                })
            }
            setModalShow(true);
        }
    }
    const { push } = useHistory();

    const handleSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            applicationService.plsPostSectionHasExperience(appId, sectionId, sectionData.hasMyNceesExperience).then((response) => {
                applicationService.plsMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                    if (response.data && data.data) // If both return something it's good to go
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)

                })
            });
        }
    }
    const updateGridData = () => {
        experienceService.getExperienceRecords(appId).then((response) => {
            setGridData(response.data)
        });
    }

    const downloadExperiences = () => {
        Swal.fire({
            title: `Downloading PDF`,
            didOpen: () => {
                Swal.showLoading();
                experienceService.downloadExperiences(appId, "PLS").then((response) => {
                    var name = new String(response.headers['content-disposition']);
                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('target', '_blank');
                    link.setAttribute('download', name.slice(name.indexOf('filename=') + 9, name.lastIndexOf(';')));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    Swal.close();
                })
            },
            allowOutsideClick: false
        })
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("experience")} />
                <br />
                {hasLoaded ? <>
                    {modalShow &&
                        <AddEditExperienceRecordsModal appType="application" updateGrid={updateGridData} modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} proptitle="Add" />
                    }
                    {verifierModalShow &&
                        <ExternalFormModal show={verifierModalShow} onHide={() => setVerifierModalShow(false)} id={verifierExperienceId} type="experienceApplication" />
                    }
                    <Form onSubmit={handleSubmit} model={model} formValue={sectionData} onChange={data => setSectionData(data)}>
                        <Experience appType="application" updateGrid={updateGridData} griddata={gridData}
                            openModal={openAddEditModal} hasMyNceesExperience={sectionData.hasMyNceesExperience} isInternal={true}
                            openVerifierModal={openVerifierModal} downloadExperiences={downloadExperiences} isReadOnly={!isUserEditable} />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true}
                            backToSectionLink="/internal/plsapp/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ExperiencePage