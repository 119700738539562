import React, { useState, useEffect } from 'react'
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import EmploymentLicensure from '../../../../../Shared/Card/EmploymentLicensure';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import applicationService from '../../../../../../services/Public/Application/PLS/plsApplication.service';
import employmentService from '../../../../../../services/Public/Application/PLS/employment.service';
import { getVfCredential } from '../../../../../../services/Shared/codeTable.service';
import Issues from '../../../../../Shared/Card/Issues';

function EmploymentPage() {
    const [sectionData, setSectionData] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const { StringType, ObjectType } = Schema.Types;
    const [option, setOption] = useState();
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 5 ? "By Comity" : appTypeId == 6 ? "By Exam" : "";
    const sectionId = 5;
    const store = useSelector(x => x);
    const [formModel, setFormModel] = useState({
        id: 0,
        employedBy: '',
        licensedFirmType: '',
        firmNumber: null,
        comment: '',
        signature: ''
    })
    const model = Schema.Model({
        licensedFirmType: StringType().isRequired('This field is required'),
        comment: StringType().isRequired('This field is required'),
        employedBy: StringType().isRequired('This field is required'),
        firmNumber: ObjectType().isRequired("This field is required"),
    });

    useEffect(() => {
        setHasLoaded(false);
        employmentService.getEmployment(store.currentApplicationId).then((response) => {
            applicationService.plsGetSection(store.currentApplicationId, sectionId).then((data) => {
                setSectionData(data.data);
                if (response.data) {
                    var licensedFirmType = "";
                    if (response.data.firmIsLicensed)
                        licensedFirmType = "licensed";
                    else if (response.data.firmIsNotLicensed)
                        licensedFirmType = "unlicensed";
                    else if (response.data.soleProprietor)
                        licensedFirmType = "soleProprietor";
                    else if (response.data.unemployed)
                        licensedFirmType = "unemployed"
                    if (response.data.credentialId) {
                        getVfCredential(response.data.credentialId).then((cred) => {
                            setFormModel({
                                id: response.data.id,
                                employedBy: response.data.employedByName,
                                firmNumber: cred?.value,
                                comment: response.data.comment,
                                licensedFirmType: licensedFirmType,
                            });
                            setOption(licensedFirmType);
                        })
                    }
                    else {
                        setFormModel({
                            id: response.data.id,
                            employedBy: response.data.employedByName,
                            firmNumber: response.data.credentialId,
                            comment: response.data.comment,
                            licensedFirmType: licensedFirmType,
                        });
                        setOption(licensedFirmType);
                    }
                }
            })
        })
        setHasLoaded(true);
    }, [])
    const clearFirmNumber = () => {
        setFormModel({ ...formModel, firmNumber: null });
    }
    const formSubmit = (validation, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var hasErrors = false;
            var validationObject = model.check(formModel);
            for (const key in validationObject) {
                if (validationObject[key].hasError) {
                    if (key === "firmNumber" && formModel.licensedFirmType === "licensed") {
                        hasErrors = true;
                        return
                    }
                    else if (key === "employedBy" && formModel.licensedFirmType === "unlicensed") {
                        hasErrors = true;
                        return
                    }
                    else if (key === "comment" && formModel.licensedFirmType === "soleProprietor") {
                        hasErrors = true;
                        return
                    }
                    else if (key === "licensedFirmType" || key === "signature") {
                        hasErrors = true;
                        return
                    }
                }
            }
            if (!hasErrors) {
                submitData(true);
            }
        }
    }

    const formSave = (event) => {
        submitData();
    }

    const submitData = (isForSubmit) => {
        var dataTransfer = {
            id: formModel.id,
            applicationId: store.currentApplicationId,
            comment: formModel.comment === "" ? null : formModel.comment,
            credentialId: formModel.firmNumber ? formModel.firmNumber.licenseNumber : null,
            employedByName: formModel.employedBy === "" ? null : formModel.employedBy,
            entityId: store.userInfo.EntityId,
            firmIsLicensed: formModel.licensedFirmType ? formModel.licensedFirmType == "licensed" ? true : false : null,
            firmIsNotLicensed: formModel.licensedFirmType ? formModel.licensedFirmType == "unlicensed" ? true : false : null,
            soleProprietor: formModel.licensedFirmType ? formModel.licensedFirmType == "soleProprietor" ? true : false : null,
            unemployed: formModel.licensedFirmType ? formModel.licensedFirmType == "unemployed" ? true : false : null
        };
        employmentService.postEmployment(dataTransfer).then((response) => {
            if (response.data && isForSubmit) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data) {
                var licensedFirmType = "";
                if (response.data.firmIsLicensed)
                    licensedFirmType = "licensed";
                else if (response.data.firmIsNotLicensed)
                    licensedFirmType = "unlicensed";
                else if (response.data.soleProprietor)
                    licensedFirmType = "soleProprietor";
                else if (response.data.unemployed)
                    licensedFirmType = "unemployed"
                if (response.data.credentialId) {
                    getVfCredential(response.data.credentialId).then((cred) => {
                        setFormModel({
                            id: response.data.id,
                            employedBy: response.data.employedByName,
                            firmNumber: cred,
                            comment: response.data.comment,
                            licensedFirmType: licensedFirmType,
                        });
                        setOption(licensedFirmType);
                        Swal.fire("Saved!");
                    })
                }
                else {
                    setFormModel({
                        id: response.data.id,
                        employedBy: response.data.employedByName,
                        firmNumber: response.data.credentialId,
                        comment: response.data.comment,
                        licensedFirmType: licensedFirmType,
                    });
                    setOption(licensedFirmType);
                    Swal.fire("Saved!");
                }
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <b>Professional Land Surveyor Application {appType}</b>
                <h4 className="mb-3">Employment</h4>
                <Information content={GetAppInformationContent("employment")} />
                <br />
                {hasLoaded ?
                    <Form fluid model={model} formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <EmploymentLicensure
                            firmNumber={formModel.firmNumber}
                            isReadOnly={!sectionData.isUserEditable}
                            licensedFirmType={formModel.licensedFirmType}
                            option={option}
                            setOption={setOption}
                            clearFirmNumber={clearFirmNumber} />
                        <br />
                        <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/plsApp/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default EmploymentPage