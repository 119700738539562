import axios from "axios";
import authHeader from '../../../authheader'

const EFAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/FirmEfApplication";

const getContactInformation = (applicationId) => {
    return axios.get(`${EFAPPLICATIONURL}/contactinformation`, { headers: authHeader(), params: { applicationId } });
}
//const postContactInformation = (applicationId, certDisplayName) => {
//    return axios.post(`${VFAPPLICATIONURL}/contactinformation`, null, { headers: authHeader(), params: { applicationId, certDisplayName } });
//}
export default {
    getContactInformation,
    //postContactInformation
}