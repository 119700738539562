import axios from "axios";
import authHeader from "../authheader";

const ASKLOLAURL = process.env.REACT_APP_APIURL + "/api/AskLola";

const getAskLola = () => {
    return axios.get(`${ASKLOLAURL}/asklola`, { headers: authHeader() });
}

const createAskLola = (text) => {
    //return axios.post(`${ASKLOLAURL}/createasklola`, { headers: authHeader(), params: { text } });
    return axios.post(`${ASKLOLAURL}/createasklola`, null, { headers: authHeader(), params: { text } });
}

const updateAskLola = (data) => {
    var header = authHeader();
    return axios.post(`${ASKLOLAURL}/updateasklola`, data, { headers: { ...header } });
}

export default {
    getAskLola,
    createAskLola,
    updateAskLola
}