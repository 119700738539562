import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, FormControl, Schema, TagPicker } from 'rsuite'
import peApplicationService from '../../../../../../services/Public/Application/PE/peApplication.service'
import residencyService from '../../../../../../services/Public/Application/PE/residency.service'
import { getStates } from '../../../../../../services/Shared/codeTable.service'
import Information from '../../../../../Shared/Card/Information'
import Issues from '../../../../../Shared/Card/Issues'
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner'
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PeInformation'

function ResidencyPage() {
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const [model, setModel] = useState({
        applicationId: appId,
        entityId: entityId,
        states: ''
    })
    const [hasLoaded, setHasLoaded] = useState(true)
    const { push } = useHistory();
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 17;
    const [states, setStates] = useState([])
    const [sectionData, setSectionData] = useState({})
    useEffect(() => {
        peApplicationService.peGetSection(appId, sectionId).then((response) => {
            getStates().then(setStates);
            setSectionData(response.data);
            residencyService.getResidency(appId).then((data) => {
                if (data.data)
                    setModel(data.data)
                setHasLoaded(true);
            })
        })
    }, [])

    const { ArrayType } = Schema.Types;
    const validationModel = Schema.Model({
        states: ArrayType().isRequired('This field is required.')
    })
    const formSubmit = (val, event) => {
        if (!sectionData.isUserEditable)
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        else if (val) {
            residencyService.postResidency(model).then((response) => { 
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            })
        }
    }
    return (
        <Container fluid>
            <b>Professional Engineer Application {appType}</b>
            <h4 className="mb-3">Residency</h4>
            <Information content={GetAppInformationContent("residency")} />
            <br />{hasLoaded ?
                <Form readOnly={!sectionData.isUserEditable} model={validationModel} onChange={setModel} formValue={model} onSubmit={formSubmit}>
                    <Card className="mb-3">
                        <Card.Header>
                            <h6>Residency</h6>
                        </Card.Header>
                        <Card.Body>
                            <b>What state(s) do you currently hold an active license in? Select all choices below that are applicable.</b>
                            <FormControl
                                accepter={TagPicker}
                                placeholder="Select a State"
                                block
                                data={states}
                                searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                name="states"
                            />
                        </Card.Body>
                    </Card>
                    <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                    <FormActionButtons backToSectionLink="/individual/peapp/sections" />
                </Form> : <LoadingSpinner />}
        </Container>
    );
}
export default ResidencyPage;