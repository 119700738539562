import axios from "axios";
import authHeader from '../../../authheader'

const PLSREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsReinstatement";

const getViolation = (reinstatementId) => {
    return axios.get(`${PLSREINSTATEMENTURL}/violation`, { headers: authHeader(), params: { reinstatementId } });
}
const postViolation = (data) => {
    return axios.post(`${PLSREINSTATEMENTURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}