import React, { useState } from 'react';
import WelcomeContent from './WelcomeContent';
import Profile from './Profile';
import RecentUpdates from './RecentUpdates';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import AuthService from '../../../../services/Public/dashboard.service'
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import EditLockedContactInfoModal from './EditLockedContactInfoModal';
import TemporaryInformationCard from './TemporaryInformationCard';

function DashboardPage(props) {
    const [publicDashboardModel, setPublicDashboardModel] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});

    useEffect(() => {
        AuthService.getFirmDashboard().then((response) => {
            response.data.pendingApplications = response.data.pendingApplications.map(obj => ({ ...obj, pendingApplicationStatus: obj.status }));
            setPublicDashboardModel(response.data);
            setHasLoaded(true);
            AuthService.getFirmLockedContactInformation().then((data) => {
                if (data.data ?? false) {
                    setShowModal(true);
                    setModalData(data.data)
                }
            })
        });

    }, [])
    return (
        <React.Fragment>
            { hasLoaded ?
                <Container fluid>
                    <h4 className="mb-3">Dashboard</h4>
                    <TemporaryInformationCard display={false} />
                    <EditLockedContactInfoModal show={showModal} modaldata={modalData} onHide={() => setShowModal(false)} />
                    <Row>
                        <Col><WelcomeContent /></Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Profile
                                profileInfo={publicDashboardModel.profileInfo}
                                credentials={publicDashboardModel.credentials}
                                pendingApps={publicDashboardModel.pendingApplications} />
                        </Col>
                        <Col><RecentUpdates updates={publicDashboardModel.recentUpdates} /></Col>
                    </Row>

                </Container> : <LoadingSpinner />
            }
        </React.Fragment>
    )
}
export default DashboardPage;