import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap'
import { ControlLabel, FormControl, FormGroup, InputPicker } from 'rsuite'
import { getCountries, getStates } from '../../../services/Shared/codeTable.service'
import RedStar from './RedStar';

function Address(props) {
    const [countryValue, setCountryValue] = useState();
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [loadedDropDowns, setLoadedDropDowns] = useState(false);
    useEffect(() => {
        if (!loadedDropDowns) {
            getCountries().then(setCountries);
            getStates().then(setStates);
            setLoadedDropDowns(true);
        }
        setCountryValue(props.boundValues?.country?.country);
    }, [props])
    return (
        <>
            <FormGroup>
                <Row>
                    <Col sm={3}>
                        <ControlLabel>
                            <strong>
                                Country {!props.hideRequired && <RedStar />}
                            </strong>
                        </ControlLabel>
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            errorMessage={(!props.boundValues?.country && (props.boundValues?.showErrors || props.showErrors)) ? "This field is required" : null }
                            block
                            name={props.countryName ? props.countryName : "country"}
                            accepter={InputPicker}
                            data={countries}
                            onChange={(value) => { setCountryValue(value) }}
                            readOnly={props.readOnly}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col sm={3}>
                        <ControlLabel>
                            <strong>Address</strong> {!props.hideRequired && <RedStar />}
                        </ControlLabel>
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            errorMessage={
                                (!props.boundValues?.streetAddress && (props.boundValues?.showErrors || props.showErrors))
                                    ? "This field is required" : null}
                            rows={3}
                            componentClass="textarea"
                            name={props?.addressName ? props.addressName : "streetAddress"}
                            readOnly={props.readOnly}/>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col sm={3}>
                        <ControlLabel>
                            <strong>City </strong>{!props.hideRequired && <RedStar />}
                        </ControlLabel>
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            errorMessage={
                                (!props.boundValues?.city && (props.boundValues?.showErrors || props.showErrors))
                                    ? "This field is required" : null}
                            name={props?.cityName ? props.cityName : "city"}
                            readOnly={props.readOnly}/>
                    </Col>
                </Row>
            </FormGroup>
            {
                countryValue === "United States" &&
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>State </strong>{!props.hideRequired && <RedStar />}
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                errorMessage={(!props.boundValues?.state && (props.boundValues?.showErrors || props.showErrors))? "This field is required" : null}
                                accepter={InputPicker}
                                placeholder="Please Select"
                                block
                                data={states}
                                name={props?.stateName ? props.stateName : "state"}
                                searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                readOnly={props.readOnly}/>
                        </Col>
                    </Row>
                </FormGroup>
            }
            {
                countryValue !== "United States" &&
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>State/Province/Region </strong>{!props.hideRequired && <RedStar />}
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                errorMessage={(!props.boundValues?.provinceRegion && (props.boundValues?.showErrors || props.showErrors))? "This field is required" : null}
                                name={props?.stateProvinceRegionName ? props.stateProvinceRegionName : "provinceRegion"}
                                readOnly={props.readOnly}/>
                        </Col>
                    </Row>
                </FormGroup>
            }
            <FormGroup>
                <Row>
                    <Col sm={3}>
                        <ControlLabel>
                            <strong>Zip/Postal Code </strong>{!props.hideRequired && <RedStar />}
                        </ControlLabel>
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            errorMessage={(!props.boundValues?.postalCode && (props.boundValues?.showErrors || props.showErrors)) ? "This field is required" : null}
                            name={props?.postalCodeName ? props.postalCodeName : "postalCode"}
                            readOnly={props.readOnly}/>
                    </Col>
                </Row>
            </FormGroup>
        </>
    )
}
export default Address;