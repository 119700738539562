import React, { useState } from 'react'
import { useEffect } from 'react';
import { Table } from 'rsuite'
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import recentQueueService from "../../../services/Internal/Recent/recentQueue.service"
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sort_by } from '../../Shared/HelperFunctions';



function AllRecentQueue() {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState("dateLastAccessed");
    const [sortType, setSortType] = useState("desc");
    const { push } = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        recentQueueService.getAllRecentQueue().then((response) => {
            if (response.data) {
                setGridData(response.data);
            }
            setHasLoaded(true);
        })

    }, [])
    const getData = () => {
        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    const filteredData = getData();

    const viewContact = (data) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: data.parentId });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: data.entityId });
        dispatch({ type: "PERSIST_CURRENT_APP_TYPE_ID", payload: data.appTypeId });
        dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: JSON.parse(data.routes) });
    }
    return (
        <>
            <Card>
                <Card.Header>
                    <h4>All Viewed Queue Items</h4>
                </Card.Header>
                <Card.Body>
                    {hasLoaded ? <>
                        <Table
                            onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            wordWrap autoHeight data={filteredData} >
                            <Table.Column width={200} fixed>
                                <Table.HeaderCell />
                                <Table.Cell>
                                    {rowData => {
                                        var link = JSON.parse(rowData.routes)[0].route;
                                        link = link.slice(0, link.lastIndexOf('/') + 1) + "sections?recentQueueId=" + rowData.id;
                                        return (
                                            <a className="btn btn-primary" href={link} onClick={() => viewContact(rowData)}>View</a>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Name</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="entityName">

                                    {rowData => {
                                        var display = rowData.entityName;
                                        var parentType = "";
                                        switch (rowData.parentTypeId) {
                                            case 1:
                                                parentType = "Reinstatement";
                                                break;
                                            case 2:
                                                parentType = "Application";
                                                break;
                                            case 3:
                                                parentType = "Renewal";
                                                break;
                                            default: break;
                                        }
                                        switch (rowData.appTypeId) {
                                            case 1:
                                                display += " (EI " + parentType + ")";
                                                break;
                                            case 2:
                                                display += " (LSI " + parentType + ")";
                                                break;
                                            case 3:
                                            case 4:
                                                display += " (PE " + parentType + ")";
                                                break;
                                            case 5:
                                            case 6:
                                                display += " (PLS " + parentType + ")";
                                                break;
                                            case 7:
                                                display += " (EF " + parentType + ")";
                                                break;
                                            case 8:
                                                display += " (VF " + parentType + ")";
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            display
                                        );
                                    }
                                    }
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={125} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Date Last Accessed</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="dateLastAccessed">{rowData => (new Date(rowData.dateLastAccessed).toLocaleString())}</Table.Cell>
                            </Table.Column>
                        </Table >
                        <GenericPagination dataLength={gridData.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                    </> : <LoadingSpinner />}
                </Card.Body>
            </Card>
        </>
    )
}
export default AllRecentQueue;