import { loremParagraphs } from "../../../fakeapi";
import React, { useState, useEffect } from "react";
import RedStar from "../GenericComponents/RedStar";

export const GetAppInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("application");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <div className="row">
                    <div className="col-md-12">
                        <ul>
                            <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
                            <li>Mandatory fields are indicated with "<RedStar />" (asterisk) to the left of the field</li>
                            <li>Louisiana Revised Statute 31:23 requires that every application by an individual for a professional license, permit or certificate shall require the applicant's social security number.
                                Disclosure of your social security number is mandatory for this purpose and for the purpose of establishing, modifying or enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support under La. R.S. 46:236.1.1, et seq.
                                Failure to provide your social security number for these mandatory purposes will result in the denial of your licensure, permit or certificate application.
                                Disclosure of your social security number is voluntary for disclosure to other state regulatory agencies, testing and examination vendors, law enforcement agencies, and other private federations and associations involved in professional regulation.
                                Such disclosure is for identification purposes only. Your social security number will not be released for any other purpose not provided by law.</li>
                            <li>Complete the following sections</li>
                            <ul>
                                <li>Personal Information – ensure you put LAPELS emails on your safe sender list</li>
                                <li>Verify Mailing Address</li>
                                <li>Verify Public Address</li>
                            </ul>
                        </ul>
                    </div>
                </div>

            );
            break;
        case "education":
            return (
                <div className="row">

                    <div className="col-md-12">
                        <p><b>The following Board Rules apply to your LSI certification (download the Board Rules from here:</b> (<a href="https://www.lapels.com/docs/Laws_and_Rules/Board_Rules.pdf" target="_blank">https://www.lapels.com/docs/Laws_and_Rules/Board_Rules.pdf</a>)</p>
                        <p>
                            <b>§105. Definitions</b>
                            <br />
                            <i>Accredited Land Surveying Curriculum</i> ― a curriculum approved by ANSAC/ABET, EAC/ABET or ETAC/ABET as a land surveying or geomatics academic program that satisfies the academic requirements for the practice of land surveying at the professional level.
                        </p>
                        <br />
                        <p>
                            <b>§907. Land Surveyor Intern Certification</b>
                            <br />
                            A. The requirements for certification as a land surveyor intern under the two alternatives provided in the licensure law are as follows:<br />
                            &emsp; 1. the applicant for certification as a land surveyor intern shall be a graduate holding a baccalaureate degree from a curriculum of four years or more who has completed at least 30 semester credit hours, or the equivalent, in land surveying, mapping, and real property courses approved by the board, who is of good character and reputation, who has passed the examination required by the board in the fundamentals of land surveying, who was recommended for certification by a professional land surveyor holding a valid license to engage in the practice of land surveying issued to him/her by proper authority of a state, territory, or possession of the United States, or the District of Columbia, who has submitted an application for certification in accordance with the requirements of R.S. 37:694, and who
                            was duly certified as a land surveyor intern by the board; or
                            <br />
                            &emsp; 2. the applicant for certification as a land surveyor intern shall be an individual certified by the board as a land surveyor in training or a land surveyor intern on or before January 1, 1995.
                        </p>
                        <br />
                        <p>
                            <b>§1107. Land Surveying, Mapping and Real Property Courses</b>
                            <br />
                            A. To qualify for certification as a land surveyor intern pursuant to §907.A.1, the “30 semester credit hours, or the equivalent, in land surveying, mapping, and real property courses approved by the board” shall include: <br />
                            &emsp; 1. 15 semester credit hours, or the equivalent, with a grade of “C-” or better in land surveying courses, at least three of which shall be in boundary surveying; <br />
                            &emsp; 2. three semester credit hours, or the equivalent, with a grade of “C-” or better in mapping courses; <br />
                            &emsp; 3. three semester credit hours, or the equivalent, with a grade of “C-” or better in real property courses; and <br />
                            &emsp; 4. nine semester credit hours, or the equivalent, with a grade of “C-” or better in either land surveying or mapping courses. <br />

                            B. The mapping courses referenced in Subsection A shall not include more than six semester credit hours, or the equivalent, in drafting, graphics, or computer-aided design (CAD). <br />

                            C. The real property courses referenced in Subsection A must cover subject matter germane to land surveying applications as they apply to real property, such as real property principles and real property law. <br />
                        </p>
                        {/*    <label><b>Education Records</b></label>*/}
                        {/*    <p className="mt-2">Indicate each college or university in chronological order where a degree was conferred or in progress, or where you have completed surveying coursework. Include the time spent at each institution and if graduated, the date conferred, the degree received, and the major as shown on your transcripts.</p>*/}
                        {/*    <p>We do not issue refunds for application fees.</p>*/}
                        {/*    <hr />*/}
                        {/*    <label><b>Course Evaluation</b></label>*/}
                        {/*    <p className="mt-2">Indicate each college or university in chronological order where a degree was conferred or in progress, or where you have completed surveying coursework. Include the time spent at each institution and if graduated, the date conferred, the degree received, and the major as shown on your transcripts.</p>*/}
                        {/*    <p>We do not issue refunds for application fees.</p>*/}
                        {/*    <p>If your school is not listed below, please send an email to <a href="mailto:janet@lapels.com">janet@lapels.com</a> with your course information so it can be added to the list.</p>*/}
                    </div>
                </div>
            )
            break;
        case "recommendation":
            return (
                <div className="row">
                    <div className="col-md-12">

                        <p className="mt-2">You must be recommended for certification by a US Professional Surveyor holding an active license.</p>
                        <p>After adding a recommender,  please click the send icon(paper airplane) next to the record in the table.  The recommender will receive a request by email from LOLA to verify or decline your request.  Once your recommender makes a decision, you will be notified by email of the result. </p>

                    </div>
                </div>

            )
            break;
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Application” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        case "violations":
            return (
                <p>
                    In the case of answering “Yes” to the question below about unauthorized or unlicensed practice (Question #1), explain in detail the facts surrounding such practicing or offering to practice surveying in Louisiana and submit a list of all projects/jobs on which you offered to provide or provided surveying services in Louisiana during such period.
                    Such list should include, for each such project/job, offer date, start date, completion date, client name, project/job name, project/job location and project/job descriptions. Upload additional pages if necessary.
                    <br />
                    <br />
                    In the case of answering “Yes” to any of the questions below other than the question about unauthorized or unlicensed practice, provide the date(s) and pertinent facts of the case(s) or proceeding(s), including the final disposition (even if the information was previously provided to the Board).
                    Upload additional pages if necessary.
                </p>
            )
        case "acknowledgement":
            return (
                <p>

                </p>
            )
        case "index":
            return (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <p><strong style={{ color: "red" }}>NOTE:</strong> Applications are subject to revision and modification at any time. In order to ensure timely processing, please verify that you are submitting the most current version of our application form.</p><br />
                            <ul>
                                <li>
                                    Read all of the instructions before you begin
                                </li>
                                <li>Much of this information may be included in your MY NCEES account</li>
                                <li>If you do not have a MY NCEES account, you must provide all required documents. </li>

                                <li>Complete all required sections of the application; incomplete applications will not be processed. </li>
                            </ul>
                        </div>
                    </div>

                </>

            )
            break;
        case "exam":
            return (
                <div className="row">
                    <div className="col-md-12">

                        <p className="mt-2">Fundamentals Exam (FS) is <span className="colorRed">required</span></p>
                        <p>Needs to be verified on your MyNCEES ID account.</p>

                    </div>
                </div>
            )
        default:
            return (
                <>
                    <h5 className="colorRed"></h5>
                    <span></span>
                </>
            )
            break;
    }
}
export const GetRenewalInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("renewal");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <ul>
                    <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
                    <li>Mandatory fields are indicated with "<RedStar />" (asterisk) to the left of the field</li>
                    <li>Louisiana Revised Statute 37:23 requires that every application by an individual for a professional license, permit or certificate shall require the applicant's social security number.
                        Disclosure of your social security number is mandatory for this purpose and for the purpose of establishing, modifying or enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support under La. R.S. 46:236.1.1, et seq.
                        Failure to provide your social security number for these mandatory purposes will result in the denial of your licensure, permit or certificate application.
                        Disclosure of your social security number is voluntary for disclosure to other state regulatory agencies, testing and examination vendors, law enforcement agencies, and other private federations and associations involved in professional regulation.
                        Such disclosure is for identification purposes only. Your social security number will not be released for any other purpose not provided by law.</li>
                    <li>Complete the following sections</li>
                    <ul>
                        <li>Personal Information – ensure you put LAPELS emails on your safe sender list</li>
                        <li>Verify Mailing Address</li>
                        <li>Verify Public Address</li>
                    </ul>
                </ul>
            );
        case "cpdRequirements":
            return (
                <ul>
                    <li><b>A PLS</b> must complete 8 CPD hours each calendar year (January – December);</li>
                    <li>Of the 8 hours, one hour must be in the Standards of Practice and one hour must be in Professional Ethics</li>
                    <ul>
                        <li>In the event you have earned more than 8 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year</li>
                        <li>You may not earn more than 8 hours in a calendar da;</li>
                    </ul>

                    <li><b>A PE</b> must complete 15 CPD hours each calendar year (January – December) </li>
                    <li>Of the 15 hours, one hour must be in Professional Ethics; </li>
                    <ul>
                        <li>If you design buildings or building systems, of the 15 hours, four hours must be in life safety/ADA compliance </li>
                        <li>In the event you have earned more than 15 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year </li>
                        <li>You may not earn more than 8 hours in a calendar day  </li>
                    </ul>

                    <li> <b>A Dual Licensee</b> must complete 15 CPD hours each calendar year (January – December) </li>
                    <li>Of the 15 hours, five hours must be in earned in each profession, one hour must be in Professional Ethics and one hour must be in the Standards of Practice and two must be in life safety/ADA </li>
                    <ul>
                        <li>If you design buildings or building systems, of the 15 hours, four hours must be in life safety/ADA compliance </li>
                        <li>In the event you have earned more than 15 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year </li>
                        <li>You may not earn more than 8 hours in a calendar day </li>
                    </ul>
                </ul>);
        case "licenseStatus":
            return (
                <ul>
                    <li><strong><u>Active</u></strong>: Individual has complied with the licensure and licensure renewal requirements (including CPD) of the Board and may offer and/or provide professional services in Louisiana.</li>

                    <li><strong><u>Inactive</u></strong>: Individual has chosen not to offer and/or provide professional services in Louisiana and has indicated that fact on his/her licensure renewal. Individual can represent himself/herself to the public as a P.E. Inactive, or a P.L.S. Inactive, but cannot otherwise offer and/or provide professional services in Louisiana. CPD Requirements do not apply.</li>

                    <li><strong><u>Retired (Normal)</u></strong>: Individual has chosen not to offer and/or provide professional services in Louisiana and
                        has indicated that fact on his/her licensure renewal. Individual must be at least 70 years
                        of age or have been a licensee of the Board for at least 35 years. Unless individual is
                        granted a waiver by the Board, the renewal fee is one-half of the current renewal fee for
                        the active status. Individual can represent himself/herself to the public as a P.E. Retired,
                        or a P.L.S. Retired, but cannot otherwise offer and/or provide professional services in
                        Louisiana. CPD requirements do not apply.</li>

                    <li><strong><u>Retired (Waiver Granted)</u></strong>: Individual is qualified for the retired status and has been granted a waiver of the renewal
                        fee by the Board. An individual qualified for the retired status may be granted a waiver
                        of the renewal fee if all criteria below are met:
                        <ul>
                            <li>individual is at least 70 years of age;</li>
                            <li>individual has been a licensee of the Board for at least 35 years continuously;</li>
                            <li>individual has never been subject to disciplinary action in any jurisdiction;</li>
                            <li>individual has never committed any of the offenses described in La. R.S. 37:698(A)(3), (4) or (5);</li>
                            <li>individual is of good character and reputation.</li>
                            <li>Individual can represent himself/herself to the public as a P.E. Retired, or a P.L.S.
                                Retired, but cannot otherwise offer and/or provide professional services in Louisiana.
                                CPD requirements do not apply.</li>
                            <li>If you wish to apply for this status based on the above criteria, download and complete the <a target="new" href="https://www.lapels.com/docs/Renewal_Invoices/Fall_2020/PE_PLS_Retired_Renewal_Invoice_AR.pdf">Retired Professionals (PE/PLS) Renewal Invoice</a>. Once completed, you can mail
                                in this invoice to LAPELS, or email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.</li>
                        </ul></li>
                </ul>
            )
        case "violations":
            return (
                <p>
                    If the answer to any of these questions is "Yes", provide the date(s) and pertinent facts of the case(s) or proceeding(s) including the final disposition (even if the information was previously provided to the Board). Upload additional pages if necessary.
                </p>
            )
        case "privacy":
            return (
                <p>You can choose below how your privacy options are setup. Please indicate how you would like LAPELS to send you information or share it with third parties/vendors</p>
            )
        case "acknowledgement":
            return (
                <>
                    <p>
                        I have read the Rules for Professional Engineers and Surveyors (LAC Title 46, Part LXI), and I agree to conduct my professional affairs in
                        accordance with these rules.
                    </p>
                </>
            )
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Renewal” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        default:
            return (
                <>
                    <ul><li>Read all of the instructions within each section of the renewal.</li>
                        <li>Complete all required sections.</li>
                    </ul>
                </>
            )
            break;
    }
}
export const GetReinstatementInformationContent = (page) => {
    switch (page) {
        case "contact":
            return (
                <ul>
                    <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
                    <li>Mandatory fields are indicated with "<RedStar />" (asterisk) to the left of the field</li>
                    <li>Louisiana Revised Statute 31:23 requires that every application by an individual for a professional license, permit or certificate shall require the applicant's social security number.
                        Disclosure of your social security number is mandatory for this purpose and for the purpose of establishing, modifying or enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support under La. R.S. 46:236.1.1, et seq.
                        Failure to provide your social security number for these mandatory purposes will result in the denial of your licensure, permit or certificate application.
                        Disclosure of your social security number is voluntary for disclosure to other state regulatory agencies, testing and examination vendors, law enforcement agencies, and other private federations and associations involved in professional regulation.
                        Such disclosure is for identification purposes only. Your social security number will not be released for any other purpose not provided by law.</li>
                    <li>Complete the following sections</li>
                    <ul>
                        <li>Personal Information</li>
                        <li>Verify Mailing Address</li>
                        <li>Verify Public Address</li>
                        <li>Ensure you add "lapels.com" to your "Safe Senders" list in your email application and/or (if applicable) in your network firewall.</li>
                    </ul>
                </ul>
            );
        case "violations":
            return (
                <p>
                    In the case of answering “Yes” to the question below about unauthorized or unlicensed practice (Question #1), explain in detail the facts surrounding such practicing or offering to practice engineering in Louisiana and submit a list of all projects/jobs on which you offered to provide or provided engineering services in Louisiana during such period.
                    Such list should include, for each such project/job, offer date, start date, completion date, client name, project/job name, project/job location and project/job descriptions. Upload additional pages if necessary.
                    <br />
                    <br />
                    In the case of answering “Yes” to any of the questions below other than the question about unauthorized or unlicensed practice, provide the date(s) and pertinent facts of the case(s) or proceeding(s), including the final disposition (even if the information was previously provided to the Board).
                    Upload additional pages if necessary.
                </p>
            )
        case "acknowledgement":
            return (
                <p>
                    Fields marked with an asterisk * are required.
                </p>
            )
        case "education":
            return (
                <p>
                    All transcripts must be verified in your NCEES record for EI/LSI reinstatement application processing.
                </p>
            )
        case "exam":
            return (
                <div className="row">
                    <div className="col-md-12">

                        <p className="mt-2">Fundamentals Exam (FLS) is <span className="colorRed">required</span></p>
                        <p>Needs to be verified on your MyNCEES ID account.</p>

                    </div>
                </div>
            )
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the reinstatement that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the reinstatment is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your reinstatement for your records</li>
                            <li>Select “Submit Reinstatement” to add your reinstatement to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the reinstatement and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        default:
            return (
                <>
                    <span className="colorRed">NOTE:</span> Reinstatements are subject to revision and modification at any time.
                    <ul><li>Read all of the instructions within each section of the reinstatement.</li>
                        <li>Complete all required sections.</li>
                    </ul>
                </>
            )
            break;
    }
}