import { loremParagraphs } from "../../../fakeapi";
import React, { useState, useEffect } from "react";
import RedStar from "../GenericComponents/RedStar";

export const GetAppInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("application");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <div className="row">
                    <div className="col-md-12">
                        <ul>
                            By clicking the Update Contact Information button, you will be taken to the Contact Module to make your changes.  After you save your changes, please navigate back to your application, renewal, or reinstatement to continue.
                        </ul>
                    </div>
                </div>

            );
            break;
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Application” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        case "violations":
            return (
                <p>
                    In the case of answering “Yes” to the question below about unauthorized or unlicensed practice (Question #1), explain in detail the facts surrounding such practicing or offering to practice engineering in Louisiana and submit a list of all projects/jobs on which the firm offered to provide or provided engineering services in Louisiana during such period.
                    Such list should include, for each such project/job, offer date, start date, completion date, client name, project/job name, project/job location and project/job descriptions, and names of firm representatives involved. Upload additional pages if necessary.
                    <br />
                    <br />
                    In the case of answering “Yes” to any of the questions below other than the question about unauthorized or unlicensed practice, provide the date(s) and pertinent facts of the case(s) or proceeding(s), including the final disposition (even if the information was previously provided to the Board). Upload additional pages if necessary.
                </p>
            )
        case "acknowledgement":
            return (
                <p>
                    Fields marked with an asterisk * are required.
                </p>
            )
        case "supervisingProfessional":
            return (
                <>
                    The full text of the Licensure Law and the Rules of the Board are available from the Board's website at <a href="https://www.lapels.com/lawsrules.html" target="_blank">https://www.lapels.com/lawsrules.html</a>.
                    <br />
                    <p className="pl-4" >§2305. Supervising Professional</p>
                    <ol>
                        <li>
                            Each firm licensed with the board shall designate one or more supervising professionals. Each supervising professional shall be a licensed professional:
                        </li>
                        <ul>
                            <li>
                                a. whose primary employment is with the firm on a full-time basis; or
                            </li>
                            <li>
                                b. whose secondary employment is with the firm, provided the supervising professional is an owner of the firm.
                            </li>
                        </ul>
                        <li>
                            The supervising professionals of an engineering firm shall be professional engineers. The supervising professionals of a land surveying firm shall be professional land surveyors.
                        </li>
                        <li>
                            The responsibilities of a supervising professional include:
                        </li>
                        <ul>
                            <li>a. renewal of the firm’s license and notification to the board of any change in the firm’s supervising professionals;</li>
                            <li>b. institution of and adherence to policies of the firm that are in accordance with the licensure law and the rules of the board; and</li>
                            <li>c. ensuring that all professional services provided by the firm are performed by or under the responsible charge of licensed professionals.</li>
                        </ul>
                        <li>
                            The supervising professional of a firm which participates in a joint venture shall be responsible for assuring that all professional services performed by the joint venture are rendered in conformity with the provisions of these rules.
                        </li>
                        <li>
                            Nothing herein shall prohibit a supervising professional from also being in responsible charge of professional services provided by the firm.
                        </li>
                        <li> A failure to comply with any of the provisions of this Chapter may subject both the licensed firm and the supervising professional to disciplinary action by the board.
                        </li>
                        <li><span style={{ color: 'red' }}>Each designated Supervising Professional <b><u>MUST</u></b> complete the quizzes (<a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a> ) and upload the results below.</span></li>
                        <li>Compliance with this Section will not be met by a contractual relationship between the firm and a licensed professional or a firm of licensed professionals in which such licensed professional or firm of licensed professionals is available on a consultative basis. Nor will it be considered
                        </li>
                    </ol>

                    <p>Each designated supervising professional of the firm hereby certifies that:</p>
                    <ol>
                        <li>As a Professional Land Surveyor, I have successfully completed the Board’s online Louisiana Laws and Rules Quiz, Louisiana Professionalism and Ethics Quiz, and Louisiana Standards of Practice for Boundary Surveys Quiz with a score of 90% or higher within the past two calendar years. Provide either evidence of prior successful completion or complete the online quiz at <a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a>.</li>
                        <li>As a Professional Engineer, I have successfully completed the Board’s online Louisiana Laws and Rules Quiz and Louisiana Professionalism and Ethics Quiz with a score of 90% or higher within the past two calendar years. Provide either evidence of prior successful completion or complete the online quiz at <a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a>.</li>
                        <li> I have read and understand the Laws and Rules of the Board (<a href="https://www.lapels.com/LawsRules.html" target="_blank">https://www.lapels.com/LawsRules.html</a>); and</li>
                        <li>I acknowledge my responsibility to inform the Board, in writing, within thirty (30) days of any change of my employment or supervising professional status with the firm.</li>
                    </ol>

                    <p>Please complete the information as requested.  If you have any questions, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.</p>

                </>
            )
        case "waiver":
            return (
                <p>
                    Firms which have the word(s), or any derivative thereof, “engineering” or “surveying” in their name should complete this form and submit to LAPELS for processing.  The approval letter will be returned to you by email and you will then be able to obtain your Louisiana Certificate of Authority from the Louisiana Secretary of State.<br /><br />

                    <p>After you obtain your Louisiana Certificate of Authority, you will need to upload it into the upload section below.</p><br />

                    <b> Louisiana Secretary of State - Commercial Division </b><br />
                    P.O. Box 94125<br />
                    Baton Rouge, LA 70804-9125<br />
                    Phone: 225-925-4704<br />
                    Fax: 225-932-5314<br />

                    <a href="http://www.sos.la.gov/Pages/default.aspx" target="_blank">http://www.sos.la.gov/Pages/default.aspx</a><br /><br />
                    If you have any questions about the waiver/certificate of authority section, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.

                </p>
            )
        case "index":
            return (
                <>
                    <div className="row">
                        <div className="col-md-12">

                            <p>Read all of the instructions before you begin</p>
                            <ul>

                                <li>The application fee is <b className="colorRed">non-refundable</b>.</li>

                                <li>The application must be accompanied by a Certificate of Good Standing or a Certificate of Authority from the Louisiana Secretary of State.
                                    You will be given the opportunity to upload this document with your application. Contact information for the Louisiana Secretary of State
                                    is as follows:
                                </li>
                                <ul>
                                    <li>Louisiana Secretary of State – Commercial Division <br />
                                        P.O. Box 94125<br />
                                        Baton Rouge LA 70804-9125<br />
                                        Telephone: (225) 925-4704 Fax: (225) 932-5314<br />
                                        Email: <a href="mailto:commercial@sos.louisiana.gov">commercial@sos.louisiana.gov</a><br />
                                        Website: <a href="https://www.sos.la.gov/BusinessServices/Pages/default.aspx" target="_blank">https://www.sos.la.gov/BusinessServices/Pages/default.aspx</a>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                    </div>

                </>

            )
            break;
        default:
            return (
                <>
                    <h5 className="colorRed"></h5>
                    <span></span>
                </>
            )
            break;
    }
}

export const GetRenewalInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("renewal");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <div className="row">
                    <div className="col-md-12">
                        <ul>
                            By clicking the Update Contact Information button, you will be taken to the Contact Module to make your changes.  After you save your changes, please navigate back to your application, renewal, or reinstatement to continue.
                        </ul>
                    </div>
                </div>

            );
            break;
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Renewal” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        case "violations":
            return (
                <p>
                    If the answer to any of these questions is "Yes", provide the date(s) and pertinent facts of the case(s) or proceeding(s) including the final disposition (even if the information was previously provided to the Board). Upload additional pages if necessary.
                </p>
            )
        case "supervisingProfessional":
            return (<>
                The full text of the Licensure Law and the Rules of the Board are available from the Board's website at <a href="https://www.lapels.com/lawsrules.html" target="_blank">https://www.lapels.com/lawsrules.html</a>.
                <br />
                <p className="pl-4" >§2305. Supervising Professional</p>
                <ol>
                    <li>
                        Each firm licensed with the board shall designate one or more supervising professionals. Each supervising professional shall be a licensed professional:
                    </li>
                    <ul>
                        <li>
                            a. whose primary employment is with the firm on a full-time basis; or
                        </li>
                        <li>
                            b. whose secondary employment is with the firm, provided the supervising professional is an owner of the firm.
                        </li>
                    </ul>
                    <li>
                        The supervising professionals of an engineering firm shall be professional engineers. The supervising professionals of a land surveying firm shall be professional land surveyors.
                    </li>
                    <li>
                        The responsibilities of a supervising professional include:
                    </li>
                    <ul>
                        <li>a. renewal of the firm’s license and notification to the board of any change in the firm’s supervising professionals;</li>
                        <li>b. institution of and adherence to policies of the firm that are in accordance with the licensure law and the rules of the board; and</li>
                        <li>c. ensuring that all professional services provided by the firm are performed by or under the responsible charge of licensed professionals.</li>
                    </ul>

                    <li>
                        Nothing herein shall prohibit a supervising professional from also being in responsible charge of professional services provided by the firm.
                    </li>
                    <li> A failure to comply with any of the provisions of this Chapter may subject both the licensed firm and the supervising professional to disciplinary action by the board.
                    </li>


                </ol>
                <p>
                    I acknowledge my responsibility to keep my SP locker current. I further understand that failure to comply with such requirements of any false statements made by me, on behalf of the firm, could be cause for disciplinary action. I acknowledge my responsibility to inform the Board, in writing, within thirty (30) days of any change of my employment or supervising professional status with the firm.
                </p>


                <p>Please complete the information as requested.  If you have any questions, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.</p>
                <hr />
                <div style={{ color: "red" }}>
                    <p>All Supervising Professionals <i><b><u>MUST</u></b></i> complete the attestation form <i><b><u>ONLY</u></b></i> during the firm's renewal period. Download the form from <a href="https://www.lapels.com/docs/Applications/firm/Firm_SP_Attestation_AR.pdf" target="_blank"><u>https://www.lapels.com/docs/Applications/firm/Firm_SP_Attestation_AR.pdf </u></a>
                        and upload the completed form using the "Upload" button below.<br /><br /><b>Failure to do so could result in a violation for both firm and individual.</b></p>

                </div>

            </>);
        case "acknowledgement":
            return (
                <p>
                    Fields marked with an asterisk * are required.
                </p>
            )
        case "waiver":
            return (
                <p>
                    Firms should search the LASOS website business filings, save the <b>detailed report</b> to your desktop, and then click or drag the file to the upload section below.<br /><br />
                    <p>After you obtain your Louisiana Certificate of Authority, you will need to upload it into the upload section below.</p><br />
                    <b> Louisiana Secretary of State - Commercial Division </b><br />
                    P.O. Box 94125<br />
                    Baton Rouge, LA 70804-9125<br />
                    Phone: 225-925-4704<br />
                    Fax: 225-932-5314<br />
                    <a href="http://www.sos.la.gov/Pages/default.aspx" target="_blank">http://www.sos.la.gov/Pages/default.aspx</a><br /><br />
                    If you have any questions about the certificate of authority section, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.

                </p>
            )
        case "index":
            return (
                <>
                    <div className="row">
                        <div className="col-md-12">

                            <p>Read all of the instructions before you begin</p>
                            <ul>

                                <li>The application fee is <b className="colorRed">non-refundable</b>.</li>

                                <li>The application must be accompanied by a Certificate of Good Standing or a Certificate of Authority from the Louisiana Secretary of State.
                                    You will be given the opportunity to upload this document with your application. Contact information for the Louisiana Secretary of State
                                    is as follows:
                                </li>
                                <ul>
                                    <li>Louisiana Secretary of State – Commercial Division <br />
                                        P.O. Box 94125<br />
                                        Baton Rouge LA 70804-9125<br />
                                        Telephone: (225) 925-4704 Fax: (225) 932-5314<br />
                                        Email: <a href="mailto:commercial@sos.louisiana.gov">commercial@sos.louisiana.gov</a><br />
                                        Website: <a href="https://www.sos.la.gov/BusinessServices/Pages/default.aspx" target="_blank">https://www.sos.la.gov/BusinessServices/Pages/default.aspx</a>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                    </div>

                </>

            )
            break;
        default:
            return (
                <>
                    <h5 className="colorRed"></h5>
                    <span></span>
                </>
            )
            break;
    }
}

export const GetReinstatementInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("reinstatement");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <div className="row">
                    <div className="col-md-12">
                        <ul>

                        </ul>
                    </div>
                </div>

            );
            break;
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Reinstatement” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        case "violations":
            return (
                <p>
                    In the case of answering “Yes” to the question below about unauthorized or unlicensed practice (Question #1), explain in detail the facts surrounding such practicing or offering to practice engineering in Louisiana and submit a list of all projects/jobs on which the firm offered to provide or provided engineering services in Louisiana during such period.
                    Such list should include, for each such project/job, offer date, start date, completion date, client name, project/job name, project/job location and project/job descriptions, and names of firm representatives involved. Upload additional pages if necessary.
                    <br />
                    <br />
                    In the case of answering “Yes” to any of the questions below other than the question about unauthorized or unlicensed practice, provide the date(s) and pertinent facts of the case(s) or proceeding(s), including the final disposition (even if the information was previously provided to the Board). U additional pages if necessary.
                </p>
            )
        case "acknowledgement":
            return (
                <p>
                    Fields marked with an asterisk * are required.
                </p>
            )
        case "supervisingProfessional":
            return (
                <>
                    The full text of the Licensure Law and the Rules of the Board are available from the Board's website at <a href="https://www.lapels.com/lawsrules.html" target="_blank">https://www.lapels.com/lawsrules.html</a>.
                    <br />
                    <p className="pl-4" >§2305. Supervising Professional</p>
                    <ol>
                        <li>
                            Each firm licensed with the board shall designate one or more supervising professionals. Each supervising professional shall be a licensed professional:
                        </li>
                        <ul>
                            <li>
                                a. whose primary employment is with the firm on a full-time basis; or
                            </li>
                            <li>
                                b. whose secondary employment is with the firm, provided the supervising professional is an owner of the firm.
                            </li>
                        </ul>
                        <li>
                            The supervising professionals of an engineering firm shall be professional engineers. The supervising professionals of a land surveying firm shall be professional land surveyors.
                        </li>
                        <li>
                            The responsibilities of a supervising professional include:
                        </li>
                        <ul>
                            <li>a. renewal of the firm’s license and notification to the board of any change in the firm’s supervising professionals;</li>
                            <li>b. institution of and adherence to policies of the firm that are in accordance with the licensure law and the rules of the board; and</li>
                            <li>c. ensuring that all professional services provided by the firm are performed by or under the responsible charge of licensed professionals.</li>
                        </ul>
                        <li>
                            The supervising professional of a firm which participates in a joint venture shall be responsible for assuring that all professional services performed by the joint venture are rendered in conformity with the provisions of these rules.
                        </li>
                        <li>
                            Nothing herein shall prohibit a supervising professional from also being in responsible charge of professional services provided by the firm.
                        </li>
                        <li> A failure to comply with any of the provisions of this Chapter may subject both the licensed firm and the supervising professional to disciplinary action by the board.
                        </li>
                        <li><span style={{ color: 'red' }}>Each designated Supervising Professional <b><u>MUST</u></b> complete the quizzes (<a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a> ) and upload the results below.</span></li>
                        <li>Compliance with this Section will not be met by a contractual relationship between the firm and a licensed professional or a firm of licensed professionals in which such licensed professional or firm of licensed professionals is available on a consultative basis. Nor will it be considered
                        </li>
                    </ol>

                    <p>Each designated supervising professional of the firm hereby certifies that:</p>
                    <ol>
                        <li>As a Professional Land Surveyor, I have successfully completed the Board’s online Louisiana Laws and Rules Quiz, Louisiana Professionalism and Ethics Quiz, and Louisiana Standards of Practice for Boundary Surveys Quiz with a score of 90% or higher within the past two calendar years. Provide either evidence of prior successful completion or complete the online quiz at <a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a>.</li>
                        <li>As a Professional Engineer, I have successfully completed the Board’s online Louisiana Laws and Rules Quiz and Louisiana Professionalism and Ethics Quiz with a score of 90% or higher within the past two calendar years. Provide either evidence of prior successful completion or complete the online quiz at <a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a>.</li>
                        <li> I have read and understand the Laws and Rules of the Board (<a href="https://www.lapels.com/LawsRules.html" target="_blank">https://www.lapels.com/LawsRules.html</a>); and</li>
                        <li>I acknowledge my responsibility to inform the Board, in writing, within thirty (30) days of any change of my employment or supervising professional status with the firm.</li>
                    </ol>

                    <p>Please complete the information as requested.  If you have any questions, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.</p>

                </>
            )
        case "waiver":
            return (
                <p>
                    Firms which have the word(s), or any derivative thereof, “engineering” or “surveying” in their name should complete this form and submit to LAPELS for processing.  The approval letter will be returned to you by email and you will then be able to obtain your Louisiana Certificate of Authority from the Louisiana Secretary of State.<br /><br />
                    <p>After you obtain your Louisiana Certificate of Authority, you will need to upload it into the upload section below.</p><br />
                    <b> Louisiana Secretary of State - Commercial Division </b><br />
                    P.O. Box 94125<br />
                    Baton Rouge, LA 70804-9125<br />
                    Phone: 225-925-4704<br />
                    Fax: 225-932-5314<br />
                    <a href="http://www.sos.la.gov/Pages/default.aspx" target="_blank">http://www.sos.la.gov/Pages/default.aspx</a><br /><br />
                    If you have any questions about the waiver/certificate of authority section, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.

                </p>
            )
        case "index":
            return (
                <>
                    <div className="row">
                        <div className="col-md-12">

                            <p>Read all of the instructions before you begin</p>
                            <ul>

                                <li>The application fee is <b className="colorRed">non-refundable</b>.</li>

                                <li>The application must be accompanied by a Certificate of Good Standing or a Certificate of Authority from the Louisiana Secretary of State.
                                    You will be given the opportunity to upload this document with your application. Contact information for the Louisiana Secretary of State
                                    is as follows:
                                </li>
                                <ul>
                                    <li>Louisiana Secretary of State – Commercial Division <br />
                                        P.O. Box 94125<br />
                                        Baton Rouge LA 70804-9125<br />
                                        Telephone: (225) 925-4704 Fax: (225) 932-5314<br />
                                        Email: <a href="mailto:commercial@sos.louisiana.gov">commercial@sos.louisiana.gov</a><br />
                                        Website: <a href="https://www.sos.la.gov/BusinessServices/Pages/default.aspx" target="_blank">https://www.sos.la.gov/BusinessServices/Pages/default.aspx</a>
                                    </li>
                                </ul>
                                <li>The application fee referenced above is not a renewal fee. Upon receipt and approval of the application,
                                    your firm’s license will be placed in the current renewal cycle. Renewal invoices are mailed approximately
                                    five weeks prior to the expiration date of either March 31st or September 30th.</li>
                            </ul>
                        </div>
                    </div>

                </>

            )
            break;
        default:
            return (
                <>
                    <h5 className="colorRed"></h5>
                    <span></span>
                </>
            )
            break;
    }
}
