import React, { useState } from 'react';
import ViolationIssueCommentItem from './ViolationIssueCommentItem';
import { Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap';
import askLolaService from '../../../services/Shared/askLola.service';

function AskLolaComment({ comments, issueClosed, askLolaId }) {
    const [issueComments, updateIssueComments] = useState(comments);
    const [commentBox, updateCommentBox] = useState("");
    const commentsMarkup = issueComments.map((item, i) => {
        return <Row key={i}><Col><ViolationIssueCommentItem person={item.formattedName} commentDate={new Date(item.dateCrte).toLocaleString()} comment={item.text} /></Col></Row>
    })
    const commentAdd = () => {
        if (commentBox) {
            askLolaService.updateAskLola({ askLolaId: askLolaId, text: commentBox }).then((response) => {
                if (response.data) {
                    updateIssueComments([...issueComments, response.data]);
                    updateCommentBox("");
                }
            });
        }
    }

    return (
        <div className="issuesCommentSection">
            <Row><Col><h5>Messages</h5></Col></Row>
            <hr />
            {commentsMarkup}
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Add Comment"
                            aria-label="Add Comment"
                            aria-describedby="basic-addon2"
                            value={commentBox}
                            onChange={e => updateCommentBox(e.target.value)}
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    commentAdd();
                                }
                            }}
                            disabled={issueClosed ? "disabled" : ""}
                        />
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={commentAdd} disabled={issueClosed ? "disabled" : ""}>Submit</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}

export default AskLolaComment;