import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import designService from '../../../../../../services/Public/Renewal/PE/design.service';
import peRenewalService from '../../../../../../services/Public/Renewal/PE/peRenewal.service';
import renewalService from '../../../../../../services/Public/Renewal/renewal.service';
import Information from '../../../../../Shared/Card/Information';
import Issues from '../../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import RedStar from '../../../../../Shared/GenericComponents/RedStar';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import LockedRenewalModal from '../../../../../Shared/Modal/LockedRenewalModal';
import YesNoRadioOption from '../../../../../Shared/RadioButton/YesNoRadioOption';

function BuildingDesignPage() {
    const renewalId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const [isLicenseStatusSet, setIsLicenseStatusSet] = useState(true);
    const [isLicenseStatusNonactiveToActive, setIsLicenseStatusNonactiveToActive] = useState(false);
    const [showLockedRenewalModal, setShowLockedRenewalModal] = useState(false);
    const year1 = new Date().getFullYear() - 2;
    const year2 = new Date().getFullYear() - 1;
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isBuildingdesignRequired, setIsBuildingdesignRequired] = useState(true);
    const [model, setModel] = useState({
        hasLaBuildingDesign: '',
        hasSafetyCodeHours: null,
        renewalId: renewalId,
        entityId: entityId
    });
    const { push } = useHistory();
    const sectionId = 10;
    const routes = useSelector(x => x.currentRoutes);
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        peRenewalService.peGetSection(renewalId, sectionId).then((data) => {
            setSectionData(data.data);
            designService.isBuildingDesignRequired(renewalId).then((result) => {//if result is null, no status was chosen
                if (result.data) {
                    designService.getBuildingDesign(renewalId).then((response) => {
                        if (response.data)
                            setModel({
                                ...response.data
                            })
                        designService.isNonactiveToActive(renewalId).then((status) => setIsLicenseStatusNonactiveToActive(status.data));
                        setHasLoaded(true)
                    })
                }
                else if (result.data === false) {
                    setIsBuildingdesignRequired(false)
                    setHasLoaded(true)
                }
                else {
                    setIsLicenseStatusSet(false)
                    setHasLoaded(true)
                }
            })
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const validationModel = Schema.Model({
        hasLaBuildingDesign: BooleanType().isRequired("This field is required."),
        hasSafetyCodeHours: BooleanType().isRequired("This field is required.")
    });
    const notRequiredSubmit = (event) => {
        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
    }
    const formSubmit = (val, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (val || model.hasLaBuildingDesign == false) {
                var dataToTransmit = { ...model };
                if (model.hasLaBuildingDesign == false) {
                    dataToTransmit = { ...model, hasSafetyCodeHours: null };
                }
                renewalService.wasHeldForBuildingDesign(renewalId).then((wasHeld) => {
                    designService.postBuildingDesign(dataToTransmit).then((response) => {

                        if (response.data) {

                            if (response.data.causedApplicationHold === true) {
                                if (wasHeld.data) {
                                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                                } else {
                                    setShowLockedRenewalModal(true);
                                }
                            }
                            else {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                            }
                        }
                    })
                })
            }
        }
    }

    return (
        <div className="peRenewalDesignPage">
            <Container fluid>
                <b>Professional Engineer Renewal</b>
                <h4 className="mb-3">Building Design</h4>
                <Information content={GetRenewalInformationContent("design")} />
                <LockedRenewalModal sectionId={sectionId} show={showLockedRenewalModal} onHide={() => setShowLockedRenewalModal(false)} />
                <br />{hasLoaded ? !isLicenseStatusSet ?
                    <Form onSubmit={notRequiredSubmit}>
                        <Card>
                            <Card.Header>
                                <h5>Building Design in Louisiana</h5>
                            </Card.Header>
                            <Card.Body>
                                <span>Please go back and choose a license status to renew into before completing this section. You may access that section by clicking the "Back to Sections" button at the bottom of the page.</span>
                            </Card.Body>
                        </Card>
                        <br />
                        <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/peRenewal/sections" />
                    </Form> : isBuildingdesignRequired ?
                        <Form formValue={model} onSubmit={formSubmit} onChange={setModel} model={validationModel} readOnly={!sectionData.isUserEditable} >
                            <Card>
                                <Card.Header><h6>Building Design in Louisiana</h6></Card.Header>
                                <Card.Body>
                                    {isLicenseStatusNonactiveToActive ?
                                        <p className="mb-3"><RedStar /> Have you designed buildings or building systems in Louisiana since your license was no longer in Active status?</p> :
                                        <p className="mb-3"><RedStar /> Have you designed buildings or building systems in Louisiana since your last renewal?</p>}
                                    {/*<p className="mb-3"><RedStar /> Have you designed buildings or building systems in Louisiana since your last renewal?</p>*/}
                                    <YesNoRadioOption radioName="hasLaBuildingDesign" />
                                    {model.hasLaBuildingDesign == true &&
                                        <>
                                            <hr />
                                            <h6 className="mb-3">Safety Code Hours</h6>
                                            <p className="mb-3">Do you have 4 PDH’s of Life Safety Code, building codes, and/or Americans with Disabilities Act Accessibility Guidelines <strong>per calendar year(s)</strong> when you designed buildings or buildings systems in Louisiana?</p>
                                            <YesNoRadioOption radioName="hasSafetyCodeHours" />
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                            <br />
                            <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />
                            <FormActionButtons backToSectionLink="/individual/peRenewal/sections" />
                        </Form> : <Form onSubmit={notRequiredSubmit}>
                            <Card>
                                <Card.Header>
                                    <h5>Building Design in Louisiana</h5>
                                </Card.Header>
                                <Card.Body>
                                    <span>Due to the license status chosen at the beginning of this renewal, this section is not required. You may click "Next Section" to continue your renewal.</span>
                                </Card.Body>
                            </Card>
                            <br />
                            <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/peRenewal/sections" />
                        </Form> : <LoadingSpinner />
                }
            </Container >
        </div >
    )
}
export default BuildingDesignPage;