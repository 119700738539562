import React from 'react';
import { Card, Button, Row, Container, Col } from 'react-bootstrap'
import License from './License';
import PendingApplications from './PendingApplications';

function Profile({ profileInfo, credentials, pendingApps}) {
    let licenses = credentials !== undefined ? credentials.map((item, i) => {
        return (
            <React.Fragment key={i}>
            <Row className="mb-3">
                    <License  data={item}/>
            </Row>
            </React.Fragment>
        )
    }) : null;

    const hasPendingApps = pendingApps.length > 0;

    return (
        <div className="dashboardProfile">
            <Card className="h-100">
                <Card.Header><h6>Profile</h6></Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={4}><strong>Name</strong></Col>
                            <Col lg={8}>{profileInfo.name}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={4}><strong>LOLA #</strong></Col>
                            <Col lg={8}>{profileInfo.lolaNumber}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={4}><strong>UserID</strong></Col>
                            <Col lg={8}>{profileInfo.userName}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={4}><strong>Email Address</strong></Col>
                            <Col lg={8}><a href={'mailto:' + profileInfo.email}>{profileInfo.email}</a></Col>
                        </Row>
                        {hasPendingApps &&
                            <React.Fragment>
                            <Row className="mb-3">
                            <PendingApplications data={pendingApps} />
                            </Row>
                            </React.Fragment>
                        }
                        {licenses}
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Profile;