import axios from "axios";
import authHeader from '../../../authheader'

const EFAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/FirmEfApplication";

const getUnlicensedActivity = (applicationId) => {
    return axios.get(`${EFAPPLICATIONURL}/unlicensedActivity`, { headers: authHeader(), params: { applicationId } });
}
const postUnlicensedActivity = (data) => {
    return axios.post(`${EFAPPLICATIONURL}/unlicensedActivity`, data, { headers: authHeader() });
}
export default {
    getUnlicensedActivity,
    postUnlicensedActivity
}