import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function FormEntityInformation(props) {
    const [entityInformation, setEntityInformation] = useState({
        formType: "",
        currentSection: "",
        isIndividual: "",
        entityId: 0,
        entityName: "",
        licenseNumber: "",
        lolaNum: "",
        applicationDate: ""

    })
    const dispatch = useDispatch();
    const updateRecentContacts = () => {
        var token = parseInt(localStorage.getItem('updateRecentContacts')) ? parseInt(localStorage.getItem('updateRecentContacts')) : 0;
        localStorage.setItem('updateRecentContacts', token + 1)
        dispatch({ type: "PERSIST_CURRENT_RECENT_CONTACTS", payload: parseInt(localStorage.getItem('updateRecentContacts')) });
    }
    useEffect(() => {
        setEntityInformation({
            ...props
        })
    }, [props])

    return (
        <>
            <strong>{entityInformation.currentSection ? (`${entityInformation.formType}`) : ""}</strong>
            <h4>{entityInformation.currentSection ? (`${entityInformation.currentSection}`) : (`${entityInformation.formType}`)} - <a href={entityInformation.isIndividual ? `internal/indCredential?entityId=${entityInformation.entityId}` : `internal/firmCredential?entityId=${entityInformation.entityId}`} target="_blank" onClick={() => updateRecentContacts()}>{entityInformation.entityName}</a> {entityInformation.licenseNumber ? `- ${entityInformation.licenseNumber}` : ""}</h4>
            <div className="mb-1">LOLA #{entityInformation.lolaNum}</div>
            <div className="mb-3">Application Date: {entityInformation.applicationDate ? new Date(entityInformation.applicationDate).toLocaleDateString() : ""}</div>
        </>
    )
}
export default FormEntityInformation;

