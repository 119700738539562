import React from 'react';
import { Card } from 'react-bootstrap'
import { FormControl, SelectPicker, Table } from 'rsuite';
import RedStar from '../../../../../Shared/GenericComponents/RedStar';
function LicenseStatus(props) {
 
    return (
        <div className="peRenewalLicenseStatus">
            <Card>
                <Card.Header><h6>Your License Status</h6></Card.Header>
                <Card.Body>
                    <Card>
                        <Card.Body>
                            <Table wordWrap autoHeight data={props.gridData} >
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Credential</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="credential" />
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Credential Status</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="credentialStatus" />
                                </Table.Column>
                            </Table>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <strong>I am renewing into the following status:<RedStar /></strong>
                            <FormControl accepter={SelectPicker}
                                placeholder="Select Status"
                                searchable={false}
                                block
                                readOnly={ props.isReadOnly}
                                data={props.peRenewalLicenseStatuses}
                                name="renewingTo" />
                        </Card.Body>
                    </Card>
                </Card.Body>
            </Card>
        </div>
    )
}

export default LicenseStatus;