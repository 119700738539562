import axios from "axios";
import authHeader from '../../../authheader'

const EFREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/FirmEfReinstatement";

const getUnlicensedActivity = (reinstatementId) => {
    return axios.get(`${EFREINSTATEMENTURL}/unlicensedActivity`, { headers: authHeader(), params: { reinstatementId } });
}
const postUnlicensedActivity = (data) => {
    return axios.post(`${EFREINSTATEMENTURL}/unlicensedActivity`, data, { headers: authHeader() });
}
export default {
    getUnlicensedActivity,
    postUnlicensedActivity
}