import React from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'rsuite';
import DocumentUpload from '../../../Shared/Upload/DocumentUpload';

function BulkCourseUpload(props) {

    return (

        <Card>
            <Card.Header>
                <h6>Record Uploads (Logs and Certificates)</h6>
            </Card.Header>
            <Card.Body>
                <Form>
                    <DocumentUpload
                        isUserEditable={true}
                        attachmentSection={"Cpd"}
                        parentId={props.entityId}
                        parentTypeId={11}
                        entityId={props.entityId}
                        fileUploadTitle={" "}
                    />
                </Form>
            </Card.Body>
        </Card>
    )
}

export default BulkCourseUpload;