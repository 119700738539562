import React, { useState } from 'react';
import { Container, Row, Col, Card, DropdownButton, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import applicationService from '../../../../services/Public/Application/application.service'
import { Table } from 'rsuite';
import { codeApplicationType } from '../../../../fakeCodeTable';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import Swal from 'sweetalert2';
import { GetAppInstruction } from '../../../Shared/InformationContent/GeneralInformation';
import Information from '../../../Shared/Card/Information';

function Applications() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showDropDown, setShowDropDown] = useState(true);
    const [applications, setApplications] = useState([]);

    const [hasLoadedHistorical, setHasLoadedHistorical] = useState(false);
    const [historicalRecords, setHistoricalRecords] = useState([]);

    const [currentAppTypeIds, setCurrentAppTypeIds] = useState([]);
    const [currentCredentialTypes, setCurrentCredentialTypes] = useState([]);
    const { push } = useHistory();
    const dispatch = useDispatch();
    const createApplication = (id) => {
        applicationService.createFirmApplication(id).then((response) => {
            if (response.status == 200) {
                applicationService.getFirmApplication(response.data).then((response) => {
                    navigateAndSetCurrentApp(response.data)
                })
            }
            else {
                //give an alert of the fail
            }
        })
    }
    let applicationDropDown =
        <DropdownButton as={ButtonGroup} variant='primary' title="Start a New Application">
            {

                codeApplicationType.map((item, i) => {
                    if (!currentAppTypeIds.includes(item.abbreviation) && !currentCredentialTypes.includes(item.abbreviation) && (item.id == 7 || item.id == 8)) {
                        return (<Dropdown.Item value={item.id} key={item.id} onClick={x => createApplication(item.id)}> {item.applicationType} ({item.abbreviation})</Dropdown.Item>)
                    }
                })
            }
        </DropdownButton>;

    useEffect(() => {
        refreshApplicationGrid();
        applicationService.getFirmHistoricalApplications().then((response) => {
            if (response.data?.apps.length) {

                var sortedApps = response.data.apps.sort((a, b) => {
                    return new Date(b.dateSubmitted) - new Date(a.dateSubmitted)
                });
                setHistoricalRecords({ creds: response.data.creds, apps: sortedApps });
            }
            setHasLoadedHistorical(true)
        });
    }, [])
    const refreshApplicationGrid = () => {
        setHasLoaded(false);
        applicationService.getFirmPendingApplications().then((response) => {
            setApplications(
                response.data?.apps)
            setCurrentAppTypeIds(response.data?.apps.map(x => x.applicationType?.abbreviation)); // Will need to get user certifications later to be part of this to filter out
            setCurrentCredentialTypes(response.data?.creds?.map(x => x.credentialType?.prefix)); // Will need to get user certifications later to be part of this to filter out
            if (response.data?.apps?.length + response.data?.creds?.length == 2) //firm currently has two app types, if this increases we need to increase this number
                setShowDropDown(false);
            else
                setShowDropDown(true);
            setHasLoaded(true);
        })
    }

    const deleteDraft = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                applicationService.deleteFirmDraft(rowData.id).then((response) => {
                    refreshApplicationGrid();
                })
            }
        })
    }
    const navigateAndSetCurrentApp = (rowData) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: rowData.id });
        if (rowData?.sections?.length) {
            var route = [];

            for (var i = 0; i < rowData.sections.length; i++) {
                var link = rowData?.applicationType?.id == 7 ? "/firm/efApp/" : "/firm/vfApp/";
                let sectionId = rowData.sections[i].sectionType.id
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 13:
                        link += "waiver"
                        break;
                    case 14:
                        link += "supervisingProfessional"
                        break;
                    case 4:
                        link += "violations"
                        break;
                    case 15:
                        link += "unlicensedActivity"
                        break;
                    case 8:
                        link += "acknowledgement"
                        break;
                    case 9:
                        link += "review"
                        break;
                    default:
                        break;
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }

        }

        dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: route })
        var sectionsLink = rowData?.applicationType?.id == 7 ? "/firm/efApp/sections" : "/firm/vfApp/sections";
        push(sectionsLink);
    }

    return (
        <div className="applicationsPage">

            <Container fluid>
                <h4 className="mb-3">Applications</h4>
                <Row>
                    <Col><Information content={GetAppInstruction()} /></Col>
                </Row>
                <br />
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row>
                                    {hasLoaded && showDropDown &&
                                        <Col>
                                            <div className="float-right">
                                                {applicationDropDown}
                                            </div>
                                        </Col>}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {hasLoaded ?
                                    <Table wordWrap autoHeight data={applications}>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (
                                                    <>
                                                        <Button onClick={() => navigateAndSetCurrentApp(rowData)}> {
                                                            rowData.status?.status === "Draft"
                                                                ? "Continue"
                                                                : rowData.sections?.filter(x => x.isUserEditable == true).length
                                                                    ? "Continue"
                                                                    : "View"}</Button>
                                                        {rowData.status?.status === "Draft" &&
                                                            <Button className="ml-1" variant="danger" onClick={() => deleteDraft(rowData)}>Delete</Button>
                                                        }
                                                    </>
                                                )}
                                            </Table.Cell>
                                        </Table.Column>

                                        <Table.Column flexGrow={3}>
                                            <Table.HeaderCell>
                                                Application Type
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.applicationType?.type)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Created
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Submitted
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateSubmitted ? new Date(rowData.dateSubmitted).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Review Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>

                                    </Table> : <LoadingSpinner />
                                }

                            </Card.Body>
                        </Card></Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header><h6>Historical Applications</h6></Card.Header>
                            <Card.Body>
                                {hasLoadedHistorical ?
                                    <Table wordWrap autoHeight data={historicalRecords.apps}>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Application Type
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.applicationType?.type)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => {
                                                    var cred = historicalRecords.creds.filter(x => x.applicationId == rowData.id);
                                                    if (cred[0].credential)
                                                        return (cred[0].credential.status?.status)
                                                }}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Expiration Date
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => {
                                                    var cred = historicalRecords.creds.filter(x => x.applicationId == rowData.id);
                                                    if (cred[0].credential)
                                                        return (cred[0].credential.expirationDate ? new Date(cred[0].credential.expirationDate).toLocaleDateString() : '')
                                                }}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Created
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Submitted
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateSubmitted ? new Date(rowData.dateSubmitted).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Approved/Declined
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => {
                                                    var cred = historicalRecords.creds.filter(x => x.applicationId == rowData.id);
                                                    if (cred.length)
                                                        return (cred[0].dateActioned ? new Date(cred[0].dateActioned).toLocaleDateString() : '')
                                                }}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Review Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>

                                    </Table> : <LoadingSpinner />
                                }
                            </Card.Body>
                        </Card></Col>
                </Row>
            </Container>
        </div >
    )
}
export default Applications;