import React, { useState } from 'react'
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Acknowledgement from '../../../../Shared/Card/Acknowledgement';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import { useSelector } from 'react-redux';
import renewalService from '../../../../../services/Internal/Renewal/renewal.service';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function AcknowledgementPage() {
    const [hasLoaded, setHasLoaded] = useState(false)
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const sectionId = 7;
    const [isComplete, isCompleteCallBack] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const routes = useSelector(x => x.currentRoutes);
    const renewalId = useSelector(x => x.currentApplicationId);
    const [formModel, setFormModel] = useState({});
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Acknowledgement"
        }
    );
    useEffect(() => {
        renewalService.plsGetSection(renewalId, sectionId).then((response) => {
            setFormModel(response.data);
            isCompleteCallBack(response.data.staffReviewComplete);
            renewalService.getIndividualRenewal(renewalId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }

    const submitData = (isForSubmit) => {
        renewalService.plsPostSection(renewalId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
                renewalService.plsMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <Form readOnly={!isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                    <FormEntityInformation {...entityInfo} />
                    <Information content={GetRenewalInformationContent()} className="mb-3" />
                    <br />
                    <Acknowledgement appType="renewal" />
                    <br />
                    <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={formModel.id} setIsComplete={isCompleteCallBack} />
                    <FormActionButtons
                        isUserEditable={isUserEditable}
                        showSectionComplete={true}
                        isComplete={isComplete}
                        isCompleteCallback={isCompleteCallBack}
                        backToSectionLink="/internal/plsRenewal/sections" />
                </Form>
            </Container>
        </div>
    );
}
export default AcknowledgementPage