import axios from "axios";
import authHeader from '../../authheader.js'


const RECENTCONTACTSURL = process.env.REACT_APP_APIURL + "/api/RecentContacts";

const getRecentContacts = async () => {
    return axios.get(`${RECENTCONTACTSURL}/getrecentcontacts`, { headers: authHeader() });
};
const getAllRecentContacts = async () => {
    return axios.get(`${RECENTCONTACTSURL}/getallrecentcontacts`, { headers: authHeader() });
};
const updateRecentContacts = (recentContact) => {
    return axios.post(`${RECENTCONTACTSURL}/updaterecentcontacts`, recentContact, { headers: authHeader() });
}

export default {
    getRecentContacts,
    getAllRecentContacts,
    updateRecentContacts

}