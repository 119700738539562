import { Checkbox, ControlLabel, Form, FormControl, FormGroup, InputPicker, Schema } from 'rsuite'
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { getStates, getFirmTypes } from '../../../services/Shared/codeTable.service';
import AuthService from "../../../services/auth.service";

const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};
function FirmForm() {
    const [showPass, toggleShowPass] = useState(false);
    const history = useHistory();
    const [states, setStates] = useState([]);
    const [firmTypes, setFirmTypes] = useState([]);
    const [inputType, setInputType] = useState("text");
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        getStates().then(setStates);
        getFirmTypes().then(setFirmTypes);
        setHasLoaded(true)
    }, [])
    const { StringType, DateType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        fein: StringType().isRequired('This field is required.').pattern(/^[0-9]{2}-[0-9]{7}$/, "Please enter a vaild FEIN"),
        userName: StringType().addRule((value, data) => {
            //return AuthService.checkUsername(value).then((response) => {
            //    return response.data;//assuming this is a bool value
            //})
            return true;
        }, 'This username is already in use').addRule((value, data) => {
            var alphaNumeric = /^[0-9a-zA-Z]+$/;
            if (value.match(alphaNumeric))
                return true;
            else {
                return false;
            }
        }, "Invalid characters in username. Please use numbers or letters only.").isRequired('This field is required.'),
        email: StringType().isRequired('This field is required.').isEmail("This is not a valid Email."),
        confirmEmail: StringType().isRequired('This field is required.').addRule((value, data) => {
            if (value !== data.email) {
                return false;
            }
            return true;
        }, "Emails don't match").isEmail("This is not a valid Email."),
        password: StringType().pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/, 'Password requires a minimum of 8 characters using upper and lower case letters, and at least 1 number and 1 symbol').isRequired('This field is required.'),
        confirmPassword: StringType().isRequired('This field is required.').addRule((value, data) => {
            if (value !== data.password) {
                return false;
            }
            return true;
        }, "Passwords don't match"),
        firmName: StringType().isRequired("This field is required."),
        domicileState: ObjectType().isRequired("This field is required."),
        firmType: ObjectType().isRequired("This field is required."),
        formationDate: DateType().isRequired("This field is required."),
        contactName: StringType().isRequired("This field is required."),
        website: StringType().isURL("Please enter a valid URL (http://google.com)")

    })
    const [state, setState] = useState({
        firmName: "",
        fein: "",
        contactName: "",
        website: "",
        email: "",
        confirmEmail: "",
        firmType: "",
        domicileState: "",
        formationDate: "",
        password: "",
        confirmPassword: "",
        userName: ""
    });
    const handleFirmFormSubmit = (validation, event) => {
        var validationObject = model.check(state);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                return;
            }
        }
        AuthService.registerFirm(state).then(
            (data) => {
                if (data.data.isSuccess) {
                    history.push("/registerSuccess");
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(resMessage);
            }
        );
    }
    return (
        <Form id="firmForm" fluid formValue={state} model={model} onChange={setState} onSubmit={handleFirmFormSubmit}>
            <Row className="mb-4 pb-1">
                <Col>
                    <FormControl name="firmName" placeholder="Firm Name" />
                </Col>
                <Col>
                    <FormControl name="contactName" placeholder="Contact name" />
                </Col>
                <Col>
                    <FormControl name="email" placeholder="Company Email" checkTrigger="blur"/>
                </Col>
            </Row>
            <Row className="mb-4 pb-1">
                <Col>
                    <FormControl
                        accepter={InputMask}
                        placeholder="FEIN"
                        name="fein"
                        format="##-#######"
                        mask="_"
                    />
                </Col>
                <Col>
                    <FormControl name="website" placeholder="Website" />
                </Col>
                <Col>
                    <FormControl name="confirmEmail" placeholder="Confirm Company Email" checkTrigger="blur" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormControl
                        block={true}
                        name="firmType"
                        placeholder="Select a Firm Type"
                        accepter={InputPicker}
                        data={firmTypes}
                    />
                </Col>
                <Col>
                    <FormControl
                        block={true}
                        name="domicileState"
                        placeholder="Select Firm Domicile State"
                        searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                        accepter={InputPicker}
                        data={states}
                    />
                </Col>
                <Col>
                    <FormControl
                        type={inputType}
                        name="formationDate"
                        onFocus={() => setInputType("date")}
                        onBlur={(e) => { if (!e.target.value) { setInputType("text") } }}
                        placeholder="Date Formed / Incorporated"
                    />
                </Col>
            </Row>
            <br />
            <hr />
            <Row>
                <Col>
                    <FormGroup>
                        <FormControl placeholder="User Id" name="userName" errorPlacement="topStart" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormControl type={showPass ? "text" : "password"} placeholder="Password" checkTrigger="blur" name="password" title="Password requires a minimum of 8 characters using upper and lower case letters, and at least 1 number and 1 symbol" errorPlacement="topStart" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormControl type={showPass ? "text" : "password"} placeholder="Confirm Password" checkTrigger="blur" name="confirmPassword" errorPlacement="bottomStart" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    <Checkbox onChange={() => { toggleShowPass(!showPass) }} >Show Password</Checkbox>
                </Col>
                <Col></Col>
            </Row>
        </Form>
    );
}
export default FirmForm;