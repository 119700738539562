import React from 'react';
import { Container, Header, Content, Sidebar } from 'rsuite';
import RenderRoutes from '../../Routes/renderroutes'
import INTERNAL_ROUTES from '../../Routes/internalroutes';
import InternalSideNav from '../SideNav/SideNav';
import InternalTopNavbar from '../TopNav/TopNav'
import recentQueueService from '../../../services/Internal/Recent/recentQueue.service';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import { useEffect } from 'react';

function MainInternal() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const dispatch = useDispatch();
    useEffect(() => {
        if (params.recentQueueId) {
            recentQueueService.getRecentQueueById(params.recentQueueId).then((data) => {
                dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: data.data.parentId });
                dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: data.data.entityId });
                dispatch({ type: "PERSIST_CURRENT_APP_TYPE_ID", payload: data.data.appTypeId });
                dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: JSON.parse(data.data.routes) });
            }).then(() => {
                setHasLoaded(true);
            })
        }
        else
            setHasLoaded(true);
    }, [])

    return (
        <Container>
            <Sidebar>
                <InternalSideNav />
            </Sidebar>
            {hasLoaded ?
                <Container>
                    <Header>
                        <InternalTopNavbar />
                    </Header>
                    <Content className="mainContent">
                        <RenderRoutes routes={INTERNAL_ROUTES} />
                    </Content>
                </Container> : <LoadingSpinner />
            }
        </Container>
    );
}

export default MainInternal;