import axios from "axios";
import authHeader from '../../authheader'

const INDIVIDUALREINSTATEMENT = process.env.REACT_APP_APIURL + "/api/IndividualReinstatement";
const FIRMREINSTATEMENT = process.env.REACT_APP_APIURL + "/api/FirmReinstatement";

const getPendingReinstatements = async () => {
    return axios.get(`${INDIVIDUALREINSTATEMENT}/pendingreinstatements`, { headers: authHeader() });;
};
const getHistoricalReinstatements = async () => {
    return axios.get(`${INDIVIDUALREINSTATEMENT}/historicalreinstatements`, { headers: authHeader() });;
};
const getIndividualReinstatement = async (reinstatementId) => {
    return axios.get(`${INDIVIDUALREINSTATEMENT}/reinstatement`, { headers: authHeader(), params: { reinstatementId } });;
};

const createReinstatement = (credentialTypeId, credentialId) => {
    return axios.post(`${INDIVIDUALREINSTATEMENT}/create`, null, { headers: authHeader(), params: { credentialTypeId, credentialId } })
        .then((response) => {
            return response;
        });
}

const validateReinstatement = (reinstatementId) => {
    return axios.post(`${INDIVIDUALREINSTATEMENT}/validate`, null, { headers: authHeader(), params: { reinstatementId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const submitReinstatement = (reinstatementId) => {
    return axios.post(`${INDIVIDUALREINSTATEMENT}/submit`, null, { headers: authHeader(), params: { reinstatementId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}

const getFirmPendingReinstatements = async () => {
    return axios.get(`${FIRMREINSTATEMENT}/pendingreinstatements`, { headers: authHeader() });;
};
const getFirmHistoricalReinstatements = async () => {
    return axios.get(`${FIRMREINSTATEMENT}/historicalreinstatements`, { headers: authHeader() });;
};
const getFirmReinstatement = async (reinstatementId) => {
    return axios.get(`${FIRMREINSTATEMENT}/reinstatement`, { headers: authHeader(), params: { reinstatementId } });;
};

const createFirmReinstatement = (credentialTypeId, credentialId) => {
    return axios.post(`${FIRMREINSTATEMENT}/create`, null, { headers: authHeader(), params: { credentialTypeId, credentialId } })
        .then((response) => {
            return response;
        });
}

const validateFirmReinstatement = (reinstatementId) => {
    return axios.post(`${FIRMREINSTATEMENT}/validate`, null, { headers: authHeader(), params: { reinstatementId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const submitFirmReinstatement = (reinstatementId) => {
    return axios.post(`${FIRMREINSTATEMENT}/submit`, null, { headers: authHeader(), params: { reinstatementId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}

export default {
    getPendingReinstatements,
    getHistoricalReinstatements,
    getIndividualReinstatement,
    createReinstatement,
    validateReinstatement,
    submitReinstatement,
    getFirmPendingReinstatements,
    getFirmHistoricalReinstatements,
    getFirmReinstatement,
    createFirmReinstatement,
    validateFirmReinstatement,
    submitFirmReinstatement
}