import axios from "axios";
import authHeader from '../authheader'

const GETINDIVIDUAL = process.env.REACT_APP_APIURL + "/api/Individual";
const GETFIRM = process.env.REACT_APP_APIURL + "/api/Firm";
const CONTACTURL = process.env.REACT_APP_APIURL + "/api/Contact";

const getIndividualDashboard = async () => {
    return axios.get(`${GETINDIVIDUAL}/dashboard`, { headers: authHeader() });
};
const getFirmDashboard = async () => {
    return axios.get(`${GETFIRM}/dashboard`, { headers: authHeader() });
};

const postIndividualLockedContactInformation = async (data) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/Contact/updateindividualmigratation`, data, { headers: authHeader() });
}
const postFirmLockedContactInformation = async (data) => {
    return axios.post(`${CONTACTURL}/updatefirmmigratation`, data, { headers: authHeader() });
}
const getIndividualLockedContactInformation = async () => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/Contact/individualmigrationupdate`, { headers: authHeader() });
}
const getFirmLockedContactInformation = async () => {
    return axios.get(`${CONTACTURL}/firmmigrationupdate`, { headers: authHeader() });
}

export default {
    getIndividualDashboard,
    getFirmDashboard,
    postIndividualLockedContactInformation,
    postFirmLockedContactInformation,
    getIndividualLockedContactInformation,
    getFirmLockedContactInformation

}