import axios from "axios";
import authHeader from '../authheader'

const PICKERCACHEURL = process.env.REACT_APP_APIURL + "/api/PickerCache";

const getCodeTableId = () => {
    return axios.get(`${PICKERCACHEURL}/availblecodetable`)
}

export const getStates = () => {
    var codeTableType = 17;
    return axios.get(`${PICKERCACHEURL}/statecountry`, { params: { codeTableType } }).then((response) => {
        return response.data.sort((a, b) => a.state.localeCompare(b.state)).filter(state => state.state !== "A Not Applicable").map(state => { return { "label": state.state, "value": { ...state } } })
    });
}

export const getCountries = () => {
    var codeTableType = 11;
    return axios.get(`${PICKERCACHEURL}/statecountry`, { params: { codeTableType } }).then((response) => {
        return response.data.sort((a, b) => a.country.localeCompare(b.country))
            .sort(function (x, y) { return x.country == "United States" ? -1 : y.country == "United States" ? 1 : 0; })
            .map(country => { return { "label": country.country, "value": { ...country } } })
    });
}

export const getFirmTypes = () => {
    return axios.get(`${PICKERCACHEURL}/organizationtypes`).then((response) => {
        return response.data.map(firmType => { return { "label": firmType.organizationType, "value": { id: firmType.id, code: firmType.organizationType } } })
    });
}

export const getSchools = () => {
    var codeTableType = 15;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(school => { return { "label": `${school.name} (${school.city})`, "value": { ...school } } })
    });
}
export const getStatuses = () => {
    var codeTableType = 16;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(status => { return { "label": `${status.schoolStatus}`, "value": { ...status } } })
    });
}

export const getDegrees = () => {
    var codeTableType = 12;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(degree => { return { "label": degree.degree, "value": { ...degree } } })
    });
}
export const getEmploymentTypes = () => {
    var codeTableType = 1;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(employmentType => { return { "label": employmentType.employmentType, "value": { ...employmentType } } })
    });
}
export const getBoardMembers = () => {
    var codeTableType = 10;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(boardMemeber => { return { "label": `${boardMemeber.firstName} ${boardMemeber.middleName} ${boardMemeber.lastName}, ${boardMemeber.credentialSuffix}`, "value": { ...boardMemeber } } })
    });
}

export const getAreas = () => {
    var codeTableType = 19;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(area => { return { "label": `${area.area}`, "value": { ...area } } })
    });
}

export const getCourses = (schoolId) => {
    var codeTableType = 20;
    return axios.get(`${PICKERCACHEURL}/courses`, { headers: authHeader(), params: { schoolId } }).then((response) => {
        return response.data.map(course => { return { "label": `${course.courseNumber} (${course.description})`, "value": { courseNumber: course.courseNumber, description: course.description, id: course.id, schoolId: course.schoolId } } })
    });
}

export const getCourseClassifications = () => {
    var codeTableType = 21;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(classification => { return { "label": `${classification.courseClassification}`, "value": { ...classification } } })
    });
}

export const getCredStatus = () => {
    var codeTableType = 22;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(credStatus => { return { "label": `${credStatus.status}`, "value": { ...credStatus } } })
    });
}

export const getIssueTypes = () => {
    var codeTableType = 23;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(issueType => { return { "label": `${issueType.issueType}`, "value": { ...issueType } } })
    });
}

export const getAttachmentTypes = () => {
    var codeTableType = 24;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(attachmentType => { return { "label": `${attachmentType.laserficheDocumentType}`, "value": { ...attachmentType } } })
    });
}

export const getFirstExamDiscipline = () => {
    var codeTableType = 25;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(firstExamDiscipline => { return { "label": `${firstExamDiscipline.peDiscipline}`, "value": { ...firstExamDiscipline } } })
    });
}

export const getExamDiscipline = () => {
    var codeTableType = 26;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(examDiscipline => { return { "label": `${examDiscipline.examDiscipline}`, "value": { ...examDiscipline } } })
    });
}

export const getEngineerExamDiscipline = () => {
    var codeTableType = 35;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(examDiscipline => { return { "label": `${examDiscipline.examDiscipline}`, "value": { ...examDiscipline } } })
    });
}

export const getSurveyingExamDiscipline = () => {
    var codeTableType = 36;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(examDiscipline => { return { "label": `${examDiscipline.examDiscipline}`, "value": { ...examDiscipline } } })
    });
}

export const getCodeViolationAnswers = (applicationTypeId) => {
    return axios.get(`${PICKERCACHEURL}/appviolationquestionsbyapptype`, { headers: authHeader(), params: { applicationTypeId } }).then((response) => {
        return response.data.map(answers => { return { "id": 0, "violationId": 0, "applicationId": 0, "entityId": 0, "codeViolationQuestion": { ...answers }, "answer": null, "userCrte": null } })
    });
}
export const getReinstatementCodeViolationAnswers = (credentialtypeid) => {
    return axios.get(`${PICKERCACHEURL}/reinstatementviolationquestionsbycredtype`, { headers: authHeader(), params: { credentialtypeid } }).then((response) => {
        return response.data.map(answers => { return { "id": 0, "violationId": 0, "reinstatementId": 0, "entityId": 0, "codeViolationQuestion": { ...answers }, "answer": null, "userCrte": null } })
    });
}
export const getRenewalCodeViolationAnswers = (credentialtypeid) => {
    return axios.get(`${PICKERCACHEURL}/renewalviolationquestionsbycredtype`, { headers: authHeader(), params: { credentialtypeid } }).then((response) => {
        return response.data.map(answers => { return { "id": 0, "violationId": 0, "renewalId": 0, "entityId": 0, "codeViolationQuestion": { ...answers }, "answer": null, "userCrte": null } })
    });
}



export const getPaymentTypes = () => {
    var codeTableType = 27;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(paymentType => { return { "label": `${paymentType.paymentType}`, "value": { ...paymentType } } })
    });
}
export const getBatchStatusType = () => {
    var codeTableType = 28;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(statusType => { return { "label": `${statusType.batchStatus}`, "value": `${statusType.batchStatus}` } })
    });
}

export const getFeeDefinitions = () => {
    var codeTableType = 29;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(feeDefinition => { return { "label": `${feeDefinition.feeDefinition}`, "value": { ...feeDefinition } } })
    });
}
export const getCredTypes = () => {
    var codeTableType = 30;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(credType => { return { "label": `${credType.credentialType}`, "value": { ...credType } } })
    });
}
export const getInternalStatuses = () => {
    var codeTableType = 31;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(status => { return { "label": `${status.internalStatus}`, "value": { ...status } } })
    });
}
export const getAdditionalStructurals = () => {
    var codeTableType = 32;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(data => { return { "label": `${data.examAdditionalStructural}`, "value": { ...data } } })
    });
}
export const getExams = () => {
    var codeTableType = 33;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(exam => { return { "label": `${exam.officialTitle}`, "value": { ...exam } } })
    });
}
export const getExamStatuses = () => {
    var codeTableType = 34;
    return axios.get(`${PICKERCACHEURL}/lookup`, { headers: authHeader(), params: { codeTableType } }).then((response) => {
        return response.data.map(status => { return { "label": `${status.status}`, "value": { ...status } } })
    });
}
export const getCredStatusReason = (credentialStatusId) => {
    return axios.get(`${PICKERCACHEURL}/credentialstatusreasons`, { headers: authHeader(), params: { credentialStatusId } }).then((response) => {
        return response.data.map(statusReason => { return { "label": `${statusReason.statusReason}`, "value": { ...statusReason } } })
    });
}

export const getAllCredentials = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/allcredentials`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data)
            return response.data.map(cred => { return { "label": `(${cred.formattedLicenseNumber}) ${cred.formattedName}`, "value": `(${cred.formattedLicenseNumber}) ${cred.formattedName}` } })
    });
}
export const getContacts = (name) => {
    return axios.get(`${PICKERCACHEURL}/allcontactsbyname`, { headers: authHeader(), params: { name } }).then((response) => {
        if (response.data) {
            return response.data.map(contact => { return { "label": `${contact.formattedName} | ${contact.lolanumber} ${contact.formattedLicenseNumber ? "| " + contact.formattedLicenseNumber : ""}`, "name": `${contact.name}`, "value": { ...contact } } })
        }
    });
}

export const getVfCredentials = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/vfcredential`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data)
            return response.data.map(cred => { return { "label": `(${cred.formattedLicenseNumber}) ${cred.formatedName}`, "value": { ...cred } } })
    });
}
export const getEfCredentials = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/efcredential`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data)
            return response.data.map(cred => { return { "label": `(${cred.formattedLicenseNumber}) ${cred.formatedName}`, "value": { ...cred } } })
    });
}
export const getVfCredential = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/vfcredential`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data.length)
            return { "label": `(${response.data[0].formattedLicenseNumber}) ${response.data[0].formatedName}`, "value": { ...response.data[0] } }
    });
}
export const getEfCredential = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/efcredential`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data.length)
            return { "label": `(${response.data[0].formattedLicenseNumber}) ${response.data[0].formatedName}`, "value": { ...response.data[0] } }
    });
}
export const getFirmCredential = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/firmcredential`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data.length)
            return response.data.map(cred => { return { "label": `(${cred.formattedLicenseNumber}) ${cred.formatedName}`, "value": `(${cred.formattedLicenseNumber}) ${cred.formatedName}` } })
    });
}
export const getIndividualCredentials = (credentialNumber) => {
    return axios.get(`${PICKERCACHEURL}/individualcredential`, { headers: authHeader(), params: { credentialNumber } }).then((response) => {
        if (response.data)
            return response.data.map(cred => { return { "label": `(${cred.formattedLicenseNumber}) ${cred.formattedName}`, "value": `(${cred.formattedLicenseNumber}) ${cred.formattedName}` } })
    });
}
export const getIndividualCredentialsInternal = (credentialNumber, firmEntityId) => {
    return axios.get(`${PICKERCACHEURL}/individualcredential`, { headers: authHeader(), params: { credentialNumber, firmEntityId } }).then((response) => {
        if (response.data)
            return response.data.map(cred => { return { "label": `(${cred.formattedLicenseNumber}) ${cred.formattedName}`, "value": { ...cred } } })
    });
}

export const getIndividualCredentialsOnEdit = (formattedLicenseNumber) => {
    return axios.get(`${PICKERCACHEURL}/individualcredentialbyformattedlicensenumber`, { headers: authHeader(), params: { formattedLicenseNumber } }).then((response) => {
        if (response.data)
            return { "label": `(${response.data.formattedLicenseNumber}) ${response.data.formattedName}`, "value": { ...response.data } }
    });
}
export const getRenewingintolist = (renewalId) => {
    return axios.get(`${PICKERCACHEURL}/renewingintolist`, { headers: authHeader(), params: { renewalId } }).then((response) => {
        if (response.data)
            return response.data.map(data => { return { "label": `${data.code}`, "value": { ...data } } })
    });
}

export const emailPhonePrivacy = [
    { label: 'Yes, keep private', value: true },
    { label: 'No, share when requested', value: false }
];
export const journalDropdown = [
    { label: 'Do Not Send', value: 'do not send' },
    { label: 'Email', value: 'email' },
    { label: 'Mail', value: 'mail' }
];
export const subscribeDropdown = [
    { label: 'Subscribe', value: true },
    { label: 'Unsubscribe', value: false },
];

