import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import recentQueueService from "../../../services/Internal/Recent/recentQueue.service"
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';

function RecentQueue() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [recentQueueList, setRecentQueueList] = useState([])
    const dispatch = useDispatch();
    const updateSelector = useSelector(x => x.refreshRecentQueue);
    const updateRecentQueue = () => {
        setHasLoaded(false);
        recentQueueService.getRecentQueue().then((response) => {
            if (response.data)
                setRecentQueueList(response.data)
            setHasLoaded(true);
        })
    }

    useEffect(() => {
        updateRecentQueue();
        function checkForUpdate() {
            const item = localStorage.getItem('updateRecentQueue')

            if (item) {
                updateRecentQueue();
            }
        }

        window.addEventListener('storage', checkForUpdate)

        return () => {
            window.removeEventListener('storage', checkForUpdate)
        }
    }, [])

    useEffect(() => {
        updateRecentQueue();
    }, [updateSelector])

    const navigateAndSetCurrentApplication = (data) => {

        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: data.parentId });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: data.entityId });
        dispatch({ type: "PERSIST_CURRENT_APP_TYPE_ID", payload: data.appTypeId });
        dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: JSON.parse(data.routes) });

    }
    return (
        <>
            <hr />
            <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
                <a href="internal/allRecentQueue" target="_blank" style={{ color: "black" }}><h4 style={{ paddingBottom: "5px" }}>Recent Queue</h4></a>
                {hasLoaded ?
                    recentQueueList.map((val, i) => {
                        var link = JSON.parse(val.routes)[0].route;
                        link = link.slice(0, link.lastIndexOf('/') + 1) + "sections";
                        var display = val.entityName;
                        var prefix = "";
                        var parentType = "";
                        switch (val.appTypeId) {
                            case 1:
                                prefix = "EI";
                                break;
                            case 2:
                                prefix = "LSI";
                                break;
                            case 3:
                            case 4:
                                prefix = "PE";
                                break;
                            case 5:
                            case 6:
                                prefix = "PLS";
                                break;
                            case 7:
                                prefix = "EF";
                                break;
                            case 8:
                                prefix = "VF";
                                break;
                            default:
                                break;
                        }
                        switch (val.parentTypeId) {
                            case 1:
                                parentType = "Reinstatement";
                                break;
                            case 2:
                                parentType = "Application";
                                break;
                            case 3:
                                parentType = "Renewal";
                                break;
                            default: break;
                        }
                        return (<>
                            <h6 style={{ paddingBottom: "10px" }}>
                                <a style={{ fontSize: "15px" }} href={"internal/redirectToSection?parentTypeId=" + val.parentTypeId + "&parentId=" + val.parentId} onClick={() => navigateAndSetCurrentApplication(val)}>{display}<br />({prefix} {parentType})</a>
                            </h6>
                        </>)
                    })
                    : <LoadingSpinner />
                }
            </div>
        </>
    );
}

export default RecentQueue;