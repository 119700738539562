import React from 'react';
import { Card, Row, Col } from 'react-bootstrap'
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
import RedStar from '../../../../Shared/GenericComponents/RedStar';
import DocumentUpload from '../../../../Shared/Upload/DocumentUpload';

function EducationRecord(props) {
    return (
        <div className="plsAppsEducationInfo">
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>Education Records</h6>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body >
                    <p>
                        <RedStar /> I have my education verified in MyNCEES account.
                    </p>
                    <Row>
                        <Col xs sm md lg="auto">
                            <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasMyNceesEducation" handleChange={props.handleMyNceesEducationChange} />
                        </Col>
                        {props.hasMyNceesEducation == false &&
                            <Col>
                                <span className="badge badge-light" style={{ padding: "0.5rem", textWrap: "wrap", wordWrap: "", color: "red" }}><i>You must create a MyNCEES account at ncees.org </i>(<a href="https://account.ncees.org/login" target="_blank">https://account.ncees.org/login</a>
                                    ), <i>and have your transcript sent directly to NCEES. If you already have a MyNCEES account, you must have your education verified by NCEES</i></span>
                            </Col>
                        }
                    </Row>
                    {(props.hasMyNceesEducation == true || props.hasMyNceesEducation == false) &&
                        <>
                            <br />
                            <p>
                                <RedStar /> I have a ANSAC/ABET-accredited Geomatics degree.
                            </p>
                            <Row>
                                <Col>
                                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasAnsacDegree" handleChange={props.handleShowQuestion3} />
                                </Col>
                            </Row>
                        </>
                    }
                    {props.hasAnsacDegree == false &&
                        <>
                            <br />
                            <p>
                                <RedStar /> I have a 4-year degree with approved 30 hours of surveying courses.
                            </p>
                            <Row>
                                <Col xs sm md lg="auto">
                                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasFourYearDegree" />
                                </Col>
                                {props.hasFourYearDegree == true && // yes
                                    <Col>
                                        <span className="badge badge-light" style={{ padding: "0.5rem", textWrap: "wrap", wordWrap: "", textAlign: "left", color: "red" }}>
                                            <ul>
                                                <li><i>Have all transcripts sent directly from your institutions to your MyNCEES</i></li>
                                                <li><i>Download the </i><a target="_blank" href="https://www.lapels.com/docs/applications/ps/Approved_Courses.pdf"><b>Approved Surveying Course </b></a>
                                                    <i>list and the </i><a target="_blank" href=" https://www.lapels.com/docs/Applications/ps/ps_lsi_instr_curricula.pdf"><b>Course Evaluation Form </b></a><i>from our website.</i>
                                                </li>
                                                <li><i>In the event that you have not obtained Surveying Courses from our approved list, you must provide Syllabi for all courses that you want reviewed.</i></li>
                                            </ul>
                                        </span>
                                    </Col>
                                }
                                {props.hasFourYearDegree == false && // no
                                    <Col>
                                        <span className="badge badge-light" style={{ padding: "0.5rem", textWrap: "wrap", wordWrap: "", color: "red" }}>
                                            <i>You may proceed to complete the application, but without 30 hours, you are not eligible for licensure in Louisiana. Contact Janet Lindsey at</i> <a href="mailto:janet@lapels.com">janet@lapels.com</a>
                                        </span>
                                    </Col>
                                }
                            </Row>
                            {props.hasFourYearDegree == true &&
                                <>
                                    <DocumentUpload
                                        isUserEditable={!props.isReadOnly}
                                        credentialTypeId={4}
                                        sectionId={props.sectionId}
                                        parentId={props.sectionDataId}
                                        parentTypeId={1}
                                        entityId={props.entityId}
                                        setHasFiles={props.setHasFiles}
                                    />
                                    {!props.hasFiles &&
                                        <div className="text-danger">
                                            At least one attachment is required.
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}


export default EducationRecord;