import axios from "axios";
import authHeader from '../../authheader.js'

const BATCHURL = process.env.REACT_APP_APIURL + "/api/Batch";
const BATCHSEARCHURL = process.env.REACT_APP_APIURL + "/api/BatchSearch";

const getBatchInfo = async (id) => {
    return axios.get(`${BATCHURL}/batch`, { headers: authHeader(), params: { id } });;
}
const createBatch = async () => {
    return axios.post(`${BATCHURL}/createbatch`,null, { headers: authHeader() });;
}
const checkBatchOpened = async () => {
    return axios.get(`${BATCHURL}/userhasopenbatch`, { headers: authHeader() });;
}
const hasOpenedBatchForDay = async () => {
    return axios.get(`${BATCHURL}/userhasbatchfortoday`, { headers: authHeader() });;
}
const closeBatch = async (id) => {
    return axios.post(`${BATCHURL}/closebatch`, null, { headers: authHeader(), params: { id } });;
}
const openBatch = async (id) => {
    return axios.post(`${BATCHURL}/openbatch`, null, { headers: authHeader(), params: { id } });;
}

const searchBatch = async (data) => {
    return axios.post(`${BATCHSEARCHURL}`, data, { headers: authHeader() });;
}

export default {
    getBatchInfo,
    createBatch,
    checkBatchOpened,
    hasOpenedBatchForDay,
    searchBatch,
    closeBatch,
    openBatch
}