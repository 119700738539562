import React, { useEffect, useState } from 'react'
import { Container, Card } from 'react-bootstrap'
import { Form, Schema } from 'rsuite'
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import { useHistory } from 'react-router-dom';
import DocumentUploads from '../../../../Shared/Card/DocumentUploads'
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import attachmentService from '../../../../../services/Shared/attachment.service';
import publicApplicationService from '../../../../../services/Public/Application/PE/peApplication.service';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import peApplicationService from '../../../../../services/Internal/Application/PE/peApplication.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';
import DocumentUpload from '../../../../Shared/Upload/DocumentUpload';


function QuizzesPage() {
    const appId = useSelector(x => x.currentApplicationId);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const sectionId = 6;
    const [isComplete, setIsComplete] = useState(false);

    const [isUserEditable, setIsUserEditable] = useState(true);

    const [hasFiles, setHasFiles] = useState();
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Quizzes"
        }
    );
    useEffect(() => {
        publicApplicationService.peGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data)
            setIsComplete(response.data.staffReviewComplete)
            applicationService.getIndividualApplication(appId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        });
    }, [])

    const { push } = useHistory();

    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (hasFiles) {
            // do form post
            peApplicationService.peMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            });
        }
    }
    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("quiz")} />
                <br />
                {hasLoaded ?
                    <Form onSubmit={handleSubmit} fluid> {/*This is to make sure validation is triggered and form does nothing else*/}
                        <Card>
                            <Card.Header>
                                <h6>Document Uploads</h6>
                            </Card.Header>
                            <Card.Body>
                                <DocumentUpload
                                    isUserEditable={isUserEditable}
                                    credentialTypeId={6}
                                    sectionId={sectionId}
                                    appTypeId={appTypeId}
                                    //refreshData={ }
                                    parentId={sectionData.id}
                                    parentTypeId={1}
                                    entityId={entityInfo.entityId}
                                    setHasFiles={setHasFiles}
                                />
                                {!hasFiles && <div className="text-danger">Please select at least 1 file.</div>}
                            </Card.Body>
                        </Card>
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            backToSectionLink="/internal/peapp/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default QuizzesPage;