import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useEffect } from 'react';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import Invoice from '../../../Shared/Card/Invoice';
import invoiceService from '../../../../services/Shared/invoice.service';
import { useSelector } from 'react-redux';

function InvoicePage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [entity, setEntity] = useState({});
    const [licenseNumbers, setLicenseNumbers] = useState([]);
    const invoiceId = useSelector(x => x.currentInvoiceId);
    useEffect(() => {
        invoiceService.getInvoice(invoiceId).then((response) => {
            setInvoiceData(response.data);
            invoiceService.getEntityInfo(response.data.entityId).then((data) => {
                setEntity(data.data);
                invoiceService.getEntityLicenseInfo(response.data.entityId, invoiceId).then((licenseResponse) => {
                    setLicenseNumbers([...licenseResponse.data]);
                    setHasLoaded(true);
                })
            })
        })
    }, [])

    return (
        <React.Fragment>
            {hasLoaded ?
                <Container fluid>
                    <h4 className="mb-3">Invoice</h4>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Requirements</Card.Header>
                                <Card.Body><p>Select the Print button below to print the invoice and mail with payment to the following address:</p>
                                    <p>Please make your check payable to: <b>LAPELS</b></p>
                                    <div className="col-md-6 my-2">
                                        Please send this invoice with your check to:<br />
                                        <b>LAPELS</b><br />
                                        9643 BROOKLINE AVENUE, SUITE 121<br />
                                        BATON ROUGE, LA 70809<br />

                                    </div>
                                    <p>Please also send in a LAPELS PAYMENT AUTHORIZATION FORM along with your invoice if paying by credit card or ACH. <a href="https://www.lapels.com/payments.html">https://www.lapels.com/payments.html</a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Invoice entity={entity} data={invoiceData} licenseInfo={licenseNumbers} />
                        </Col>
                    </Row>

                </Container> : <LoadingSpinner />
            }
        </React.Fragment>
    )
}
export default InvoicePage;