import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, Schema, SelectPicker } from 'rsuite';
import credentialService from '../../../../../services/Internal/Credential/credential.service';
import { getCredStatus, getCredStatusReason } from '../../../../../services/Shared/codeTable.service';
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function PlsCredentialForm(props) {
    const [formData, setFormData] = useState({});
    const [credStaus, setCredStaus] = useState([])
    const [credReason, setCredReason] = useState([])
    useEffect(() => {
        setFormData({
            ...props.credential,
            applicationDate: props.credential.applicationDate != null ? dayjs(props.credential.applicationDate).format('YYYY-MM-DD') : null,
            expirationDate: props.credential.expirationDate != null ? dayjs(props.credential.expirationDate).format('YYYY-MM-DD') : null,
            effectiveDate: props.credential.effectiveDate != null ? dayjs(props.credential.effectiveDate).format('YYYY-MM-DD') : null,
            reinstatementDate: props.credential.reinstatementDate != null ? dayjs(props.credential.reinstatementDate).format('YYYY-MM-DD') : null,
            firstIssuanceDate: props.credential.firstIssuanceDate != null ? dayjs(props.credential.firstIssuanceDate).format('YYYY-MM-DD') : null,
            dateMod: props.credential.dateMod != null ? dayjs(props.credential.dateMod).format('YYYY-MM-DD') : null,
            statusLabel: props.credential.status?.status,
            statusReasonLabel: props.credential.statusReason?.statusReason,
            status: props.credential.status,
            statusReason: props.credential.statusReason,
        });

        getCredStatus().then(setCredStaus);
        getCredStatusReason(props.credential.status?.id).then(setCredReason);
    }, [props.credential])

    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        statusReason: ObjectType().isRequired('This field is required.'),
        status: ObjectType().isRequired('This field is required.'),

    })

    const handleCredentialStatusChange = (val) => {
        setFormData({ ...formData, status: val, statusReason: null })
        getCredStatusReason(val?.id).then(setCredReason);
    }

    const submitForm = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...formData,
                expirationDate: formData.expirationDate == '' ? null : formData.expirationDate,
                effectiveDate: formData.effectiveDate == '' ? null : formData.effectiveDate,
                reinstatementDate: formData.reinstatementDate == '' ? null : formData.reinstatementDate,
            }
            credentialService.updatePlsCredential(dataToTransmit).then((response) => {
                props.saveSuccess(response.data)
                if (dataToTransmit.status.id != 1) {
                    props.updateSupervisesCardCallback()
                }
            });
        }
    }
    return (
        <Form id={props.formId} fluid model={model} formValue={formData} onChange={setFormData} onSubmit={submitForm} >
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>Credential #</b></ControlLabel>
                    <FormControl
                        name="formattedLicenseNumber"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Credential Status</b> {props.editMode && <RedStar />}</ControlLabel>
                    {props.editMode ?
                        <FormControl
                            name="status"
                            block
                            data={credStaus}
                            accepter={SelectPicker}
                            searchable={false}
                            placeholder="Select Status"
                            onChange={handleCredentialStatusChange}
                        /> :
                        <FormControl
                            name="statusLabel"
                            block="true"
                            disabled
                        />
                    }
                </Col>
                <Col>
                    <ControlLabel><b>Credential Reason</b> {props.editMode && <RedStar />}</ControlLabel>
                    {props.editMode ?
                        <FormControl
                            name="statusReason"
                            block
                            data={credReason}
                            accepter={SelectPicker}
                            searchable={false}
                            readOnly={!formData.status}
                            placeholder="Select Status Reason"
                        /> :
                        <FormControl
                            name="statusReasonLabel"
                            block="true"
                            disabled
                        />
                    }
                </Col>

            </Row>
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>First Issuance Date</b></ControlLabel>
                    <FormControl
                        name="firstIssuanceDate"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Effective Date</b></ControlLabel>
                    <FormControl
                        name="effectiveDate"
                        type={props.editMode ? "date" : 'text'}
                        disabled={!props.editMode}
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Expiration Date</b></ControlLabel>
                    <FormControl
                        name="expirationDate"
                        type={props.editMode ? "date" : 'text'}
                        disabled={!props.editMode}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <ControlLabel><b>Application Date</b></ControlLabel>
                    <FormControl
                        name="applicationDate"
                        type={props.editMode ? "date" : 'text'}
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Application Type</b></ControlLabel>
                    <FormControl
                        name="applicationType"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Reinstatement Date</b></ControlLabel>
                    <FormControl
                        name="reinstatementDate"
                        type={props.editMode ? "date" : 'text'}
                        disabled={!props.editMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ControlLabel><b>Licensed By</b></ControlLabel>
                    <FormControl
                        name="approvedBy"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Last Updated By</b></ControlLabel>
                    <FormControl
                        name="userMod"
                        disabled
                    />
                </Col>
                <Col>
                    <ControlLabel><b>Date of Last Activity</b></ControlLabel>
                    <FormControl
                        name="dateMod"
                        disabled
                    />
                </Col>
            </Row>
        </Form>
    );
}

export default PlsCredentialForm;