import { useState, useEffect } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import React from 'react'
import { ControlLabel, Schema, Form, FormControl, FormGroup, Input, Radio, RadioGroup } from 'rsuite'
import RedStar from '../../Shared/GenericComponents/RedStar';
import Address from '../../Shared/GenericComponents/Address';


function AddEditPersonalReferenceModal(props) {
    const [referenceModel, setReferenceModel] = useState(
        {
            address: {
                state: null,
                country: null,
                city: '',
                postalCode: '',
                streetAddress: '',
                id: 0,
                parentId: 0,
                parentType: null,
                addressType: {
                    id: 2,
                    addressType: "Mailing Address"
                },
                entityId: 0,
                provinceRegion: "",
                county: null,
                userCrte: "",
                dateCrte: null,
            },
            firstOccasionFirmAddress: {
                state: null,
                country: null,
                city: '',
                postalCode: '',
                streetAddress: '',
                id: 0,
                parentId: 0,
                parentType: null,
                addressType: {
                    id: 2,
                    addressType: "Mailing Address"
                },
                entityId: 0,
                provinceRegion: "",
                county: null,
                userCrte: "",
                dateCrte: null,
            },
            secondOccasionFirmAddress: {
                state: null,
                country: null,
                city: '',
                postalCode: '',
                streetAddress: '',
                id: 0,
                parentId: 0,
                parentType: null,
                addressType: {
                    id: 2,
                    addressType: "Mailing Address"
                },
                entityId: 0,
                provinceRegion: "",
                county: null,
                userCrte: "",
                dateCrte: null,
            },
            reinstatementId: 0,
            dateCrte: null,
            deleted: false,
            email: "",
            entityId: 0,
            id: 0,
            name: "",
            userCrte: "",
            verificationStatus: null,
            membersOfSameFirm: null,
            notMembersOfSameFirmExplanation: '',
            dateFromFirstOccasion: null,
            dateToFirstOccasion: null,
            firstOccasionFirmName: '',
            firstOccasionPosition: '',
            firstOccasionReferencePosition: '',
            dateToSecondOccasion: null,
            dateFromSecondOccasion: null,
            secondOccasionFirmName: '',
            secondOccasionPosition: '',
            secondOccasionReferencePosition: '',
            modalType: "Add"
        })
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        setReferenceModel(props.modaldata);
    }, [props.modaldata])

    const { StringType, ObjectType, ArrayType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        name: StringType().isRequired('This field is required.'),
        email: StringType().isRequired('This field is required.').isEmail('Enter in a valid Email'),
        membersOfSameFirm: BooleanType().isRequired('This field is required.'),
        notMembersOfSameFirmExplanation: StringType().isRequired('This field is required.'),
        firstOccasionFirmName: StringType().isRequired("This field is required"),
        firstOccasionPosition: StringType().isRequired("This field is required"),
        firstOccasionReferencePosition: StringType().isRequired("This field is required"),
        dateToFirstOccasion: StringType().isRequired('This field is required'),
        dateFromFirstOccasion: StringType().isRequired('This field is required')
    })

    const addressModel = Schema.Model({
        streetAddress: StringType().isRequired('This field is required.'),
        country: ObjectType().isRequired('This field is required.'),
        city: StringType().isRequired('This field is required.'),
        postalCode: StringType().isRequired('This field is required.'),
        state: ObjectType().isRequired('This field is required.'),
        provinceRegion: StringType().isRequired('This field is required.'),
    })

    const validateAddress = (validationObject, model) => {
        var hasErrors = false;
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                var stateCountryValidation = (key.includes("provinceRegion") && model.address?.country?.country !== "United States") ||
                    (key === "state" && model.address?.country?.country === "United States");
                if (stateCountryValidation) {
                    hasErrors = true;
                }
                else if (key !== "state" && key !== "provinceRegion") {
                    hasErrors = true;
                }
            }
        }
        return hasErrors;
    }

    const formSubmit = (validation, event) => {
        setShowErrors(true);
        let hasErrors = false;
        var validationObject = model.check(referenceModel);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                if ((
                    (key.includes("Occasion") || !key.includes("notMembersOfSameFirmExplanation")) && referenceModel.membersOfSameFirm)
                    || (!referenceModel.membersOfSameFirm && !key.includes("Occasion"))) {
                    hasErrors = true;
                    return;
                }
            }
        }
        if (!hasErrors) {
            var addressValidationObject = addressModel.check(referenceModel.address);
            hasErrors = validateAddress(addressValidationObject, referenceModel);
        }

        if (!hasErrors) {
            submitData();
        }
    }
    const handleMembersOfSameFirmChange = (value) => {
        if (value) {
            setReferenceModel({
                ...referenceModel,
                membersOfSameFirm: value,
                notMembersOfSameFirmExplanation: null,
                firstOccasionFirmAddress: {
                    state: null,
                    country: null,
                    city: '',
                    postalCode: '',
                    streetAddress: '',
                    id: 0,
                    parentId: 0,
                    parentType: null,
                    addressType: {
                        id: 2,
                        addressType: "Mailing Address"
                    },
                    entityId: referenceModel.entityId,
                    provinceRegion: "",
                    county: null,
                    userCrte: "",
                    dateCrte: null,
                },
                secondOccasionFirmAddress: {
                    state: null,
                    country: null,
                    city: '',
                    postalCode: '',
                    streetAddress: '',
                    id: 0,
                    parentId: 0,
                    parentType: null,
                    addressType: {
                        id: 2,
                        addressType: "Mailing Address"
                    },
                    entityId: referenceModel.entityId,
                    provinceRegion: "",
                    county: null,
                    userCrte: "",
                    dateCrte: null,
                },
            })
        }
        else {
            setReferenceModel({
                ...referenceModel,
                membersOfSameFirm: value,
                firstOccasionFirmAddress: null,
                secondOccasionFirmAddress: null,
                dateFromFirstOccasion: null,
                dateToFirstOccasion: null,
                firstOccasionFirmName: null,
                firstOccasionPosition: '',
                firstOccasionReferencePosition: null,
                dateToSecondOccasion: null,
                dateFromSecondOccasion: null,
                secondOccasionFirmName: null,
                secondOccasionPosition: null,
                secondOccasionReferencePosition: null,
            })
        }
    }
    const submitData = () => {
        var dataToTransmit = {
            ...referenceModel,
            id: referenceModel.id === '' ? 0 : referenceModel.id,
            dateToFirstOccasion: referenceModel.dateToFirstOccasion ? new Date(referenceModel.dateToFirstOccasion) : null,
            dateFromFirstOccasion: referenceModel.dateFromFirstOccasion ? new Date(referenceModel.dateFromFirstOccasion) : null,
            dateToSecondOccasion: referenceModel.dateToSecondOccasion ? new Date(referenceModel.dateToSecondOccasion) : null,
            dateFromSecondOccasion: referenceModel.dateFromSecondOccasion ? new Date(referenceModel.dateFromSecondOccasion) : null,

            dateCrte: new Date()
        };
        delete dataToTransmit.modalType;
        props.onsave(dataToTransmit).then((response) => {
            if (response.data.id) {
                props.updategrid();
                setShowErrors(false);
                props.onHide();
            }
        }).catch((response) => {
        })
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {referenceModel.modalType} a Reference
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form model={model} id="referenceForm" formValue={referenceModel} onChange={referenceModel => setReferenceModel(referenceModel)} onSubmit={formSubmit} fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Name&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="name" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Email&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="email" />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
                <br />
                <Form fluid
                    model={addressModel}
                    formValue={referenceModel.address}
                    onChange={(value) => { setReferenceModel({ ...referenceModel, address: { ...value } }) }}
                    id="addressForm"
                >
                    <Address showErrors={showErrors} boundValues={referenceModel.address} />
                </Form>
                <hr />
                <Form formValue={referenceModel} onChange={referenceModel => setReferenceModel(referenceModel)} fluid>
                    <p>
                        <RedStar /> Have you been members of the same firm?
                    </p>
                    <br />
                    <FormControl
                        name="membersOfSameFirm"
                        inline
                        accepter={RadioGroup}
                        errorMessage={(referenceModel.membersOfSameFirm == null && showErrors) ? "This field is required" : null}
                        onChange={handleMembersOfSameFirmChange}
                    >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </FormControl>
                </Form>
                {referenceModel.membersOfSameFirm === true &&
                    <div>
                        <Card>
                            <Card.Header><h6>First Occasion</h6></Card.Header>
                            <Card.Body>
                                <Form id="firstOccasionForm" formValue={referenceModel} onChange={data => setReferenceModel(data)} fluid>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <ControlLabel>
                                                    <strong>
                                                        Firm Name&nbsp;<RedStar />
                                                    </strong>
                                                </ControlLabel>
                                            </Col>
                                            <Col sm={9}>
                                                <FormControl name="firstOccasionFirmName" errorMessage={((showErrors && !referenceModel.firstOccasionFirmName) ? "This field is required" : null)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <ControlLabel>
                                                <strong>
                                                    Date From&nbsp;<RedStar />
                                                </strong>
                                            </ControlLabel>
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="month"
                                                block="true"
                                                name="dateFromFirstOccasion" errorMessage={((showErrors && !referenceModel.dateFromFirstOccasion) ? "This field is required" : null)} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <ControlLabel>
                                                <strong>
                                                    Date To&nbsp;<RedStar />
                                                </strong>
                                            </ControlLabel>
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="month"
                                                block="true"
                                                name="dateToFirstOccasion" errorMessage={((showErrors && !referenceModel.dateToFirstOccasion) ? "This field is required" : null)} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <ControlLabel>
                                                    <strong>
                                                        Applicant's Position&nbsp;<RedStar />
                                                    </strong>
                                                </ControlLabel>
                                            </Col>
                                            <Col sm={9}>
                                                <FormControl name="firstOccasionPosition"
                                                    errorMessage={((showErrors && !referenceModel.firstOccasionPosition) ? "This field is required" : null)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <ControlLabel>
                                                    <strong>
                                                        Reference's Position&nbsp;<RedStar />
                                                    </strong>
                                                </ControlLabel>
                                            </Col>
                                            <Col sm={9}>
                                                <FormControl name="firstOccasionReferencePosition"
                                                    errorMessage={((showErrors && !referenceModel.firstOccasionReferencePosition) ? "This field is required" : null)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                                <br />
                                <Form fluid
                                    model={addressModel}
                                    formValue={referenceModel.firstOccasionFirmAddress}
                                    onChange={(value) => { setReferenceModel({ ...referenceModel, firstOccasionFirmAddress: { ...value } }) }}
                                    id="firstOccassionAddressForm">
                                    <Address showErrors={showErrors} boundValues={referenceModel.firstOccasionFirmAddress} />
                                </Form>
                            </Card.Body>
                        </Card>
                        <br />
                        <Card>
                            <Card.Header><h6>Second Occasion</h6></Card.Header>
                            <Card.Body>
                                <Form id="secondOccasionForm" formValue={referenceModel} onChange={data => setReferenceModel(data)} fluid>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <ControlLabel>
                                                    <strong>
                                                        Firm Name
                                                    </strong>
                                                </ControlLabel>
                                            </Col>
                                            <Col sm={9}>
                                                <FormControl name="secondOccasionFirmName" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <ControlLabel>
                                                <strong>
                                                    Date From
                                                </strong>
                                            </ControlLabel>
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="month"
                                                block="true"
                                                name="dateFromSecondOccasion" />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <ControlLabel>
                                                <strong>
                                                    Date To
                                                </strong>
                                            </ControlLabel>
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="month"
                                                block="true"
                                                name="dateToSecondOccasion" />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <ControlLabel>
                                                    <strong>
                                                        Applicant's Position
                                                    </strong>
                                                </ControlLabel>
                                            </Col>
                                            <Col sm={9}>
                                                <FormControl name="secondOccasionPosition" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <ControlLabel>
                                                    <strong>
                                                        Reference's Position
                                                    </strong>
                                                </ControlLabel>
                                            </Col>
                                            <Col sm={9}>
                                                <FormControl name="secondOccasionReferencePosition" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                                <br />
                                <Form fluid
                                    model={addressModel}
                                    formValue={referenceModel.secondOccasionFirmAddress}
                                    onChange={(value) => { setReferenceModel({ ...referenceModel, secondOccasionFirmAddress: { ...value } }) }}
                                    id="secondOccassionAddressForm">
                                    <Address hideRequired={true} boundValues={referenceModel.secondOccasionFirmAddress} />
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                }
                {referenceModel.membersOfSameFirm === false &&
                    <Form formValue={referenceModel} onChange={referenceModel => setReferenceModel(referenceModel)} fluid>
                        <div>
                            <FormGroup>
                                <ControlLabel>
                                    If not, please state how you know each other (include dates) <RedStar/>
                                </ControlLabel>
                                <FormControl
                                    accepter={Input}
                                    componentClass="textarea"
                                    rows={5}
                                    name="notMembersOfSameFirmExplanation"
                                    errorMessage={(showErrors && !referenceModel.notMembersOfSameFirmExplanation) ? "This field is required" : null} />
                            </FormGroup>
                        </div>
                    </Form>}
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="referenceForm" variant="success">Save</Button>
                <Button variant="danger" onClick={() => { setShowErrors(false); props.onHide(); }}>Cancel</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddEditPersonalReferenceModal;