import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import { FaFile, FaPrint } from 'react-icons/fa';
import authHeader from "../../../services/authheader";
import axios from "axios";
import './ReviewPdf.css';


function ReviewPdf(props) {
    const downloadPdf = () => {
        var urlProps = {};
        switch (props.parentTypeId) {
            case 1: // Application
                urlProps = {
                    url: process.env.REACT_APP_APIURL + "/Reports/ApplicationPdf?applicationId=" + props.id,
                    name: "ApplicationPdf.pdf"
                }
                break;
            case 2: // Reinstatement
                urlProps = {
                    url: process.env.REACT_APP_APIURL + "/Reports/ReinstatementPdf?reinstatementId=" + props.id,
                    name: "ReinstatementPdf.pdf"
                }
                break;
            case 3: // Renewal
                urlProps = {
                    url: process.env.REACT_APP_APIURL + "/Reports/RenewalPdf?renewalId=" + props.id,
                    name: "RenewalPdf.pdf"
                }
                break;
        }
        Swal.fire({
            title: 'Generating PDF',
            didOpen: () => {
                Swal.showLoading();
                axios.post(urlProps.url, null, { headers: authHeader(), responseType: 'arraybuffer' }).then((response) => {
                    var name = new String(response.headers['content-disposition']);
                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', urlProps.name);
                    document.body.appendChild(link);
                    Swal.close()
                    link.click();
                    link.remove();
                });
            },
            allowOutsideClick: false
        })
    }

    return (
        <Button onClick={downloadPdf} variant='primary'><FaFile /> Download PDF</Button>
    );
}

export default ReviewPdf;