import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import designService from '../../../../../../services/Public/Reinstatement/PE/design.service';
import peReinstatementService from '../../../../../../services/Public/Reinstatement/PE/peReinstatement.service';
import Information from '../../../../../Shared/Card/Information';
import Issues from '../../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import RedStar from '../../../../../Shared/GenericComponents/RedStar';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import YesNoRadioOption from '../../../../../Shared/RadioButton/YesNoRadioOption';

function BuildingDesignPage() {
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const [model, setModel] = useState({
        hasLaBuildingDesign: '',
        hasSafetyCodeHours: null,
        reinstatementId: reinstatementId,
        entityId: entityId
    });
    const { push } = useHistory();
    const sectionId = 15;
    const routes = useSelector(x => x.currentRoutes);
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        peReinstatementService.peGetSection(reinstatementId, sectionId).then((data) => {
            setSectionData(data.data);
            designService.getBuildingDesign(reinstatementId).then((response) => {
                if (response.data)
                    setModel({
                        ...response.data
                    })
            })
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const validationModel = Schema.Model({
        hasLaBuildingDesign: BooleanType().isRequired("This field is required."),
        hasSafetyCodeHours: BooleanType().isRequired("This field is required.")
    });
    const formSubmit = (val, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (val || model.hasLaBuildingDesign == false) {
                designService.postBuildingDesign(model).then((response) => {
                    if (response.data)
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            }
        }
    }

    return (
        <div className="peReinstatementDesignPage">
            <Container fluid>
                <b>Professional Engineer Reinstatement</b>
                <h4 className="mb-3">Building Design</h4>
                <Information content={GetReinstatementInformationContent("design")} />
                <br />
                <Form formValue={model} onSubmit={formSubmit} onChange={setModel} model={validationModel} readOnly={!sectionData.isUserEditable} >
                    <Card>
                        <Card.Header><h6>Building Design in Louisiana</h6></Card.Header>
                        <Card.Body>
                            <p className="mb-3"><RedStar /> Have you designed buildings or building systems in Louisiana since your license was no longer in active status?</p>
                            <YesNoRadioOption radioName="hasLaBuildingDesign" />
                            {model.hasLaBuildingDesign == true &&
                                <>
                                    <hr />
                                    <h6 className="mb-3">Safety Code Hours</h6>
                                <p className="mb-3">Do you have 4 PDH’s of Life Safety Code, building codes, and/or Americans with Disabilities Act Accessibility Guidelines <strong>per calendar year(s)</strong> when you designed buildings or buildings systems in Louisiana?</p>
                                    <YesNoRadioOption radioName="hasSafetyCodeHours" />
                                </>
                            }
                        </Card.Body>
                    </Card>
                    <br />
                    <Issues isUserEditable={true} type={2} sectionId={sectionData.id} />
                    <FormActionButtons backToSectionLink="/individual/pereinstatement/sections" />
                </Form>
            </Container >
        </div >
    )
}
export default BuildingDesignPage;