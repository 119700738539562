import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { Icon, Table } from 'rsuite';
import GenericBadge from '../../../../Shared/Badge/GenericBadge';
import GenericPagination from '../../../../Shared/Grid/GenericPagination';

function Recommendation(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setGridData(props.griddata)
        setHasLoaded(true);
    }, [props.griddata])

    const getData = () => {
        return gridData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <h6>Professional Land Surveyors</h6>
                    </Col>

                </Row>
            </Card.Header>
            <Card.Body>
                {(hasLoaded)
                    &&
                    <div>
                        <Table wordWrap autoHeight data={filteredData}>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                    <b>Full Name</b>
                                </Table.HeaderCell>
                                <Table.Cell>
                                    {
                                        rowData =>
                                            `${rowData.firstName} ${rowData.lastName}`

                                    }
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                    <b>Email</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="email" />
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                    <b>Sent Date</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.sentDate ? new Date(rowData.sentDate).toLocaleDateString() : '')}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                    <b>Response Date</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.responseDate ? new Date(rowData.responseDate).toLocaleDateString() : '')}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                    <b>Verification Status</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.verificationStatus ? <GenericBadge status={rowData.verificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="id">
                                    {rowData => {
                                        const verificationStatusId = rowData.verificationStatus?.id;
                                        function handleAction() {
                                            if (props.isUserEditable) {
                                                var tempData = { ...rowData, modalType: 'Edit' }
                                                props.openModal(tempData);
                                            }
                                        }
                                        function openVerifierModal() {
                                            props.openVerifierModal(rowData.id);
                                        }
                                        if (verificationStatusId > 1) {
                                            if (verificationStatusId == 4) {
                                                return (<span>
                                                    <Icon icon="file-text" size="2x" title="View verifier response" onClick={openVerifierModal} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                    <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />
                                                </span>)
                                            }
                                        }
                                        else {
                                            return (
                                                <span>
                                                    <Icon icon="send" size="2x" title="Send email for verification" onClick={() => { props.emailReference(rowData) }} style={{ cursor: "pointer" }} /> &nbsp;&nbsp;
                                                    <Icon icon="edit2" size="2x" title="Edit" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                    <Icon icon="trash" size="2x" title="Delete" onClick={() => { props.deleteRow(rowData) }} style={{ cursor: "pointer" }} />
                                                </span>
                                            );
                                        }
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                        <GenericPagination dataLength={gridData.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                    </div>}
            </Card.Body>
        </Card>
    )
}

export default Recommendation