import React, { useState, useEffect } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { Table } from 'rsuite';
import Swal from 'sweetalert2';
import supervisingProfessionalService from '../../../../services/supervisingProfessional.service';
import GenericBadge from '../../../Shared/Badge/GenericBadge';
import Information from '../../../Shared/Card/Information';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import { GetSupervisingProfessionalIndividualInstructions } from '../../../Shared/InformationContent/GeneralInformation';
import SupervisingProfessionalDocumentUpload from './SupervisingProfessionalDocumentUpload';

function SupervisingProfessionalPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [supervisingProfessionals, setSupervisingProfessionals] = useState([]);
    const declineSupervisingProfessional = (value) => {
        supervisingProfessionalService.postSupervisingProfessional(value.supervisingProfessionalId, false).then((data) => {
            if (data.data) {
                refreshGrid();
            } else {
                Swal.fire("Error", "Failed to update status.", "error")
            }
        });
    }
    const acceptSupervisingProfessional = (value) => {
        supervisingProfessionalService.postSupervisingProfessional(value.supervisingProfessionalId, true).then((data) => {
            if (data.data) {
                refreshGrid();
            } else {
                Swal.fire("Error", "Failed to update status.", "error")
            }
        })
    }
    useEffect(() => {
        refreshGrid();
        setHasLoaded(true);
    }, [])
    const refreshGrid = () => {
        supervisingProfessionalService.getSupervisingProfessional().then((data) => {
            var filteredData = data.data.filter(x => x.codeVerificationStatus?.verificationStatus != "Inactive");
            setSupervisingProfessionals(filteredData);
        }
        );
    }
    const changeOwnership = (values) => {
        var text = values.isOwner ? "This will change the supervising professional to an employee of the firm." : "This will change the supervising professional to an owner of the firm.";
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setHasLoaded(false)
                supervisingProfessionalService.changeSupervisingProfessionalOwnership(values.relationshipId, values.supervisingProfessionalId)
                    .then(() => { refreshGrid(); setHasLoaded(true) })
                    .catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } })
                    .finally(() => setHasLoaded(true));
            }
        })
    }
    const inactivateSupervisingProfessional = (values) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "By choosing to inactivate this supervising professional, this record will be removed from your supervising professional locker.  Would you like to continue?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setHasLoaded(false)
                supervisingProfessionalService.inactivateSupervisingProfessionalAsIndividual(values.relationshipId, values.supervisingProfessionalId)
                    .then(() => { refreshGrid(); setHasLoaded(true) })
                    .catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } })
                    .finally(() => setHasLoaded(true));
                // Some service that removes it + refresh when it's good
            }
        })
    }
    return (
        <div>
            <Container fluid>
                <h4 className="mb-3">Supervising Professional</h4>
                <Information content={GetSupervisingProfessionalIndividualInstructions()} />
                <br />
                <SupervisingProfessionalDocumentUpload />
                <br />
                <Card>
                    <Card.Header>Supervising Professional Status</Card.Header>
                    <Card.Body>
                        {hasLoaded ?
                            <Table wordWrap autoHeight data={supervisingProfessionals}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Firm Name
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.firmFormattedName)}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Firm License #
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.firmFormattedLicenseNumber)}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={2}>
                                    <Table.HeaderCell>
                                        SP Employee/Owner Status (click to update)
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {
                                            rowData => (<div style={{ display: "flex", alignItems: "center" }}>
                                                <label>
                                                    <b>Employee</b>
                                                </label>
                                                &nbsp;
                                                <label className="switch">
                                                    {["Approved", "Pending", "Active"].includes(rowData.codeVerificationStatus?.verificationStatus) ?
                                                        <input checked={rowData.isOwner} onChange={() => { changeOwnership(rowData) }} type="checkbox" />
                                                        :
                                                        <input checked={rowData.isOwner} onChange={() => { changeOwnership(rowData) }} type="checkbox" disabled />
                                                    }
                                                    <span className="slider round"></span>
                                                </label>
                                                &nbsp;
                                                <label>
                                                    <b>Owner</b>
                                                </label>
                                            </div>)
                                        }
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Received Date
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.sentDate ? new Date(rowData.sentDate).toLocaleDateString() : '')}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Response Date
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.responseDate ? new Date(rowData.responseDate).toLocaleDateString() : '')}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Verification Status
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.codeVerificationStatus.verificationStatus ? <GenericBadge status={rowData.codeVerificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.codeVerificationStatus?.verificationStatus == "Pending" ?
                                            <>
                                                <Button className="ml-1" variant="danger" onClick={() => declineSupervisingProfessional(rowData)}>Decline</Button>
                                                <Button className="ml-1" variant="primary" onClick={() => acceptSupervisingProfessional(rowData)}>Accept</Button>
                                            </> :
                                            (rowData.codeVerificationStatus?.verificationStatus == "Active" &&
                                                <>
                                                    <Button className="ml-1" variant="danger" onClick={() => inactivateSupervisingProfessional(rowData)}>Inactivate</Button>
                                                </>)
                                        )}
                                    </Table.Cell>
                                </Table.Column>
                            </Table> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
export default SupervisingProfessionalPage