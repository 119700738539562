import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shoppingCartService from '../../../../services/Public/shoppingCart.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';

function PaymentFailedPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory()
    var dispatch = useDispatch();
    var refreshToken = useSelector(x => x.refreshToken);
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        shoppingCartService.postFailOnlinePayment(params.token, params.r).then((response) => {
            if (response.data) {
                dispatch({ type: "PERSIST_CURRENT_REFRESH", payload: refreshToken + 1 });
                setHasLoaded(true)
            }
        })
    }, [])

    const returnToCart = () => {
        push('/individual/shoppingcart')
    }
    return (
        <Card>
            <Card.Body style={{ textAlign: 'center' }}>
                {hasLoaded ?
                    <>
                        <p className="colorRed">
                            <FaTimes style={{ height: '10em', width: '10em' }} />
                            <h3>Payment Failed</h3>
                        </p>
                        <h5 className="mb-3">Looks like we encountered an error. Please try again. If you continue to have issues, try another payment method.</h5>
                        <Button onClick={returnToCart}>Back to Cart</Button>
                    </> : <LoadingSpinner />
                }
            </Card.Body>
        </Card>

    );
}

export default PaymentFailedPage;