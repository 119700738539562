import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import { ControlLabel, Form, FormControl, FormGroup, InputPicker, Schema, SelectPicker } from 'rsuite';
import Swal from 'sweetalert2';
import { suffixData, titleData, yesNoData } from '../../../../fakeapi';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import { emailPhonePrivacy, getCountries, getStates, journalDropdown, subscribeDropdown } from '../../../../services/Shared/codeTable.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import PhoneNumberMask from '../../../Shared/GenericComponents/PhoneNumberMask';
import RedStar from '../../../Shared/GenericComponents/RedStar';


const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};

function EditIndGeneralInformation(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [data, setData] = useState({});
    const [genInfoFormData, setGenInfoFormData] = useState({});
    const [genInfoFormDataCache, setGenInfoFormDataCache] = useState({});
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        credentialService.getIndGeneralInfo(props.entityid).then((response) => {
            setData(response.data);
            getCountries().then(setCountries);
            getStates().then(setStates);
            setGenInfoFormData({
                firstName: response.data.firstName ?? undefined,
                middleName: response.data.middleName ?? undefined,
                lastName: response.data.lastName ?? undefined,
                maidenName: response.data.maidenName ?? undefined,
                suffix: response.data.suffix,
                title: response.data.title,
                lolaNumber: response.data.lolaNumber ?? undefined,
                mailingAddressStreet: response.data.mailingAddress?.streetAddress,
                mailingAddressCity: response.data.mailingAddress?.city,
                mailingAddressState: response.data.mailingAddress?.state,
                mailingAddressStateProvidence: response.data.mailingAddress?.provinceRegion,
                mailingAddressPostalCode: response.data.mailingAddress?.postalCode,
                mailingAddressCountry: response.data.mailingAddress?.country,
                publicAddressStreet: response.data.publicAddress?.streetAddress,
                publicAddressCity: response.data.publicAddress?.city,
                publicAddressState: response.data.publicAddress?.state,
                publicAddressStateProvidence: response.data.publicAddress?.provinceRegion,
                publicAddressPostalCode: response.data.publicAddress?.postalCode,
                publicAddressCountry: response.data.publicAddress?.country,
                userId: response.data.userName ?? undefined,
                dateOfBirth: response.data.dateOfBirth != null ? dayjs(response.data.dateOfBirth).format('YYYY-MM-DD') : undefined,
                dateOfDeath: response.data.dateOfDeath != null ? dayjs(response.data.dateOfDeath).format('YYYY-MM-DD') : undefined,
                birthCity: response.data.birthCity ?? undefined,
                birthState: response.data.birthState,
                birthCountry: response.data.birthCountry,
                nonUsBirthState: response.data.nonUsBirthState,
                federalId: response.data.federalId ?? undefined,
                visaGreenCardNumber: response.data.visaGreenCardNumber ?? undefined,
                myNceesNumber: response.data.myNceesNumber != null ? response.data.myNceesNumber.replace(/[^\d]*/g, "") : undefined,
                isUsCitizen: response.data.isUsCitizen,
                email: response.data.primaryEmailAddress?.contactEmailAddress ?? undefined,
                alternateEmail: response.data.alternateEmailAddress?.contactEmailAddress ?? undefined,
                homePhoneNum: response.data.homePhone?.contactPhoneNumber ?? undefined,
                mobilePhoneNum: response.data.cellPhone?.contactPhoneNumber ?? undefined,
                workPhoneNum: response.data.workPhone?.contactPhoneNumber ?? undefined,
                amountDue: response.data.amountDue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                additionalInformation: response.data.additionalInformation ?? undefined,
                citizenshipCountry: response.data.citizenshipCountry,
                certificateName: response.data.certificateName,
                journalSubscription: response.data.journalSubscription,
                pressReleases: response.data.pressReleases,
                webinar: response.data.webinar,
                emailPhonePrivacy: response.data.emailPhonePrivacy,
            })
            setGenInfoFormDataCache({
                firstName: response.data.firstName ?? undefined,
                middleName: response.data.middleName ?? undefined,
                lastName: response.data.lastName ?? undefined,
                maidenName: response.data.maidenName ?? undefined,
                suffix: response.data.suffix,
                title: response.data.title,
                lolaNumber: response.data.lolaNumber ?? undefined,
                mailingAddressStreet: response.data.mailingAddress?.streetAddress,
                mailingAddressCity: response.data.mailingAddress?.city,
                mailingAddressState: response.data.mailingAddress?.state,
                mailingAddressStateProvidence: response.data.mailingAddress?.provinceRegion,
                mailingAddressPostalCode: response.data.mailingAddress?.postalCode,
                mailingAddressCountry: response.data.mailingAddress?.country,
                publicAddressStreet: response.data.publicAddress?.streetAddress,
                publicAddressCity: response.data.publicAddress?.city,
                publicAddressState: response.data.publicAddress?.state,
                publicAddressStateProvidence: response.data.publicAddress?.provinceRegion,
                publicAddressPostalCode: response.data.publicAddress?.postalCode,
                publicAddressCountry: response.data.publicAddress?.country,
                userId: response.data.userName ?? undefined,
                dateOfBirth: response.data.dateOfBirth != null ? dayjs(response.data.dateOfBirth).format('YYYY-MM-DD') : undefined,
                dateOfDeath: response.data.dateOfDeath != null ? dayjs(response.data.dateOfDeath).format('YYYY-MM-DD') : undefined,
                birthCity: response.data.birthCity ?? undefined,
                birthState: response.data.birthState,
                birthCountry: response.data.birthCountry,
                nonUsBirthState: response.data.nonUsBirthState,
                federalId: response.data.federalId ?? undefined,
                visaGreenCardNumber: response.data.visaGreenCardNumber ?? undefined,
                myNceesNumber: response.data.myNceesNumber != null ? response.data.myNceesNumber.replace(/[^\d]*/g, "") : undefined,
                isUsCitizen: response.data.isUsCitizen,
                email: response.data.primaryEmailAddress?.contactEmailAddress ?? undefined,
                alternateEmail: response.data.alternateEmailAddress?.contactEmailAddress ?? undefined,
                homePhoneNum: response.data.homePhone?.contactPhoneNumber ?? undefined,
                mobilePhoneNum: response.data.cellPhone?.contactPhoneNumber ?? undefined,
                workPhoneNum: response.data.workPhone?.contactPhoneNumber ?? undefined,
                amountDue: response.data.amountDue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                additionalInformation: response.data.additionalInformation ?? undefined,
                citizenshipCountry: response.data.citizenshipCountry,
                certificateName: response.data.certificateName,
                journalSubscription: response.data.journalSubscription,
                pressReleases: response.data.pressReleases,
                webinar: response.data.webinar,
                emailPhonePrivacy: response.data.emailPhonePrivacy,
            })
            setHasLoaded(true);
        })
    }, [])

    const { StringType, ObjectType, BooleanType, NumberType } = Schema.Types;
    const validationModel = Schema.Model({
        firstName: StringType().isRequired('This field is required.'),
        lastName: StringType().isRequired('This field is required.'),
        //title: StringType().isRequired('This field is required.'),
        //mailingAddressStreet: StringType().isRequired('This field is required.'),
        //mailingAddressCity: StringType().isRequired('This field is required.'),
        //mailingAddressState: ObjectType().isRequired('This field is required.'),
        //mailingAddressStateProvidence: StringType().isRequired('This field is required.'),
        //mailingAddressPostalCode: StringType().isRequired('This field is required.'),
        //mailingAddressCountry: ObjectType().isRequired('This field is required.'),
        //publicAddressStreet: StringType().isRequired('This field is required.'),
        //publicAddressCity: StringType().isRequired('This field is required.'),
        //publicAddressState: ObjectType().isRequired('This field is required.'),
        //publicAddressStateProvidence: StringType().isRequired('This field is required.'),
        //publicAddressPostalCode: StringType().isRequired('This field is required.'),
        //publicAddressCountry: ObjectType().isRequired('This field is required.'),
        mobilePhoneNum: NumberType().pattern(/^[0-9]{10}/, "Please enter a valid Phone Number"),//.isRequired("This field is required."),
        workPhoneNum: NumberType().pattern(/^[0-9]{10}/, "Please enter a valid Phone Number"),
        homePhoneNum: NumberType().pattern(/^[0-9]{10}/, "Please enter a valid Phone Number"),
        userId: StringType().isRequired('This field is required.'),
        myNceesNumber: NumberType().pattern(/^[0-9]{7}/, "Please enter a valid My Ncees Number")
        //federalId: StringType().isRequired('This field is required.').pattern(/^([0-9]{3}[-][0-9]{2}[-][0-9]{4})/, "Please enter a valid SSN"),
        //email: StringType().isRequired('This field is required.'),
        //isUsCitizen: BooleanType().isRequired('This field is required.'),
        //dateOfBirth: StringType().isRequired('This field is required.'),
        //birthCity: StringType().isRequired('This field is required.'),
        //birthState: ObjectType().isRequired('This field is required.'),
        //nonUsBirthState: StringType().isRequired('This field is required'),
        //birthCountry: ObjectType().isRequired('This field is required.'),
        //citizenshipCountry: ObjectType().isRequired('This field is required.')
    })

    const inputHasError = (cacheInputVal, formInputVal) => {
        if ((cacheInputVal != null && cacheInputVal.length !== 0) && (formInputVal == null || formInputVal.length === 0))
            return true;
        return false;
    }
    const nceesChange = (val) => {
        var newInfo = genInfoFormData;
        var correctedText = val.replace(/[^\d-]*/g, "");
        newInfo.myNceesNumber = correctedText;
        setGenInfoFormDataCache(newInfo);
        setGenInfoFormData(newInfo);
    }
    const conditionalValidation = () => {
        var hasErrors = false;
        if (inputHasError(genInfoFormDataCache.mailingAddressStreet, genInfoFormData.mailingAddressStreet) ||
            inputHasError(genInfoFormDataCache.mailingAddressCity, genInfoFormData.mailingAddressCity) ||
            inputHasError(genInfoFormDataCache.mailingAddressState, genInfoFormData.mailingAddressState) ||
            inputHasError(genInfoFormDataCache.mailingAddressStateProvidence, genInfoFormData.mailingAddressStateProvidence) ||
            inputHasError(genInfoFormDataCache.mailingAddressPostalCode, genInfoFormData.mailingAddressPostalCode) ||
            inputHasError(genInfoFormDataCache.mailingAddressCountry, genInfoFormData.mailingAddressCountry) ||
            inputHasError(genInfoFormDataCache.publicAddressStreet, genInfoFormData.publicAddressStreet) ||
            inputHasError(genInfoFormDataCache.publicAddressCity, genInfoFormData.publicAddressCity) ||
            inputHasError(genInfoFormDataCache.publicAddressState, genInfoFormData.publicAddressState) ||
            inputHasError(genInfoFormDataCache.publicAddressStateProvidence, genInfoFormData.publicAddressStateProvidence) ||
            inputHasError(genInfoFormDataCache.publicAddressPostalCode, genInfoFormData.publicAddressPostalCode) ||
            inputHasError(genInfoFormDataCache.publicAddressCountry, genInfoFormData.publicAddressCountry) ||


            inputHasError(genInfoFormDataCache.title, genInfoFormData.title) ||
            inputHasError(genInfoFormDataCache.federalId, genInfoFormData.federalId) ||
            inputHasError(genInfoFormDataCache.email, genInfoFormData.email) ||
            inputHasError(genInfoFormDataCache.isUsCitizen, genInfoFormData.isUsCitizen) ||
            inputHasError(genInfoFormDataCache.dateOfBirth, genInfoFormData.dateOfBirth) ||
            inputHasError(genInfoFormDataCache.birthCity, genInfoFormData.birthCity) ||
            inputHasError(genInfoFormDataCache.birthState, genInfoFormData.birthState) ||
            inputHasError(genInfoFormDataCache.nonUsBirthState, genInfoFormData.nonUsBirthState) ||
            inputHasError(genInfoFormDataCache.birthCountry, genInfoFormData.birthCountry) ||
            (genInfoFormData.isUsCitizen == false && inputHasError(genInfoFormDataCache.citizenshipCountry, genInfoFormData.citizenshipCountry))) {
            hasErrors = true;
        }

        if ((genInfoFormData.mailingAddressCountry?.country === "United States" && inputHasError(genInfoFormDataCache.mailingAddressState, genInfoFormData.mailingAddressState)) ||
            (genInfoFormData.mailingAddressCountry?.country !== "United States") && inputHasError(genInfoFormDataCache.mailingAddressStateProvidence, genInfoFormData.mailingAddressStateProvidence) ||
            (genInfoFormData.publicAddressCountry?.country !== "United States") && inputHasError(genInfoFormDataCache.publicAddressState, genInfoFormData.publicAddressState) ||
            (genInfoFormData.publicAddressCountry?.country !== "United States") && inputHasError(genInfoFormDataCache.publicAddressStateProvidence, genInfoFormData.publicAddressStateProvidence)) {
            hasErrors = true;
        }
        return hasErrors;
    }
    const submitModal = (validation, event) => {
        var validationObject = validationModel.check(genInfoFormData);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                if (key == "publicAddressStateProvidence" || key == "publicAddressState") {
                    if (!(key == "publicAddressStateProvidence" && genInfoFormData.publicAddressCountry?.country === "United States") &&
                        !(key == "publicAddressState" && genInfoFormData.publicAddressCountry?.country !== "United States")) {
                        return;
                    }
                }
                else if (key == "mailingAddressStateProvidence" || key == "mailingAddressState") {
                    if (!(key == "mailingAddressStateProvidence" && genInfoFormData.mailingAddressCountry?.country === "United States") &&
                        !(key == "mailingAddressState" && genInfoFormData.mailingAddressCountry?.country !== "United States")) {
                        return;
                    }
                }
                else if (key.includes("birthState") || key.includes("nonUsBirthState")) {
                    if (
                        (genInfoFormData.birthCountry?.country !== "United States" && genInfoFormData.nonUsBirthState !== "")
                        || (genInfoFormData.birthCountry?.country === "United States" && genInfoFormData.birthState?.state)) {
                        continue;
                    }
                    else return;

                }
                else if (key.includes("citizenshipCountry")) {
                    if (genInfoFormData.isUsCitizen)
                        continue;
                    else return;
                }
                else if (key == "userId" && !data.userName) {
                    continue;
                }
                else {
                    return;
                }
            }
        }
        if (conditionalValidation()) {
            setShowErrors(true);
            return;
        }
        var dataToTransmit = {
            ...data,
            firstName: genInfoFormData.firstName,
            middleName: genInfoFormData.middleName,
            lastName: genInfoFormData.lastName,
            maidenName: genInfoFormData.maidenName,
            suffix: genInfoFormData.suffix,
            title: genInfoFormData.title,
            lolaNumber: genInfoFormData.lolaNumber,
            mailingAddress: {
                ...data.mailingAddress,
                state: genInfoFormData.mailingAddressState,
                country: genInfoFormData.mailingAddressCountry,
                city: genInfoFormData.mailingAddressCity,
                postalCode: genInfoFormData.mailingAddressPostalCode,
                streetAddress: genInfoFormData.mailingAddressStreet,
                provinceRegion: genInfoFormData.mailingAddressStateProvidence
            },
            publicAddress: {
                ...data.publicAddress,
                state: genInfoFormData.publicAddressState,
                country: genInfoFormData.publicAddressCountry,
                city: genInfoFormData.publicAddressCity,
                postalCode: genInfoFormData.publicAddressPostalCode,
                streetAddress: genInfoFormData.publicAddressStreet,
                provinceRegion: genInfoFormData.publicAddressStateProvidence
            },
            userName: genInfoFormData.userId,
            //federalId: genInfoFormData.federalId,
            primaryEmailAddress: {
                ...data.primaryEmailAddress,
                contactEmailAddress: genInfoFormData.companyEmail
            },
            dateOfBirth: new Date(genInfoFormData.dateOfBirth),
            dateOfDeath: genInfoFormData.dateOfDeath ? new Date(genInfoFormData.dateOfDeath) : null,
            birthCity: genInfoFormData.birthCity,
            birthState: genInfoFormData.birthCountry?.id == 221 ? genInfoFormData.birthState : null,
            nonUsBirthState: genInfoFormData.birthCountry?.id == 221 ? "" : genInfoFormData.nonUsBirthState,
            birthCountry: genInfoFormData.birthCountry,
            visaGreenCardNumber: genInfoFormData.visaGreenCardNumber,
            myNceesNumber: genInfoFormData.myNceesNumber,
            isUsCitizen: genInfoFormData.isUsCitizen,
            primaryEmailAddress: { ...data.primaryEmailAddress, contactEmailAddress: genInfoFormData.email },
            alternateEmailAddress: { ...data.alternateEmailAddress, contactEmailAddress: genInfoFormData.alternateEmail },
            cellPhone: { ...data.cellPhone, contactPhoneNumber: genInfoFormData.mobilePhoneNum == '' ? null : genInfoFormData.mobilePhoneNum },
            homePhone: { ...data.homePhone, contactPhoneNumber: genInfoFormData.homePhoneNum == '' ? null : genInfoFormData.homePhoneNum },
            workPhone: { ...data.workPhone, contactPhoneNumber: genInfoFormData.workPhoneNum == '' ? null : genInfoFormData.workPhoneNum },
            additionalInformation: genInfoFormData.additionalInformation,
            citizenshipCountry: genInfoFormData.citizenshipCountry,
            certificateName: genInfoFormData.certificateName,
            journalSubscription: genInfoFormData.journalSubscription,
            pressReleases: genInfoFormData.pressReleases,
            webinar: genInfoFormData.webinar,
            emailPhonePrivacy: genInfoFormData.emailPhonePrivacy,
        }
        credentialService.postIndGeneralInfo(dataToTransmit).then((response) => {
            props.onHide();
            props.saveDataCallback();
        }).catch((reason) => {
            if (reason.response.status == 400)
                Swal.fire("Error!", reason.response.data, 'error');
            else
                Swal.fire("Error!", 'An error occured. Please try again.', 'error');
        })

    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4>General Information</h4>
            </Modal.Header>
            <Modal.Body>
                {
                    hasLoaded ?
                        <>
                            <Form id='genInfoEditForm' fluid formValue={genInfoFormData} model={validationModel} onChange={(vals) => { setGenInfoFormData({ ...vals, citizenshipCountry: (vals.isUsCitizen ? null : vals.citizenshipCountry) }) }} onSubmit={submitModal}>
                                <Row className="mb-3">
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <b>
                                                First Name  <RedStar />
                                            </b>
                                        </ControlLabel>
                                        <FormControl
                                            name='firstName'
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <b>
                                                Middle Name
                                            </b>
                                        </ControlLabel>
                                        <FormControl
                                            name='middleName'
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <b>
                                                Last Name <RedStar />
                                            </b>
                                        </ControlLabel>
                                        <FormControl
                                            name='lastName'
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <b>
                                                Maiden Name
                                            </b>
                                        </ControlLabel>
                                        <FormControl
                                            name='maidenName'
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <b>
                                                Suffix
                                            </b>
                                        </ControlLabel>
                                        <FormControl
                                            placeholder="Suffix"
                                            data={suffixData}
                                            name="suffix"
                                            block
                                            accepter={InputPicker}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <b>
                                                Title
                                            </b>
                                        </ControlLabel>
                                        <FormControl
                                            accepter={InputPicker}
                                            placeholder="Title"
                                            data={titleData}
                                            errorMessage={(inputHasError(genInfoFormDataCache.title, genInfoFormData.title) && showErrors) ? "This field is required" : null}
                                            block
                                            name="title"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <strong>
                                                LOLA #
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name='lolaNumber'
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h6>Mailing Address</h6>
                                        <hr />
                                        <ControlLabel>
                                            <strong>
                                                Country
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            block
                                            name="mailingAddressCountry"
                                            accepter={InputPicker}
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressCountry, genInfoFormData.mailingAddressCountry) && showErrors) ? "This field is required" : null}
                                            data={countries}
                                            className='mb-1'
                                        />
                                        <ControlLabel>
                                            <strong>Address</strong>
                                        </ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="mailingAddressStreet"
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressStreet, genInfoFormData.mailingAddressStreet) && showErrors) ? "This field is required" : null}
                                            className='mb-1'
                                        />
                                        <ControlLabel>
                                            <strong>City </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name="mailingAddressCity"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressCity, genInfoFormData.mailingAddressCity) && showErrors) ? "This field is required" : null}
                                        />
                                        {
                                            genInfoFormData.mailingAddressCountry?.country === "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    accepter={InputPicker}
                                                    placeholder="Please Select"
                                                    block
                                                    className='mb-1'
                                                    data={states}
                                                    name="mailingAddressState"
                                                    errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressState, genInfoFormData.mailingAddressState) && showErrors) ? "This field is required" : null}
                                                    searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                                />
                                            </>
                                        }
                                        {
                                            genInfoFormData.mailingAddressCountry?.country !== "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State/Province/Region </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    name="mailingAddressStateProvidence"
                                                    className='mb-1'
                                                    errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressStateProvidence, genInfoFormData.mailingAddressStateProvidence) && showErrors) ? "This field is required" : null}
                                                />
                                            </>
                                        }
                                        <ControlLabel>
                                            <strong>Zip/Postal Code </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name="mailingAddressPostalCode"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressPostalCode, genInfoFormData.mailingAddressPostalCode) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <h6>Public Address</h6>
                                        <hr />
                                        <ControlLabel>
                                            <strong>
                                                Country
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            block
                                            name="publicAddressCountry"
                                            accepter={InputPicker}
                                            data={countries}
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressCountry, genInfoFormData.publicAddressCountry) && showErrors) ? "This field is required" : null}
                                            className='mb-1'
                                        />
                                        <ControlLabel>
                                            <strong>Address</strong>
                                        </ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="publicAddressStreet"
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressStreet, genInfoFormData.publicAddressStreet) && showErrors) ? "This field is required" : null}
                                            className='mb-1'
                                        />
                                        <ControlLabel>
                                            <strong>City </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name="publicAddressCity"
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressCity, genInfoFormData.publicAddressCity) && showErrors) ? "This field is required" : null}
                                            className='mb-1'
                                        />
                                        {
                                            genInfoFormData.publicAddressCountry?.country === "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    accepter={InputPicker}
                                                    placeholder="Please Select"
                                                    block
                                                    className='mb-1'
                                                    data={states}
                                                    name="publicAddressState"
                                                    errorMessage={(inputHasError(genInfoFormDataCache.publicAddressState, genInfoFormData.publicAddressState) && showErrors) ? "This field is required" : null}
                                                    searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                                />
                                            </>
                                        }
                                        {
                                            genInfoFormData.publicAddressCountry?.country !== "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State/Province/Region </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    name="publicAddressStateProvidence"
                                                    errorMessage={(inputHasError(genInfoFormDataCache.publicAddressStateProvidence, genInfoFormData.publicAddressStateProvidence) && showErrors) ? "This field is required" : null}
                                                    className='mb-1'
                                                />
                                            </>
                                        }
                                        <ControlLabel>
                                            <strong>Zip/Postal Code </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name="publicAddressPostalCode"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressPostalCode, genInfoFormData.publicAddressPostalCode) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    {!data.userName ?
                                        <Col>
                                            <ControlLabel><b>UserId</b></ControlLabel>
                                            <FormControl
                                                disabled
                                            />
                                        </Col>
                                        : <Col>
                                            <ControlLabel><b>UserId</b><RedStar /></ControlLabel>
                                            <FormControl
                                                name="userId"
                                            />
                                        </Col>
                                    }
                                    <Col>
                                        <ControlLabel><b>Date of Birth</b></ControlLabel>
                                        <FormControl
                                            name="dateOfBirth"
                                            type='date'
                                            errorMessage={(inputHasError(genInfoFormDataCache.dateOfBirth, genInfoFormData.dateOfBirth) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Date of Death</b></ControlLabel>
                                        <FormControl
                                            name="dateOfDeath"
                                            type='date'
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>City of Birth</b></ControlLabel>
                                        <FormControl
                                            name="birthCity"
                                            errorMessage={(inputHasError(genInfoFormDataCache.birthCity, genInfoFormData.birthCity) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel>
                                            <b>{(genInfoFormData.birthCountry?.id === 221)
                                                ? <span>State of Birth</span> :
                                                <span>State/Province/Region</span>}
                                            </b></ControlLabel>
                                        {genInfoFormData.birthCountry?.id === 221 &&
                                            <FormControl
                                                accepter={InputPicker}
                                                placeholder="Please Select"
                                                block
                                                className='mb-1'
                                                data={states}
                                            name="birthState"
                                            errorMessage={(inputHasError(genInfoFormDataCache.birthState, genInfoFormData.birthState) && showErrors) ? "This field is required" : null}
                                                searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                            />
                                        }
                                        {
                                            genInfoFormData.birthCountry?.id !== 221 &&
                                            <FormControl placeholder="State/Province/Region of Birth"
                                                name="nonUsBirthState"
                                                errorMessage={(inputHasError(genInfoFormDataCache.nonUsBirthState, genInfoFormData.nonUsBirthState) && showErrors) ? "This field is required" : null}
                                            />
                                        }
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Country of Birth</b></ControlLabel>
                                        <FormControl
                                            block
                                            name="birthCountry"
                                            accepter={InputPicker}
                                            data={countries}
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.birthCountry, genInfoFormData.birthCountry) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>SSN/FEIN</b></ControlLabel>
                                        <FormControl
                                            name="federalId"
                                            errorMessage={(inputHasError(genInfoFormDataCache.federalId, genInfoFormData.federalId) && showErrors) ? "This field is required" : null}
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Visa/Green Card No.</b></ControlLabel>
                                        <FormControl
                                            name="visaGreenCardNumber"
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>NCEES ID #</b></ControlLabel>
                                        <FormGroup>
                                            <FormControl
                                                accepter={PhoneNumberMask}
                                                format="##-###-##"
                                                mask="_"
                                                name="myNceesNumber"
                                                checkTrigger="blur"
                                                //placeholder="NCEES ID#"
                                                //onChange={nceesChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Email/Phone Privacy</b></ControlLabel>
                                        <FormControl
                                            name="emailPhonePrivacy"
                                            data={emailPhonePrivacy}
                                            accepter={InputPicker}
                                            className='mb-1'
                                            block
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Email</b></ControlLabel>
                                        <FormControl
                                            name="email"
                                            errorMessage={(inputHasError(genInfoFormDataCache.email, genInfoFormData.email) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Alternate Email</b></ControlLabel>
                                        <FormControl
                                            name="alternateEmail"
                                        />
                                    </Col>

                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Mobile Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="mobilePhoneNum"
                                            format="(###) ###-####"
                                            mask="_"
                                            checkTrigger="blur"
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Work Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="workPhoneNum"
                                            format="(###) ###-####"
                                            mask="_"
                                            checkTrigger="blur"
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Home Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="homePhoneNum"
                                            format="(###) ###-####"
                                            mask="_"
                                            checkTrigger="blur"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>US Citizen</b></ControlLabel>
                                        <FormControl
                                            placeholder="Select Yes or No"
                                            data={yesNoData}
                                            searchable={false}
                                            name="isUsCitizen"
                                            block
                                            accepter={SelectPicker}
                                            errorMessage={(inputHasError(genInfoFormDataCache.isUsCitizen, genInfoFormData.isUsCitizen) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Certificate Name</b></ControlLabel>
                                        <FormControl
                                            name="certificateName"
                                        /></Col>
                                    <Col>
                                        <ControlLabel><b>Amount Due</b></ControlLabel>
                                        <FormControl
                                            name="amountDue"
                                            disabled
                                        /></Col>
                                </Row>
                                {
                                    genInfoFormData.isUsCitizen == false &&
                                    <Row>
                                        <Col>
                                            <>
                                                <ControlLabel><b>Citizenship Country</b><RedStar /></ControlLabel>
                                                <FormControl
                                                    block
                                                    name="citizenshipCountry"
                                                    accepter={InputPicker}
                                                    data={countries}
                                                    className='mb-1'
                                                    errorMessage={(inputHasError(genInfoFormDataCache.citizenshipCountry, genInfoFormData.citizenshipCountry) && showErrors) ? "This field is required" : null}
                                                />
                                            </>
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <ControlLabel><b>Additional Information</b></ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="additionalInformation"
                                            maxLength={1000}
                                        /></Col>

                                </Row>
                                <hr />

                                <Row className='mb-3'>
                                    <Col>
                                        <ControlLabel><b>Journal</b></ControlLabel>

                                        <FormControl
                                            name="journalSubscription"
                                            data={journalDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Press Releases</b></ControlLabel>
                                        <FormControl
                                            name="pressReleases"
                                            data={subscribeDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Webinar</b></ControlLabel>
                                        <FormControl
                                            name="webinar"
                                            data={subscribeDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </> : <LoadingSpinner />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
                <Button type="submit" form="genInfoEditForm" >Save</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default EditIndGeneralInformation;