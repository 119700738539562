import axios from "axios";
import authHeader from '../../../authheader'

const VFREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/FirmVfReinstatement";

const getContactInformation = (reinstatementId) => {
    return axios.get(`${VFREINSTATEMENTURL}/contactinformation`, { headers: authHeader(), params: { reinstatementId } });
}
export default {
    getContactInformation
}