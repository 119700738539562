import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import ShoppingCart from '../../../Shared/Card/ShoppingCart';
import Information from '../../../Shared/Card/Information';
import shoppingCartService from '../../../../services/Public/shoppingCart.service';
import { useDispatch, useSelector } from 'react-redux';
import { GetCartInstructions } from '../../../Shared/InformationContent/GeneralInformation';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
function ShoppingCartPage() {
    const [blocking, setBlocking] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    const { push } = useHistory();
    var dispatch = useDispatch();
    var refreshToken = useSelector(x => x.refreshToken);
    useEffect(() => {
        refreshGrid();
    }, [])
    function refreshGrid() {
        shoppingCartService.getShoppingCartData().then((response) => {
            dispatch({ type: "PERSIST_CURRENT_REFRESH", payload: refreshToken + 1 });
            if (response.data.length > 0) {
                var total = response.data.reduce(function (total, currentValue) {
                    return total + currentValue.feeDefinition.feeAmount;
                }, 0)
                response.data.push({ feeDefinition: { credentialType: 'Total', feeAmount: total }, id: 0 });
            }
            setGridData(response.data);
            setHasLoaded(true);
        });
    }
    const submitByMail = () => {
        Swal.fire({
            title: 'Confirm Mail-In Method',
            html: "Are you sure you want to pay by Mail-In? Your application will not be reviewed until payment is received.<b> Your application could take additional time to process.</b>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue'
        }).then((result) => {
            if (result.isConfirmed) {
                shoppingCartService.postMailPayment().then((response) => {
                    dispatch({ type: "PERSIST_INVOICE_ID", payload: response.data.id });
                    dispatch({ type: "PERSIST_CURRENT_REFRESH", payload: refreshToken + 1 });
                    push('/firm/invoice');
                })
            }

        })
    }

    const submitElectronicPayment = () => {
        setBlocking(true);
        shoppingCartService.postOnlinePayment().then((response) => {
            if (response.data)
                document.location.href = response.data;
        })
    }
    return (
        <React.Fragment>
            { hasLoaded ?
                <Container fluid>
                    <h4 className="mb-3">Cart</h4>
                    <Row>
                        <Col><Information content={GetCartInstructions()} /></Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <BlockUi blocking={blocking}>
                                <ShoppingCart submitElectronicPayment={submitElectronicPayment} submitByMail={submitByMail} gridData={gridData} refreshGrid={refreshGrid} />
                            </BlockUi>
                        </Col>
                    </Row>

                </Container> : <LoadingSpinner />
            }
        </React.Fragment>
    )
}
export default ShoppingCartPage;