import axios from "axios";
import authHeader from '../../authheader.js'

const ENTITYSEARCHURL = process.env.REACT_APP_APIURL + "/api/EntitySearch";

const postEntitySearch = (data) => {
    return axios.post(`${ENTITYSEARCHURL}`, data, { headers: authHeader() });
}

export default {
postEntitySearch
}