import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../../Shared/Card/Issues';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import reinstatementService from '../../../../../../services/Public/Reinstatement/PE/peReinstatement.service';
import violationService from '../../../../../../services/Public/Reinstatement/PE/violation.service';
import Swal from 'sweetalert2';
import Information from '../../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import { getReinstatementCodeViolationAnswers } from '../../../../../../services/Shared/codeTable.service';
import IndividualViolations from '../../../../../Shared/Card/Violations';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 3;
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        signature: StringType().isRequired('This field is required.'),
        yesAnswerExplanation: StringType().isRequired('This field is required.'),
    });
    useEffect(() => {
        setHasLoaded(false);
        reinstatementService.peGetSection(store.currentApplicationId, sectionId).then((response) => {
            violationService.getViolation(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, issues: formData.issues, files: formData.files, filesForDisplay: formData.filesForDisplay, signature: response.data.electronicSignature });
                }
                else {
                    getReinstatementCodeViolationAnswers(6).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        signature: undefined,
        yesAnswerExplanation: "",
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true && x.codeViolationQuestion.versionNumber < 3) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);

    //Verison 3+ violations
    const showBlukViolationExplanation = formData?.answers?.findIndex(x => x.answer == true && !x.codeViolationQuestion.hasExplanation && x.codeViolationQuestion.versionNumber >= 3) >= 0 ?
        'If the answer to question number 2-6 is "Yes", provide the date and the pertinent facts of the case or proceeding including its final disposition. Upload additional pages if necessary' : "";


    const handleSubmit = (val, event) => {
        setShowFormErrors(true)
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }
                if (validationObject[key].hasError && (showBlukViolationExplanation && key.includes("yesAnswerExplanation"))) {
                    return;
                }

            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            if (formData?.answers?.findIndex(x => x.answer === true && x.codeViolationQuestion.hasExplanation && !x.violationExplanation) >= 0) {//undefined check
                return;
            }

            if (formData.signature) {
                if (showAdditionalFields === false || val) {
                    var dataTransfer = { ...formData, reinstatementId: store.currentApplicationId, entityId: store.userInfo.EntityId };
                    violationService.postViolation(dataTransfer).then((response) => {
                        reinstatementService.pePostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                            if (response.data) {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                            }
                        })
                    });
                }
            }
        }
    }
    const formSave = () => {
        var dataTransfer = { ...formData, reinstatementId: store.currentApplicationId, entityId: store.userInfo.EntityId };
        violationService.postViolation(dataTransfer).then((response) => {
            updateFormData({ ...response.data, signature: formData.signature });
            reinstatementService.pePostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                Swal.fire("Saved!")
            })
        });
    }

    return (
        <div className="peReinstatementViolationsPage">
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <strong>Professional Engineer Reinstatement</strong>
                    <h4 className="mb-3">Violations</h4>
                    <Row className="mb-3">
                        <Col><Information content={GetReinstatementInformationContent("violations")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <IndividualViolations
                                        showFormErrors={showFormErrors}
                                        showAdditionalFields={showAdditionalFields}
                                        showBlukViolationExplanation={showBlukViolationExplanation}
                                        isReadOnly={!sectionData.isUserEditable}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        parentTypeId={2}
                                        credentialTypeId={6}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={true} type={2} sectionId={sectionData.id} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} handleSave={formSave} backToSectionLink="/individual/peReinstatement/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    )
}
export default Violations;