import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ControlLabel, Form, FormControl, FormGroup, Schema } from 'rsuite';
import coopExperienceService from '../../../../../services/Public/Application/PE/coopExperience.service';
import Information from '../../../../Shared/Card/Information';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import RedStar from '../../../../Shared/GenericComponents/RedStar';
import publicApplicationService from '../../../../../services/Public/Application/PE/peApplication.service';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
import { useSelector } from 'react-redux';
import peApplicationService from '../../../../../services/Internal/Application/PE/peApplication.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function CoopExperiencePage() {
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const [model, setModel] = useState({
        hasCoopExperience: '',
        months: '',
        years: '',
        entityId: entityId,
        applicationId: appId
    });
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const sectionId = 16;
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Quizzes"
        }
    );

    useEffect(() => {
        setHasLoaded(false);
        publicApplicationService.peGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete)
            coopExperienceService.getCoopExperience(appId).then((data) => {
                if (data.data)
                    setModel({
                        ...data.data,
                        years: !data.data.years ? '' : data.data.years,
                        months: !data.data.months ? '' : data.data.months
                    });
            })
            applicationService.getIndividualApplication(appId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])

    const { BooleanType } = Schema.Types;
    const validationModel = Schema.Model({
        hasCoopExperience: BooleanType().isRequired('This field is required.')

    });

    const formSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (val) {
            var dataToTransmit = {
                ...model,
                years: model.years == "" ? 0 : model.years,
                months: model.months == "" ? 0 : model.months,
            }
            coopExperienceService.postCoopExperience(dataToTransmit).then((response) => {
                if (response.data)
                    peApplicationService.peMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    })
            })
        }

    }

    return (
        <Container fluid>
            <FormEntityInformation {...entityInfo} />
            <Information content={GetAppInformationContent("coopExperience")} />
            <br />
            {hasLoaded ?
                <Form readOnly={!isUserEditable} formValue={model} onChange={setModel} onSubmit={formSubmit} model={validationModel}>
                    <Card className="mb-3">
                        <Card.Header>
                            <h6>Co-op Experience</h6>
                        </Card.Header>
                        <Card.Body>
                            <RedStar /> I have Co-op Experience listed on my transcript
                            <YesNoRadioOption radioName="hasCoopExperience" />
                            {model.hasCoopExperience === false &&
                                <>
                                    <hr />
                                    <Row>
                                        <Col sm={3}>
                                            <b>TOTAL OF CO-OP EXPERIENCE:</b>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <FormControl className="mr-3"
                                                    name="months"
                                                    placeholder="Month(s)"
                                                    type="number"
                                                />
                                                <FormControl
                                                    name="years"
                                                    placeholder="Year(s)"
                                                    type="number"
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Card.Body>
                    </Card>
                    <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                    <FormActionButtons
                        isUserEditable={isUserEditable}
                        showSectionComplete={true}
                        isComplete={isComplete}
                        isCompleteCallback={setIsComplete} backToSectionLink="/internal/peapp/sections" />
                </Form> : <LoadingSpinner />}
        </Container>
    );
}
export default CoopExperiencePage;