import axios from "axios";
import authHeader from "../authheader";

const RECENTUPDATEURL = process.env.REACT_APP_APIURL + "/api/RecentUpdate";

const getRecentUpdate = () => {
    return axios.get(`${RECENTUPDATEURL}`, { headers: authHeader() });
}

export default {
    getRecentUpdate
}