import React from 'react';
import { Nav, Navbar, Icon, Dropdown } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import Notification from './Notifications';
import ShoppingCartButton from './ShoppingCart/ShoppingCartButton';
import AuthService from "../../../../services/auth.service"
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function TopNavBar() {
    const { push } = useHistory();
    const logout = () => {
        AuthService.logout();
        window.location.href = "/";
    }
    const askLola = () => {
        push("/individual/askLola")
    }
    const userProfile = () => {
        push("/individual/userProfile")
    }
    const invoiceHistory = () => {
        push("/individual/invoicehistory")
    }
    const name = useSelector(x => x.userInfo.FormattedName);
    return (
        <div className='topnav'>
            <Navbar className='content'>
                <Navbar.Body>
                    <Nav pullRight>
                        <Notification />
                        <ShoppingCartButton />
                        <Dropdown placement="bottomEnd" title={name}>
                            <Dropdown.Item onSelect={() => { userProfile() }} icon={<Icon icon="user" />}>User Profile</Dropdown.Item>
                            <Dropdown.Item onSelect={() => { invoiceHistory() }} icon={<Icon icon="file-text" />}>Invoice History</Dropdown.Item>
                            {/*<Dropdown.Item onSelect={() => { askLola() }} icon={<Icon icon="comments" />}>Ask LOLA</Dropdown.Item>*/}
                            <Dropdown.Item icon={<Icon icon="power-off" />} onSelect={logout}>Logout</Dropdown.Item>
                        </Dropdown>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        </div>
    );
}

export default TopNavBar;