import axios from "axios";
import authHeader from '../../../authheader'

const PLSRENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsRenewal";

const getContactInformation = (renewalId) => {
    return axios.get(`${PLSRENEWALURL}/contactinformation`, { headers: authHeader(), params: { renewalId } });
}
export default {
    getContactInformation
}