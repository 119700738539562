import axios from "axios";
import authHeader from "../authheader";

const INVOICEURL = process.env.REACT_APP_APIURL + "/api/Invoice";

const getInvoiceHistory = () => {
    return axios.get(`${INVOICEURL}/invoicehistory`, { headers: authHeader() });
}
const getEntityInfo = (entityId) => {
    return axios.get(`${INVOICEURL}/getentityinfoforinvoice`, { headers: authHeader(), params: { entityId} });
}
const getEntityLicenseInfo = (entityId, invoiceId) => {
    return axios.get(`${INVOICEURL}/getentitylicenseinfoforinvoice`, { headers: authHeader(), params: { entityId, invoiceId} });
}
const getInvoice = async (id) => {
    return axios.get(`${INVOICEURL}`, { headers: authHeader(), params: { id } });
}
const createInvoice = async (entityId) => {
    return axios.post(`${INVOICEURL}/createinvoice`, null, { headers: authHeader(), params: { entityId } });
}
const addLineItem = async (data) => {
    return axios.post(`${INVOICEURL}/addlineitem`, data, { headers: authHeader() });
}
const editLineItem = async (data) => {
    return axios.post(`${INVOICEURL}/editlineitem`, data, { headers: authHeader() });
}
const deleteLineItem = async (lineItemId) => {
    return axios.post(`${INVOICEURL}/deletelineitem`, null, { headers: authHeader(), params: { lineItemId } });
}
const deleteInvoice = async (invoiceId) => {
    return axios.post(`${INVOICEURL}/deleteinvoice`, null, { headers: authHeader(), params: { invoiceId } });
}
export default {
    getInvoiceHistory,
    getInvoice,
    getEntityInfo,
    getEntityLicenseInfo,
    createInvoice,
    addLineItem,
    deleteLineItem,
    deleteInvoice,
    editLineItem
}