import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ControlLabel, Form, FormControl, FormGroup, Icon, InputPicker, Schema } from 'rsuite';
import { getContacts } from '../../../../services/Shared/codeTable.service';
import invoiceService from '../../../../services/Shared/invoice.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import InvoicePage from './InvoicePage';

function CreateInvoicePage() {
    const [contacts, setContacts] = useState();
    const { push } = useHistory();
    const [newInvoice, setNewInvoice] = useState({
        contact: null
    })
    const [contactLoading, setContactLoading] = useState(false);
    const updateContacts = (value) => {
        if (value.length > 2) {
            getContacts(value).then((response) => {
                setContactLoading(true);
                setContacts(response);
                setContactLoading(false);
            });
        }
        else {
            setContacts([]);
        }
    }

    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        contact: ObjectType().isRequired("This field is required.")
    });

    const createInvoice = (validation, event) => {
        if (validation) {
            invoiceService.createInvoice(newInvoice.contact.entityId).then((response) => {
                if (response.data) {
                    push("/internal/revenue/invoice?invoiceId=" + response.data.id);
                }
            });
        }
    }

    return (
        <Card>
            <Card.Header>
                <h5>Create Invoice</h5>
            </Card.Header>
            <Card.Body>
                <Form fluid
                    model={model}
                    formValue={newInvoice}
                    onChange={newInvoice => setNewInvoice(newInvoice)}
                    onSubmit={createInvoice}
                >
                    <FormGroup>
                        <ControlLabel><b>Contact</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block="true"
                            data={contacts}
                            name="contact"
                            labelKey="name"
                            placeholder="Find a Contact (Min 3 characters)"
                            onSearch={updateContacts}
                            renderMenu={menu => {
                                if (contactLoading) {
                                    return (
                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                            <Icon icon="spinner" spin /> Loading...
                                        </p>
                                    );
                                }
                                return menu;
                            }}
                            renderMenuItem={(label, item) => {
                                return (
                                    <>
                                        {`${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber}`}
                                    </>
                                );
                            }}
                            renderValue={(value, item, selectedElement) => {
                                return (
                                    <>
                                        {item ? `${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber}` : 'Find a Contact (Min 3 characters)'}
                                    </>
                                );
                            }}
                        />
                    </FormGroup>
                    <Row>
                        <Col style={{ textAlign: 'right' }}>
                            <Button type="submit">Create</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}
export default CreateInvoicePage;