import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import seal from '../../images/LAPELS-LOLA-Logo.png'
import AuthService from "../../services/auth.service"
import { login } from "../../fakeapi.js"
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { ControlLabel, Form, FormControl, FormGroup, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import * as IoIcons from 'react-icons/io'

function LoginPage(props) {
    const [state, setState] = useState({
        username: "",
        password: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [userOrPassIncorrect, setUserOrPassIncorrect] = useState(false);
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        username: StringType().isRequired("This field is required"),
        password: StringType().isRequired("This field is required")
    })
    const doesAccountExist = (password, userName) => {
        return true;
    }
    const handleSubmitClick = (validation, event) => {
        if (validation) {
            setIsLoading(true);
            var isAccountValid = doesAccountExist(state.password, state.username)
            if (isAccountValid) {
                sendDetailsToServer();
            } else {
                setUserOrPassIncorrect(true)
            }
        }
        //const form = e.currentTarget;
        //e.preventDefault();
        //if (form.checkValidity() === false) {

        //    e.stopPropagation();
        //}
        //sendDetailsToServer();
    };
    const resendVerificationEmail = () => {
        Swal.close();
        Swal.fire({
            title: 'Resend Verification Email',
            text: "User Name:",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Send',
            showLoaderOnConfirm: true,
            preConfirm: (userName) => {
                if (userName) {
                    return userName;
                }
                Swal.showValidationMessage(
                    `Username is required.`
                )
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                AuthService.resendEmailVerification(result.value).then(
                    (response) => {
                        if (response.data.isSuccess === true) {
                            Swal.fire("Success", response.data.message, "success");
                        } else {
                            Swal.fire("Error", response.data.message, "error");
                        }
                    }, (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        Swal.fire({
                            icon: 'error',
                            title: "Error",
                            html: resMessage
                        })
                    }
                )
            }
        })
    };
    const dispatch = useDispatch();
    const sendDetailsToServer = () => {
        //login(state.username, state.password).then(
        Swal.close();
        AuthService.login(state.username, state.password).then(
            //based on the claim send to appropriate route
            (data) => {
                if (data.isSuccess === true) {
                    props.handleLogin(true);
                    dispatch({ type: "LOGIN_SUCCEEDED", payload: jwt_decode(data.message) })
                } else {
                    //TODO: we need to handle false on this page
                    props.handleLogin(false);
                }
            }, (error) => {
                setIsLoading(false);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                const toast = Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 15000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                toast.fire({
                    icon: 'error',
                    title: resMessage
                })
            });
    };

    useEffect(() => {
        //check if user is logged in

        var user = AuthService.getCurrentUser();
        if (user !== null) {
            props.handleLogin(true)
        }
    }, []);


    return (
        <div className="loginPage">
            <BlockUi blocking={isLoading}>
                <Card className='loginMainCard'>
                    <Card.Img variant="top" src={seal} alt="Lapels Seal" className='loginImage' />
                    <Card.Body>
                        <Card.Title className='centerText' >Access your <b><span style={{ color: '#2976A3' }}>LAPELS</span></b> account?</Card.Title>
                        <Form fluid onSubmit={handleSubmitClick} formValue={state} onChange={(value) => { setState(value) }} model={model} >
                            <FormGroup>

                                <FormControl block="true"
                                    name="username"
                                    placeholder="User ID"
                                />
                            </FormGroup>
                            <FormGroup>

                                <FormControl
                                    checkAsync
                                    block="true"
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                />

                            </FormGroup>
                            {
                                userOrPassIncorrect &&
                                <>
                                    <ControlLabel>
                                        <b className="colorRed">UserID or Password is incorrect. Please try again.</b>
                                    </ControlLabel>
                                    <br />
                                </>
                            }

                            <Button
                                variant="primary"
                                type="submit"
                                className="loginButton">
                                Login
                            </Button>
                            <div className="cardText textDanger">
                                <div className="alert alertLight centerText" role="alert">
                                    <div className="row">

                                        <div className="col alignSelfCenter">
                                            <Card.Link as={Link} to="/forgotpassword">Reset Your Password</Card.Link><br />
                                        </div>
                                        <div className="col alignSelfCenter">
                                            <Card.Link as={Link} to="/forgotuserid">Forgot User Id?</Card.Link><br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        Please update your password if you are having issues accessing your account.
                                    </div>
                                </div>
                            </div>
                            <hr className="my-0" />

                        </Form>
                        <div className="centerText loginFooter">
                            <IoIcons.IoIosMail className="h3 mr-2 mt-1 text-dark" />Email not verified? <span className="fakeAnchor" onClick={resendVerificationEmail}>Resend Email</span>
                        </div>
                        <hr className="my-0" />
                        <div className="loginFooter centerText">
                            <p><b>Still can't log in?</b> Please email <a href="mailto:AccountRecovery@lapels.com">AccountRecovery@lapels.com</a></p>
                            <hr />
                            <p>Don't have an account? <Card.Link as={Link} to="/register">Register</Card.Link></p>
                        </div>
                    </Card.Body>
                </Card>
            </BlockUi>
        </div>
    )
}

export default LoginPage;