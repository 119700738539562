import axios from "axios";
import authHeader from '../authheader'

const SHOPPINGCARTURL = process.env.REACT_APP_APIURL + "/api/ShoppingCart";
const ONLINEPAYMENTURL = process.env.REACT_APP_APIURL + "/api/OnlinePayment";

const getShoppingCart = async () => {
    return axios.get(`${SHOPPINGCARTURL}/summary`, { headers: authHeader() });
}

const getShoppingCartData = async () => {
    return axios.get(`${SHOPPINGCARTURL}`, { headers: authHeader() });
}

const addToShoppingCart = (parentId, feeDefinitionId) => {
    return axios.post(`${SHOPPINGCARTURL}/addtocart`, null, { headers: authHeader(), params: { parentId, feeDefinitionId } });
}

const deleteItemFromShoppingCart = (shoppingCartItemId, feeDefinitionId, parentId) => {
    return axios.post(`${SHOPPINGCARTURL}/deleteitem`, null, { headers: authHeader(), params: { shoppingCartItemId, feeDefinitionId, parentId } });
}
const postMailPayment = () => {
    return axios.post(`${SHOPPINGCARTURL}/preparemailpayment`, null, { headers: authHeader() });
}
const postOnlinePayment = () => {
    return axios.post(`${ONLINEPAYMENTURL}/postonlinepaymentredirecturl`, null, { headers: authHeader() });
}
const postOnlinePaymentToken = (token) => {
    return axios.post(`${ONLINEPAYMENTURL}/postprocessonlinepayment`, null, { headers: authHeader(), params: { token} });
}
const postAutoRenewalPdfs = (autoApprovedRenewalIds) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachRenewalPdfs`, autoApprovedRenewalIds , { headers: authHeader()});
}
const postFailOnlinePayment = (token, r) => {
    return axios.post(`${ONLINEPAYMENTURL}/postfailonlinepayment`, null, { headers: authHeader(), params: { token, r } });
}

export default {
    getShoppingCart,
    addToShoppingCart,
    getShoppingCartData,
    deleteItemFromShoppingCart,
    postMailPayment,
    postOnlinePayment,
    postOnlinePaymentToken,
    postFailOnlinePayment,
    postAutoRenewalPdfs
}