import * as dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { InputPicker, SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup, InputNumber, Checkbox, CheckboxGroup } from 'rsuite';
import generalEntityInfoService from '../../../../../services/Internal/generalEntityInfo.service';
import { getIssueTypes} from '../../../../../services/Shared/codeTable.service'
import RedStar from '../../../../Shared/GenericComponents/RedStar';


function WarningModal(props) {
    const [issueTypes, setIssueTypes] = useState([]);

    const [warningModel, setWarningModel] = useState({
        id: 0,
        entityId: 0,
        reason: "",
        issueType: null,
        clearedDate: '',
        effectiveDate: '',
        userCrte: '',
        dateCrte: '',
        clearedBy: '',
        issue: '',
        suppressLicensePrint: null,

        modalType: "Add"
    })

    useEffect(() => {
        setWarningModel({
            ...props.modalData
        });
        getIssueTypes().then(setIssueTypes);
    }, [props.modalData])

    const { StringType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        issueType: ObjectType().isRequired('This field is required.'),
        reason: StringType().isRequired('This field is required.'),
        effectiveDate: StringType().isRequired('This field is required.')
    })
    const formSubmit = (validation, event) => {
        if (validation) {
            submitData();
        }
    }
    const submitData = () => {
        var effectiveDate = warningModel.effectiveDate ? dayjs(warningModel.effectiveDate).format('YYYY-MM-DD') : null;
        var dateCrte = warningModel.dateCrte ? dayjs(warningModel.dateCrte).format('YYYY-MM-DD') : null;
        var clearedDate = warningModel.clearedDate ? dayjs(warningModel.clearedDate).format('YYYY-MM-DD') : null;
        var dataToTransmit = {
            ...warningModel,
            id: warningModel.id === '' ? 0 : warningModel.id,
            issue: warningModel.reason,
            effectiveDate,
            dateCrte,
            clearedDate
        };
        var isCreate = warningModel.modalType == "Add";
        delete dataToTransmit.modalType;
        delete dataToTransmit.reason;
        if (isCreate) {
            generalEntityInfoService.createWarning(dataToTransmit).then((response) => {
                if (response.data.id) {
                    props.updateGrid();
                    props.onHide();
                }
            }).catch((response) => {
            })
        }
        else //Edit
        {
            generalEntityInfoService.updateWarning(dataToTransmit).then((response) => {
                if (response.data.id) {
                    props.updateGrid();
                    props.onHide();
                }
            }).catch((response) => {
            })
        }

    }

    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Warning
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="warningForm" fluid model={model} formValue={warningModel} onChange={warningModel => setWarningModel(warningModel)} onSubmit={formSubmit} >
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>Effective Date&nbsp;<RedStar /></strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="date"
                                    name="effectiveDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>Cleared Date</strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="date"
                                    name="clearedDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Type&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block
                                    name="issueType"
                                    placeholder="-- Select --"
                                    accepter={InputPicker}
                                    data={issueTypes} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Reason&nbsp;<RedStar/>
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="reason" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Suppress License Print
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={1}>
                                <FormGroup>
                                    <FormControl
                                        name="suppressLicensePrint"
                                        accepter={Checkbox}
                                        checked={warningModel.suppressLicensePrint}
                                        value={true}
                                        onChange={(value, checked) => setWarningModel({ ...warningModel, suppressLicensePrint: checked })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="warningForm" variant="success">Save</Button>
                <Button onClick={props.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default WarningModal;