import React, { useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { Form } from 'rsuite';
import Information from '../../../../../Shared/Card/Information';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/LsiInformation';

import Recommendation from './Recommendation';

import recommendationService from '../../../../../../services/Public/Application/LSI/recommendation.service'
import applicationService from '../../../../../../services/Public/Application/LSI/lsiApplication.service';
import Swal from 'sweetalert2';
import AddEditRecommendationModal from './AddEditRecommendationModal';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Issues from '../../../../../Shared/Card/Issues';

function RecommendationPage() {
    const { push } = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(true);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const applicationId = useSelector(x => x.currentApplicationId);
    const sectionId = 12; // Revisit
    const routes = useSelector(x => x.currentRoutes);
    const [modalData, setModalData] = useState({
        applicationId: 0,
        dateCrte: null,
        deleted: false,
        email: "",
        entityId: 0,
        id: 0,
        firstName: "",
        lastName: "",
        sentDate: null,
        responseDate: null,
        userCrte: "",
        verificationStatus: null,
        modalType: "Add"
    });
    const [gridData, setGridData] = useState([]);
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        setHasLoaded(false)
        applicationService.lsiGetSection(applicationId, sectionId).then((response) => {
            setSectionData(response.data);
            updateGridData();
            setHasLoaded(true);
        })
    }, [])


    const updateGridData = () => {
        recommendationService.getRecommendations(applicationId).then((response) => {
            setGridData(response.data);
        });
    }
    const openAddEditModal = (values) => {
        if (values.id) {
            var data = {
                ...values,
                modalType: "Edit"
            }
            setModalData(data);
        }
        else {
            setModalData({
                applicationId: applicationId,
                dateCrte: null,
                deleted: false,
                email: "",
                entityId: entityId,
                id: 0,
                firstName: "",
                lastName: "",
                sentDate: null,
                responseDate: null,
                userCrte: "",
                verificationStatus: null,
                modalType: "Add"
            })
        }
        setModalShow(true);
    }

    const formSubmit = (validation, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
        }
    }

    const deleteRow = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                recommendationService.postRecommendation({ ...rowData, deleted: true }).then((response) => {
                    updateGridData();
                })
            }
        })
    }
    const emailReference = (rowData) => {
        recommendationService.sendRecommendationEmail(rowData.id).then((response) => {
            if (response.data) {
                Swal.fire(`You have successfully sent an email to ${rowData.firstName} ${rowData.lastName} at ${rowData.email}!`);
                updateGridData();
            }
            else
                Swal.fire(`Failed to send email: ${response.data.message}`);
        })
    }

    return (
        <div>
            <Container fluid>
                <b>Land Surveyor Intern Application</b>
                <h4 className="mb-3">Recommendations</h4>
                <Information content={GetAppInformationContent("recommendation")} />
                <br />
                {hasLoaded ? <>
                    <AddEditRecommendationModal
                        updategrid={updateGridData}
                        modaldata={modalData}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onsave={recommendationService.postRecommendation}
                    />
                    <Form
                        readOnly={!sectionData.isUserEditable}
                        fluid
                        formValue={sectionData}
                        onChange={data => { setSectionData(data) }}
                        onSubmit={formSubmit}  >
                        <Recommendation
                            updateGrid={updateGridData}
                            isReadOnly={!sectionData.isUserEditable}
                            openModal={openAddEditModal}
                            griddata={gridData}
                            deleteRow={deleteRow}
                            emailReference={emailReference}
                        />
                        <br />
                        <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                        <FormActionButtons
                            backToSectionLink="/individual/lsiApp/sections"
                            isReadOnly={!sectionData.isUserEditable} />
                    </Form>
                </> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}

export default RecommendationPage