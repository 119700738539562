import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import JournalPrivacyCard from './JournalPrivacyCard';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import privacyService from '../../../../../services/Internal/Renewal/EI/privacy.service';
import internalRenewalService from '../../../../../services/Internal/Renewal/EI/eiRenewal.service';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/EiInformation';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import FormEntityInformation from '../../../Shared/FormEntityInformation';
import renewalService from '../../../../../services/Internal/Renewal/renewal.service';


function Privacy() {
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const renewalId = useSelector(x => x.currentApplicationId);
    const { push } = useHistory();
    const sectionId = 6;
    const entityId = useSelector(x => x.userInfo.EntityId)
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Journal & Privacy Option"
        }
    );

    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        emailPhonePrivacy: BooleanType().isRequired('This field is required.'),
        receiveMethod: StringType().isRequired('This field is required.')
    });

    const [formData, updateFormData] = useState({
        id: 0,
        entityId: entityId,
        emailPhonePrivacy: null,
        journalEmail: null,
        journalMail: null,
        journalDoNotSend: null,
        userCrte: "",
        dateCrte: new Date(),
        deleted: false,
        receiveMethod: ""
    });

    useEffect(() => {
        internalRenewalService.eiGetSection(renewalId, sectionId).then((data) => {
            setSectionData(data.data)
            privacyService.getPrivacyData(data.data.entityId).then((response) => {
                if (response.data) {
                    updateFormData({
                        ...response.data,
                        receiveMethod: response.data.journalEmail
                            ? "email"
                            : response.data.journalMail
                                ? "mail"
                                : response.data.journalDoNotSend
                                    ? "none"
                                    : null
                    })
                }
            })
            setIsComplete(data.data.staffReviewComplete);
            renewalService.getIndividualRenewal(renewalId).then((renewalData) => {
                setIsUserEditable(renewalData.data.status.id == 2)
                entityService.getEntity(data.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: renewalData.data?.credential?.formattedLicenseNumber,
                            applicationDate: renewalData.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])

    const handleSubmit = (val, event) => {
        if (val) {
            submitData(true);
        }
    }
    const submitData = (isForSubmit) => {
        var dataToTransmit = {
            ...formData,
            journalEmail: formData.receiveMethod === "email" ? true : null,
            journalMail: formData.receiveMethod === "mail" ? true : null,
            journalDoNotSend: formData.receiveMethod === "none" ? true : null,
        }

        privacyService.postPrivacyData(dataToTransmit).then((response) => {
            if (response.data.id) {
                if (isForSubmit)
                    internalRenewalService.eiMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                    })
            }
        })
    }
    return (
        <div className="eiRenewalPrivacy">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetRenewalInformationContent("privacy")} />
                <br />
                {hasLoaded &&
                    <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                        <JournalPrivacyCard isUserEditable={isUserEditable} />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            backToSectionLink="/internal/eirenewal/sections" />
                    </Form>
                }
            </Container>
        </div>
    )
}
export default Privacy;