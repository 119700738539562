import axios from "axios";
import authHeader from '../../../authheader'

const PEREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPeReinstatement";

const getBuildingDesign = (reinstatementId) => {
    return axios.get(`${PEREINSTATEMENTURL}/buildingdesign`, { headers: authHeader(), params: { reinstatementId } });
}
const postBuildingDesign = (data) => {
    return axios.post(`${PEREINSTATEMENTURL}/buildingdesign`, data, { headers: authHeader() });
}
export default {
    getBuildingDesign,
    postBuildingDesign
}