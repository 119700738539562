import React, { useState } from 'react'
import { useEffect } from 'react';
import { Table } from 'rsuite'
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import recentContactService from "../../../services/Internal/Recent/recentContacts.service"
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { sort_by } from '../../Shared/HelperFunctions';

function AllRecentContacts() {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState("dateLastAccessed");
    const [sortType, setSortType] = useState("desc");
    const { push } = useHistory();

    useEffect(() => {
        recentContactService.getAllRecentContacts().then((response) => {
            if (response.data) {
                setGridData(response.data);
            }
            setHasLoaded(true);
        })

    }, [])
    const getData = () => {
        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const filteredData = getData();

    return (
        <>
            <Card>
                <Card.Header>
                    <h4>All Viewed Contacts</h4>
                </Card.Header>
                <Card.Body>
                    {hasLoaded ? <>
                        <Table
                            onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            wordWrap autoHeight data={filteredData} >
                            <Table.Column width={200} fixed>
                                <Table.HeaderCell />
                                <Table.Cell>
                                    {rowData => {
                                        return (
                                            <a className="btn btn-primary" href={(rowData.isFirm ? "internal/firmCredential?entityId=" + rowData.entityId : "internal/indCredential?entityId=" + rowData.entityId)}>View</a>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Name</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="entityName" />
                            </Table.Column>
                            <Table.Column minWidth={125} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Date Last Accessed</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="dateLastAccessed">{rowData => (new Date(rowData.dateLastAccessed).toLocaleString())}</Table.Cell>
                            </Table.Column>
                        </Table >
                        <GenericPagination dataLength={gridData.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                    </> : <LoadingSpinner />}
                </Card.Body>
            </Card>
        </>
    )
}
export default AllRecentContacts;