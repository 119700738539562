import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import { useHistory } from 'react-router-dom';
import DisclosureAuthorization from '../../../../Shared/Card/DisclosureAuthorization';
import Information from '../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import { useSelector } from 'react-redux';
import reinstatementService from '../../../../../services/Internal/Reinstatement/PE/peReinstatement.service';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';
import dayjs from 'dayjs';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function DisclosurePage() {
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const [hasLoaded, setHasLoaded] = useState(false);
    const sectionId = 8;
    const routes = useSelector(x => x.currentRoutes);
    const [formModel, setFormModel] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Disclosure"
        }
    );
    useEffect(() => {
        reinstatementService.peGetSection(reinstatementId, sectionId).then((response) => {
            reinstatementService.getIndividualReinstatement(reinstatementId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                });
                setFormModel({
                    ...response.data,
                    dateOfBirth: response.data.dateOfBirth != null ? dayjs(response.data.dateOfBirth).format('YYYY-MM-DD') : null,
                    dateSigned: response.data.dateSigned != null ? dayjs(response.data.dateSigned).format('YYYY-MM-DD') : null
                });
                setHasLoaded(true);
                setIsComplete(response.data.staffReviewComplete);
            });
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }
    const formSave = (event) => {
        submitData();
    }
    const submitData = (isForSubmit) => {
        reinstatementService.pePostSection(reinstatementId, sectionId, formModel.electronicSignature).then((response) => {
            reinstatementService.peMarkSectionComplete(reinstatementId, sectionId, isComplete).then((data) => {
                if (isForSubmit && response.data.id) {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                }
                else if (response.data.id) {
                    Swal.fire("Saved!");
                }
            });
        })
    }

    return (
        <div className="peReinstatementDisclosureInfo">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetReinstatementInformationContent("disclosure")} />
                <br />
                {hasLoaded ?
                    <Form readOnly={!isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <DisclosureAuthorization />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={formModel.id} setIsComplete={setIsComplete}/>
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true}
                            backToSectionLink="/internal/pereinstatement/sections"
                            handleSave={formSave} />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div >
    )
}

export default DisclosurePage;