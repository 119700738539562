import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, InputPicker, Schema } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import { getStates } from '../../../../../services/Shared/codeTable.service'
import Information from '../../../../Shared/Card/Information';
import publicApplicationService from '../../../../../services/Internal/Application/LSI/lsiApplication.service';
import examinationService from '../../../../../services/Internal/Application/LSI/examination.service'
import { GetAppInformationContent } from '../../../../Shared/InformationContent/LsiInformation';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import lsiApplicationService from '../../../../../services/Internal/Application/LSI/lsiApplication.service';
import Issues from '../../../../Shared/Card/Issues';
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ExaminationVerification() {
    const [states, setStates] = useState([])
    const routes = useSelector(x => x.currentRoutes);
    const appId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const sectionId = 11;
    const [formModel, setFormModel] = useState({
        id: 0,
        applicationId: appId,
        entityId: entityId,
        jurisdictionState: null,
        dateOfExam: null,
        userCrte: "",
        dateCrte: new Date(),
        deleted: false
    });
    const [sectionData, setSectionData] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Land Surveyor Intern Application",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Examination"
        }
    );
    const { StringType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        jurisdictionState: ObjectType().isRequired('This field is required.'),
        dateOfExam: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        getStates().then(setStates);
        examinationService.getExamination(appId).then((response) => {
            if (response.data) {
                setFormModel({
                    ...response.data,
                    dateOfExam: response.data.dateOfExam != null ? dayjs(response.data.dateOfExam).format('YYYY-MM-DD') : null
                });
            }
            publicApplicationService.lsiGetSection(appId, sectionId).then((data) => {
                setSectionData(data.data);
                setIsComplete(data.data.staffReviewComplete);
                applicationService.getIndividualApplication(appId).then((appData) => {
                    setIsUserEditable(appData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                                applicationDate: appData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })
    }, [])
    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            examinationService.postExamination(formModel).then((response) => {
                if (response.data) {
                    lsiApplicationService.lsiMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    })
                }
            })
        }
    }
    return (
        <Container fluid>
            <FormEntityInformation {...entityInfo} />
            <Information content={GetAppInformationContent("exam")} />
            <br />
            {hasLoaded ?
                <Form readOnly={!isUserEditable} fluid model={model} formValue={formModel} onChange={setFormModel} onSubmit={formSubmit}>
                    <Card className="mb-3">
                        <Card.Header>
                            Fundamentals Exam (FS)
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col sm={3}>
                                    <ControlLabel>Jurisdiction</ControlLabel>
                                </Col>
                                <Col>
                                    <FormControl
                                        accepter={InputPicker}
                                        placeholder="Please Select"
                                        block
                                        data={states}
                                        name="jurisdictionState"
                                        searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>Date</ControlLabel>
                                </Col>
                                <Col>
                                    <FormControl type="date" style={{ width: '100%' }} block="true" name="dateOfExam"></FormControl>
                                </Col>
                            </Row>
                        </Card.Body>

                    </Card>
                    <br />
                    <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                    <FormActionButtons
                        isUserEditable={isUserEditable}
                        isComplete={isComplete}
                        isCompleteCallback={setIsComplete}
                        showSectionComplete={true}
                        backToSectionLink="/internal/lsiApp/sections" />
                </Form> : <LoadingSpinner />
            }
        </Container>
    );
}
export default ExaminationVerification