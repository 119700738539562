import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getViolation = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/violation`, { headers: authHeader(), params: { applicationId }  });
}
const postViolation = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}