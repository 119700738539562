import axios from "axios";
import authHeader from '../../../authheader'

const LSIAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualLsiApplication";

const getExamination = (applicationId) => {
    return axios.get(`${LSIAPPLICATIONURL}/examinationverification`, { headers: authHeader(), params: { applicationId } });
}
const postExamination = (data) => {
    return axios.post(`${LSIAPPLICATIONURL}/examinationverification`, data, { headers: authHeader() });
}


export default {
    getExamination,
    postExamination
}