import React, { useEffect, useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Schema } from 'rsuite';
import dayjs from 'dayjs';
import { GetFirmGeneralContactInformationInstructions } from '../../../../Shared/InformationContent/GeneralInformation'
import Information from '../../../../Shared/Card/Information';
import contactService from '../../../../../services/Internal/Reinstatement/EF/contact.service';
import reinstatementService from '../../../../../services/Internal/Reinstatement/EF/efReinstatement.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import MailingAddress from '../../../../Shared/Card/Contact/MailingAddress';
import FirmInformation from '../../../../Shared/Card/Contact/FirmInformation';
import PublicAddress from '../../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import Swal from 'sweetalert2';
import Issues from '../../../../Shared/Card/Issues';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ContactInformationPage() {
    const sectionId = 1;
    const routes = useSelector(x => x.currentRoutes);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineering Firm Reinstatement",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Contact Information"
        }
    );
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);

    useEffect(() => {
        contactService.getContactInformation(reinstatementId).then((response) => {
            var data = response.data;
            setFormModel({
                ...data,
                dateOfFormation: data.dateOfFormation != null ? dayjs(data.dateOfFormation).format('YYYY-MM-DD') : null,
                orgType: data.organizationType?.organizationType,
                primaryEmail: data.primaryEmailAddress?.contactEmailAddress,
                phoneNum: data.phone?.phoneNumber

            })
            reinstatementService.efGetSection(reinstatementId, sectionId).then((response) => {
                setSectionData(response.data);
                setIsComplete(response.data.staffReviewComplete)
                reinstatementService.getFirmReinstatement(reinstatementId).then((data) => {
                    setIsUserEditable(data.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: data.data?.credential?.formattedLicenseNumber,
                                applicationDate: data.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })
    }, [])

    const [formModel, setFormModel] = useState({})

    const [tabPane, setTabPane] = useState("firmInformation")

    const submitData = () => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            reinstatementService.efMarkSectionComplete(reinstatementId, sectionId, isComplete).then((markSectionResponse) => {
                if (markSectionResponse.data)
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                else
                    Swal.fire("Failed to mark section complete");
            });
        }
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetFirmGeneralContactInformationInstructions()} />
                <br />
                <Card className="mb-3">
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="firmInformation" title="Firm Information">
                                    <br />
                                    <br />
                                    <Form id="firmInformationForm"
                                        fluid
                                        formValue={formModel}
                                        onChange={formModel => setFormModel(formModel)}
                                        onSubmit={submitData} >
                                        <FirmInformation isReadOnly={true} isForApp={true} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress isReadOnly={true} boundValues={formModel.mailingAddress} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress isReadOnly={true} boundValues={formModel.publicAddress} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                {hasLoaded &&

                    <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                }
                <FormActionButtons
                    isUserEditable={isUserEditable}
                    showSectionComplete={true}
                    isComplete={isComplete}
                    isCompleteCallback={setIsComplete}
                    submitFormName="firmInformationForm"
                    backToSectionLink="/internal/efreinstatement/sections"

                />
            </Container>
        </div>
    );
}
export default ContactInformationPage