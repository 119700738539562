import axios from "axios";
import authHeader from '../../../authheader'

const PLSAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsApplication";

const getPersonalReferences = (applicationId) => {
    return axios.get(`${PLSAPPLICATIONURL}/personalreferences`, { headers: authHeader(), params: {applicationId} });
}
const postPersonalReference = (data) => {
    return axios.post(`${PLSAPPLICATIONURL}/personalreference`, data, { headers: authHeader() });
}

const sendPersonalReferenceEmail = (referenceId) => {
    return axios.post(`${PLSAPPLICATIONURL}/sendreferenceemail`, null, { headers: authHeader(), params:{referenceId} });
}

export default {
    getPersonalReferences,
    postPersonalReference,
    sendPersonalReferenceEmail
}