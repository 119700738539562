import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, FormGroup, Icon, InputPicker, Schema, Table } from 'rsuite'
import Swal from 'sweetalert2';
import feeTransactionTabService from '../../../../services/Internal/Credential/feeTransactionTab.service';
import { getFeeDefinitions } from '../../../../services/Shared/codeTable.service';
import invoiceService from '../../../../services/Shared/invoice.service';
import CurrencyMask from '../../../Shared/GenericComponents/CurrencyMask';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import RedStar from '../../../Shared/GenericComponents/RedStar';
import { sort_by } from '../../../Shared/HelperFunctions';
import PaymentInfoModal from '../../Shared/PaymentInfoModal';

function FeeTransactionPage({ entityId, activeKey, isIndividual }) {
    const [invoiceData, setInvoiceData] = useState([]);
    const [refundData, setRefundData] = useState([]);
    const [invoiceHasLoaded, setInvoiceHasLoaded] = useState(false);
    const [refundHasLoaded, setRefundHasLoaded] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [currentPaymentId, setCurrentPaymentId] = useState(0);

    const [editModalShow, setEditModalShow] = useState(false);
    const [currentRowData, setCurrentRowData] = useState();



    const refreshInvoicesRefunds = () => {
        feeTransactionTabService.getInvoices(entityId, isIndividual).then((response) => {
            setInvoiceData(response.data.sort(sort_by('invoiceDate', true)));
        })
        feeTransactionTabService.getRefunds(entityId, isIndividual).then((response) => {
            setRefundData(response.data.sort(sort_by('refundDate', true)));
        })
        setInvoiceHasLoaded(true);
        setRefundHasLoaded(true);
    }

    useEffect(() => {
        if (activeKey == "feeTransactions") {
            //get data
            refreshInvoicesRefunds();
        }
    }, [activeKey])

    const deleteInvoiceLineItem = (lineItemId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                feeTransactionTabService.deleteLineItem(lineItemId).then((response) => {
                    if (response.data) {
                        setInvoiceHasLoaded(false);
                        feeTransactionTabService.getInvoices(entityId, isIndividual).then((response) => {
                            setInvoiceData(response.data.sort(sort_by('invoiceDate', true)));
                        })
                        setInvoiceHasLoaded(true);
                    }
                });
            }
        })
    }

    return (<>
        <PaymentInfoModal
            paymentId={currentPaymentId}
            show={modalShow}
            onHide={() => { setModalShow(false); }}
            refreshInvoicesRefunds={refreshInvoicesRefunds} />
        <EditInvoiceTransactionFee
            rowData={currentRowData}
            show={editModalShow}
            onHide={() => { setEditModalShow(false); }}
            refreshInvoicesRefunds={refreshInvoicesRefunds}
        />

        <h4>FEES</h4>
        {invoiceHasLoaded ? <>
            <Table wordWrap autoHeight data={invoiceData}>
                <Table.Column minWidth={75} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Status</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.status)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Invoice Date</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.invoiceDate ? new Date(rowData.invoiceDate).toLocaleDateString() : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={200} flexGrow={1.25}>
                    <Table.HeaderCell>
                        <b>GL Account</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.glAccount)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={125} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Invoice Amount</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.invoiceAmount ? rowData.invoiceAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : rowData.invoiceAmount == 0 ? '$0.00' : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Invoice ID</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.invoiceId ? rowData.invoiceId : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Payment ID</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (
                        rowData.paymentId ?
                            <Button variant="link" onClick={() => {
                                setCurrentPaymentId(rowData.paymentId);
                                setModalShow(true)
                            }}>{rowData.paymentId}</Button> : ''
                    )}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={125} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Payment Amount</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.paymentAmount ? rowData.paymentAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : rowData.paymentAmount == 0 ? '$0.00' : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Payment Type</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.paymentType)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Paid Date</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.paidDate ? new Date(rowData.paidDate).toLocaleDateString() : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={150} flexGrow={.8}>
                    <Table.HeaderCell>
                        <b>User</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.user)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Actions</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (
                        rowData.status == "Unpaid" ?
                            <Button variant="danger" onClick={() => {
                                deleteInvoiceLineItem(rowData.invoiceLineItemId)
                            }}>Delete</Button> :
                            rowData.status == "Paid" ?
                                <Button variant="primary" onClick={() => {
                                    setCurrentRowData(rowData);
                                    setEditModalShow(true);
                                }}>Edit</Button> : ""
                    )}</Table.Cell>
                </Table.Column>
            </Table>
        </> : <LoadingSpinner />
        }
        <br />
        <h4>REFUNDS</h4>
        {refundHasLoaded ? <>
            <Table wordWrap autoHeight data={refundData}>
                <Table.Column minWidth={100} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Payment ID</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (
                        <Button variant="link" onClick={() => {
                            setCurrentPaymentId(rowData.paymentId);
                            setModalShow(true)
                        }}>{rowData.paymentId}</Button>
                    )}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Payment Date</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.paymentDate ? new Date(rowData.paymentDate).toLocaleDateString() : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Payment Type</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.paymentType)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Refund Date</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.refundDate ? new Date(rowData.refundDate).toLocaleDateString() : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={125} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Refund Amount</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.refundAmount ? rowData.refundAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : rowData.refundAmount == 0 ? '$0.00' : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={200} flexGrow={1.5}>
                    <Table.HeaderCell>
                        <b>Reason</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.reason)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={150} flexGrow={.8}>
                    <Table.HeaderCell>
                        <b>User</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.user)}</Table.Cell>
                </Table.Column>
            </Table>
        </> : <LoadingSpinner />
        }
    </>);
}

function EditInvoiceTransactionFee(props) {

    const [lineItemModel, setLineItemModel] = useState();
    const [feeDefinitions, setFeeDefinitions] = useState({
        feeDefinition: {},
        amount: 0
    });

    const { NumberType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        feeDefinition: ObjectType().isRequired('This field is required.'),
        amount: NumberType().isRequired('This field is required.').min(.01, "Please enter an amount greater than 0"),
    })

    useState(() => {
        getFeeDefinitions().then(setFeeDefinitions);
    }, [])

    useEffect(() => {
        if (props.show) {
            console.log(props.rowData)
            var fee = feeDefinitions.find(obj => {
                return obj.value.id == props.rowData.feeDefinitionId
            })
            setLineItemModel({ feeDefinition: fee.value, amount: props.rowData.invoiceAmount })
        }
    }, [props.show])

    const onFeeDefinitionSelect = (value) => {
        setLineItemModel({ feeDefinition: { ...value }, amount: value.amount })
    }

    const formSubmit = (validation, event) => {
        if (validation) {
            if (props.rowData.invoiceAmount == lineItemModel.amount) {
                submitData();
            }
            else {
                var text = props.rowData.invoiceAmount > lineItemModel.amount ? "Saving now will alter the invoice line item, deallocate the current payment, reallocate the payement, and create a refund from the difference in amounts. Do you want to continue?"
                    : props.rowData.invoiceAmount < lineItemModel.amount ? "Saving now will alter the invoice line item and deallocate the current payment. Do you want to continue?" : ""
                Swal.fire({
                    title: 'Are you sure?',
                    text: text,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Continue!'
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            submitData();
                        }
                    })
            }
        }
    }
    const submitData = () => {
        var dataToTransmit = {
            amount: lineItemModel.amount,
            entityId: 0,
            feeDefinition: lineItemModel.feeDefinition,
            invoiceId: props.rowData.invoiceId,
            parentId: 0,
            id: props.rowData.invoiceLineItemId

        }
        invoiceService.editLineItem(dataToTransmit).then((responsse) => {
            if (responsse.data) {
                props.refreshInvoicesRefunds()
                Swal.fire("Saved", `Invoice Line Item has been successfully updated!`, "success")
                props.onHide(true);
            }
        })
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Line Item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form fluid
                    id="editLineItemForm"
                    model={model}
                    formValue={lineItemModel}
                    onChange={values => setLineItemModel(values)}
                    onSubmit={formSubmit}>
                    <FormGroup>
                        <ControlLabel>
                            <strong>
                                GL Account&nbsp;<RedStar />
                            </strong>
                        </ControlLabel>
                        <FormControl
                            block="true"
                            name="feeDefinition"
                            placeholder="-- Select --"
                            accepter={InputPicker}
                            searchable={true}
                            data={feeDefinitions}
                            onChange={onFeeDefinitionSelect}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <strong>
                                Amount&nbsp;<RedStar />
                            </strong>
                        </ControlLabel>
                        <FormControl
                            name="amount"
                            accepter={CurrencyMask}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button form="editLineItemForm" type="submit">Edit</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default FeeTransactionPage;