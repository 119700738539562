import axios from "axios";
import authHeader from '../../authheader.js'

const INDIVIDUALURL = process.env.REACT_APP_APIURL + "/api/Individual";

const getEngineeringAdditionalInfo = async (entityId) => {
    return axios.get(`${INDIVIDUALURL}/getpeexaminformation`, { headers: authHeader(), params: { entityId } });
}
const getSurveyingAdditionalInfo = async (entityId) => {
    return axios.get(`${INDIVIDUALURL}/getplsexaminformation`, { headers: authHeader(), params: { entityId } });
}

const postEngineeringAdditionalInfo = async (data) => {
    return axios.post(`${INDIVIDUALURL}/savepeexaminformation`, data, { headers: authHeader() });
}
const postSurveyingAdditionalInfo = async (data) => {
    return axios.post(`${INDIVIDUALURL}/saveplsexaminformation`, data, { headers: authHeader() });

}
export default {
    getEngineeringAdditionalInfo,
    getSurveyingAdditionalInfo,
    postEngineeringAdditionalInfo,
    postSurveyingAdditionalInfo
}