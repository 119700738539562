import React, { useEffect, useState } from 'react'
import { Card, Tabs, Tab } from 'react-bootstrap'
import AllTasksGrid from './AllTasksGrid';
import MyTasksGrid from './MyTasksGrid';
import UnassignedTasksGrid from './UnassignedTasksGrid';
import ClosedTasksGrid from './ClosedTasksGrid';
import Swal from 'sweetalert2';
import applicationService from '../../../services/Internal/Application/application.service';
import firmApplicationService from '../../../services/Internal/Application/VF/vfApplication.service';
import firmReinstatementService from '../../../services/Internal/Reinstatement/VF/vfReinstatement.service';
import reinstatementService from '../../../services/Internal/Reinstatement/reinstatement.service';
import renewalService from '../../../services/Internal/Renewal/renewal.service';
import firmRenewalService from '../../../services/Internal/Renewal/VF/vfRenewal.service';
import entityService from '../../../services/Internal/Staff/entity.service';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function QueuePage() {
    const [gridType, setGridType] = useState("myTasks");
    const [hasUnassignedPermission, setHasUnassignedPermission] = useState(false);
    const [blockUI, toggleBlockUi] = useState(false);
    const { push } = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        entityService.getUnassignedQueuePermission().then((response) => {
            setHasUnassignedPermission(response.data);
        })
    }, [])
    const viewItem = (rowData) => {
        switch (rowData.prefix) {
            case 'PLS'://PLS by Exam
            case 'PE': // Professional Engineer
            case "EI": // Engineering Intern
            case "LSI": //Land Surveying Intern
                switch (rowData.taskTypeId) {
                    case 1://Application
                        applicationService.getIndividualApplication(rowData.parentId).then((response) => {
                            navigateAndSetCurrentApplication(response.data)
                        })
                        toggleBlockUi(true);
                        break;
                    case 2://Reinstatement
                        reinstatementService.getIndividualReinstatement(rowData.parentId).then((response) => {
                            navigateAndSetCurrentReinstatement(response.data)
                        })
                        toggleBlockUi(true);
                        break;
                    case 3://Renewal
                        renewalService.getIndividualRenewal(rowData.parentId).then((response) => {
                            navigateAndSetCurrentRenewal(response.data)
                        })
                        toggleBlockUi(true);
                        break;
                    default:
                        Swal.fire("Error Occured!")
                }
                break;
            case 'VF': // Surveying Firm
            case 'EF': // Engineering Firm
                switch (rowData.taskTypeId) {
                    case 1://Application
                        firmApplicationService.getFirmApplication(rowData.parentId).then((response) => {
                            navigateAndSetCurrentApplication(response.data)
                        })
                        toggleBlockUi(true);
                        break;
                    case 2://Reinstatement
                        firmReinstatementService.getFirmReinstatement(rowData.parentId).then((response) => {
                            navigateAndSetCurrentReinstatement(response.data)
                        })
                        toggleBlockUi(true);
                        break;
                    case 3://Renewal
                        firmRenewalService.getFirmRenewal(rowData.parentId).then((response) => {
                            navigateAndSetCurrentRenewal(response.data)
                        })
                        toggleBlockUi(true);
                        break;
                    default:
                        Swal.fire("Error Occured!")
                }
                break;
            default:
                Swal.fire("Not implemented!");
        }
    }

    const navigateAndSetCurrentApplication = (rowData) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: rowData.id });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: rowData.entityId });
        dispatch({ type: "PERSIST_CURRENT_APP_TYPE_ID", payload: rowData.applicationType.id });
        var baseLink = "";
        switch (rowData.applicationType?.id) {
            case 1:
                baseLink = "/internal/eiapp/";
                break;
            case 2:
                baseLink = "/internal/lsiapp/";
                break;
            case 3:
            case 4:
                baseLink = "/internal/peapp/";
                break;
            case 5:
            case 6:
                baseLink = "/internal/plsApp/";
                break;
            case 7:
                baseLink = "/internal/efapp/";
                break;
            case 8:
                baseLink = "/internal/vfApp/";
                break;
        }
        if (rowData?.sections?.length) {
            var route = [];
            for (var i = 0; i < rowData.sections.length; i++) {
                let sectionId = rowData.sections[i].sectionType.id
                var link = baseLink;
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 2:
                        link += "education"
                        break;
                    case 3:
                        link += "personalReferences"
                        break;
                    case 4:
                        link += "violations"
                        break;
                    case 5:
                        link += "employment"
                        break;
                    case 6:
                        link += "quizzes"
                        break;
                    case 7:
                        link += "experience"
                        break;
                    case 8:
                        link += "acknowledgement"
                        break;
                    case 9:
                        link += "review"
                        break;
                    case 10:
                        link += "boardApproval"
                        break;
                    case 11:
                        link += "examinationVerification";
                        break;
                    case 12:
                        link += "recommendation";
                        break;
                    case 13:
                        link += "waiver";
                        break;
                    case 14:
                        link += "supervisingProfessional";
                        break;
                    case 15:
                        link += "unlicensedActivity";
                        break;
                    case 16:
                        link += "coopexperience";
                        break;
                    case 17:
                        link += "residency";
                        break;
                    default:
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }
        }
        dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: route })
        push(baseLink + "sections");
    }
    const navigateAndSetCurrentReinstatement = (rowData) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: rowData.id });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: rowData.entityId });
        var baseLink = "";
        switch (rowData.credential?.credentialType?.id) {
            case 1:
                baseLink = "/internal/efreinstatement/";
                break;
            case 2:
                baseLink = "/internal/vfReinstatement/";
                break;
            case 3: // For when we have reinstatement
                baseLink = "/internal/eiReinstatement/";
                break;
            case 4:// For when we have reinstatement
                baseLink = "/internal/lsiReinstatement/";
                break;
            case 5:
                baseLink = "/internal/plsReinstatement/";
                break;
            case 6:
                baseLink = "/internal/pereinstatement/";
                break;
        }
        if (rowData?.sections?.length) {
            var route = [];
            for (var i = 0; i < rowData.sections.length; i++) {
                let sectionId = rowData.sections[i].sectionType.id
                var link = baseLink;
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 2:
                        link += "employment"
                        break;
                    case 3:
                        link += "violations"
                        break;
                    case 4:
                        link += "quizzes"
                        break;
                    case 5:
                        link += "cpdRequirements"
                        break;
                    case 6:
                        link += "personalReferences"
                        break;
                    case 7:
                        link += "experience"
                        break;
                    case 8:
                        link += "disclosure"
                        break;
                    case 9:
                        link += "acknowledgement"
                        break;
                    case 10:
                        link += "review"
                        break;
                    case 11:
                        link += "boardApproval"
                        break;
                    case 12:
                        link += "waiver"
                        break;
                    case 13:
                        link += "supervisingProfessional"
                        break;
                    case 14:
                        link += "unlicensedActivity"
                        break;
                    case 15:
                        link += "design"
                        break;
                    case 16:
                        link += "education"
                        break;
                    case 17:
                        link += "examination"
                        break;
                    default:
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }
            dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: route })
        }
        push(baseLink + "sections");
    }
    const navigateAndSetCurrentRenewal = (rowData) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: rowData.id });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: rowData.entityId });
        var baseLink = "";
        switch (rowData.credential?.credentialType?.id) {
            case 1:
                baseLink = "/internal/efrenewal/";
                break;
            case 2:
                baseLink = "/internal/vfRenewal/";
                break;
            case 3:
                baseLink = "/internal/eirenewal/";
                break;
            case 4:
                baseLink = "/internal/lsirenewal/";
                break;
            case 5:
                baseLink = "/internal/plsRenewal/";
                break;
            case 6:
                baseLink = "/internal/perenewal/";
                break;
        }
        if (rowData?.sections?.length) {
            var route = [];
            for (var i = 0; i < rowData.sections.length; i++) {
                let sectionId = rowData.sections[i].sectionType.id
                var link = baseLink;
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 2:
                        link += "licenseStatus"
                        break;
                    case 3:
                        link += "cpdRequirements"
                        break;
                    case 4:
                        link += "violations"
                        break;
                    case 5:
                        link += "supervisingprofessional"
                        break;
                    case 6:
                        link += "privacy"
                        break;
                    case 7:
                        link += "acknowledgement"
                        break;
                    case 8:
                        link += "review"
                        break;
                    case 9:
                        link += "waiver"
                        break;
                    case 10:
                        link += "design"
                        break;
                    default:
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }
            dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: route })
        }
        push(baseLink + "sections");
    }

    return (
        <>
            <h3 className="mb-3">Queue</h3>
            <BlockUi blocking={blockUI}>
                <Card>
                    <Card.Body>
                        <Tabs activeKey={gridType} transition={false} onSelect={(selected) => { setGridType(selected) }}>
                            <Tab eventKey="myTasks" title="My Tasks">
                                <hr />
                                <MyTasksGrid viewItemCallback={viewItem} propsIsActive={gridType === 'myTasks'} />
                            </Tab>
                            {hasUnassignedPermission && 
                                <Tab eventKey="unassignedTasks" title="Unassigned Tasks">
                                    <hr />
                                    <UnassignedTasksGrid viewItemCallback={viewItem} propsIsActive={gridType === 'unassignedTasks'} />
                                </Tab>
                            }
                            <Tab eventKey="allTasks" title="All Tasks">
                                <hr />
                                <AllTasksGrid viewItemCallback={viewItem} propsIsActive={gridType === 'allTasks'} />
                            </Tab>
                            <Tab eventKey="closedTasks" title="Closed Tasks">
                                <hr />
                                <ClosedTasksGrid viewItemCallback={viewItem} propsIsActive={gridType === 'closedTasks'} />
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </BlockUi>
        </>
    );
}
export default QueuePage;