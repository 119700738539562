import axios from "axios";
import authHeader from '../authheader'

const ISSUESURL = process.env.REACT_APP_APIURL + "/api/Issue";

// Due to the way the code tables were made, we reinstatement and renewals are flip flopped so we are remapping this here for now
const getIssues = (type, sectionId) => {
    var parentTypeId = 0;
    switch (type) {
        case 1:
            parentTypeId = 1;
            break;
        case 2:
            parentTypeId = 3;
            break;
        case 3:
            parentTypeId = 2;
        default:
    }
    return axios.get(`${ISSUESURL}/issues`, { headers: authHeader(), params: { parentTypeId, sectionId } });
}

const postIssue = (text, type, sectionId) => {
    var parentTypeId = 0;
    switch (type) {
        case 1:
            parentTypeId = 1;
            break;
        case 2:
            parentTypeId = 3;
            break;
        case 3:
            parentTypeId = 2;
        default:
    }
    return axios.post(`${ISSUESURL}/issue`, null, { headers: authHeader(), params: { parentTypeId, sectionId, text} });
}

const updateIssue = (issueId, text, type, sectionId) => {
    var parentTypeId = 0;
    switch (type) {
        case 1:
            parentTypeId = 1;
            break;
        case 2:
            parentTypeId = 3;
            break;
        case 3:
            parentTypeId = 2;
        default:
    }
    return axios.post(`${ISSUESURL}/updateissue`, null, { headers: authHeader(), params: { issueId, parentTypeId, sectionId, text } });
}

const getIssuesForSections = (parentTypeId, id) => {
    return axios.get(`${ISSUESURL}/issuesforsections`, { headers: authHeader(), params: { parentTypeId, id } });
}

const postIssueComment = (issueId, text, type) => {
    return axios.post(`${ISSUESURL}/issueresponse`, null, { headers: authHeader(), params: { issueId, text } });
}

const toggleIssue = (type, issueId, closed) => {
    if (closed)
        return axios.post(`${ISSUESURL}/closeissue`,null, { headers: authHeader(), params: { issueId } });
    else
        return axios.post(`${ISSUESURL}/reopenissue`,null, { headers: authHeader(), params: { issueId } });

}

export default {
    getIssues,
    postIssue,
    postIssueComment,
    toggleIssue,
    getIssuesForSections,
    updateIssue
}