import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, FormGroup, InputPicker, SelectPicker, Table, TagPicker } from 'rsuite';
import queueService from '../../../services/Internal/Queue/queue.service';
import entityService from '../../../services/Internal/Staff/entity.service';
import { getInternalStatuses } from '../../../services/Shared/codeTable.service';

function GridSearch(props) {
    const [statuses, setStatuses] = useState([]);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [applicationTypes, setApplicationTypes] = useState([]);
    useEffect(() => {
        if (props.propsIsActive) {
            entityService.plsGetStaffAndAdmin().then((response) => {
                setEmployeeList(response.data.map((item, i) => {
                    return { "label": `${item.firstName} ${item.lastName}`, "value": `${item.id}` }
                }).filter((item, index, self) =>
                    index === self.findIndex((i) => (
                        i.value === item.value
                    ))
                ));
            });
            queueService.getApplicationTypes().then((response) => {
                setApplicationTypes(response.data.map((x, i) => { return { "label": `${x}`, "value": `${x}` } }));
            })
            if (props.hasInternalStatusSearch) {
                getInternalStatuses().then(setStatuses);
            }
        }
    }, [props.propsIsActive])

    return (
        <Form formValue={props.searchModel} onChange={props.setSearchModel} onSubmit={props.handleSearch} fluid>
            <Row>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>First Name</b></ControlLabel>
                        <FormControl name="firstName" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Last Name</b></ControlLabel>
                        <FormControl name="lastName" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Organization Name</b></ControlLabel>
                        <FormControl name="organizationName" />
                    </FormGroup>
                </Col>
                {props.hasInternalStatusSearch &&
                    <Col>
                        <FormGroup>
                            <ControlLabel className="text-left"><b>Internal Status</b></ControlLabel>
                            <FormControl
                                block
                                accepter={SelectPicker}
                                searchable={false}
                                data={statuses}
                                name="internalStatus" />
                        </FormGroup>
                    </Col>
                }
            </Row>
            {
                (showAdvancedSearch && props.hasAdvancedSearch) &&
                <>
                    <Row className="mt-2">
                        <Col>
                            <FormGroup>
                                <ControlLabel><b>Assigned To</b></ControlLabel>
                                <FormControl
                                    block
                                    accepter={SelectPicker}
                                    searchable={false}
                                    data={employeeList}
                                    name="assignedTo" />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ControlLabel><b>Submitted Date Range</b></ControlLabel>
                                <FormControl
                                    name="dateSubmittedBegin"
                                    type="date"
                                    block="true"
                                />
                            </FormGroup>
                        </Col>
                        <span style={{ marginTop: '2rem' }}>to</span>
                        <Col>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <FormControl
                                name="dateSubmittedEnd"
                                type="date"
                                block="true"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <FormGroup>
                                <ControlLabel><b>Application Type</b></ControlLabel>
                                <FormControl
                                    block
                                    accepter={TagPicker}
                                    data={applicationTypes}
                                    name="applicationType" />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            }
            <hr />
            {props.hasAdvancedSearch ?
                <>{showAdvancedSearch ?
                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={() => { setShowAdvancedSearch(false); props.clearSearchModel(); }} className="mr-2">Simple Search</Button>
                        <Button onClick={props.clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                        <Button type="submit">Search</Button>
                    </div>
                    :
                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={() => { setShowAdvancedSearch(true); }} className="mr-2">Advanced Search</Button>
                        <Button type="submit">Search</Button>
                    </div>
                }
                </>
                :
                <div style={{ textAlign: 'right' }}>
                    <Button type="submit">Search</Button>
                </div>
            }
        </Form>
    );
}

export default GridSearch;