import React, { useEffect, useState } from 'react'
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Acknowledgement from '../../../../../Shared/Card/Acknowledgement';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/LsiInformation';
import applicationService from '../../../../../../services/Public/Application/LSI/lsiApplication.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Swal from 'sweetalert2';
import Issues from '../../../../../Shared/Card/Issues';

function AcknowledgementPage() {
    const [hasLoaded, setHasLoaded] = useState(false)
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 8; // Revisit
    const [formModel, setFormModel] = useState({})
    useEffect(() => {
        applicationService.lsiGetSection(appId, sectionId).then((response) => {
            setFormModel(response.data);
            setHasLoaded(true);
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!formModel.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                submitData(true);
            }
        }
    }
    const submitData = (isForSubmit) => {
        applicationService.lsiPostSection(appId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <b>Land Surveyor Intern Application</b>
                <h4 className="mb-3">Acknowledgement</h4>
                <Row>
                    <Col>
                        <Information content={GetAppInformationContent("acknowledgement")} className="mb-3" />
                    </Col>
                </Row>
                <br />
                {hasLoaded ?
                    <Form readOnly={!formModel.isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <Row>
                            <Col>
                                <Acknowledgement appType="application"/>
                            </Col>
                        </Row>
                        <br />
                        <Issues isUserEditable={true} type={1} sectionId={formModel.id} />
                        <FormActionButtons isReadOnly={!formModel.isUserEditable} backToSectionLink="/individual/lsiApp/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default AcknowledgementPage