import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import LicenseStatus from './LicenseStatus';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import licenseStatusService from '../../../../../../services/Public/Renewal/PLS/licenseStatus.service';
import renewalService from '../../../../../../services/Public/Renewal/PLS/plsRenewal.service';
import Swal from 'sweetalert2';
import Information from '../../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import { getRenewingintolist } from '../../../../../../services/Shared/codeTable.service';
import Issues from '../../../../../Shared/Card/Issues';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
function LicenseStatusPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const renewalId = useSelector(x => x.currentApplicationId);
    const sectionId = 2;
    const routes = useSelector(x => x.currentRoutes);
    const [sectionData, setSectionData] = useState({});
    const [plsRenewalLicenseStatuses, setPlsRenewalLicenseStatuses] = useState([]);

    const [formModel, setFormModel] = useState({
        gridData: [],
        renewingTo: ""
    })
    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        renewingTo: ObjectType().isRequired("This field is required.")
    });

    useEffect(() => {
        getRenewingintolist(renewalId).then(setPlsRenewalLicenseStatuses);
        renewalService.plsGetSection(renewalId, sectionId).then((data) => {
            setSectionData(data.data);
            licenseStatusService.getLicenseStatus(renewalId).then((response) => {
                setFormModel({
                    gridData: [{ credential: response.data?.credential?.formattedLicenseNumber, credentialStatus: response.data?.credential?.status?.status }],
                    renewingTo: response.data.renewingTo ? { id: response.data.renewingTo.id, code: response.data.renewingTo.renewingTo } : null
                })
                setHasLoaded(true);
        })
    })
}, [])

const formSubmit = (validation, event) => {
    if (!sectionData.isUserEditable) {
        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
    }
    else {
        if (validation) {
            submitData(true);
        }
    }
}

const formSave = (event) => {
    submitData();
}

const submitData = (isForSubmit) => {
    licenseStatusService.postLicenseStatus(renewalId, formModel.renewingTo.id).then((response) => {
        if (response.data) {
            if (isForSubmit)
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            else
                Swal.fire("Saved!")
        }
    })
}

return (
    <div className="plsRenewalLicenseStatusPage">
        <Container fluid>
            <strong>Professional Land Surveyor Renewal</strong>
            <h4 className="mb-3">License Status</h4>
            <Information content={GetRenewalInformationContent("licenseStatus")} />
            <br />
            {hasLoaded ?
                <Form model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                    <LicenseStatus plsRenewalLicenseStatuses={plsRenewalLicenseStatuses} isReadOnly={!sectionData.isUserEditable} gridData={formModel.gridData} />
                    <br />
                    <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />
                    <FormActionButtons isReadOnly={!sectionData.isUserEditable} handleSave={formSave} backToSectionLink="/individual/plsRenewal/sections" />
                </Form>
                :
                <LoadingSpinner />
            }
        </Container>
    </div>
)
}
export default LicenseStatusPage;