import axios from "axios";
import authHeader from '../../../authheader'

const EIREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualEiReinstatement";

const getContactInformation = (reinstatementId) => {
    return axios.get(`${EIREINSTATEMENTURL}/contactinformation`, { headers: authHeader(), params: { reinstatementId } });
}
const postContactInformation = (reinstatementId, certDisplayName) => {
    return axios.post(`${EIREINSTATEMENTURL}/contactinformation`, null, { headers: authHeader(), params: { reinstatementId, certDisplayName } });
}

export default {
    getContactInformation,
    postContactInformation
}