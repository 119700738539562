import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Icon, Table } from 'rsuite';
import Swal from 'sweetalert2'
import paymentService from '../../../../services/Internal/Revenue/payment.service';
import invoiceService from '../../../../services/Shared/invoice.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner'
import AddLineItem from './AddLineItem';

function InvoicePage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [currentInvoiceId, setCurrentInvoiceId] = useState();
    const [payments, setPayments] = useState([])
    const [invoice, setInvoice] = useState();
    const { push } = useHistory();
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        var invoiceId = params.invoiceId;
        if (invoiceId) {
            setCurrentInvoiceId(invoiceId);
            reloadInvoice(invoiceId);
        }
    }, [])
    const reloadPayments = (invoiceId) => {
        paymentService.getPaymentsForInvoice(invoiceId).then((response) => {
            if (response.data) {
                setPayments(response.data);
                setHasLoaded(true);
            }
        })
    }
    const reloadInvoice = (invoiceId) => {
        setHasLoaded(false);
        invoiceService.getInvoice(invoiceId).then((response) => {
            if (response.data) {
                setInvoice(response.data);
                reloadPayments(invoiceId);
            }
        })
    }
    const deleteInvoice = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will delete the invoice and associated line items!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                invoiceService.deleteInvoice(invoice.id).then((response) => {
                    if (response.data)
                    {
                        push("/internal/revenue/invoicesearch");
                    }
                });
            }
        })
    }
    return (
        <>
            <Card className="w-100 mb-3">
                <Card.Body>
                    {hasLoaded ?
                        <>
                            <AddLineItem show={modalShow} onHide={(shouldReload) => { if (shouldReload) { reloadInvoice(invoice.id); } setModalShow(false); }} invoiceId={invoice.id} entityId={invoice.entityId} />
                            <Row className="mb-3">
                                <Col style={{ maxWidth: '100px' }}>
                                    <span>Invoice ID</span>
                                    <br />
                                    <h6>{invoice.id}</h6>
                                </Col>
                                <Col>
                                    <span>Contact</span>
                                    <br />
                                    <h6>{invoice.entityDetails}</h6>
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                    <span>Invoice Date</span>
                                    <br />
                                    <h6>{new Date(invoice.dateCrte).toLocaleDateString()}</h6>
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                    <span>Paid Date</span>
                                    <br />
                                    <h6>{invoice.datePaid ? new Date(invoice.datePaid).toLocaleDateString() : ''}</h6>
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                    <span>Invoice Amount</span>
                                    <br />
                                    <h6>{invoice.totalAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}</h6>
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                    <span>Balance</span>
                                    <br />
                                    <h6>{invoice.balance.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}</h6>
                                </Col>
                                <Col>
                                    <span>User</span>
                                    <br />
                                    <h6>{invoice.userName}</h6>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col style={{ textAlign: 'right' }}>
                                    <Button disabled={!invoice.canAddLineItem} variant={invoice.canAddLineItem ? 'primary' : 'secondary'} onClick={() => setModalShow(true)}>Add Line Item</Button>
                                    &nbsp;
                                    <Button disabled={!invoice.canAddLineItem} variant={'danger'} onClick={() => deleteInvoice()}>Delete Invoice</Button>
                                </Col>
                            </Row>
                            <Table autoHeight data={invoice.lineItems} bordered={true}>
                                <Table.Column flexGrow={3}>
                                    <Table.HeaderCell>
                                        <b>GL Account</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.feeDefinition.feeDefinitionDescription)}</Table.Cell>
                                </Table.Column>

                                <Table.Column width={150}>
                                    <Table.HeaderCell>
                                        <b>Amount Due</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.amount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }))}</Table.Cell>
                                </Table.Column>

                                <Table.Column width={100}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">
                                        {rowData => {
                                            function handleDelete() {
                                                Swal.fire({
                                                    title: 'Are you sure?',
                                                    text: "You won't be able to revert this!",
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#3085d6',
                                                    cancelButtonColor: '#d33',
                                                    confirmButtonText: 'Yes, delete it!'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        invoiceService.deleteLineItem(rowData.id).then((response) => {
                                                            if (response.data)
                                                                reloadInvoice(invoice.id);
                                                            else
                                                                Swal.fire("Error", "Failed to delete line item", "error");
                                                        });
                                                    }
                                                })
                                            }
                                            return ((invoice.totalAmount == invoice.balance && invoice.canAddLineItem) &&
                                                <span>
                                                    <Icon icon="trash" size="2x" onClick={handleDelete} style={{ cursor: "pointer" }} />
                                                </span>
                                            );
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                            </Table>
                        </>

                        : <LoadingSpinner />
                    }
                </Card.Body>
            </Card >
            <br />
            <Card>
                <Card.Header><h5>{!invoice?.canAddLineItem ? "Payment Applied to Invoice" : "Available Payments for Open Batch"}</h5></Card.Header>
                <Card.Body>
                    <Table wordWrap autoHeight data={payments} bordered={true}>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Payment ID</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.id)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={150}>
                            <Table.HeaderCell>
                                <b>Payment Type</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.paymentType.paymentType)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Contact</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.entityDetails)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Remitter</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.remitter)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Amount</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }))}</Table.Cell>
                        </Table.Column>
                        {invoice?.canAddLineItem &&
                            <Table.Column width={100}>
                                <Table.HeaderCell>
                                    <b>Available</b>
                                </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.isAllocatedToInvoice ? '' : rowData.availableAmount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }))}</Table.Cell>
                            </Table.Column>
                        }
                        <Table.Column width={150}>
                            <Table.HeaderCell>
                                <b>Action(s)</b>
                            </Table.HeaderCell>
                            <Table.Cell dataKey="id">
                                {rowData => {
                                    function invoiceDeallocate() {
                                        paymentService.deallocatePayment(rowData.id, currentInvoiceId).then((response) => {
                                            if (response.data) {
                                                reloadInvoice(currentInvoiceId);
                                            }
                                        })
                                    }
                                    function invoicePay() {
                                        paymentService.allocatePayment(rowData.id, currentInvoiceId).then((response) => {
                                            if (response.data) {
                                                if (response.data.autoApprovedRenewalIds?.length) {
                                                    Swal.fire({
                                                        title: 'Saving PDF(s)',
                                                        didOpen: () => {
                                                            Swal.showLoading();
                                                            var dataToTransmit = {
                                                                RenewalIds: response.data.autoApprovedRenewalIds
                                                            }
                                                            paymentService.postAutoRenewalPdfs(dataToTransmit).then((data) => {
                                                                if (data) {
                                                                     reloadInvoice(currentInvoiceId);
                                                                    Swal.close()
                                                                }
                                                            })
                                                        },
                                                        allowOutsideClick: false
                                                    })
                                                }
                                                else { reloadInvoice(currentInvoiceId); }
                                            }
                                        })
                                    }
                                    return (
                                        <>
                                            {rowData.isAllocatedToInvoice ?
                                                <Button type="button" onClick={invoiceDeallocate} variant="dark">Deallocate</Button>
                                                :
                                                <Button type="button" disabled={!invoice?.canAddLineItem} onClick={invoicePay} variant="primary">Pay</Button>
                                            }
                                        </>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
export default InvoicePage;