import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Form, List, Schema } from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import ReviewPdf from '../../../../../Shared/Card/ReviewPdf';
import renewalService from '../../../../../../services/Public/Renewal/renewal.service';
import plsRenewalService, { getSections } from '../../../../../../services/Public/Renewal/PLS/plsRenewal.service';
import shoppingCartService from '../../../../../../services/Public/shoppingCart.service';
import Swal from 'sweetalert2';

function ReviewPage() {
    const [hasIssues, setHasIssues] = useState(true)
    const [hasLoaded, setHasLoaded] = useState(true)
    const { push } = useHistory();
    const renewalId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const [allSections, setAllSections] = useState([]);
    const [sectionData, setSectionData] = useState({});
    const [violationIssuesHtml, setViolationIssuesHtml] = useState();
    const sectionId = 8;

    useEffect(() => {
        getSections().then(setAllSections);
        plsRenewalService.plsGetSection(renewalId, sectionId).then((response) => {
            setSectionData(response.data);
        })
    }, [])
    const navigateToPage = (sectionId) => {
        push(routes.filter(x => x.sectionId == (sectionId))[0].route)
    }
    const validateRenewal = () => {
        setHasLoaded(false);
        renewalService.validateRenewal(renewalId).then((response) => {
            let errors = response.data.map((error, i) => {
                return <List.Item key={i} index={i} onClick={() => { navigateToPage(error.sectionId) }}>
                    Error{error.errors.length > 1 ? 's' : ''} in <b>{allSections.filter(x => x.id == error.sectionId)[0].sectionTitle}</b>: {error.errors.join(', ')}
                </List.Item>
            })
            if (response.data?.length)
                setViolationIssuesHtml(
                    <List hover bordered>
                        {errors}
                    </List>
                )
            else {
                setViolationIssuesHtml('')
                setHasIssues(false);
                Swal.fire("Validation Successful", `Your renewal passed all validation checks.  You may now download a PDF version to keep for your records.`, 'success')
            }
            setHasLoaded(true);
        })
    }
    const submitRenewal = () => {
        setHasLoaded(false);
        shoppingCartService.addToShoppingCart(renewalId, 6).then((data) => {
            if (data.data) { push('/individual/shoppingcart'); }

        })

        //renewalService.submitRenewal(renewalId).then((data) => {

        //    if (data.data?.length) {
        //        setHasIssues(true);
        //        let errors = data.data.map((error, i) => {
        //            return <List.Item key={i} index={i} onClick={() => { navigateToPage(error.sectionId) }}>
        //                Error{error.errors.length > 1 ? 's' : ''} in <b>{allSections.filter(x => x.id == error.sectionId)[0].sectionTitle}</b>: {error.errors.join(', ')}
        //            </List.Item>
        //        })
        //        setViolationIssuesHtml(
        //            <List hover bordered>
        //                {errors}
        //            </List>
        //        )
        //        setHasLoaded(true);
        //    }
        //    else {
        //        setHasIssues(false);
        //        setViolationIssuesHtml("")
        //        plsRenewalService.plsGetSection(renewalId, sectionId).then((response) => {
        //            setSectionData(response.data);
        //            setHasLoaded(true);
        //        })
        //    }
        //})
    }

    return (
        <div>
            <Container fluid>
                <b>Professional Land Surveyor Renewal</b>
                <h4 className="mb-3">Review</h4>
                <Row>
                    <Col>
                        <Information content={GetRenewalInformationContent("review")} className="mb-3" />
                    </Col>
                </Row>
                {hasLoaded ?
                    <>
                        <br />
                        {sectionData.isUserEditable ?
                            <Button onClick={validateRenewal} name="reviewRenewal">Validate Renewal</Button> : <ReviewPdf id={renewalId} parentTypeId={3} />
                        }
                        {
                            (!hasIssues && sectionData.isUserEditable) &&
                            <><span className="ml-2"><ReviewPdf id={renewalId} parentTypeId={3} /></span>
                                <Button name="addToCartBtn" onClick={submitRenewal} variant="success" className="ml-2">Submit Renewal</Button>
                            </>
                        }
                        <Button name="backToSections" as={Link} to="/individual/plsRenewal/sections" variant="primary" className="ml-2" >Back to Sections</Button>
                        <br />
                        <br />
                        {violationIssuesHtml}
                    </>
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ReviewPage