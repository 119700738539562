import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { InputPicker, SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup, InputNumber } from 'rsuite';
import Swal from 'sweetalert2';
import { titleData } from '../../../../fakeapi'
import dashboardService from '../../../../services/Public/dashboard.service';
import { getCountries } from '../../../../services/Shared/codeTable.service'
import RedStar from '../../../Shared/GenericComponents/RedStar';
import YesNoRadioOption from '../../../Shared/RadioButton/YesNoRadioOption';


function EditLockedContactInfoModal(props) {
    const [recordModel, setRecordModel] = useState({
        dateOfBirth: null,
        birthCity: '',
        birthCountry: null
    })
    const [countries, setCountries] = useState([])

    useEffect(() => {
        getCountries().then(setCountries);
        setRecordModel(props.modaldata);
    }, [props.modaldata])

    const { StringType, ObjectType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        dateOfBirth: StringType().isRequired('This field is required.'),
        birthCity: StringType().isRequired('This field is required.'),
        birthCountry: ObjectType().isRequired('This field is required.'),
    })
    const formSubmit = (validation, event) => {
        //Do validation check and submit
        var validationObject = model.check(recordModel);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                if (key.includes("citizenshipCountry")) {
                    if (recordModel.isUsCitizen)
                        continue;
                }
                return;
            }
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to update this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Save it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var dataToTransmit = { ...recordModel };//do mapping if needed
                dashboardService.postIndividualLockedContactInformation(dataToTransmit).then((response) => {
                    if (response.data) {
                        props.onHide();

                    }
                })
            }
        })

    }


    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <b className='text-danger'>Required Contact Information</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="recordForm" fluid model={model} formValue={recordModel} onChange={recordModel => setRecordModel(recordModel)} onSubmit={formSubmit} >
                    {props.modaldata.dateOfBirth == null &&
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Date of Birth&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        name="dateOfBirth"
                                        type="date"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                    {(props.modaldata.birthCity == null || props.modaldata.birthCity == '') &&
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            City of Birth&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl name="birthCity" />
                                </Col>
                            </Row>
                        </FormGroup>
                    }{props.modaldata.birthCountry == null &&
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Country of Birth&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        accepter={InputPicker}
                                        placeholder="Please Select"
                                        block
                                        data={countries}
                                        name="birthCountry"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="recordForm" variant="success">Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditLockedContactInfoModal;