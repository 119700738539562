import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import internalRenewalService from '../../../../../services/Internal/Renewal/EI/eiRenewal.service';
import violationService from '../../../../../services/Internal/Renewal/EI/violation.service';
import { useSelector } from 'react-redux';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/EiInformation';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import { getRenewalCodeViolationAnswers } from '../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../Shared/Card/Violations';
import FormEntityInformation from '../../../Shared/FormEntityInformation';
import renewalService from '../../../../../services/Internal/Renewal/renewal.service';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const { push } = useHistory();
    const sectionId = 4;
    const renewalId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Violations"
        }
    );

    const [formData, updateFormData] = useState({
        renewalId: renewalId,
        dateCrte: new Date(),
        entityId: entityId,
        id: 0,
        userCrte: "",
        yesAnswerExplanation: ""
    });

    useEffect(() => {
        setHasLoaded(false);
        internalRenewalService.eiGetSection(renewalId, sectionId).then((response) => {
            violationService.getViolation(renewalId).then((data) => {
                if (data.data) {

                    updateFormData(data.data);
                }
                else {
                    getRenewalCodeViolationAnswers(3).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setIsComplete(response.data.staffReviewComplete);
                renewalService.getIndividualRenewal(renewalId).then((renewalData) => {
                    setIsUserEditable(renewalData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: renewalData.data?.credential?.formattedLicenseNumber,
                                applicationDate: renewalData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })

    }, [])

    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        yesAnswerExplanation: StringType().isRequired('This field is required.')
    });
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);



    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            setShowFormErrors(true)
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }

            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            violationService.postViolation(formData).then((response) => {
                if (response.data.id) {
                    internalRenewalService.eiMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                        push(routes.filter(x => x.sectionId == (sectionId + 2))[0].route);
                    })
                }
            })
        }
    }


    return (
        <div className="eiRenewalViolationsPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetRenewalInformationContent("violations")} />
                <br />
                {hasLoaded &&
                    <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>

                        <GenericViolations
                            showFormErrors={showFormErrors}
                            showAdditionalFields={showAdditionalFields}
                            hideSignature={true}
                            isReadOnly={!isUserEditable}
                            isInternal={true}
                            sectionId={sectionId}
                            sectionDataId={sectionData.id}
                            entityId={entityInfo.entityId}
                            parentTypeId={3}
                            credentialTypeId={3}
                        />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            backToSectionLink="/internal/eirenewal/sections" />
                    </Form>
                }
            </Container>
        </div>
    )
}
export default Violations;