import React from 'react';
import { Card } from 'react-bootstrap'
import { Panel } from 'rsuite';

function WelcomeContent() {

    return (
        <div className="dashboardWelcomeContent">
            <Panel header="Welcome" shaded defaultExpanded collapsible bordered>

                <div>

                    <b>Complete your Profile:</b>
                    <ul>
                        <li>Click on <b>"Update contact information"</b> in the left menu to ensure your contact information is entered and updated. New registrants or new applicants will need to complete this first before starting an application.</li>
                    </ul>

                    <hr />

                    <b>Online Applications:</b>
                    <ul>
                        <li>Application for first-time EI certification in Louisiana</li>
                        <li>Application for Licensure as a Professional Engineer by Examination </li>
                        <ul>
                            <li>This application is for an applicant seeking an initial PE license.</li>
                            <li>NOTE: PE Applicants wanting to apply for a license by examination in Louisiana must meet ALL the following requirements before applying:</li>
                                <ul>
                                    <li>You have graduated with a BS degree from an EAC/ABET accredited program; or meet the educational requirements. </li>
                                <li>You have obtained the requisite four years of verified progressive engineering experience subsequent to meeting the educational requirements.</li>
                                <li>Passed the NCEES PE Exam.</li>
                                    <li>You must have established a NCEES record. </li>
                                    <li>If you have not met all of the above requirements, contact Janet Lindsey at <a href="mailto:janet@lapels.com">janet@lapels.com.</a> </li>
                                </ul>
                        </ul>
                        <li>Application for Licensure as a Professional Engineer by Comity with NCEES record </li>
                        <ul>
                            <li>
                                This application is for an applicant with a license in another jurisdiction and seeking a comity PE license.
                            </li>
                        </ul>
                        <li>To apply for firm licensure, sign out from this individual account and sign back in with your firm user ID and password. If you have not yet registered your firm, click "Register" at <a href="https://lola.lapels.com">https://lola.lapels.com</a> and select the "Firm" option to create a new account.</li>
                        

                     </ul>


                    <b>Online Renewals/Reinstatements</b>
              
                    <ul>
                        <li>Licenses/certifications expiring in March may begin renewing in February. Those expiring in September may begin renewing in August.</li>        
                        <li>Once licenses/certifications have expired, licensees may apply to reinstate.</li>
                     </ul>
                       
                    
                </div>
            </Panel>
        </div>
    )
}

export default WelcomeContent;