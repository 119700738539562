import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Tab, Tabs } from 'react-bootstrap'
import { Form, Schema } from 'rsuite';
import { GetFirmGeneralContactInformationInstructions } from '../../../Shared/InformationContent/GeneralInformation'
import Information from '../../../Shared/Card/Information';
import Swal from 'sweetalert2';
import ContactService from '../../../../services/contact.service';
import MailingAddress from '../../../Shared/Card/Contact/MailingAddress';
import FirmInformation from '../../../Shared/Card/Contact/FirmInformation';
import PublicAddress from '../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import dayjs from 'dayjs';

function ContactInformationPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        ContactService.getFirmContactInformation().then((response) => {
            var data = response.data;
            setFormModel({
                ...data,
                dateOfFormation: data.dateOfFormation != null ? dayjs(data.dateOfFormation).format('YYYY-MM-DD') : null,
                orgType: data.organizationType?.code,
                primaryEmail: data.primaryEmailAddress?.emailAddress,
                phoneNum: data.phone?.phoneNumber

            })
            setHasLoaded(true);
        })
    }, [])
    const [formModel, setFormModel] = useState({})

    const { StringType, ObjectType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().isRequired('This field is required.'),
        federalId: StringType().isRequired('This field is required.'),
        primaryEmail: StringType().isRequired('This field is required.'),
        contactName: StringType().isRequired('This field is required.'),
        orgType: StringType().isRequired('This field is required.'),
        domicileState: ObjectType().isRequired('This field is required.'),
        dateOfFormation: StringType().isRequired('This field is required.'),
        additionalInformation: StringType().isRequired('This field is required.'),
        phoneNum: StringType().isRequired('This field is required.').pattern(/^([(]\d{3}[)][ ]\d{3}[-]\d{4})|[0-9]{10}/, "Please enter a valid Phone Number"),
    });
    const addressModel = Schema.Model({
        streetAddress: StringType().isRequired('This field is required.'),
        country: ObjectType().isRequired('This field is required.'),
        city: StringType().isRequired('This field is required.'),
        postalCode: StringType().isRequired('This field is required.'),
        state: ObjectType().isRequired('This field is required.'),
        provinceRegion: StringType().isRequired('This field is required.'),
    })

    const [tabPane, setTabPane] = useState("firmInformation")

    const copyFromMailing = () => {
        setFormModel({
            ...formModel,
            publicAddress: formModel.mailingAddress == null ? {
                ...formModel.publicAddress,
                country: null,
                streetAddress: '',
                city: '',
                postalCode: '',
                state: null,
                provinceRegion: '',
            } : {
                ...formModel.publicAddress,
                country: formModel.mailingAddress?.country,
                streetAddress: formModel.mailingAddress?.streetAddress,
                city: formModel.mailingAddress?.city,
                postalCode: formModel.mailingAddress?.postalCode,
                state: formModel.mailingAddress?.state,
                provinceRegion: formModel.mailingAddress?.provinceRegion,
            }
        })
    }

    const formSubmit = (validation, event) => {
        var hasErrors = false;
        setFormModel({ ...formModel, mailingAddress: { ...formModel.mailingAddress, showErrors: true }, publicAddress: { ...formModel.publicAddress, showErrors: true } });
        var validationObject = model.check(formModel);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                hasErrors = true;
                setTabPane("firmInformation")
                return;
            }
        }
        if (!formModel.mailingAddress) {
            hasErrors = true;
            setTabPane("mailingAddress")
        }
        else {
            var mailingAddressValidationObject = addressModel.check(formModel.mailingAddress);
            for (const key in mailingAddressValidationObject) {
                if (mailingAddressValidationObject[key].hasError) {
                    if (!(key.includes("provinceRegion") && formModel.mailingAddress.country?.country === "United States") &&
                        !(key.includes("state") && formModel.mailingAddress.country?.country !== "United States")) {
                        setTabPane("mailingAddress")
                        document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                        hasErrors = true;
                        return;
                    }
                }
            }
            if (!formModel.publicAddress) {
                hasErrors = true;
                setTabPane("publicAddress")
            }
            else {
                var publicAddressValidationObject = addressModel.check(formModel.publicAddress);
                for (const key in publicAddressValidationObject) {
                    if (publicAddressValidationObject[key].hasError) {
                        if (!(key.includes("provinceRegion") && formModel.publicAddress.country?.country === "United States") &&
                            !(key.includes("state") && formModel.publicAddress.country?.country !== "United States")) {
                            setTabPane("publicAddress")
                            document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                            hasErrors = true;
                            return;
                        }
                    }
                }
            }
        }

        if (!hasErrors) {
            submitData();
        }
        //if i have gotten here then no real errors
        //navigate to errors

    }

    const submitData = () => {
        var dataToTransmit = {
            ...formModel,
            primaryEmailAddress: { ...formModel.primaryEmailAddress, emailAddress: formModel.primaryEmail },
            phone: { ...formModel.phone, phoneNumber: formModel.phoneNum == '' ? null : formModel.phoneNum }
        };
        ContactService.postFirmContactInformation(dataToTransmit).then((response) => {
            if (response.data?.entityId)
                Swal.fire("Saved", "Contact Information was successfully saved!", "success")
        })
    }

    return (
        <div>
            <Container fluid>
                <strong>Contact Information</strong>
                <h4 className="mb-3">Contact</h4>
                <Information content={GetFirmGeneralContactInformationInstructions()} />
                <br />
                <Card>
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="firmInformation" title="Firm Information">
                                    <br />
                                    <br />
                                    <Form id="firmInformationForm"
                                        model={model}
                                        fluid
                                        formValue={formModel}
                                        onChange={formModel => setFormModel(formModel)}
                                        onSubmit={formSubmit} >
                                        <FirmInformation />
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        model={addressModel}
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress boundValues={formModel.mailingAddress} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        model={addressModel}
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress copyFromMailing={() => { copyFromMailing() }} boundValues={formModel.publicAddress} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                <Button style={{ float: 'right', marginTop: '1rem' }} type="submit" form="firmInformationForm">Save</Button>
            </Container>
        </div>
    );
}
export default ContactInformationPage