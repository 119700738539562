import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import renewalService from '../../../../../../services/Public/Renewal/EF/efRenewal.service';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Information from '../../../../../Shared/Card/Information';
import Acknowledgement from '../../../../../Shared/Card/FirmAcknowledgement';
import Issues from '../../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/EFInformation';

function AcknowledgementPage() {
    const [hasLoaded, setHasLoaded] = useState(false)
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 7; // Revisit
    const [formModel, setFormModel] = useState({})
    useEffect(() => {
        renewalService.efGetSection(appId, sectionId).then((response) => {
            setFormModel(response.data);
            setHasLoaded(true);
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!formModel.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                submitData(true);
            }
        }
    }
    const submitData = (isForSubmit) => {
        renewalService.efPostSection(appId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <b>Engineering Firm Renewal</b>
                <h4 className="mb-3">Acknowledgement</h4>
                <Row>
                    <Col>
                        <Information content={GetRenewalInformationContent("acknowledgement")} className="mb-3" />
                    </Col>
                </Row>
                <br />
                {hasLoaded ?
                    <Form readOnly={!formModel.isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <Row>
                            <Col>
                                <Acknowledgement appType="renewal"/>
                            </Col>
                        </Row>
                        <br />
                        <Issues isUserEditable={true} type={3} sectionId={formModel.id} />
                        <FormActionButtons isReadOnly={!formModel.isUserEditable} backToSectionLink="/firm/efrenewal/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default AcknowledgementPage