import React, { useState } from 'react'
import { useEffect } from 'react';
import { Table } from 'rsuite'
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import changeLogService from "../../../services/Shared/changeLog.service"
import { sort_by } from '../../Shared/HelperFunctions';
import EntityInformationPage from '../Credential/Shared/EntityInformationPage';

function ContactChangeLogForEntity() {
    const [contactAuditTrail, setContactAuditTrail] = useState([]);
    const [contactAuditTrailHasLoaded, setContactAuditTrailHasLoaded] = useState(false);
    const [entityId, setEntityId] = useState();
    const refreshChangeLog = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        setEntityId(params.entityId);
        changeLogService.getContactChangeLogData(params.entityId).then((response) => {
            setContactAuditTrail(response.data.sort(sort_by('dateMod', true)));
        })
        setContactAuditTrailHasLoaded(true);
    }

    useEffect(() => {
        refreshChangeLog();
    }, [])

    return (<>
        {contactAuditTrailHasLoaded ? <>

            <EntityInformationPage entityId={entityId} activeKey={"entityInfo"} />
            <hr/>
            <br/>
            <h3 style={{ textAlign: "center" }}>Contact Audit Trail</h3>
            <Table wordWrap autoHeight bordered affixHeader data={contactAuditTrail}>
                <Table.Column minWidth={50} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Date</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.dateMod ? new Date(rowData.dateMod).toLocaleDateString() : '')}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={125} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>User</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.userMod)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={125} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Table</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.parentName)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={125} flexGrow={.75}>
                    <Table.HeaderCell>
                        <b>Field</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.fieldName)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={1}>
                    <Table.HeaderCell>
                        <b>Old</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.oldValue)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={100} flexGrow={1}>
                    <Table.HeaderCell>
                        <b>New</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.newValue)}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={75} flexGrow={.5}>
                    <Table.HeaderCell>
                        <b>Comments</b>
                    </Table.HeaderCell>
                    <Table.Cell>{rowData => (rowData.comments)}</Table.Cell>
                </Table.Column>
            </Table>
        </> : <LoadingSpinner />
        }
    </>);
}
export default ContactChangeLogForEntity;