import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import BlockUi from 'react-block-ui';
import { ControlLabel, Form, Table, FormControl, FormGroup, InputPicker, Icon } from 'rsuite';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import paymentService from '../../../services/Internal/Revenue/payment.service';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import { sort_by } from '../../Shared/HelperFunctions';
import { getContacts } from '../../../services/Shared/codeTable.service';

function DailyPaymentsByAccountPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [pdfData, setPdfData] = useState({
        beginDate: '',
        endDate: '',
        contact: '',
        data: null,
        summary: null
    });
    const [searchModel, setSearchModel] = useState({
        beginDate: '',
        endDate: '',
        contact: ''
    })
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultSummary, setSearchResultsSummary] = useState([]);
    useEffect(() => {
        // Initial search
        refreshGrid({
            beginDate: null,
            endDate: null,
            contact: null
        })
    }, [])
    const [contactLoading, setContactLoading] = useState(false);
    const [contacts, setContacts] = useState();
    const updateContacts = (value) => {
        if (value.length > 2) {
            getContacts(value).then((response) => {
                setContactLoading(true);
                setContacts(response);
                setContactLoading(false);
            });
        }
        else {
            setContacts([]);
        }
    }
    const [hasLoaded, setHasLoaded] = useState(false);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState("generalLedgerId");
    const [sortType, setSortType] = useState("asc");
    const clearSearchModel = () => {
        setSearchModel({
            beginDate: '',
            endDate: '',
            contact: ''
        })
    }
    const refreshGrid = (beginDate, endDate, contact) => {
        setIsSearching(true);
        paymentService.searchPaymentsByAccount(beginDate, endDate, contact?.entityId).then((response) => {
            setSearchResults(response.data.data);
            setPdfData({ ...response.data, contactEntityName: contact ? `${contact.formattedName} ${contact.formattedLicenseNumber ? "| " + contact.formattedLicenseNumber : ""} | ${contact.lolanumber}` : null });

            var tempSummary = response.data.summary;
            var grandTotalAmount = 0;
            var grandTotalItems = 0;
            for (var i = 0; i < response.data.summary.length; i++) {
                grandTotalAmount += response.data.summary[i].totalAmount;
                grandTotalItems += response.data.summary[i].itemsCount;
            }
            if (tempSummary.length > 0) {
                tempSummary = tempSummary.sort(sort_by('generalLedgerId',false))
                tempSummary.push({
                    generalLedgerId: null,
                    generalLedgerDescription: null,
                    totalAmount: grandTotalAmount,
                    itemsCount: grandTotalItems
                })
            }
            setSearchResultsSummary(tempSummary);
        }).finally(() => {
            setHasLoaded(true);
            setIsSearching(false);
        })
    }
    const submitSearch = () => {
        refreshGrid(searchModel.beginDate ? searchModel.beginDate : null, searchModel.endDate ? searchModel.endDate : null, searchModel.contact);
    }
    const downloadPdf = () => {
        setIsDownloading(true);
        var temp = pdfData.data.map((item, index) => {
            return {
                generalLedgerId: item.generalLedgerId,
                generalLedgerDescription: item.generalLedgerDescription,
                paymentId: item.paymentId,
                paymentType: item.paymentType,
                paymentAllocationAmount: item.paymentAllocationAmount,
                paymentFormattedName: item.paymentFormattedName,
                paymentEntityId: item.paymentEntityId,
                paymentLolaNumber: item.paymentLolaNumber,
                batchOwnerName: item.batchOwnerName,
                paymentAllocationDate: new Date(item.paymentAllocationDate)
            };
        })
        var dataToTransmit = {
            beginDate: pdfData.beginDate ? new Date(pdfData.beginDate) : null,
            endDate: pdfData.endDate ? new Date(pdfData.endDate) : null,
            contactEntityName: pdfData.contactEntityName,
            summary: [],
            data: temp
        }
        paymentService.downloadPaymentSummary(dataToTransmit).then((response) => {
            setIsDownloading(false);
        });
    }

    const getData = () => {
        var sortedData = searchResults.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    const filteredData = getData();

    return (
        <>
            <Card className="w-100 mb-3">
                <Card.Header>
                    <b>Daily Payments By Account</b>
                </Card.Header>
                <Card.Body>
                    <BlockUi blocking={isSearching}>
                        <Form fluid formValue={searchModel} onChange={setSearchModel} onSubmit={submitSearch} >
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Date Range</b></ControlLabel>
                                        <FormControl
                                            name="beginDate"
                                            type="date"
                                            block="true"
                                        />
                                    </FormGroup>
                                </Col>
                                <span style={{ marginTop: '2rem' }}>to</span>
                                <Col>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <FormControl
                                        name="endDate"
                                        type="date"
                                        block="true"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Contact</b></ControlLabel>
                                        <FormControl
                                            accepter={InputPicker}
                                            block="true"
                                            data={contacts}
                                            name="contact"
                                            labelKey="name"
                                            placeholder="Find a Contact (Min 3 characters)"
                                            onSearch={updateContacts}
                                            renderMenu={menu => {
                                                if (contactLoading) {
                                                    return (
                                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                            <Icon icon="spinner" spin /> Loading...
                                                        </p>
                                                    );
                                                }
                                                return menu;
                                            }}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <>
                                                        {`${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber}`}
                                                    </>
                                                );
                                            }}
                                            renderValue={(value, item, selectedElement) => {
                                                return (
                                                    <>
                                                        {item ? `${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber}` : 'Find a Contact (Min 3 characters)'}
                                                    </>
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                                <Button type="submit">Search</Button>
                            </div>
                        </Form>
                    </BlockUi>
                </Card.Body>
            </Card >

            <Card className="w-100 mb-3">
                <Card.Header><b>Daily Payments By Account Summary</b></Card.Header>
                <Card.Body>
                    <BlockUi blocking={isDownloading}>
                        <br />
                        {hasLoaded ?
                            <>
                                <div className="w-50" style={{ margin: "0 auto" }}>
                                    <Table
                                        wordWrap
                                        autoHeight data={searchResultSummary}
                                    >
                                        <Table.Column minWidth={150} flexGrow={1} className="ap">
                                            <Table.HeaderCell>
                                                <b>GL Account</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => {
                                                if (rowData.generalLedgerDescription || rowData.generalLedgerId) {
                                                    return (`${rowData.generalLedgerId} - ${rowData.generalLedgerDescription}`)
                                                }
                                                else return <b>Grand Total</b>
                                            }}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column width={100}>
                                            <Table.HeaderCell>
                                                <b>Total Items</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => {
                                                if (rowData.generalLedgerDescription || rowData.generalLedgerId) {
                                                    return (`${rowData.itemsCount}`)
                                                }
                                                else return <b>{rowData.itemsCount}</b>
                                            }}</Table.Cell>
                                        </Table.Column>
                                        <Table.Column width={150}>
                                            <Table.HeaderCell>
                                                <b>Total Amount</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => {
                                                if (rowData.generalLedgerDescription || rowData.generalLedgerId) {
                                                    return (rowData.totalAmount ? rowData.totalAmount.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    }) : rowData.totalAmount == 0 ? '$0.00' : '')
                                                }
                                                else return <b>{rowData.totalAmount.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                })}</b>

                                            }}</Table.Cell>
                                        </Table.Column>
                                    </Table>
                                </div>
                                <hr />
                                <Table wordWrap
                                    autoHeight
                                    data={filteredData}
                                    onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                                    sortColumn={sortColumn}
                                    sortType={sortType}>
                                    <Table.Column minWidth={150} flexGrow={1} sortable>
                                        <Table.HeaderCell>
                                            <b>GL Account</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="generalLedgerId">{rowData => (`${rowData.generalLedgerId} - ${rowData.generalLedgerDescription}`)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={150} sortable>
                                        <Table.HeaderCell>
                                            <b>Payment Type</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="paymentType">{rowData => (rowData.paymentType)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={100} sortable>
                                        <Table.HeaderCell>
                                            <b>Payment ID</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="paymentId">{rowData => (rowData.paymentId)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={150} sortable>
                                        <Table.HeaderCell>
                                            <b>Amount</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="paymentAllocationAmount">{rowData => (rowData.paymentAllocationAmount ? rowData.paymentAllocationAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }) : rowData.paymentAllocationAmount == 0 ? '$0.00' : '')}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={150} flexGrow={1} sortable>
                                        <Table.HeaderCell>
                                            <b>Contact</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="paymentFormattedName">{rowData => (rowData.paymentFormattedName)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={200} flexGrow={1} sortable>
                                        <Table.HeaderCell>
                                            <b>User</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="batchOwnerName">{rowData => (rowData.batchOwnerName)}</Table.Cell>
                                    </Table.Column>
                                </Table>
                                <GenericPagination dataLength={searchResults.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                                <Row className="float-right">
                                    <Button onClick={downloadPdf}><FaPrint /> Print</Button>
                                </Row>
                            </>
                            : <LoadingSpinner />
                        }
                    </BlockUi>
                </Card.Body>
            </Card >
        </>
    )
}


export default DailyPaymentsByAccountPage;