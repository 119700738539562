import axios from "axios";
import authHeader from './authheader.js'

const CONTACTURL = process.env.REACT_APP_APIURL + "/api/Contact";

const getContactInformation = () => {
    return axios.get(CONTACTURL, { headers: authHeader() });
}
const postContactInformation = (data) => {
    return axios.post(`${CONTACTURL}/updatecontact`, data, { headers: authHeader() });
}
const getFirmContactInformation = () => {
    return axios.get(`${CONTACTURL}/getfirmcontact`, { headers: authHeader() });
}
const postFirmContactInformation = (data) => {
    return axios.post(`${CONTACTURL}/updatefirmcontact`, data, { headers: authHeader() });
}

const changePassword = (data) => {
    return axios.post(`${CONTACTURL}/changepassword`, data, { headers: authHeader() });
}
const checkPassword = (currentPassword) => {
    return axios.post(`${CONTACTURL}/checkpassword`, null, { headers: authHeader(), params: { currentPassword } });
}
export default {
    getContactInformation,
    postContactInformation,
    getFirmContactInformation,
    postFirmContactInformation,
    changePassword,
    checkPassword
}