import axios from "axios";
import authHeader from '../../../authheader'

const PLSAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsApplication";

const getExperienceRecords = (applicationId) => {
    return axios.get(`${PLSAPPLICATIONURL}/experiencerecords`, { headers: authHeader(), params: { applicationId} });
}
const postExperienceRecord = (data) => {
    return axios.post(`${PLSAPPLICATIONURL}/experiencerecord`, data, { headers: authHeader() });
}
const downloadExperiences = (applicationId, credType) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/Reports/GetApplicationExperiences`, { headers: authHeader(), params: { applicationId: applicationId, credType: credType }, responseType: 'arraybuffer' });
}
export default {
    getExperienceRecords,
    postExperienceRecord,
    downloadExperiences
}