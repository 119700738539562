import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../Shared/Card/Issues';
import { useSelector } from 'react-redux';
import ViolationService from '../../../../../services/Internal/Application/EF/violation.service';
import applicationService from '../../../../../services/Internal/Application/EF/efApplication.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/EFInformation';
import Information from '../../../../Shared/Card/Information';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import { getCodeViolationAnswers } from '../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../Shared/Card/Violations';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 4;
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineering Firm Application",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Violations"
        }
    );
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        yesAnswerExplanation: StringType().isRequired('This field is required.'),
    });
    useEffect(() => {
        setHasLoaded(false);
        applicationService.efGetSection(store.currentApplicationId, sectionId).then((response) => {
            setIsComplete(response.data.staffReviewComplete);
            applicationService.getFirmApplication(store.currentApplicationId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                });
            })
            ViolationService.getViolation(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, issues: formData.issues, files: formData.files, filesForDisplay: formData.filesForDisplay });
                }
                else {
                    getCodeViolationAnswers(7).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        yesAnswerExplanation: "",
        files: [],
        filesForDisplay: [],
        issues: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);
    const downloadViolations = () => {
        ViolationService.downloadViolationFile(store.currentApplicationId)
    }
    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            setShowFormErrors(true)
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }
            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            if (showAdditionalFields === false || val) {
                var dataTransfer = { ...formData, applicationId: store.currentApplicationId, entityId: store.userInfo.EntityId };
                ViolationService.postViolation(dataTransfer).then((response) => {
                    applicationService.efPostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                        applicationService.efMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((data) => {
                            if (response.data && data.data) {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                            }
                        })
                    })
                });
            }
        }
    }

    return (
        <div className="efAppViolationsPage">
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col> <Information content={GetAppInformationContent("violations")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <GenericViolations
                                        showFormErrors={showFormErrors}
                                        showAdditionalFields={showAdditionalFields}
                                        hideSignature={true}
                                        downloadViolations={downloadViolations}
                                        isReadOnly={!isUserEditable}
                                        isInternal={true}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        entityId={entityInfo.entityId}
                                        parentTypeId={1}
                                        credentialTypeId={1}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                showSectionComplete={true}
                                backToSectionLink="/internal/efapp/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    )
}
export default Violations;