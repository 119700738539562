import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import InvoiceHistory from '../Card/InvoiceHistory';
import invoiceService from '../../../services/Shared/invoice.service';

function InvoicePage({ isIndividual }) {
    const [gridData, setGridData] = useState([]);
    useEffect(() => {
        invoiceService.getInvoiceHistory().then((response) => {
            setGridData(response.data);
        })
    }, [])

    return (
        <Container fluid>
            <h4 className="mb-3">Invoice History</h4>
            <InvoiceHistory isIndividual={isIndividual} gridData={gridData} />

        </Container>
    )
}
export default InvoicePage;