import React, { useEffect, useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'
import { Form } from 'rsuite';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/PeInformation'
import Information from '../../../../Shared/Card/Information';
import PersonalInformation from '../../../../Shared/Card/Contact/PersonalInformation';
import MailingAddress from '../../../../Shared/Card/Contact/MailingAddress';
import PublicAddress from '../../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';

import contactService from '../../../../../services/Internal/Reinstatement/PE/contact.service';
import reinstatementService from '../../../../../services/Internal/Reinstatement/PE/peReinstatement.service';
import Issues from '../../../../Shared/Card/Issues';
import dayjs from 'dayjs';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ContactInformationPage() {

    const { push } = useHistory();
    const [hasLoaded, setHasLoaded] = useState(false);
    const appId = useSelector(x => x.currentApplicationId);
    const sectionId = 1;
    const routes = useSelector(x => x.currentRoutes);
    const [formModel, setFormModel] = useState({});
    const [tabPane, setTabPane] = useState("personalInformation")
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Contact Information"
        }
    );
    useEffect(() => {
        contactService.getContactInformation(appId).then((response) => {
            var data = response.data;

            setFormModel({
                ...data,
                dateOfBirth: data.dateOfBirth != null ? dayjs(data.dateOfBirth).format('YYYY-MM-DD') : null,
                altEmail: data.alternateEmailAddress?.contactEmailAddress,
                primaryEmail: data.primaryEmailAddress?.contactEmailAddress,
                cellPhoneNum: data.cellPhone?.contactPhoneNumber,
                workPhoneNum: data.workPhone?.contactPhoneNumber,
                homePhoneNum: data.homePhone?.contactPhoneNumber
            })
            reinstatementService.peGetSection(appId, sectionId).then((response) => {
                setSectionData(response.data);
                setIsComplete(response.data.staffReviewComplete)
                reinstatementService.getIndividualReinstatement(appId).then((data) => {
                    setIsUserEditable(data.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: data.data?.credential?.formattedLicenseNumber,
                                applicationDate: data.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })
    }, [])


    const formSubmit = (validation, event) => {
        submitData(true);
    }

    const formSave = (event) => {
        submitData();
    }

    const submitData = (isForSubmit) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            reinstatementService.peMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                if (data.data) {
                    if (isForSubmit) {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    }
                    else {
                        Swal.fire("Saved!");
                    }
                }
            })
        }
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetReinstatementInformationContent("contact")} />
                <br />
                <Card>
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="personalInformation" title="Personal Information">
                                    <br />
                                    <br />
                                    <Form id="personalInformationForm" fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} >
                                        <PersonalInformation isUserEditable={isUserEditable} isReadOnly={true} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress isUserEditable={isUserEditable} isReadOnly={true} boundValues={formModel.mailingAddress} contactInfoLink={"/individual/contactInformation"} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress isUserEditable={isUserEditable} isReadOnly={true} boundValues={formModel.publicAddress} contactInfoLink={"/individual/contactInformation"} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                {
                    hasLoaded &&
                    <>
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                    </>
                }
                <FormActionButtons
                    isUserEditable={isUserEditable}
                    submitFormName="personalInformationForm"
                    backToSectionLink="/internal/pereinstatement/sections"
                    handleSave={formSave}
                    showSectionComplete={true}
                    isComplete={isComplete}
                    isCompleteCallback={setIsComplete}/>
            </Container>
        </div>
    );
}
export default ContactInformationPage