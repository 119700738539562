import axios from "axios";
import authHeader from '../../../authheader'

const PEREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPeReinstatement";

const getExperienceRecords = (reinstatementId) => {
    return axios.get(`${PEREINSTATEMENTURL}/experiencerecords`, { headers: authHeader(), params: { reinstatementId } });
}
const postExperienceRecord = (data) => {
    return axios.post(`${PEREINSTATEMENTURL}/experiencerecord`, data, { headers: authHeader() });
}
export default {
    getExperienceRecords,
    postExperienceRecord
}