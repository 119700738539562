import React, { useEffect, useState, useRef } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { List } from 'rsuite';
import RecentUpdateItem from './RecentUpdateItem';

function RecentUpdates({ updates }) {
    const [postUpdate, setPostUpdate] = useState(updates);
    const [page, setPage] = useState(1);
    const loader = useRef(null);


    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((page) => page + 1)
        }
    }

    return (
        <div className="dashboardRecentUpdates">
            <Card className="h-100">
                <Card.Header><h6>Recent Updates <i>(click to review)</i></h6></Card.Header>
                <Card.Body>
                    <div id="recentUpdatesInfiniteDiv">
                        {postUpdate.length > 0 ?
                            <List hover bordered >
                                {postUpdate.map((item, index) => {
                                    return <RecentUpdateItem data={item} key={index} />
                                })}
                            </List>
                            :
                            <Row className="text-center">
                                <Col><h2>No Recent Updates</h2></Col>
                            </Row>
                        }
                        <div className="loading" ref={loader}>

                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default RecentUpdates;