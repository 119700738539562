import React from 'react';
import { Nav, Navbar, Icon, Dropdown } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import Notification from './Notifications';
import AuthService from "../../../services/auth.service"
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function TopNav() {
    const { push } = useHistory();

    const logout = () => {
        AuthService.logout();
        window.location.href = "/";
    }
    const askLola = () => {
        push("/internal/askLola")
    }
    const userProfile = () => {
        push("/internal/userProfile")
    }
    const name = useSelector(x => x.userInfo.FormattedName);
    return (
        <div className='topnav'>
            <Navbar className='content'>            
                <Navbar.Body>
                    <Nav pullRight>
                        <Notification />
                            <Dropdown placement="bottomEnd" title={name}>
                            <Dropdown.Item onSelect={() => { userProfile() }} icon={<Icon icon="user" />}>User Profile</Dropdown.Item>
                            {/*<Dropdown.Item onSelect={() => {askLola()}} icon={<Icon icon="comments" />}>Ask LOLA</Dropdown.Item>*/}
                            <Dropdown.Item icon={<Icon icon="power-off" />} onSelect={logout}>Logout</Dropdown.Item>
                            </Dropdown>
                    </Nav>
                    </Navbar.Body>            
        </Navbar>
        </div>
    );
}

export default TopNav;