import axios from "axios";
import authHeader from '../../../authheader'

const PERENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualPeRenewal";

const getLicenseStatus = (renewalId) => {
    return axios.get(`${PERENEWALURL}/licensestatus`, { headers: authHeader(), params: { renewalId } });
}
const postLicenseStatus = (renewalId, renewingToId) => {
    return axios.post(`${PERENEWALURL}/licensestatus`, null, { headers: authHeader(), params: {renewalId, renewingToId} });
}
export default {
    getLicenseStatus,
    postLicenseStatus
}