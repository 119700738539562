import axios from "axios";
import authHeader from '../../authheader.js'


const RECENTQUEUEURL = process.env.REACT_APP_APIURL + "/api/RecentQueue";

const getRecentQueue = async () => {
    return axios.get(`${RECENTQUEUEURL}/getrecentqueue`, { headers: authHeader() });
};
const getRecentQueueById = async (id) => {
    return axios.get(`${RECENTQUEUEURL}/getrecentqueuebyid`, { headers: authHeader(), params: { id } });
};
const getAllRecentQueue = async () => {
    return axios.get(`${RECENTQUEUEURL}/getallrecentqueue`, { headers: authHeader() });
};
const updateRecentQueue = (recentQueue) => {
    return axios.post(`${RECENTQUEUEURL}/updaterecentqueue`, recentQueue, { headers: authHeader() });
}

export default {
    getRecentQueue,
    getRecentQueueById,
    getAllRecentQueue,
    updateRecentQueue

}