import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CommentsCard from '../Shared/CommentsCard';
import DocumentUploadsCard from '../Shared/DocumentUploadsCard';
import TopInformationCard from '../Shared/TopInformationCard';
import CredentialCard from './CredentialCard';
import GeneralInfoCard from './GeneralInfoCard';
import SupervisedByCard from './SupervisedByCard';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import recentContactService from '../../../../services/Internal/Recent/recentContacts.service'

function CredentialPage() {
    const [entityId, setEntityId] = useState(0);
    const [updateSupervisedCard, setUpdateSupervisedCard] = useState(0);
    const [updateCommentCard, setUpdateCommentCard] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.entityId) {
            setEntityId(params.entityId);
        }
        const recentContact = { entityId: params.entityId, isFirm: true, entityName: "" }
        recentContactService.updateRecentContacts(recentContact).then((data) => {
            var token = parseInt(localStorage.getItem('updateRecentContacts')) ? parseInt(localStorage.getItem('updateRecentContacts')) : 0;
            localStorage.setItem('updateRecentContacts', token + 1)
            dispatch({ type: "PERSIST_CURRENT_RECENT_CONTACTS", payload: parseInt(localStorage.getItem('updateRecentContacts')) });
        })
    }, []);
    const updateSupervisedData = () => { setUpdateSupervisedCard(updateSupervisedCard + 1) };
    const updateCommentData = () => { setUpdateCommentCard(updateCommentCard + 1) };


    return (
        <Container fluid>
            {entityId > 0 ?
                <>
                    <Row className="mb-3">
                        <Col><TopInformationCard entityId={entityId} /></Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><GeneralInfoCard entityId={entityId} /></Col>
                        <Col><CredentialCard entityId={entityId} updateSupervisedCardCallback={updateSupervisedData} updateCommentCardCallback={updateCommentData} /></Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><SupervisedByCard updateData={updateSupervisedCard} entityId={entityId} /></Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><CommentsCard entityId={entityId} updateData={updateCommentCard} /></Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><DocumentUploadsCard entityId={entityId} isIndividual={false} /></Col>
                    </Row>
                </> : <h2>No Entity ID provided. Please use the search.</h2>
            }
        </Container>
    )
}
export default CredentialPage