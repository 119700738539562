import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card, Row, Col, Badge, } from 'react-bootstrap'
import { ControlLabel, FormControl, FormGroup, HelpBlock, InputNumber, SelectPicker, Uploader } from 'rsuite'
import RedStar from '../../../../../Shared/GenericComponents/RedStar'
import YesNoRadioOption from '../../../../../Shared/RadioButton/YesNoRadioOption'
import DocumentUpload from '../../../../../Shared/Upload/DocumentUpload'
import QuizzesPage from './QuizzesPage'
function Courses(props) {
    const [metCpd, setMetCpd] = useState();
    const [hasCpdInMyNcees, setHasCpdInMyNcees] = useState();

    useEffect(() => {
        setMetCpd(props.metCpd);
        setHasCpdInMyNcees(props.hasCpdInMyNcees);
    }, [props])

    const requirementsNotMetReasonData = !props.isRetiredToActive ?
        [
            {
                label: "New License - Licensees are exempt from the CPD requirements during the calendar year in which they are initially licensed.", value: { id: 1, notMetCpdReason: "New License - Licensees are exempt from the CPD requirements during the calendar year in which they are initially licensed." }
            },
            {
                label: "Newly Reinstated - Licensees are exempt from the CPD requirements during the calendar year in which they are newly reinstated.", value: { id: 8, notMetCpdReason: "Newly Reinstated - Licensees are exempt from the CPD requirements during the calendar year in which they are newly reinstated." }
            },
            {
                label: "Inactive - Refer to Rule 2103 (A). By selecting this option, you are confirming that you are not offering or providing engineering/land surveying services in Louisiana.", value: { id: 2, notMetCpdReason: "Inactive - Refer to Rule 2103 (A). By selecting this option, you are confirming that you are not offering or providing engineering/land surveying services in Louisiana." }
            },
            {
                label: "Medical reasons - Refer to Rule 3109 (A) (3). (FILE UPLOAD REQUIRED)", value: { id: 4, notMetCpdReason: "Medical reasons - Refer to Rule 3109 (A) (3)." }
            },
            {
                label: "I am not in compliance with earning the requisite hours for this renewal - LAPELS staff will notify you of your next steps.", value: { id: 7, notMetCpdReason: "I am not in compliance with earning the requisite hours for this renewal - LAPELS staff will notify you of your next steps" }
            },
            {
                label: "I served on Active Duty in the U.S. military for a period of time exceeding 180 consecutive days in a calendar year - you must provide deployment documentation to be reviewed prior to being approved. (FILE UPLOAD REQUIRED)", value: { id: 10, notMetCpdReason: "I served on Active Duty in the U.S. military for a period of time exceeding 180 consecutive days in a calendar year - you must provide deployment documentation to be reviewed prior to being approved." }
            },
            {
                label: "I worked in a location for more than 180 days in a calendar year where compliance with the CPD requirements is impractical due to location, working hours, mail restrictions, etc. - Refer to Rule 3109 (A) (4). (FILE UPLOAD REQUIRED)", value: { id: 9, notMetCpdReason: "I worked in a location for more than 180 days in a calendar year where compliance with the CPD requirements is impractical due to location, working hours, mail restrictions, etc. - Refer to Rule 3109 (A) (4)." }
            },
        ]
        :
        [
            {
                label: "New License - Licensees are exempt from the CPD requirements during the calendar year in which they are initially licensed.", value: { id: 1, notMetCpdReason: "New License - Licensees are exempt from the CPD requirements during the calendar year in which they are initially licensed." }
            },
            {
                label: "Newly Reinstated - Licensees are exempt from the CPD requirements during the calendar year in which they are newly reinstated.", value: { id: 8, notMetCpdReason: "Newly Reinstated - Licensees are exempt from the CPD requirements during the calendar year in which they are newly reinstated." }
            },
            {
                label: "Inactive - Refer to Rule 2103 (A). By selecting this option, you are confirming that you are not offering or providing engineering/land surveying services in Louisiana.", value: { id: 2, notMetCpdReason: "Inactive - Refer to Rule 2103 (A). By selecting this option, you are confirming that you are not offering or providing engineering/land surveying services in Louisiana." }
            },
            {
                label: "Medical reasons - Refer to Rule 3109 (A) (3). (FILE UPLOAD REQUIRED)", value: { id: 4, notMetCpdReason: "Medical reasons - Refer to Rule 3109 (A) (3)." }
            },
            {
                label: "I am not in compliance with earning the requisite hours for this renewal - LAPELS staff will notify you of your next steps.", value: { id: 7, notMetCpdReason: "I am not in compliance with earning the requisite hours for this renewal - LAPELS staff will notify you of your next steps." }
            },
            {
                label: "I served on Active Duty in the U.S. military for a period of time exceeding 180 consecutive days in a calendar year - you must provide deployment documentation to be reviewed prior to being approved. (FILE UPLOAD REQUIRED)", value: { id: 10, notMetCpdReason: "I served on Active Duty in the U.S. military for a period of time exceeding 180 consecutive days in a calendar year - you must provide deployment documentation to be reviewed prior to being approved." }
            },
            {
                label: "I worked in a location for more than 180 days in a calendar year where compliance with the CPD requirements is impractical due to location, working hours, mail restrictions, etc. - Refer to Rule 3109 (A) (4). (FILE UPLOAD REQUIRED)", value: { id: 9, notMetCpdReason: "I worked in a location for more than 180 days in a calendar year where compliance with the CPD requirements is impractical due to location, working hours, mail restrictions, etc. - Refer to Rule 3109 (A) (4)." }
            },
            { label: "Other", value: { id: 6, notMetCpdReason: "Other" } }
        ];

    return (
        <Card>
            <Card.Header>
                <h5>CPD Requirements</h5>
            </Card.Header>
            <Card.Body>
                {props.isNonActiveToActive ?
                    <>
                        <p><b>§3121. CPD Reactivation</b></p>
                        <ol type="A">
                            <li>To become reactivated to an active status, a licensee in an expired, inactive, or retired status must have earned all PDHs which he/she would have been required to earn if he/she had been in an active status during the previous two calendar years as provided in §3105.</li>
                        </ol>
                        <p>Do you have a NCEES CPC locker?</p>
                        <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasCpdInMyNcees" />
                        {hasCpdInMyNcees ?
                            <p style={{ color: "red" }}>Please have NCEES release your CPC Locker to LAPELS (<a href="https://ncees.org/ncees-service/cpc-tracking/" target="_blank">https://ncees.org/ncees-service/cpc-tracking/</a>)</p>
                            :
                            hasCpdInMyNcees != null &&
                            <><p>You must complete the <a href="https://www.lapels.com/docs/CPD/CPD_Log_Changeofstatus_AR.pdf" target="_blank">LAPELS Approved Activity Log</a> outlining all PDHs claimed for the period of 24 months prior to the date of this reactivation,
                                along with copies of all supporting documentation substantiating the PDHs claimed and complementing the dates and information on your PDH Activity log. Download the log to your computer, complete, save, and upload as one file below with your certificates.
                                List your PDHs in chronological order on the log, and place on top of your certificates of completion.</p><br />
                                <DocumentUpload
                                    isUserEditable={!props.isReadOnly}
                                    credentialTypeId={6}
                                    sectionId={3}
                                    attachmentSection={"Cpd"}
                                    //appTypeId={}
                                    //refreshData={ }
                                    parentId={props.sectionId}
                                    parentTypeId={3}
                                    //entityId={props.entityId}
                                    fileUploadTitle={"CPD File Upload"}
                                />
                                <Row>
                                    <Col>
                                        <QuizzesPage
                                            sectionId={props.sectionId}
                                            isReadOnly={props.isReadOnly}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }
                    </>

                    :
                    <>
                        <p>
                            I have met the CPD requirements for Active status for calendar years {props.year1Number} and {props.year2Number}, and understand that, if I am selected for an audit,
                            I will be required to provide my Professional Development Hour (PDH) Activity Log and all supporting documentation to validate completion of all CPD requirements.
                            I further understand that failure to comply with such requirements or any false statements made by me could be cause for disciplinary action.
                            <br />
                            <br />
                            <b className="colorRed">NOTE: Licensees are exempt from the CPD requirements during the calendar year in which they are initially licensed.</b>

                        </p>
                        <br />
                        <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="metCpd" />

                        {metCpd === false &&

                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <ControlLabel>Please submit the reason why you have not met the requirements. Refer to the board rules here when selecting your exemption: <a href="https://www.lapels.com/LawsRules.html" target="_blank">https://www.lapels.com/LawsRules.html</a>. </ControlLabel>
                                            <FormControl
                                                accepter={SelectPicker}
                                                data={requirementsNotMetReasonData}
                                                searchable={false}
                                                placeholder="Select one"
                                                isReadOnly={props.isReadOnly}
                                                name="notMetCPDReason" block />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />

                                <DocumentUpload
                                    isUserEditable={!props.isReadOnly}
                                    credentialTypeId={6}
                                    sectionId={3}
                                    attachmentSection={"Cpd"}
                                    //appTypeId={}
                                    //refreshData={ }
                                    parentId={props.sectionId}
                                    parentTypeId={3}
                                    //entityId={props.entityId}
                                    warningMessage={<i className='colorRed'> (use ONLY for CPD exemption documentation, NOT for CPD logs or certificates)</i>}
                                    fileUploadTitle={"CPD File Upload"}
                                />
                            </>
                        }


                        <hr />
                        <p>
                            How many CPD hours did you earn in each calendar year? (enter a number below for each year)
                        </p>
                        <br />
                        <Row className="mb-3">
                            <Col>
                                <FormGroup>
                                    <ControlLabel><b>{props.year1Number}</b></ControlLabel>
                                    <FormControl isReadOnly={props.isReadOnly} accepter={InputNumber} min={0} name="year1Hours" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FormGroup>
                                    <ControlLabel><b>{props.year2Number}</b></ControlLabel>
                                    <FormControl isReadOnly={props.isReadOnly} accepter={InputNumber} min={0} name="year2Hours" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <ControlLabel><b>Explain Carry-over Hours, or reason(s) for CPD non-compliance</b></ControlLabel>
                                    <FormControl isReadOnly={props.isReadOnly} name="carryoverExplanation" componentClass="textarea" rows={5} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <hr />
                        <p>
                            The CPD hours listed herein are true and correct, and accurately state the professional development hours I have earned. I understand that I must make available all supporting documentation if requested by the Board.
                        </p>
                        <br />
                        <Row>
                            <Col sm={4}>
                                <h5>Electronic Signature of Applicant:<RedStar /></h5>
                                <span>(Type full name)</span>
                            </Col>
                            <Col sm={8}><FormControl isReadOnly={props.isReadOnly} name="electronicSignature" /></Col>
                        </Row>
                    </>
                }
            </Card.Body>
        </Card>
    )
}
export default Courses

