import axios from "axios";
import authHeader from '../../../authheader'

const EIAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualEiApplication";

const getExamination = (applicationId) => {
    return axios.get(`${EIAPPLICATIONURL}/examinationverification`, { headers: authHeader(), params: { applicationId } });
}
const postExamination = (data) => {
    return axios.post(`${EIAPPLICATIONURL}/examinationverification`, data, { headers: authHeader() });
}


export default {
    getExamination,
    postExamination
}