import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import References from '../../../../Shared/Card/References'
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import AddEditPersonalReferenceModal from '../../../../Shared/Modal/AddEditPersonalReferenceModal';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';

import personalReferenceService from '../../../../../services/Internal/Application/PLS/personalReferences.service'
import applicationService from '../../../../../services/Internal/Application/application.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import dayjs from 'dayjs';
import ExternalFormModal from '../../../../Shared/Modal/ExternalFormModal';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function PersonalReferencesPage() {
    const { push } = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [verifierModalShow, setVerifierModalShow] = useState(false);
    const [verifierId, setVerifierId] = useState(0);
    const entityId = useSelector(x => x.currentApplicationEntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 5 ? "By Comity" : appTypeId == 6 ? "By Exam" : "";
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 3;
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "References"
        }
    );
    const [modalData, setModalData] = useState({
        address: {
            state: null,
            country: null,
            city: '',
            postalCode: '',
            streetAddress: '',
            id: 0,
            parentId: 0,
            parentType: null,
            addressType: {
                id: 2,
                addressType: "Mailing Address"
            },
            entityId: 0,
            provinceRegion: "",
            county: null,
            userCrte: "",
            dateCrte: null,
        },
        firstOccasionFirmAddress: {
            state: null,
            country: null,
            city: '',
            postalCode: '',
            streetAddress: '',
            id: 0,
            parentId: 0,
            parentType: null,
            addressType: {
                id: 2,
                addressType: "Mailing Address"
            },
            entityId: 0,
            provinceRegion: "",
            county: null,
            userCrte: "",
            dateCrte: null,
        },
        secondOccasionFirmAddress: {
            state: null,
            country: null,
            city: '',
            postalCode: '',
            streetAddress: '',
            id: 0,
            parentId: 0,
            parentType: null,
            addressType: {
                id: 2,
                addressType: "Mailing Address"
            },
            entityId: 0,
            provinceRegion: "",
            county: null,
            userCrte: "",
            dateCrte: null,
        },
        applicationId: 0,
        dateCrte: null,
        deleted: false,
        email: "",
        entityId: 0,
        id: 0,
        name: "",
        userCrte: "",
        verificationStatus: null,
        membersOfSameFirm: null,
        notMembersOfSameFirmExplanation: '',
        dateFromFirstOccasion: null,
        dateToFirstOccasion: null,
        firstOccasionFirmName: '',
        firstOccasionPosition: '',
        firstOccasionReferencePosition: '',
        dateToSecondOccasion: null,
        dateFromSecondOccasion: null,
        secondOccasionFirmName: '',
        secondOccasionPosition: '',
        secondOccasionReferencePosition: '',
        modalType: "Add"
    });
    const [gridData, setGridData] = useState([]);
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        applicationService.plsGetSection(appId, 3).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete)
            applicationService.getIndividualApplication(appId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                });
            });

            updateGridData();
        })

    }, [])
    const updateGridData = () => {
        personalReferenceService.getPersonalReferences(appId).then((response) => {
            setHasLoaded(false);
            setGridData(response.data);
            setHasLoaded(true)
        });
    }
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.'),
        hasReferences: BooleanType().isRequired('This field is required.')
    });
    const openAddEditModal = (values) => {
        if (isUserEditable) {
            if (values.id) {
                var data = {
                    ...values,
                    dateToFirstOccasion: values.dateToFirstOccasion ? dayjs(values.dateToFirstOccasion).format('YYYY-MM') : null,
                    dateFromFirstOccasion: values.dateFromFirstOccasion ? dayjs(values.dateFromFirstOccasion).format('YYYY-MM') : null,
                    dateToSecondOccasion: values.dateToSecondOccasion ? dayjs(values.dateToSecondOccasion).format('YYYY-MM') : null,
                    dateFromSecondOccasion: values.dateFromSecondOccasion ? dayjs(values.dateFromSecondOccasion).format('YYYY-MM') : null,
                    modalType: "Edit"
                }
                setModalData(data);
            }
            else {
                setModalData({
                    address: {
                        state: null,
                        country: null,
                        city: '',
                        postalCode: '',
                        streetAddress: '',
                        id: 0,
                        parentId: 0,
                        parentType: null,
                        addressType: {
                            id: 2,
                            addressType: "Mailing Address"
                        },
                        entityId: entityId,
                        provinceRegion: "",
                        county: null,
                        userCrte: "",
                        dateCrte: null,
                    },
                    firstOccasionFirmAddress: {
                        state: null,
                        country: null,
                        city: '',
                        postalCode: '',
                        streetAddress: '',
                        id: 0,
                        parentId: 0,
                        parentType: null,
                        addressType: {
                            id: 2,
                            addressType: "Mailing Address"
                        },
                        entityId: entityId,
                        provinceRegion: "",
                        county: null,
                        userCrte: "",
                        dateCrte: null,
                    },
                    secondOccasionFirmAddress: {
                        state: null,
                        country: null,
                        city: '',
                        postalCode: '',
                        streetAddress: '',
                        id: 0,
                        parentId: 0,
                        parentType: null,
                        addressType: {
                            id: 2,
                            addressType: "Mailing Address"
                        },
                        entityId: entityId,
                        provinceRegion: "",
                        county: null,
                        userCrte: "",
                        dateCrte: null,
                    },
                    applicationId: appId,
                    dateCrte: null,
                    deleted: false,
                    email: "",
                    entityId: entityId,
                    id: 0,
                    name: "",
                    userCrte: "",
                    verificationStatus: null,
                    membersOfSameFirm: null,
                    notMembersOfSameFirmExplanation: '',
                    dateFromFirstOccasion: null,
                    dateToFirstOccasion: null,
                    firstOccasionFirmName: '',
                    firstOccasionPosition: '',
                    firstOccasionReferencePosition: '',
                    dateToSecondOccasion: null,
                    dateFromSecondOccasion: null,
                    secondOccasionFirmName: '',
                    secondOccasionPosition: '',
                    secondOccasionReferencePosition: '',
                    modalType: "Add"
                })
            }
            setModalShow(true);
        }
    }

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            applicationService.plsPostSection(appId, sectionId, sectionData.electronicSignature).then((signatureResponse) => {
                applicationService.plsPostSectionHasRef(appId, sectionId, sectionData.hasReferences).then((response) => {
                    applicationService.plsMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                        if (response.data && signatureResponse.data && data.data) // If both return something it's good to go
                            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)

                    })
                });
            });
        }
    }
    const openVerifierModal = (id) => {
        setVerifierId(id);
        setVerifierModalShow(true);
    }
    const formSave = (event) => {
        applicationService.plsPostSection(appId, sectionId, sectionData.electronicSignature).then(() => {
            applicationService.plsPostSectionHasRef(appId, sectionId, sectionData.hasReferences).then(() => {
                applicationService.plsMarkSectionComplete(appId, sectionId, isComplete).then(() => {
                    Swal.fire("Saved!");
                })
            })
        });
    }

    const downloadPersonalReferences = () => {
        Swal.fire({
            title: `Downloading PDF`,
            didOpen: () => {
                Swal.showLoading();
                personalReferenceService.downloadPersonalReferences(appId, "PLS").then((response) => {
                    var name = new String(response.headers['content-disposition']);
                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('target', '_blank');
                    link.setAttribute('download', name.slice(name.indexOf('filename=') + 9, name.lastIndexOf(';')));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    Swal.close();
                })
            },
            allowOutsideClick: false
        })
    }

    const deleteRow = (rowData) => {
        if (isUserEditable) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    personalReferenceService.postPersonalReference({ ...rowData, deleted: true }).then((response) => {
                        updateGridData();
                    })
                }
            })
        }
    }
    const emailReference = (rowData) => {
        if (isUserEditable) {
            personalReferenceService.sendPersonalReferenceEmail(rowData.id).then((response) => {
                if (response.data) {
                    Swal.fire(`You have successfully sent an email to ${rowData.name} at ${rowData.email}!`);
                    updateGridData();
                }
                else
                    Swal.fire(`Failed to send email: ${response.data.message}`);
            })
        }
    }
    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("personalReference")} />
                <br />
                <h4 className="mb-3">Personal References</h4>
                {hasLoaded ? <>
                    <AddEditPersonalReferenceModal
                        updategrid={updateGridData}
                        modaldata={modalData}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onsave={personalReferenceService.postPersonalReference}
                    />
                    {verifierModalShow &&
                        <ExternalFormModal show={verifierModalShow} onHide={() => setVerifierModalShow(false)} id={verifierId} type="referencesApplication" />
                    }
                    <Form model={model} fluid formValue={sectionData} onChange={data => setSectionData(data)} onSubmit={formSubmit}  >
                        <References
                            isReadOnly={!isUserEditable}
                            updateGrid={updateGridData}
                            openModal={openAddEditModal}
                            griddata={gridData}
                            hasReferences={sectionData.hasReferences}
                            deleteRow={deleteRow}
                            emailReference={emailReference}
                            openVerifierModal={openVerifierModal}
                            isInternal={true}
                            downloadPersonalReferences={downloadPersonalReferences}
                        />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true}
                            backToSectionLink="/internal/plsApp/sections"
                            handleSave={formSave} />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}

export default PersonalReferencesPage
