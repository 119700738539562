import axios from "axios";
import authHeader from '../../../authheader'

const LSIRENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualLsiRenewal";

const getViolation = (renewalId) => {
    return axios.get(`${LSIRENEWALURL}/violation`, { headers: authHeader(), params: { renewalId } });
}
const postViolation = (data) => {
    return axios.post(`${LSIRENEWALURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}