import React, { useState, useEffect } from 'react'
import { Button, Card, Row, Col } from 'react-bootstrap'
import { ControlLabel, FormControl, FormGroup, Icon, SelectPicker, Table, Uploader } from 'rsuite'
import Swal from 'sweetalert2';
import boardApprovalService from '../../../../../services/Internal/Application/PLS/boardApproval.service';
import attachmentService from '../../../../../services/Shared/attachment.service';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption'
import DocumentUpload from '../../../../Shared/Upload/DocumentUpload';

function BoardApproval(props) {
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }
    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }

    const getData = () => {
        return props.gridData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const formDataAppender = (passedKey, model, formData) => {
        for (let key in model) {
            if (typeof (model[key]) === 'object') {
                formDataAppender(`${passedKey}.${key}`, model[key], formData); // Recursive key for objects
            }
            else {
                if (passedKey)
                    formData.append(`${passedKey}.${key}`, model[key]);
                else
                    formData.append(`${key}`, model[key]);
            }
        }
    }
    const filteredData = getData();
    const removeFile = (id, rowData) => {
        Swal.fire({
            title: 'Are you sure you want to delete this file?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                attachmentService.deleteFile(id).then((response) => {
                    if (response.status == 200) {
                        if (rowData) {
                            var index = props.gridData.findIndex(x => x.id == rowData.id);
                            var tempModel = props.gridData;
                            tempModel[index].files = tempModel[index].files.filter(x => x.id !== id);
                            props.setGridData([...tempModel]);
                        }
                        else {
                            props.setFiles([...props.files.filter(x => x.id != id)])
                        }
                    }
                });
            }
        })
    }

    const openFile = (id) => {
        attachmentService.openFile(id);
    }
    return (
        <>

            <Card className='mb-3'>
                <Card.Header><h6>Board/Staff Attachments</h6></Card.Header>
                <Card.Body>
                    <DocumentUpload
                        isUserEditable={props.isUserEditable}
                        credentialTypeId={5}
                        sectionId={props.sectionId}
                        parentId={props.sectionDataId}
                        parentTypeId={7}
                        entityId={props.entityId}
                        appTypeId={props.appTypeId}
                        fileUploadTitle={"Uploads/Documents"}
                    />
                    <hr />
                    {/*<FormGroup>*/}
                    {/*    <ControlLabel><b>Education</b></ControlLabel>*/}
                    {/*    <FormControl*/}
                    {/*        accepter={SelectPicker}*/}
                    {/*        searchable={false}*/}
                    {/*        data={[*/}
                    {/*            { label: 'N/A', value: 'N/A' },*/}
                    {/*            { label: '4 Years', value: '4 Years' },*/}
                    {/*            { label: '8 Years', value: '8 Years' },*/}
                    {/*        ]}*/}
                    {/*        placeholder="Please Select"*/}
                    {/*        block*/}
                    {/*        name="education" />*/}
                    {/*</FormGroup>*/}
                    {/*<hr />*/}
                    <FormGroup>
                        <ControlLabel><b>Additional Comments</b></ControlLabel>
                        <FormControl
                            readOnly={!props.isUserEditable}
                            rows={5}
                            componentClass="textarea"
                            name="additionalComments" />
                    </FormGroup>
                    <Button className="float-right" onClick={props.openModal}>Package Documents</Button>
                </Card.Body>
            </Card>
            <Card className='mb-3'>
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>Board/Staff Responses</h6>
                        </Col>
                        {props.isUserEditable &&
                            <Col>
                                <Button className="float-right" onClick={props.openModal}>
                                    <Icon icon="plus" /> Add Response</Button>
                            </Col>}
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table wordWrap autoHeight data={filteredData}>
                        <Table.Column flexGrow={1} minwidth={100} verticalAlign="middle">
                            <Table.HeaderCell><b>Document</b></Table.HeaderCell>
                            <Table.Cell>
                                {rowData => {
                                    return (<>
                                        {rowData.files.map((item, i) => {
                                            return (
                                                <Row key={i} style={{ padding: '0' }}>
                                                    {props.isUserEditable &&
                                                        <Button onClick={() => { removeFile(item.id, rowData) }} title={`Delete ${item.fileName}`} variant="link" style={{ color: "red", textDecoration: 'none' }} size="sm"><b>X</b></Button>
                                                    }
                                                    <Button onClick={() => { openFile(item.id) }} variant="link" size="sm">{item.fileName}</Button>
                                                </Row>

                                            )
                                        })}
                                    </>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Date Sent</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.dateSent ? new Date(rowData.dateSent).toLocaleDateString() : '')}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} >
                            <Table.HeaderCell>
                                <b>Date Received</b>
                            </Table.HeaderCell>
                            <Table.Cell >{rowData => (rowData.dateReceived ? new Date(rowData.dateReceived).toLocaleDateString() : '')}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={2}>
                            <Table.HeaderCell>
                                <b>Board Member</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (`${rowData.boardMember.firstName} ${rowData.boardMember.middleName} ${rowData.boardMember.lastName}, ${rowData.boardMember.credentialSuffix}`)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Decision</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.boardMemberDecision?.boardMemberDecision)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Comments</b>
                            </Table.HeaderCell>
                            <Table.Cell dataKey="comments" />
                        </Table.Column>
                        {props.isUserEditable &&
                            <Table.Column flexGrow={1} minWidth={100}>
                                <Table.HeaderCell>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="id">
                                    {rowData => {
                                        function handleAction() {
                                            var tempData = { ...rowData, files: [] }
                                            props.openModal(tempData);
                                        }
                                        function handleDelete() {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                text: "You won't be able to revert this!",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Yes, delete it!'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    var formData = new FormData();
                                                    var dataToTransmit = {
                                                        ...rowData,
                                                        deleted: true,
                                                    }
                                                    formDataAppender('boardMemberResponse', dataToTransmit, formData);
                                                    for (var i = 0; i < rowData.files.length; i++) {
                                                        formData.append('files', rowData.files[i].blobFile);
                                                    }
                                                    boardApprovalService.postBoardResponse(formData).then((response) => {
                                                        if (response.status == 200) {
                                                            props.setGridData([...props.gridData.filter(x => x.id !== rowData.id)]);
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                        return (
                                            <span>
                                                <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                <Icon icon="trash" size="2x" onClick={handleDelete} style={{ cursor: "pointer" }} />
                                            </span>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>}
                    </Table>
                    <Table.Pagination lengthMenu={[
                        {
                            value: 10,
                            label: 10
                        },
                        {
                            value: 20,
                            label: 20
                        }
                    ]}
                        activePage={page}
                        displayLength={displayLength}
                        total={props.gridData.length}
                        onChangePage={handleChangePage}
                        onChangeLength={handleChangeLength} />
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <h6>Board/Staff Checklist</h6>
                </Card.Header>
                <Card.Body>
                    <p>Exams verified in Applicants MyNCEES Account (FS, PLS, State Specific (Louisiana Laws))?</p>
                    <YesNoRadioOption isReadOnly={!props.isUserEditable} radioName="examsVerifiedInMyNcees" />
                    <p>Board approval?</p>
                    <YesNoRadioOption isReadOnly={!props.isUserEditable} radioName="boardApproval" />
                </Card.Body>
            </Card>
        </>
    );
}

export default BoardApproval;