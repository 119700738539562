import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Tab, Tabs } from 'react-bootstrap'
import { Form, Schema } from 'rsuite';
import { GetGeneralContactInformationInstructions } from '../../../Shared/InformationContent/GeneralInformation'
import Information from '../../../Shared/Card/Information';
import Swal from 'sweetalert2';
import ContactService from '../../../../services/contact.service';
import MailingAddress from '../../../Shared/Card/Contact/MailingAddress';
import PersonalInformation from '../../../Shared/Card/Contact/PersonalInformation';
import PublicAddress from '../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import dayjs from 'dayjs';

function ContactInformationPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        ContactService.getContactInformation().then((response) => {
            var data = response.data;
            setFormModel({
                ...data,
                dateOfBirth: data.dateOfBirth != null ? dayjs(data.dateOfBirth).format('YYYY-MM-DD') : null,
                altEmail: data.alternateEmailAddress?.emailAddress,
                primaryEmail: data.primaryEmailAddress?.emailAddress,
                cellPhoneNum: data.cellPhone?.phoneNumber,
                workPhoneNum: data.workPhone?.phoneNumber,
                homePhoneNum: data.homePhone?.phoneNumber,
                myNceesNumber: data.myNceesNumber != null ? data.myNceesNumber.replace(/[^\d]*/g, "") : null,
            })
            setHasLoaded(true);
        })
    }, [])
    const [formModel, setFormModel] = useState({})
    const [showVisaGreenCardError, setShowVisaGreenCardError] = useState(false);

    const { StringType, BooleanType, ObjectType, NumberType } = Schema.Types;

    const model = Schema.Model({
        title: StringType().isRequired('This field is required.'),
        firstName: StringType().isRequired('This field is required.'),
        lastName: StringType().isRequired('This field is required.'),
        myNceesNumber: NumberType().pattern(/^[0-9]{7}/, "Please enter a valid My Ncees Number"),
        cellPhoneNum: StringType().isRequired('This field is required.').pattern(/^([(]\d{3}[)][ ]\d{3}[-]\d{4})|[0-9]{10}/, "Please enter a valid Phone Number"),
        dateOfBirth: StringType().isRequired('This field is required.'),
        birthCity: StringType().isRequired('This field is required.'),
        birthCountry: ObjectType().isRequired('This field is required.'),
        isUsCitizen: BooleanType().isRequired('This field is required.'),
        primaryEmail: StringType().isRequired('This field is required').isEmail('Please enter a valid email')
    });
    const addressModel = Schema.Model({
        streetAddress: StringType().isRequired('This field is required.'),
        country: ObjectType().isRequired('This field is required.'),
        city: StringType().isRequired('This field is required.'),
        postalCode: StringType().isRequired('This field is required.'),
        state: ObjectType().isRequired('This field is required.'),
        provinceRegion: StringType().isRequired('This field is required.'),
    })

    const [tabPane, setTabPane] = useState("personalInformation")

    const copyFromMailing = () => {
        setFormModel({
            ...formModel,
            publicAddress: formModel.mailingAddress == null ? {
                ...formModel.publicAddress,
                country: null,
                streetAddress: '',
                city: '',
                postalCode: '',
                state: null,
                provinceRegion: '',
            } : {
                ...formModel.publicAddress,
                country: formModel.mailingAddress?.country,
                streetAddress: formModel.mailingAddress?.streetAddress,
                city: formModel.mailingAddress?.city,
                postalCode: formModel.mailingAddress?.postalCode,
                state: formModel.mailingAddress?.state,
                provinceRegion: formModel.mailingAddress?.provinceRegion,
            }
        })
    }

    const formSubmit = (validation, event) => {
        setShowVisaGreenCardError(false);
        var hasErrors = false;
        setFormModel({ ...formModel, mailingAddress: { ...formModel.mailingAddress, showErrors: true }, publicAddress: { ...formModel.publicAddress, showErrors: true } });
        var validationObject = model.check(formModel);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                hasErrors = true;
                setTabPane("personalInformation")
                return;
            }
        }
        if (!formModel.isUsCitizen && (!formModel.visaGreenCardNumber || !formModel.visaGreenCardNumber.trim()))
        {
            setShowVisaGreenCardError(true);
            hasErrors = true;
            return;
        }
        if (!formModel.mailingAddress) {
            hasErrors = true;
            setTabPane("mailingAddress")
        }
        else {
            var mailingAddressValidationObject = addressModel.check(formModel.mailingAddress);
            for (const key in mailingAddressValidationObject) {
                if (mailingAddressValidationObject[key].hasError) {
                    if (!(key.includes("provinceRegion") && formModel.mailingAddress.country?.country === "United States") &&
                        !(key.includes("state") && formModel.mailingAddress.country?.country !== "United States")) {
                        setTabPane("mailingAddress")
                        document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                        hasErrors = true;
                        return;
                    }
                }
            }
            if (!formModel.publicAddress) {
                hasErrors = true;
                setTabPane("publicAddress")
            }
            else {
                var publicAddressValidationObject = addressModel.check(formModel.publicAddress);
                for (const key in publicAddressValidationObject) {
                    if (publicAddressValidationObject[key].hasError) {
                        if (!(key.includes("provinceRegion") && formModel.publicAddress.country?.country === "United States") &&
                            !(key.includes("state") && formModel.publicAddress.country?.country !== "United States")) {
                            setTabPane("publicAddress")
                            document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                            hasErrors = true;
                            return;
                        }
                    }
                }
            }
        }

        if (!hasErrors) {
            submitData();
        }
        //if i have gotten here then no real errors
        //navigate to errors

    }

    const submitData = () => {
        var dataToTransmit = {
            ...formModel,
            alternateEmailAddress: { ...formModel.alternateEmailAddress, emailAddress: formModel.altEmail },
            primaryEmailAddress: { ...formModel.primaryEmailAddress, emailAddress: formModel.primaryEmail },
            cellPhone: { ...formModel.cellPhone, phoneNumber: formModel.cellPhoneNum == '' ? null : formModel.cellPhoneNum },
            homePhone: { ...formModel.homePhone, phoneNumber: formModel.homePhoneNum == '' ? null : formModel.homePhoneNum },
            workPhone: { ...formModel.workPhone, phoneNumber: formModel.workPhoneNum == '' ? null : formModel.workPhoneNum },
        };
        ContactService.postContactInformation(dataToTransmit).then((response) => {
            if (response.data?.entityId)
                Swal.fire("Success!", "You have successfully saved your contact information.", "success");
        })
    }

    return (
        <div>
            <Container fluid>
                <strong>Contact Information</strong>
                <h4 className="mb-3">Contact</h4>
                <Information content={GetGeneralContactInformationInstructions()} />
                <br />
                <Card>
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="personalInformation" title="Personal Information">
                                    <br />
                                    <br />
                                    <Form id="personalInformationForm" model={model} fluid formValue={formModel} onChange={formModel => {
                                        setFormModel(formModel);
                                        if (!formModel.isUsCitizen && (!formModel.visaGreenCardNumber || !formModel.visaGreenCardNumber.trim())) {
                                            setShowVisaGreenCardError(true);
                                        }
                                        else { setShowVisaGreenCardError(false) };
                                    }} onSubmit={formSubmit} >
                                        <PersonalInformation isNonUsBirthState={formModel.birthCountry?.country != 'United States'} showVisaGreenCardError={showVisaGreenCardError} isExternal={true}/>
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        model={addressModel}
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress boundValues={formModel.mailingAddress} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        model={addressModel}
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress copyFromMailing={() => { copyFromMailing() }} boundValues={formModel.publicAddress} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                <Button style={{ float: 'right', marginTop: '1rem' }} type="submit" form="personalInformationForm">Save</Button>
            </Container>
        </div>
    );
}
export default ContactInformationPage