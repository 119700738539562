import axios from "axios";
import authHeader from '../../../authheader'

const PLSAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsApplication";

const getContactInformation = (applicationId) => {
    return axios.get(`${PLSAPPLICATIONURL}/contactinformation`, { headers: authHeader(), params: {applicationId} });
}
const postContactInformation = (applicationId, certDisplayName) => {
    return axios.post(`${PLSAPPLICATIONURL}/contactinformation`, null, { headers: authHeader(), params: { applicationId, certDisplayName } });
}
export default {
    getContactInformation,
    postContactInformation
}