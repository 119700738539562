import React, { useState, useEffect } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { ControlLabel, FormGroup, FormControl, RadioGroup, Radio, Input, InputPicker, AutoComplete, Icon } from 'rsuite';
import { getEfCredentials, getVfCredentials } from '../../../services/Shared/codeTable.service';
import RedStar from '../GenericComponents/RedStar';

function EmploymentLicensure({ type = "VF", isReadOnly, option, setOption, firmNumber, clearFirmNumber }) {
    const [creds, setCreds] = useState();
    const [contactLoading, setContactLoading] = useState(false);
    useEffect(() => {
        if (firmNumber?.licenseNumber)
            if (type == "VF") {
                getVfCredentials(firmNumber.licenseNumber).then(setCreds);
            }
            else {
                getEfCredentials(firmNumber.licenseNumber).then(setCreds);
            }
    }, [firmNumber])
    const updateCreds = (value) => {
        setContactLoading(true)
        if (type == "VF")
            getVfCredentials(value).then(setCreds);
        else if (type == "EF")
            getEfCredentials(value).then(setCreds);
        setContactLoading(false);
    }
    const test = () => {
        clearFirmNumber();
    }
    return (
        <Card>
            <Card.Header><h6>Employment Licensure</h6></Card.Header>
            <Card.Body>
                <FormGroup>
                    <Row>
                        <Col>
                            <ControlLabel><b>If approved for licensure in Louisiana, I will be employed by:<RedStar /></b></ControlLabel>
                            <FormControl name="licensedFirmType" accepter={RadioGroup} onChange={x => setOption(x)} readOnly={isReadOnly}>
                                <Radio value="licensed">A firm that is currently licensed in Louisiana. If firm has an expired status, please select second option.</Radio>
                                {option == "licensed" &&
                                    <FormControl
                                        accepter={InputPicker}
                                        block
                                        data={creds}
                                        name="firmNumber"
                                        placeholder="Firm #"
                                        cleanable={false}
                                        onOpen={test}
                                        onSearch={updateCreds}
                                        renderMenu={menu => {
                                            if (contactLoading) {
                                                return (
                                                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                        <Icon icon="spinner" spin /> Loading...
                                                    </p>
                                                );
                                            }
                                            return menu;
                                        }}
                                        readOnly={isReadOnly} />
                                }
                                <Radio value="unlicensed">A firm that is not licensed or expired in Louisiana. I understand a firm application or reinstate firm application must be submitted within three weeks of receiving or reinstating a Louisiana license.</Radio>
                                {option == "unlicensed" &&
                                    <>
                                        <FormGroup>
                                            <FormControl name="employedBy" placeholder="Firm/Employer Name" readOnly={isReadOnly} />
                                        </FormGroup>
                                    </>
                                }
                                <Radio value="soleProprietor">I am a Sole Proprietor. In the comment box below, please list the name you will be practicing under in the state of Louisiana if approved.</Radio>
                                {option == "soleProprietor" &&
                                    <ControlLabel className="ml-5">
                                        2301.C. Unless otherwise provided, sole proprietorships which are <b><u>not legal entities</u></b>, and which bear the full name of the owner who is a licensed professional are exempt from the application of this Chapter. Such sole proprietorships are not required to be licensed as engineering or land surveying firms with the board. Sole proprietorships which are not legal entities, and which do not bear the full name of the owner who is a licensed professional must be licensed with the board as an engineering or land surveying firm and must comply with all the provisions of this Chapter. For purposes of this §2301.C, legal entities is defined as domestic or foreign partnerships, associations, cooperatives, ventures, corporations, limited liability companies, limited liability partnerships, or other entities.
                                    </ControlLabel>
                                }
                                <Radio value="unemployed">I will be seeking employment if approved for licensure in the state of Louisiana.</Radio>
                            </FormControl>
                        </Col>
                    </Row>
                </FormGroup>
                <br />
                <FormGroup controlId="comment">
                    <ControlLabel><b>Comments:</b></ControlLabel>
                    <FormControl
                        readOnly={isReadOnly}
                        accepter={Input}
                        componentClass="textarea"
                        rows={5}
                        name="comment" />
                </FormGroup>
            </Card.Body>
        </Card>

    )
}
export default EmploymentLicensure