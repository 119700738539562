import React, { useEffect, useState, useRef } from 'react';
import { Card, ListGroup, Row, Col } from 'react-bootstrap'
import { List } from 'rsuite';
import RecentUpdateItem from './RecentUpdateItem';

function RecentUpdates({ updates }) {
    const [postUpdate, setPostUpdate] = useState(updates);
    const loader = useRef(null);
    return (
        <div className="dashboardRecentUpdates">
            <Card className="h-100">
                <Card.Header><h6>Recent Updates <i>(click to review)</i></h6></Card.Header>
                <Card.Body>
                    {updates && updates.length ?
                        <div id="recentUpdatesInfiniteDiv">
                            <List hover bordered >
                                {postUpdate.map((item, index) => {
                                    return <RecentUpdateItem data={item} key={index} />
                                })}
                            </List>
                            <div className="loading" ref={loader}>

                            </div>
                        </div>
                        :
                        <Row className="text-center">
                            <Col><h2>No Recent Updates</h2></Col>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default RecentUpdates;