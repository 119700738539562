import React from 'react'
import NumberFormat from 'react-number-format';

function CurrencyMask({ onChange, ...rest }) {
    return (
        <NumberFormat
            {...rest}
            prefix={"$"}
            fixedDecimalScale={true}
            thousandSeparator={true}
            decimalScale={2}
            className="form-control"
            onValueChange={(value) => onChange(value.floatValue)}
        />
    )
}
export default CurrencyMask;