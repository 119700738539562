import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { List } from 'rsuite';
import GenericBadge from '../../../Shared/Badge/GenericBadge';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';

function RecentUpdateItem({ data }) {
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(false);
    }, [])
    const goToPage = (data) => {
        if (data.issueId) {
            setIsLoading(true);
            window.open("firm/redirectToSection?issueId=" + data.issueId, "_self");
            setIsLoading(false);
        }
    }
    return (
        <List.Item onClick={() => { goToPage(data) }} className="recentUpdateItem">
            {loading ? <LoadingSpinner /> : <>
                <Row>
                    <Col className="text-left text-muted"><h5>{data.name}</h5></Col>
                    <Col className="text-right text-small">{new Date(data.date).toLocaleDateString()}</Col>
                </Row>
                <Row><Col>Message in &nbsp; <GenericBadge status={data.updateOwner} /></Col></Row>
                <Row className="mb-2"><Col>{data.text}</Col>
                    {data.isClosed && <Col sm="3" className="text-right text-small text-danger"><b>CLOSED</b></Col>}
                    {!data.isClosed && <Col sm="3" className="text-right text-small text-success"><b>OPEN</b></Col>}
                </Row></>
            }
        </List.Item>

    )
}

export default RecentUpdateItem;