import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import EducationRecord from './EducationRecords';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/EiInformation';
import { useSelector } from 'react-redux';
import reinstatementService from '../../../../../services/Internal/Reinstatement/EI/eiReinstatement.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function EducationPage() {
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Education"
        }
    );
    const [hideGrids, setHideGrids] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 16;
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        setHasLoaded(false)
        reinstatementService.eiGetSection(reinstatementId, sectionId).then((response) => {
            reinstatementService.getIndividualReinstatement(reinstatementId).then((reinstatementData) => {
                setIsUserEditable(reinstatementData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: reinstatementData.data?.credential?.formattedLicenseNumber,
                            applicationDate: reinstatementData.data?.dateSubmitted
                        })
                });
            });
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete)
            setHideGrids(response.data.hasMyNceesEducation ?? true);
            setHasLoaded(true);
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesEducation: BooleanType().isRequired('This field is required.')
    });
    const handleMyNceesEducationChange = (val) => {
        setHideGrids(val);
    }

    const submitForm = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            reinstatementService.eiPostSectionHasMyNceesEducation(reinstatementId, sectionId, sectionData.hasMyNceesEducation).then(() => {
                reinstatementService.eiMarkSectionComplete(reinstatementId, sectionId, isComplete).then((val) => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                })
            })
        }
    }
    return (
        <div className="eiReinstatementEducationPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />

                <Information content={GetReinstatementInformationContent("education")} />
                <br />
                {hasLoaded ? <>
                    <Form
                        model={model}
                        fluid
                        formValue={sectionData}
                        onSubmit={submitForm}
                        onChange={data => setSectionData(data)}
                    >
                        <EducationRecord
                            reinstatementId={reinstatementId}
                            isReadOnly={!isUserEditable}
                            hideGrid={hideGrids}
                            handleMyNceesEducationChange={handleMyNceesEducationChange}
                        />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true}
                            backToSectionLink="/internal/eiReinstatement/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default EducationPage;