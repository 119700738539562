import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Table } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../../Shared/Grid/GenericPagination';
import { sort_by } from '../../../Shared/HelperFunctions';

function CommentsCard(props) {
    const [comments, updateComments] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        updateCommentGrid();
    }, [])

    useEffect(() => {
        if (props.updateData > 0) {
            updateCommentGrid();
        }
    }, [props.updateData])

    const getData = () => {
        return comments.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();

    const updateCommentGrid = () => {
        credentialService.getComments(props.entityId).then((response) => {
            if (response.data) {
                updateComments(response.data.sort(sort_by('dateCrte', true)));
            }
        })
        setHasLoaded(true);
    }
    const addComment = () => {
        Swal.fire({
            title: 'Add comment here',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Add Comment',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                return !value && 'Please enter a comment.'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                credentialService.createComment(result.value, props.entityId).then((response) => {
                    if (response.data) {
                        updateCommentGrid();
                        Swal.fire(
                            'Comment Successfully Submitted!',
                            `You submitted this comment:  ${result.value}!`,
                            'success'
                        )
                    }
                });
            }
        })
    }
    const deleteComment = (commentId) => {
        Swal.fire({
            title: 'Are you sure you want to delete this comment?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                credentialService.deleteComment(commentId).then((response) => {
                    if (response.data) {
                        updateCommentGrid();
                        Swal.fire(
                            'Comment Successfully Deleted!',
                            ``,
                            'success'
                        )
                    }
                });
            }
        })
    }
    const updateComment = (commentId, comment) => {
        Swal.fire({
            title: 'Edit comment here',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off',
            },
            inputValue: comment,
            showCancelButton: true,
            confirmButtonText: 'Edit Comment',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                return !value && 'Please enter a comment.'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                credentialService.updateComment(commentId, result.value).then((response) => {
                    if (response.data) {
                        updateCommentGrid();
                        Swal.fire(
                            'Comment Successfully Updated!',
                            `The comment has changed to:  ${result.value}!`,
                            'success'
                        )
                    }
                });
            }
        })
    }

    return (
        <Card className="w-100">
            <Card.Header>
                <h5>Comments
                    <span><Button variant="primary" className="float-right" onClick={addComment}>Add Comment</Button></span>
                </h5>
            </Card.Header>
            <Card.Body>
                {hasLoaded ?
                    <>
                        <Table wordWrap autoHeight data={filteredData}>
                            <Table.Column width={150}>
                                <Table.HeaderCell>
                                    <b>Staff Member</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.staffMember)}</Table.Cell>
                            </Table.Column>
                            <Table.Column width={100}>
                                <Table.HeaderCell>
                                    <b>Date</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.commentDate ? new Date(rowData.commentDate).toLocaleDateString() : '')}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell>
                                    <b>Comment</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.commentText)}</Table.Cell>
                            </Table.Column>
                            <Table.Column width={150}>
                                <Table.HeaderCell>
                                    <b>Actions</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => {
                                    return (<>
                                        <Button onClick={() => updateComment(rowData.id, rowData.commentText)} variant='primary'>Edit</Button>&nbsp;
                                        <Button onClick={() => deleteComment(rowData.id)} variant='danger'>Delete</Button>
                                    </>);
                                }}</Table.Cell>
                            </Table.Column>
                        </Table>
                        <GenericPagination dataLength={comments.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                    </> : <LoadingSpinner />
                }
            </Card.Body>
        </Card >
    )
}


export default CommentsCard;