import axios from "axios";
import authHeader from '../../../authheader'

const peGetSection = (applicationId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPeApplication/section`, { headers: authHeader(), params: { applicationId, sectionId } });
}
const pePostSection = (applicationId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeApplication/signsection`, null, { headers: authHeader(), params: { applicationId, sectionId, electronicSignature } });
}
const pePostSectionHasRef = (applicationId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeApplication/setsectionhasreferences`, null, { headers: authHeader(), params: { applicationId, sectionId, hasReferences } });
}
const pePostSectionHasMyNceesEducation = (applicationId, sectionId, hasMyNceesEducation) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeApplication/setsectionhasmynceeseducation`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation } });
}

const pePostSectionHasExperience = (applicationId, sectionId, hasMyNceesExperience) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeApplication/setsectionhasmynceesexperience`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesExperience } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
    },
    {
        id: 3,
        sectionTitle: "Personal References",
        sectionContent: "Complete this section listing all personal references.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 5,
        sectionTitle: "Employment",
        sectionContent: "Verify Louisiana Employment Firm licensure",
    },
    {
        id: 17,
        sectionTitle: "Residency",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 7,
        sectionTitle: "Experience Record",
        sectionContent: "Include a GRAND TOTAL of all experience",
    },
    {
        id: 6,
        sectionTitle: "Quizzes",
        sectionContent: "Upload the confirmation for Online Laws and Rules, the Online Professionalism & Ethics and the Online Louisiana Standards of Practice for Boundary Surveys quizzes.",
    },
    {
        id: 16,
        sectionTitle: "Co-op Experience",
        sectionContent: "Complete this section listing all co-op experience.",
    },
    {
        id: 8,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 9,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    peGetSection,
    pePostSection,
    pePostSectionHasRef,
    pePostSectionHasMyNceesEducation,
    pePostSectionHasExperience
}