import React from 'react';
import { Card} from 'react-bootstrap'
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';

function BoardStaffChecklist(props) {
    return (
        <div className="vfBoardStaffChecklist">
            <Card>
                <Card.Header><h6>Board/Staff Checklist</h6></Card.Header>
                <Card.Body>
                    <p>Has the firm been verified by the Secretary of State?</p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="isFirmVerifiedBySecretaryOfState" />
                    <p>Have the Supervising Professional(s) been verified?</p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="areFirmSupervisingProfessionalsVerified" />
                    <p>Have the required quizzes been submitted for each of the Supervising Professional(s)?</p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="areFirmRequiredSupervisingProfessionalQuizzesSubmitted" />
                    <p>Have the disciplinary action(s) for firm(s) and Supervising Professional(s) been checked?</p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="areFirmDisciplinaryActionsChecked" />
                    <p>Do you have Board Approval?</p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="boardApproval" />

                </Card.Body>
            </Card>
        </div>
    )
}

export default BoardStaffChecklist;