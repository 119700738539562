import axios from "axios";
import authHeader from '../../../authheader'

const LSIRENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualLsiRenewal";

const getContactInformation = (renewalId) => {
    return axios.get(`${LSIRENEWALURL}/contactinformation`, { headers: authHeader(), params: { renewalId } });
}
export default {
    getContactInformation
}