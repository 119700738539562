import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/VFInformation';
import applicationService from '../../../../../services/Internal/Application/VF/vfApplication.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { Form, Schema } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import SupervisingProfessional from '../../../../Shared/Card/SupervisingProfessional';
import Swal from 'sweetalert2';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function SupervisingProfessionalPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 14;
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Surveying Firm Application",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Supervising Professional"
        }
    );
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasFirmSubmittedSupervisingProfessional: BooleanType().isRequired('This field is required.'),
        electronicSignature: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        setHasLoaded(false);
        applicationService.vfGetSection(store.currentApplicationId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete);
            applicationService.getFirmApplication(store.currentApplicationId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            })
        })


    }, [])
    const store = useSelector(x => x);

    const { push } = useHistory();
    const showAdditionalFields = sectionData.hasFirmSubmittedSupervisingProfessional;

    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (val) {
            applicationService.vfPostSection(store.currentApplicationId, sectionId, sectionData.electronicSignature).then((response) => {
                if (response.data) {
                    applicationService.vfPostSectionHasFirmSubmittedSupervisingProfessional(store.currentApplicationId, sectionId, sectionData.hasFirmSubmittedSupervisingProfessional).then((data) => {
                        if (data.data) {
                            applicationService.vfMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((markSectionResponse) => {
                                if (markSectionResponse.data)
                                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                                else
                                    Swal.fire("Failed to mark section complete");
                            });
                        }
                        else
                            Swal.fire("Failed to post supervising professional section");
                    })
                }
            })
        }
    }


    return (
        <div>
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={setSectionData} fluid formValue={sectionData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col> <Information content={GetAppInformationContent("supervisingProfessional")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <SupervisingProfessional
                                        isReadOnly={!isUserEditable}
                                        isInternal={true}
                                        entityId={entityInfo.entityId}
                                        prefix={"VF"}
                                        showAdditionalFields={showAdditionalFields}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        parentTypeId={1}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                showSectionComplete={true}
                                backToSectionLink="/internal/vfApp/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    );
}
export default SupervisingProfessionalPage