import React from 'react';
import { Col } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { Link } from 'react-router-dom';

// action url is javascript function
function GenericGridAction({ actionName, actionUrl }) {
    let action = "";
    switch (actionName) {
        case "Add":
            break;
        case "Edit":
            action = <FaIcons.FaEdit onClick={actionUrl} />
            break;
        case "Delete":
            action = <FaIcons.FaTrash onClick={actionUrl} />
            break;
        case "Start":
        case "Continue":
        case "View":
            if (actionUrl == "") {
                action = actionName;
            }
            else
                action = <Col as={Link} to={actionUrl} className="genericActionLink"><span>{actionName}</span></Col>
            break;
        default:
            action = <Col>{actionName}</Col>
            break;
    }
    return (
        action
    )
}

export default GenericGridAction;