import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Table } from 'rsuite';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import applicationService from '../../../../services/Internal/Application/application.service';
import firmApplicationService from '../../../../services/Internal/Application/VF/vfApplication.service';
import firmReinstatementService from '../../../../services/Internal/Reinstatement/VF/vfReinstatement.service';
import reinstatementService from '../../../../services/Internal/Reinstatement/reinstatement.service';
import renewalService from '../../../../services/Internal/Renewal/renewal.service';
import firmRenewalService from '../../../../services/Internal/Renewal/VF/vfRenewal.service';

import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import { sort_by } from '../../../Shared/HelperFunctions';
import { Badge } from 'react-bootstrap';

function CredentialHistoryTable(props) {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [displayLength, setDisplayLength] = useState(10);
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState("dateSubmitted");
    const [sortType, setSortType] = useState("desc");

    const dispatch = useDispatch();

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }
    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }

    const getData = () => {
        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    useEffect(() => {
        if (props.activeKey == 'credHistory') {
            updateTableWithEntityId()
        }
    }, [props.activeKey])
    useEffect(() => {
        if (props.activeKey == 'noCreds') {
            updateTableWithEntityId()
        }
    }, [props.activeKey])
    useEffect(() => {
        if (props.credential?.id == props.activeCredentialId && props.credential?.id)
            updateTable();
    }, [props.activeCredentialId])

    const viewRecord = (rowData) => {

        switch (rowData.credType) {
            case 'PLS'://PLS by Exam
            case 'PE': // Professional Engineer
            case "EI": // Engineering Intern
            case "LSI": //Land Surveying Intern
                switch (rowData.appType) {
                    case "Application":
                        applicationService.getIndividualApplication(rowData.id).then((response) => {
                            navigateAndSetCurrentApplication(response.data)
                        })
                        break;
                    case 'Reinstatement':
                        reinstatementService.getIndividualReinstatement(rowData.id).then((response) => {
                            navigateAndSetCurrentReinstatement(response.data)
                        })
                        break;
                    case 'Renewal':
                        renewalService.getIndividualRenewal(rowData.id).then((response) => {
                            navigateAndSetCurrentRenewal(response.data)
                        })
                        break;
                    default:
                        break;
                }
                break;
            case 'VF': // Surveying Firm
            case 'EF': // Engineering Firm
                switch (rowData.appType) {
                    case 'Application':
                        firmApplicationService.getFirmApplication(rowData.id).then((response) => {
                            navigateAndSetCurrentApplication(response.data)
                        })
                        break;
                    case 'Reinstatement':
                        firmReinstatementService.getFirmReinstatement(rowData.id).then((response) => {
                            navigateAndSetCurrentReinstatement(response.data)
                        })
                        break;
                    case 'Renewal':
                        firmRenewalService.getFirmRenewal(rowData.id).then((response) => {
                            navigateAndSetCurrentRenewal(response.data)
                        })
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }

    const navigateAndSetCurrentApplication = (rowData) => {
        localStorage.setItem("PERSIST_CURRENT_APP_ID", rowData.id);
        localStorage.setItem("PERSIST_CURRENT_APP_ENTITY_ID", rowData.entityId);
        localStorage.setItem("PERSIST_CURRENT_APP_TYPE_ID", rowData.applicationType.id);
        var baseLink = "";
        switch (rowData.applicationType?.id) {
            case 1:
                baseLink = "/internal/eiapp/";
                break;
            case 2:
                baseLink = "/internal/lsiapp/";
                break;
            case 3:
            case 4:
                baseLink = "/internal/peapp/";
                break;
            case 5:
            case 6:
                baseLink = "/internal/plsApp/";
                break;
            case 7:
                baseLink = "/internal/efapp/";
                break;
            case 8:
                baseLink = "/internal/vfApp/";
                break;
        }
        if (rowData?.sections?.length) {
            var route = [];
            for (var i = 0; i < rowData.sections.length; i++) {
                let sectionId = rowData.sections[i].sectionType.id
                var link = baseLink;
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 2:
                        link += "education"
                        break;
                    case 3:
                        link += "personalReferences"
                        break;
                    case 4:
                        link += "violations"
                        break;
                    case 5:
                        link += "employment"
                        break;
                    case 6:
                        link += "quizzes"
                        break;
                    case 7:
                        link += "experience"
                        break;
                    case 8:
                        link += "acknowledgement"
                        break;
                    case 9:
                        link += "review"
                        break;
                    case 10:
                        link += "boardApproval"
                        break;
                    case 11:
                        link += "examinationVerification";
                        break;
                    case 12:
                        link += "recommendation";
                        break;
                    case 13:
                        link += "waiver";
                        break;
                    case 14:
                        link += "supervisingProfessional";
                        break;
                    case 15:
                        link += "unlicensedActivity";
                        break;
                    case 16:
                        link += "coopexperience";
                        break;
                    case 17:
                        link += "residency";
                        break;
                    default:
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }
        }
        localStorage.setItem("PERSIST_CURRENT_ROUTE", JSON.stringify(route));
        window.open(baseLink + "sections");
    }
    const navigateAndSetCurrentReinstatement = (rowData) => {
        localStorage.setItem("PERSIST_CURRENT_APP_ID", rowData.id);
        localStorage.setItem("PERSIST_CURRENT_APP_ENTITY_ID", rowData.entityId);
        var baseLink = "";
        switch (rowData.credential?.credentialType?.id) {
            case 1:
                baseLink = "/internal/efreinstatement/";
                break;
            case 2:
                baseLink = "/internal/vfReinstatement/";
                break;
            case 3: // For when we have reinstatement
                baseLink = "/internal/eiReinstatement/";
                break;
            case 4:// For when we have reinstatement
                baseLink = "/internal/lsiReinstatement/";
                break;
            case 5:
                baseLink = "/internal/plsReinstatement/";
                break;
            case 6:
                baseLink = "/internal/pereinstatement/";
                break;
        }
        if (rowData?.sections?.length) {
            var route = [];
            for (var i = 0; i < rowData.sections.length; i++) {
                let sectionId = rowData.sections[i].sectionType.id
                var link = baseLink;
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 2:
                        link += "employment"
                        break;
                    case 3:
                        link += "violations"
                        break;
                    case 4:
                        link += "quizzes"
                        break;
                    case 5:
                        link += "cpdRequirements"
                        break;
                    case 6:
                        link += "personalReferences"
                        break;
                    case 7:
                        link += "experience"
                        break;
                    case 8:
                        link += "disclosure"
                        break;
                    case 9:
                        link += "acknowledgement"
                        break;
                    case 10:
                        link += "review"
                        break;
                    case 11:
                        link += "boardApproval"
                        break;
                    case 12:
                        link += "waiver"
                        break;
                    case 13:
                        link += "supervisingProfessional"
                        break;
                    case 14:
                        link += "unlicensedActivity"
                        break;
                    case 15:
                        link += "design"
                        break;
                    case 16:
                        link += "education"
                        break;
                    case 17:
                        link += "examination"
                        break;
                    default:
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }
            localStorage.setItem("PERSIST_CURRENT_ROUTE", JSON.stringify(route));
            window.open(baseLink + "sections");
        }
    }
    const navigateAndSetCurrentRenewal = (rowData) => {
        localStorage.setItem("PERSIST_CURRENT_APP_ID", rowData.id);
        localStorage.setItem("PERSIST_CURRENT_APP_ENTITY_ID", rowData.entityId);

        var baseLink = "";
        switch (rowData.credential?.credentialType?.id) {
            case 1:
                baseLink = "/internal/efrenewal/";
                break;
            case 2:
                baseLink = "/internal/vfRenewal/";
                break;
            case 3:
                baseLink = "/internal/eirenewal/";
                break;
            case 4:
                baseLink = "/internal/lsirenewal/";
                break;
            case 5:
                baseLink = "/internal/plsRenewal/";
                break;
            case 6:
                baseLink = "/internal/perenewal/";
                break;
        }
        if (rowData?.sections?.length) {
            var route = [];
            for (var i = 0; i < rowData.sections.length; i++) {
                let sectionId = rowData.sections[i].sectionType.id
                var link = baseLink;
                switch (sectionId) {
                    case 1:
                        link += "contactInformation"
                        break;
                    case 2:
                        link += "licenseStatus"
                        break;
                    case 3:
                        link += "cpdRequirements"
                        break;
                    case 4:
                        link += "violations"
                        break;
                    case 5:
                        link += "supervisingprofessional"
                        break;
                    case 6:
                        link += "privacy"
                        break;
                    case 7:
                        link += "acknowledgement"
                        break;
                    case 8:
                        link += "review"
                        break;
                    case 9:
                        link += "waiver"
                        break;
                    case 10:
                        link += "design"
                        break;
                    default:
                }
                route.push({ sectionId: rowData.sections[i].sectionType.id, route: link })
            }
            localStorage.setItem("PERSIST_CURRENT_ROUTE", JSON.stringify(route));
            window.open(baseLink + "sections");
        }
    }


    const updateTable = () => {
        credentialService.getCredHistory(props.credential?.entityId, props.credential?.id).then((data) => {
            setGridData(data.data);
            setHasLoaded(true);
        })
    }
    const updateTableWithEntityId = () => {
        if (props.pendingOnly) {
            credentialService.getPendingCreds(props.entityId).then((data) => {
                setGridData(data.data);
                setHasLoaded(true);
            })
        }
        else {
            credentialService.getEntityCredHistory(props.entityId).then((data) => {
                setGridData(data.data);
                setHasLoaded(true);
            })
        }
    }

    return (
        <>

            {
                hasLoaded ? <>
                    <Table
                        onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        bordered
                        wordWrap
                        autoHeight
                        data={getData()}>
                        <Table.Column flexGrow={1} minWidth={80}>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.Cell>{rowData => {
                                if (!rowData.isLegacy) {
                                    let link = `internal/${rowData.credType}${rowData.appType == "Application" ? "app" : rowData.appType}/sections`.toLowerCase();
                                    return (<a className="btn btn-primary" href={link} target="_blank" onClick={(e) => { e.preventDefault(); viewRecord(rowData) }}>View</a>)
                                }
                                else
                                    return (<></>);
                            }
                            }
                            </Table.Cell>
                        </Table.Column>
                        {props.showLicenseNumber &&
                            <Table.Column flexGrow={1} minWidth={125} sortable>
                                <Table.HeaderCell><b>Credential #</b></Table.HeaderCell>
                                <Table.Cell dataKey="licenseNumber">{rowData => rowData.licenseNumber}</Table.Cell>
                            </Table.Column>

                        }
                        <Table.Column flexGrow={1} minWidth={125} sortable>
                            <Table.HeaderCell><b>App Type</b></Table.HeaderCell>
                            <Table.Cell dataKey="appType">{rowData => {

                                if (props.activeKey == "noCreds")
                                    return (rowData.licenseNumber)
                                else
                                    return (rowData.appType)
                            }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} minWidth={125} sortable>
                            <Table.HeaderCell><b>Date Submitted</b></Table.HeaderCell>
                            <Table.Cell dataKey="dateSubmitted">{rowData => (rowData.dateSubmitted != null ? new Date(rowData.dateSubmitted).toLocaleDateString() : null)}</Table.Cell>
                        </Table.Column>
                        {props.activeKey == "credHistory" &&
                            <Table.Column flexGrow={1} minWidth={125} sortable>
                                <Table.HeaderCell><b>Prev. Expiration</b></Table.HeaderCell>
                                <Table.Cell dataKey="previousExpirationDate">{rowData => (rowData.previousExpirationDate != null ? new Date(rowData.previousExpirationDate).toLocaleDateString() : null)}</Table.Cell>
                            </Table.Column>
                        }
                        <Table.Column flexGrow={1} minWidth={125} sortable>
                            <Table.HeaderCell><b>Date Actioned</b></Table.HeaderCell>
                            <Table.Cell dataKey="lastActionDate">{rowData => (rowData.lastActionDate != null ? new Date(rowData.lastActionDate).toLocaleDateString() : null)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} minWidth={85} sortable>
                            <Table.HeaderCell><b>Status</b></Table.HeaderCell>
                            <Table.Cell dataKey="status">{rowData => {
                                if (rowData.status == 'Pending') {
                                    return (<Badge style={{ padding: '1em' }} variant={'danger'}>{rowData.status}</Badge>)
                                } else {
                                    return (rowData.status)
                                }
                            }}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1} minWidth={150} sortable>
                            <Table.HeaderCell><b>User Actioned</b></Table.HeaderCell>
                            <Table.Cell dataKey="userLastActioned">{rowData => (rowData.userLastActioned)}</Table.Cell>
                        </Table.Column>
                    </Table>
                    <Table.Pagination lengthMenu={[
                        {
                            value: 10,
                            label: 10
                        },
                        {
                            value: 20,
                            label: 20
                        }
                    ]}
                        activePage={page}
                        displayLength={displayLength}
                        total={gridData.length}
                        onChangePage={handleChangePage}
                        onChangeLength={handleChangeLength} /></>
                    : <LoadingSpinner />
            }
        </>
    )
}
export default CredentialHistoryTable;