import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';
import { Document, Page, pdfjs } from 'react-pdf';
import ReactToPrint from 'react-to-print';
import authHeader from '../../../services/authheader'
import { Button, Modal } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import '../Card/ReviewPdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function ExternalFormModal({ show, onHide, type, id }) {
    const [title, setTitle] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(true);
    const componentRef = useRef();
    const [file, setFile] = useState({
        url: "",
        httpHeaders: {}
    })
    const [zoom, setZoom] = useState(1.5);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        //setPdfLoaded(true);
    }
    useEffect(() => {
        if (show && id) {
            var header = authHeader();
            switch (type) {
                case "experienceApplication":
                    setFile({
                        url: process.env.REACT_APP_APIURL + "/Reports/GetApplicationExperienceVerifierForm?experienceId=" + id,
                        httpHeaders: header
                    })
                    break;
                case "experienceReinstatement":
                    setFile({
                        url: process.env.REACT_APP_APIURL + "/Reports/GetReinstatementExperienceVerifierForm?experienceId=" + id,
                        httpHeaders: header
                    })
                    break;
                case "referencesApplication":
                    setFile({
                        url: process.env.REACT_APP_APIURL + "/Reports/GetApplicationReferenceVerifierForm?referenceId=" + id,
                        httpHeaders: header
                    })
                    break;
                case "referencesReinstatement":
                    setFile({
                        url: process.env.REACT_APP_APIURL + "/Reports/GetReinstatementReferenceVerifierForm?referenceId=" + id,
                        httpHeaders: header
                    })
                    break;
                case "recommendationApplication":
                case "recommendationReinstatement":
                    setFile({
                        url: process.env.REACT_APP_APIURL + "/Reports/GetApplicationRecommendationVerifierForm?recommendationId=" + id,
                        httpHeaders: header
                    })
                    break;
                default:
            }
            setHasLoaded(true);
        }
    }, [show])
    return (
        <Modal
            show={show}
            onHide={onHide}
            animation={false}
            size="xl"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="viewer">
                    {hasLoaded &&
                        <>
                            <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={<><h4 className={"text-center"}>Loading PDF...</h4><div><LoadingSpinner /></div></>}
                                ref={componentRef}
                            >
                                {Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            scale={zoom}
                                        />
                                    ),
                                )}
                            </Document>
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ReactToPrint
                    trigger={() => (<Button variant='primary'><FaPrint /> Print</Button>)}
                    content={() => componentRef.current}
                />
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ExternalFormModal;