import * as dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { Form, FormGroup, ControlLabel, FormControl, InputPicker, SelectPicker, Toggle } from 'rsuite';
import Swal from 'sweetalert2';
import additionalInformationService from '../../../../../services/Internal/Credential/additionalInformation.service';
import { getAdditionalStructurals, getEngineerExamDiscipline, getFirstExamDiscipline, getStates } from '../../../../../services/Shared/codeTable.service';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
function AdditionalEngineeringInformationForm({ entityId, active }) {
    const [engineeringModel, setEngineeringModel] = useState({});
    const [states, setStates] = useState([])
    const [isAct2, setIsAct2] = useState(false)
    const [isByEndorsement, setIsByEndorsement] = useState(false)

    const [examDiscipline, setExamDiscipline] = useState([])
    const [appDiscipline, setAppDiscipline] = useState([])
    const [additionalStructurals, setAdditionalStructurals] = useState([])
    useEffect(() => {
        getStates().then(setStates);
        getEngineerExamDiscipline().then(setExamDiscipline);
        getFirstExamDiscipline().then(setAppDiscipline);
        getAdditionalStructurals().then(setAdditionalStructurals);
    }, [])
    useEffect(() => {
        if (active) {
            additionalInformationService.getEngineeringAdditionalInfo(entityId).then((response) => {
                if (response.data) {
                    setEngineeringModel({
                        ...response.data,
                        entityId: entityId,
                        eiCertificationDate: response.data.eiCertificationDate != null ? dayjs(response.data.eiCertificationDate).format('YYYY-MM-DD') : null,
                        feExamDate: response.data.feExamDate != null ? dayjs(response.data.feExamDate).format('YYYY-MM-DD') : null,
                        peExamDate: response.data.peExamDate != null ? dayjs(response.data.peExamDate).format('YYYY-MM-DD') : null,
                        peLicenseDate: response.data.peLicenseDate != null ? dayjs(response.data.peLicenseDate).format('YYYY-MM-DD') : null,
                        peExamDiscipline2RegistrationDate: response.data.peExamDiscipline2RegistrationDate != null ? dayjs(response.data.peExamDiscipline2RegistrationDate).format('YYYY-MM-DD') : null,
                        peExamDiscipline3RegistrationDate: response.data.peExamDiscipline3RegistrationDate != null ? dayjs(response.data.peExamDiscipline3RegistrationDate).format('YYYY-MM-DD') : null,
                        peExamDiscipline4RegistrationDate: response.data.peExamDiscipline4RegistrationDate != null ? dayjs(response.data.peExamDiscipline4RegistrationDate).format('YYYY-MM-DD') : null,
                        additionalStructuralExamDate1: response.data.additionalStructuralExamDate1 != null ? dayjs(response.data.additionalStructuralExamDate1).format('YYYY-MM-DD') : null,
                        additionalStructuralExamDate2: response.data.additionalStructuralExamDate2 != null ? dayjs(response.data.additionalStructuralExamDate2).format('YYYY-MM-DD') : null,
                    });
                    setIsAct2(response.data.isAct2);
                    setIsByEndorsement(response.data.isByEndorsement);
                }
            })
        }
    }, [active])

    const formSubmit = () => {
        var dataToTransmit = {
            ...engineeringModel,
            eiCertificationDate: engineeringModel.eiCertificationDate == '' ? null : engineeringModel.eiCertificationDate,
            feExamDate: engineeringModel.feExamDate == '' ? null : engineeringModel.feExamDate,
            peExamDate: engineeringModel.peExamDate == '' ? null : engineeringModel.peExamDate,
            peLicenseDate: engineeringModel.peLicenseDate == '' ? null : engineeringModel.peLicenseDate,
            peExamDiscipline2RegistrationDate: engineeringModel.peExamDiscipline2RegistrationDate == '' ? null : engineeringModel.peExamDiscipline2RegistrationDate,
            peExamDiscipline3RegistrationDate: engineeringModel.peExamDiscipline3RegistrationDate == '' ? null : engineeringModel.peExamDiscipline3RegistrationDate,
            peExamDiscipline4RegistrationDate: engineeringModel.peExamDiscipline4RegistrationDate == '' ? null : engineeringModel.peExamDiscipline4RegistrationDate,
            additionalStructuralExamDate1: engineeringModel.additionalStructuralExamDate1 == '' ? null : engineeringModel.additionalStructuralExamDate1,
            additionalStructuralExamDate2: engineeringModel.additionalStructuralExamDate2 == '' ? null : engineeringModel.additionalStructuralExamDate2,
            isAct2: isAct2,
            isByEndorsement: isByEndorsement
        }
        additionalInformationService.postEngineeringAdditionalInfo(dataToTransmit).then((response) => {
            if (response.data)
                Swal.fire('Success!', 'You have successfully saved additional information for this EI/PE.', 'success')
        })
    }
    const stateSearch = (userInput, label, dataItem) => {
        if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput)
            return true;
    }

    return (
        <Form fluid formValue={engineeringModel} onChange={setEngineeringModel} onSubmit={formSubmit}>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Application Disc.</b></ControlLabel>
                        <FormControl
                            name="applicationDiscipline"
                            data={appDiscipline}
                            accepter={SelectPicker}
                            searchable={false}
                            block
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Comity State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="comityState" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>EI Cert. Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="eiCertificationDate"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>EI Cert. #</b></ControlLabel>
                        <FormControl name="eiCertificationNumber" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>FE Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="feExamDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>FE Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="feExamState" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="peExamDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="peExamState" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE Lic State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="peLicenseState" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE Lic Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="peLicenseDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE Lic #</b></ControlLabel>
                        <FormControl name="peLicenseNumber" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE Exam Discipline</b></ControlLabel>
                        <FormControl
                            name="peExamDiscipline"
                            data={examDiscipline}
                            accepter={InputPicker}
                            searchable={true}
                            block
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Discipline 2</b></ControlLabel>
                        <FormControl
                            name="peExamDiscipline2"
                            data={appDiscipline}
                            accepter={InputPicker}
                            searchable={true}
                            block
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Dis. 2 Reg. Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="peExamDiscipline2RegistrationDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Dis. 2 Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="peExamDiscipline2ExamState" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Discipline 3</b></ControlLabel>
                        <FormControl
                            name="peExamDiscipline3"
                            data={appDiscipline}
                            accepter={InputPicker}
                            searchable={true}
                            block
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Dis. 3 Reg. Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="peExamDiscipline3RegistrationDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Dis. 3 Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="peExamDiscipline3ExamState" />

                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Discipline 4</b></ControlLabel>
                        <FormControl
                            name="peExamDiscipline4"
                            data={appDiscipline}
                            accepter={InputPicker}
                            searchable={true}
                            block
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Dis. 4 Reg. Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="peExamDiscipline4RegistrationDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Dis. 4 Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="peExamDiscipline4ExamState" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Model Law</b></ControlLabel>
                        <YesNoRadioOption radioName="isModelLaw" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Act 2</b></ControlLabel>
                        <FormControl accepter={Toggle} size={'lg'} checked={isAct2} onChange={(val) => { setIsAct2(val); if (val) setIsByEndorsement(false); }} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE by Endorsement</b></ControlLabel>
                        <FormControl accepter={Toggle} size={'lg'} checked={isByEndorsement} onChange={(val) => { setIsByEndorsement(val); if (val) setIsAct2(false); }} />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Additional Structural</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={additionalStructurals}
                            searchBy={stateSearch}
                            name="additionalStructural1" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Additional Structural Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="additionalStructuralExamDate1"
                        />
                    </FormGroup>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Additional Structural</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={additionalStructurals}
                            searchBy={stateSearch}
                            name="additionalStructural2" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Additional Structural Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="additionalStructuralExamDate2"
                        />
                    </FormGroup>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col className="text-right"><Button type="submit">Save</Button></Col>
            </Row>
        </Form>
    )
}
export default AdditionalEngineeringInformationForm;