import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import EducationRecord from './EducationRecords';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/EiInformation';
import { useSelector } from 'react-redux';
import reinstatementService from '../../../../../../services/Public/Reinstatement/EI/eiReinstatement.service';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../../Shared/Card/Issues';

function EducationPage() {
    const [hideGrids, setHideGrids] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 16;
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        setHasLoaded(false)
        reinstatementService.eiGetSection(reinstatementId, sectionId).then((response) => {
            setSectionData(response.data);
            setHideGrids(response.data.hasMyNceesEducation ?? true);
            setHasLoaded(true);
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesEducation: BooleanType().isRequired('This field is required.')
    });
    const handleMyNceesEducationChange = (val) => {
        setHideGrids(val);
    }

    const submitForm = (validation, event) => {
        if (!sectionData.isUserEditable)
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
        else {
            if (validation) {
                reinstatementService.eiPostSectionHasMyNceesEducation(reinstatementId, sectionId, sectionData.hasMyNceesEducation).then(() => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                })
            }
        }
    }
    return (
        <div className="eiReinstatementEducationPage">
            <Container fluid>
                <strong>Engineer Intern Reinstatement</strong>
                <h4 className="mb-3">Education</h4>
                <Information content={GetReinstatementInformationContent("education")} />
                <br />
                {hasLoaded ? <>
                    <Form
                        readOnly={!sectionData.isUserEditable}
                        model={model}
                        fluid
                        formValue={sectionData}
                        onSubmit={submitForm}
                        onChange={data => setSectionData(data)}
                    >
                        <EducationRecord
                            reinstatementId={reinstatementId}
                            isReadOnly={!sectionData.isUserEditable}
                            hideGrid={hideGrids}
                            handleMyNceesEducationChange={handleMyNceesEducationChange}
                        />
                        <br />
                        <Issues isUserEditable={true} type={2} sectionId={sectionData.id} />
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/eiReinstatement/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default EducationPage;