import axios from "axios";
import authHeader from '../../../authheader'

const vfGetSection = (applicationId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/FirmVfApplication/section`, { headers: authHeader(), params: { applicationId, sectionId } });
}

const vfPostSection = (applicationId, sectionId, electronicSignature) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmVfApplication/signsection`, null, { headers: authHeader(), params: { applicationId, sectionId, electronicSignature } });
}

const vfPostSectionHasWordOffering = (applicationId, sectionId, hasWordOfferingProfessionalServices) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmVfApplication/setsectionhaswordofferingprofessionalservices`, null, { headers: authHeader(), params: { applicationId, sectionId, hasWordOfferingProfessionalServices } });
}
const vfPostSectionHasFirmSubmittedSupervisingProfessional = (applicationId, sectionId, hasFirmSubmittedSupervisingProfessional) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmVfApplication/setsectionhasfirmsubmittedsupervisingprofessional`, null, { headers: authHeader(), params: { applicationId, sectionId, hasFirmSubmittedSupervisingProfessional } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Verify personal and contact information is correct and accurate.",
    },
    {
        id: 13,
        sectionTitle: "Waiver Request/Certificate of Good Standing Forms",
        sectionContent: "Verify information requested and upload any supporting documents.",
    },
    {
        id: 14,
        sectionTitle: "Firm Supervising Professionals",
        sectionContent: "Certify any supervising professionals working on behalf of a firm.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 15,
        sectionTitle: "Unlicensed Activity",
        sectionContent: "Question if the firm has ever practiced or offered to practice engineering or land surveying in Louisiana.",
    },
    {
        id: 8,
        sectionTitle: "Acknowledgment",
        sectionContent: "Verify you have read the Rules for Professional Engineers and Land Surveyors (LAC Title 46, Part LXI), and agree.",
    },
    {
        id: 9,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    vfGetSection,
    vfPostSection,
    vfPostSectionHasWordOffering,
    vfPostSectionHasFirmSubmittedSupervisingProfessional
}