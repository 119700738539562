import React from "react"
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import * as FaIcons from 'react-icons/fa';
import attachmentService from "../../../services/Shared/attachment.service";

// Will need function for deleting later on the trash icon
function GenericAttachmentGrid({ data, theadStyle, tStyle, deleteFileCallback, isSync }) {
    var hasType = false;
    var hideActions = data.find(item => item.hideDelete == false || item.hideDelete == undefined);
    let rows = data.map((item, i) => {
        if (item.attachmentType) {
            hasType = true;
        }
        return (
            <tr key={item.id} id={i}>
                {(isSync || isNaN(item.id)) ?
                    <td>{item.file}</td> :
                    <td><Button variant='link' onClick={() => { attachmentService.openFile(item.id) }} >{item.file}</Button></td>}
                <td>{item.timestamp}</td>
                {item.attachmentType &&
                    <td>{item.attachmentType}</td>
                }
                {!item.hideDelete && <td><FaIcons.FaTrash onClick={() => { deleteFileCallback(item.id) }} /></td>}
            </tr>
        )
    })

    return (
        <Table hover className={tStyle}>
            <thead className={theadStyle}>
                <tr>
                    <th>File</th>
                    <th>Date | Timestamp</th>
                    {hasType &&
                        <th>Type</th>
                    }
                    {hideActions &&
                        <th>Action(s)</th>
                    }
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    );
}

export default GenericAttachmentGrid;