import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { InputPicker, SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup, InputNumber } from 'rsuite';
import Swal from 'sweetalert2';
import dashboardService from '../../../../services/Public/dashboard.service';
import { getStates, getFirmTypes } from '../../../../services/Shared/codeTable.service'
import RedStar from '../../../Shared/GenericComponents/RedStar';


function EditLockedContactInfoModal(props) {
    const [recordModel, setRecordModel] = useState({
        organizationType: "",
        domicileState: "",
        dateOfFormation: "",
    })
    const [states, setStates] = useState([])
    const [firmTypes, setFirmTypes] = useState([]);

    useEffect(() => {
        getStates().then(setStates);
        getFirmTypes().then(setFirmTypes);
        setRecordModel(props.modaldata);     
    }, [props.modaldata])

    const { StringType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        domicileState: ObjectType().isRequired("This field is required."),
        organizationType: ObjectType().isRequired("This field is required."),
        dateOfFormation: StringType().isRequired("This field is required."),
    })
    const formSubmit = (validation, event) => {
        if (validation)//and other validation
        {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to update this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Save it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    var dataToTransmit = { ...recordModel };//do mapping if needed
                    dashboardService.postFirmLockedContactInformation(dataToTransmit).then((response) => {
                        if (response.data) {
                            props.onHide();

                        }
                    })
                }
            })
        }
    }


    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <b className='text-danger'>Required Contact Information</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="recordForm" fluid model={model} formValue={recordModel} onChange={recordModel => setRecordModel(recordModel)} onSubmit={formSubmit} >
                    {!props.modaldata.organizationType &&
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Type of Firm&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        block={true}
                                        name="organizationType"
                                        accepter={InputPicker}
                                        data={firmTypes}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                    {!props.modaldata.domicileState &&
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Domicile Firm State&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        block={true}
                                        name="domicileState"
                                        searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                        accepter={InputPicker}
                                        data={states}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                    {!props.modaldata.dateOfFormation &&
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Date Formed/Incorporated&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        name="dateOfFormation"
                                        type="date"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="recordForm" variant="success">Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditLockedContactInfoModal;