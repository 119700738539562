import React, { useState } from 'react';
import { Container, Header, Content, Sidebar } from 'rsuite';
import RenderRoutes from '../../Routes/renderroutes'
import INDIVIDUAL_ROUTES from '../../Routes/individualroutes';
import PublicIndividualSidebar from '../Individual/Sidebar/Sidebar';
import PublicTopNavbar from '../Individual/TopNavbar/TopNavbar'


function PublicIndividual() {
    return (
        <Container>
            <Sidebar>
                <PublicIndividualSidebar />
            </Sidebar>
            <Container>
                <Header>
                    <PublicTopNavbar />
                </Header>
                <Content className="mainContent">
                    <RenderRoutes routes={INDIVIDUAL_ROUTES} />
                </Content>
            </Container>
        </Container>
    );
}

export default PublicIndividual;