import { loremParagraphs } from "../../../fakeapi";
import React, { useState, useEffect } from "react";
import RedStar from "../GenericComponents/RedStar";

export const GetAppInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("application");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <ul>
                    <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
                    <li>Mandatory fields are indicated with "<RedStar />" (asterisk) to the left of the field</li>
                    <li>Louisiana Revised Statute 31:23 requires that every application by an individual for a professional license, permit or certificate shall require the applicant's social security number.
                        Disclosure of your social security number is mandatory for this purpose and for the purpose of establishing, modifying or enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support under La. R.S. 46:236.1.1, et seq.
                        Failure to provide your social security number for these mandatory purposes will result in the denial of your licensure, permit or certificate application.
                        Disclosure of your social security number is voluntary for disclosure to other state regulatory agencies, testing and examination vendors, law enforcement agencies, and other private federations and associations involved in professional regulation.
                        Such disclosure is for identification purposes only. Your social security number will not be released for any other purpose not provided by law.</li>
                    <li>Complete the following sections</li>
                    <ul>
                        <li>Personal Information</li>
                        <li>Verify Mailing Address</li>
                        <li>Verify Public Address</li>
                        <li>Ensure you add "lapels.com" to your "Safe Senders" list in your email application and/or (if applicable) in your network firewall.</li>
                    </ul>
                </ul>
            );
            break;
        case "education":
            return (
                <ul>
                    {/*<li>If you select <b>No</b>, Indicate each college or university in chronological order where a degree was conferred or is in progress, or where you have completed surveying coursework. Include the time spent at each institution and if graduated, the date conferred, the degree received, the date received, and the major as shown on your transcripts.</li>*/}
                    <li>Please have your transcripts verified on your MyNCEES account.</li>
                    {/*<li>If your school is not listed below, please send an email to <a href="mailto:cande@lapels.com">cande@lapels.com</a> with your school's information so it can be added to the list. </li>*/}
                </ul>
            )
            break;
        case "employment":
            return (
                <ul>
                    <li>Please indicate your employment status in Louisiana below.</li>
                </ul>

            )
            break;
        case "experience":
            return (
                <ul>
                    <li>If you have NCEES records, please authorize NCEES to transmit your records to LAPELS;</li>
                    <li>If you do not have a NCEES record, you must complete the sections below.</li>
                    <ul>
                        <li>Please note, <span className="colorRed">ALL TIME SINCE GRADUATION WITH A BS DEGREE MUST BE DOCUMENTED</span>; You must include military, illness, unemployment or time spent completing an additional college degree, etc., on an Experience Record entry.</li>
                    </ul>

                    <li>Any time gaps will result in an incomplete application. Applicant must comply with LAPELS rule 1505 (C) <a target="_blank" href="https://www.lapels.com/docs/Laws_and_Rules/Board_Rules.pdf">Land Surveying Work Experience</a></li>
                    <li>Please make sure all surveying experience is verified including at least 4 years verified by a licensed PLS.</li>
                    <ul>
                        <li>NOTE: The verifer needs to be licensed during the time period they are verifying.</li>
                    </ul>
                </ul>
            )
            break;
        case "personalReference":
            return (
                <>
                    <p>
                        If you select <b>no</b>, please list below your <b>five personal references</b> and attest below to rule 1701 (B):
                        <br />
                        <br />
                        LAPELS rule §1701 (B):  Applications for licensure as a professional engineer or professional land surveyor shall be completed on
                        the most current forms developed by the board. The application shall contain statements showing the applicant's qualifications, and
                        the names and addresses of five personal references who recommend the applicant for licensure. None of the five personal references
                        can be an immediate family member or business associate of the applicant. For purposes of this <b>§1701.B</b>, immediate family member is
                        defined as a spouse, child, spouse of a child, sibling, spouse of a sibling, sibling of a spouse, parent, parent of a spouse,
                        stepparent or stepchild. For purposes of this <b>§1701.B</b>, business associate is defined as a subordinate of the applicant, or a
                        consultant or contractor who provides goods or services to the applicant or to a business, entity or agency in which the applicant
                        is an owner, member, officer, director, trustee, partner, principal, manager, employee, associate, consultant or contractor...
                        Three or more of the five personal references furnished by an applicant
                        for licensure as a professional land surveyor shall be professional land surveyors holding valid licenses to engage in the practice of
                        land surveying issued to them by proper authority of a state, territory, or possession of the United States, or the District of Columbia.
                        Applicants for licensure as a professional engineer or professional land surveyor must also successfully complete the board’s Louisiana
                        laws and rules quiz and Louisiana ethics and professionalism quiz prior to licensure. Additionally, applicants for licensure as a
                        professional land surveyor must successfully complete the board’s Louisiana standards of practice for boundary surveys quiz prior to licensure.

                    </p>
                    <p><b className="colorRed">
                        Five verified references are required - 3 of the 5 must have an active PLS license.
                    </b></p>
                </>
            )
            break;
        case "quiz":
            return (
                <ul>
                    <li>You must complete the online quizzes, with a minimum score of 90%:</li>
                    <ul>
                        <li>Louisiana Laws and Rules Quiz</li>
                        <li>Louisiana Professionalism and Ethics Quiz</li>
                        <li>Louisiana Standards of Practice for Boundary Surveys Quiz</li>
                    </ul>
                    <li>Quizzes can be found here:  <a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a></li>
                    <li>You will receive a certificate by email once you have achieved the requisite score.</li>
                    <li>You must upload each certificate below.</li>
                    <li>Select each quiz type from the dropdown below and click "Upload".</li>
                </ul>

            )
            break;
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Application” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        case "violations":
            return (
                <p>
                    In the case of answering “Yes” to the question below about unauthorized or unlicensed practice (Question #1), explain in detail the facts surrounding such practicing or offering to practice surveying in Louisiana and submit a list of all projects/jobs on which you offered to provide or provided surveying services in Louisiana during such period.
                    Such list should include, for each such project/job, offer date, start date, completion date, client name, project/job name, project/job location and project/job descriptions. Upload additional pages if necessary.
                    <br />
                    <br />
                    In the case of answering “Yes” to any of the questions below other than the question about unauthorized or unlicensed practice, provide the date(s) and pertinent facts of the case(s) or proceeding(s), including the final disposition (even if the information was previously provided to the Board).
                    Upload additional pages if necessary.
                </p>
            )
        case "acknowledgement":
            return (
                <>
                    <p>
                        I, the applicant named in this application, have read the contents hereof, and to the best of my knowledge, information and belief the
                        statements and information in this application are true and correct in substance and effect and are made in good faith. I have not
                        omitted any information requested in this application. I am aware that such an omission or untrue response may constitute fraud or deceit,
                        material misstatement, perjury or the giving of false or forged evidence under Louisiana Revised Statutes 37:698 and 37:700. I understand
                        and agree that any responses verifying my education, personal references, and experience shall be considered privileged and confidential
                        and that this information was not reviewed by me nor will it be available for my inspection or review in the future.
                    </p>
                    <ul>
                        <li>It is your responsibility to check your LOLA account frequently for any pending issues. Ensure you add "lapels.com" to your "Safe Senders" list in your email application and/or (if applicable) in your network firewall.</li>
                        <li>Once we receive a complete application, with no pending issues, process time may take 6-8 weeks.</li>
                    </ul>
                </>
            )
        default:
            return (
                <>
                    {
                        //<h5 className="colorRed">This is sample text</h5>
                        //<span>{fakeData}</span>
                    }
                </>
            )
            break;
    }
}

export const GetRenewalInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("renewal");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <ul>
                    <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
                    <li>Mandatory fields are indicated with "<RedStar />" (asterisk) to the left of the field</li>
                    <li>Louisiana Revised Statute 37:23 requires that every application by an individual for a professional license, permit or certificate shall require the applicant's social security number.
                        Disclosure of your social security number is mandatory for this purpose and for the purpose of establishing, modifying or enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support under La. R.S. 46:236.1.1, et seq.
                        Failure to provide your social security number for these mandatory purposes will result in the denial of your licensure, permit or certificate application.
                        Disclosure of your social security number is voluntary for disclosure to other state regulatory agencies, testing and examination vendors, law enforcement agencies, and other private federations and associations involved in professional regulation.
                        Such disclosure is for identification purposes only. Your social security number will not be released for any other purpose not provided by law.</li>
                    <li>Complete the following sections</li>
                    <ul>
                        <li>Personal Information – ensure you put LAPELS emails on your safe sender list</li>
                        <li>Verify Mailing Address</li>
                        <li>Verify Public Address</li>
                    </ul>
                </ul>
            );
        case "cpdRequirements":
            return (
                <p className="mb-2">
                    <b className="text-uppercase">CPD Requirements for Engineers and Surveyors:</b>
                    <br />
                    <br />
                    A <b>Professional Land Surveyor (PLS)</b> must complete 8 CPD hours each calendar year (January - December). Of the 8 hours, one hour must be in the Standards of Practice, and one hour must be in Professional Ethics. In the event you have earned more than 8 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year. You may not earn more than 8 CPD hours in a calendar day.
                    <br />
                    <br />
                    A <b>Professional Engineer (PE)</b> must complete 15 CPD hours each calendar year (January - December). Of the 15 hours, one hour must be in Professional Ethics. If you design buildings or building systems, of the 15 hours, four hours must be in life safety/ADA compliance. In the event you have earned more than 15 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year. You may not earn more than 8 CPD hours in a calendar day.
                    <br />
                    <br />
                    A <b>PE/PLS dual licensee</b> must complete 15 CPD hours each calendar year (January - December). Of the 15 hours, five hours must be earned in each profession, one hour must be in Professional Ethics, and one hour must be in the Standards of Practice. If you design buildings or building systems, of the 15 hours, four hours must be in life safety/ADA compliance. In the event you have earned more than 15 CPD hours in a calendar year, you may carry over a maximum of 7 CPD hours to the next calendar year. You may not earn more than 8 CPD hours in a calendar day.
                    <br />
                    <br />
                    <span className="badge badge-danger" style={{ padding: "0.5rem" }}>Partial CPD Hours must be in quarter hour increments (such as .25, .5, .75) to be considered acceptable credit.</span>
                    <br />
                    <br />
                    <span className="badge badge-danger" style={{ padding: "0.5rem" }}>The LAPELS CPD Locker is available at any time to upload your CPD activity log and complementary certificates of completion.</span>
                </p>


            );
        case "licenseStatus":
            return (
                <p>
                    <b>2103.&emsp;Licensure and Certification Status</b>
                    <br />
                    &emsp;A.&emsp;The board has established the following licensure statuses for licensees.<br />
                    &emsp;&emsp;<span style={{ fontStyle: "italic" }}>Active Status</span> - the licensure status which exists for a licensee of the board who has complied with all the licensure and licensure renewal requirements of the board and who has elected to be in this status on his/her biennial licensure renewal form.<br />
                    &emsp;&emsp;<span style={{ fontStyle: "italic" }}>Expired Status</span> - the licensure status which exists for a licensee of the board who has failed to properly renew licensure as required in R.S. 37:697. A licensee in an expired status cannot practice or offer to practice engineering or land surveying in Louisiana.<br />
                    &emsp;&emsp;<span style={{ fontStyle: "italic" }}>Inactive Status</span> - the licensure status which exists for an individual licensee of the board who has chosen not to practice or offer to practice engineering and/or land surveying in Louisiana and who has elected to be in this status on his/her biennial licensure renewal form. A licensee in an inactive status can represent himself/herself to the public as a P.E. inactive or a P.L.S. inactive, as applicable, but cannot otherwise practice or offer to practice engineering and/or land surveying in Louisiana.<br />
                    &emsp;&emsp;<span style={{ fontStyle: "italic" }}>Retired Status</span> - the licensure status which exists for an individual licensee of the board who has chosen not to practice or offer to practice engineering and/or land surveying in Louisiana and who has elected to be in this status on his/her biennial licensure renewal form. To qualify for the retired status, the licensee must be at least 70 years of age or have been a licensee of the board for at least 35 years. Unless the licensee is granted a waiver by the board, the renewal fee for the retired status shall be one-half of the current renewal fee for the active status. A licensee qualified for the retired status may be granted a waiver of this renewal fee if the licensee is at least 70 years of age, has been a licensee of the board for at least 35 years continuously, has never been subject to disciplinary action in any jurisdiction, has never committed any of the offenses described in R.S. 37:698(A)(3), (4) or (5), and is of good character and reputation. A licensee in a retired status can represent himself/herself to the public as a P.E. retired or a P.L.S. retired, as applicable, but cannot otherwise practice or offer to practice engineering and/or land surveying in Louisiana.<br />
                </p>
            )
        case "violations":
            return (
                <p>
                    If the answer to any of these questions is "Yes", provide the date(s) and pertinent facts of the case(s) or proceeding(s) including the final disposition (even if the information was previously provided to the Board). Upload additional pages if necessary.
                </p>
            )
        case "privacy":
            return (
                <p>You can choose below how your privacy options are setup. Please indicate how you would like LAPELS to send you information or share it with third parties/vendors</p>
            )
        case "acknowledgement":
            return (
                <>
                    <p>
                        I have read the Rules for Professional Engineers and Surveyors (LAC Title 46, Part LXI), and I agree to conduct my professional affairs in
                        accordance with these rules.
                    </p>
                </>
            )
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Renewal” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        default:
            return (
                <>
                    <ul><li>Read all of the instructions within each section of the renewal.</li>
                        <li>Complete all required sections.</li>
                    </ul>
                </>
            )
            break;
    }
}

export const GetReinstatementInformationContent = (page) => {
    const [fakeData, setFakeData] = useState("");
    const [type, setType] = useState("reinstatement");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    switch (page) {
        case "contact":
            return (
                <ul>
                    <li>Information already in the system will appear in the fields below.  You can make changes by selecting the “Update Contact Information” link.</li>
                    <li>Mandatory fields are indicated with an <span className='text-danger'>‘*’</span> (asterisk).</li>
                    <li>Louisiana Revised Statute 37:23 requires that every application for a professional license, permit or certificate shall require the applicant’s
                        social security number.  Disclosure of your social security is mandatory for this purpose and for the purpose of establishing, modifying or
                        enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support.
                        Failure to provide your social security number for these mandatory purposes will result in the denial of your license or certification.
                        Your social security number will not be released for any other purpose not provided by law.
                    </li>
                    <li>Complete the following sections</li>
                    <ul>
                        <li>Personal Information – ensure you put LAPELS emails on your safe sender list</li>
                        <li>Verify Mailing Address</li>
                        <li>Verify Public Address</li>
                    </ul>
                </ul>)
            break;
        case "employment":
            return (
                <>
                    <b className="colorRed">***YOU MUST COMPLETE THIS SECTION. INITIAL THE FIRM OPTION BELOW WHICH WILL BEST DESCRIBE YOUR EMPLOYMENT IN LOUISIANA***</b>
                    <p>Please be advised that LA R. S. 37:689 requires firms practicing or offering to practice engineering
                        or surveying in the state of Louisiana to be licensed by the Board prior to offering such services.
                    </p>
                    <br />
                    <p>
                        To apply for firm licensure, sign out from this individual account and sign back in with your firm user ID and password. If you have not yet registered your firm, click "Register" at <a href="https://lola.lapels.com" target="blank">https://lola.lapels.com</a> and select the "Firm" option to create a new account
                    </p>
                    <br />
                    <p>
                        Here is the link to Chapter 23 from the LAPELS Rules that addresses firm licensure and supervising professional requirements: <a href="http://www.lapels.com/docs/Applications/firm/Board_Rules_Ch_23.pdf" target="blank">http://www.lapels.com/docs/Applications/firm/Chapter_23_Firms.pdf</a>
                    </p>
                </>
            )
            break;
        case "experience":
            return (
                <ul>
                    <li>If you have a MyNCEES account and you are a record holder, please authorize NCEES to release your records to LAPELS.</li>
                    <li>If you are not a MyNCEES record holder, you must complete the sections below.</li>
                    <li>Submit with your application ten (10) years of work experience dated from oldest to newest.</li>
                    <li>Please note, <span className='text-danger'>ALL TIME SINCE GRADUATION WITH A BS DEGREE MUST BE DOCUMENTED</span>; You must include military, illness, unemployment or time spent completing an additional college degree, etc., on an Experience Record entry. </li>
                    <li>Please refer to LAPELS rule 1507 for Co-op experience, before meeting the educational requirement.  Note:  co-op experience must be reflected on your transcript and there is no provision for engineering internships while you are still in school </li>
                    <li>Any time gaps will result in an incomplete application. Applicant must comply with LAPELS rule 1505 (C) <a target="_blank" href="https://www.lapels.com/docs/Laws_and_Rules/Board_Rules.pdf">Land Surveying Work Experience</a></li>
                </ul>
            )
            break;
        case "experience5year":
            return (
                <ul>
                    <li>If you have a NCEES record, please authorize NCEES to release your records to LAPELS.</li>
                    <li>If you do not have a NCEES record, you must complete the sections below.</li>
                    <li>Submit with your application the last ten (10) years of work experience.</li>
                </ul>
            )
            break;
        case "landing":
            return (
                <ul>
                    <li>There are two applications for PLS licensure reinstatement:</li>
                    <ul>
                        <li>Application if expired more than 120 days but less than 5 years</li>
                        <li>Application if expired more than 5 years</li>
                    </ul>
                    <li>The type of reinstatement application that appears on the next page will be automatically selected based on your license expiration date.</li>
                </ul>
            );
        case "personalReference":
            return (
                <>
                    <p>
                        If you select <b>no</b>, please list below your <b>five personal references</b> and attest below to rule 1701 (B):
                    </p>
                    <p>LAPELS rule §1701 (B):  Applications for licensure as a professional engineer or professional land surveyor shall be completed on the most current forms developed by the board.
                        The application shall contain statements showing the applicant's qualifications, and the names and addresses of five personal references who recommend the applicant for licensure.
                        None of the five personal references can be an immediate family member or business associate of the applicant. For purposes of this <b>§1701.B</b>,
                        immediate family member is defined as a spouse, child, spouse of a child, sibling, spouse of a sibling, sibling of a spouse, parent, parent of a spouse,
                        stepparent or stepchild. For purposes of this <b>§1701.B</b>, business associate is defined as a subordinate of the applicant,
                        or a consultant or contractor who provides goods or services to the applicant or to a business, entity or agency in which the applicant is an owner,
                        member, officer, director, trustee, partner, principal, manager, employee, associate, consultant or contractor...
                        Three or more of the five personal references furnished by an applicant for licensure as a professional land surveyor shall be professional
                        land surveyors holding valid licenses to engage in the practice of land surveying issued to them by proper authority of a state, territory, or possession of the United States,
                        or the District of Columbia.
                        Applicants for licensure as a professional engineer or professional land surveyor must also successfully complete the board’s Louisiana laws and rules quiz and
                        Louisiana ethics and professionalism quiz prior to licensure. Additionally, applicants for licensure as a professional land surveyor must successfully complete
                        the board’s Louisiana standards of practice for boundary surveys quiz prior to licensure.</p>
                    <p><b className="colorRed">
                        Five verified references are required - 3 of the 5 must have an active PLS license.
                    </b></p>
                </>
            )
            break;
        case "quiz":
            return (
                <>
                    <ul>
                        <li>You must complete the online quizzes, with a minimum score of 90%:</li>
                        <ul>
                            <li>Louisiana Laws and Rules Quiz</li>
                            <li>Louisiana Professionalism and Ethics Quiz</li>
                            <li>Louisiana Standards of Practice for Boundary Surveys Quiz</li>
                        </ul>
                        <li>Quizzes can be found here:  <a href="https://www.lapels.com/quiz" target="_blank">https://www.lapels.com/quiz</a></li>
                        <li>You will receive a certificate by email once you have achieved the requisite score.</li>
                        <li>You must take a screenshot of each certificate and upload the attachment below.</li>
                    </ul>
                </>
            )
            break;
        case "sections":
            return (
                <>
                    <div><span className='text-danger'>NOTE:</span> Reinstatements are subject to revision and modification at any time.</div>
                    <ul>
                        <li>Read all of the instructions before you begin.</li>
                        <li>Complete all required sections of the application; incomplete applications will not be processed.</li>
                    </ul>
                </>
            );
        case "cpdRequirements":
            return (
                <>
                    <p>
                        §3121. CPD Reinstatement<br />
                        A. To become reinstated to an active status, a licensee in
                        an expired, inactive, or retired status must have earned all PDHs which he/she would have been required to earn if
                        he/she had been in an active status during the previous two
                        calendar years as provided in §3105.
                    </p>

                    <ul>
                        <li><b>PE:</b></li>
                        <li style={{ listStyleType: "none" }}>Professional Engineering must submit an approved log showing a total of 30 PDHs, of which two (2) must be in professional ethics, along with the certificates substantiating the PDHs claimed for the period of two (2) years prior to the date of this application.If you design buildings or building systems in Louisiana, of the 30 hours, eight (8) hours must be in life safety/ADA compliance.</li>
                        <li><b>PLS:</b></li>
                        <li style={{ listStyleType: "none" }}>Professional Land Surveyors must submit an approved log showing a total of 16 PDHs, of which two (2) must be in professional ethics and two (2) must be in the standards of practice for boundary surveys in Louisiana, along with the certificates substantiating the PDHs claimed for the period of two (2) years prior to the date of this application.</li>
                        <li><b>DUAL LICENSEES (PE/PLS):</b></li>
                        <li style={{ listStyleType: "none" }}>Professional Engineers and Surveyors wishing to apply to reinstate both licenses must submit an approved log showing a total of 30 PDHs which were earned within the past two (2) years from the date of this application, along with the certificates substantiating the PDHs. Of the 30 PDHs, two (2) must be in professional ethics, ten (10) of the hours must be earned in each profession, and two (2) must be in the standards of practice. If you design buildings or building systems in Louisiana, of the 30 hours, eight (8) hours must be in life safety/ADA compliance.</li>
                    </ul>



                </>
            );
        case "disclosure":
            return (
                <p>
                    As part of the application process, the Louisiana Professional Engineering and Land Surveying Board (LAPELS) will obtain a consumer report, which I understand may include information regarding my credit worthiness, credit standing, credit capacity, character, general reputation, personal characteristics, or mode of living.
                </p>
            );
        case "violations":
            return (
                <p>
                    In the case of answering “Yes” to the question below about unauthorized or unlicensed practice (Question #1), explain in detail the facts surrounding such practicing or offering to practice engineering in Louisiana and submit a list of all projects/jobs on which you offered to provide or provided engineering services in Louisiana during such period.
                    Such list should include, for each such project/job, offer date, start date, completion date, client name, project/job name, project/job location and project/job descriptions. Upload additional pages if necessary.
                    <br />
                    <br />
                    In the case of answering “Yes” to any of the questions below other than the question about unauthorized or unlicensed practice, provide the date(s) and pertinent facts of the case(s) or proceeding(s), including the final disposition (even if the information was previously provided to the Board).
                    Upload additional pages if necessary.
                </p>
            );
        case "acknowledgement":
            return (
                <>
                    <p>I, the applicant named in this application, have read the contents hereof, and to the best of my knowledge, information and believe the statements and information in this application are true and correct in substance and effect and are made in good faith. I have not omitted any information requested in this application. I am aware that such an omission or untrue response may constitute fraud or deceit, material misstatement, perjury or the giving of false or forged evidence under Louisiana Revised Statutes 37:698 and 37:700. I understand and agree that any responses verifying my education, personal references, and experience shall be considered privileged and confidential and that this information was not reviewed by me nor will it be available for my inspection or review in the future.</p>
                    <ul>
                        <li>It is your responsibility to check your LOLA account frequently for any pending issues. Ensure you add "lapels.com" to your "Safe Senders" list in your email application and/or (if applicable) in your network firewall.</li>
                        <li>Once we receive a complete application, with no pending issues, process time may take 6-8 weeks.  </li>
                    </ul>
                </>
            );
        case "review":
            return (
                <>
                    <ul>
                        <li><b>Click Validate</b> to see if there are any issues within the {type} that you provided.</li>
                        <li>If there are issues, please correct the issue in the respective section(s) by clicking the <b>“Back to Sections”</b> button below.</li>
                        <li>Then return to the <b>Review</b> section to <b>validate</b> again.</li>
                        <li style={{ color: "red" }}>You will not be allowed to submit until all issues are corrected.</li>
                    </ul>
                    <p>
                        Once the {type} is validated successfully, additional buttons will appear:
                        <ul>
                            <li>Select “Download PDF” to download a copy of your {type} for your records</li>
                            <li>Select “Submit Reinstatement” to add your {type} to the cart and see payment options.</li>
                        </ul>
                    </p>

                    <p>You will not have direct access to this review page after completing the {type} and submitting payment. To request a copy, please contact LAPELS staff.</p>
                </>
            )
            break;
        default:
            return (
                <>
                    {
                        //<h5 className="colorRed">This is sample text</h5>
                        //<span>{fakeData}</span>
                    }
                </>
            )
            break;
    }
}