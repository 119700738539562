import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getExperienceRecords = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/experiencerecords`, { headers: authHeader(), params: { applicationId} });
}
const postExperienceRecord = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/experiencerecord`, data, { headers: authHeader() });
}
export default {
    getExperienceRecords,
    postExperienceRecord
}