import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShoppingCartService from '../../../../../services/Public/shoppingCart.service';
function ShoppingCartButton() {
    const [numberOfItems, setNumberOfItems] = useState(0);
    const [total, setTotal] = useState(0);
    var amount = useSelector(x => x.refreshToken);
    useEffect(() => {
        ShoppingCartService.getShoppingCart().then((response) => {
            setNumberOfItems(response.data.numberOfItems);
            setTotal(response.data.total);
        });
    }, [amount])
    var history = useHistory();
    function navigateToCart() {
        history.push("/individual/shoppingcart");
    }
    return (
        <>
            <Button onClick={navigateToCart} className="mt-2">
                <FaIcons.FaShoppingCart />
                <span className="badge badge-light ml-2">{numberOfItems}</span>
                <span className="badge badge-light ml-1"><NumberFormat value={total} displayType={'text'} decimalScale={2} fixedDecimalScale={ true} prefix={'$'} /></span></Button>

        </>
    )
}

export default ShoppingCartButton;