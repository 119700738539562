import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getEmployment = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/employment`, { headers: authHeader(), params: { applicationId }   });
}
const postEmployment = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/employment`, data, { headers: authHeader() });
}
export default {
    getEmployment,
    postEmployment
}