import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Table } from 'rsuite';
import queueService from '../../../services/Internal/Queue/queue.service';
import GenericBadge from '../../Shared/Badge/GenericBadge';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import { sort_by } from './../../Shared/HelperFunctions'
import GridSearch from './GridSearch';

function ClosedTasksGrid({ propsIsActive, viewItemCallback }) {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sortColumn, setSortColumn] = useState("dateSubmitted");
    const [sortType, setSortType] = useState("desc");
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [gridLoading, setGridLoading] = useState(false);
    const [searchModel, setSearchModel] = useState({
        firstName: '',
        lastName: '',
        organizationName: '',
        internalStatus: null,
        assignedTo: '',
        dateSubmittedBegin: '',
        dateSubmittedEnd: '',
        applicationType: []
    })

    useEffect(() => {
        setHasLoaded(false);
        if (propsIsActive) {
            refreshGrid();
            setHasLoaded(true);
        }
        //the reason that this is in a set timeout is because without this databind delay, the column width calculations with flexgrow would be wrong
        //We will need to test with a C# controller call and see if the get request delay that happens will be enough to have the columns recalculate their widths
    }, [propsIsActive])

    const clearSearchModel = () => {
        setSearchModel({
            firstName: '',
            lastName: '',
            organizationName: '',
            internalStatus: null,
            assignedTo: '',
            dateSubmittedBegin: '',
            dateSubmittedEnd: '',
            applicationType: []
        });
    }
    const handleSearch = () => {
        refreshGrid();
        setHasLoaded(true);
    }
    const getData = () => {

        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const refreshGrid = () => {
        setHasLoaded(false);
        setGridLoading(true);
        var dataToTransmit = {
            firstName: searchModel.firstName ? searchModel.firstName : null,
            lastName: searchModel.lastName ? searchModel.lastName : null,
            organizationName: searchModel.organizationName ? searchModel.organizationName : null,
            internalStatus: searchModel.internalStatus,
            assignedTo: searchModel.assignedTo ? searchModel.assignedTo : null,
            dateSubmittedBegin: searchModel.dateSubmittedBegin ? searchModel.dateSubmittedBegin : null,
            dateSubmittedEnd: searchModel.dateSubmittedEnd ? searchModel.dateSubmittedEnd : null,
            applicationType: searchModel.applicationType ? searchModel.applicationType : null
        };
        queueService.getClosedTasks(dataToTransmit).then((response) => {
            setGridData(response.data);
            setGridLoading(false);
        });
    }

    const handleSortColumn = (sortColumn, sortType) => {

        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const filteredData = getData();
    return (
        <>
            <GridSearch searchModel={searchModel} setSearchModel={setSearchModel} propsIsActive={propsIsActive}
                handleSearch={handleSearch} clearSearchModel={clearSearchModel} refreshGrid={refreshGrid}
                setHasLoaded={setHasLoaded} hasAdvancedSearch={true} hasInternalStatusSearch={false} />
            <br />
            {
                hasLoaded ?
                    <>

                        <GenericPagination page={page} dataLength={gridData.length} displayLength={displayLength} setDisplayLength={setDisplayLength} setPage={setPage} discludeAllLength={false} />
                        <Table
                            loading={gridLoading}
                            onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            wordWrap autoHeight data={filteredData}>
                            <Table.Column width={100} fixed>
                                <Table.HeaderCell />
                                <Table.Cell dataKey="id">
                                    {rowData => {
                                        return (
                                            <Button onClick={() => { viewItemCallback(rowData) }}>View</Button>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Applicant</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="applicant" />
                            </Table.Column>
                            <Table.Column minWidth={150} flexGrow={1.7} sortable>
                                <Table.HeaderCell>
                                    <b>Application Type</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="taskType">{rowData => (`${rowData.parentType} - ${rowData.taskType}`)}</Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={125} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Date Submitted</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="dateSubmitted">{rowData => (new Date(rowData.dateSubmitted).toLocaleDateString())}</Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Assigned To</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="assignedTo" />
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Internal Status</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="internalStatus"/>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Comment(s)</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="comments" />
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Status</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="parentStatus" />
                            </Table.Column>
                        </Table>
                        <GenericPagination page={page} dataLength={gridData.length} displayLength={displayLength} setDisplayLength={setDisplayLength} setPage={setPage} discludeAllLength={false} />
                    </> : <LoadingSpinner />
            }
        </>
    );
}

export default ClosedTasksGrid;