import * as dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Icon, Table } from 'rsuite'
import Swal from 'sweetalert2';
import educationTabService from '../../../../../services/Internal/Credential/educationTab.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import EducationTabModal from './EducationTabModal';

function EducationPage({ entityId, activeKey }) {
    const [data, setData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});
    useEffect(() => {
        if (activeKey == 'education') {
            getData();
        }
    }, [activeKey])
    const getData = () => {
        educationTabService.getEducations(entityId).then((response) => {
            setData(response.data);
        })
        setHasLoaded(true);
    }
    const openModal = (rowData) => {
        if (rowData?.id) {
            setModalData({
                ...rowData,
                attendanceEndDate: rowData.attendanceEndDate ? dayjs(rowData.attendanceEndDate).format('YYYY-MM') : '',
                attendanceStartDate: rowData.attendanceStartDate ? dayjs(rowData.attendanceStartDate).format('YYYY-MM') : '',
                graduationDate: rowData.graduationDate ? dayjs(rowData.graduationDate).format('YYYY-MM-DD') : '',
                modalType: "Edit"
            });
        }
        else {
            setModalData({
                attendanceEndDate: '',
                attendanceStartDate: '',
                legacyDatesAttended: '',
                legacyDegreeOrSpecialty: '',
                dateCrte: new Date(),
                degree: null,
                deleted: false,
                entityId: entityId,
                graduationDate: '',
                id: '',
                major: '',
                school: null,
                userCrte: '',
                modalType: "Add"
            })
        }
        setModalShow(true);
    }
    const deleteRow = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                educationTabService.deleteEducation(rowData.id).then((response) => {
                    if (response.data) {
                        Swal.fire("Success!", "You have successfully deleted an education record.", "success");
                        getData();
                    }
                })
            }
        });
    }
    return (
        <>
            <EducationTabModal updateGrid={() => { getData(); }} modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} proptitle="Add" />
            {hasLoaded ? <>
                <Table wordWrap autoHeight data={data}>

                    <Table.Column minWidth={200} flexGrow={1.25}>
                        <Table.HeaderCell>
                            <b>Name</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.school?.name)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={75} flexGrow={.5}>
                        <Table.HeaderCell>
                            <b>Status</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.schoolStatus?.schoolStatus)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Country</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.school?.country)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Dates Attended</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => ((rowData.attendanceStartDate ? new Date(rowData.attendanceStartDate).toLocaleDateString('en-us', { year: "numeric", month: "numeric" }) : '') + ' - ' + (rowData.attendanceEndDate ? new Date(rowData.attendanceEndDate).toLocaleDateString('en-us', { year: "numeric", month: "numeric" }) : ''))}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Legacy Dates Attended</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.legacyDatesAttended)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Date Graduated</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.graduationDate ? new Date(rowData.graduationDate).toLocaleDateString() : '')}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Degree/Speciality</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.degree?.degree)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Legacy Degree</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.legacyDegreeOrSpecialty)}</Table.Cell>
                    </Table.Column>
                    <Table.Column minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>
                            <b>Major</b>
                        </Table.HeaderCell>
                        <Table.Cell>{rowData => (rowData.major)}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={80}>
                        <Table.HeaderCell>
                            <b>Action(s)</b>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="id">
                            {rowData => {
                                function handleAction() {
                                    var tempData = { ...rowData, modalType: 'Edit' }
                                    openModal(tempData);
                                }
                                return (
                                    <>
                                        <Icon icon="edit2" size="2x" title="Edit" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                        <Icon icon="trash" size="2x" title="Delete" onClick={() => { deleteRow(rowData) }} style={{ cursor: "pointer" }} />
                                    </>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
                <br />
                <Row className="float-right">
                    <Button onClick={() => { openModal(); }}>Add Education</Button>
                </Row>
            </> : <LoadingSpinner />
            }
        </>
    );
}
export default EducationPage;