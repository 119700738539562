import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidenav, Nav, Icon, Dropdown } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import Swal from 'sweetalert2'
import * as FaIcons from 'react-icons/fa';
import logo from '../../../../images/LAPELS-LOLA-Logo.png'

function Sidebar(props) {
    const [activeKey, setActiveKey] = React.useState();
    useEffect(() => {
        if (props.activeKey) {
            setActiveKey(props.activeKey);
        } else {
            setActiveKey("1");
        }
    }, [])
    return (
        <div className='sidenav'>
            <Sidenav activeKey={activeKey} onSelect={setActiveKey}>
                <Sidenav.Header>
                    <div className='heads'>
                        <img src={logo} style={{ width: '60px' }} />
                        <b> LOLA</b>
                    </div>
                </Sidenav.Header>
                <Sidenav.Body>
                    <Nav>
                        <Nav.Item eventKey="1" icon={<FaIcons.FaTh className="rs-icon sideBarIcon" />} componentClass={Link} to="/firm/dashboard">
                            Dashboard
                        </Nav.Item>
                        <Nav.Item eventKey="2" icon={<FaIcons.FaIdCard className="rs-icon sideBarIcon" />} componentClass={Link} to="/firm/contactinformation">
                            Update contact information
                        </Nav.Item>
                        <Nav.Item eventKey="3" icon={<FaIcons.FaClipboardList className="rs-icon sideBarIcon" />} componentClass={Link} to="/firm/applications/">
                            Apply for a license/certification
                        </Nav.Item>
                        <Nav.Item eventKey="4" icon={<FaIcons.FaCertificate className="rs-icon sideBarIcon" />} componentClass={Link} to="/firm/renewals/">
                            Renew a license/certification
                        </Nav.Item>
                        <Nav.Item eventKey="5" icon={<FaIcons.FaSyncAlt className="rs-icon sideBarIcon" />} componentClass={Link} to="/firm/reinstatements/">
                            Reinstate a license/certification
                        </Nav.Item>
                        <Nav.Item eventKey="6" icon={<FaIcons.FaUsers className="rs-icon sideBarIcon" />} componentClass={Link} target='_blank' to="/firm/supervisingprofessional">
                            Supervising Professional (SP) Locker
                            </Nav.Item>
                        <Dropdown eventKey="7" title="Other Online Services" icon={<FaIcons.FaToolbox className="rs-icon sideBarIcon" />}>
                            <Dropdown.Item href="https://www.lapels.com/quiz" target="_blank" eventKey="7-1">Online Quizzes</Dropdown.Item>
                            <Dropdown.Item href="https://lapels.com/indivsearch" target="_blank" eventKey="7-2">Individual Search</Dropdown.Item>
                            <Dropdown.Item href="https://lapels.com/firmsearch" target="_blank" eventKey="7-3">Firm Search</Dropdown.Item>
                            <Dropdown.Item href="https://lapels.com/contact.html" target="_blank" eventKey="7-4">Contact Us</Dropdown.Item>
                            <Dropdown.Item href="http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=9643+Brookline+Ave.,+Baton+Rouge,+LA+70809&vps=2&jsv=166d&sll=30.428601,-91.080544&sspn=0.01247,0.019205&ie=UTF8&ei=-mVXSrjQLJCgM_X9icYB&sig2=mpA1O6wQxsPS_3QIETbO8w&dtab=5" target="_blank" eventKey="7-5">Directions to LAPELS</Dropdown.Item>
                            <Dropdown.Item href="https://lapels.com/LawsRules.html" target="_blank" eventKey="7-6">Laws and Rules</Dropdown.Item>
                            <Dropdown.Item href="https://lapels.com/board_meeting.html" target="_blank" eventKey="7-7">Board Meetings</Dropdown.Item>
                        </Dropdown>
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </div>
    );
}

export default Sidebar;
