import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import renewalService from '../../../../../services/Internal/Renewal/VF/vfRenewal.service';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Information from '../../../../Shared/Card/Information';
import Acknowledgement from '../../../../Shared/Card/FirmAcknowledgement';
import Issues from '../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/VFInformation';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function AcknowledgementPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Surveying Firm Renewal",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Acknowledgement"
        }
    );
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const renewalId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const sectionId = 7; // Revisit
    const [formModel, setFormModel] = useState({})
    useEffect(() => {
        renewalService.vfGetSection(renewalId, sectionId).then((response) => {
            setFormModel(response.data);
            setIsComplete(response.data.staffReviewComplete);
            renewalService.getFirmRenewal(renewalId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }
    const submitData = (isForSubmit) => {
        renewalService.vfPostSection(renewalId, sectionId, formModel.electronicSignature).then((response) => {
            renewalService.vfMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                if (isForSubmit && response.data.id && data.data.id) {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                }
                else if (response.data.id && data.data.id) {
                    Swal.fire("Saved!");
                }
            })
        })
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Row>
                    <Col>
                        <Information content={GetRenewalInformationContent("acknowledgement")} className="mb-3" />
                    </Col>
                </Row>
                <br />
                {hasLoaded ?
                    <Form readOnly={!isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <Row>
                            <Col>
                                <Acknowledgement appType="renewal" />
                            </Col>
                        </Row>
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={formModel.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            showSectionComplete={true}
                            backToSectionLink="/internal/vfRenewal/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default AcknowledgementPage