import React, { useEffect, useRef, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Card, Row, Col, Button, Image, Container } from 'react-bootstrap'
import { FaPrint } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import { Table } from 'rsuite';
import seal from '../../../images/LAPELS-LOLA-Logo.png'

function Invoice(props) {
    const [printLoading, setPrintLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [entity, setEntity] = useState({});
    const [licenseData, setLicenseData] = useState();
    const componentRef = useRef();
    useEffect(() => {
        setData(props.data);
        setEntity(props.entity)
        setLicenseData(props.licenseInfo.join(", "))
        setHasLoaded(true);
    }, [props.data])

    const applicationMarkup = () => {
        return (
            data.lineItems.map((item) => {
                if (item.feeDefinition.feeType.id == 2)
                    return (<><b>{item.feeDefinition.feeDefinitionDescription}</b><br /></>);
            })
        );
    }
    return (
        <div className="invoice">
            <BlockUi blocking={printLoading}>
                <Card className="mb-3">
                    <Card.Body>
                        {hasLoaded &&
                            <>
                                <Container ref={componentRef} className="mt-3">
                                    <Row className="mb-3"></Row>
                                    <Row>
                                        <Col>
                                            <Image src={seal} style={{ height: '200px' }} />
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <b>{entity?.formattedName}</b><br />
                                            <b>{entity?.mailingAddress?.streetAddress}</b><br />
                                            <b>{entity?.mailingAddress?.city}, {entity?.mailingAddress?.state?.code} {entity?.mailingAddress?.postalCode}</b><br /><br />
                                            {applicationMarkup()}
                                            {licenseData?.length > 0
                                                ? <><b>License #:</b> {licenseData} <br /></>
                                                : <></>
                                            }
                                            <b>LOLA #:</b> {entity?.lolaNumber}<br /><br />
                                            <b>Invoice #{data.id}</b><br />
                                            <span>Invoice Date: {new Date(data.dateCrte).toLocaleDateString()}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6>LAPELS</h6>
                                            <span>9643 Brookline Avenue Suite 121</span><br />
                                            <span>Baton Rouge, LA 70809-1433</span><br />
                                            <span>(225) 925-6291</span>
                                        </Col>
                                    </Row>
                                    <Table wordWrap autoHeight data={data.lineItems} className="mb-3">
                                        <Table.Column flexGrow={2}>
                                            <Table.HeaderCell>
                                                <b>Description</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => (rowData.feeDefinition.feeDefinitionDescription)}</Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                <b>Price</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => (rowData.feeDefinition.feeAmount.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }))}</Table.Cell>
                                        </Table.Column>
                                    </Table>

                                    <Row className='mb-3'>
                                        <Col></Col>
                                        <Col className='pr-0'><b>TOTAL</b></Col>
                                        <Col className='pl-0'>
                                            <b>{data.totalAmount?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}</b>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col></Col>
                                        <Col className='pr-0'>
                                            <b>AMOUNT PAID</b>
                                        </Col>
                                        <Col className='pl-0'>
                                            {data.paidAmount?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}

                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col></Col>
                                        <Col className='pr-0'>
                                            <b>PAID DATE</b>
                                        </Col>
                                        <Col className='pl-0'>
                                            {data.datePaid ? new Date(data.datePaid).toLocaleDateString() : ''}
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col></Col>
                                        <Col className='pr-0'>
                                            <b>AMOUNT DUE</b>
                                        </Col>
                                        <Col className='pl-0'>
                                            <b>{data.balance?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}</b>
                                        </Col>
                                    </Row>
                                </Container>
                                <hr />
                                <br />
                                <Row>
                                    <Col style={{ textAlign: 'right' }}>
                                        <ReactToPrint
                                            trigger={() => (<Button variant='light'><FaPrint /> Print</Button>)}
                                            content={() => componentRef.current}
                                            onBeforeGetContent={() => { setPrintLoading(true) }}
                                            onAfterPrint={() => { setPrintLoading(false) }}
                                        />

                                    </Col>
                                </Row>
                            </>
                        }
                    </Card.Body>
                </Card>
            </BlockUi>
        </div>
    )
}



export default Invoice;