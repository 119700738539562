import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import LoginPage from '../../Login/LoginPage';
import RegisterNewMemberPage from '../../Login/Register/RegisterNewMemberPage'
import RegisterSuccessPage from '../../Login/Register/RegisterSuccessPage'

import PublicIndividual from "./Individual";
import InternalMain from '../../Internal/Main/Main'
import Firm from "./Firm";
import Enums from '../../../helpers/enums';

import AuthService from "../../../services/auth.service";
import ForgotPassword from '../../Login/ForgotPassword';
import ForgotPasswordSuccess from '../../Login/ForgotPasswordSuccess';
import ForgotUserId from '../../Login/ForgotUserId';
import ForgotUserIdSuccess from '../../Login/ForgotUserIdSuccess';

export default function Main() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userType, setUserType] = useState(null);
    const history = useHistory();
    const handleLogin = (loggedIn) => {
        setLoggedIn(loggedIn);
        if (loggedIn === true) {
            //get user data and send to appropriate page
            var roles = AuthService.getRoles();
            if (roles.includes("Admin")) {
                setUserType(Enums.userRoles.ADMINISTRATOR);
                if (pathContains("login")) {
                    history.push("/internal");
                }
                //Todo: push history
            }
            else if (roles.includes("Staff")) {
                setUserType(Enums.userRoles.STAFF);
                if (pathContains("login")) {
                    history.push("/internal");
                }
                //Todo: push history
            }
            else if (roles.includes("Firm")) {
                setUserType(Enums.userRoles.FIRM);
                if (!pathContains("firm")) {
                    history.push("/firm");
                }
            }
            else if (roles.includes("Individual")) {
                setUserType(Enums.userRoles.INDIVIDUAL);
                if (!pathContains("individual")) {
                    history.push("/individual");
                }
            }
            else {
                sendToLogin();
            }

        } else {
            sendToLogin();
        }
    }
    const sendToLogin = () => {
        setUserType(null);
        setLoggedIn(false);

        localStorage.removeItem('updateRecentQueue');
        localStorage.removeItem('updateRecentContacts');
        localStorage.removeItem('currentPageCache');
        localStorage.removeItem('searchCache');
        localStorage.removeItem('displayLengthCache');
        //debugger;
        if (!pathContains("login")) {
            history.push("/login");
        }
    }

    const pathContains = (name) => {
        var pathArray = history.location.pathname.split("/");
        return pathArray[1] && pathArray[1] === name;
    };

    useEffect(() => {
        //this will force the login page to load along with its routes
        //but doing this ruins the history....
        var user = AuthService.getCurrentUser();
        if (user !== null) {
            handleLogin(true);
        }
        else {
            handleLogin(false);
        }
    }, []);

    return (
        <div>
            {userType === Enums.userRoles.ADMINISTRATOR ? <InternalMain /> :
                userType === Enums.userRoles.FIRM ? <Firm /> :
                    userType === Enums.userRoles.INDIVIDUAL ? <PublicIndividual /> :
                        userType === Enums.userRoles.STAFF ? <InternalMain /> :
                            <>
                                <Switch>
                                    <Route path="/" render={(props) => <LoginPage {...props} handleLogin={handleLogin} />} exact />
                                    <Route path="/login" render={(props) => <LoginPage {...props} handleLogin={handleLogin} />} exact />
                                    <Route path="/register" render={() => <RegisterNewMemberPage />} />
                                    <Route path="/forgotpassword" render={() => <ForgotPassword />} />
                                    <Route path="/forgotpasswordsuccess" render={() => <ForgotPasswordSuccess />} />
                                    <Route path="/registersuccess" render={() => <RegisterSuccessPage />} />
                                    <Route path="/forgotuserid" render={() => <ForgotUserId />} />
                                    <Route path="/forgotuseridsuccess" render={() => <ForgotUserIdSuccess />} />
                                </Switch>
                            </>
            }
        </div>
    )
}