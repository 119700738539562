import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { Table } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import GenericBadge from '../../../Shared/Badge/GenericBadge';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';

function SupervisesTable(props) {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        updateTable();
    }, [props.updateTable])

    const statusChange = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to reactivate this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, deactivate'
        }).then((result) => {
            if (result.isConfirmed) {
                credentialService.updateRelationshipStatus(id).then((response) => {
                    updateTable();
                })
            }
        })

    }
    const removePendingSP = (id) => {
        Swal.fire({
            title: 'Are you sure you want to remove this SP?',
            text: "You will not be able to reactivate this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                credentialService.removePendingSupervisingProfessional(id).then((response) => {
                    if (response)
                        updateTable();
                    else
                        Swal.fire("Failed to remove supervising professional");
                })
            }
        })
    }
    const updateTable = () => {
        setHasLoaded(false);
        credentialService.getIndSupervises(props.entityid).then((data) => {
            setGridData(data.data.sort(function (a, b) {
                var x = (a.status + a.firmFormattedtName);
                var y = (b.status + b.firmFormattedtName);
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            }));
            setHasLoaded(true)
        })
    }

    return (
        <>
            {
                hasLoaded ?
                    <Table bordered wordWrap autoHeight data={gridData}>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell><b>Firm Name</b></Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.firmFormattedName)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={115}>
                            <Table.HeaderCell><b>Firm License #</b></Table.HeaderCell>
                            <Table.Cell>{rowData => (<Button variant="link" style={{ 'padding': 0 }} href={"internal/firmCredential?entityId=" + rowData.firmEntityId} target="_blank">{rowData.firmFormattedLicenseNumber ?? "N/A"}</Button>)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={125}>
                            <Table.HeaderCell><b>Owner of this firm?</b></Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.isOwner ? 'Yes' : 'No')}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell><b>Received Date</b></Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.sentDate != null ? new Date(rowData.sentDate).toLocaleDateString() : null)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell><b>Response Date</b></Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.responseDate != null ? new Date(rowData.responseDate).toLocaleDateString() : null)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={150}>
                            <Table.HeaderCell><b>Status</b></Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.codeVerificationStatus?.verificationStatus ? <GenericBadge status={rowData.codeVerificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                        </Table.Column>
                        {props.editMode && gridData.filter((value, index) => {
                            return (value.codeVerificationStatus.verificationStatus == "Active" || value.codeVerificationStatus.verificationStatus == "Pending")
                        })?.length > 0 &&
                            <Table.Column minWidth={125}>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.Cell>{rowData => {
                                if (rowData.codeVerificationStatus.verificationStatus == "Active")
                                    return (<Button variant='danger' onClick={() => { statusChange(rowData.relationshipId) }}>Deactivate</Button>)
                                else if (rowData.codeVerificationStatus.verificationStatus == "Pending")
                                    return (<Button onClick={() => removePendingSP(rowData.supervisingProfessionalId)} variant='light'><FaTrash /></Button>)
                                else return (<></>)
                                }
                                }</Table.Cell>
                            </Table.Column>
                        }
                    </Table>
                    : <LoadingSpinner />
            }
        </>
    )
}

export default SupervisesTable;