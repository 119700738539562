import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { Icon, Dropdown } from 'rsuite'
import Swal from 'sweetalert2';
import notificationService from '../../../../services/notification.service';
import { sort_by } from '../../../Shared/HelperFunctions';

function Notification() {
    const [notifications, setNotifications] = useState([])

    const badgeStyle = {
        position: "absolute",
        marginLeft: "-30px",
        marginTop: "5px",
        padding: "2px 8px",
        borderRadius: "50%",
        background: "red",
        color: "white",
        zIndex: "6",
        pointerEvents: "none"
    };

    const notificationPanel = (text, date, userName, id) => {
        return (
            <Dropdown.Item panel key={"notificationNumber" + id}>
                <div className="notificationFlexContainer">
                    <p className="notificationTextDisplay" ><b>{userName}</b> ({date})<br /> {text}</p>
                    <Button className="notificationCloseButton" onClick={() => { removeNotification(id) }} variant="link">X</Button>
                </div>
                <hr />
            </Dropdown.Item>
        )
    }
    const clickableNotificationPanel = (text, date, userName, id, issueId) => {
        return (
            <Dropdown.Item panel key={"notificationNumber" + id}>
                <div className="notificationFlexContainer">
                    <a href={"individual/redirectToSection?issueId=" + issueId} className="notificationTextDisplay" ><b>{userName}</b> ({date})<br /> {text}</a>
                    <Button className="notificationCloseButton" onClick={() => { removeNotification(id) }} variant="link">X</Button>
                </div>
                <hr />
            </Dropdown.Item >
        )
    }


    const removeNotification = (id) => {
        notificationService.deleteNotification(id).then((response) => {
            if (response.status == 200) {
                var currentNotifications = notifications;
                setNotifications(currentNotifications.filter(notification => notification.id !== id));
            }
            else {
                Swal.fire("Error removing notification");
            }
        })

    }

    const removeAllNotifications = () => {
        notificationService.deleteAllNotifications().then((response) => {
            if (response.status == 200) {
                setNotifications([]);
            }
            else {
                Swal.fire("Error removing notifications");
            }
        })
    }

    useEffect(() => {
        notificationService.getNotifications().then((response) => {
            const data = response.data.sort(sort_by("dateCrte", true));
            setNotifications(data);
        });
    }, [])

    return (
        <>
            <Dropdown id="topNavNotificationsDropdown" placement="bottomEnd" icon={<Icon icon="bell-o" size="2x" />} noCaret={true}>
                {
                    notifications.length > 0 ?
                        <>
                            <Dropdown.Item panel className="text-center pb-3 pr-3 pl-3"><h5>Notifications</h5></Dropdown.Item>
                            {notifications.map((val, index) => {
                                if (val.issueId) {
                                    return clickableNotificationPanel(val.text, new Date(val.dateCrte).toLocaleDateString(), val.userName, val.id, val.issueId);
                                }
                                return notificationPanel(val.text, new Date(val.dateCrte).toLocaleDateString(), val.userName, val.id)
                            })}

                            <Dropdown.Item panel className="text-center pb-1 pr-3 pl-3"><Button className="w-100" onClick={removeAllNotifications} variant="danger">Remove All</Button></Dropdown.Item></> :
                        <h4 className="noNotifications">No Notifications</h4>
                }
            </Dropdown>
            {notifications.length > 0 && <span style={badgeStyle}>{notifications.length}</span>}
        </>
    );
}
export default Notification;