import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function LockedRenewalModal(props) {
    const { push } = useHistory();
    const routes = useSelector(x => x.currentRoutes);
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <b className='text-danger text-center'>Renewal Locked</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.sectionId == 3 &&
                    <h5 className="text-center">Once you obtain your CPD hours, download and complete the CPD Log form located here: <a href="https://www.lapels.com/CPD.html" target="_blank">https://www.lapels.com/CPD.html</a>. You must upload your completed log and certificates of completion into your CPD locker in LOLA. <br /><br />If you have questions, contact <a href="mailto:CandE@lapels.com">CandE@lapels.com</a></h5>
                }
                {props.sectionId == 10 &&
                    <h5 className="text-center">If you have not met this requirement, you may not proceed with the online renewal. Once you obtain the hours, contact <a href="mailto:CandE@lapels.com">CandE@lapels.com</a>; or you can renew into Inactive status.</h5>
                }
                {props.sectionId == 5 &&
                    <h5>To complete your firm renewal, you must designate a Professional Surveyor in active status for a Surveying Firm or a Professional Engineer in active status for an Engineering Firm as your Supervising Professional.
                 <br /><br />
                    Your SP must meet the requirements in 2305 (A) (1) (a-b):<br />
                    &ensp;a. Primary employment is with the firm, provided the supervising professional works for the firm for a 12-month average of at least 30 hours per week or 130 hours per month, OR<br />
                    &ensp;b. Employment is with the firm, provided the supervising professional has at least a 25 percent ownership interest in the firm
                </h5>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { push(routes[routes.findIndex(el => { if (el.sectionId == props.sectionId) { return true; } }) + 1].route) }}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default LockedRenewalModal;