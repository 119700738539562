import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { ControlLabel, Form, FormGroup, Schema, SelectPicker, FormControl, InputPicker, Icon } from 'rsuite';
import Swal from 'sweetalert2';
import paymentService from '../../../../services/Internal/Revenue/payment.service';
import { getContacts, getPaymentTypes } from '../../../../services/Shared/codeTable.service';
import CurrencyMask from '../../../Shared/GenericComponents/CurrencyMask';
import RedStar from '../../../Shared/GenericComponents/RedStar';

function AddPaymentModal(props) {
    const [addPaymentTypes, setAddPaymentTypes] = useState([]);
    const [contactLoading, setContactLoading] = useState(false);
    const [contacts, setContacts] = useState();
    const [paymentModel, setPaymentModel] = useState({
        entityId: '',
        paymentType: null,
        amount: '',
        nicAuthCode: '',
        remitter: '',
        entityDetails: null,
        comments: '',
        batchId: props.batchId
    });

    useEffect(() => {
        getPaymentTypes().then(setAddPaymentTypes);
    }, [])
    const formSubmit = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...paymentModel,
                entityId: paymentModel.entityDetails.entityId,
                availableAmount: paymentModel.amount
            }
            delete dataToTransmit.entityDetails;
            paymentService.createPayment(dataToTransmit).then(response => {
                if (response.data.id) {
                    props.updateGrid();
                    props.onHide();
                }
                else {
                    Swal.fire("Error!", "Failed to create payment", "error");
                }
            })
        }
    }
    const { StringType, ObjectType, NumberType } = Schema.Types;
    const model = Schema.Model({
        paymentType: ObjectType().isRequired('This field is required.'),
        amount: NumberType().isRequired('This field is required.').min(0.01, 'Payment amount must be greater than 0.'),
        nicAuthCode: StringType().isRequired('This field is required.'),
        remitter: StringType().isRequired('This field is required.'),
        entityDetails: ObjectType().isRequired('This field is required.')
    })

    const updateContacts = (value) => {
        if (value) {
            setContactLoading(true);
            getContacts(value).then((response) => {
                setContacts(response);
                setContactLoading(false);
            });
        }
    }

    return (
        <Modal
            {...props}
            animation={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="addPaymentForm" fluid model={model} formValue={paymentModel} onChange={paymentModel => setPaymentModel(paymentModel)} onSubmit={formSubmit}>
                    <FormGroup className="mb-3">
                        <Row>
                            <Col>
                                <ControlLabel>
                                    <strong>
                                        Payment Type&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    block="true"
                                    name="paymentType"
                                    placeholder="-- Select --"
                                    accepter={SelectPicker}
                                    searchable={false}
                                    data={addPaymentTypes} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Row>
                            <Col>
                                <ControlLabel>
                                    <strong>
                                        Amount&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormControl name="amount"
                                    accepter={CurrencyMask}
                                    checkTrigger="blur"
                                    placeholder="$0.00"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Row>
                            <Col>
                                <ControlLabel>
                                    <strong>
                                        Check#/CC Auth Number&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl name="nicAuthCode"
                                    placeholder="Enter Authorization Number"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Row>
                            <Col>
                                <ControlLabel>
                                    <strong>
                                        Remitter&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormControl name="remitter"
                                    placeholder="Enter Remitter"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Row>
                            <Col>
                                <ControlLabel>
                                    <strong>
                                        Contact&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    accepter={InputPicker}
                                    block="true"
                                    data={contacts}
                                    name="entityDetails"
                                    labelKey="name"
                                    placeholder="Find a Contact (Min 3 characters)"
                                    onSearch={updateContacts}
                                    renderMenu={menu => {
                                        if (contactLoading) {
                                            return (
                                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                    <Icon icon="spinner" spin /> Loading...
                                                </p>
                                            );
                                        }
                                        return menu;
                                    }}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <>
                                                {`${item.value.formattedName} | ${item.value.lolanumber} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""}`}
                                            </>
                                        );
                                    }}
                                    renderValue={(value, item, selectedElement) => {
                                        return (
                                            <>
                                                {item ? `${item.value.formattedName} | ${item.value.lolanumber} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""}` : 'Find a Contact (Min 3 characters)'}
                                            </>
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Row>
                            <Col>
                                <ControlLabel>
                                    <strong>
                                        Comments&nbsp;
                                    </strong>
                                </ControlLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl name="comments"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button type="submit" form="addPaymentForm" variant="primary">Add Payment</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddPaymentModal;