import axios from "axios";
import authHeader from '../../../authheader'

const EFREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/FirmEfReinstatement";

const getContactInformation = (reinstatementId) => {
    return axios.get(`${EFREINSTATEMENTURL}/contactinformation`, { headers: authHeader(), params: { reinstatementId } });
}
export default {
    getContactInformation
}