import axios from "axios";
import authHeader from '../../../authheader'

const eiGetSection = (renewalId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualEiRenewal/section`, { headers: authHeader(), params: { renewalId, sectionId } });
}
const eiPostSection = (renewalId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualEiRenewal/signsection`, null, { headers: authHeader(), params: { renewalId, sectionId, electronicSignature } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 6,
        sectionTitle: "Journal & Phone Privacy Options",
        sectionContent: "Verify your subscription and distribution options.",
    },
    {
        id: 7,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 8,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    eiGetSection,
    eiPostSection
}