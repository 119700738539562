import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ControlLabel, FormControl, FormGroup, Input, InputPicker } from 'rsuite';
import RedStar from '../../GenericComponents/RedStar';
import { getStates } from '../../../../services/Shared/codeTable.service'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import PhoneNumberMask from '../../GenericComponents/PhoneNumberMask';

function FirmInformation({ isUserEditable, isForApp, isReadOnly }) {
    const [states, setStates] = useState([])

    useEffect(() => {
        getStates().then(setStates);
    }, [])
    return (
        <Card>
            <Card.Header>


                <Row>
                    <Col lg={6}>
                        <h6>Firm Information</h6>
                    </Col>
                    {(isReadOnly && isUserEditable) &&
                        <Col lg={6}>
                            <Row>
                                <Col lg={9}>
                                <b style={{ color: "red" }}>NOTE:</b> By clicking the Update Contact Information button, the Update Contact Information section will open in a new tab. After you save your changes in this new tab, close this tab and refresh your page to continue your application, renewal, or reinstatement.</Col>
                            <Col lg={3}><Button as={Link} to="/firm/contactinformation" variant="dark" target="_blank" >
                                    Update Contact Information</Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>


            </Card.Header>
            <Card.Body>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Firm Name (Domicile)&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                name="name"
                                plaintext />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Federal taxpayer identification number (TIN)&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext name="federalId" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>Company Email&nbsp;<RedStar /></strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext={isReadOnly} name="primaryEmail" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Company Website
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext={isReadOnly} name="url" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Business Phone <RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            {
                                isReadOnly
                                    ?
                                    <FormControl
                                        accepter={Input}
                                        name="phoneNum"
                                        plaintext={isReadOnly}
                                    />
                                    :
                                    <FormControl
                                        accepter={PhoneNumberMask}
                                        name="phoneNum"
                                        format="(###) ###-####x#####"
                                        mask="_"
                                    />
                            }
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Contact Person&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext={isReadOnly} name="contactName" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Type of Firm&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext name="orgType" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Domicile Firm State&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                accepter={InputPicker}
                                placeholder="Please Select"
                                block
                                data={states}
                                name="domicileState"
                                searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                tabIndex="-1"
                                plaintext />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Date Formed/Incorporated&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                name="dateOfFormation"
                                type="date"
                                plaintext
                                tabIndex="-1"
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <hr />
                <FormGroup>
                    <Row>
                        <Col>
                            <ControlLabel>
                                <strong>
                                    Brief description of services to be provided by the firm in Louisiana <RedStar />
                                </strong>
                                <br />
                                <em>Max 1000 characters including spaces</em>
                            </ControlLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormControl
                                accepter={Input}
                                componentClass="textarea"
                                rows={5}
                                name="additionalInformation"
                                plaintext={isReadOnly}
                                maxLength={1000}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </Card.Body>
        </Card >
    );
}
export default FirmInformation;