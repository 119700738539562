import React from 'react'
import { Card } from 'react-bootstrap'

function TemporaryInformationCard({ display }) {
    return (<>
        {display &&
            <>
                <Card>
                    <Card.Body>
                        <h4 className='colorRed'>
                            
                        </h4>
                    </Card.Body>
                </Card>
                <br />
            </>
        }
    </>)
}
export default TemporaryInformationCard