import React, { useState } from 'react';
import { Card } from 'react-bootstrap'
function Instructions() {
    return (
        <div className="peRenewalSectionInfo">
            <Card>
                <Card.Header><h6>Requirements/Information</h6></Card.Header>
                <Card.Body>
                    <ul>
                        <li>Read all of the instructions within each section of the renewal.</li>
                        <li>Complete all required sections.</li>
                    </ul>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Instructions;