import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import renewalService from '../../../../../../services/Public/Renewal/EI/eiRenewal.service';
import violationService from '../../../../../../services/Public/Renewal/EI/violation.service';
import { useSelector } from 'react-redux';
import Information from '../../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/EiInformation';
import Issues from '../../../../../Shared/Card/Issues';
import { getRenewalCodeViolationAnswers } from '../../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../../Shared/Card/Violations';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const { push } = useHistory();
    const sectionId = 4;
    const renewalId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId)

    const [formData, updateFormData] = useState({
        renewalId: renewalId,
        dateCrte: new Date(),
        entityId: entityId,
        id: 0,
        userCrte: "",
        yesAnswerExplanation: ""
    });

    useEffect(() => {
        setHasLoaded(false);
        renewalService.eiGetSection(renewalId, sectionId).then((response) => {
            violationService.getViolation(renewalId).then((data) => {
                if (data.data) {

                    updateFormData(data.data);
                }
                else {
                    getRenewalCodeViolationAnswers(3).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setHasLoaded(true);
            })
        })

    }, [])

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        yesAnswerExplanation: StringType().isRequired('This field is required.')
    });
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);


    const handleSubmit = (val, event) => {
        setShowFormErrors(true)
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }
            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            violationService.postViolation(formData).then((response) => {
                if (response.data.id) {
                    push(routes.filter(x => x.sectionId == (sectionId + 2))[0].route)
                }

            })
        }
    }



    return (
        <div className="eiRenewalViolationsPage">
            <Container fluid>
                <strong>Engineer Intern Renewal</strong>
                <h4 className="mb-3">Violations</h4>
                <Information content={GetRenewalInformationContent("violations")} />
                <br />
                <Form model={model} readOnly={!sectionData.isUserEditable} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <GenericViolations
                        showFormErrors={showFormErrors}
                        showAdditionalFields={showAdditionalFields}
                        hideSignature={true}

                        isReadOnly={!sectionData.isUserEditable}
                        sectionId={sectionId}
                        sectionDataId={sectionData.id}
                        parentTypeId={3}
                        credentialTypeId={3}
                    />
                    <br />
                    <Issues isUserEditable={true} type={3} sectionId={sectionData.id} />
                    <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/eirenewal/sections" />
                </Form>
            </Container>
        </div>
    )
}
export default Violations;