import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import EducationRecord from './EducationRecords';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/LsiInformation';
import { useSelector } from 'react-redux';
import applicationService from '../../../../../../services/Public/Application/LSI/lsiApplication.service';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../../Shared/Card/Issues';

function EducationPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);
    const { push } = useHistory();
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 2;
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        setHasLoaded(false);
        applicationService.lsiGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            setHasLoaded(true);
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesEducation: BooleanType().isRequired('This field is required.'),
        hasAnsacDegree: BooleanType().isRequired('This field is required.'),
        hasFourYearDegree: BooleanType().isRequired('This field is required.')
    });

    const submitForm = (validation, event) => {
        if (!sectionData.isUserEditable)
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
        else {
            var validationObject = model.check(sectionData);
            for (const key in validationObject) {
                if (validationObject[key].hasError) {
                    if (key == "hasFourYearDegree" && sectionData.hasAnsacDegree == true) {
                        continue;
                    }
                    else {
                        return;
                    }
                }
            }
            if (sectionData.hasFourYearDegree && !hasFiles) {
                return;
            }
            applicationService.lsiPostEducationSection(appId, sectionId, sectionData.hasMyNceesEducation, sectionData.hasAnsacDegree, sectionData.hasFourYearDegree).then(() => {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
            })
        }
    }
    return (
        <div className="lsiAppEducationPage">
            <Container fluid>
                <strong>Land Surveyor Intern Application</strong>
                <h4 className="mb-3">Education</h4>
                <Information content={GetAppInformationContent("education")} />
                <br />
                {hasLoaded ? <>
                    <Form
                        readOnly={!sectionData.isUserEditable}
                        model={model}
                        fluid
                        formValue={sectionData}
                        onSubmit={submitForm}
                        onChange={data => setSectionData(data)}
                    >
                        <EducationRecord
                            applicationId={appId}
                            isReadOnly={!sectionData.isUserEditable}
                            hasMyNceesEducation={sectionData.hasMyNceesEducation}
                            hasFourYearDegree={sectionData.hasFourYearDegree}
                            hasAnsacDegree={sectionData.hasAnsacDegree}
                            hasFiles={hasFiles}
                            setHasFiles={setHasFiles}
                            sectionId={sectionId}
                            sectionDataId={sectionData.id}
                        />
                        <br />
                        <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/lsiApp/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default EducationPage;