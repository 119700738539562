import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useSelector } from 'react-redux';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import Information from '../../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import AddEditPersonalReferenceModal from '../../../../../Shared/Modal/AddEditPersonalReferenceModal';
import Issues from '../../../../../Shared/Card/Issues';
import References from '../../../../../Shared/Card/References';

import personalReferenceService from '../../../../../../services/Public/Reinstatement/PLS/personalReference.service'
import reinstatementService from '../../../../../../services/Public/Reinstatement/PLS/plsReinstatement.service'
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

function PersonalReferencesPage() {
    const { push } = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(true);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const sectionId = 6;
    const routes = useSelector(x => x.currentRoutes);
    const [modalData, setModalData] = useState({
        address: {
            state: null,
            country: null,
            city: '',
            postalCode: '',
            streetAddress: '',
            id: 0,
            parentId: 0,
            parentType: null,
            addressType: {
                id: 2,
                addressType: "Mailing Address"
            },
            entityId: 0,
            provinceRegion: "",
            county: null,
            userCrte: "",
            dateCrte: null,
        },
        firstOccasionFirmAddress: {
            state: null,
            country: null,
            city: '',
            postalCode: '',
            streetAddress: '',
            id: 0,
            parentId: 0,
            parentType: null,
            addressType: {
                id: 2,
                addressType: "Mailing Address"
            },
            entityId: 0,
            provinceRegion: "",
            county: null,
            userCrte: "",
            dateCrte: null,
        },
        secondOccasionFirmAddress: {
            state: null,
            country: null,
            city: '',
            postalCode: '',
            streetAddress: '',
            id: 0,
            parentId: 0,
            parentType: null,
            addressType: {
                id: 2,
                addressType: "Mailing Address"
            },
            entityId: 0,
            provinceRegion: "",
            county: null,
            userCrte: "",
            dateCrte: null,
        },
        reinstatementId: 0,
        dateCrte: null,
        deleted: false,
        email: "",
        entityId: 0,
        id: 0,
        name: "",
        userCrte: "",
        verificationStatus: null,
        membersOfSameFirm: null,
        notMembersOfSameFirmExplanation: '',
        dateFromFirstOccasion: null,
        dateToFirstOccasion: null,
        firstOccasionFirmName: '',
        firstOccasionPosition: '',
        firstOccasionReferencePosition: '',
        dateToSecondOccasion: null,
        dateFromSecondOccasion: null,
        secondOccasionFirmName: '',
        secondOccasionPosition: '',
        secondOccasionReferencePosition: '',
        modalType: "Add"
    });
    const [gridData, setGridData] = useState([]);
    const [sectionData, setSectionData] = useState({});

    useEffect(() => {
        setHasLoaded(false)
        reinstatementService.plsGetSection(reinstatementId, sectionId).then((response) => {
            setSectionData(response.data);
            updateGridData();
            setHasLoaded(true);
        })
    }, [])


    const updateGridData = () => {
        personalReferenceService.getPersonalReferences(reinstatementId).then((response) => {
            setGridData(response.data);
        });
    }
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.'),
        hasReferences: BooleanType().isRequired('This field is required.')
    });
    const openAddEditModal = (values) => {
        if (values.id) {
            var data = {
                ...values,
                dateToFirstOccasion: values.dateToFirstOccasion ? dayjs(values.dateToFirstOccasion).format('YYYY-MM') : null,
                dateFromFirstOccasion: values.dateFromFirstOccasion ? dayjs(values.dateFromFirstOccasion).format('YYYY-MM') : null,
                dateToSecondOccasion: values.dateToSecondOccasion ? dayjs(values.dateToSecondOccasion).format('YYYY-MM') : null,
                dateFromSecondOccasion: values.dateFromSecondOccasion ? dayjs(values.dateFromSecondOccasion).format('YYYY-MM') : null,
                modalType: "Edit"
            }
            setModalData(data);
        }
        else {
            setModalData({
                address: {
                    state: null,
                    country: null,
                    city: '',
                    postalCode: '',
                    streetAddress: '',
                    id: 0,
                    parentId: 0,
                    parentType: null,
                    addressType: {
                        id: 2,
                        addressType: "Mailing Address"
                    },
                    entityId: entityId,
                    provinceRegion: "",
                    county: null,
                    userCrte: "",
                    dateCrte: null,
                },
                firstOccasionFirmAddress: {
                    state: null,
                    country: null,
                    city: '',
                    postalCode: '',
                    streetAddress: '',
                    id: 0,
                    parentId: 0,
                    parentType: null,
                    addressType: {
                        id: 2,
                        addressType: "Mailing Address"
                    },
                    entityId: entityId,
                    provinceRegion: "",
                    county: null,
                    userCrte: "",
                    dateCrte: null,
                },
                secondOccasionFirmAddress: {
                    state: null,
                    country: null,
                    city: '',
                    postalCode: '',
                    streetAddress: '',
                    id: 0,
                    parentId: 0,
                    parentType: null,
                    addressType: {
                        id: 2,
                        addressType: "Mailing Address"
                    },
                    entityId: entityId,
                    provinceRegion: "",
                    county: null,
                    userCrte: "",
                    dateCrte: null,
                },
                reinstatementId: reinstatementId,
                dateCrte: null,
                deleted: false,
                email: "",
                entityId: entityId,
                id: 0,
                name: "",
                userCrte: "",
                verificationStatus: null,
                membersOfSameFirm: null,
                notMembersOfSameFirmExplanation: '',
                dateFromFirstOccasion: null,
                dateToFirstOccasion: null,
                firstOccasionFirmName: '',
                firstOccasionPosition: '',
                firstOccasionReferencePosition: '',
                dateToSecondOccasion: null,
                dateFromSecondOccasion: null,
                secondOccasionFirmName: '',
                secondOccasionPosition: '',
                secondOccasionReferencePosition: '',
                modalType: "Add"
            })
        }
        setModalShow(true);
    }

    const formSubmit = (validation, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                reinstatementService.plsPostSection(reinstatementId, sectionId, sectionData.electronicSignature).then((signatureResponse) => {
                    reinstatementService.plsPostSectionHasRef(reinstatementId, sectionId, sectionData.hasReferences).then((response) => {
                        if (response.data && signatureResponse.data) // If both return something it's good to go
                            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    });
                });
            }
        }
    }

    const formSave = (event) => {
        reinstatementService.plsPostSection(reinstatementId, sectionId, sectionData.electronicSignature).then(() => {
            reinstatementService.plsPostSectionHasRef(reinstatementId, sectionId, sectionData.hasReferences).then(() => {
                Swal.fire("Saved!")
            })
        });
    }

    const deleteRow = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                personalReferenceService.postPersonalReference({ ...rowData, deleted: true }).then((response) => {
                    updateGridData();
                })
            }
        })
    }

    const emailReference = (rowData) => {
        personalReferenceService.sendPersonalReferenceEmail(rowData.id).then((response) => {
            if (response.data) {
                Swal.fire(`You have successfully sent an email to ${rowData.name} at ${rowData.email}!`);
                updateGridData();
            }
            else
                Swal.fire(`Failed to send email: ${response.data.message}`);
        })
    }


    return (
        <div>
            <Container fluid>
                <b>Professional Land Surveyor Reinstatement</b>
                <h4 className="mb-3">References</h4>
                <Information content={GetReinstatementInformationContent("personalReference")} />
                <br />
                <h4>
                    Personal References
            </h4>
                {hasLoaded ? <>
                    <AddEditPersonalReferenceModal
                        updategrid={updateGridData}
                        modaldata={modalData}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onsave={personalReferenceService.postPersonalReference}
                    />
                    <Form
                        readOnly={!sectionData.isUserEditable}
                        model={model}
                        fluid
                        formValue={sectionData}
                        onChange={data => { setSectionData(data) }}
                        onSubmit={formSubmit}  >

                        <References
                            updateGrid={updateGridData}
                            isReadOnly={!sectionData.isUserEditable}
                            openModal={openAddEditModal}
                            griddata={gridData}
                            hasReferences={sectionData.hasReferences}
                            deleteRow={deleteRow}
                            emailReference={emailReference}
                        />

                        <br />
                        <Issues isUserEditable={true} type={2} sectionId={sectionData.id} />
                        <FormActionButtons
                            backToSectionLink="/individual/plsReinstatement/sections"
                            handleSave={formSave}
                            isReadOnly={ !sectionData.isUserEditable} />
                    </Form>
                </> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}

export default PersonalReferencesPage
