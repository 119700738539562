import React from 'react';
import { Card } from 'react-bootstrap'

function Information({content, isQuiz}) {
    return (
        <Card>
            <Card.Header><h6>{isQuiz && <span>Quiz </span>}Requirements/Information</h6></Card.Header>
            <Card.Body>
                { content}
            </Card.Body>
        </Card>
        )
}
export default Information