import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ControlLabel, FormControl, FormGroup, Radio, RadioGroup, Uploader } from 'rsuite';
import RedStar from '../GenericComponents/RedStar';
import DocumentUpload from '../Upload/DocumentUpload';

const ErrorMessage = ({ children }) => <span style={{ color: 'red' }}>{children}</span>;


const ViolationQuestion = ({ rowValue = {}, onChange, rowIndex, showFormErrors, isReadOnly, props }) => {
    const [errorMsg, setErrorMsg] = useState((rowValue.answer === true || rowValue.answer === false) ? "" : "This field is required.");
    const [explanationErrorMsg, setExplanationErrorMsg] = useState((!rowValue.violationExplanation && rowValue.codeViolationQuestion.hasExplanation) ? "This field is required." : "");
    const handleChangeAnswer = value => {
        if (value === true || value === false)//undefined check
            setErrorMsg("");
        onChange(rowIndex, { ...rowValue, answer: value });
    };
    const handleExplainationChange = value => {
        if (value) {
            setExplanationErrorMsg("");
        }
        else {
            setExplanationErrorMsg("This field is required.");
        }
        onChange(rowIndex, { ...rowValue, violationExplanation: value });
    }
    return (
        <>
            <p>
                {rowValue.codeViolationQuestion.question} <RedStar />
                <br />
                <FormGroup controlId="violationCheckbox">
                    <FormControl
                        readOnly={isReadOnly}
                        inline
                        onChange={handleChangeAnswer}
                        accepter={RadioGroup}
                        value={rowValue.answer}
                    >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </FormControl>
                    {showFormErrors ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
                </FormGroup>
                {(rowValue.codeViolationQuestion.hasExplanation && rowValue.answer === true) ?
                    <>
                        <p><RedStar />{rowValue.codeViolationQuestion.explanationText}</p>
                        <FormGroup>
                            <FormControl
                                readOnly={isReadOnly}
                                name="violationCheckboxExplaination"
                                componentClass="textarea"
                                rows={4}
                                onChange={handleExplainationChange}
                                value={rowValue.violationExplanation}
                                errorMessage={showFormErrors ? explanationErrorMsg : ""}
                            />
                            {showFormErrors ? <ErrorMessage>{explanationErrorMsg}</ErrorMessage> : null}
                        </FormGroup>
                        <DocumentUpload
                            attachmentSection={"ViolationQuestionId" + rowValue.codeViolationQuestion.id}
                            isUserEditable={!props.isReadOnly}
                            credentialTypeId={props.credentialTypeId}
                            sectionId={props.sectionId}
                            appTypeId={props.appTypeId}
                            parentId={props.sectionDataId}
                            parentTypeId={props.parentTypeId}
                            entityId={props.entityId}
                            fileUploadTitle={"Additional Pages/Court Documents"}
                        />
                    </>
                    : ""}
            </p>
        </>
    );
};
const ViolationQuestionControl = ({ value = [], onChange, showFormErrors, isReadOnly, props }) => {
    const [answers, setAnswers] = useState(value);
    useEffect(() => { setAnswers(value) }, [value])
    const handleChangeAnswers = nextAnswers => {
        setAnswers(nextAnswers);
        onChange(nextAnswers);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextAnswers = [...answers];
        nextAnswers[rowIndex] = value;
        handleChangeAnswers(nextAnswers);
    };
    return (
        <>
            {answers.map((rowValue, index) => (
                <ViolationQuestion
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    showFormErrors={showFormErrors}
                    onChange={handleInputChange}
                    isReadOnly={isReadOnly}
                    props={props}
                />
            ))}
        </>
    );
};

function Violations(props) {
    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <h6>Violations</h6>
                    </Col>
                    {props.downloadViolations &&
                        <Col className="text-right">
                            <Button onClick={props.downloadViolations}>Download PDF Version</Button>
                        </Col>
                    }
                </Row>
            </Card.Header>
            <Card.Body>
                <FormControl
                    name="answers"
                    accepter={ViolationQuestionControl}
                    showFormErrors={props.showFormErrors}
                    isReadOnly={props.isReadOnly}
                    props={props}
                />
                {props.showAdditionalFields ?
                    <>
                        <p><RedStar /> If the answer to any of these questions is "Yes", provide the date and the pertinent facts of the case or proceeding including its final disposition. Upload
                            additional pages if necessary.</p>
                        <FormGroup>
                            <FormControl readOnly={props.isReadOnly} name="yesAnswerExplanation" componentClass="textarea" rows={4} />
                        </FormGroup>

                        <DocumentUpload
                            isUserEditable={!props.isReadOnly}
                            credentialTypeId={props.credentialTypeId}
                            sectionId={props.sectionId}
                            appTypeId={props.appTypeId}
                            //refreshData={ }
                            parentId={props.sectionDataId}
                            parentTypeId={props.parentTypeId}
                            entityId={props.entityId}
                            fileUploadTitle={"Additional Pages/Court Documents"}
                        />
                    </>
                    :
                    null} {props.showBlukViolationExplanation ?
                        <>
                            <p><RedStar /> {props.showBlukViolationExplanation}</p>
                            <FormGroup>
                                <FormControl readOnly={props.isReadOnly} name="yesAnswerExplanation" componentClass="textarea" rows={4} />
                            </FormGroup>

                            <DocumentUpload
                                attachmentSection="ViolationBulkExplanation"
                                isUserEditable={!props.isReadOnly}
                                credentialTypeId={props.credentialTypeId}
                                sectionId={props.sectionId}
                                appTypeId={props.appTypeId}
                                parentId={props.sectionDataId}
                                parentTypeId={props.parentTypeId}
                                entityId={props.entityId}
                                fileUploadTitle={"Additional Pages/Court Documents"}
                            />
                        </>
                        :
                        null}
                {!props.hideSignature &&
                    <>
                        <br />

                        <strong>DISCLOSURE</strong>
                        <p>As part of the application process, the Louisiana Professional Engineering and Land Surveying Board (LAPELS) will obtain a consumer report, which I understand may
                            include information regarding my credit worthiness, credit standing, credit capacity, character, general reputation, personal characteristics, or mode of living.
                        </p>
                        <br />
                        <strong>AUTHORIZATION</strong>
                        <p>
                            During the application process and at any time during the tenure of my licensure with LAPELS, I hereby authorize First Advantage, on behalf of
                            LAPELS, to procure a consumer report which I understand may include information regarding my credit worthiness, credit standing, credit capacity, character,
                            general reputation, personal characteristics, or mode of living. This report may be compiled with information from credit bureaus, court record repositories, departments of
                            motor vehicles, past or present employers and educational institutions, governmental occupational licensing or registration entities, business or personal references,
                            and any other source required to verify information that I have voluntarily supplied. I understand that I may request a complete and accurate disclosure of the nature
                            and scope of the background verification and a written summary of consumer rights; to the extent such investigation includes information bearing on my character, general
                            reputation, personal characteristics or mode of living.
                        </p>
                        <hr />
                        <FormGroup>
                            <Row>
                                <Col sm="5">
                                    <ControlLabel>
                                        <h5>
                                            Electronic Signature of Applicant:<RedStar />
                                        </h5>
                                        (type your name)
                                    </ControlLabel>
                                </Col>
                                <Col sm="7">
                                    <FormControl readOnly={props.isReadOnly} name="signature" placeholder="" aria-label="" />
                                </Col>
                            </Row>
                        </FormGroup>
                    </>
                }
            </Card.Body>
        </Card>
    )
}
export default Violations;