import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import generalEntityInfoService from '../../../../../services/Internal/generalEntityInfo.service';
import { Icon, Table } from 'rsuite';
import GenericPagination from '../../../../Shared/Grid/GenericPagination';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import WarningModal from './WarningModal';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';

function WarningsPage({ entityId, setWarningNumber, activeKey }) {
    const [warningHasLoaded, setWarningHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});
    const [warningData, setWarningData] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (activeKey == "warnings")
            refreshGrid();
    }, [activeKey])
    useEffect(() => {
        refreshGrid();
    }, [])

    function refreshGrid() {
        setWarningHasLoaded(false);
        generalEntityInfoService.getWarnings(entityId).then((response) => {
            setWarningData(response.data);
            setWarningNumber(response.data.filter((item) => (item.clearedDate == null) || (dayjs(item.clearedDate) > dayjs())).length);
            setWarningHasLoaded(true);
        })
    }

    const openModal = (values) => {
        if (values.id) {
            setModalData(
                {
                    ...values,
                    clearedDate: values.clearedDate ? dayjs(values.clearedDate).format('YYYY-MM-DD') : null,
                    effectiveDate: values.effectiveDate ? dayjs(values.effectiveDate).format('YYYY-MM-DD') : null,
                    reason: values.issue,
                    modalType: 'Edit'
                });
        }
        else {
            setModalData({
                id: 0,
                entityId: entityId,
                reason: "",
                issueType: null,
                clearedDate: '',
                effectiveDate: '',
                suppressLicensePrint: null,
                userCrte: '',
                dateCrte: '',
                clearedBy: '',
                issue: '',
                modalType: "Add"
            })
        }
        setModalShow(true);
    }
    const deleteRow = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                generalEntityInfoService.deleteWarning(rowData.id).then((response) => {
                    if (response)
                        refreshGrid();
                })
            }
        })
    }
    const getData = () => {
        return warningData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    return (
        <>
            {warningHasLoaded ?
                <>
                    {
                        modalShow &&
                        <WarningModal modalData={modalData} show={modalShow} onHide={() => setModalShow(false)} updateGrid={() => { refreshGrid() }} />
                    }
                    <Table wordWrap autoHeight data={filteredData}>

                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Effective Date</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.effectiveDate ? new Date(rowData.effectiveDate).toLocaleDateString() : '')}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Cleared Date</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.clearedDate ? new Date(rowData.clearedDate).toLocaleDateString() : '')}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Type</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.issueType.issueType)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Reason</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.issue)}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Suppress License Print</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.suppressLicensePrint ? "Yes" : "No")}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Created By</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.staffMember)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={150}>
                            <Table.HeaderCell>
                                <b>Action(s)</b>
                            </Table.HeaderCell>
                            <Table.Cell dataKey="id">
                                {rowData => {
                                    function handleAction() {
                                        var tempData = { ...rowData, modalType: 'Edit' }
                                        openModal(tempData);
                                    }
                                    return (
                                        <>
                                            <Icon icon="edit2" size="2x" title="Edit" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                        <Icon icon="trash" size="2x" title="Delete" onClick={() => { deleteRow(rowData) }} style={{ cursor: "pointer" }} />
                                        </>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                    <GenericPagination dataLength={warningData.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                    <Row>
                        <Col>
                            <span><Button variant="danger" className="float-right" onClick={openModal}>Add Warning</Button></span>
                        </Col>
                    </Row>
                </> : <LoadingSpinner />
            }
        </>
    )
}
export default WarningsPage;