import axios from "axios";
import authHeader from '../../../authheader'

const plsGetSection = (applicationId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/section`, { headers: authHeader(), params: { applicationId, sectionId } });
}
const plsPostSection = (applicationId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/signsection`, null, { headers: authHeader(), params: { applicationId, sectionId, electronicSignature } });
}
const plsPostSectionHasRef = (applicationId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/setsectionhasreferences`, null, { headers: authHeader(), params: { applicationId, sectionId, hasReferences } });
}
//const plsPostSectionHasMyNceesEducation = (applicationId, sectionId, hasMyNceesEducation) => {
//    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/setsectionhasmynceeseducation`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation } });
//}

const plsPostSectionHasExperience = (applicationId, sectionId, hasMyNceesExperience) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/setsectionhasmynceesexperience`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesExperience } });
}

const plsPostEducationSection = (applicationId, sectionId, hasMyNceesEducation, hasAnsacDegree, hasFourYearDegree) =>
{
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/seteducationsection`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation, hasAnsacDegree, hasFourYearDegree } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
    },
    {
        id: 3,
        sectionTitle: "Personal References",
        sectionContent: "Complete this section listing all personal references.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 5,
        sectionTitle: "Employment",
        sectionContent: "Verify Louisiana Employment Firm licensure",
    },
    {
        id: 6,
        sectionTitle: "Quizzes",
        sectionContent: "Upload the confirmation for Online Laws and Rules, the Online Professionalism & Ethics and the Online Louisiana Standards of Practice for Boundary Surveys quizzes.",
    },
    {
        id: 7,
        sectionTitle: "Experience Record",
        sectionContent: "Include a GRAND TOTAL of all experience",
    },
    {
        id: 8,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 9,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    plsGetSection,
    plsPostSection,
    plsPostSectionHasRef,
    //plsPostSectionHasMyNceesEducation,
    plsPostSectionHasExperience,
    plsPostEducationSection
}