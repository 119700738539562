import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import TopInformationCard from '../Shared/TopInformationCard';
import CommentsCard from '../Shared/CommentsCard';
import DocumentUploadsCard from '../Shared/DocumentUploadsCard';
import CpdLockerCard from './CpdLockerCard';
import CredentialCard from './CredentialCard';
import EmploymentCard from './EmploymentCard';
import GeneralInfoCard from './GeneralInfoCard';
import SupervisesCard from './SupervisesCard';
import recentContactService from '../../../../services/Internal/Recent/recentContacts.service'

function CredentialPage() {
    const [entityId, setEntityId] = useState(0);
    const [updateSupervisesCard, setUpdateSupervisesCard] = useState(0);
    const [updateCommentCard, setUpdateCommentCard] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.entityId) {
            setEntityId(params.entityId);
        }
        const recentContact = { entityId: params.entityId, isFirm: false, entityName: "" }
        recentContactService.updateRecentContacts(recentContact).then((data) => {
            var token = parseInt(localStorage.getItem('updateRecentContacts')) ? parseInt(localStorage.getItem('updateRecentContacts')) : 0;
            localStorage.setItem('updateRecentContacts', token + 1)
            dispatch({ type: "PERSIST_CURRENT_RECENT_CONTACTS", payload: parseInt(localStorage.getItem('updateRecentContacts')) });
        })
    }, [])

    const updateSupervisesData = () => { setUpdateSupervisesCard(updateSupervisesCard + 1) }
    const updateCommentData = () => { setUpdateCommentCard(updateCommentCard + 1) }


    return (
        <Container fluid>
            {entityId > 0 ?
                <>
                    <Row className="mb-3"><Col><TopInformationCard isIndividual={true} entityId={entityId} /></Col></Row>
                    <Row className="mb-3">
                        <Col><GeneralInfoCard entityId={entityId} /></Col>
                        <Col><CredentialCard updateSupervisesCardCallback={updateSupervisesData} updateCommentCardCallback={updateCommentData} entityId={entityId} /></Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><SupervisesCard updateData={updateSupervisesCard} entityId={entityId} /></Col>
                        <Col><EmploymentCard entityId={entityId} /></Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><CpdLockerCard entityId={entityId} /></Col>
                    </Row>
                    <Row className="mb-3"><Col><CommentsCard entityId={entityId} updateData={updateCommentCard} /></Col></Row>
                    <Row className="mb-3"><Col><DocumentUploadsCard entityId={entityId} isIndividual={true} /></Col></Row>
                </> : <h2>No Entity ID provided. Please use the search.</h2>
            }
        </Container>
    )
}
export default CredentialPage