import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import recentQueueService from '../../../services/Internal/Recent/recentQueue.service';
import redirectToSectionService from '../../../services/Shared/redirectToSection.service';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';

function RedirectToSection(props) {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const isInternal = props.linkPrefix == "internal";


    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        /*
    * Example:
    * We get a sectionParentTypeId of 1 and sectionId of 13
    * We look into the Application.Section table for ID 13
    * that section is where we have to go so we will fill the session storage with all relevant information
    * then we push to the section
    */
        if (params.sectionParentTypeId && params.sectionId) {
            redirectToSectionService.getSectionData(params.sectionParentTypeId, params.sectionId).then((data) => {
                var vm = data.data;
                switch (vm.taskTypeId) {
                    case 1://Application
                        navigateAndSetCurrentApplication(vm);
                        break;
                    case 2://Renewal
                        navigateAndSetCurrentRenewal(vm);
                        break;
                    case 3://Reinstatement
                        navigateAndSetCurrentReinstatement(vm);
                        break;
                    default:
                }
            })
        }
        else if (params.issueId) {
            redirectToSectionService.getSectionDataFromIssueId(params.issueId).then((data) => {
                var vm = data.data;
                switch (vm.taskTypeId) {
                    case 1://Application
                        navigateAndSetCurrentApplication(vm);
                        break;
                    case 2://Renewal
                        navigateAndSetCurrentRenewal(vm);
                        break;
                    case 3://Reinstatement
                        navigateAndSetCurrentReinstatement(vm);
                        break;
                    default:
                }

            })
        }
        else if (params.parentTypeId && params.parentId) {
            redirectToSectionService.getTaskData(params.parentTypeId, params.parentId).then((data) => {
                var vm = data.data;
                switch (vm.taskTypeId) {
                    case 1://Application
                        navigateAndSetCurrentApplication(vm);
                        break;
                    case 2://Renewal
                        navigateAndSetCurrentRenewal(vm);
                        break;
                    case 3://Reinstatement
                        navigateAndSetCurrentReinstatement(vm);
                        break;
                    default:
                }
            })
        }
        else {
            //Show an error message with redirect back to home button?
        }
    }, [])

    const navigateAndSetCurrentApplication = (data) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: data.parentId });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: data.entityId });
        dispatch({ type: "PERSIST_CURRENT_APP_TYPE_ID", payload: data.parentCredTypeId });
        var baseLink = `/${props.linkPrefix}/`;
        switch (data.parentCredTypeId) {
            case 1:
                baseLink += "eiapp/";
                break;
            case 2:
                baseLink += "lsiapp/";
                break;
            case 3:
            case 4:
                baseLink += "peapp/";
                break;
            case 5:
            case 6:
                baseLink += "plsApp/";
                break;
            case 7:
                baseLink += "efapp/";
                break;
            case 8:
                baseLink += "vfApp/";
                break;
        }
        var routes = [];
        if (data?.sectionIds?.length) {
            for (var i = 0; i < data.sectionIds.length; i++) {
                let sectionId = data.sectionIds[i]
                var link = baseLink;
                link += getAppSection(sectionId);

                routes.push({ sectionId: sectionId, route: link })
            }
            dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: routes })
        }
        if (isInternal) {
            recentQueueService.updateRecentQueue({
                entityId: data.entityId,
                parentId: data.parentId,
                parentTypeId: 2, //application, i hate this
                appTypeId: data.parentCredTypeId,
                routes: JSON.stringify(routes)
            }).then((data) => {
                var token = parseInt(localStorage.getItem('updateRecentQueue')) ? parseInt(localStorage.getItem('updateRecentQueue')) : 0;
                localStorage.setItem('updateRecentQueue', token + 1)
                dispatch({ type: "PERSIST_CURRENT_RECENT_QUEUE", payload: parseInt(localStorage.getItem('updateRecentQueue')) });
            })
        }
        push(baseLink + getAppSection(data.currentSectionId));
    }
    const getAppSection = (sectionId) => {
        var section = "";
        switch (sectionId) {
            case 0:
                section = "sections"
                break;
            case 1:
                section = "contactInformation"
                break;
            case 2:
                if (isInternal)
                    section = "education"
                else
                    section = "educationrecord"
                break;
            case 3:
                section = "personalReferences"
                break;
            case 4:
                section = "violations"
                break;
            case 5:
                section = "employment"
                break;
            case 6:
                section = "quizzes"
                break;
            case 7:
                section = "experience"
                break;
            case 8:
                section = "acknowledgement"
                break;
            case 9:
                section = "review"
                break;
            case 10:
                section = "boardApproval"
                break;
            case 11:
                if (isInternal)
                    section = "examinationVerification"
                else
                    section = "examination"
                break;
            case 12:
                section = "recommendation";
                break;
            case 13:
                section = "waiver";
                break;
            case 14:
                section = "supervisingProfessional";
                break;
            case 15:
                section = "unlicensedActivity";
                break;
            case 16:
                section = "coopexperience";
                break;
            case 17:
                section = "residency";
                break;
            default:
                break;
        }
        return section;
    }

    const navigateAndSetCurrentRenewal = (data) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: data.parentId });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: data.entityId });
        var baseLink = `/${props.linkPrefix}/`;
        switch (data.parentCredTypeId) {
            case 1:
                baseLink += "efrenewal/";
                break;
            case 2:
                baseLink += "vfRenewal/";
                break;
            case 3:
                baseLink += "eirenewal/";
                break;
            case 4:
                baseLink += "lsirenewal/";
                break;
            case 5:
                baseLink += "plsRenewal/";
                break;
            case 6:
                baseLink += "perenewal/";
                break;
        }
        var routes = [];
        if (data?.sectionIds?.length) {
            for (var i = 0; i < data.sectionIds.length; i++) {
                let sectionId = data.sectionIds[i]
                var link = baseLink;
                link += getRenewalSection(sectionId);
                routes.push({ sectionId: sectionId, route: link })
            }
            dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: routes })
        }
        if (isInternal) {
            recentQueueService.updateRecentQueue({
                entityId: data.entityId,
                parentId: data.parentId,
                parentTypeId: 3, //renewal, i hate this
                appTypeId: data.parentCredTypeId,
                routes: JSON.stringify(routes)
            }).then((data) => {
                var token = parseInt(localStorage.getItem('updateRecentQueue')) ? parseInt(localStorage.getItem('updateRecentQueue')) : 0;
                localStorage.setItem('updateRecentQueue', token + 1)
                dispatch({ type: "PERSIST_CURRENT_RECENT_QUEUE", payload: parseInt(localStorage.getItem('updateRecentQueue')) });
            })
        }
        push(baseLink + getRenewalSection(data.currentSectionId));
    }
    const getRenewalSection = (sectionId) => {
        var section = "";
        switch (sectionId) {
            case 0:
                section = "sections"
                break;
            case 1:
                section = "contactInformation"
                break;
            case 2:
                section = "licenseStatus"
                break;
            case 3:
                section = "cpdRequirements"
                break;
            case 4:
                section = "violations"
                break;
            case 5:
                section = "supervisingprofessional"
                break;
            case 6:
                section = "privacy"
                break;
            case 7:
                section = "acknowledgement"
                break;
            case 8:
                section = "review"
                break;
            case 9:
                section = "waiver"
                break;
            case 10:
                section = "design"
                break;
            default:
        }
        return section;
    }

    const navigateAndSetCurrentReinstatement = (data) => {
        dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: data.parentId });
        dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: data.entityId });
        var baseLink = `/${props.linkPrefix}/`;
        switch (data.parentCredTypeId) {
            case 1:
                baseLink += "efreinstatement/";
                break;
            case 2:
                baseLink += "vfReinstatement/";
                break;
            case 3:
                baseLink += "eiReinstatement/";
                break;
            case 4:
                baseLink += "lsiReinstatement/";
                break;
            case 5:
                baseLink += "plsReinstatement/";
                break;
            case 6:
                baseLink += "pereinstatement/";
                break;
        }
        var routes = [];
        if (data?.sectionIds?.length) {
            for (var i = 0; i < data.sectionIds.length; i++) {
                let sectionId = data.sectionIds[i]
                var link = baseLink;
                link += getReinstatementSection(sectionId);
                routes.push({ sectionId: sectionId, route: link })
            }
            dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: routes })
        }
        if (isInternal) {
            recentQueueService.updateRecentQueue({
                entityId: data.entityId,
                parentId: data.parentId,
                parentTypeId: 1, //reinstatement, i hate this
                appTypeId: data.parentCredTypeId,
                routes: JSON.stringify(routes)
            }).then((data) => {
                var token = parseInt(localStorage.getItem('updateRecentQueue')) ? parseInt(localStorage.getItem('updateRecentQueue')) : 0;
                localStorage.setItem('updateRecentQueue', token + 1)
                dispatch({ type: "PERSIST_CURRENT_RECENT_QUEUE", payload: parseInt(localStorage.getItem('updateRecentQueue')) });
            })
        }
        push(baseLink + getReinstatementSection(data.currentSectionId));
    }
    const getReinstatementSection = (sectionId) => {
        var section = "";
        switch (sectionId) {
            case 0:
                section = "sections"
                break;
            case 1:
                section = "contactInformation"
                break;
            case 2:
                section = "employment"
                break;
            case 3:
                section = "violations"
                break;
            case 4:
                section = "quizzes"
                break;
            case 5:
                section = "cpdRequirements"
                break;
            case 6:
                section = "personalReferences"
                break;
            case 7:
                section = "experience"
                break;
            case 8:
                section = "disclosure"
                break;
            case 9:
                section = "acknowledgement"
                break;
            case 10:
                section = "review"
                break;
            case 11:
                section = "boardApproval"
                break;
            case 12:
                section = "waiver"
                break;
            case 13:
                section = "supervisingProfessional"
                break;
            case 14:
                section = "unlicensedActivity"
                break;
            case 15:
                section = "design"
                break;
            case 16:
                section = "education"
                break;
            case 17:
                section = "examination"
                break;
            default:
                break;
        }
        return section;
    }

    return (<center>
        <h2>Please Wait</h2>
        <LoadingSpinner />
    </center>);
}
export default RedirectToSection;

