import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getPersonalReferences = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/personalreferences`, { headers: authHeader(), params: {applicationId} });
}
const postPersonalReference = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/personalreference`, data, { headers: authHeader() });
}

const sendPersonalReferenceEmail = (referenceId) => {
    return axios.post(`${PEAPPLICATIONURL}/sendreferenceemail`, null, { headers: authHeader(), params:{referenceId} });
}
const downloadPersonalReferences = (applicationId, credType) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/Reports/GetApplicationPersonalReferences`, { headers: authHeader(), params: { applicationId: applicationId, credType: credType }, responseType: 'arraybuffer' });
}
export default {
    getPersonalReferences,
    postPersonalReference,
    sendPersonalReferenceEmail,
    downloadPersonalReferences
}