import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, FormControl, Schema, TagPicker } from 'rsuite'
import peApplicationService from '../../../../../services/Internal/Application/PE/peApplication.service'
import residencyService from '../../../../../services/Internal/Application/PE/residency.service'
import { getStates } from '../../../../../services/Shared/codeTable.service'
import entityService from '../../../../../services/Internal/Staff/entity.service'
import Information from '../../../../Shared/Card/Information'
import Issues from '../../../../Shared/Card/Issues'
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner'
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PeInformation'
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';


function ResidencyPage() {
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const [model, setModel] = useState({
        applicationId: appId,
        entityId: entityId,
        states: ''
    })
    const [hasLoaded, setHasLoaded] = useState(true)
    const { push } = useHistory();
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const sectionId = 17;
    const [states, setStates] = useState([])
    const [sectionData, setSectionData] = useState({})
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Residency"
        }
    );
    useEffect(() => {
        peApplicationService.peGetSection(appId, sectionId).then((response) => {
            getStates().then(setStates);
            residencyService.getResidency(appId).then((data) => {
                if (data.data)
                    setModel(data.data)
                setIsComplete(response.data.staffReviewComplete)
                applicationService.getIndividualApplication(appId).then((appData) => {
                    setIsUserEditable(appData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setSectionData(response.data);
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                                applicationDate: appData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })
    }, [])

    const { ArrayType } = Schema.Types;
    const validationModel = Schema.Model({
        states: ArrayType().isRequired('This field is required.')
    })
    const formSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (val) {
            residencyService.postResidency(model).then((response) => {
                peApplicationService.peMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            })
        }
    }
    return (
        <Container fluid>
            <FormEntityInformation {...entityInfo} />
            <Information content={GetAppInformationContent("residency")} />
            <br />
            {hasLoaded ?
                <Form readOnly={!isUserEditable} model={validationModel} onChange={setModel} formValue={model} onSubmit={formSubmit}>
                    <Card className="mb-3">
                        <Card.Header>
                            <h6>Residency</h6>
                        </Card.Header>
                        <Card.Body>
                            <b>What state(s) do you currently hold an active license in? Select all choices below that are applicable.</b>
                            <FormControl
                                accepter={TagPicker}
                                placeholder="Select a State"
                                block
                                data={states}
                                searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                name="states"
                            />
                        </Card.Body>
                    </Card>
                    <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                    <FormActionButtons
                        isUserEditable={isUserEditable}
                        showSectionComplete={true}
                        isComplete={isComplete}
                        isCompleteCallback={setIsComplete} backToSectionLink="/internal/peapp/sections" />
                </Form> : <LoadingSpinner />}
        </Container>
    );
}
export default ResidencyPage;