import React from 'react'
import { Badge } from 'react-bootstrap';

function GenericBadge(props) {
    let variant = "danger";
    switch (props.status.toLowerCase()) {
        case "expired":
        case "licensing":
        case "declined":
        case "issue":
            variant = "danger";
            break;
        case "pending":
        case "enforcement":
            variant = "warning";
            break;
        case "active":
        case "complete":
        case "verified":
        case "applications":
        case "approved":
            variant = "success";
            break;
        case 'incomplete':
        case 'board/staff':
        case 'reviewed':
        case 'license':
        case 'hold':
            variant = "secondary";
    }
    return (
        <Badge variant={variant}><b>{props.status}</b></Badge>
    )
}

export default GenericBadge