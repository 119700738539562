import React, { useEffect, useState } from 'react'
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/EiInformation';
import AddEditExperienceRecordsModal from './ExperienceRecordModal';
import Experience from '../../../../../Shared/Card/Experience';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import applicationService from '../../../../../../services/Public/Application/EI/eiApplication.service';
import experienceService from '../../../../../../services/Public/Application/EI/experience.service';
import Issues from '../../../../../Shared/Card/Issues';
import dayjs from 'dayjs';

function ExperiencePage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});

    const [modalShow, setModalShow] = useState(false);
    const [gridData, setGridData] = useState([]);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 7;
    const [modalData, setModalData] = useState({});
    useEffect(() => {
        setHasLoaded(false)
        applicationService.eiGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            updateGridData();
            setHasLoaded(true)
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesExperience: BooleanType().isRequired('This field is required.')
    });
    const openAddEditModal = (values) => {
        if (values.id) {
            setModalData(
                {
                    ...values,
                    verifierEmail: values.emailAddresses[1].contactEmailAddress,
                    verifierPhone: values.phoneNumbers[1].contactPhoneNumber,
                    employerEmail: values.emailAddresses[0].contactEmailAddress,
                    employerPhone: values.phoneNumbers[0].contactPhoneNumber,
                    dateFrom: values.dateFrom ? dayjs(values.dateFrom).format('YYYY-MM') : null,
                    dateTo: values.dateTo ? dayjs(values.dateTo).format('YYYY-MM') : null,
                    modalType: 'Edit'
                });
        }
        else {
            setModalData({
                applicationId: appId,
                comments: "",
                dateCrte: null,
                dateFrom: null,
                dateTo: null,
                deleted: false,
                employerName: "",
                employerPosition: "",
                verifierName: "",
                verifierPosition: '',
                verifierEmail: "",
                verifierPhone: "",
                employerEmail: "",
                employerPhone: "",
                employmentType: null,
                entityId: entityId,
                id: 0,
                userCrte: "",
                position: "",
                location: "",
                description: "",
                acceptableDescription: "",
                emailAddresses: [
                    {
                        contactEmailAddress: '',
                        emailAddressParentType: {
                            id: 10,
                            emailAddressParentType: "Application Experience Employer"
                        },
                        emailAddressType: {
                            id: 1,
                            emailAddressType: ''
                        },
                        id: 0,
                        entityId: entityId,
                        parentId: 0,
                        userCrte: '',
                        dateCrte: null
                    },
                    {
                        contactEmailAddress: '',
                        emailAddressParentType: {
                            id: 11,
                            emailAddressParentType: "Application Experience Verifier"
                        },
                        emailAddressType: {
                            id: 1,
                            emailAddressType: ''
                        },
                        id: 0,
                        entityId: entityId,
                        parentId: 0,
                        userCrte: '',
                        dateCrte: null
                    }
                ],
                phoneNumbers: [
                    {
                        contactPhoneNumber: '',
                        parentType: {
                            id: 10,
                            phoneParentType: "Application Experience Employer"
                        },
                        phoneType: {
                            id: 3,
                            phoneType: ''
                        },
                        id: 0,
                        entityId: entityId,
                        parentId: 0,
                        userCrte: '',
                        dateCrte: null
                    },
                    {
                        contactPhoneNumber: '',
                        parentType: {
                            id: 11,
                            phoneParentType: "Application Experience Verifier"
                        },
                        phoneType: {
                            id: 3,
                            phoneType: ''
                        },
                        id: 0,
                        entityId: entityId,
                        parentId: 0,
                        userCrte: '',
                        dateCrte: null
                    }
                ],
                modalType: "Add"
            })
        }
        setModalShow(true);
    }
    const { push } = useHistory();

    const handleSubmit = (validation, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                applicationService.eiPostSectionHasExperience(appId, sectionId, sectionData.hasMyNceesExperience).then((response) => {
                    if (response.data) // If both return something it's good to go
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                });
            }
        }
    }
    const updateGridData = () => {
        experienceService.getExperienceRecords(appId).then((response) => {
            setGridData(response.data)
        });
    }

    return (
        <div>
            <Container fluid>
                <b>Engineer Intern Application</b>
                <h4 className="mb-3">Experience</h4>
                <Information content={GetAppInformationContent("experience")} />
                <br />
                {hasLoaded ? <>
                    {modalShow &&
                        <AddEditExperienceRecordsModal appType="application" updateGrid={updateGridData} modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} proptitle="Add" />
                    }
                    <Form onSubmit={handleSubmit} model={model} formValue={sectionData} onChange={data => setSectionData(data)}>
                        <Experience appType="application" updateGrid={updateGridData} griddata={gridData} isReadOnly={!sectionData.isUserEditable} openModal={openAddEditModal} hasMyNceesExperience={sectionData.hasMyNceesExperience} isEi={true}/>
                        <br />
                        <Issues isUserEditable={true} type={1} sectionId={sectionData.id} /> 
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/eiapp/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ExperiencePage