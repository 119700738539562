import axios from "axios";
import authHeader from '../../authheader'

const INDIVIDUALRENEWAL = process.env.REACT_APP_APIURL + "/api/IndividualRenewal";
const FIRMRENEWAL = process.env.REACT_APP_APIURL + "/api/FirmRenewal";
const SHOPPINGCART = process.env.REACT_APP_APIURL + "/api/ShoppingCart";

const getPendingRenewals = async () => {
    return axios.get(`${INDIVIDUALRENEWAL}/pendingrenewals`, { headers: authHeader() });
};
const getRenewalBanner = async () => {
    return axios.get(`${INDIVIDUALRENEWAL}/getrenewalbannertext`, { headers: authHeader() });;
};
const getHistoricalRenewals = async () => {
    return axios.get(`${INDIVIDUALRENEWAL}/historicalrenewals`, { headers: authHeader() });
};
const getIndividualRenewal = async (renewalId) => {
    return axios.get(`${INDIVIDUALRENEWAL}/renewal`, { headers: authHeader(), params: { renewalId } });;
};
const wasHeldForCpd = async (renewalId) => {
    return axios.get(`${INDIVIDUALRENEWAL}/wasHeldForCpd`, { headers: authHeader(), params: { renewalId } });;
};
const wasHeldForBuildingDesign = async (renewalId) => {
    return axios.get(`${INDIVIDUALRENEWAL}/wasHeldForBuildingDesign`, { headers: authHeader(), params: { renewalId } });;
};
const wasHeldForSupervisingProfessional= async (renewalId) => {
    return axios.get(`${FIRMRENEWAL}/washeldforsp`, { headers: authHeader(), params: { renewalId } });;
};
const createRenewal = (credentialTypeId, credentialId) => {
    return axios.post(`${INDIVIDUALRENEWAL}/create`, null, { headers: authHeader(), params: { credentialTypeId, credentialId } })
        .then((response) => {
            return response;
        });
}

const validateRenewal = (renewalId) => {
    return axios.post(`${INDIVIDUALRENEWAL}/validate`, null, { headers: authHeader(), params: { renewalId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}


const getFirmPendingRenewals = async () => {
    return axios.get(`${FIRMRENEWAL}/pendingrenewals`, { headers: authHeader() });;
};
const getFirmHistoricalRenewals = async () => {
    return axios.get(`${FIRMRENEWAL}/historicalrenewals`, { headers: authHeader() });;
};
const getFirmRenewal = async (renewalId) => {
    return axios.get(`${FIRMRENEWAL}/renewal`, { headers: authHeader(), params: { renewalId } });;
};

const createFirmRenewal = (credentialTypeId, credentialId) => {
    return axios.post(`${FIRMRENEWAL}/create`, null, { headers: authHeader(), params: { credentialTypeId, credentialId } })
        .then((response) => {
            return response;
        });
}

const validateFirmRenewal = (renewalId) => {
    return axios.post(`${FIRMRENEWAL}/validate`, null, { headers: authHeader(), params: { renewalId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}


export default {
    getPendingRenewals,
    getHistoricalRenewals,
    getIndividualRenewal,
    createRenewal,
    validateRenewal,
    getFirmPendingRenewals,
    getFirmHistoricalRenewals,
    getFirmRenewal,
    createFirmRenewal,
    validateFirmRenewal,
    wasHeldForCpd,
    wasHeldForBuildingDesign,
    wasHeldForSupervisingProfessional,
    getRenewalBanner
    
}