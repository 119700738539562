import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, InputPicker, Schema } from 'rsuite';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import { getStates } from '../../../../../../services/Shared/codeTable.service'
import Information from '../../../../../Shared/Card/Information';
import reinstatementService from '../../../../../../services/Public/Reinstatement/LSI/lsiReinstatement.service';
import examinationService from '../../../../../../services/Public/Reinstatement/LSI/examination.service'
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/LsiInformation';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import Issues from '../../../../../Shared/Card/Issues';

function ExaminationVerification() {
    const [states, setStates] = useState([])
    const routes = useSelector(x => x.currentRoutes);
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const sectionId = 17;
    const [formModel, setFormModel] = useState({
        id: 0,
        reinstatementId: reinstatementId,
        entityId: entityId,
        jurisdictionState: null,
        dateOfExam: null,
        userCrte: "",
        dateCrte: new Date(),
        deleted: false
    });
    const [sectionData, setSectionData] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const { push } = useHistory();
    const { StringType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        jurisdictionState: ObjectType().isRequired('This field is required.'),
        dateOfExam: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        getStates().then(setStates);
        examinationService.getExamination(reinstatementId).then((response) => {
            if (response.data) {
                setFormModel({
                    ...response.data,
                    dateOfExam: response.data.dateOfExam != null ? dayjs(response.data.dateOfExam).format('YYYY-MM-DD') : null
                });
            }
            reinstatementService.lsiGetSection(reinstatementId, sectionId).then((data) => {
                setSectionData(data.data);
                setHasLoaded(true);
            })
        })
    }, [])
    const formSubmit = (validation, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                //setFormModel({ ...formModel, dateOfExam: dayjs(formModel.dateOfExam).format('MM-dd-yyyy')})
                examinationService.postExamination(formModel).then((response) => {
                    if (response.data) {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)

                    }
                })
            }
        }
    }
    return (
        <Container fluid>
            <strong>Land Surveyor Intern Reinstatement</strong>
            <h4 className="mb-3">Examination</h4>
            <Information content={GetReinstatementInformationContent("exam")} />
            <br />
            <Form readOnly={!sectionData.isUserEditable} fluid model={model} formValue={formModel} onChange={setFormModel} onSubmit={formSubmit}>
                <Card className="mb-3">
                    <Card.Header>
                        Fundamentals Exam (FLS)
                    </Card.Header>
                    {hasLoaded ?
                        <Card.Body>
                            <Row className="mb-3">
                                <Col sm={3}>
                                    <ControlLabel>Jurisdiction</ControlLabel>
                                </Col>
                                <Col>
                                    <FormControl
                                        accepter={InputPicker}
                                        placeholder="Please Select"
                                        block
                                        data={states}
                                        name="jurisdictionState"
                                        searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>Date</ControlLabel>
                                </Col>
                                <Col>
                                    <FormControl type="date" style={{ width: '100%' }} block name="dateOfExam"></FormControl>
                                </Col>
                            </Row>
                        </Card.Body>
                        : <LoadingSpinner />
                    }
                </Card>
                <Issues isUserEditable={true} type={2} sectionId={sectionData.id} />
                <FormActionButtons
                    isReadOnly={false}
                    backToSectionLink="/individual/lsiReinstatement/sections" />
            </Form>
        </Container>
    );
}
export default ExaminationVerification