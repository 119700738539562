import React from 'react';
import { Card } from 'react-bootstrap'
import { Panel } from 'rsuite';

function WelcomeContent() {

    return (
        <div className="dashboardWelcomeContent">
            <Panel header="Welcome Content" shaded defaultExpanded collapsible bordered>

                <div>

                    <b>Complete your Profile:</b>
                    <ul>
                        <li>Click on <b>"Update contact information"</b> in the left menu to ensure your contact information is entered and updated. New registrants or new applicants will need to complete this first before starting an application.</li>
                    </ul>

                    <hr />

                    <b>Online Applications:</b>
                    <ul>
                        <li>Application for Engineering and/or Surveying Firm First-Time Licensure.</li>
                        <li>To apply for individual licensure, sign out from this firm account and sign back in with your individual user ID and password. If you have not yet registered, click "Register" at <a href="https://lola.lapels.com">https://lola.lapels.com</a> and select the "Individual" option to create a new account.</li>
                    </ul>


                    <b>Online Renewals/Reinstatements</b>

                    <ul>
                        <li>Licenses/certifications expiring in March may begin renewing in February. Those expiring in September may begin renewing in August.</li>
                        <li>Once licenses/certifications have expired, licensees may apply to reinstate.</li>
                    </ul>


                </div>
            </Panel>
        </div>
    )
}

export default WelcomeContent;