import axios from "axios";
import authHeader from '../authheader.js'

const WARNINGURL = process.env.REACT_APP_APIURL + "/api/Warning";
const ENTITYSEARCHURL = process.env.REACT_APP_APIURL + "/api/EntitySearch";

const getEntityInfo = async (entityId) => {
    return axios.get(`${ENTITYSEARCHURL}/entityinfo`, { headers: authHeader(), params: { entityId } });;
}

// warnings service
const getWarnings = async (entityId) => {
    return axios.get(`${WARNINGURL}/warnings`, { headers: authHeader(), params: { entityId } });;
}
const createWarning = async (data) => {
    return axios.post(`${WARNINGURL}/createwarning`, data, { headers: authHeader() });;
}
const updateWarning = async (data) => {
    return axios.post(`${WARNINGURL}/updatewarning`, data, { headers: authHeader() });;
}
const deleteWarning = async (warningId) => {
    return axios.post(`${WARNINGURL}/deletewarning`, null, { headers: authHeader(), params: { warningId } });;
}

export default {
    getWarnings,
    createWarning,
    updateWarning,
    deleteWarning,
    getEntityInfo
}