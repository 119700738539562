import axios from "axios";
import authHeader from '../../../authheader'

const FIRMAPP = process.env.REACT_APP_APIURL + "/api/FirmRenewal";

const getFirmRenewal = async (renewalId) => {
    return axios.get(`${FIRMAPP}/renewal`, { headers: authHeader(), params: { renewalId } });;
};

const vfGetSection = (renewalId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/FirmVfRenewal/section`, { headers: authHeader(), params: { renewalId, sectionId } });
}

const vfPostSection = (renewalId, sectionId, electronicSignature) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmVfRenewal/signsection`, null, { headers: authHeader(), params: { renewalId, sectionId, electronicSignature } });
}

const vfMarkSectionComplete = (renewalId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmVfRenewal/marksectioncomplete`, null, { headers: authHeader(), params: { renewalId, sectionId, complete } })
}

const postStaffApproval = (renewalId, isApproved) => {
    return axios.post(`${FIRMAPP}/staffApproval`, null, { headers: authHeader(), params: { renewalId, isApproved } });
}
const postRenewalPdf = (renewalId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachRenewalPdf`, null, { headers: authHeader(), params: { renewalId, isApproved } });
}
const vfPostSectionHasFirmSubmittedSupervisingProfessional = (renewalId, sectionId, hasFirmSubmittedSupervisingProfessional) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/FirmVfRenewal/setsectionhasfirmsubmittedsupervisingprofessional`, null, { headers: authHeader(), params: { renewalId, sectionId, hasFirmSubmittedSupervisingProfessional } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Verify personal and contact information is correct and accurate.",
    },
    {
        id: 9,
        sectionTitle: "Certificate of Authority",
        sectionContent: "Verify information requested and upload any supporting documents.",
    },
    {
        id: 5,
        sectionTitle: "Firm Supervising Professionals",
        sectionContent: "Certify any supervising professionals working on behalf of a firm.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 7,
        sectionTitle: "Acknowledgment",
        sectionContent: "Verify you have read the Rules for Professional Engineers and Land Surveyors (LAC Title 46, Part LXI), and agree.",
    },
    {
        id: 8,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    vfGetSection,
    vfPostSection,
    vfMarkSectionComplete,
    postStaffApproval,
    getFirmRenewal,
    vfPostSectionHasFirmSubmittedSupervisingProfessional,
    postRenewalPdf
}