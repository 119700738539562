import React from 'react';
import { Card } from 'react-bootstrap'
function Information() {
    return (
        <div className="applicationInstructions">
            <Card>
                <Card.Header><h6>Requirements/Information</h6></Card.Header>
                <Card.Body>
                    <p><strong className="text-danger">NOTE</strong>: Applications are subject to revision and modification at any time.</p>
                    <ul>
                        <li>Read all of the instructions before you begin.</li>
                        <li>Much of this information may be included in your MY NCEES account.</li>
                        <li>If you do not have a MY NCEES account, you must provide all required documents.</li>
                        <li>Complete all required sections of the application; incomplete applications will not be processed.</li>
                    </ul>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Information;