import axios from "axios";
import authHeader from '../../authheader.js'

const PAYMENTURL = process.env.REACT_APP_APIURL + "/api/Payment";
const PAYMENTSEARCHURL = process.env.REACT_APP_APIURL + "/api/PaymentSearch";
const PAYMENTACCOUNTSEARCHURL = process.env.REACT_APP_APIURL + "/api/PaymentByAccountSearch";
const REFUNDURL = process.env.REACT_APP_APIURL + "/api/Refund";
const DAILYREFUNDURL = process.env.REACT_APP_APIURL + "/api/DailyRefundSearch";
const INVOICEURL = process.env.REACT_APP_APIURL + "/api/Invoice";
const INVOICESEARCHURL = process.env.REACT_APP_APIURL + "/api/InvoiceSearch";

const getPayment = async (id) => {
    return axios.get(`${PAYMENTURL}/payment`, { headers: authHeader(), params: { id } });
}
const createPayment = async (data) => {
    return axios.post(`${PAYMENTURL}/createpayment`, data, { headers: authHeader() });
}
const deletePayment = async (paymentId) => {
    return axios.post(`${PAYMENTURL}/deletepayment`, null, { headers: authHeader(), params: { paymentId } });
}
const updatePayment = async (data) => {
    return axios.post(`${PAYMENTURL}/updatepayment`, data, { headers: authHeader() });
}
const allocatePayment = async (paymentId, invoiceId) => {
    return axios.post(`${PAYMENTURL}/allocatepaymenttoinvoice`, null, { headers: authHeader(), params: { paymentId, invoiceId } });
}
const postAutoRenewalPdfs = (autoApprovedRenewalIds) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachRenewalPdfs`, autoApprovedRenewalIds, { headers: authHeader() });
}

const deallocatePayment = async (paymentId, invoiceId) => {
    return axios.post(`${PAYMENTURL}/deallocatepaymentfrominvoice`, null, { headers: authHeader(), params: { paymentId, invoiceId } });
}
const getPaymentsForInvoice = async (invoiceId) => {
    return axios.get(`${PAYMENTURL}/getpaymentsforinvoice`, { headers: authHeader(), params: { invoiceId } });
}
const getInvoicesForPayment = async (paymentId) => {
    return axios.get(`${INVOICEURL}/getinvoicesforpayment`, { headers: authHeader(), params: { paymentId } });
}
const getRefunds = async (paymentId) => {
    return axios.get(`${REFUNDURL}/refundsforpayment`, { headers: authHeader(), params: { paymentId } });
}
const createRefund = async (data) => {
    return axios.post(`${REFUNDURL}/createrefund`, data, { headers: authHeader() });
}
const searchDailyRefunds = async (beginDate, endDate) => {
    return axios.post(`${DAILYREFUNDURL}`, null, { headers: authHeader(), params: { beginDate, endDate } });
}
const searchPayments = async (data) => {
    return axios.post(`${PAYMENTSEARCHURL}`, data, { headers: authHeader() });
}
const searchInvoice = async (data) => {
    return axios.post(`${INVOICESEARCHURL}`, data, { headers: authHeader() });;
}
const searchPaymentsByAccount = async (beginDate, endDate, contactEntityId) => {
    return axios.post(`${PAYMENTACCOUNTSEARCHURL}`, null, { headers: authHeader(), params: { beginDate, endDate, contactEntityId } });
}
const downloadPaymentSummary = async (data) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/PaymentsByAccountReport`, data, { headers: authHeader(), responseType: 'arraybuffer' }).then((response) => {
        var name = new String(response.headers['content-disposition']);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name.slice(name.indexOf('filename=') + 9, name.lastIndexOf(';')));
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}
export default {
    getPayment,
    createPayment,
    updatePayment,
    getRefunds,
    createRefund,
    getInvoicesForPayment,
    getPaymentsForInvoice,
    searchPayments,
    allocatePayment,
    deallocatePayment,
    searchInvoice,
    searchDailyRefunds,
    searchPaymentsByAccount,
    deletePayment,
    downloadPaymentSummary,
    postAutoRenewalPdfs
}