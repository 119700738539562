import React, { useState, useEffect } from 'react'
import { Badge, Button, Card, Col, Row } from 'react-bootstrap'
import { FormControl, Icon, Table } from 'rsuite'
import GenericBadge from '../../Shared/Badge/GenericBadge';
import RedStar from '../../Shared/GenericComponents/RedStar';
import YesNoRadioOption from '../../Shared/RadioButton/YesNoRadioOption';

function References(props) {
    const [hideReferencesGrid, setHideReferencesGrid] = useState();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }
    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }

    useEffect(() => {
        setHideReferencesGrid(props.hasReferences ?? true);
        setGridData(props.griddata)
        setHasLoaded(true);
    }, [props.griddata])

    const getData = () => {
        return gridData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    const handleMyNceesReferencesChange = (val) => {
        setHideReferencesGrid(val);
    }

    return (
        <div>

            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>References</h6>
                        </Col>
                        {
                            (!props.isReadOnly && hideReferencesGrid === false && !props.isInternal) &&
                            <Col> <Button style={{ float: 'right' }} onClick={props.openModal}>
                                <Icon icon="plus" /> Add New</Button></Col>
                        }
                        {
                            props.isInternal && props.downloadPersonalReferences &&
                            <Col className="text-right"><Button onClick={props.downloadPersonalReferences}>Download PDF Version</Button></Col>
                        }
                    </Row>

                </Card.Header>
                <Card.Body>
                    <p>
                        <RedStar /> I have personal references listed in MyNCEES record.
                    </p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasReferences" handleChange={handleMyNceesReferencesChange} />
                    {(hideReferencesGrid === false && hasLoaded)
                        &&
                        <div>
                            <Badge variant="danger" style={{ padding: '1rem' }}>You will be required to send each personal reference form for verification.</Badge>
                            <hr />
                            <Table wordWrap autoHeight data={filteredData}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Name</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="name" />
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Current Mailing Address</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.address.streetAddress)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>City/State</b>
                                    </Table.HeaderCell>
                                    <Table.Cell >{rowData => (`${rowData.address.city}, ${rowData.address.state?.code ?? rowData.address.provinceRegion}`)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Zip</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.address.postalCode)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Email</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="email" />
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Verification Status</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.verificationStatus ? <GenericBadge status={rowData.verificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                                </Table.Column>

                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">
                                        {rowData => {
                                            const verificationStatusId = rowData.verificationStatus?.id;
                                            function handleAction() {
                                                if (!props.isReadOnly) {
                                                    var tempData = { ...rowData, modalType: 'Edit' }
                                                    props.openModal(tempData);
                                                }
                                            }
                                            function openVerifierModal() {
                                                props.openVerifierModal(rowData.id);
                                            }
                                            if (verificationStatusId > 1) {
                                                if (verificationStatusId == 4 && props.isInternal) {
                                                    return (<span>
                                                        <Icon icon="file-text" size="2x" title="View verifier response" onClick={openVerifierModal} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                        <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />
                                                    </span>)
                                                    //    <span>
                                                    //        <Icon icon="send" size="2x" title="Send email for verification" onClick={() => { props.emailReference(rowData) }} style={{ cursor: "pointer" }} /> &nbsp;&nbsp;
                                                    //            <Icon icon="edit2" size="2x" title="Edit" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                    //            <Icon icon="trash" size="2x" title="Delete" onClick={() => { props.deleteRow(rowData) }} style={{ cursor: "pointer" }} />
                                                    //    </span>
                                                    //);
                                                }
                                            }
                                            else {
                                                return (
                                                    <span>
                                                        <Icon icon="send" size="2x" title="Send email for verification" onClick={() => { props.emailReference(rowData) }} style={{ cursor: "pointer" }} /> &nbsp;&nbsp;
                                                        <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                        <Icon icon="trash" size="2x" onClick={() => { props.deleteRow(rowData) }} style={{ cursor: "pointer" }} />
                                                    </span>
                                                );
                                            }
                                        }}
                                    </Table.Cell>
                                </Table.Column>

                            </Table>
                            <Table.Pagination lengthMenu={[
                                {
                                    value: 10,
                                    label: 10
                                },
                                {
                                    value: 20,
                                    label: 20
                                }
                            ]}
                                activePage={page}
                                displayLength={displayLength}
                                total={gridData.length}
                                onChangePage={handleChangePage}
                                onChangeLength={handleChangeLength} />
                        </div>}
                    <hr />
                    <p>
                        "I attest that the personal references listed above are in compliance with rule 1701.B."
                    </p>
                    <br />


                    <Row>
                        <Col sm={4}>
                            <h5>Electronic Signature of Applicant:<RedStar /></h5>
                            <span>(Type full name)</span>
                        </Col>
                        <Col sm={8}><FormControl readOnly={props.isReadOnly} name="electronicSignature" /></Col>
                    </Row>
                </Card.Body>
            </Card>
        </div >
    )
}
export default References
