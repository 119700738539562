import axios from "axios";
import authHeader from '../../authheader.js'

const INDIVIDUALURL = process.env.REACT_APP_APIURL + "/api/Individual";
const FIRMURL = process.env.REACT_APP_APIURL + "/api/Firm";
const INVOICEURL = process.env.REACT_APP_APIURL + "/api/Invoice";

const getInvoices = async (entityId, isIndividual) => {
    if (isIndividual)
        return axios.get(`${INDIVIDUALURL}/getinvoicelineitemdata`, { headers: authHeader(), params: { entityId } });
    else
        return axios.get(`${FIRMURL}/getinvoicelineitemdata`, { headers: authHeader(), params: { entityId } });
}
const getRefunds = async (entityId, isIndividual) => {
    if (isIndividual)
        return axios.get(`${INDIVIDUALURL}/getrefunddata`, { headers: authHeader(), params: { entityId } });
    else
        return axios.get(`${FIRMURL}/getrefunddata`, { headers: authHeader(), params: { entityId } });
}

const deleteLineItem = async (lineItemId) => {
    return axios.post(`${INVOICEURL}/deletelineiteminfeestransaction`, null, { headers: authHeader(), params: { lineItemId } });
}
export default {
    getInvoices,
    getRefunds,
    deleteLineItem
}