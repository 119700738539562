import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Tabs, Tab, Badge } from 'react-bootstrap'
import { ControlLabel, Form, Icon, Table } from 'rsuite';
import GenericPagination from '../../../Shared/Grid/GenericPagination';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';
import batchService from '../../../../services/Internal/Revenue/batch.service';
import AddPaymentModal from './AddPaymentModal';
import PaymentInfoModal from '../../Shared/PaymentInfoModal';
import paymentService from '../../../../services/Internal/Revenue/payment.service';

function CreateBatchPage() {
    const [batchInfo, setBatchInfo] = useState({
        batchId: '',
        batchDate: '',
        batchItemCount: '',
        batchAmount: '',
        lastOpenedBy: '',
        batchStatus: ''
    })
    const [paymentData, setPaymentData] = useState([]);
    const [batchActionShow, setBatchActionShow] = useState(false);
    useEffect(() => {
        refreshGrid();
    }, [])

    function closeBatch() {
        batchService.closeBatch(batchInfo.batchId).then((response) =>
        {
            if (response.data.batchStatus.batchStatus == "Closed") {
                setBatchInfo({ ...batchInfo, batchStatus: "Closed" });
                setBatchActionShow(true);
            }
            else {
                Swal.fire("Error!", "Failed to close batch.", "error")
            }
        })
    }

    function openBatch() {
        batchService.openBatch(batchInfo.batchId).then((response) => {
            if (response.data.batchStatus.batchStatus == "Open") {
                setBatchInfo({ ...batchInfo, batchStatus: "Open", lastOpenedBy: response.data.batchOwnerName });
            }
            else {
                Swal.fire("Error!", "Failed to open batch.", "error")
            }
        })
    }

    function refreshGrid() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        batchService.getBatchInfo(params.batchId).then((response) => {
            if (response.data) {
                setBatchInfo({
                    batchId: response.data.id,
                    batchDate: response.data.batchDate,
                    batchItemCount: response.data.batchItemCount,
                    batchAmount: response.data.batchAmount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }),
                    lastOpenedBy: response.data.batchOwnerName,
                    batchStatus: response.data.batchStatus.batchStatus
                })
                setPaymentData(response.data.payments);
            }
            setHasLoaded(true);
        });
        batchService.checkBatchOpened().then((response) => {
            if (!response.data)
                setBatchActionShow(true);
        });
    }
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [paymentId, setPaymentId] = useState();

    const getData = () => {
        return paymentData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();

    return (
        <>
            <Card className="w-100 mb-3">
                <Card.Body>
                    {hasLoaded &&
                        <Row>
                            <Col>
                                <span>Batch ID</span>
                                <br />
                                <h6>{batchInfo.batchId}</h6>
                            </Col>
                            <Col>
                                <span>Batch Date</span>
                                <br />
                                <h6>{new Date(batchInfo.batchDate).toLocaleDateString()}</h6>
                            </Col>
                            <Col>
                                <span>Batch Item Count</span>
                                <br />
                                <h6>{batchInfo.batchItemCount}</h6>
                            </Col>
                            <Col>
                                <span>Batch Amount</span>
                                <br />
                                <h6>{batchInfo.batchAmount}</h6>
                            </Col>
                            <Col>
                                <span>Last Opened By</span>
                                <br />
                                <h6>{batchInfo.lastOpenedBy}</h6>
                            </Col>
                        </Row>
                    }
                </Card.Body>
            </Card >

            <Card className="w-100 mb-3">
                <Card.Header>
                    <h6>Batch
                        {
                            batchInfo.batchStatus == "Open" &&
                            <span className="float-right"><Button variant="primary" onClick={() => { setModalShow(true) }}>New Payment</Button></span>
                        }
                    </h6>
                </Card.Header>
                <Card.Body>
                    {
                        modalShow &&
                        <AddPaymentModal show={modalShow} onHide={() => setModalShow(false)} updateGrid={() => { refreshGrid() }} batchId={batchInfo.batchId} />
                    }
                    <PaymentInfoModal paymentId={paymentId} show={show} onHide={() => {setShow(false); refreshGrid();}} />
                    <br />
                    {hasLoaded ?
                        <>
                            <Table wordWrap autoHeight data={filteredData}>
                                <Table.Column width={150}>
                                    <Table.HeaderCell>
                                        <b>Action(s)</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">
                                        {rowData => {
                                            function viewPayment() {
                                                setPaymentId(rowData.id);
                                                setShow(true);
                                            }
                                            return (
                                                <>
                                                    <Button onClick={viewPayment} variant="primary">View</Button>
                                                </>
                                            )
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column width={150}>
                                    <Table.HeaderCell>
                                        <b>Payment ID</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.id)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Payment Type</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.paymentType.paymentType)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={2}>
                                    <Table.HeaderCell>
                                        <b>Contact</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.entityDetails)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Remitter</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.remitter)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={.75}>
                                    <Table.HeaderCell>
                                        <b>Amount</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.amount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }))}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={.75}>
                                    <Table.HeaderCell>
                                        <b>Available</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.availableAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }))}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={150}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">
                                        {rowData => {
                                            function deletePayment() {
                                                Swal.fire({
                                                    title: 'Are you sure you want to delete this payment?',
                                                    text: "You won't be able to revert this!",
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#3085d6',
                                                    cancelButtonColor: '#d33',
                                                    confirmButtonText: 'Yes, delete it!'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        paymentService.deletePayment(rowData.id).then((response) => {
                                                            refreshGrid();
                                                        });
                                                    }
                                                })
                                            }
                                            return (
                                                (batchInfo.batchStatus == "Open" && rowData.amount == rowData.availableAmount) && 
                                                <>
                                                    <Icon icon="trash" size="2x" onClick={deletePayment} style={{ cursor: "pointer" }} />
                                                </>
                                            )
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                            </Table>
                            <GenericPagination dataLength={paymentData.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                            <br />
                            {
                                batchInfo.batchStatus == "Open" ?
                                    <span><Button className="float-right" variant="success" onClick={closeBatch}>Close Batch</Button></span>
                                    :
                                    batchActionShow && < span > <Button className="float-right" variant="success" onClick={openBatch}>Open Batch</Button></span>
                            }
                            </>
                        : <LoadingSpinner />
                    }
                </Card.Body>
            </Card >
        </>
    )
}


export default CreateBatchPage;