import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { FaBuilding, FaEdit } from 'react-icons/fa';
import { ControlLabel, Form, FormControl, InputPicker, SelectPicker } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import { emailPhonePrivacy, journalDropdown, subscribeDropdown } from '../../../../services/Shared/codeTable.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import PhoneNumberMask from '../../../Shared/GenericComponents/PhoneNumberMask';
import EditFirmGeneralInformation from './EditFirmGeneralInformationModal';

function GeneralInfoCard(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});
    const [genInfoFormData, setGenInfoFormData] = useState({});

    useEffect(() => {
        updateCard();
    }, [])

    const updateCard = () => {
        setHasLoaded(false);
        credentialService.getFirmGeneralInfo(props.entityId).then((response) => {
            setData(response.data);
            setGenInfoFormData({
                userId: response.data.userName,
                federalId: response.data.federalId,
                companyEmail: response.data.primaryEmailAddress?.contactEmailAddress,
                businessPhone: response.data.phone?.contactPhoneNumber,
                companyWebsite: response.data.url,
                contactName: response.data.contactName,
                firmType: response.data.organizationType?.organizationType,
                domicileFirmState: response.data.domicileState?.state,
                dateFormed: response.data.dateOfFormation ? new Date(response.data.dateOfFormation).toLocaleDateString() : null,
                amountDue: response.data.amountDue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                servicesDesc: response.data.additionalInformation,
                journalSubscription: response.data.journalSubscription,
                pressReleases: response.data.pressReleases,
                webinar: response.data.webinar,
                emailPhonePrivacy: response.data.emailPhonePrivacy,
            })
            setHasLoaded(true);
        })
    }
    const saveGenInfo = () => {
        updateCard();
        Swal.fire('Success!', "You have successfully updated this firm's general information.", 'success');
    }
    const setAddress = (address) => {
        if (address?.streetAddress) {
            var addresses = address.streetAddress.split("\n").filter(x => (x != "") && (x != "\r"));
            return (
                addresses.map((addressComponents) => {
                    return (<><span>{addressComponents}</span><br /></>);
                })
            );
        }
    }

    return (
        <>
            <EditFirmGeneralInformation saveDataCallback={saveGenInfo} show={showModal} onHide={() => setShowModal(false)} entityId={props.entityId} />
            <Card className="w-100 h-100">
                <Card.Header className="text-center" style={{ background: 'white' }}>
                    <a href={"internal/contactChangeLog?entityId=" + props.entityId} target="_blank" className="changeLog">
                        <span className="fakeH6">GENERAL
                        </span>
                        <br />
                        <FaBuilding fontSize='42' />
                    </a>
                </Card.Header>
                <Card.Body>
                    {
                        hasLoaded ?
                            <>
                                <div className="text-center mb-3">
                                    <h4>{data.name}</h4>
                                    <span>LOLA #</span><br />
                                    <span>{data.lolaNumber}</span>
                                    <hr />
                                    <h6>Address Information</h6>
                                </div>
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Header>Mailing Address</Card.Header>
                                            <Card.Body>
                                                {setAddress(data.mailingAddress)}
                                                {data.mailingAddress?.country?.country == 'United States' ?
                                                    <span>{data.mailingAddress?.city}, {data.mailingAddress?.state?.code} {data.mailingAddress?.postalCode} </span> :
                                                    <span>{data.mailingAddress?.city}, {data.mailingAddress?.provinceRegion} {data.mailingAddress?.postalCode} </span>}
                                                <br />
                                                <span>{data.mailingAddress?.country?.country} </span><br />

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <Card.Header>Public Address</Card.Header>
                                            <Card.Body>
                                                {setAddress(data.publicAddress)}
                                                {data.publicAddress?.country?.country == 'United States' ?
                                                    <span>{data.publicAddress?.city}, {data.publicAddress?.state?.code} {data.publicAddress?.postalCode} </span> :
                                                    <span>{data.publicAddress?.city}, {data.publicAddress?.provinceRegion} {data.publicAddress?.postalCode} </span>}
                                                <br />
                                                <span>{data.publicAddress?.country?.country} </span><br />

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <hr />
                                <Form fluid formValue={genInfoFormData}>
                                    <Row className="mb-3">
                                        <Col>
                                            <ControlLabel><b>UserId</b></ControlLabel>
                                            <FormControl
                                                name="userId"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>FEIN</b></ControlLabel>
                                            <FormControl
                                                name="federalId"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Company Email</b></ControlLabel>
                                            {genInfoFormData.companyEmail ? <div className="rs-form-control-wrapper"><a href={"mailto:" + genInfoFormData.companyEmail + ""}>{genInfoFormData.companyEmail}</a></div> : <FormControl
                                                name="companyEmail"
                                                disabled
                                            />}
                                            
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <ControlLabel><b>Business Phone</b></ControlLabel>
                                            <FormControl
                                                accepter={PhoneNumberMask}
                                                format="(###) ###-####x#####"
                                                name="businessPhone"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Company Website</b></ControlLabel>
                                            {genInfoFormData.companyWebsite ? <div className="rs-form-control-wrapper"><a href={"https://" + genInfoFormData.companyWebsite + ""} target="_blank">{genInfoFormData.companyWebsite}</a></div> : <FormControl
                                                name="companyWebsite"
                                                disabled
                                            />}
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Contact Name</b></ControlLabel>
                                            <FormControl
                                                name="contactName"
                                                disabled
                                            />
                                        </
                                        Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <ControlLabel><b>Firm Type</b></ControlLabel>
                                            <FormControl
                                                name="firmType"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Domicile Firm State</b></ControlLabel>
                                            <FormControl
                                                name="domicileFirmState"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Date Formed/Incorporated</b></ControlLabel>
                                            <FormControl
                                                name="dateFormed"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <ControlLabel><b>Amount Due</b></ControlLabel>
                                            <FormControl
                                                name="amountDue"
                                                disabled
                                            /></Col>
                                        <Col>
                                            <ControlLabel><b>Email/Phone Privacy</b></ControlLabel>
                                            <FormControl
                                                name="emailPhonePrivacy"
                                                data={emailPhonePrivacy}
                                                accepter={InputPicker}
                                                block
                                                disabled
                                            />

                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ControlLabel><b>Brief description of Services to be provided by the firm in Louisiana</b></ControlLabel>
                                            <FormControl
                                                rows={3}
                                                componentClass="textarea"
                                                name="servicesDesc"
                                                disabled
                                            /></Col>

                                    </Row>
                                    <hr />
                                    <Row className='mb-3'>
                                        <Col>
                                            <ControlLabel><b>Journal</b></ControlLabel>
                                            <FormControl
                                                name="journalSubscription"
                                                data={journalDropdown}
                                                accepter={SelectPicker}
                                                searchable={false}
                                                block
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Press Releases</b></ControlLabel>
                                            <FormControl
                                                name="pressReleases"
                                                data={subscribeDropdown}
                                                accepter={SelectPicker}
                                                searchable={false}
                                                block
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <ControlLabel><b>Webinar</b></ControlLabel>
                                            <FormControl
                                                name="webinar"
                                                data={subscribeDropdown}
                                                accepter={SelectPicker}
                                                searchable={false}
                                                block
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </> : <LoadingSpinner />
                    }
                </Card.Body>
                <Card.Footer className='text-right'>
                    <Button variant='light' onClick={() => setShowModal(true)}><FaEdit /></Button>
                </Card.Footer>
            </Card >
        </>
    )
}


export default GeneralInfoCard;