import React from 'react'
import NumberFormat from 'react-number-format';

const PhoneNumberMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onValueChange={(values) => {
                const { formattedValue, value } = values;
                onChange(value)
            }}
        />
    );
};
export default PhoneNumberMask;