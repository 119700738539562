import axios from "axios";
import authHeader from '../../authheader'

const INDIVIDUALRENEWAL = process.env.REACT_APP_APIURL + "/api/IndividualRenewal";

const getPendingRenewals = async () => {
    return axios.get(`${INDIVIDUALRENEWAL}/pendingrenewals`, { headers: authHeader() });;
};
const getIndividualRenewal = async (renewalId) => {
    return axios.get(`${INDIVIDUALRENEWAL}/renewal`, { headers: authHeader(), params: { renewalId } });;
};

const createRenewal = (credentialTypeId, credentialId) => {
    return axios.post(`${INDIVIDUALRENEWAL}/create`, null, { headers: authHeader(), params: { credentialTypeId, credentialId } })
        .then((response) => {
            return response;
        });
}
const plsGetSection = (renewalId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPlsRenewal/section`, { headers: authHeader(), params: { renewalId, sectionId } });
}
const plsPostSection = (renewalId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsRenewal/signsection`, null, { headers: authHeader(), params: { renewalId, sectionId, electronicSignature } });
}
const plsPostSectionHasRef = (renewalId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsRenewal/setsectionhasreferences`, null, { headers: authHeader(), params: { renewalId, sectionId, hasReferences } });
}
const plsPostSectionHasCpd = (renewalId, sectionId, hasCpd) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsRenewal/setsectionhascpd`, null, { headers: authHeader(), params: { renewalId, sectionId, hasCpd } });
}
const plsMarkSectionComplete = (renewalId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsRenewal/marksectioncomplete`, null, { headers: authHeader(), params: { renewalId, sectionId, complete } })
}
const validateRenewal = (renewalId) => {
    return axios.post(`${INDIVIDUALRENEWAL}/validate`, null, { headers: authHeader(), params: { renewalId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const submitRenewal = (renewalId) => {
    return axios.post(`${INDIVIDUALRENEWAL}/submit`, null, { headers: authHeader(), params: { renewalId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const postStaffApproval = (renewalId, isApproved) => {
    return axios.post(`${INDIVIDUALRENEWAL}/staffApproval`, null, { headers: authHeader(), params: { renewalId, isApproved } });
}
const postRenewalPdf = (renewalId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachRenewalPdf`, null, { headers: authHeader(), params: { renewalId, isApproved } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "License Status",
        sectionContent: "Verify the staus of your license.",
    },
    {
        id: 3,
        sectionTitle: "CPD Requirements",
        sectionContent: "If you answer yes to any of the questions in this section, you will need to submit a detailed description for each violation and you may be required to provide court documents.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 5,
        sectionTitle: "Supervising Professional",
        sectionContent: "Certify if you are a supervising professional on behalf of a firm.",
    },
    {
        id: 6,
        sectionTitle: "Journal & Phone Privacy Options",
        sectionContent: "Verify your subscription and distribution options.",
    },
    {
        id: 7,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 8,
        sectionTitle: "Review",
        sectionContent: "Review certificate renewal.",
    }
])
export default {
    getPendingRenewals,
    getIndividualRenewal,
    createRenewal,
    plsGetSection,
    plsPostSection,
    plsPostSectionHasRef,
    plsPostSectionHasCpd,
    plsMarkSectionComplete,
    validateRenewal,
    submitRenewal,
    postStaffApproval,
    postRenewalPdf
}