import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap'
import { FaEdit, FaEye } from 'react-icons/fa';
import EditSupervisesModal from './EditSupervisesModal';
import SupervisesTable from './SupervisesTable';

function SupervisesCard(props) {
    const [modalShow, setModalShow] = useState(false);
    const [updateTable, setUpdateTable] = useState(0);
    useEffect(() => { setUpdateTable(updateTable + 1) }, [props.updateData])
    return (
        <Card className="w-100 h-100">
            <Card.Header className="text-center" style={{ background: 'white' }}>
                <h5>SUPERVISES
                </h5>
                <FaEye fontSize='42' />
            </Card.Header>
            <Card.Body>
                <EditSupervisesModal entityid={props.entityId} show={modalShow} onHide={() => { setUpdateTable(updateTable + 1); setModalShow(false) }} />
                <SupervisesTable entityid={props.entityId} updateTable={updateTable} editMode={false} />
            </Card.Body>
            <Card.Footer className='text-right'>
                <Button onClick={() => setModalShow(true)} variant='light'><FaEdit /></Button>
            </Card.Footer>
        </Card >
    )
}


export default SupervisesCard;