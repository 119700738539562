import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import BlockUi from 'react-block-ui';
import { ControlLabel, Form, Table, FormControl, FormGroup } from 'rsuite';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import paymentService from '../../../services/Internal/Revenue/payment.service';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa';

function DailyRefundSearchPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const componentRef = useRef();
    const [searchModel, setSearchModel] = useState({
        beginDate: '',
        endDate: ''
    })
    const [searchResults, setSearchResults] = useState([]);
    useEffect(() => {
        // Initial search
        refreshGrid()
        refreshGrid()
    }, [])
    const [hasLoaded, setHasLoaded] = useState(false);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const clearSearchModel = () => {
        setSearchModel({
            beginDate: '',
            endDate: '',
        })
    }
    const refreshGrid = (beginDate, endDate) => {
        setIsSearching(true);
        paymentService.searchDailyRefunds(beginDate, endDate).then((response) => {
            setSearchResults(response.data.data);
        }).finally(() => {
            setHasLoaded(true);
            setIsSearching(false);
        })
    }
    const submitSearch = () => {
        refreshGrid(searchModel.beginDate ? searchModel.beginDate : null, searchModel.endDate ? searchModel.endDate : null);
    }
    const getData = () => {
        return searchResults.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    let totalAmount = searchResults.reduce(function (total, currentValue) {
        return total + currentValue.refundAmount;
    }, 0)

    return (
        <>
            <Card className="w-100 mb-3">
                <Card.Body>
                    <BlockUi blocking={isSearching}>
                        <Form fluid formValue={searchModel} onChange={setSearchModel} onSubmit={submitSearch} >
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Date Range</b></ControlLabel>
                                        <FormControl
                                            name="beginDate"
                                            type="date"
                                            block="true"
                                        />
                                    </FormGroup>
                                </Col>
                                <span style={{ marginTop: '2rem' }}>to</span>
                                <Col>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <FormControl
                                        name="endDate"
                                        type="date"
                                        block="true"
                                    />
                                </Col>
                            </Row>

                            <hr />
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                                <Button type="submit">Search</Button>
                            </div>
                        </Form>
                    </BlockUi>
                </Card.Body>
            </Card >

            <Card className="w-100 mb-3">
                <Card.Body>
                    <br />
                    {hasLoaded ?
                        <>
                            <BlockUi blocking={printLoading}>
                                <Table ref={componentRef} wordWrap autoHeight data={filteredData}>
                                    <Table.Column width={100}>
                                        <Table.HeaderCell>
                                            <b>Payment ID</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.paymentId)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={100}>
                                        <Table.HeaderCell>
                                            <b>Payment Date</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.paymentDate ? new Date(rowData.paymentDate).toLocaleDateString() : '')}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={150}>
                                        <Table.HeaderCell>
                                            <b>Payment Type</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.paymentType)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={150} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>Chec#/CC Auth Number</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.authorizationNumber)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={150} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>GL Account</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => {
                                            return (<>
                                                {rowData.glCodes.map((item, i) => {
                                                    return (
                                                        <>
                                                            {item} <br />
                                                        </>
                                                    )
                                                })}
                                            </>
                                            )
                                        }}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={150} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>LOLA #</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.paymentLolaNumber)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={200} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>Mailing Address</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (<><p>{rowData.paymentFormattedName}</p><p>{rowData.address}</p><p>{rowData.city}, {rowData.stateCode}</p></>)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={100}>
                                        <Table.HeaderCell>
                                            <b>Refund Date</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.refundDate ? new Date(rowData.refundDate).toLocaleDateString() : '')}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={150}>
                                        <Table.HeaderCell>
                                            <b>Refund Amount</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.refundAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }))}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={200} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>Reason</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.refundReason)}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={200} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>User</b>
                                        </Table.HeaderCell>
                                        <Table.Cell>{rowData => (rowData.refundUserFormattedName)}</Table.Cell>
                                    </Table.Column>
                                </Table>
                                <GenericPagination dataLength={searchResults.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                            </BlockUi>
                            <Row>
                                <Col >
                                    <ReactToPrint
                                        trigger={() => (<Button variant='primary'><FaPrint /> Print</Button>)}
                                        content={() => componentRef.current}
                                        onBeforeGetContent={() => { setPrintLoading(true) }}
                                        onAfterPrint={() => { setPrintLoading(false) }}
                                    /></Col>
                                <Col className="text-right">
                                    <h3> Total: {totalAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}</h3>
                                </Col>
                            </Row>
                        </>
                        : <LoadingSpinner />
                    }
                </Card.Body>
            </Card >
        </>
    )
}


export default DailyRefundSearchPage;