import { useState, useEffect } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import React from 'react'
import { ControlLabel, Schema, Form, FormControl, FormGroup } from 'rsuite'
import RedStar from '../../../../Shared/GenericComponents/RedStar'


function AddEditRecommendationModal(props) {
    const [recommendationModel, setRecommendationModel] = useState(
        {
            applicationId: 0,
            dateCrte: null,
            deleted: false,
            email: "",
            entityId: 0,
            id: 0,
            firstName: "",
            lastName: "",
            userCrte: "",
            verificationStatus: null,
            modalType: "Add"
        })
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        setRecommendationModel(props.modaldata);
    }, [props.modaldata])

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        firstName: StringType().isRequired("This field is required."),
        lastName: StringType().isRequired('This field is required.'),
        email: StringType().isRequired('This field is required.').isEmail('Enter in a valid Email')
    })
    
    const submitData = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...recommendationModel,
                id: recommendationModel.id === '' ? 0 : recommendationModel.id,
                dateCrte: new Date()
            };
            delete dataToTransmit.modalType;
            props.onsave(dataToTransmit).then((response) => {
                if (response.data.id) {
                    props.updategrid();
                    setShowErrors(false);
                    props.onHide();
                }
            }).catch((response) => {
            })
        }
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {recommendationModel.modalType} a Recommendation
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form model={model} id="recommendationForm" formValue={recommendationModel} onChange={recommendationModel => setRecommendationModel(recommendationModel)} onSubmit={submitData} fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                       First Name&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="firstName" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Last Name&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="lastName" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Email&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="email" />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
                <br />
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="recommendationForm" variant="success">Save</Button>
                <Button  onClick={() => { setShowErrors(false); props.onHide(); }}>Cancel</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddEditRecommendationModal;