import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import cpdRequirementsService from '../../../../../../services/Public/Renewal/PE/cpdRequirements.service';
import renewalService from '../../../../../../services/Public/Renewal/PE/peRenewal.service';
import attachmentService from '../../../../../../services/Shared/attachment.service';
import Information from '../../../../../Shared/Card/Information';
import Issues from '../../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { GetRenewalInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import LockedRenewalModal from '../../../../../Shared/Modal/LockedRenewalModal';
import Courses from './Courses';
import generalRenewalService from '../../../../../../services/Public/Renewal/renewal.service';


function CpdRequirementsPage() {
    const { push } = useHistory();
    const [showLockedRenewalModal, setShowLockedRenewalModal] = useState(false);
    const [isRetiredToActive, setIsRetiredToActive] = useState(false);
    const [isNonActiveToActive, setIsNonActiveToActive] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isRequired, setIsRequired] = useState(true);
    const renewalId = useSelector(x => x.currentApplicationId);
    const [sectionData, setSectionData] = useState({});
    const entityId = useSelector(x => x.userInfo.EntityId)
    const sectionId = 3;
    const routes = useSelector(x => x.currentRoutes);
    const [isLicenseStatusSet, setIsLicenseStatusSet] = useState(true);

    const changeLogData = {
        Header: "CPD Change History",
        BodyText: "The information below displays all changes made to the CPD section. It allows our staff to track any irregularities and find process breakdowns so that we can make improvements to our system.",
        CurrentId: renewalId,
        SectionId: sectionId
    };
    const currentDate = new Date();
    var yearBase = (currentDate.getMonth() == 0 && currentDate.getDate() < 30) ? currentDate.getFullYear() - 1 : currentDate.getFullYear(); //LAPELS-379
    const [formModel, setFormModel] = useState({
        id: 0,
        renewalId: renewalId,
        entityId: entityId,
        year1: (yearBase - 2),
        year1Hours: null,
        year2: (yearBase - 1),
        year2Hours: null,
        metCpd: null,
        hasCpc: null,
        notMetCPDReason: null,
        carryoverExplanation: "",
        userCrte: "",
        dateCrte: new Date(),
        deleted: false,
        hasCpdInMyNcees: null,
        electronicSignature: "",
    });
    useEffect(() => {
        cpdRequirementsService.isRetiredToActive(renewalId).then((response) => {
            if (response.data) {
                setIsRetiredToActive(true);
            }
        })
        cpdRequirementsService.isNonActiveToActive(renewalId).then((response) => {
            if (response.data) {
                setIsNonActiveToActive(true);
            }
        })
        renewalService.peGetSection(renewalId, sectionId).then((response) => {
            setSectionData(response.data);
            cpdRequirementsService.isCpdRequired(renewalId).then((result) => {
                if (result.data) {
                    cpdRequirementsService.getCpdRequirements(renewalId).then((data) => {
                        if (data.data) {
                            setFormModel({ ...data.data, electronicSignature: response.data.electronicSignature })
                        }
                        setHasLoaded(true);
                    })
                }
                else if (result.data === false) {
                    setIsRequired(false)
                    setHasLoaded(true)
                }
                else {
                    setIsLicenseStatusSet(false)
                    setHasLoaded(true)
                }
            })
        })
    }, [])

    const { StringType, BooleanType, NumberType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.'),
        metCpd: BooleanType().isRequired('This field is required.'),
        notMetCPDReason: ObjectType().isRequired('This field is required'),
        hasCpdInMyNcees: BooleanType().isRequired("This field is required."),
        year1Hours: NumberType().isRequired("This field is required."),
        year2Hours: NumberType().isRequired("This field is required.")
    });

    const formSubmit = (validation, event) => {

        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var hasErrors = false;
            var validationObject = model.check(formModel);
            for (const key in validationObject) {
                if (validationObject[key].hasError) {
                    if (!isNonActiveToActive) {
                        if (key === "electronicSignature") {
                            hasErrors = true;
                            return
                        }
                        if (key === "metCpd" && formModel.metCpd === null) {
                            hasErrors = true;
                            return
                        }
                        if (key === "notMetCPDReason" && formModel.metCpd === false) {
                            hasErrors = true;
                            return
                        }
                        if (key === "year1Hours" || key == "year2Hours") {
                            hasErrors = true;
                            return;
                        }
                    }
                    else {
                        if (key === "hasCpdInMyNcees" && formModel.hasCpdInMyNcees === null)
                        {
                            hasErrors = true;
                            return;
                        }
                    }
                }
            }
            if (!hasErrors) {
                submitData(true);
            }
        }
    }
    const formSave = (event) => {
        submitData();
    }
    const notRequiredSubmit = (event) => {
        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
    }
    const submitData = (isForSubmiit) => {
        var dataToTransmit = { ...formModel, year1Hours: formModel.year1Hours === '' ? null : formModel.year1Hours, year2Hours: formModel.year2Hours === '' ? null : formModel.year2Hours };
        renewalService.pePostSection(renewalId, sectionId, formModel.electronicSignature).then((data) => {
            generalRenewalService.wasHeldForCpd(renewalId).then((wasHeld) => {
                cpdRequirementsService.postCpdRequirements(dataToTransmit).then((response) => {
                    if (response.data.id) {
                        if (data.data) {
                            if (isForSubmiit) {
                                //WO 271
                                //if (response.data.metCpd == false) {
                                //    if (wasHeld.data) {
                                //        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                                //    } else {
                                //        setShowLockedRenewalModal(true);
                                //    }
                                //}
                                //else {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                                //}
                            }
                            else
                                Swal.fire("Saved!");
                        }
                    }
                })
            })
        })
    }

    return (
        <div>
            <Container fluid>
                <strong>Professional Engineer Renewal</strong>
                <h4 className="mb-3">
                    CPD Requirements
                </h4>
                <Information content={GetRenewalInformationContent("cpdRequirements")} />
                <LockedRenewalModal sectionId={sectionId} show={showLockedRenewalModal} onHide={() => setShowLockedRenewalModal(false)} />
                <br />{hasLoaded ? !isLicenseStatusSet ?
                    <Form onSubmit={notRequiredSubmit}>
                        <Card>
                            <Card.Header>
                                <h5>CPD Requirements</h5>
                            </Card.Header>
                            <Card.Body>
                                <span>Please go back and choose a license status to renew into before completing this section. You may access that section by clicking the "Back to Sections" button at the bottom of the page.</span>
                            </Card.Body>
                        </Card>
                        <br />
                        <Issues isUserEditable={true} type={3} sectionId={sectionData.id} changeLog={changeLogData} />
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/perenewal/sections" />
                    </Form>
                    : isRequired ?
                        <Form model={model} readOnly={!sectionData.isUserEditable} formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} fluid>
                            <Courses
                                year1Number={formModel.year1}
                                year2Number={formModel.year2}
                                hasCpdInMyNcees={formModel.hasCpdInMyNcees}
                                metCpd={formModel.metCpd}
                                isReadOnly={!sectionData.isUserEditable}
                                isRetiredToActive={isRetiredToActive}
                                isNonActiveToActive={isNonActiveToActive}
                                sectionId={ sectionData.id}
                            />
                            <br />
                            <Issues isUserEditable={true} type={3} sectionId={sectionData.id} changeLog={changeLogData} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/perenewal/sections" />
                        </Form> :
                        <Form onSubmit={notRequiredSubmit}>
                            <Card>
                                <Card.Header>
                                    <h5>CPD Requirements</h5>
                                </Card.Header>
                                <Card.Body>
                                    <span>Due to the license status chosen at the beginning of this renewal, this section is not required. You may click "Next Section" to continue your renewal.</span>
                                </Card.Body>
                            </Card>
                            <br />
                            <Issues isUserEditable={true} type={3} sectionId={sectionData.id} changeLog={changeLogData} />
                            <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/peRenewal/sections" />
                        </Form>
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}

export default CpdRequirementsPage