import React, { useEffect } from 'react'
import { useState } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ControlLabel, Form, FormControl, FormGroup, Icon, InputPicker, SelectPicker } from 'rsuite';
import paymentService from '../../../../services/Internal/Revenue/payment.service';
import { getContacts, getPaymentTypes } from '../../../../services/Shared/codeTable.service';
import CurrencyMask from '../../../Shared/GenericComponents/CurrencyMask';
import PaymentSearchGrid from './PaymentSearchGrid'

function PaymentSearchPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [paymentType, setPaymentType] = useState([]);
    const [contacts, setContacts] = useState();
    const [gridData, setGridData] = useState([]);
    const [searchModel, setSearchModel] = useState({
        paymentId: '',
        paymentType: null,
        paymentStartDate: '',
        paymentEndDate: '',
        checkNumber: '',
        contact: null,
        amountMin: '',
        amountMax: '',
    })

    useEffect(() => {
        getPaymentTypes().then(setPaymentType);
    }, [])

    const updateContacts = (value) => {
        if (value.length > 2) {
            getContacts(value).then((response) => {
                setContactLoading(true);
                setContacts(response);
                setContactLoading(false);
            });
        }
        else {
            setContacts([]);
        }
    }

    const clearSearchModel = () => {
        setSearchModel({
            paymentId: '',
            paymentType: null,
            paymentStartDate: '',
            paymentEndDate: '',
            checkNumber: '',
            contact: '',
            amountMin: '',
            amountMax: ''
        })
    }
    const submitSearch = () => {
        setIsSearching(true)
        var dataToTransmit = {
            paymentId: searchModel.paymentId == '' ? null : searchModel.paymentId,
            paymentType: searchModel.paymentType?.paymentType ?? null,
            dateCrteBegin: searchModel.paymentStartDate ? new Date(searchModel.paymentStartDate) : null,
            dateCrteEnd: searchModel.paymentEndDate ? new Date(searchModel.paymentEndDate) : null,
            authorizationNumber: searchModel.checkNumber ? searchModel.checkNumber : null,
            contactEntityId: searchModel.contact?.entityId ?? null,
            amountMin: searchModel.amountMin == '' ? null : searchModel.amountMin,
            amountMax: searchModel.amountMax == '' ? null : searchModel.amountMax
        }
        paymentService.searchPayments(dataToTransmit).then((response) => {
            if (response.data) {
                setGridData(response.data);
            }

        }).then(() => {
            setIsSearching(false);
        })
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5>
                        Payment Search
                    </h5>
                </Card.Header>
                <Card.Body>
                    <BlockUi blocking={isSearching}>
                        <Form fluid formValue={searchModel} onChange={setSearchModel} onSubmit={submitSearch} >
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Payment ID</b></ControlLabel>
                                        <FormControl
                                            name="paymentId"
                                            placeholder="Payment ID"
                                            type="number"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Payment Type</b></ControlLabel>
                                        <FormControl
                                            name="paymentType"
                                            placeholder="Select Type"
                                            block="true"
                                            data={paymentType}
                                            accepter={SelectPicker}
                                            searchable={false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Payment Date Created</b></ControlLabel>
                                        <FormControl
                                            name="paymentStartDate"
                                            type="date"
                                            block="true"
                                        />
                                    </FormGroup>
                                </Col>
                                <span style={{ marginTop: '2rem' }}>to</span>
                                <Col>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <FormControl
                                        name="paymentEndDate"
                                        type="date"
                                        block="true"
                                    />
                                </Col>
                                <Col>
                                    <ControlLabel><b>Check#/CC Auth Number</b></ControlLabel>
                                    <FormControl
                                        name="checkNumber"
                                        placeholder="Enter Authorization Number"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={7}>
                                    <FormGroup>
                                        <ControlLabel><b>Contact</b></ControlLabel>
                                        <FormControl
                                            accepter={InputPicker}
                                            block="true"
                                            data={contacts}
                                            name="contact"
                                            labelKey="name"
                                            placeholder="Find a Contact (Min 3 characters)"
                                            onSearch={updateContacts}
                                            renderMenu={menu => {
                                                if (contactLoading) {
                                                    return (
                                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                            <Icon icon="spinner" spin /> Loading...
                                                        </p>
                                                    );
                                                }
                                                return menu;
                                            }}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <>
                                                        {`${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber}`}
                                                    </>
                                                );
                                            }}
                                            renderValue={(value, item, selectedElement) => {
                                                return (
                                                    <>
                                                        {item ? `${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber} ` : 'Find a Contact (Min 3 characters)'}
                                                    </>
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Amount Range</b></ControlLabel>
                                        <FormControl
                                            accepter={CurrencyMask}
                                            name="amountMin"
                                            block="true"
                                            placeholder="Minimum Amount"
                                        />
                                    </FormGroup>
                                </Col>
                                <span style={{ marginTop: '2rem' }}>to</span>
                                <Col>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <FormControl
                                        accepter={CurrencyMask}
                                        name="amountMax"
                                        block="true"
                                        placeholder="Maximum Amount"
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                                <Button type="submit">Search</Button>
                            </div>
                        </Form>
                    </BlockUi>
                </Card.Body>
            </Card>
            <PaymentSearchGrid gridData={gridData} setIsSearchingCallback={setIsSearching} />
        </>

    )
}

export default PaymentSearchPage;