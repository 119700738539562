import axios from "axios";
import authHeader from '../../../authheader'

const lsiGetSection = (applicationId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualLsiApplication/section`, { headers: authHeader(), params: { applicationId, sectionId } });
}
const lsiPostSection = (applicationId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualLsiApplication/signsection`, null, { headers: authHeader(), params: { applicationId, sectionId, electronicSignature } });
}

//const lsiPostSectionHasMyNceesEducation = (applicationId, sectionId, hasMyNceesEducation) => {
//    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualLsiApplication/setsectionhasmynceeseducation`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation } });
//}

const lsiPostEducationSection = (applicationId, sectionId, hasMyNceesEducation, hasAnsacDegree, hasFourYearDegree) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualLsiApplication/seteducationsection`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation, hasAnsacDegree, hasFourYearDegree } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 11,
        sectionTitle: "Examination Verification in Louisiana or Other Jurisdictions",
        sectionContent: "This must be uploaded to your MyNCEES account.",
    },
    {
        id: 12,
        sectionTitle: "Recommendation",
        sectionContent: "You must be recommended for certification by a licensed Professional Surveyor.",
    },
    {
        id: 8,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 9,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    }
])

export default {
    lsiGetSection,
    lsiPostSection,
    lsiPostEducationSection
    //lsiPostSectionHasMyNceesEducation
}