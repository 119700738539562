import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import LegacyCredentialForm from '../Shared/Credential/LegacyCredentialForm';
import FirmCredentialForm from './FirmCredentialForm';

function EditFirmCredential(props) {
    const saveSuccess = (updatedCred) => {
        props.updateCardData();
        var statusReasonIds = [9, 12, 13, 15];
        if ((props.credential?.status?.id != updatedCred.status?.id || props.credential?.statusReason?.id != updatedCred.statusReason?.id) &&
            updatedCred.status?.id == 3 && statusReasonIds.includes(updatedCred.statusReason.id)) {
            var expirationDate = new Date(updatedCred.expirationDate);
            var nextReinstatementDate = new Date();
            nextReinstatementDate.setDate(expirationDate.getDate() + 1);
            Swal.fire('Success!', `Firm credential has been successfully updated. The new expiration date is ${expirationDate.toLocaleDateString()}, and the firm may apply to reinstate starting ${nextReinstatementDate.toLocaleDateString()}.`, 'success');
        }
        else
            Swal.fire('Success!', 'Firm credential has been successfully updated.', 'success');
        props.updateCommentCardCallback();
        props.onHide();
    }

    const generateLicense = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to generate a license for this credential?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                credentialService.activatefirmpendingcredential(props.credential?.id).then((response) => {
                    if (response.data) {
                        props.updateCardData();
                        Swal.fire('Success!', 'Firm credential has been successfully updated.', 'success');
                        props.onHide();
                    }
                })
            }
        })

    }

    return (<Modal
        {...props}
        animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <h4>Credential Information ({props.credential?.licenseTypePrefix})</h4>
        </Modal.Header>
        <Modal.Body>
            {
                props.credential?.licenseTypePrefix == "WG" ?
                    <LegacyCredentialForm formId={"formModal" + props.credential?.id} editMode={true} credential={props.credential} />
                    : props.credential?.licenseTypePrefix == "CPD" ?
                        <LegacyCredentialForm formId={"formModal" + props.credential?.id} editMode={true} credential={props.credential} saveSuccess={saveSuccess} />
                        : <FirmCredentialForm
                            formId={"formModal" + props.credential?.id}
                            editMode={true}
                            credential={props.credential}
                            saveSuccess={saveSuccess}
                            updateSupervisedCardCallback={props.updateSupervisedCardCallback} />
            }
        </Modal.Body>
        <Modal.Footer>
            <div className='w-100' >
                {
                    props.credential?.status?.id == 8 && props.credential?.statusReason?.id == 25 &&
                    <Button onClick={() => generateLicense()} variant='success'>Generate License</Button>
                }
                <span style={{ float: 'right' }}>
                    <Button className="mr-2" variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
                    <Button type="submit" form={"formModal" + props.credential?.id} >Save</Button>
                </span>
            </div>
        </Modal.Footer>
    </Modal >)
}

export default EditFirmCredential;