import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import cpdRequirementsService from '../../../../../services/Public/Renewal/PE/cpdRequirements.service';
import publicRenewalService from '../../../../../services/Public/Renewal/PE/peRenewal.service';
import Information from '../../../../Shared/Card/Information';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import Courses from './Courses'
import entityService from '../../../../../services/Internal/Staff/entity.service';
import peRenewalService from '../../../../../services/Internal/Renewal/PE/peRenewal.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';
import renewalService from '../../../../../services/Internal/Renewal/renewal.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';


function CpdRequirementsPage() {
    const { push } = useHistory();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isRequired, setIsRequired] = useState(true);
    const [isNonActiveToActive, setIsNonActiveToActive] = useState(false);
    const renewalId = useSelector(x => x.currentApplicationId);
    const [sectionData, setSectionData] = useState({});
    const entityId = useSelector(x => x.userInfo.EntityId)
    const [isLicenseStatusSet, setIsLicenseStatusSet] = useState(true);
    const [isRetiredToActive, setIsRetiredToActive] = useState(false);
    const sectionId = 3;
    const routes = useSelector(x => x.currentRoutes);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "CPD Requirements"
        }
    );
    const changeLogData = {
        Header: "CPD Change History",
        BodyText: "The information below displays all changes made to the CPD section. It allows our staff to track any irregularities and find process breakdowns so that we can make improvements to our system.",
        CurrentId: renewalId,
        SectionId: sectionId
    };
    const currentDate = new Date();
    var yearBase = (currentDate.getMonth() == 0 && currentDate.getDate() < 30) ? currentDate.getFullYear() - 1 : currentDate.getFullYear(); //LAPELS-379
    const [formModel, setFormModel] = useState({
        id: 0,
        renewalId: renewalId,
        entityId: entityId,
        year1: (yearBase - 2),
        year1Hours: null,
        year2: (yearBase - 1),
        year2Hours: null,
        metCpd: null,
        notMetCPDReason: null,
        carryoverExplanation: "",
        userCrte: "",
        dateCrte: new Date(),
        deleted: false,
        hasCpdInMyNcees: null,
        electronicSignature: "",
    });
    useEffect(() => {
        cpdRequirementsService.isRetiredToActive(renewalId).then((response) => {
            if (response.data) {
                setIsRetiredToActive(true);
            }
        })
        cpdRequirementsService.isNonActiveToActive(renewalId).then((response) => {
            if (response.data) {
                setIsNonActiveToActive(true);
            }
        })
        publicRenewalService.peGetSection(renewalId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete)
            renewalService.getIndividualRenewal(renewalId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
            cpdRequirementsService.isCpdRequired(renewalId).then((result) => {
                if (result.data) {
                    cpdRequirementsService.getCpdRequirements(renewalId).then((data) => {
                        if (data.data) {
                            setFormModel({ ...data.data, electronicSignature: response.data.electronicSignature })
                        }
                    })
                }
                else if (result.data === false) {
                    setIsRequired(false)
                    setHasLoaded(true)
                }
                else {
                    setIsLicenseStatusSet(false)
                    setHasLoaded(true)
                }
            })
        })
    }, [])

    const { StringType, BooleanType, NumberType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.'),
        metCpd: BooleanType().isRequired('This field is required.'),
        notMetCPDReason: ObjectType().isRequired('This field is required'),
        hasCpdInMyNcees: BooleanType().isRequired("This field is required.")
    });

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            var hasErrors = false;
            var validationObject = model.check(formModel);
            for (const key in validationObject) {
                if (validationObject[key].hasError) {
                    if (!isNonActiveToActive) {
                        if (key === "electronicSignature") {
                            hasErrors = true;
                            return
                        }
                        if (key === "metCpd" && formModel.metCpd === null) {
                            hasErrors = true;
                            return
                        }
                        if (key === "notMetCPDReason" && formModel.metCpd === false) {
                            hasErrors = true;
                            return
                        }
                    }
                    else {
                        if (key === "hasCpdInMyNcees" && formModel.hasCpdInMyNcees === null) {
                            hasErrors = true;
                            return;
                        }
                    }
                }
            }
            if (!hasErrors) {
                submitData(true);
            }
        }
    }
    const formSave = (event) => {
        submitData();
    }
    const submitData = (isForSubmiit) => {
        var dataToTransmit = { ...formModel, year1Hours: formModel.year1Hours === '' ? null : formModel.year1Hours, year2Hours: formModel.year2Hours === '' ? null : formModel.year2Hours };
        publicRenewalService.pePostSection(renewalId, sectionId, formModel.electronicSignature).then((data) => {
            cpdRequirementsService.postCpdRequirements(dataToTransmit).then((response) => {
                if (response.data.id) {
                    if (data.data) {
                        peRenewalService.peMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                            if (isForSubmiit)
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                            else
                                Swal.fire("Saved!");
                        })
                    }
                }
            })
        })
    }
    const notRequiredSubmit = (event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            peRenewalService.peMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            })
        }
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetRenewalInformationContent("cpdRequirements")} />
                <br />
                {hasLoaded ? !isLicenseStatusSet ?
                    <Form onSubmit={notRequiredSubmit}>
                        <Card>
                            <Card.Header>
                                <h5>CPD Requirements</h5>
                            </Card.Header>
                            <Card.Body>
                                <span>Please go back and choose a license status to renew into before completing this section. You may access that section by clicking the "Back to Sections" button at the bottom of the page.</span>
                            </Card.Body>
                        </Card>
                        <br />
                        <Issues isUserEditable={isUserEditable} type={3} sectionId={sectionData.id} changeLog={changeLogData} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            backToSectionLink="/internal/perenewal/sections" />
                    </Form>
                    :
                    isRequired ?
                        <Form model={model} formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} fluid>
                            <Courses
                                isReadOnly={!isUserEditable}
                                year1Number={formModel.year1}
                                year2Number={formModel.year2}
                                hasCpdInMyNcees={formModel.hasCpdInMyNcees}
                                metCpd={formModel.metCpd}
                                isRetiredToActive={isRetiredToActive}
                                isNonActiveToActive={isNonActiveToActive}
                                sectionId={sectionData.id}
                                entityId={formModel.entityId}
                            />
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={sectionData.id} setIsComplete={setIsComplete} changeLog={changeLogData} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                showSectionComplete={true}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                backToSectionLink="/internal/perenewal/sections" />
                        </Form> :
                        <Form onSubmit={notRequiredSubmit}>
                            <Card>
                                <Card.Header>
                                    <h5>CPD Requirements</h5>
                                </Card.Header>
                                <Card.Body>
                                    <span>Due to the license status chosen at the beginning of this renewal, this section is not required. You may click "Next Section" to continue your renewal.</span>
                                </Card.Body>
                            </Card>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={sectionData.id} setIsComplete={setIsComplete} changeLog={changeLogData} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete} isCompleteCallback={setIsComplete}
                                showSectionComplete={true} backToSectionLink="/internal/perenewal/sections" />
                        </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}

export default CpdRequirementsPage