import axios from "axios";
import authHeader from '../../../authheader'

const LSIAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualLsiApplication";

const getRecommendations = (applicationId) => {
    return axios.get(`${LSIAPPLICATIONURL}/recommendations`, { headers: authHeader(), params: { applicationId } });
}
const postRecommendation = (data) => {
    return axios.post(`${LSIAPPLICATIONURL}/recommendation`, data, { headers: authHeader() });
}

const sendRecommendationEmail = (recommendationId) => {
    return axios.post(`${LSIAPPLICATIONURL}/sendrecommendationemail`, null, { headers: authHeader(), params: { recommendationId } });
}

export default {
    getRecommendations,
    postRecommendation,
    sendRecommendationEmail
}