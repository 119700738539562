import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { InputPicker, SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup } from 'rsuite';
import RedStar from '../GenericComponents/RedStar';
import YesNoRadioOption from '../RadioButton/YesNoRadioOption';
import { getSchools, getDegrees } from '../../../services/Shared/codeTable.service'
import EducationService from '../../../services/Public/Application/PLS/education.service'

function AddEditEducationRecordsModal(props) {
    const [schools, setSchools] = useState([]);
    const [degrees, setDegrees] = useState([]);
    
    const [recordModel, setRecordModel] = useState({
        applicationId :'',
        attendanceEndDate: null,
        attendanceStartDate: null,
        dateCrte: null,
        degree: null,
        deleted: false,
        didGraduate:'',
        entityId: '',
        graduationDate:null,
        id:0,
        major: '',
        school: null,
        userCrte: '',
        modalType: "Add"
    })

    useEffect(() => {
        getSchools().then(setSchools);
        getDegrees().then(setDegrees);
        setRecordModel({
            ...props.modaldata
        });

    }, [props.modaldata])

    const { StringType, ObjectType, ArrayType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        school: ObjectType().isRequired('This field is required.'),
        attendanceStartDate: StringType().isRequired('This field is required.'),
        attendanceEndDate: StringType().isRequired('This field is required.'),
        didGraduate: BooleanType().isRequired('This field is required.'),
        graduationDate: StringType().isRequired('This field is required.'),
        degree: ObjectType().isRequired('This field is required.'),
    })
    const formSubmit = (validation, event) => {
        var validationObject = model.check(recordModel);
        for (const key in validationObject) {
            if (recordModel.didGraduate === false) {
                if (validationObject[key].hasError && !(key.includes("graduationDate") || key.includes("degree"))) {
                    return;
                }
            }
        }
        if (recordModel.didGraduate === false || (validation && recordModel.didGraduate === true)) {
            submitData();
        }
    }
    const submitData = () => {
        var dataToTransmit = {
            ...recordModel,
            id: recordModel.id === '' ? 0 : recordModel.id,
            attendanceEndDate: new Date(recordModel.attendanceEndDate),
            attendanceStartDate: new Date(recordModel.attendanceStartDate)
        };
        delete dataToTransmit.modalType;
        var didSave = false;
        EducationService.postEducationRecord(dataToTransmit).then((response) => {
            if (response.data.id) {
                props.updateGrid();
                props.onHide();
            }
        }).catch((response) => {
        })
        //post formData to api
        //return bool didSave
        
    }
    const handleDidGraduateChange = (value) => {
        if (!value) {
            setRecordModel({
                ...recordModel, graduationDate: null, degree: null, didGraduate: value
            })
        }
    } 
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {recordModel.modalType} an Education Record
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="recordForm" fluid model={model} formValue={recordModel} onChange={recordModel => setRecordModel(recordModel)} onSubmit={formSubmit} >
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Institution Name&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block
                                    name="school"
                                    placeholder="Pick an institution"
                                    accepter={InputPicker}
                                    data={schools} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Date From&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="month"
                                    block="true"
                                    name="attendanceStartDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Date To&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="month"
                                    block="true"
                                    name="attendanceEndDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Did you graduate?&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <YesNoRadioOption handleChange={ handleDidGraduateChange} radioName="didGraduate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    {recordModel.didGraduate &&
                        <>
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>Graduation Date&nbsp;<RedStar /></strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col sm={9}>
                                    <FormControl
                                        type="date"
                                            block="true"
                                        name="graduationDate" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Degree&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col sm={9}>
                                        <FormControl accepter={SelectPicker}
                                            placeholder="Select Type"
                                            searchable={false}
                                            block
                                            data={degrees}
                                            name="degree" />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </> 
                    }
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Major
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="major" />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="recordForm" variant="success">Save</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditEducationRecordsModal;