// This can be edited for other actions to update the store when it expands
// combineReducers could make a bigger one that has more data and separated pieces to retrieve
function userInfo(state = {}, action) {
    switch (action.type) {
        case 'LOGIN_SUCCEEDED':
            return action.payload;
        default:
            return state;
    }
}

function currentApplicationId(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_APP_ID':
            return action.payload;
        default:
            return state;
    }
}
function currentApplicationEntityId(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_APP_ENTITY_ID':
            return action.payload;
        default:
            return state;
    }
}
function currentApplicationTypeId(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_APP_TYPE_ID':
            return action.payload;
        default:
            return state;
    }
}
function currentRoutes(state = [], action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_ROUTE':
            return action.payload;
        default:
            return state;
    }
}

function refreshToken(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_REFRESH':
            return action.payload;
        default:
            return state;
    }
}
function currentInvoiceId(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_INVOICE_ID':
            return action.payload;
        default:
            return state;
    }
}
function refreshRecentQueue(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_RECENT_QUEUE':
            return action.payload;
        default:
            return state;
    }
}
function refreshRecentContacts(state = 0, action) {
    switch (action.type) {
        case 'PERSIST_CURRENT_RECENT_CONTACTS':
            return action.payload;
        default:
            return state;
    }
}

export default {
    userInfo,
    currentApplicationId,
    currentRoutes,
    currentApplicationEntityId,
    currentApplicationTypeId,
    refreshToken,
    currentInvoiceId,
    refreshRecentQueue,
    refreshRecentContacts
}