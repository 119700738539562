import axios from "axios";
import authHeader from './authheader.js'

const SUPERVISINGPROFESSIONALURL = process.env.REACT_APP_APIURL + "/api/SupervisingProfessional";
const RELATIONSHIPURL = process.env.REACT_APP_APIURL + "/api/Relationship";

const getSupervisingProfessional = () => {
    return axios.get(`${SUPERVISINGPROFESSIONALURL}/getsupervisingprofessionalfirms`, { headers: authHeader() });
}
const postSupervisingProfessional = (supervisingProfessionalId, isAccepted) => {
    return axios.post(`${SUPERVISINGPROFESSIONALURL}/updatesupervisingprofessional`, null, { headers: authHeader(), params: { supervisingProfessionalId, isAccepted } });
}
const getFirmSupervisingProfessional = () => {
    return axios.get(`${SUPERVISINGPROFESSIONALURL}/getsupervisingprofessionals`, { headers: authHeader() });
}
const getSupervisingProfessionalsByLicenseType = (entityId, prefix) => {
    return axios.get(`${SUPERVISINGPROFESSIONALURL}/getsupervisingprofessionalsbylicensetype`, { headers: authHeader(), params: { entityId, prefix } });
}
const postFirmSupervisingProfessional = (formattedLicenseNumber, isOwner) => {
    return axios.post(`${SUPERVISINGPROFESSIONALURL}/createfirmsupervisingprofessional`, null, { headers: authHeader(), params: { formattedLicenseNumber, isOwner } });
}
const deleteSupervisingProfessional = (relationshipId, supervisingProfessionalId) => {
    return axios.post(`${SUPERVISINGPROFESSIONALURL}/deletesupervisingprofessional`, null, { headers: authHeader(), params: { relationshipId, supervisingProfessionalId } });
}
const inactivateSupervisingProfessional = (relationshipId, supervisingProfessionalId) => {
    return axios.post(`${SUPERVISINGPROFESSIONALURL}/inactivatesupervisingprofessional`, null, { headers: authHeader(), params: { relationshipId, supervisingProfessionalId } });
}

const inactivateSupervisingProfessionalAsIndividual = (relationshipId, supervisingProfessionalId) => {
    return axios.post(`${SUPERVISINGPROFESSIONALURL}/selfinactivatesupervisingprofessional`, null, { headers: authHeader(), params: { relationshipId, supervisingProfessionalId } });
}
const postFirmSupervisingProfessionalInternal = (relationshipId, isOwner) => {
    return axios.post(`${RELATIONSHIPURL}/updatesprelationshipforfirm`, null, { headers: authHeader(), params: { relationshipId, isOwner } });
}
const createFirmSupervisingProfessionalInternal = (firmEntityId, isOwner, professionalFormattedLicenseNumber) => {
    return axios.post(`${RELATIONSHIPURL}/createactivesprelationshipforfirm`, null, { headers: authHeader(), params: { firmEntityId, isOwner, professionalFormattedLicenseNumber } });
}
const changeSupervisingProfessionalOwnership = (relationshipId, supervisingProfessionalId) => {
    return axios.post(`${SUPERVISINGPROFESSIONALURL}/changesupervisingprofessionalownership`, null, { headers: authHeader(), params: { relationshipId, supervisingProfessionalId } });
}
export default {
    getSupervisingProfessional,
    postSupervisingProfessional,
    getFirmSupervisingProfessional,
    postFirmSupervisingProfessional,
    deleteSupervisingProfessional,
    inactivateSupervisingProfessional,
    getSupervisingProfessionalsByLicenseType,
    postFirmSupervisingProfessionalInternal,
    createFirmSupervisingProfessionalInternal,
    changeSupervisingProfessionalOwnership,
    inactivateSupervisingProfessionalAsIndividual
}