import { useState, useEffect } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import React from 'react'
import { ControlLabel, Schema, Form, FormControl, FormGroup, InputPicker, Icon } from 'rsuite'
import YesNoRadioOption from '../../../Shared/RadioButton/YesNoRadioOption';
import { getIndividualCredentialsInternal, getIndividualCredentialsOnEdit } from '../../../../services/Shared/codeTable.service'
import RedStar from '../../../Shared/GenericComponents/RedStar';
import supervisingProfessionalService from '../../../../services/supervisingProfessional.service';
import Swal from 'sweetalert2';

function EditSupervisedByModal(props) {
    const [individualCreds, setIndividualCreds] = useState();
    const [individualCredLoading, setIndividualCredLoading] = useState(false);
    const [supervisingProfessionalModel, setSupervisingProfessionalModel] = useState({
        selectedValue: "",
        isOwner: "",
        firmEntityId: 0
    })
    useEffect(() => {
        if (props.modaldata.professionalCredentialId) {
            getIndividualCredentialsOnEdit(props.modaldata.professionalFormattedLicenseNumber).then((response) => {
                if (response) {
                    setIndividualCreds(response);
                    setSupervisingProfessionalModel({ ...props.modaldata, selectedValue: response.value })
                }
                else {

                }
            })
        }
        else {
            setSupervisingProfessionalModel(props.modaldata);
        }
    }, [props.modaldata])

    const { ObjectType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        selectedValue: ObjectType().isRequired('This field is required.'),
        isOwner: BooleanType().isRequired('This field is required.'),
    })
    const formSubmit = (val, event) => {
        if (val) {
            if (supervisingProfessionalModel.modalType == "Edit") {
                supervisingProfessionalService.postFirmSupervisingProfessionalInternal(supervisingProfessionalModel.id, supervisingProfessionalModel.isOwner).then((response) => {
                    if (response.data) {
                        props.onHide();
                        setSupervisingProfessionalModel({
                            selectedValue: "",
                            isOwner: "",
                            firmEntityId: 0
                        });
                    }
                }).catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } });
            }
            else if (supervisingProfessionalModel.modalType == "Add") {
                supervisingProfessionalService.createFirmSupervisingProfessionalInternal(supervisingProfessionalModel.firmEntityId, supervisingProfessionalModel.isOwner, supervisingProfessionalModel.selectedValue.formattedLicenseNumber).then((response) => {
                    if (response.data) {
                        props.onHide();
                        setSupervisingProfessionalModel({
                            selectedValue: "",
                            isOwner: "",
                            firmEntityId: 0
                        });
                    }
                }).catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } });

            }
        }
    }
    const updateIndividualCreds = (value) => {
        getIndividualCredentialsInternal(value, supervisingProfessionalModel.firmEntityId).then((response) => {
            setIndividualCredLoading(true);
            setIndividualCreds(response);
            setIndividualCredLoading(false);
        });
    }

    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {supervisingProfessionalModel.modalType} Supervising Professional
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    model={model}
                    id="supervisingProfessionalForm"
                    formValue={supervisingProfessionalModel}
                    onChange={setSupervisingProfessionalModel}
                    onSubmit={formSubmit}
                    fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        License #&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    disabled={supervisingProfessionalModel.modalType == 'Edit'}
                                    accepter={InputPicker}
                                    block
                                    data={individualCreds}
                                    name="selectedValue"
                                    placeholder="License #"
                                    onSearch={updateIndividualCreds}
                                    renderMenu={menu => {
                                        if (individualCredLoading) {
                                            return (
                                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                    <Icon icon="spinner" spin /> Loading...
                                                </p>
                                            );
                                        }
                                        return menu;
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Owner of this firm?&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <YesNoRadioOption radioName="isOwner" />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
                <Button type="submit" form="supervisingProfessionalForm" >{supervisingProfessionalModel.modalType == 'Edit' ? ("Save") : ("Add New")}</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default EditSupervisedByModal;