import axios from "axios";
import authHeader from './authheader.js'

const NOTIFICATIONURL = process.env.REACT_APP_APIURL + "/api/Notification";

const getNotifications = () => {
    return axios.get(NOTIFICATIONURL, { headers: authHeader() });
}

const deleteNotification = (notificationId) => {
    return axios.post(NOTIFICATIONURL + "/deletenotification", null, { headers: authHeader(), params: { notificationId} });
}

const deleteAllNotifications = () => {
    return axios.post(NOTIFICATIONURL + "/deleteallnotification", null, { headers: authHeader() });
}

export default {
    getNotifications,
    deleteNotification,
    deleteAllNotifications
}