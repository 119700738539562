import React, { useState, useEffect } from 'react'
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Form, FormControl, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import reinstatementService from '../../../../../services/Internal/Reinstatement/reinstatement.service';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import FormEntityInformation from '../../../Shared/FormEntityInformation';
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function AcknowledgementPage() {
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const sectionId = 9;
    const routes = useSelector(x => x.currentRoutes);
    const [hasLoaded, setHasLoaded] = useState(false);
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [formModel, setFormModel] = useState({});
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Acknowledgement"
        }
    );
    useEffect(() => {
        reinstatementService.plsGetSection(reinstatementId, sectionId).then((response) => {
            reinstatementService.getIndividualReinstatement(reinstatementId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
                setFormModel(response.data);
                setHasLoaded(true)
                setIsComplete(response.data.staffReviewComplete)
            });


        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }
    const submitData = (isForSubmit) => {
        reinstatementService.plsPostSection(reinstatementId, sectionId, formModel.electronicSignature).then((response) => {
            reinstatementService.plsMarkSectionComplete(reinstatementId, sectionId, isComplete).then((data) => {
                if (isForSubmit && response.data.id && data.data.id) {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                }
                else if (response.data.id) {
                    Swal.fire("Saved!");
                }
            })
        })
    }

    return (
        <div>
            <Container fluid>
                <Form readOnly={!isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                    <FormEntityInformation {...entityInfo} />
                    {hasLoaded ?
                        <>
                            <Card>
                                <Card.Header><h6>Requirements/Information/Acknowledgement</h6></Card.Header>
                                <Card.Body>
                                    <p>I, the applicant named in this application, have read the contents hereof, and to the best of my knowledge, information and believe the statements and information in this application are true and correct in substance and effect and are made in good faith. I have not omitted any information requested in this application. I am aware that such an omission or untrue response may constitute fraud or deceit, material misstatement, perjury or the giving of false or forged evidence under Louisiana Revised Statutes 37:698 and 37:700. I understand and agree that any responses verifying my education, personal references, and experience shall be considered privileged and confidential and that this information was not reviewed by me nor will it be available for my inspection or review in the future.</p>
                                    <br />
                                    <ul>
                                        <li>It is your responsibility to check your LOLA account frequently for any pending issues. Ensure you add "lapels.com" to your "Safe Senders" list in your email application and/or (if applicable) in your network firewall.</li>
                                        <li>Once we receive a complete application, with no pending issues, process time may take 6-8 weeks.  </li>
                                        <li>
                                            I certify that all information provided in this online reinstatement is true and correct to the best of my knowledge, information and belief.
                                        </li>
                                        <li>
                                            I am aware that an omission or untrue response may constitute fraud, deceit, material misstatement, perjury or the giving of false or forged evidence under Louisiana Revised Statutes 37:698 and 37:700
                                        </li>

                                        <li>
                                            <b className="colorRed">IMPORTANT: Payments are non-refundable.</b> If you believe that you will be reimbursed for your fee, verify this BEFORE proceeding further
                                        </li>
                                        <li>
                                            If you are unsure about getting reimbursed by your firm, please stop the application process here and come back after you verify. Your answers will be saved.
                                        </li>
                                    </ul>
                                    <hr />
                                    <Row>
                                        <Col sm={4}>
                                            <h5>Electronic Signature of Applicant:<RedStar /></h5>
                                            <span>(Type full name)</span>
                                        </Col>
                                        <Col sm={8}><FormControl name="electronicSignature" /></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={formModel.id} setIsComplete={setIsComplete} /></> : <LoadingSpinner />
                    }
                    <FormActionButtons
                        isUserEditable={isUserEditable}
                        isComplete={isComplete}
                        isCompleteCallback={setIsComplete}
                        showSectionComplete={true}
                        backToSectionLink="/internal/plsReinstatement/sections" />
                </Form>
            </Container>

        </div>
    );
}
export default AcknowledgementPage