import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FormControl, Input, Table } from 'rsuite';
import Swal from 'sweetalert2';
import supervisingProfessionalService from '../../../services/supervisingProfessional.service';
import GenericBadge from '../Badge/GenericBadge';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';
import RedStar from "../GenericComponents/RedStar";
import YesNoRadioOption from '../RadioButton/YesNoRadioOption';
import DocumentUpload from '../Upload/DocumentUpload';


function SupervisingProfessional(props) {
    const [supervisingLockerHasLoaded, setSupervisingLockerHasLoaded] = useState(false);
    const [supervisingProfessionals, setSupervisingProfessionals] = useState([]);
    useEffect(() => {
        if (props.entityId && props.prefix) {
            supervisingProfessionalService.getSupervisingProfessionalsByLicenseType(props.entityId, props.prefix).then((result) => { setSupervisingProfessionals(result.data) }).then(() => {
                setSupervisingLockerHasLoaded(true)
            });
        }
    }, [props.entityId])
    return (
        <div className="vfAppSupervisingProfessional">
            <Card>
                <Card.Body>
                    <p className="mb-3"><RedStar />I attest that I am the Supervising Professional of my firm.</p>

                    {/*{props.isRenewal &&*/}
                    {/*    <div style={{ backgroundColor: 'black' }}>*/}
                    {/*    <Alert transition={false} className="text-warning" >*/}
                    {/*        <p>All Supervising Professionals <i><b><u>MUST</u></b></i> complete the attestation form <i><b><u>ONLY</u></b></i> during the firm's renewal period. Download the form from here: <a href="https://www.lapels.com/docs/Applications/firm/Firm_SP_Attestation_AR.pdf" class="text-warning" target="_blank"><u>https://www.lapels.com/docs/Applications/firm/Firm_SP_Attestation_AR.pdf </u></a></p>*/}
                    {/*    <p>Upload the completed form using the "Upload" button below.</p>*/}
                    {/*    <p><b>Failure to do so could result in a violation for both firm and individual.</b></p>*/}
                    {/*    </Alert>*/}
                    {/*    </div>*/}

                    {/*}*/}
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasFirmSubmittedSupervisingProfessional" />




                    <Card bg="danger" text="white" className="text-center">
                        <Card.Body>



                            <Row>
                                <Col md={10} className="text-center"> <p>You are required to have at least one (1) supervising professional in your Supervising Professional (SP) Locker before continuing.
                                    To add or remove an SP, click the "Update Supervising Professionals" button, or "SP Locker" in the left menu, to open your SP Locker in a new tab.
                                    If you are replacing your only assigned SP, you must add a new one before removing the old one. After adding one or more SP's, those SP's must sign into their LOLA account(s)
                                    and go to their own SP Locker to accept the role. Once they have completed this, you may continue your firm application, renewal, or reinstatement.</p></Col>
                                <Col md={2}><Button variant="light" as={Link} to={props.supervisingProfessionalLink} target="_blank"> Update Supervising <br /> Professionals</Button></Col>
                            </Row>


                        </Card.Body>
                    </Card>



                    {props.isInternal && <>
                        <br />{supervisingLockerHasLoaded ?
                            <Table wordWrap autoHeight data={supervisingProfessionals}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Name
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.professionalFormattedName)}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        License #
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.codeVerificationStatus?.verificationStatus === "Approved" ? <Button variant="link" style={{ 'padding': 0 }} href={"internal/indCredential?entityId=" + rowData.professionalEntityId} target="_blank" >{rowData.professionalFormattedLicenseNumber}</Button> : rowData.professionalFormattedLicenseNumber)}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Owner of this firm?
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.isOwner ? "+25% or more" : "No")}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Sent Date
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.sentDate ? new Date(rowData.sentDate).toLocaleDateString() : '')}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Response Date
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.responseDate ? new Date(rowData.responseDate).toLocaleDateString() : '')}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Verification Status
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.codeVerificationStatus?.verificationStatus ? <GenericBadge status={rowData.codeVerificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                                </Table.Column>
                            </Table> : <LoadingSpinner />
                        }
                    </>
                    }
                    <hr />

                    <br />
                    <Card style={{ padding: '1rem' }}>
                        <DocumentUpload
                            isUserEditable={!props.isReadOnly}
                            credentialTypeId={props.prefix == 'EF' ? 1 : 2}
                            sectionId={props.sectionId}
                            //appTypeId={ }
                            //refreshData={ }
                            parentId={props.sectionDataId}
                            parentTypeId={props.parentTypeId}
                            entityId={props.entityId}
                            warningMessage={props.isRenewal ? <i className='colorRed'>(Supervising Professional Attestation Form)</i> : <i className='colorRed'>(select quiz type from dropdown and click "Upload". Repeat for each quiz type.)</i>}
                            fileUploadTitle={props.isRenewal ? "File Upload" : "Quiz File Upload"}
                        />
                    </Card>
                    <br />
                    <Row>
                        <Col sm={4}>
                            <h5>Electronic Signature of Supervising Professional(s):<RedStar /></h5>
                            <span>(Type full name of each Supervising Professional, separated by commas)</span>
                        </Col>
                        <Col sm={8}><FormControl
                            accepter={Input}
                            componentClass="textarea"
                            rows={2}
                            readOnly={props.isReadOnly}
                            name="electronicSignature" /></Col>
                    </Row>
                </Card.Body>
            </Card>
        </div >
    )
}
export default SupervisingProfessional;