import axios from "axios";
import authHeader from '../../../authheader'

const PLSREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsReinstatement";
const PEREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPeReinstatement";

const getExperienceRecords = (reinstatementId) => {
    return axios.get(`${PLSREINSTATEMENTURL}/experiencerecords`, { headers: authHeader(), params: { reinstatementId } });
}
const postExperienceRecord = (data) => {
    return axios.post(`${PLSREINSTATEMENTURL}/experiencerecord`, data, { headers: authHeader() });
}
const sendVerifierEmail = (experienceId) => {
    return axios.post(`${PLSREINSTATEMENTURL}/sendexperienceverificationemail`, null, { headers: authHeader(), params: { experienceId } });
}
const peGetExperienceRecords = (reinstatementId) => {
    return axios.get(`${PEREINSTATEMENTURL}/experiencerecords`, { headers: authHeader(), params: { reinstatementId } });
}
const pePostExperienceRecord = (data) => {
    return axios.post(`${PEREINSTATEMENTURL}/experiencerecord`, data, { headers: authHeader() });
}
const peSendVerifierEmail = (experienceId) => {
    return axios.post(`${PEREINSTATEMENTURL}/sendexperienceverificationemail`, null, { headers: authHeader(), params: { experienceId } });
}
export default {
    getExperienceRecords,
    postExperienceRecord,
    sendVerifierEmail,
    peGetExperienceRecords,
    pePostExperienceRecord,
    peSendVerifierEmail
}