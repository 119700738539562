import React, { useEffect, useState } from 'react'
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { Card } from 'react-bootstrap';
import DocumentUpload from '../../../../Shared/Upload/DocumentUpload';

function QuizzesPage(props) {
    //const attachmentTypes = [
    //    {
    //        "label": "Quiz - Laws and Rules", "value": 12
    //    },
    //    {
    //        "label": "Quiz - Ethics", "value": 13
    //    }
    //]

    return (
        <div>
            <Information content={GetAppInformationContent("quiz")} isQuiz={true} />
            <br />
            <Card>
                <Card.Body>
                    <DocumentUpload
                        isUserEditable={props.isUserEditable}
                        credentialTypeId={6}
                        sectionId={3}
                        attachmentSection={"Quiz"}
                        //appTypeId={}
                        //refreshData={ }
                        parentId={props.sectionId}
                        parentTypeId={3}
                        entityId={props.entityId}
                        fileUploadTitle={"Quiz File Upload"}
                    />
                </Card.Body>
            </Card>
        </div>
    )
}
export default QuizzesPage;