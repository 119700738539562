import axios from "axios";
import authHeader from '../../authheader'

const INDIVIDUALAPP = process.env.REACT_APP_APIURL + "/api/IndividualApplication";
const FIRMAPP = process.env.REACT_APP_APIURL + "/api/FirmApplication";

const getPendingApplications = async () => {
    return axios.get(`${INDIVIDUALAPP}/pendingApplications`, { headers: authHeader() });;
};
const getIndividualApplication = async (applicationId) => {
    return axios.get(`${INDIVIDUALAPP}/application`, { headers: authHeader(), params: { applicationId } });;
};
const getHistoricalApplications = async () => {
    return axios.get(`${INDIVIDUALAPP}/historicalapplications`, { headers: authHeader() });;
};
const createApplication = (applicationTypeId) => {
    return axios.post(`${INDIVIDUALAPP}/create`, null, { headers: authHeader(), params: { applicationTypeId } })
        .then((response) => {
            return response;
        });
}
const deleteDraft = (applicationId) => {
    return axios.post(`${INDIVIDUALAPP}/deletedraft`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        });
}
const validateApplication = (applicationId) => {
    return axios.post(`${INDIVIDUALAPP}/validate`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const submitApplication = (applicationId) => {
    return axios.post(`${INDIVIDUALAPP}/submit`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}

const getFirmPendingApplications = async () => {
    return axios.get(`${FIRMAPP}/pendingapplications`, { headers: authHeader() });;
};
const getFirmApplication = async (applicationId) => {
    return axios.get(`${FIRMAPP}/application`, { headers: authHeader(), params: { applicationId } });;
};
const getFirmHistoricalApplications = async () => {
    return axios.get(`${FIRMAPP}/historicalapplications`, { headers: authHeader() });;
};

const createFirmApplication = (applicationTypeId) => {
    return axios.post(`${FIRMAPP}/create`, null, { headers: authHeader(), params: { applicationTypeId } })
        .then((response) => {
            return response;
        });
}
const deleteFirmDraft = (applicationId) => {
    return axios.post(`${FIRMAPP}/deletedraft`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        });
}
const validateFirmApplication = (applicationId) => {
    return axios.post(`${FIRMAPP}/validate`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const submitFirmApplication = (applicationId) => {
    return axios.post(`${FIRMAPP}/submit`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}

export default {
    getPendingApplications,
    getIndividualApplication,
    getHistoricalApplications,
    createApplication,
    deleteDraft,
    validateApplication,
    submitApplication,
    getFirmPendingApplications,
    getFirmHistoricalApplications,
    createFirmApplication,
    deleteFirmDraft,
    validateFirmApplication,
    submitFirmApplication,
    getFirmApplication
}