import React from 'react'
import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { ControlLabel, Form, FormControl, FormGroup, Schema } from 'rsuite'
import Swal from 'sweetalert2';
import contactService from '../../../services/contact.service';


function UserProfilePage() {
    const [userProfileModel, setUserProfileModel] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    })

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        currentPassword: StringType().isRequired("This field is required"),
        // For some reason rsuite will keep calling this even with check trigger set to none
        //currentPassword: StringType().isRequired("This field is required").addRule((val, data) => {
        //    return checkCurrentPassword(val);
        //}, "Current password is incorrect"),
        newPassword: StringType().isRequired("This field is required").addRule((val, data) => {
            return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*\-_]).{8,}$/.test(val);
        }, "Password requires a minimum of 8 characters using upper and lower case letters, and at least 1 number and 1 symbol"),
        confirmNewPassword: StringType().addRule((val, data) => {
            if (val !== data.newPassword) {
                return false;
            }
            return true;
        }, "Does not match new password").isRequired("This field is required")
    })
    const checkCurrentPassword = (passwordValue) => {
        contactService.checkPassword(passwordValue).then((response) => {
            return new Promise(resolve => {
                resolve(response.data.isSuccess);
            });
        })
    }

    const formSubmit = (validation, event) => {
        if (validation) {
            contactService.changePassword(userProfileModel).then((response) => {
                if (response.data.isSuccess) {
                    // Swal to tell them its changed
                    Swal.fire("Saved!", response.data.message, 'success');
                    setUserProfileModel({
                        currentPassword: "",
                        newPassword: "",
                        confirmNewPassword: "",
                    })
                }
                else {
                    // Swal using response.Message
                    Swal.fire("Failed to Change Password!", response.data.message, 'error');
                }
            }).catch((error) => {
                var errors = "";
                if (error.response.data.errors?.length > 0) {
                    errors = "<br/> " + error.response.data.errors.join("<br/>");
                }
                Swal.fire("Failed to Change Password!", error.response.data.message + errors, 'error');
            });
        }
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Row>
                        <Col sm={5}></Col>
                        <Col>
                            <h5>Update User Profile</h5>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm={5}></Col>
                        <Col>
                            <Form
                                formValue={userProfileModel}
                                model={model}
                                onChange={(value) => setUserProfileModel(value)}
                                onSubmit={formSubmit}>
                                <FormGroup>
                                    <ControlLabel>Current Password</ControlLabel>
                                    <FormControl type="password" name="currentPassword" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>New Password</ControlLabel>
                                    <FormControl type="password" name="newPassword" checkTrigger="blur" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Re-Type New Password</ControlLabel>
                                    <FormControl type="password" name="confirmNewPassword" checkTrigger="blur" />
                                </FormGroup>
                                <Button type="submit">Update Password</Button>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}
export default UserProfilePage;