import axios from "axios";
import authHeader from '../../../authheader'

const EIRENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualEiRenewal";



const getBoardRecommendations = (renewalId) => {
    return axios.get(`${EIRENEWALURL}/boardrecommendations`, { headers: authHeader(), params: { renewalId } });
}
const getBoardResponses = (renewalId) => {
    return axios.get(`${EIRENEWALURL}/boardmemberresponses`, { headers: authHeader(), params: { renewalId } });
}
const postBoardRecommendation = (data) => {
    return axios.post(`${EIRENEWALURL}/boardrecommendation`, data, { headers: authHeader() });
}
const postBoardResponse = (data) => {
    var header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    return axios.post(`${EIRENEWALURL}/boardmemberresponse`, data, { headers: { ...header } });
}
export default {
    getBoardRecommendations,
    getBoardResponses,
    postBoardRecommendation,
    postBoardResponse
}