import React from 'react';
import { Card } from 'react-bootstrap'
function Information() {
    return (
        <div className="applicationInstructions">
            <Card>
                <Card.Header><h6>Requirements/Information</h6></Card.Header>
                <Card.Body>
                    
                    <ul>
                       
                        <li>Most of this information may be included in your NCEES records.</li>
                        <li>If you do not have a NCEES record, you must provide all required documents. Please know this may add 2-3 weeks to the application process.</li>
                        <li>Complete all required sections of the application; incomplete applications are only kept on file for one year.</li>
                        <li>Fields marked with an asterisk * are required.</li>
                    </ul>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Information;