import axios from "axios";
import authHeader from '../../../authheader'


const INTERNALREINSTATEMENT = process.env.REACT_APP_APIURL + "/api/IndividualReinstatement";

const getIndividualReinstatement = async (reinstatementId) => {
    return axios.get(`${INTERNALREINSTATEMENT}/reinstatement`, { headers: authHeader(), params: { reinstatementId } });;
};

const lsiGetSection = (reinstatementId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualLsiReinstatement/section`, { headers: authHeader(), params: { reinstatementId, sectionId } });
}
const lsiPostSection = (reinstatementId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualLsiReinstatement/signsection`, null, { headers: authHeader(), params: { reinstatementId, sectionId, electronicSignature } });
}
const lsiPostSectionHasMyNceesEducation = (reinstatementId, sectionId, hasMyNceesEducation) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualLsiReinstatement/setsectionhasmynceeseducation`, null, { headers: authHeader(), params: { reinstatementId, sectionId, hasMyNceesEducation } });
}
const lsiMarkSectionComplete = (reinstatementId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualLsiReinstatement/marksectioncomplete`, null, { headers: authHeader(), params: { reinstatementId, sectionId, complete } })
}
export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Ensure your contact information, including your mailing and public addresses are up to date.  Ensure you put LAPELS emails on your safe sender list.",
    },
    {
        id: 16,
        sectionTitle: "Education",
        sectionContent: "All transcripts must be verified in your NCEES record for EI/LSI reinstatement application processing.",
    },
    {
        id: 3,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 17,
        sectionTitle: "Examination Verification in Louisiana or Other Jurisdictions",
        sectionContent: "This must be uploaded to your MyNCEES account.",
    },
    {
        id: 9,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 10,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    },
    {
        id: 11,
        sectionTitle: "Board/Staff Recommendations",
        sectionContent: 'Waiting on text',
    }
])

export default {
    getIndividualReinstatement,
    lsiGetSection,
    lsiPostSection,
    lsiMarkSectionComplete,
    lsiPostSectionHasMyNceesEducation
}