import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import seal from '../../../images/LAPELS-LOLA-Logo.png'
import React, { useState } from 'react';
import IndividualForm from './IndividualForm';
import FirmForm from './FirmForm';
import { Col, Row } from 'react-bootstrap';
import { FormGroup, Radio, RadioGroup } from 'rsuite';

function RegisterNewMemberForm(props) {
    const [radioState, setRadioState] = useState('individual');
    const imageStyle = {
        height: '10rem',
        width: '10rem',
        margin: 'auto'
    };
    const centerText = {
        textAlign: 'center'
    }

    const handleSubmitClick = () => {
    }
    return (
        <Card style={{ width: '55rem', paddingTop: '1rem' }}>
            <br />
            <Card.Img variant="top" src={seal} alt="Lapels Seal" style={imageStyle} />
            <Card.Body>
                <Card.Title style={centerText}>Apply for a new license</Card.Title>
                <div className="container">

                    <Row className="text-center">
                        <Col>
                            <FormGroup>
                                <RadioGroup name="radioState" value={radioState} onChange={state => { setRadioState(state) }} inline>
                                    <Radio value="individual">Individual</Radio>
                                    <Radio value="firm">Firm</Radio>
                                </RadioGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                   
                    {radioState === 'individual' && <IndividualForm />}
                    {radioState === 'firm' && <FirmForm />}


                    
                    <br />
                    <div className="row justify-content-center">
                        <Button variant="primary" type="submit" onClick={handleSubmitClick} form={radioState + 'Form'}>Register</Button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
export default RegisterNewMemberForm;

