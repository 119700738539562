import axios from "axios";
import authHeader from '../../authheader'

const QUEUEURL = process.env.REACT_APP_APIURL + "/api/WorkQueue";

const getMyPendingTasks = async (model) => {
    return axios.post(`${QUEUEURL}/tasks`, { ...model }, { headers: authHeader(), params: { taskType: 1 } });
};
const getAllTasks = async (model) => {
    return axios.post(`${QUEUEURL}/tasks`, { ...model }, { headers: authHeader(), params: { taskType: 3 } });
};
const getUnassignedTasks = async () => {
    return axios.post(`${QUEUEURL}/tasks`, {}, { headers: authHeader(), params: { taskType: 2 } });
};
const getClosedTasks = async (model) => {
    return axios.post(`${QUEUEURL}/tasks`, {...model}, { headers: authHeader(), params: { taskType: 4 } });
};
const postAssignTask = (taskId, userId) => {
    return axios.post(`${QUEUEURL}/assigntask`, null, { headers: authHeader(), params: { taskId, userId } });
}
const reassignTask = (taskId, userId) => {
    return axios.post(`${QUEUEURL}/reassigntask`, null, { headers: authHeader(), params: { taskId, userId } });
}
const saveInternalTaskStatusAndComments = (parentTypeId, parentId, internalStatusId, comments) => {
    return axios.post(`${QUEUEURL}/saveinternaltaskstatusandcomments`, null, { headers: authHeader(), params: { parentTypeId, parentId, internalStatusId, comments } });
}
const getInternalTaskStatusAndComments = (parentTypeId, parentId) => {
    return axios.get(`${QUEUEURL}/getinternaltaskstatusandcomments`,  { headers: authHeader(), params: { parentTypeId, parentId } });
}
const getTaskId = (parentTypeId, parentId) => {
    return axios.get(`${QUEUEURL}/gettaskid`, { headers: authHeader(), params: { parentTypeId, parentId } });
}
const getWhoAssigned = (taskId) => {
    return axios.get(`${QUEUEURL}/getwhoassignedtask`, { headers: authHeader(), params: { taskId } });
}
const getAssignmentHistory = (taskId) => {
    return axios.get(`${QUEUEURL}/getassignmenthistory`, { headers: authHeader(), params: { taskId } });
}
const getApplicationTypes = () => {
    return axios.get(`${QUEUEURL}/getapplicationtypes`, { headers: authHeader(), params: { } });
}

export default {
    getMyPendingTasks,
    getAllTasks,
    getUnassignedTasks,
    getClosedTasks,
    postAssignTask,
    reassignTask,
    saveInternalTaskStatusAndComments,
    getInternalTaskStatusAndComments,
    getTaskId,
    getWhoAssigned,
    getAssignmentHistory,
    getApplicationTypes
}