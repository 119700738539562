import axios from "axios";
import authHeader from '../../../authheader'

const LSIREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualLsiReinstatement";

const getContactInformation = (reinstatementId) => {
    return axios.get(`${LSIREINSTATEMENTURL}/contactinformation`, { headers: authHeader(), params: { reinstatementId } });
}
const postContactInformation = (reinstatementId, certDisplayName) => {
    return axios.post(`${LSIREINSTATEMENTURL}/contactinformation`, null, { headers: authHeader(), params: { reinstatementId, certDisplayName } });
}
export default {
    getContactInformation,
    postContactInformation
}