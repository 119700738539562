import React, { useState, useEffect } from 'react';
import { Card, Tabs, Tab, Badge } from 'react-bootstrap'
import AdditionalInformationPage from './AdditionalInformation/AdditionalInformationPage';
import CredentialHistoryTable from './CredentialHistoryTable';
import EducationPage from './Education/EducationPage';
import EntityInformationPage from './EntityInformationPage';
import EntityAttachments from './EntityAttachments';
import ExamsPage from './Exams/ExamsPage';
import FeeTransactionPage from './FeeTransactionPage';
import WarningsPage from './Warnings/WarningsPage';

function TopInformationCard(props) {
    const [activeSection, setActiveSection] = useState('entityInfo')
    const [warningNumber, setWarningNumber] = useState(0);
    const warningTitle =
        <>
            <span>WARNING(S)</span>
            {warningNumber > 0 &&
                <Badge style={{ borderRadius: '10em', paddingRight: '.5em', paddingLeft: '.5em' }} variant="danger">{warningNumber}</Badge >
            }
        </>;

    return (
        <Card className="w-100">
            <Card.Body>
                <Tabs activeKey={activeSection} transition={false} onSelect={(selected) => { setActiveSection(selected) }} className='credentialNav'>
                    <Tab eventKey='entityInfo' title='ENTITY INFORMATION'>
                        <br />
                        <EntityInformationPage entityId={props.entityId} activeKey={activeSection} />
                    </Tab>
                    <Tab eventKey='warnings' title={warningTitle}>
                        <br />
                        <WarningsPage entityId={props.entityId} setWarningNumber={setWarningNumber} activeKey={activeSection} />
                    </Tab>
                    {props.isIndividual &&
                        <Tab eventKey='education' title='EDUCATION'>
                            <br />
                            <EducationPage entityId={props.entityId} activeKey={activeSection} />
                        </Tab>
                    }
                    {props.isIndividual &&
                        <Tab eventKey='exams' title='EXAMS'>
                            <br />
                            <ExamsPage entityId={props.entityId} activeKey={activeSection} />
                        </Tab>
                    }
                    <Tab eventKey='feeTransactions' title='FEE TRANSACTIONS'>
                        <br />
                        <FeeTransactionPage isIndividual={props.isIndividual} entityId={props.entityId} activeKey={activeSection} />
                    </Tab>
                    {props.isIndividual &&
                        <Tab eventKey='additionalInformation' title='ADDITIONAL INFORMATION'>
                            <br />
                            <AdditionalInformationPage entityId={props.entityId} activeKey={activeSection} />
                        </Tab>
                    }
                    <Tab eventKey='credHistory' title='CREDENTIAL HISTORY'>
                        <br />
                        <CredentialHistoryTable entityId={props.entityId} activeKey={activeSection} showLicenseNumber={true} />
                    </Tab>
                    <Tab eventKey='attachments' title='LASERFICHE'>
                        <br />
                        <EntityAttachments entityId={props.entityId} activeKey={activeSection}/>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card >
    )
}


export default TopInformationCard;