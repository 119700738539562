import axios from "axios";
import authHeader from '../../../authheader'

const EFRENEWALURL = process.env.REACT_APP_APIURL + "/api/FirmEfRenewal";

const getContactInformation = (renewalId) => {
    return axios.get(`${EFRENEWALURL}/contactinformation`, { headers: authHeader(), params: { renewalId } });
}

export default {
    getContactInformation
}