import React, { useState, useEffect } from 'react';
import queueService from '../../../services/Internal/Queue/queue.service';
import entityService from '../../../services/Internal/Staff/entity.service';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

function ReassignButton({ taskId, refreshInternalStatus}) {
    const [employeeList, setEmployeeList] = useState({});
    const refreshEmployeeList = () => {
        entityService.getStaffAndAdminReassignList(taskId).then((response) => {
            var staffList = {};
            response.data.map((item, i) => {
                staffList[`${item.id}`] = `${item.firstName} ${item.lastName}`;
            })
            setEmployeeList(staffList);
        });
    };
    useEffect(() => {
        refreshEmployeeList();
    }, [])

    const handleAssignClick = () => {
        Swal.fire({
            title: 'Assign To',
            input: 'select',
            inputOptions: { ...employeeList },
            inputPlaceholder: 'Select a Reviewer',
            showCancelButton: true,
            confirmButtonText: 'Save',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                return value === "" && 'Select a Reviewer!'
            }
        }).then((result) => {
            if (result.isConfirmed && result.value !== '') {
                queueService.reassignTask(taskId, result.value).then((response) => {
                    if (response.status == 200) {
                        Swal.fire("Successfully reassigned");
                        refreshEmployeeList();
                        if (refreshInternalStatus)
                        {
                            refreshInternalStatus();
                        }
                    }
                })
            }
            else {
                if (!result.value && result.isConfirmed) {
                    Swal.fire("Selected dropdown list item's value is empty")
                }
            }
        })
    }

    return (
        <>
            <Button variant="dark" onClick={handleAssignClick}>Reassign</Button>
        </>
    )
}
export default ReassignButton;