import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Form, Schema } from 'rsuite'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import Information from '../../../../../Shared/Card/Information';
import {  GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import reinstatementService from '../../../../../../services/Public/Reinstatement/PE/peReinstatement.service';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../../Shared/Card/Issues';
import DocumentUpload from '../../../../../Shared/Upload/DocumentUpload';

function QuizzesPage() {
    const appId = useSelector(x => x.currentApplicationId);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);
    const sectionId = 4;
    const routes = useSelector(x => x.currentRoutes);
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        reinstatementService.peGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data)
            setHasLoaded(true);
        })
    }, [])

    const { push } = useHistory();

    const handleSave = () => {
        Swal.fire("Saved!")
    }

    const handleSubmit = (val, event) => {
        if (!sectionData.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        // e is the result of the validation
        if (hasFiles) {
            // do form post
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
    }
    //const attachmentTypes = [
    //    {
    //        "label": "Quiz - Laws and Rules", "value": 15
    //    },
    //    {
    //        "label": "Quiz - Ethics", "value": 16
    //    }
        
    //]
    return (
        <div>
            <Container fluid>
                <b>Professional Engineer Reinstatement</b>
                <h4 className="mb-3">Quizzes</h4>
                <Information content={GetReinstatementInformationContent("quiz")} />
                <br />
                {hasLoaded ?
                    <Form onSubmit={handleSubmit} fluid> {/*This is to make sure validation is triggered and form does nothing else*/}
                        <Card>
                            <Card.Header>
                                <h6>Document Uploads</h6>
                            </Card.Header>
                            <Card.Body>
                                <DocumentUpload
                                    isUserEditable={sectionData.isUserEditable}
                                    credentialTypeId={6}
                                    sectionId={sectionId}
                                    //appTypeId={appTypeId}
                                    //refreshData={ }
                                    parentId={sectionData.id}
                                    parentTypeId={2}
                                    setHasFiles={setHasFiles}
                                />
                                {!hasFiles && <div className="text-danger">Please select at least 1 file.</div>}
                            </Card.Body>
                        </Card>
                        <br />
                        <Issues isUserEditable={true} type={2} sectionId={sectionData.id}/>
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} handleSave={handleSave} backToSectionLink="/individual/peReinstatement/sections" />
                    </Form> : <LoadingSpinner />
                }

            </Container>
        </div>
    )
}
export default QuizzesPage;