import { useState, useEffect } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import React from 'react'
import { ControlLabel, Schema, Form, FormControl, FormGroup, AutoComplete } from 'rsuite'
import YesNoRadioOption from '../../../Shared/RadioButton/YesNoRadioOption';
import { getIndividualCredentials } from '../../../../services/Shared/codeTable.service'
import RedStar from '../../../Shared/GenericComponents/RedStar';
import supervisingProfessionalService from '../../../../services/supervisingProfessional.service';
import Swal from 'sweetalert2';

function AddNewSupervisingProfessionalModal(props) {
    const [individualCreds, setIndividualCreds] = useState();
    const updateIndividualCreds = (value) => {
        getIndividualCredentials(value).then(setIndividualCreds);
    }
    const [supervisingProfessionalModel, setSupervisingProfessionalModel] = useState({
        individualCredential: "",
        ownerOfFirm: ""
    })

    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        individualCredential: StringType().isRequired('This field is required.').pattern(/^.*\.[0-9]{7}.*$/, "Please select a license number."),
        ownerOfFirm: BooleanType().isRequired('This field is required.'),
    })
    const formSubmit = (val, event) => {
        if (val) {
            var licenseNum = supervisingProfessionalModel.individualCredential ? supervisingProfessionalModel.individualCredential.split("(")[1].split(")")[0] : null;
            supervisingProfessionalService.postFirmSupervisingProfessional(licenseNum, supervisingProfessionalModel.ownerOfFirm).then((response) => {
                if (response.data.supervisingProfessionalId) {
                    props.updateGrid();
                    props.onHide();
                    setSupervisingProfessionalModel({
                        individualCredential: "",
                        ownerOfFirm: ""
                    });
                    Swal.fire("Saved", `An email notification was sent to ${response.data.professionalEmailAddress} to sign into their LOLA account, go to the \"SP Locker\", and accept the role of SP. Once the role has been accepted, you can complete the firm application, renewal, or reinstatement`, "success");
                }
            }).catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } });
        }
    }

    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Supervising Professionals
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    model={model}
                    id="supervisingProfessionalForm"
                    formValue={supervisingProfessionalModel}
                    onChange={setSupervisingProfessionalModel}
                    onSubmit={formSubmit}
                    fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        License #&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    accepter={AutoComplete}
                                    block
                                    data={individualCreds}
                                    name="individualCredential"
                                    placeholder="License # | First Last Name"
                                    onChange={updateIndividualCreds}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Owner of this firm?&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <YesNoRadioOption radioName="ownerOfFirm" />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
                <Button type="submit" form="supervisingProfessionalForm" >Add New</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddNewSupervisingProfessionalModal;