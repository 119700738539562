import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import SupervisesTable from './SupervisesTable';

function EditSupervisesModal(props) {

    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4>Supervises Information</h4>
            </Modal.Header>
            <Modal.Body>
                <SupervisesTable editMode={true} entityid={props.entityid} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default EditSupervisesModal;