import React from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
function License({ data }) {
    const needsRenewal = data.renew;
    const needsReinstatement = data.reinstate;
    let superProMarkup = data.supervisingProfessionals ? data.supervisingProfessionals.map((item, i) => {
        return (
            <React.Fragment key={ "supervisingProf"+i}>
                <Row className="mb-3">
                    <Col lg={4} md={5}><strong>Supervising Professional</strong></Col>
                    <Col lg={8} md={7}>{item.name} | {item.credentialNumber}</Col>
                </Row>
            </React.Fragment>
        )
    }) : "";
    return (
        <div className="profileLicense">
            <Card>
                <Card.Header>
                    <Card.Title>
                        <b>{data.certificationNumber}</b>
                        <span style={{ float: 'right' }}>
                            {needsRenewal &&
                                <Button as={Link} to="/firm/renewals" variant="success" className="text-uppercase">Renew</Button>
                            }
                            {needsReinstatement &&
                                <Button as={Link} to="/firm/reinstatements" variant="success" className="text-uppercase">Reinstate</Button>
                            }
                        </span>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={4} md={5}><strong>License Status</strong></Col>
                            <Col lg={8} md={7}>{data.licenseStatus}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={4} md={5}><strong>First Issuance Date</strong></Col>
                            <Col lg={8} md={7}>{data.firstIssuanceDate ? new Date(data.firstIssuanceDate).toLocaleDateString() : null}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={4} md={5}><strong>Expiration Date</strong></Col>
                            <Col lg={8} md={7}>{data.expirationDate ? new Date(data.expirationDate).toLocaleDateString() : null}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={4} md={5}><strong>Reinstatement Date</strong></Col>
                            <Col lg={8} md={7}>{data.reinstatementDate ? new Date(data.reinstatementDate).toLocaleDateString() : null}</Col>
                        </Row>
                            {superProMarkup}
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default License;