import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { FormGroup, FormControl, ControlLabel } from 'rsuite';
import NumberFormat from 'react-number-format';
import * as FaIcons from 'react-icons/fa';
import RedStar from '../GenericComponents/RedStar';

function DisclosureAuthorization({isInternal}) {
    return (
        <div className="plsReinstatementDisclosureAuthorization">
            <Card>
                <Card.Header><h6>Authorization</h6></Card.Header>
                <Card.Body>
                    <p>During the application process and at any time during the tenure of my licensure with LAPELS, I hereby authorize First Advantage,
                    on behalf of LAPELS, to procure a consumer report which I understand may include information regarding my credit worthiness, credit standing, credit capacity,
                    character, general reputation, personal characteristics, or mode of living. This report may be compiled with information from credit bureaus, court record repositories,
                    departments of motor vehicles, past or present employers and educational institutions, governmental occupational licensing or registration entities, business or personal references,
                    and any other source required to verify information that I have voluntarily supplied. I understand that I may request a complete and accurate disclosure of the nature and
                    scope of the background verification and a written summary of consumer rights; to the extent such investigation includes information bearing on my character, general reputation, personal characteristics or mode of living.</p>
                    <hr />
                    <FormGroup>
                        <Row>
                            <Col sm="5">
                                <ControlLabel>
                                    <h5>
                                        Electronic Signature of Applicant:<RedStar />
                                    </h5>
                                    (type your name)
                                </ControlLabel>
                            </Col>
                            <Col sm="7">
                                <FormControl name="electronicSignature" placeholder="" aria-label="" />
                            </Col>
                        </Row>
                    </FormGroup>
                    {isInternal &&
                        <>
                            <Row><Col><strong className="text-danger">*Fields below will appear on printouts generated by internal LAPELS staff</strong></Col></Row>
                            <br />
                            <FormGroup>
                                <Row>
                                    <Col sm="5">
                                        <ControlLabel>
                                            <strong>
                                                Date
                                    </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col sm="7">
                                        <FormControl name="dateSigned" type="date" id="dateSigned" /*value={personalInformationModel.dateOfBirth} onChange={handleDateOfBirthChange}*/ block="true"></FormControl>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col sm="5">
                                        <ControlLabel>
                                            <strong>
                                                Date of Birth
                                    </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col sm="7">
                                    <FormControl type="date" name="dateOfBirth" id="dateOfBirth" /*value={personalInformationModel.dateOfBirth} onChange={handleDateOfBirthChange}*/ block="true"></FormControl>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup controlId="formSsnDisplay">
                                <Row>
                                    <Col sm="5">
                                        <ControlLabel>
                                            <strong>Social Security Number</strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col sm="7">
                                        <NumberFormat placeholder="###-##-####" format="###-##-####" mask="_" className="form-control" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div className="float-right"><Button><FaIcons.FaFilePdf />Save to PDF(FOR LAPELS STAFF ONLY)</Button></div>
                        </>}
                </Card.Body>
            </Card>
        </div>
    )
}

export default DisclosureAuthorization;