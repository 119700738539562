import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ControlLabel, Form, FormControl, FormGroup, Icon, InputPicker, SelectPicker, Table } from 'rsuite';
import paymentService from '../../../../services/Internal/Revenue/payment.service';
import entityService from '../../../../services/Internal/Staff/entity.service';
import { getContacts } from '../../../../services/Shared/codeTable.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../../Shared/Grid/GenericPagination';
import { sort_by } from '../../../Shared/HelperFunctions';

function InvoiceSearchPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [contacts, setContacts] = useState();
    const [employeeList, setEmployeeList] = useState([]);
    const [searchModel, setSearchModel] = useState({
        beginDate: '',
        endDate: '',
        invoiceId: '',
        user: '',
        contact: ''
    })
    const { push } = useHistory();
    const [sortColumn, setSortColumn] = useState("dateCrte");
    const [sortType, setSortType] = useState("desc");
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const getData = () => {
        var sortedData = searchResults.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }
    useEffect(() => {
        entityService.plsGetStaffAndAdmin(false).then((response) => {
            var staffList = response.data.map(
                employee => {
                    return { "label": `${employee.formattedName}`, "value": `${employee.formattedName}` }
                }).filter((item, index, self) =>
                    index === self.findIndex((i) => (
                        i.value === item.value
                    ))
                );
            setEmployeeList(staffList);
        });
        setHasLoaded(true);
    }, [])

    const updateContacts = (value) => {
        if (value.length > 2) {
            getContacts(value).then((response) => {
                setContactLoading(true);
                setContacts(response);
                setContactLoading(false);
            });
        }
        else {
            setContacts([]);
        }
    }

    const clearSearchModel = () => {
        setSearchModel({
            beginDate: '',
            endDate: '',
            invoiceId: '',
            user: '',
            contact: ''
        })
    }
    const submitSearch = () => {
        var dataToTransmit = {
            beginDate: searchModel.beginDate ? searchModel.beginDate : null,
            endDate: searchModel.endDate ? searchModel.endDate : null,
            invoiceId: searchModel.invoiceId > 0 ? searchModel.invoiceId : null,
            user: searchModel.user ? searchModel.user : null,
            entityId: searchModel.contact?.entityId ?? null
        };
        setHasLoaded(false);
        setIsSearching(true);
        paymentService.searchInvoice(dataToTransmit).then((response) => {
            setSearchResults(response.data);
            setHasLoaded(true);
            setIsSearching(false);
        });
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5>
                        Invoice Search
                    </h5>
                </Card.Header>
                <Card.Body>
                    <BlockUi blocking={isSearching}>
                        <Form fluid formValue={searchModel} onChange={setSearchModel} onSubmit={submitSearch} >
                            <Row className="mb-3">
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Date Range</b></ControlLabel>
                                        <FormControl
                                            name="beginDate"
                                            type="date"
                                            block="true"
                                        />
                                    </FormGroup>
                                </Col>
                                <span style={{ marginTop: '2rem' }}>to</span>
                                <Col>
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <FormControl
                                        name="endDate"
                                        type="date"
                                        block="true"
                                    />
                                </Col>

                                <Col>
                                    <ControlLabel><b>Invoice ID</b></ControlLabel>
                                    <FormControl
                                        type="number"
                                        name="invoiceId"
                                        placeholder="Enter Invoice ID"
                                    />
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>User</b></ControlLabel>
                                        <FormControl
                                            name="user"
                                            placeholder="Select User"
                                            block="true"
                                            data={employeeList}
                                            accepter={SelectPicker}
                                            searchable={false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel><b>Contact</b></ControlLabel>
                                        <FormControl
                                            accepter={InputPicker}
                                            block="true"
                                            data={contacts}
                                            name="contact"
                                            labelKey="name"
                                            placeholder="Find a Contact (Min 3 characters)"
                                            onSearch={updateContacts}
                                            renderMenu={menu => {
                                                if (contactLoading) {
                                                    return (
                                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                            <Icon icon="spinner" spin /> Loading...
                                                        </p>
                                                    );
                                                }
                                                return menu;
                                            }}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <>
                                                        {`${item.value.formattedName} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""} | ${item.value.lolanumber}`}
                                                    </>
                                                );
                                            }}
                                            renderValue={(value, item, selectedElement) => {
                                                return (
                                                    <>
                                                        {item ? `${item.value.formattedName} | ${item.value.lolanumber} ${item.value.formattedLicenseNumber ? "| " + item.value.formattedLicenseNumber : ""}` : 'Find a Contact (Min 3 characters)'}
                                                    </>
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={clearSearchModel} variant="danger" className="mr-2">Reset</Button>
                                <Button type="submit">Search</Button>
                            </div>
                        </Form>
                    </BlockUi>
                </Card.Body>
            </Card>

            <br />
            <Card>
                <Card.Header><h5>Search Results</h5></Card.Header>
                <Card.Body>
                    {hasLoaded ?
                        <>
                            <Table wordWrap autoHeight data={filteredData}
                                onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                                sortColumn={sortColumn}
                                sortType={sortType}
                            >
                                <Table.Column width={100}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => {
                                            function viewInvoice() {
                                                push("/internal/revenue/invoice?invoiceId=" + rowData.id);
                                            }
                                            return (
                                                <Button onClick={viewInvoice} variant="primary">View</Button>
                                            )
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Invoice Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="dateCrte">{rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Invoice ID</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">{rowData => (rowData.id)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={2} sortable>
                                    <Table.HeaderCell>
                                        <b>Contact</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="formattedName">{rowData => (`${rowData.formattedName}`)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>User</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="userName">{rowData => (rowData.userName)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Amount Due</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="amount">{rowData => (rowData.amount ? rowData.amount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) : rowData.amount == 0 ? '$0.00' : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1} sortable>
                                    <Table.HeaderCell>
                                        <b>Amount Paid</b>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="amountPaid">{rowData => (rowData.amountPaid ? rowData.amountPaid.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) : rowData.amountPaid == 0 ? '$0.00' : '')}</Table.Cell>
                                </Table.Column>
                            </Table>
                            <GenericPagination dataLength={searchResults.length} setPage={setPage} setDisplayLength={setDisplayLength} />
                        </> : <LoadingSpinner />
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default InvoiceSearchPage;