import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import Section from '../../../../../Shared/Sections/Section';
import { getSections } from '../../../../../../services/Public/Reinstatement/PE/peReinstatement.service';
import { useSelector } from 'react-redux';
import Information from '../../../../../Shared/Card/Information';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import issuesService from '../../../../../../services/Shared/issues.service';

function Index() {
    const [sections, setSections] = useState(null);
    const selector = useSelector(x => x);
    useEffect(() => {
        getSections().then((response) => {
            issuesService.getIssuesForSections(3, selector.currentApplicationId).then((data) => {
                setSections(selector.currentRoutes != null ? selector.currentRoutes.map((value, i) => {
                    var section = {
                        ...response.filter(x => x.id == value.sectionId)[0]
                    };
                    if (section?.id) {
                        section.link = value.route;
                        var issues = data.data.filter(x => x.sectionId == section.id);
                        if (issues.length) {
                            section.status = `${issues[0].openIssueCount} Issue${issues[0].openIssueCount > 1 ? 's' : ''}`;
                        }
                        return <Section data={section} key={i} />
                    }
                    return;
                }) : []);
            });
        })
    }, [])

    return (
        <div className="peReinstatementIndexPage">
            <Container fluid>
                <b>Professional Engineer Reinstatement</b>
                <h4 className="mb-3">Reinstatement</h4>
                <Row>
                    <Col><Information content={GetReinstatementInformationContent('sections')} /></Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <h4>Sections to Complete</h4>
                        <ListGroup>
                            {sections}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Index;