import React from 'react'
import { Button, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ForgotUserIdSuccess() {
    return (
        <div className="forgotUserIdSuccessPage">
            <Card className='forgotUserIdSuccessCard'>
                <Card.Body>
                    <Card.Title className='centerText'>User Id Recovery Email Sent</Card.Title>
                    <Row className="mb-3 px-3"><p>You will receive a User Id recovery email if your account information was correct. If you don't get anything within 24 hrs, contact account recovery.</p></Row>
                    <Row className="px-3 forgotUserIdSuccessButtonRow"><Button name="backToLogin" as={Link} to="/login" variant="primary">Return to Login</Button></Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ForgotUserIdSuccess;