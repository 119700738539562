import * as dayjs from 'dayjs';
import React from 'react'
import { useState } from 'react'
import { Button, Card, Row, Col, } from 'react-bootstrap'
import { Icon, Table } from 'rsuite'
import Swal from 'sweetalert2';
import attachmentService from '../../../../services/Shared/attachment.service';
import cpdReqService from '../../../../services/Shared/cpdReq.service';
import GenericPagination from '../../../Shared/Grid/GenericPagination';

function Courses(props) {
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const getData = () => {

        return props.coursesModel.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();
    const formDataAppender = (passedKey, model, formData) => {
        for (let key in model) {
            if (typeof (model[key]) === 'object') {
                formDataAppender(`${passedKey}.${key}`, model[key], formData); // Recursive key for objects
            }
            else {
                if (passedKey)
                    formData.append(`${passedKey}.${key}`, model[key]);
                else
                    formData.append(`${key}`, model[key]);
            }
        }
    }

    const removeFile = (item, rowData) => {
        Swal.fire({
            title: 'Are you sure you want to delete this file?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                attachmentService.deleteFile(item.id).then((response) => {
                    if (response.status == 200) {
                        var index = props.coursesModel.findIndex(x => x.id == rowData.id);
                        var tempModel = props.coursesModel;
                        tempModel[index].files = tempModel[index].files.filter(x => x.id !== item.id);
                        props.setCoursesModel([...tempModel]);
                    }
                });
            }
        })
    }

    const openFile = (item) => {
        attachmentService.openFile(item.id);
    }

    const deleteRecord = (courseModel) => {
        var formData = new FormData();
        var courseRecord = {
            area: courseModel.area,
            classType: courseModel.classType,
            courseDate: (new Date(courseModel.date)).toISOString(),
            courseTitle: courseModel.title,
            description: courseModel.description,
            hours: courseModel.hours,
            entityId: props.entityId,
            id: courseModel.id,
            instructor: courseModel.instructor,
            dateCrte: courseModel.dateCrte,
            userCrte: courseModel.userCrte,
            deleted: true
        }
        formDataAppender('courseRecord', courseRecord, formData);
        for (var i = 0; i < courseModel.files.length; i++) {
            formData.append('files', courseModel.files[i].blobFile);
        }

        cpdReqService.postUpdateCpd(formData).then((response) => {
            if (response.status == 200) {
                props.setCoursesModel([...props.coursesModel.filter(x => x.id !== courseModel.id)]);
            }
        });
    }

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <h6>Individual Course Records</h6>
                    </Col>
                    <Col>
                        <Button className={"float-right"} onClick={props.openModal}>
                            <Icon icon="plus" /> Add New</Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table headerHeight={60} data={filteredData} wordWrap autoHeight>
                    <Table.Column flexGrow={1} minwidth={100} verticalAlign="middle">
                        <Table.HeaderCell><b>Document</b></Table.HeaderCell>
                        <Table.Cell>
                            {rowData => {
                                return (<>
                                    {rowData.files.map((item, i) => {
                                        return (
                                            <Row key={i} style={{ padding: '0' }}>
                                                <Button onClick={() => { removeFile(item, rowData) }} title={`Delete ${item.fileName}`} variant="link" style={{color:"red", textDecoration:'none'}} size="sm"><b>X</b></Button>
                                                <Button onClick={() => { openFile(item) }} variant="link" size="sm">{item.fileName}</Button>
                                            </Row>
                                        )
                                    })}
                                </>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={0.5} verticalAlign="middle" >
                        <Table.HeaderCell><b>Date</b></Table.HeaderCell>
                        <Table.Cell>
                            {
                                rowData => {
                                    return (<span>{dayjs(rowData.date).format("MM/DD/YYYY")}</span>)
                                }
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={0.8} verticalAlign="middle">
                        <Table.HeaderCell><b>Course Title</b></Table.HeaderCell>
                        <Table.Cell dataKey="title" />
                    </Table.Column>
                    <Table.Column flexGrow={0.8} verticalAlign="middle">
                        <Table.HeaderCell><b>Area</b></Table.HeaderCell>
                        <Table.Cell>
                            {
                                rowData => {
                                    return (<span>{rowData.area.area}</span>)
                                }
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={0.5} verticalAlign="middle">
                        <Table.HeaderCell><b>Hours</b></Table.HeaderCell>
                        <Table.Cell dataKey="hours" />
                    </Table.Column>
                    <Table.Column width={100} verticalAlign="middle">
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.Cell dataKey="id">
                            {rowData => {
                                function handleAction() {
                                    var tempData = { ...rowData, modalType: 'Edit' }
                                    props.openModal(tempData);
                                }
                                function handleDelete() {
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, delete it!'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            deleteRecord(rowData);
                                        }
                                    })
                                }
                                return (
                                    <span>
                                        <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                        <Icon icon="trash" size="2x" onClick={handleDelete} style={{ cursor: "pointer" }} />
                                    </span>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
                <GenericPagination dataLength={props.coursesModel.length} setPage={setPage} setDisplayLength={setDisplayLength} />
            </Card.Body>
        </Card>
    )
}
export default Courses

