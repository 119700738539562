import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Table } from 'rsuite';
import Swal from 'sweetalert2';
import queueService from '../../../services/Internal/Queue/queue.service';
import entityService from '../../../services/Internal/Staff/entity.service';
import GenericBadge from '../../Shared/Badge/GenericBadge';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import GenericPagination from '../../Shared/Grid/GenericPagination';
import { sort_by } from './../../Shared/HelperFunctions'

function UnassignedTasksGrid({ propsIsActive, viewItemCallback }) {
    const [gridData, setGridData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasGridLoad, setHasGridLoad] = useState(false);
    const [sortColumn, setSortColumn] = useState("dateSubmitted");
    const [sortType, setSortType] = useState("asc");
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);
    const [employeeList, setEmployeeList] = useState({});

    useEffect(() => {

        if (propsIsActive) {
            setHasLoaded(false);
            refreshGrid();
            setHasLoaded(true);
            setHasGridLoad(true);
            entityService.plsGetStaffAndAdmin(true).then((response) => {
                var staffList = {};
                response.data.map((item, i) => {
                    staffList[`${item.id}`] = `${item.firstName} ${item.lastName}`;
                })
                setEmployeeList(staffList);
            });
        }
        //the reason that this is in a set timeout is because without this databind delay, the column width calculations with flexgrow would be wrong
        //We will need to test with a C# controller call and see if the get request delay that happens will be enough to have the columns recalculate their widths
    }, [propsIsActive])

    const refreshGrid = () => {
        queueService.getUnassignedTasks().then((response) => {
            setGridData(response.data);
        });
    }

    const getData = () => {

        var sortedData = gridData.sort(sort_by(sortColumn, sortType !== "asc"));
        return sortedData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }

    const handleSortColumn = (sortColumn, sortType) => {

        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const filteredData = getData();
    return (
        <>
            {
                hasLoaded ?
                    <>
                        <GenericPagination page={page} dataLength={gridData.length} displayLength={displayLength} setDisplayLength={setDisplayLength} setPage={setPage} discludeAllLength={false} />
                        <Table
                            loading={!hasGridLoad}
                            onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            wordWrap autoHeight data={filteredData}>
                            <Table.Column width={200} fixed>
                                <Table.HeaderCell />
                                <Table.Cell dataKey="id">
                                    {rowData => {
                                        function handleViewClick() {
                                            viewItemCallback(rowData);
                                        }
                                        function handleAssignClick() {
                                            Swal.fire({
                                                title: 'Assign To',
                                                input: 'select',
                                                inputOptions: { ...employeeList },
                                                inputPlaceholder: 'Select a Reviewer',
                                                showCancelButton: true,
                                                confirmButtonText: 'Save',
                                                showLoaderOnConfirm: true,
                                                allowOutsideClick: () => !Swal.isLoading(),
                                                inputValidator: (value) => {
                                                    return value === "" && 'Select a Reviewer!'
                                                }
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value !== '') {
                                                    queueService.postAssignTask(rowData.taskId, result.value).then((response) => {
                                                        if (response.status == 200) {
                                                            if (typeof(response.data) == "string" && response.data.startsWith("This task is already assigned to")) {
                                                                Swal.fire({
                                                                    title: 'Reassign?',
                                                                    text: response.data,
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Reassign',
                                                                    showLoaderOnConfirm: true,
                                                                    allowOutsideClick: () => !Swal.isLoading(),
                                                                }).then((reassignResult) => {
                                                                    if (reassignResult.isConfirmed) {
                                                                        queueService.reassignTask(rowData.taskId, result.value).then((response) => {
                                                                            if (response.status == 200) {
                                                                                Swal.fire({ title: "Successfully reassigned" });
                                                                            }
                                                                        })
                                                                    }
                                                                    setHasGridLoad(false);
                                                                    refreshGrid();
                                                                    setHasGridLoad(true);
                                                                })
                                                            }
                                                            else {
                                                                setHasGridLoad(false);
                                                                refreshGrid();
                                                                Swal.fire({ title: "Successfully assigned", didClose: () => { setHasGridLoad(true); } });
                                                            }
                                                        }
                                                    })
                                                }
                                                else {
                                                    if (!result.value && result.isConfirmed) {
                                                        Swal.fire("Selected dropdown list item's value is empty")
                                                    }
                                                }
                                            })
                                        }
                                        return (
                                            <>
                                                <Button className="mr-1" onClick={handleViewClick}>View</Button>
                                                <Button variant="dark" onClick={handleAssignClick}>Assign</Button>
                                            </>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Applicant</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="applicant" />
                            </Table.Column>
                            <Table.Column minWidth={150} flexGrow={1.7} sortable>
                                <Table.HeaderCell>
                                    <b>Application Type</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="taskType">{rowData => (`${rowData.parentType} - ${rowData.taskType}`)}</Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={125} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Date Submitted</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="dateSubmitted">{rowData => (new Date(rowData.dateSubmitted).toLocaleDateString())}</Table.Cell>
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Assigned To</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="assignedTo" />
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Internal Status</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="internalStatus" />
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={1} sortable>
                                <Table.HeaderCell>
                                    <b>Comment(s)</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="comments" />
                            </Table.Column>
                            <Table.Column minWidth={100} flexGrow={.5} sortable>
                                <Table.HeaderCell>
                                    <b>Status</b>
                                </Table.HeaderCell>
                                <Table.Cell dataKey="parentStatus" />
                            </Table.Column>
                        </Table>
                        <GenericPagination page={page} dataLength={gridData.length} displayLength={displayLength} setDisplayLength={setDisplayLength} setPage={setPage} discludeAllLength={false} />
                    </>
                    : <LoadingSpinner />
            }
        </>
    );
}

export default UnassignedTasksGrid;