import React, { useState, useEffect } from 'react';
import { Card, Button, Tabs, Tab } from 'react-bootstrap'
import { FaEdit, FaIdCardAlt } from 'react-icons/fa';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import CredentialHistoryTable from '../Shared/CredentialHistoryTable';
import LegacyCredentialForm from '../Shared/Credential/LegacyCredentialForm';
import EditCredentialModal from './Credential/EditCredentialModal';
import EiCredentialForm from './Credential/EiCredentialForm';
import LsiCredentialForm from './Credential/LsiCredentialForm';
import PeCredentialForm from './Credential/PeCredentialForm';
import PlsCredentialForm from './Credential/PlsCrendentialForm';

function CredentialCard(props) {
    const [data, setData] = useState({})
    const [selectedTab, setSelectedTab] = useState(0);
    const [modalData, setModalData] = useState();
    const [modalShow, setModalShow] = useState();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasPending, setHasPending] = useState(false);
    useEffect(() => {
        updateCardData();
    }, [])

    const updateCardData = () => {
        setHasLoaded(false);
        credentialService.checkHasPending(props.entityId).then((result) => {
            setHasPending(result.data);
            credentialService.getIndividualCredentialInfo(props.entityId).then((response) => {
                if (response.data.length > 0) {
                    setData(response.data);
                    if (selectedTab == 0 && !result.data)
                        setSelectedTab(response.data[0].id);
                }
                setHasLoaded(true);
            })
        })
    }

    const onEditCard = () => {
        setModalData(data.find(x => x.id == selectedTab));
        setModalShow(true);
    }
    return (
        <Card className="w-100 h-100">
            <Card.Header className="text-center" style={{ background: 'white' }}>
                <a href={"internal/credentialChangeLog?entityId=" + props.entityId} target="_blank" className="changeLog">
                    <span className="fakeH6">CREDENTIAL
                    </span>
                    <br />
                    <FaIdCardAlt fontSize='42' />
                </a>
            </Card.Header>
            <Card.Body>
                {hasLoaded && data.length > 0 ?
                    <>
                        <EditCredentialModal
                            show={modalShow}
                            onHide={() => { setModalShow(false) }}
                            credential={modalData}
                            updateCardData={updateCardData}
                            updateSupervisesCardCallback={props.updateSupervisesCardCallback}
                            updateCommentCardCallback={props.updateCommentCardCallback}
                        />
                        <Tabs transition={false} activeKey={selectedTab} onSelect={setSelectedTab} className='justify-content-center'>
                            {hasPending &&
                                <Tab eventKey={0} title={"Pending"}>
                                    <br />
                                    <br />
                                    <center><h4>Application History</h4></center> <br /><CredentialHistoryTable entityId={props.entityId} activeKey={"noCreds"} pendingOnly={true} />
                                </Tab>
                            }
                            {
                                data.map((item, index) => {
                                    return (<Tab eventKey={item.id} title={item.licenseTypePrefix}>
                                        <br />
                                        <br />
                                        {
                                            (item?.licenseTypePrefix == "PLS" || item?.licenseTypePrefix == "PLS-L") &&
                                            <>
                                                <PlsCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            (item?.licenseTypePrefix == "PE" || item?.licenseTypePrefix == "PE-L") &&
                                            <>
                                                <PeCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} /></>

                                        }{
                                            item?.licenseTypePrefix == "LSI" &&
                                            <>
                                                <LsiCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            item?.licenseTypePrefix == "EI" &&
                                            <>
                                                <EiCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            item?.licenseTypePrefix == "TP" &&
                                            <>
                                                <LegacyCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                        {
                                            item?.licenseTypePrefix == "CPD" &&
                                            <>
                                                <LegacyCredentialForm formId={"form" + item.id} editMode={false} credential={item} />
                                                <br />
                                                <br />
                                                <h4>{item?.licenseTypePrefix} History</h4>
                                                <CredentialHistoryTable credential={item} activeCredentialId={selectedTab} />
                                            </>
                                        }
                                    </Tab>)
                                })

                            }
                        </Tabs>

                    </> : hasLoaded ? <><center><h4>Application History</h4></center> <br /><CredentialHistoryTable entityId={props.entityId} activeKey={"noCreds"} /></> : <LoadingSpinner />
                }
            </Card.Body>
            <Card.Footer className='text-right'>
                <Button onClick={() => onEditCard()} variant='light'><FaEdit /></Button>
            </Card.Footer>
        </Card >
    )
}


export default CredentialCard;