import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Icon, Table } from 'rsuite';
import Swal from 'sweetalert2';
import shoppingCartService from '../../../services/Public/shoppingCart.service';

function ShoppingCart(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        setGridData(props.gridData)
        setHasLoaded(true);
    }, [props.gridData])



    return (
        <div className="shoppingCart">
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>Cart Total</h6>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {hasLoaded &&
                        <>
                            <Table wordWrap autoHeight data={gridData}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>License</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => { return (rowData.id > 0 ? (rowData.feeDefinition.feeDefinitionDescription) : <b>{rowData.feeDefinition.credentialType}</b>) }}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Price</b>
                                    </Table.HeaderCell>
                                <Table.Cell>{rowData => {
                                    return (rowData.id > 0 ? (rowData.feeDefinition.feeAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })) : <b>{rowData.feeDefinition.feeAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}</b>) }}</Table.Cell>
                                </Table.Column>
                                <Table.Column width={100}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell dataKey="id">
                                        {rowData => {
                                            if (rowData.id > 0 && rowData.feeDefinition.generalLedgerDescription != "Delinquent Fees") {
                                                function handleDelete() {
                                                    Swal.fire({
                                                        title: 'Are you sure?',
                                                        text: "You won't be able to revert this!",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: 'Yes, delete it!'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            //shopping cart service to delete
                                                            shoppingCartService.deleteItemFromShoppingCart(rowData.id, rowData.feeDefinition.id, rowData.parentId).then((response) => {
                                                                props.refreshGrid();
                                                            });
                                                        }
                                                    })
                                                }
                                                return (
                                                    <span>
                                                        <Icon icon="trash" size="2x" onClick={handleDelete} style={{ cursor: "pointer" }} />
                                                    </span>
                                                );
                                            }
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                            </Table>
                            {gridData.length > 0 &&
                                <>
                                    <h5>Payment Method</h5>
                                    <br />
                                    <Button onClick={props.submitElectronicPayment}>By Credit Card / ACH Check</Button>&nbsp;&nbsp;
                                    <Button variant="outline-primary" onClick={props.submitByMail}>By Mail-in Check</Button>
                                </>
                            }
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}



export default ShoppingCart;