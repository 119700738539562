import axios from "axios";
import jwt from 'jwt-decode'

//this is example of auth header
import authHeader from './authheader.js'

const getIndividualDashboard = () => {
    return axios.get(process.env.REACT_APP_APIURL + "/api/Individual/dashboard", { headers: authHeader() });
}
//end example

const REGISTERAPIURL = process.env.REACT_APP_APIURL + "/api/Register";
const API_AUTH_URL = process.env.REACT_APP_APIURL + "/api/Auth";

const registerIndividual = (data) => {
    return axios.post(`${REGISTERAPIURL}/individual`, data);

};
const forgotPassword = (username) => {
    return axios.post(`${API_AUTH_URL}/forgotpassword`, null, { params: { username } })
        .then((response) => {
            return response;
        });
}
const forgotIndividualUserId = (email, dateOfBirth) => {
    return axios.post(`${API_AUTH_URL}/forgotindividualuserid`, null, { params: { email, dateOfBirth } })
        .then((response) => {
            return response;
        });
};
const forgotFirmUserId = (email, credential) => {
    return axios.post(`${API_AUTH_URL}/forgotfirmuserid`, null, { params: { email, credential } })
        .then((response) => {
            return response;
        });
};
const registerFirm = (data) => {
    return axios.post(`${REGISTERAPIURL}/firm`, data)
};
const login = (username, password) => {
    return axios.post(`${API_AUTH_URL}/login`, { username, password })
        .then((response) => {
            if (response.data.message) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const resendEmailVerification = (username) => {
    return axios.post(`${API_AUTH_URL}/resendemailverification`, null, { params: { username } })
        .then((response) => {
            return response;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    //check if expired
    //if so, call logout and return null
    if (user) {
        const expire = new Date(user.expireDate);
        if (expire < new Date()) {
            logout();
            return null;
        }
    }
    return user;
};

const getRoles = () => {
    var user = getCurrentUser();
    if (user == null) {
        return [];
    }

    var decoded = jwt(user.message);
    var roles = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if (roles && Array.isArray(roles)) {
        return roles;
    }
    return [roles];

}

export default {
    registerIndividual,
    registerFirm,
    login,
    logout,
    getCurrentUser,
    getRoles,
    forgotPassword,
    resendEmailVerification,
    forgotIndividualUserId,
    forgotFirmUserId
}