import React from 'react';
import RenderRoutes from "./renderroutes"
import DashboardPage from "./../Public/Individual/Dashboard/DashboardPage"
import ShoppingCartPage from "./../Public/Individual/ShoppingCart/ShoppingCartPage"
import PaymentSuccessPage from "./../Public/Individual/ShoppingCart/PaymentSuccessPage"
import PaymentFailedPage from "./../Public/Individual/ShoppingCart/PaymentFailedPage"
import PaymentErrorPage from "./../Public/Individual/ShoppingCart/PaymentErrorPage"
import AskLolaPage from "../Shared/AskLola/AskLolaPage"
import UserProfilePage from "../Shared/UserProfile/UserProfilePage"
import InvoiceHistoryPage from '../Shared/InvoiceHistory/InvoiceHistoryPage';
import RedirectToSection from '../Shared/Redirect/RedirectToSection';

import ContactPage from "./../Public/Individual/Contact/ContactPage";
import Applications from "./../Public/Individual/Applications/Applications"
import Renewals from "./../Public/Individual/Renewals/Renewals";
import Reinstatements from "./../Public/Individual/Reinstatements/Reinstatements";
import CpdRequirementsPage from "./../Public/Individual/CPDLocker/CpdRequirementsPage"
import SupervisingProfessionalPage from "./../Public/Individual/SupervisingProfessional/SupervisingProfessionalPage"
import InvoicePage from '../Public/Individual/ShoppingCart/InvoicePage';

//#region PLS
//Pls App
import PlsAppSectionPage from '../Public/Individual/Applications/PLS/Index/Index';
import PlsAppContactPage from "./../Public/Individual/Applications/PLS/ContactInformation/ContactInformationPage";
import PlsAppEducationPage from "./../Public/Individual/Applications/PLS/Education/EducationPage";
import PlsAppReferencesPage from "./../Public/Individual/Applications/PLS/PersonalReferences/PersonalReferencesPage";
import PlsAppViolations from '../Public/Individual/Applications/PLS/Violations/Violations'
import PlsAppEmploymentPage from "./../Public/Individual/Applications/PLS/Employment/EmploymentPage";
import PlsAppQuizzesPage from "./../Public/Individual/Applications/PLS/Quizzes/QuizzesPage";
import PlsAppExperiencePage from "../Public/Individual/Applications/PLS/Experience/ExperiencePage";
import PlsAppAckPage from "./../Public/Individual/Applications/PLS/Acknowledgement/AcknowledgementPage";
import PlsAppReviewPage from "./../Public/Individual/Applications/PLS/Review/ReviewPage";
//Pls Reinstatement
import PlsReinstatementContactPage from "./../Public/Individual/Reinstatements/PLS/ContactInformation/ContactInformationPage";
import PlsReinstatementQuizzesPage from "./../Public/Individual/Reinstatements/PLS/Quizzes/QuizzesPage";
import PlsReinstatementCpdPage from "./../Public/Individual/Reinstatements/PLS/CpdRequirements/CpdRequirementsPage";
import PlsReinstatementIndexPage from "./../Public/Individual/Reinstatements/PLS/Index/Index";
import PlsReinstatementAckPage from "./../Public/Individual/Reinstatements/PLS/Acknowledgement/AcknowledgementPage";
import PlsReinstatementEmploymentPage from "./../Public/Individual/Reinstatements/PLS/Employment/EmploymentPage";
import PlsReinstatementPersonalReferencePage from "./../Public/Individual/Reinstatements/PLS/PersonalReferences/PersonalReferencesPage";
import PlsReinstatementDisclosurePage from "./../Public/Individual/Reinstatements/PLS/Disclosure/DisclosurePage";
import PlsReinstatementViolationsPage from "./../Public/Individual/Reinstatements/PLS/Violations/Violations";
import PlsReinstatementExperiencePage from "./../Public/Individual/Reinstatements/PLS/Experience/ExperiencePage";
import PlsReinstatementReviewPage from "./../Public/Individual/Reinstatements/PLS/Review/ReviewPage";
//Pls Renewal
import PlsRenewalContactPage from "./../Public/Individual/Renewals/PLS/ContactInformation/ContactInformationPage";
import PlsRenewalsCpdPage from "./../Public/Individual/Renewals/PLS/CpdRequirements/CpdRequirementsPage";
import PlsRenewalsViolationsPage from "./../Public/Individual/Renewals/PLS/Violations/Violations";
import PlsRenewalsPrivacyPage from "./../Public/Individual/Renewals/PLS/Privacy/Privacy";
import PlsRenewalsIndexPage from "./../Public/Individual/Renewals/PLS/Index/Index";
import PlsRenewalAckPage from "./../Public/Individual/Renewals/PLS/Acknowledgement/AcknowledgementPage";
import PlsRenewalReviewPage from "./../Public/Individual/Renewals/PLS/Review/ReviewPage";
//import PlsRenewalSupervisingProfessional from "./../Public/Individual/Renewals/PLS/SupervisingProfessional/SupervisingProfessionalPage";
import PlsRenewalLicenseStatusPage from "./../Public/Individual/Renewals/PLS/LicenseStatus/LicenseStatusPage";
//#endregion

//#region PE

//PE Application
import PeAppSectionPage from '../Public/Individual/Applications/PE/Index/Index';
import PeAppContactPage from "./../Public/Individual/Applications/PE/ContactInformation/ContactInformationPage";
import PeAppEducationPage from "./../Public/Individual/Applications/PE/Education/EducationPage";
import PeAppReferencesPage from "./../Public/Individual/Applications/PE/PersonalReferences/PersonalReferencesPage";
import PeAppExperiencePage from "./../Public/Individual/Applications/PE/Experience/ExperiencePage"
import PeAppViolations from '../Public/Individual/Applications/PE/Violations/Violations'
import PeAppQuizzesPage from "./../Public/Individual/Applications/PE/Quizzes/QuizzesPage";
import PeAppCoopExperiencePage from "../Public/Individual/Applications/PE/CoopExperiences/CoopExperiencePage";
import PeAppResidencyPage from "../Public/Individual/Applications/PE/Residency/ResidencyPage";
import PeAppEmployementPage from "../Public/Individual/Applications/PE/Employment/EmploymentPage";
import PeAppAckPage from "./../Public/Individual/Applications/PE/Acknowledgement/AcknowledgementPage";
import PeAppReviewPage from "./../Public/Individual/Applications/PE/Review/ReviewPage";

//PE Renewal
import PeRenewalIndexPage from "./../Public/Individual/Renewals/PE/Index/Index";
import PeRenewalContactPage from "./../Public/Individual/Renewals/PE/ContactInformation/ContactInformationPage";
import PeRenewalLicenseStatusPage from "./../Public/Individual/Renewals/PE/LicenseStatus/LicenseStatusPage";
import PeRenewalCpdRequirementsPage from "./../Public/Individual/Renewals/PE/CpdRequirements/CpdRequirementsPage";
import PeRenewalDesignPage from '../Public/Individual/Renewals/PE/Design/BuildingDesignPage';
import PeRenewalViolationsPage from "./../Public/Individual/Renewals/PE/Violations/Violations";
import PeRenewalPrivacyPage from "./../Public/Individual/Renewals/PE/Privacy/Privacy";
import PeRenewalAckPage from '../Public/Individual/Renewals/PE/Acknowledgement/AcknowledgementPage';
import PeRenewalReviewPage from "./../Public/Individual/Renewals/PE/Review/ReviewPage";

//PE Reinstatement
import PeReinstatementIndexPage from "./../Public/Individual/Reinstatements/PE/Index/Index";
import PeReinstatementContactPage from "./../Public/Individual/Reinstatements/PE/ContactInformation/ContactInformationPage";
import PeReinstatementDesignPage from './../Public/Individual/Reinstatements/PE/Design/BuildingDesignPage';
import PeReinstatementEmploymentPage from "./../Public/Individual/Reinstatements/PE/Employment/EmploymentPage";
import PeReinstatementViolationsPage from "./../Public/Individual/Reinstatements/PE/Violations/Violations";
import PeReinstatementQuizzesPage from "./../Public/Individual/Reinstatements/PE/Quizzes/QuizzesPage";
import PeReinstatementCpdPage from "./../Public/Individual/Reinstatements/PE/CpdRequirements/CpdRequirementsPage";
import PeReinstatementPersonalReferencePage from "./../Public/Individual/Reinstatements/PE/PersonalReferences/PersonalReferencesPage";
import PeReinstatementExperiencePage from "./../Public/Individual/Reinstatements/PE/Experience/ExperiencePage";
import PeReinstatementDisclosurePage from "./../Public/Individual/Reinstatements/PE/Disclosure/DisclosurePage";
import PeReinstatementAckPage from "./../Public/Individual/Reinstatements/PE/Acknowledgement/AcknowledgementPage";
import PeReinstatementReviewPage from "./../Public/Individual/Reinstatements/PE/Review/ReviewPage";
//#endregion

//#region LSI
//Lsi App
import LsiAppSectionPage from '../Public/Individual/Applications/LSI/Index/Index';
import LsiAppContactPage from "./../Public/Individual/Applications/LSI/ContactInformation/ContactInformationPage";
import LsiAppEducationPage from "./../Public/Individual/Applications/LSI/Education/EducationPage";
import LsiAppRecommendationPage from "./../Public/Individual/Applications/LSI/Recommendation/RecommendationPage";
import LsiAppViolations from '../Public/Individual/Applications/LSI/Violations/ViolationsPage'
import LsiAppExaminationPage from "./../Public/Individual/Applications/LSI/ExaminationVerification/ExaminationVerificationPage";
import LsiAppAckPage from "./../Public/Individual/Applications/LSI/Acknowledgement/AcknowledgementPage";
import LsiAppReviewPage from "./../Public/Individual/Applications/LSI/Review/ReviewPage";

//Lsi Renewal
import LsiRenewalContactPage from "./../Public/Individual/Renewals/LSI/ContactInformation/ContactInformationPage";
import LsiRenewalsViolationsPage from "./../Public/Individual/Renewals/LSI/Violations/ViolationsPage";
import LsiRenewalsPrivacyPage from "./../Public/Individual/Renewals/LSI/Privacy/PrivacyPage";
import LsiRenewalsIndexPage from "./../Public/Individual/Renewals/LSI/Index/Index";
import LsiRenewalAckPage from "./../Public/Individual/Renewals/LSI/Acknowledgement/AcknowledgementPage";
import LsiRenewalReviewPage from "./../Public/Individual/Renewals/LSI/Review/ReviewPage";

//Lsi Reinstatement
import LsiReinstatementIndexPage from "./../Public/Individual/Reinstatements/LSI/Index/Index";
import LsiReinstatementContactPage from "./../Public/Individual/Reinstatements/LSI/ContactInformation/ContactInformationPage";
import LsiReinstatementEducationPage from "./../Public/Individual/Reinstatements/LSI/Education/EducationPage";
import LsiReinstatementExaminationPage from "./../Public/Individual/Reinstatements/LSI/ExaminationVerification/ExaminationVerificationPage";
import LsiReinstatementViolationsPage from "./../Public/Individual/Reinstatements/LSI/Violations/Violations";
import LsiReinstatementAckPage from "./../Public/Individual/Reinstatements/LSI/Acknowledgement/AcknowledgementPage";
import LsiReinstatementReviewPage from "./../Public/Individual/Reinstatements/LSI/Review/ReviewPage";

//#endregion

//#region EI
//Ei App
import EiAppSectionPage from '../Public/Individual/Applications/EI/Index/Index';
import EiAppContactPage from "./../Public/Individual/Applications/EI/ContactInformation/ContactInformationPage";
import EiAppEducationPage from "./../Public/Individual/Applications/EI/Education/EducationPage";
import EiAppRecommendationPage from "./../Public/Individual/Applications/EI/Recommendation/RecommendationPage";
import EiAppExperiencePage from "./../Public/Individual/Applications/EI/Experience/ExperiencePage";
import EiAppViolations from '../Public/Individual/Applications/EI/Violations/ViolationsPage'
import EiAppExaminationPage from "./../Public/Individual/Applications/EI/ExaminationVerification/ExaminationVerificationPage";
import EiAppAckPage from "./../Public/Individual/Applications/EI/Acknowledgement/AcknowledgementPage";
import EiAppReviewPage from "./../Public/Individual/Applications/EI/Review/ReviewPage";

//Ei Renewal
import EiRenewalContactPage from "./../Public/Individual/Renewals/EI/ContactInformation/ContactInformationPage";
import EiRenewalsViolationsPage from "./../Public/Individual/Renewals/EI/Violations/ViolationsPage";
import EiRenewalsPrivacyPage from "./../Public/Individual/Renewals/EI/Privacy/PrivacyPage";
import EiRenewalsIndexPage from "./../Public/Individual/Renewals/EI/Index/Index";
import EiRenewalAckPage from "./../Public/Individual/Renewals/EI/Acknowledgement/AcknowledgementPage";
import EiRenewalReviewPage from "./../Public/Individual/Renewals/EI/Review/ReviewPage";

//Ei Reinstatement
import EiReinstatementIndexPage from "./../Public/Individual/Reinstatements/EI/Index/Index";
import EiReinstatementContactPage from "./../Public/Individual/Reinstatements/EI/ContactInformation/ContactInformationPage";
import EiReinstatementEducationPage from "./../Public/Individual/Reinstatements/EI/Education/EducationPage";
import EiReinstatementExaminationPage from "./../Public/Individual/Reinstatements/EI/ExaminationVerification/ExaminationVerificationPage";
import EiReinstatementViolationsPage from "./../Public/Individual/Reinstatements/EI/Violations/Violations";
import EiReinstatementAckPage from "./../Public/Individual/Reinstatements/EI/Acknowledgement/AcknowledgementPage";
import EiReinstatementReviewPage from "./../Public/Individual/Reinstatements/EI/Review/ReviewPage";
//#endregion
const INDIVIDUAL_ROUTES = [
    {
        path: "/",
        key: "ROOT",
        exact: true,
        component: () => <DashboardPage />
    },
    {
        path: "/individual",
        key: "PUBLIC",
        component: RenderRoutes,
        routes: [
            {
                path: "/individual",
                key: "PUBLIC_ROOT",
                exact: true,
                component: () => <DashboardPage />
            },
            {
                path: "/individual/dashboard",
                key: "Dashboard",
                exact: true,
                component: () => <DashboardPage />
            },
            {
                path: "/individual/shoppingcart",
                key: "ShoppingCart",
                exact: true,
                component: () => <ShoppingCartPage />
            },
            {
                path: "/individual/shoppingcart/success",
                key: "ShoppingCart",
                exact: true,
                component: () => <PaymentSuccessPage />
            },
            {
                path: "/individual/shoppingcart/failed",
                key: "ShoppingCart",
                exact: true,
                component: () => <PaymentFailedPage />
            },
            {
                path: "/individual/shoppingcart/error",
                key: "ShoppingCart",
                exact: true,
                component: () => <PaymentErrorPage />
            },
            {
                path: "/individual/invoice",
                key: "Invoice",
                exact: true,
                component: () => <InvoicePage />
            },
            {
                path: "/individual/invoicehistory",
                key: "InvoiceHistory",
                exact: true,
                component: () => <InvoiceHistoryPage isIndividual={true} />
            },
            {
                path: "/individual/askLola",
                key: "INDIVIDUAL_ASK_LOLA",
                exact: true,
                component: () => <AskLolaPage />
            },
            {
                path: "/individual/redirectToSection",
                key: "INDIVIDUAL_REDIRECT_TO_SECTION",
                exact: true,
                component: () => <RedirectToSection linkPrefix={"individual"} />
            },
            {
                path: "/individual/userProfile",
                key: "INDIVIDUAL_USER_PROFILE",
                exact: true,
                component: () => <UserProfilePage />
            },
            {
                path: "/individual/cpdlocker",
                key: "CPDLocker",
                exact: true,
                component: () => <CpdRequirementsPage />
            },
            {
                path: "/individual/supervisingProfessional",
                key: "INDIVIDUAL_SUPERVISING_PROFESSIONAL",
                exact: true,
                component: () => <SupervisingProfessionalPage />
            },
            {
                path: "/individual/contactinformation",
                key: "PUBLIC_DASHBOARD",
                exact: true,
                component: () => <ContactPage />
            },
            {
                path: "/individual/renewals/",
                key: "PUBLIC_RENEWALS",
                exact: true,
                component: () => <Renewals />
            }, {
                path: "/individual/reinstatements/",
                key: "PUBLIC_REINSTATEMENTS",
                exact: true,
                component: () => <Reinstatements />
            }, {
                path: "/individual/applications/",
                key: "PUBLIC_APPLICATIONS",
                exact: true,
                component: () => <Applications />
            },
            {
                path: "/individual/plsRenewal/contactInformation",
                key: "PUBLIC_PLS_RENEWAL_CONTACTINFORMATION",
                exact: true,
                component: () => <PlsRenewalContactPage />
            },
            {
                path: "/individual/plsReinstatement/contactInformation",
                key: "PUBLIC_PLS_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <PlsReinstatementContactPage />
            },
            //{
            //    path: "/individual/plsRenewal/supervisingProfessional",
            //    key: "PUBLIC_PLS_RENEWALS_SUPERVISINGPROFESSIONAL",
            //    exact: true,
            //    component: () => <PlsRenewalSupervisingProfessional />
            //},
            {
                path: "/individual/plsReinstatement/quizzes",
                key: "PUBLIC_PLS_REINSTATEMENT_QUIZZES",
                exact: true,
                component: () => <PlsReinstatementQuizzesPage />
            },
            {
                path: "/individual/plsReinstatement/cpdRequirements",
                key: "PUBLIC_PLS_REINSTATEMENT_CPDREQUIREMENTS",
                exact: true,
                component: () => <PlsReinstatementCpdPage />
            },
            {
                path: "/individual/plsRenewal/sections",
                key: "PUBLIC_PLS_RENEWALS_Index",
                exact: true,
                component: () => <PlsRenewalsIndexPage />
            },
            {
                path: "/individual/plsRenewal/cpdRequirements",
                key: "PUBLIC_PLS_RENEWALS_CPDREQUIREMENTS",
                exact: true,
                component: () => <PlsRenewalsCpdPage />
            },
            {
                path: "/individual/plsRenewal/violations",
                key: "PUBLIC_PLS_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <PlsRenewalsViolationsPage />
            },
            {
                path: "/individual/plsRenewal/privacy",
                key: "PUBLIC_PLS_RENEWALS_PRIVACY",
                exact: true,
                component: () => <PlsRenewalsPrivacyPage />
            },
            {
                path: "/individual/plsReinstatements/acknowledgement",
                key: "PUBLIC_PLS_REINSTATEMENTS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <PlsReinstatementAckPage />
            },
            {
                path: "/individual/plsReinstatement/employment",
                key: "PUBLIC_PLS_REINSTATEMENTS_EMPLOYMENT",
                exact: true,
                component: () => <PlsReinstatementEmploymentPage />
            },
            {
                path: "/individual/plsRenewal/acknowledgement",
                key: "PUBLIC_PLS_RENEWALS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <PlsRenewalAckPage />
            },
            {
                path: "/individual/plsRenewal/review",
                key: "PUBLIC_PLS_RENEWALS_REVIEW",
                exact: true,
                component: () => <PlsRenewalReviewPage />
            },
            {
                path: "/individual/plsRenewal/licenseStatus",
                key: "PUBLIC_PLS_RENEWALS_LICENSESTATUS",
                exact: true,
                component: () => <PlsRenewalLicenseStatusPage />
            },
            {
                path: "/individual/plsReinstatement/disclosure",
                key: "PUBLIC_PLS_REINSTATEMENTS_DISCLOSURE",
                exact: true,
                component: () => < PlsReinstatementDisclosurePage />
            },
            {
                path: "/individual/plsReinstatements/personalReferences",
                key: "PUBLIC_PLS_REINSTATEMENTS_PERSONALREFERENCES",
                exact: true,
                component: () => <PlsReinstatementPersonalReferencePage />
            },
            {
                path: "/individual/plsApp/contactInformation",
                key: "PUBLIC_PLS_APPLICATIONS_CONTACTINFORMATION",
                exact: true,
                component: () => <PlsAppContactPage />
            },
            {
                path: "/individual/plsApp/acknowledgement",
                key: "PUBLIC_PLS_APPLICATIONS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <PlsAppAckPage />
            },
            {
                path: "/individual/plsApp/review",
                key: "PUBLIC_PLS_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <PlsAppReviewPage />
            },
            {
                path: "/individual/plsApp/violations",
                key: "PUBLIC_PLS_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <PlsAppViolations />
            },
            {
                path: "/individual/plsApp/experience",
                key: "PUBLIC_PLS_APPLICATIONS_EXPERIENCE",
                exact: true,
                component: () => <PlsAppExperiencePage />
            },
            {
                path: "/individual/plsApp/educationRecord",
                key: "PUBLIC_PLS_APPLICATIONS_EDUCATION",
                exact: true,
                component: () => <PlsAppEducationPage />
            },
            {
                path: "/individual/plsApp/employment",
                key: "PUBLIC_PLS_APPLICATIONS_EMPLOYMENT",
                exact: true,
                component: () => <PlsAppEmploymentPage />
            },
            {
                path: "/individual/plsApp/personalReferences",
                key: "PUBLIC_PLS_APPLICATIONS_REFERENCES",
                exact: true,
                component: () => <PlsAppReferencesPage />
            },
            {
                path: "/individual/plsApp/quizzes",
                key: "PUBLIC_PLS_APPLICATIONS_QUIZZES",
                exact: true,
                component: () => <PlsAppQuizzesPage />
            },
            {
                path: "/individual/plsApp/sections",
                key: "PUBLIC_PLS_APPLICATIONS_INDEX",
                exact: true,
                component: () => <PlsAppSectionPage />
            },
            {
                path: "/individual/plsReinstatement/sections",
                key: "PUBLIC_PLS_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <PlsReinstatementIndexPage />
            },
            {
                path: "/individual/plsReinstatement/violations",
                key: "PUBLIC_PLS_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <PlsReinstatementViolationsPage />
            },
            {
                path: "/individual/plsReinstatement/personalReferences",
                key: "PUBLIC_PLS_REINSTATEMENT_REFERENCES",
                exact: true,
                component: () => <PlsReinstatementPersonalReferencePage />
            },
            {
                path: "/individual/plsReinstatement/experience",
                key: "PUBLIC_PLS_REINSTATEMENT_EXPERIENCE",
                exact: true,
                component: () => <PlsReinstatementExperiencePage />
            },
            {
                path: "/individual/plsReinstatement/acknowledgement",
                key: "PUBLIC_PLS_REINSTATEMENT_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PlsReinstatementAckPage />
            },
            {
                path: "/individual/plsReinstatement/review",
                key: "PUBLIC_PLS_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <PlsReinstatementReviewPage />
            },

            //#region LSI
            //LSI App
            {
                path: "/individual/lsiApp/contactInformation",
                key: "PUBLIC_LSI_APPLICATIONS_CONTACTINFORMATION",
                exact: true,
                component: () => <LsiAppContactPage />
            },
            {
                path: "/individual/lsiApp/acknowledgement",
                key: "PUBLIC_LSI_APPLICATIONS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <LsiAppAckPage />
            },
            {
                path: "/individual/lsiApp/review",
                key: "PUBLIC_LSI_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <LsiAppReviewPage />
            },
            {
                path: "/individual/lsiApp/violations",
                key: "PUBLIC_LSI_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <LsiAppViolations />
            },
            {
                path: "/individual/lsiApp/examination",
                key: "PUBLIC_LSI_APPLICATIONS_EXAMINATION",
                exact: true,
                component: () => <LsiAppExaminationPage />
            },
            {
                path: "/individual/lsiApp/educationRecord",
                key: "PUBLIC_LSI_APPLICATIONS_EDUCATION",
                exact: true,
                component: () => <LsiAppEducationPage />
            },
            {
                path: "/individual/lsiApp/recommendation",
                key: "PUBLIC_LSI_APPLICATIONS_RECOMMENDATION",
                exact: true,
                component: () => <LsiAppRecommendationPage />
            },
            {
                path: "/individual/lsiApp/sections",
                key: "PUBLIC_LSI_APPLICATIONS_INDEX",
                exact: true,
                component: () => <LsiAppSectionPage />
            },
            //LSI Renewal
            {
                path: "/individual/lsiRenewal/sections",
                key: "PUBLIC_LSI_RENEWALS_INDEX",
                exact: true,
                component: () => <LsiRenewalsIndexPage />
            },
            {
                path: "/individual/lsiRenewal/contactInformation",
                key: "PUBLIC_LSI_RENEWALS_CONTACTINFORMATION",
                exact: true,
                component: () => <LsiRenewalContactPage />
            },
            {
                path: "/individual/lsiRenewal/violations",
                key: "PUBLIC_LSI_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <LsiRenewalsViolationsPage />
            },
            {
                path: "/individual/lsiRenewal/privacy",
                key: "PUBLIC_LSI_RENEWALS_PRIVACY",
                exact: true,
                component: () => <LsiRenewalsPrivacyPage />
            },
            {
                path: "/individual/lsiRenewal/acknowledgement",
                key: "PUBLIC_LSI_RENEWALS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <LsiRenewalAckPage />
            },
            {
                path: "/individual/lsiRenewal/review",
                key: "PUBLIC_LSI_RENEWALS_REVIEW",
                exact: true,
                component: () => <LsiRenewalReviewPage />
            },
            //LSI Reinstatement
            {
                path: "/individual/lsiReinstatement/sections",
                key: "PUBLIC_LSI_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <LsiReinstatementIndexPage />
            },
            {
                path: "/individual/lsiReinstatement/contactInformation",
                key: "PUBLIC_LSI_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <LsiReinstatementContactPage />
            },
            {
                path: "/individual/lsiReinstatement/examination",
                key: "PUBLIC_LSI_REINSTATEMENT_EXAMINATION",
                exact: true,
                component: () => <LsiReinstatementExaminationPage />
            },
            {
                path: "/individual/lsiReinstatement/education",
                key: "PUBLIC_LSI_REINSTATEMENT_EDUCATION",
                exact: true,
                component: () => <LsiReinstatementEducationPage />
            },
            {
                path: "/individual/lsiReinstatement/violations",
                key: "PUBLIC_LSI_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <LsiReinstatementViolationsPage />
            },
            {
                path: "/individual/lsiReinstatement/acknowledgement",
                key: "PUBLIC_LSI_REINSTATEMENT_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <LsiReinstatementAckPage />
            },
            {
                path: "/individual/lsiReinstatement/review",
                key: "PUBLIC_LSI_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <LsiReinstatementReviewPage />
            },
            //#endregion

            //#region EI
            //EI App
            {
                path: "/individual/eiapp/contactinformation",
                key: "PUBLIC_EI_APPLICATIONS_CONTACTINFORMATION",
                exact: true,
                component: () => <EiAppContactPage />
            },
            {
                path: "/individual/eiapp/acknowledgement",
                key: "PUBLIC_EI_APPLICATIONS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <EiAppAckPage />
            },
            {
                path: "/individual/eiapp/review",
                key: "PUBLIC_EI_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <EiAppReviewPage />
            },
            {
                path: "/individual/eiapp/violations",
                key: "PUBLIC_EI_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <EiAppViolations />
            },
            {
                path: "/individual/eiapp/examination",
                key: "PUBLIC_EI_APPLICATIONS_EXAMINATION",
                exact: true,
                component: () => <EiAppExaminationPage />
            },
            {
                path: "/individual/eiapp/educationrecord",
                key: "PUBLIC_EI_APPLICATIONS_EDUCATION",
                exact: true,
                component: () => <EiAppEducationPage />
            },
            {
                path: "/individual/eiapp/recommendation",
                key: "PUBLIC_EI_APPLICATIONS_RECOMMENDATION",
                exact: true,
                component: () => <EiAppRecommendationPage />
            },
            {
                path: "/individual/eiapp/experience",
                key: "PUBLIC_EI_APPLICATIONS_EXPERIENCE",
                exact: true,
                component: () => <EiAppExperiencePage />
            },
            {
                path: "/individual/eiapp/sections",
                key: "PUBLIC_EI_APPLICATIONS_INDEX",
                exact: true,
                component: () => <EiAppSectionPage />
            },
            //EI Renewal
            {
                path: "/individual/eirenewal/sections",
                key: "PUBLIC_EI_RENEWALS_INDEX",
                exact: true,
                component: () => <EiRenewalsIndexPage />
            },
            {
                path: "/individual/eirenewal/contactinformation",
                key: "PUBLIC_EI_RENEWALS_CONTACTINFORMATION",
                exact: true,
                component: () => <EiRenewalContactPage />
            },
            {
                path: "/individual/eiRenewal/violations",
                key: "PUBLIC_EI_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <EiRenewalsViolationsPage />
            },
            {
                path: "/individual/eirenewal/privacy",
                key: "PUBLIC_EI_RENEWALS_PRIVACY",
                exact: true,
                component: () => <EiRenewalsPrivacyPage />
            },
            {
                path: "/individual/eiRenewal/acknowledgement",
                key: "PUBLIC_EI_RENEWALS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <EiRenewalAckPage />
            },
            {
                path: "/individual/eirenewal/review",
                key: "PUBLIC_EI_RENEWALS_REVIEW",
                exact: true,
                component: () => <EiRenewalReviewPage />
            },
            //EI Reinstatement
            {
                path: "/individual/eiReinstatement/sections",
                key: "PUBLIC_EI_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <EiReinstatementIndexPage />
            },
            {
                path: "/individual/eiReinstatement/contactInformation",
                key: "PUBLIC_EI_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <EiReinstatementContactPage />
            },
            {
                path: "/individual/eiReinstatement/examination",
                key: "PUBLIC_EI_REINSTATEMENT_EXAMINATION",
                exact: true,
                component: () => <EiReinstatementExaminationPage />
            },
            {
                path: "/individual/eiReinstatement/education",
                key: "PUBLIC_EI_REINSTATEMENT_EDUCATION",
                exact: true,
                component: () => <EiReinstatementEducationPage />
            },
            {
                path: "/individual/eiReinstatement/violations",
                key: "PUBLIC_EI_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <EiReinstatementViolationsPage />
            },
            {
                path: "/individual/eiReinstatement/acknowledgement",
                key: "PUBLIC_EI_REINSTATEMENT_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <EiReinstatementAckPage />
            },
            {
                path: "/individual/eiReinstatement/review",
                key: "PUBLIC_EI_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <EiReinstatementReviewPage />
            },
            //#endregion

            //#region PE
            //App
            {
                path: "/individual/peapp/sections",
                key: "PUBLIC_PE_APPLICATION_SECTION",
                exact: true,
                component: () => <PeAppSectionPage />
            },
            {
                path: "/individual/peapp/contactinformation",
                key: "PUBLIC_PE_APPLICATION_CONTACTINFO",
                exact: true,
                component: () => <PeAppContactPage />
            },
            {
                path: "/individual/peapp/educationrecord",
                key: "PUBLIC_PE_APPLICATION_EDUCATION",
                exact: true,
                component: () => <PeAppEducationPage />
            },
            {
                path: "/individual/peapp/personalreferences",
                key: "PUBLIC_PE_APPLICATION_PERSONALREFERENCE",
                exact: true,
                component: () => <PeAppReferencesPage />
            },
            {
                path: "/individual/peapp/experience",
                key: "PUBLIC_PE_APPLICATION_EXPERIENCE",
                exact: true,
                component: () => <PeAppExperiencePage />
            },
            {
                path: "/individual/peapp/violations",
                key: "PUBLIC_PE_APPLICATION_VIOLATIONS",
                exact: true,
                component: () => <PeAppViolations />
            },
            {
                path: "/individual/peapp/quizzes",
                key: "PUBLIC_PE_APPLICATION_QUIZZES",
                exact: true,
                component: () => <PeAppQuizzesPage />
            },
            {
                path: "/individual/peapp/coopexperience",
                key: "PUBLIC_PE_APPLICATION_COOPEXPERIENCE",
                exact: true,
                component: () => <PeAppCoopExperiencePage />
            },
            {
                path: "/individual/peapp/residency",
                key: "PUBLIC_PE_APPLICATION_RESIDENCY",
                exact: true,
                component: () => <PeAppResidencyPage />
            },
            {
                path: "/individual/peapp/employment",
                key: "PUBLIC_PE_APPLICATION_EMPLOYMENT",
                exact: true,
                component: () => <PeAppEmployementPage />
            },
            {
                path: "/individual/peapp/acknowledgement",
                key: "PUBLIC_PE_APPLICATION_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PeAppAckPage />
            },
            {
                path: "/individual/peapp/review",
                key: "PUBLIC_PE_APPLICATION_REVIEW",
                exact: true,
                component: () => <PeAppReviewPage />
            },

            //Renewal
            {
                path: "/individual/perenewal/sections",
                key: "PUBLIC_PE_RENEWALS_Index",
                exact: true,
                component: () => <PeRenewalIndexPage />
            },
            {
                path: "/individual/perenewal/contactinformation",
                key: "PUBLIC_PE_RENEWAL_CONTACTINFORMATION",
                exact: true,
                component: () => <PeRenewalContactPage />
            },
            {
                path: "/individual/perenewal/licensestatus",
                key: "PUBLIC_PE_RENEWALS_LICENSESTATUS",
                exact: true,
                component: () => <PeRenewalLicenseStatusPage />
            },
            {
                path: "/individual/perenewal/cpdrequirements",
                key: "PUBLIC_PE_RENEWALS_CPDREQUIREMENTS",
                exact: true,
                component: () => <PeRenewalCpdRequirementsPage />
            },
            {
                path: "/individual/perenewal/design",
                key: "PUBLIC_PE_RENEWALS_DESIGN",
                exact: true,
                component: () => <PeRenewalDesignPage />
            },
            {
                path: "/individual/perenewal/violations",
                key: "PUBLIC_PE_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <PeRenewalViolationsPage />
            },
            {
                path: "/individual/perenewal/privacy",
                key: "PUBLIC_PE_RENEWALS_PRIVACY",
                exact: true,
                component: () => <PeRenewalPrivacyPage />
            },
            {
                path: "/individual/perenewal/acknowledgement",
                key: "PUBLIC_PE_RENEWALS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <PeRenewalAckPage />
            },
            {
                path: "/individual/perenewal/review",
                key: "PUBLIC_PE_RENEWALS_REVIEW",
                exact: true,
                component: () => <PeRenewalReviewPage />
            },

            //Reinstatement
            {
                path: "/individual/pereinstatement/sections",
                key: "PUBLIC_PE_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <PeReinstatementIndexPage />
            },
            {
                path: "/individual/pereinstatement/contactInformation",
                key: "PUBLIC_PE_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <PeReinstatementContactPage />
            },
            {
                path: "/individual/pereinstatement/design",
                key: "PUBLIC_PE_REINSTATEMENT_DESIGN",
                exact: true,
                component: () => <PeReinstatementDesignPage />
            },
            {
                path: "/individual/pereinstatement/employment",
                key: "PUBLIC_PE_REINSTATEMENTS_EMPLOYMENT",
                exact: true,
                component: () => <PeReinstatementEmploymentPage />
            },
            {
                path: "/individual/pereinstatement/violations",
                key: "PUBLIC_PE_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <PeReinstatementViolationsPage />
            },
            {
                path: "/individual/pereinstatement/quizzes",
                key: "PUBLIC_PE_REINSTATEMENT_QUIZZES",
                exact: true,
                component: () => <PeReinstatementQuizzesPage />
            },
            {
                path: "/individual/pereinstatement/cpdrequirements",
                key: "PUBLIC_PE_REINSTATEMENT_CPDREQUIREMENTS",
                exact: true,
                component: () => <PeReinstatementCpdPage />
            },
            {
                path: "/individual/pereinstatement/personalreferences",
                key: "PUBLIC_PE_REINSTATEMENT_REFERENCES",
                exact: true,
                component: () => <PeReinstatementPersonalReferencePage />
            },
            {
                path: "/individual/pereinstatement/experience",
                key: "PUBLIC_PE_REINSTATEMENT_EXPERIENCE",
                exact: true,
                component: () => <PeReinstatementExperiencePage />
            },
            {
                path: "/individual/pereinstatement/disclosure",
                key: "PUBLIC_PE_REINSTATEMENTS_DISCLOSURE",
                exact: true,
                component: () => < PeReinstatementDisclosurePage />
            },
            {
                path: "/individual/pereinstatement/acknowledgement",
                key: "PUBLIC_PE_REINSTATEMENT_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PeReinstatementAckPage />
            },
            {
                path: "/individual/pereinstatement/review",
                key: "PUBLIC_PE_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <PeReinstatementReviewPage />
            },
            //#endregion
        ]
    }
]

export default INDIVIDUAL_ROUTES;