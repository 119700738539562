import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import EducationRecord from './EducationRecords';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import AddEditEducationRecordsModal from '../../../../../Shared/Modal/AddEditEducationRecordsModal';
import { useSelector } from 'react-redux';
import applicationService from '../../../../../../services/Public/Application/PE/peApplication.service';
import educationService from '../../../../../../services/Public/Application/PE/education.service';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../../Shared/Card/Issues';
import dayjs from 'dayjs';

function EducationPage() {
    const [hideGrids, setHideGrids] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});
    const { push } = useHistory();
    const entityId = useSelector(x => x.userInfo.EntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const sectionId = 2;
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        setHasLoaded(false)
        applicationService.peGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            setHideGrids(response.data.hasMyNceesEducation ?? true);
            setHasLoaded(true);
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesEducation: BooleanType().isRequired('This field is required.')
    });
    const handleMyNceesEducationChange = (val) => {
        setHideGrids(val);
    }
    const openModal = (values) => {
        if (values.id) {
            setModalData(
                {
                    ...values,
                    attendanceEndDate: values.attendanceEndDate ? dayjs(values.attendanceEndDate).format('YYYY-MM') : null,
                    attendanceStartDate: values.attendanceStartDate ? dayjs(values.attendanceStartDate).format('YYYY-MM') : null,
                    graduationDate: values.graduationDate ? dayjs(values.graduationDate).format('YYYY-MM-DD') : null,
                    modalType: 'Edit'
                });
        }
        else {
            setModalData({
                applicationId: appId,
                attendanceEndDate: null,
                attendanceStartDate: null,
                dateCrte: new Date(),
                degree: null,
                deleted: false,
                didGraduate: '',
                entityId: entityId,
                graduationDate: null,
                id: '',
                major: '',
                school: null,
                userCrte: '',
                modalType: "Add"
            })
        }
        setModalShow(true);
    }

    const [reloadGrid, setReloadGrid] = useState(1);

    const submitForm = (validation, event) => {
        if (!sectionData.isUserEditable)
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        else {
            if (validation) {
                applicationService.pePostSectionHasMyNceesEducation(appId, sectionId, sectionData.hasMyNceesEducation).then(() => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            }
        }
    }
    return (
        <div className="peAppEducationPage">
            <Container fluid>
                <strong>Professional Engineer Application {appType}</strong>
                <h4 className="mb-3">Education</h4>
                <Information content={appTypeId == 3 ? GetAppInformationContent("educationByComity") : GetAppInformationContent("educationByExam")} />
                <br />
                {hasLoaded ? <>
                    {
                        modalShow &&
                        <AddEditEducationRecordsModal updateGrid={() => { var data = 1 + reloadGrid; setReloadGrid(data) }} modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} proptitle="Add" />
                    }
                   
                    <Form
                        readOnly={!sectionData.isUserEditable}
                        model={model}
                        fluid
                        formValue={sectionData}
                        onSubmit={submitForm}
                        onChange={data => setSectionData(data)}
                    >
                        <EducationRecord
                            reloadGrid={reloadGrid}
                            applicationId={appId}
                            isReadOnly={!sectionData.isUserEditable}
                            openModal={openModal}
                            hideGrid={hideGrids}
                            handleMyNceesEducationChange={handleMyNceesEducationChange}
                        />
                        
                        <Issues isUserEditable={true} type={1} sectionId={sectionData.id} />
                        <FormActionButtons isReadOnly={!sectionData.isUserEditable} backToSectionLink="/individual/peApp/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default EducationPage;