import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Icon, Table } from 'rsuite';
import Swal from 'sweetalert2';
import GenericBadge from '../../../Shared/Badge/GenericBadge';
import Information from '../../../Shared/Card/Information';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import AddNewSupervisingProfessionalModal from './AddNewSupervisingProfessionalModal'
import { GetSupervisingProfessionalFirmInstructions } from '../../../Shared/InformationContent/GeneralInformation';
import supervisingProfessionalService from '../../../../services/supervisingProfessional.service';
import applicationService from '../../../../services/Public/Application/application.service';

function SupervisingProfessionalPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [showAddNewButton, setShowAddNewButton] = useState(false);
    const [supervisingProfessionals, setSupervisingProfessionals] = useState([]);
    const openModal = (values) => {
        setModalShow(true);
    }
    useEffect(() => {
        refreshGrid();
        setHasLoaded(true);
    }, [])
    const refreshGrid = () => {
        applicationService.getFirmPendingApplications().then((response) => {
            if (response.data?.creds.length > 0 || response.data?.apps.length > 0) {
                setShowAddNewButton(true);
            }
            supervisingProfessionalService.getFirmSupervisingProfessional().then((data) => {
                setSupervisingProfessionals(data.data);
            })
        })
    }
    const inactivateSupervisingProfessional = (values) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "By choosing to inactivate this supervising professional, this record will be removed from your supervising professional locker.  Would you like to continue?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setHasLoaded(false)
                supervisingProfessionalService.inactivateSupervisingProfessional(values.relationshipId, values.supervisingProfessionalId)
                    .then(() => { refreshGrid(); setHasLoaded(true) })
                    .catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } })
                    .finally(() => setHasLoaded(true));
                // Some service that removes it + refresh when it's good
            }
        })
    }
    const changeOwnership = (values) => {
        var text = values.isOwner ? "This will change the supervising professional to an employee of the firm." : "This will change the supervising professional to an owner of the firm.";
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setHasLoaded(false)
                supervisingProfessionalService.changeSupervisingProfessionalOwnership(values.relationshipId, values.supervisingProfessionalId)
                    .then(() => { refreshGrid(); setHasLoaded(true) })
                    .catch((error) => { if (error?.response?.data) { Swal.fire("Error!", error?.response?.data, 'error') } })
                    .finally(() => setHasLoaded(true));
            }
        })
    }
    return (
        <div>
            <Container fluid>
                <AddNewSupervisingProfessionalModal show={modalShow} onHide={() => setModalShow(false)} updateGrid={refreshGrid} />
                <h4 className="mb-3">Supervising Professional</h4>
                <Information content={GetSupervisingProfessionalFirmInstructions()} />
                <br />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <h6>Supervising Professional</h6>
                            </Col>
                            {showAddNewButton &&
                                <Col>
                                    <Button className="float-right" onClick={openModal}>
                                        <Icon icon="plus" /> Add New</Button>
                                </Col>
                            }
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {hasLoaded ?
                            <Table wordWrap autoHeight data={supervisingProfessionals}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Name
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.professionalFormattedName)}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        License #
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.professionalFormattedLicenseNumber)}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={2}>
                                    <Table.HeaderCell>
                                        SP Employee/Owner Status (click to update)
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {
                                            rowData => (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <label>
                                                        <b>Employee</b>
                                                    </label>
                                                    &nbsp;
                                                    <label className="switch">
                                                        {["Approved", "Pending", "Active"].includes(rowData.codeVerificationStatus?.verificationStatus) ?
                                                            <input checked={rowData.isOwner} onChange={() => { changeOwnership(rowData) }} type="checkbox" />
                                                            :
                                                            <input checked={rowData.isOwner} onChange={() => { changeOwnership(rowData) }} type="checkbox" disabled />
                                                        }
                                                        <span className="slider round"></span>
                                                    </label>
                                                    &nbsp;
                                                    <label>
                                                        <b>Owner</b>
                                                    </label>
                                                </div>
                                            )
                                        }
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Sent Date
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.sentDate ? new Date(rowData.sentDate).toLocaleDateString() : '')}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Response Date
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (rowData.responseDate ? new Date(rowData.responseDate).toLocaleDateString() : '')}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        Verification Status
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.codeVerificationStatus?.verificationStatus ? <GenericBadge status={rowData.codeVerificationStatus.verificationStatus.toUpperCase()} /> : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (
                                            <>
                                                <Button className="ml-1" variant="danger" onClick={() => inactivateSupervisingProfessional(rowData)}>Inactivate</Button>
                                            </>
                                        )}
                                    </Table.Cell>
                                </Table.Column>
                            </Table> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
export default SupervisingProfessionalPage