import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import dayjs from "dayjs";

const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = dayjs(latestDate);
    const momCurrentDateTime = dayjs(currentDate);

    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

        useEffect(() => {
            fetch("/meta.json?ms="+ Date.now())
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersionDate = meta.buildDate;
                    const currentVersionDate = packageJson.buildDate;
                    const shouldForceRefresh = buildDateGreaterThan(
                        latestVersionDate,
                        currentVersionDate
                    );
                    if (shouldForceRefresh) {
                        setIsLatestBuildDate(false);
                        refreshCacheAndReload();
                    } else {
                        setIsLatestBuildDate(true);
                    }
                    if (meta.offline)
                    {
                        window.location.href = "https://www.lapels.com/Sched_Maint.html";
                    }
                });
        }, []);

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            // delete browser cache and hard reload
            window.location.reload(true);
        };

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        );
    }

    return ClearCacheComponent;
}

export default withClearCache;