import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GenericBadge from '../Badge/GenericBadge';

function Section({ data }) {
    const badge = data.status ?
        <Col md={ 2 }>
            <div className="float-right badgeMargin">
                <GenericBadge status={data.status} />
                </div>
        </Col>
        :
        "";

    return (
        <div className="list-group-item list-group-item-action">
            <Row as={Link} to={data.link} className="sectionSpacing">
                <Col md={10}>
                    <h6>{data.sectionTitle}</h6>
                    <p>{data.sectionContent}</p>
                </Col>
                { badge }
            </Row>
        </div>
    )
}

export default Section;