import axios from "axios";
import authHeader from '../../../authheader'

const VFAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/FirmVfApplication";

const getUnlicensedActivity = (applicationId) => {
    return axios.get(`${VFAPPLICATIONURL}/unlicensedActivity`, { headers: authHeader(), params: { applicationId } });
}
const postUnlicensedActivity = (data) => {
    return axios.post(`${VFAPPLICATIONURL}/unlicensedActivity`, data, { headers: authHeader() });
}
export default {
    getUnlicensedActivity,
    postUnlicensedActivity
}