import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon, Table } from 'rsuite';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';

// requires gridData

function EntitySearchGrid(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [displayLength, setDisplayLength] = useState();
    const [page, setPage] = useState();

    var dispatch = useDispatch();
    const { push } = useHistory();
    useEffect(() => {
        setGridData(props.gridData)
        setHasLoaded(true);
        props.setIsSearchingCallback(false)
        const currentPageCache = JSON.parse(localStorage.getItem("currentPageCache"));
        if (currentPageCache)
            setPage(currentPageCache)
        else
            setPage(1)

        const displayLengthCache = JSON.parse(localStorage.getItem("displayLengthCache"));
        if (displayLengthCache)
            setDisplayLength(displayLengthCache)
        else
            setDisplayLength(20)


    }, [props.gridData])

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
        localStorage.setItem("currentPageCache", JSON.stringify(dataKey));
    }
    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
        localStorage.setItem("displayLengthCache", JSON.stringify(dataKey));
    }
    const navigateToEntity = (rowData) => {
        if (rowData.entityType.id == 1)
            push("/internal/indCredential?entityId=" + rowData.entityId);
        else if (rowData.entityType.id == 2)
            push("/internal/firmCredential?entityId=" + rowData.entityId);
        else
            Swal.fire("Something went wrong")
    }
    const getData = () => {
        return gridData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }

    const filteredData = getData();
    return (
        <div className="searchListing">
            <Card className="mb-3">
                <Card.Header><h6>Search Listing</h6></Card.Header>
                <Card.Body>
                    {hasLoaded ?
                        <>
                            <Table wordWrap autoHeight data={filteredData}>
                                <Table.Column width={100} fixed>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (
                                            <Button onClick={() => navigateToEntity(rowData)}>View</Button>
                                        )}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={100} flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>LOLA #</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.lolaNumber)}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={100} flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Credential #/Status</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.credentials.map((cred) => {
                                        if (cred.status?.status == "Upgraded" || (["Active", "Inactive", "Retired"].includes(cred.status?.status) && cred.statusReason == "Normal") || (cred.status?.status == cred.statusReason))
                                            return <>{cred.formattedLicenseNumber} - {cred.status?.status}<br /></>;
                                        else
                                            return <>{cred.formattedLicenseNumber} - {cred.status?.status} ({cred.statusReason})<br /></>;
                                    }))}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={125} flexGrow={.5}>
                                    <Table.HeaderCell>
                                        <b>Expiration Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.credentials.map((cred) => <>{cred.credentialType.prefix} - {cred.expirationDate}<br /></>))}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={100} flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Name</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.name)}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={100} flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Email</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.emailAddresses?.length > 0 ? (rowData.emailAddresses.map((email) => <>{email.emailAddressType.emailAddressType} - {email.contactEmailAddress} <br /></>)) : <></>)}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={100} flexGrow={.5}>
                                    <Table.HeaderCell>
                                        <b>SSN/FEIN</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.federalId)}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={125} flexGrow={.5}>
                                    <Table.HeaderCell>
                                        <b>Birthdate</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.dateOfBirth)}</Table.Cell>
                                </Table.Column>
                                <Table.Column minWidth={100} flexGrow={.5}>
                                    <Table.HeaderCell>
                                        <b>NCEES</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.nceesNumber)}</Table.Cell>
                                </Table.Column>
                            </Table>
                            <Table.Pagination lengthMenu={[
                                {
                                    value: 10,
                                    label: 10
                                },
                                {
                                    value: 20,
                                    label: 20
                                },
                                {
                                    value: 50,
                                    label: 50
                                },
                                {
                                    value: gridData.length,
                                    label: "All"
                                }
                            ]}
                                activePage={page}
                                displayLength={displayLength}
                                total={gridData.length}
                                onChangePage={handleChangePage}
                                onChangeLength={handleChangeLength} />
                        </> : <LoadingSpinner />
                    }
                </Card.Body>
            </Card>
        </div>
    )
}



export default EntitySearchGrid;