import faker from 'faker'

//this is needed for the login page
export const fetchUsers = async () => ([...new Array(1000)].map(() => ({
    id: faker.random.uuid(),
    username: faker.internet.userName(),
    name: `${faker.name.firstName()} ${faker.name.lastName()}`
})));

export const authenticateResponse = async () => ({
    id: 1,
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    userName: faker.internet.userName(),
    token: "auth token"
})

export const login = async (username, password) => ({
    message: null,
    isSuccess: true,
    errors: null
})


export const address = async () => ({
    id: faker.random.uuid(),
    streetAddress: faker.address.streetAddress()
})

export const getSections = async () => ([
    {
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
        status: "2 Issues",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
        status: "Complete",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Personal References",
        sectionContent: "Complete this section listing all personal references.",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Employment (By Comity ONLY)",
        sectionContent: "Verify Louisiana Employment Firm licensure",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Quizzes",
        sectionContent: "Quiz content here",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Experience",
        sectionContent: "Total experience record summary",
        link: faker.internet.url()
    },
    {
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
        link: faker.internet.url()
    }
])
export const getPlsAppSections = async () => ([
    {
        sectionTitle: "Contact Info",
        sectionContent: "Complete this section providing all required contact information.",
        link: "/individual/plsApp/contactInformation"
    },
    {
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
        link: "/individual/plsApp/educationRecord"
    },
    {
        sectionTitle: "Personal References",
        sectionContent: "Complete this section listing all personal references.",
        link: "/individual/plsApp/personalReferences"
    },
    {
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
        link: "/individual/plsApp/violations"
    },
    {
        sectionTitle: "Employment",
        sectionContent: "Verify Louisiana Employment Firm licensure.",
        link: "/individual/plsApp/employment"
    },
    {
        sectionTitle: "Quizzes",
        sectionContent: "Quiz content here.",
        link: "/individual/plsApp/quizzes"
    },
    {
        sectionTitle: "Experience",
        sectionContent: "Total experience record summary.",
        link: "/individual/plsApp/experience"
    },
    {
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
        link: "/individual/plsApp/acknowledgement"
    },
    {
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
        link: "/individual/plsApp/review"
    }
])

export const getPlsReinstatementPersonalReferencesGridData = async () => ([...new Array(4)].map(() => {
    var isSameFirm = faker.random.boolean();
    return {
        id: faker.random.uuid(),
        name: `${faker.name.firstName()} ${faker.name.lastName()}`,
        address: faker.address.streetAddress(),
        city: faker.address.city(),
        country: faker.address.country(),
        state: faker.address.stateAbbr(),
        stateProvinceRegion: faker.address.stateAbbr(),
        postalCode: faker.address.zipCode(),
        email: faker.internet.email(),
        isSameFirm: isSameFirm,
        comments: isSameFirm ? '' : faker.lorem.paragraph(),
        modalType: "Edit",
        verificationStatus: faker.random.number() % 2 ? "pending" : faker.random.number() % 3 ? "verified" : "",
        firstOccasion: {
            firmName: isSameFirm ? faker.company.companyName() : '',
            dateRange: isSameFirm ? [faker.date.past(), faker.date.future()] : [],
            firmAddress: isSameFirm ? faker.address.streetAddress() : '',
            titleOfPosition: isSameFirm ? faker.name.jobTitle() : '',
            referencesPosition: isSameFirm ? faker.name.jobTitle() : '',
        },
        secondOccasion: {
            firmName: isSameFirm ? faker.company.companyName() : '',
            dateRange: isSameFirm ? [faker.date.past(), faker.date.future()] : [],
            firmAddress: isSameFirm ? faker.address.streetAddress() : '',
            titleOfPosition: isSameFirm ? faker.name.jobTitle() : '',
            referencesPosition: isSameFirm ? faker.name.jobTitle() : '',
        }
    }
}))
export const getPlsReinstatementPersonalReferenceModalData = async () => {
    var isSameFirm = faker.random.boolean();

    return {
        id: faker.random.uuid(),
        name: `${faker.name.firstName()} ${faker.name.lastName()}`,
        address: faker.address.streetAddress(),
        city: faker.address.city(),
        state: faker.address.stateAbbr(),
        stateProvinceRegion: faker.address.stateAbbr(),
        postalCode: faker.address.zipCode(),
        email: faker.internet.email(),
        isSameFirm: isSameFirm,
        comments: isSameFirm ? '' : faker.lorem.paragraph(),
        modalType: "Edit",
        firstOccasion: {
            firmName: isSameFirm ? faker.company.companyName() : '',
            dateRange: isSameFirm ? [faker.date.past(), faker.date.future()] : [],
            firmAddress: isSameFirm ? faker.address.streetAddress() : '',
            titleOfPosition: isSameFirm ? faker.name.jobTitle() : '',
            referencesPosition: isSameFirm ? faker.name.jobTitle() : '',
        },
        secondOccasion: {
            firmName: isSameFirm ? faker.company.companyName() : '',
            dateRange: isSameFirm ? [faker.date.past(), faker.date.future()] : [],
            firmAddress: isSameFirm ? faker.address.streetAddress() : '',
            titleOfPosition: isSameFirm ? faker.name.jobTitle() : '',
            referencesPosition: isSameFirm ? faker.name.jobTitle() : '',
        }
    }
}
export const getPlsReinstatementExperienceModalData = async () => (
    {
        id: faker.random.uuid(),
        title: faker.name.jobTitle(),
        employerName: `${faker.name.firstName()} ${faker.name.lastName()}`,
        employerAddress: faker.address.streetAddress(),
        location: `${faker.address.city()}, ${faker.address.stateAbbr()}`,
        description: faker.lorem.paragraph(),
        referenceInfo: faker.lorem.paragraph(),
        referenceReasoning: faker.lorem.paragraph(),
        employmentType: "Non-Engineering/Non-Surveying",
        dateRange: [faker.date.past(), faker.date.future()],
        modalType: "Edit"
    }
)

export const getPlsAppPersonalReferencesGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    currentAddress: faker.address.streetAddress(),
    cityState: `${faker.address.city()}, ${faker.address.stateAbbr()}`,
    zip: faker.address.zipCode(),
    email: faker.internet.email(),
    verificationStatus: faker.random.number() % 2 ? "pending" : faker.random.number() % 3 ? "verified" : ""
})))

export const getPlsReinstatementCoursesGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    dateEarned: faker.date.past().toLocaleDateString(),
    sponsor: faker.company.companyName(),
    descriptionOfActivity: faker.lorem.sentence(),
    type: faker.random.number() % 6,
    documentationReceived: "Certificate",
    hours: faker.random.number(),
    files: faker.system.fileName()
})))

export const getPlsReinstatementExperienceGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    title: faker.name.jobTitle(),
    employerName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    referenceAddress: faker.address.streetAddress(),
    referenceCountry: faker.address.country(),
    referenceCity: faker.address.city(),
    referenceState: faker.address.stateAbbr(),
    referenceStateProvinceRegion: faker.address.stateAbbr(),
    referencePostalCode: faker.address.zipCode(),
    employerAddress: faker.address.streetAddress(),
    employerCountry: faker.address.country(),
    employerCity: faker.address.city(),
    employerState: faker.address.stateAbbr(),
    employerStateProvinceRegion: faker.address.stateAbbr(),
    employerPostalCode: faker.address.zipCode(),
    location: faker.address.city(),
    description: faker.lorem.paragraph(),
    referenceReasoning: faker.lorem.paragraph(),
    employmentType: employmentTypeData[faker.random.number() % 5].value,
    date: `${faker.date.past().toLocaleDateString()} - ${faker.date.future().toLocaleDateString()}`,
})))

export const getPlsAppEducationGridData = async () => ([...new Array(15)].map(() => (
    {
        id: faker.random.uuid(),
        institution: `${faker.address.state()} State University`,
        state: faker.address.state(),
        date: `${faker.date.past().toLocaleDateString()} - ${faker.date.future().toLocaleDateString()}`,
        gradDate: faker.date.past().toLocaleDateString(),
        major: faker.address.city(),
        degree: degreeTypeData[faker.random.number() % 5].value,
        graduated: faker.random.boolean(),
    })))


export const getPeReinstatementPersonalReferencesGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    email: faker.internet.email(),
    country: faker.address.country(),
    address: faker.address.streetAddress(),
    city: faker.address.city(),
    state: faker.address.stateAbbr(),
    stateProvinceRegion: faker.address.stateAbbr(),
    postalCode: faker.address.zipCode(),
    verificationStatus: faker.random.number() % 2 ? "pending" : faker.random.number() % 3 ? "verified" : ""
})))

export const getPeAppPersonalReferencesGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    currentAddress: faker.address.streetAddress(),
    cityState: `${faker.address.city()}, ${faker.address.stateAbbr()}`,
    zip: faker.address.zipCode(),
    email: faker.internet.email(),
    verificationStatus: faker.random.number() % 2 ? "pending" : faker.random.number() % 3 ? "verified" : ""
})))

export const getPeReinstatementCoursesGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    dateEarned: faker.date.past().toLocaleDateString(),
    sponsor: faker.company.companyName(),
    descriptionOfActivity: faker.lorem.sentence(),
    type: faker.name.jobType(),
    documentationReceived: "Certificate",
    hours: faker.random.number(),
    files: faker.system.fileName()
})))

export const getRevenuePendingBatchGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    batchId: faker.random.uuid(),
    user: `${faker.name.firstName()} ${faker.name.lastName()}`,
    count: faker.random.number(),
    amount: faker.random.number(),
    batchDate: `${faker.date.past().toLocaleDateString()}`
})))

export const loremParagraphs = async () => (faker.lorem.paragraphs())

export const countryData = ([
    {
        "label": "Afghanistan", "value": {
            "id": 3,
            "code": "AF",
            "country": "Afghanistan"
        }
    },
    { "label": "Åland Islands", "value": "Åland Islands" },
    { "label": "Albania", "value": "Albania" },
    { "label": "Algeria", "value": "Algeria" },
    { "label": "American Samoa", "value": "American Samoa" },
    { "label": "Andorra", "value": "Andorra" },
    { "label": "Angola", "value": "Angola" },
    { "label": "Anguilla", "value": "Anguilla" },
    { "label": "Antarctica", "value": "Antarctica" },
    { "label": "Antigua and Barbuda", "value": "Antigua and Barbuda" },
    { "label": "Argentina", "value": "Argentina" },
    { "label": "Armenia", "value": "Armenia" },
    { "label": "Aruba", "value": "Aruba" },
    { "label": "Australia", "value": "Australia" },
    { "label": "Austria", "value": "Austria" },
    { "label": "Azerbaijan", "value": "Azerbaijan" },
    { "label": "Bahamas", "value": "Bahamas" },
    { "label": "Bahrain", "value": "Bahrain" },
    { "label": "Bangladesh", "value": "Bangladesh" },
    { "label": "Barbados", "value": "Barbados" },
    { "label": "Belarus", "value": "Belarus" },
    { "label": "Belgium", "value": "Belgium" },
    { "label": "Belize", "value": "Belize" },
    { "label": "Benin", "value": "Benin" },
    { "label": "Bermuda", "value": "Bermuda" },
    { "label": "Bhutan", "value": "Bhutan" },
    { "label": "Bolivia", "value": "Bolivia" },
    { "label": "Bosnia and Herzegovina", "value": "Bosnia and Herzegovina" },
    { "label": "Botswana", "value": "Botswana" },
    { "label": "Bouvet Island", "value": "Bouvet Island" },
    { "label": "Brazil", "value": "Brazil" },
    { "label": "British Indian Ocean Territory", "value": "British Indian Ocean Territory" },
    { "label": "Brunei Darussalam", "value": "Brunei Darussalam" },
    { "label": "Bulgaria", "value": "Bulgaria" },
    { "label": "Burkina Faso", "value": "Burkina Faso" },
    { "label": "Burundi", "value": "Burundi" },
    { "label": "Cambodia", "value": "Cambodia" },
    { "label": "Cameroon", "value": "Cameroon" },
    { "label": "Canada", "value": "Canada" },
    { "label": "Cape Verde", "value": "Cape Verde" },
    { "label": "Cayman Islands", "value": "Cayman Islands" },
    { "label": "Central African Republic", "value": "Central African Republic" },
    { "label": "Chad", "value": "Chad" },
    { "label": "Chile", "value": "Chile" },
    { "label": "China", "value": "China" },
    { "label": "Christmas Island", "value": "Christmas Island" },
    { "label": "Cocos (Keeling) Islands", "value": "Cocos (Keeling) Islands" },
    { "label": "Colombia", "value": "Colombia" },
    { "label": "Comoros", "value": "Comoros" },
    { "label": "Congo", "value": "Congo" },
    { "label": "Congo, The Democratic Republic of The", "value": "Congo, The Democratic Republic of The" },
    { "label": "Cook Islands", "value": "Cook Islands" },
    { "label": "Costa Rica", "value": "Costa Rica" },
    { "label": "Cote D'ivoire", "value": "Cote D'ivoire" },
    { "label": "Croatia", "value": "Croatia" },
    { "label": "Cuba", "value": "Cuba" },
    { "label": "Cyprus", "value": "Cyprus" },
    { "label": "Czech Republic", "value": "Czech Republic" },
    { "label": "Denmark", "value": "Denmark" },
    { "label": "Djibouti", "value": "Djibouti" },
    { "label": "Dominica", "value": "Dominica" },
    { "label": "Dominican Republic", "value": "Dominican Republic" },
    { "label": "Ecuador", "value": "Ecuador" },
    { "label": "Egypt", "value": "Egypt" },
    { "label": "El Salvador", "value": "El Salvador" },
    { "label": "Equatorial Guinea", "value": "Equatorial Guinea" },
    { "label": "Eritrea", "value": "Eritrea" },
    { "label": "Estonia", "value": "Estonia" },
    { "label": "Ethiopia", "value": "Ethiopia" },
    { "label": "Falkland Islands (Malvinas)", "value": "Falkland Islands (Malvinas)" },
    { "label": "Faroe Islands", "value": "Faroe Islands" },
    { "label": "Fiji", "value": "Fiji" },
    { "label": "Finland", "value": "Finland" },
    { "label": "France", "value": "France" },
    { "label": "French Guiana", "value": "French Guiana" },
    { "label": "French Polynesia", "value": "French Polynesia" },
    { "label": "French Southern Territories", "value": "French Southern Territories" },
    { "label": "Gabon", "value": "Gabon" },
    { "label": "Gambia", "value": "Gambia" },
    { "label": "Georgia", "value": "Georgia" },
    { "label": "Germany", "value": "Germany" },
    { "label": "Ghana", "value": "Ghana" },
    { "label": "Gibraltar", "value": "Gibraltar" },
    { "label": "Greece", "value": "Greece" },
    { "label": "Greenland", "value": "Greenland" },
    { "label": "Grenada", "value": "Grenada" },
    { "label": "Guadeloupe", "value": "Guadeloupe" },
    { "label": "Guam", "value": "Guam" },
    { "label": "Guatemala", "value": "Guatemala" },
    { "label": "Guernsey", "value": "Guernsey" },
    { "label": "Guinea", "value": "Guinea" },
    { "label": "Guinea-bissau", "value": "Guinea-bissau" },
    { "label": "Guyana", "value": "Guyana" },
    { "label": "Haiti", "value": "Haiti" },
    { "label": "Heard Island and Mcdonald Islands", "value": "Heard Island and Mcdonald Islands" },
    { "label": "Holy See (Vatican City State)", "value": "Holy See (Vatican City State)" },
    { "label": "Honduras", "value": "Honduras" },
    { "label": "Hong Kong", "value": "Hong Kong" },
    { "label": "Hungary", "value": "Hungary" },
    { "label": "Iceland", "value": "Iceland" },
    { "label": "India", "value": "India" },
    { "label": "Indonesia", "value": "Indonesia" },
    { "label": "Iran, Islamic Republic of", "value": "Iran, Islamic Republic of" },
    { "label": "Iraq", "value": "Iraq" },
    { "label": "Ireland", "value": "Ireland" },
    { "label": "Isle of Man", "value": "Isle of Man" },
    { "label": "Israel", "value": "Israel" },
    { "label": "Italy", "value": "Italy" },
    { "label": "Jamaica", "value": "Jamaica" },
    { "label": "Japan", "value": "Japan" },
    { "label": "Jersey", "value": "Jersey" },
    { "label": "Jordan", "value": "Jordan" },
    { "label": "Kazakhstan", "value": "Kazakhstan" },
    { "label": "Kenya", "value": "Kenya" },
    { "label": "Kiribati", "value": "Kiribati" },
    { "label": "Korea, Democratic People's Republic of", "value": "Korea, Democratic People's Republic of" },
    { "label": "Korea, Republic of", "value": "Korea, Republic of" },
    { "label": "Kuwait", "value": "Kuwait" },
    { "label": "Kyrgyzstan", "value": "Kyrgyzstan" },
    { "label": "Lao People's Democratic Republic", "value": "Lao People's Democratic Republic" },
    { "label": "Latvia", "value": "Latvia" },
    { "label": "Lebanon", "value": "Lebanon" },
    { "label": "Lesotho", "value": "Lesotho" },
    { "label": "Liberia", "value": "Liberia" },
    { "label": "Libyan Arab Jamahiriya", "value": "Libyan Arab Jamahiriya" },
    { "label": "Liechtenstein", "value": "Liechtenstein" },
    { "label": "Lithuania", "value": "Lithuania" },
    { "label": "Luxembourg", "value": "Luxembourg" },
    { "label": "Macao", "value": "Macao" },
    { "label": "Macedonia, The Former Yugoslav Republic of", "value": "Macedonia, The Former Yugoslav Republic of" },
    { "label": "Madagascar", "value": "Madagascar" },
    { "label": "Malawi", "value": "Malawi" },
    { "label": "Malaysia", "value": "Malaysia" },
    { "label": "Maldives", "value": "Maldives" },
    { "label": "Mali", "value": "Mali" },
    { "label": "Malta", "value": "Malta" },
    { "label": "Marshall Islands", "value": "Marshall Islands" },
    { "label": "Martinique", "value": "Martinique" },
    { "label": "Mauritania", "value": "Mauritania" },
    { "label": "Mauritius", "value": "Mauritius" },
    { "label": "Mayotte", "value": "Mayotte" },
    { "label": "Mexico", "value": "Mexico" },
    { "label": "Micronesia, Federated States of", "value": "Micronesia, Federated States of" },
    { "label": "Moldova, Republic of", "value": "Moldova, Republic of" },
    { "label": "Monaco", "value": "Monaco" },
    { "label": "Mongolia", "value": "Mongolia" },
    { "label": "Montenegro", "value": "Montenegro" },
    { "label": "Montserrat", "value": "Montserrat" },
    { "label": "Morocco", "value": "Morocco" },
    { "label": "Mozambique", "value": "Mozambique" },
    { "label": "Myanmar", "value": "Myanmar" },
    { "label": "Namibia", "value": "Namibia" },
    { "label": "Nauru", "value": "Nauru" },
    { "label": "Nepal", "value": "Nepal" },
    { "label": "Netherlands", "value": "Netherlands" },
    { "label": "Netherlands Antilles", "value": "Netherlands Antilles" },
    { "label": "New Caledonia", "value": "New Caledonia" },
    { "label": "New Zealand", "value": "New Zealand" },
    { "label": "Nicaragua", "value": "Nicaragua" },
    { "label": "Niger", "value": "Niger" },
    { "label": "Nigeria", "value": "Nigeria" },
    { "label": "Niue", "value": "Niue" },
    { "label": "Norfolk Island", "value": "Norfolk Island" },
    { "label": "Northern Mariana Islands", "value": "Northern Mariana Islands" },
    { "label": "Norway", "value": "Norway" },
    { "label": "Oman", "value": "Oman" },
    { "label": "Pakistan", "value": "Pakistan" },
    { "label": "Palau", "value": "Palau" },
    { "label": "Palestinian Territory, Occupied", "value": "Palestinian Territory, Occupied" },
    { "label": "Panama", "value": "Panama" },
    { "label": "Papua New Guinea", "value": "Papua New Guinea" },
    { "label": "Paraguay", "value": "Paraguay" },
    { "label": "Peru", "value": "Peru" },
    { "label": "Philippines", "value": "Philippines" },
    { "label": "Pitcairn", "value": "Pitcairn" },
    { "label": "Poland", "value": "Poland" },
    { "label": "Portugal", "value": "Portugal" },
    { "label": "Puerto Rico", "value": "Puerto Rico" },
    { "label": "Qatar", "value": "Qatar" },
    { "label": "Reunion", "value": "Reunion" },
    { "label": "Romania", "value": "Romania" },
    { "label": "Russian Federation", "value": "Russian Federation" },
    { "label": "Rwanda", "value": "Rwanda" },
    { "label": "Saint Helena", "value": "Saint Helena" },
    { "label": "Saint Kitts and Nevis", "value": "Saint Kitts and Nevis" },
    { "label": "Saint Lucia", "value": "Saint Lucia" },
    { "label": "Saint Pierre and Miquelon", "value": "Saint Pierre and Miquelon" },
    { "label": "Saint Vincent and The Grenadines", "value": "Saint Vincent and The Grenadines" },
    { "label": "Samoa", "value": "Samoa" },
    { "label": "San Marino", "value": "San Marino" },
    { "label": "Sao Tome and Principe", "value": "Sao Tome and Principe" },
    { "label": "Saudi Arabia", "value": "Saudi Arabia" },
    { "label": "Senegal", "value": "Senegal" },
    { "label": "Serbia", "value": "Serbia" },
    { "label": "Seychelles", "value": "Seychelles" },
    { "label": "Sierra Leone", "value": "Sierra Leone" },
    { "label": "Singapore", "value": "Singapore" },
    { "label": "Slovakia", "value": "Slovakia" },
    { "label": "Slovenia", "value": "Slovenia" },
    { "label": "Solomon Islands", "value": "Solomon Islands" },
    { "label": "Somalia", "value": "Somalia" },
    { "label": "South Africa", "value": "South Africa" },
    { "label": "South Georgia and The South Sandwich Islands", "value": "South Georgia and The South Sandwich Islands" },
    { "label": "Spain", "value": "Spain" },
    { "label": "Sri Lanka", "value": "Sri Lanka" },
    { "label": "Sudan", "value": "Sudan" },
    { "label": "Suriname", "value": "Suriname" },
    { "label": "Svalbard and Jan Mayen", "value": "Svalbard and Jan Mayen" },
    { "label": "Swaziland", "value": "Swaziland" },
    { "label": "Sweden", "value": "Sweden" },
    { "label": "Switzerland", "value": "Switzerland" },
    { "label": "Syrian Arab Republic", "value": "Syrian Arab Republic" },
    { "label": "Taiwan, Province of China", "value": "Taiwan, Province of China" },
    { "label": "Tajikistan", "value": "Tajikistan" },
    { "label": "Tanzania, United Republic of", "value": "Tanzania, United Republic of" },
    { "label": "Thailand", "value": "Thailand" },
    { "label": "Timor-leste", "value": "Timor-leste" },
    { "label": "Togo", "value": "Togo" },
    { "label": "Tokelau", "value": "Tokelau" },
    { "label": "Tonga", "value": "Tonga" },
    { "label": "Trinidad and Tobago", "value": "Trinidad and Tobago" },
    { "label": "Tunisia", "value": "Tunisia" },
    { "label": "Turkey", "value": "Turkey" },
    { "label": "Turkmenistan", "value": "Turkmenistan" },
    { "label": "Turks and Caicos Islands", "value": "Turks and Caicos Islands" },
    { "label": "Tuvalu", "value": "Tuvalu" },
    { "label": "Uganda", "value": "Uganda" },
    { "label": "Ukraine", "value": "Ukraine" },
    { "label": "United Arab Emirates", "value": "United Arab Emirates" },
    { "label": "United Kingdom", "value": "United Kingdom" },
    { "label": "United States", "value": "United States" },
    { "label": "United States Minor Outlying Islands", "value": "United States Minor Outlying Islands" },
    { "label": "Uruguay", "value": "Uruguay" },
    { "label": "Uzbekistan", "value": "Uzbekistan" },
    { "label": "Vanuatu", "value": "Vanuatu" },
    { "label": "Venezuela", "value": "Venezuela" },
    { "label": "Viet Nam", "value": "Viet Nam" },
    { "label": "Virgin Islands, British", "value": "Virgin Islands, British" },
    { "label": "Virgin Islands, U.S.", "value": "Virgin Islands, U.S." },
    { "label": "Wallis and Futuna", "value": "Wallis and Futuna" },
    { "label": "Western Sahara", "value": "Western Sahara" },
    { "label": "Yemen", "value": "Yemen" },
    { "label": "Zambia", "value": "Zambia" },
    { "label": "Zimbabwe", "value": "Zimbabwe" }
]);

export const stateData = (
    [
        { "label": "Alabama", "value": "AL" },
        { "label": "Alaska", "value": "Alaska" },
        { "label": "Arizona", "value": "Arizona" },
        { "label": "Arkansas", "value": "Arkansas" },
        { "label": "California", "value": "California" },
        { "label": "Colorado", "value": "Colorado" },
        { "label": "Connecticut", "value": "Connecticut" },
        { "label": "Delaware", "value": "Delaware" },
        { "label": "District Of Columbia", "value": "District Of Columbia" },
        { "label": "Florida", "value": "Florida" },
        { "label": "Georgia", "value": "Georgia" },
        { "label": "Hawaii", "value": "Hawaii" },
        { "label": "Idaho", "value": "Idaho" },
        { "label": "Illinois", "value": "Illinois" },
        { "label": "Indiana", "value": "Indiana" },
        { "label": "Iowa", "value": "Iowa" },
        { "label": "Kansas", "value": "Kansas" },
        { "label": "Kentucky", "value": "Kentucky" },
        { "label": "Louisiana", "value": "Louisiana" },
        { "label": "Maine", "value": "Maine" },
        { "label": "Maryland", "value": "Maryland" },
        { "label": "Massachusetts", "value": "Massachusetts" },
        { "label": "Michigan", "value": "Michigan" },
        { "label": "Minnesota", "value": "Minnesota" },
        { "label": "Mississippi", "value": "Mississippi" },
        { "label": "Missouri", "value": "Missouri" },
        { "label": "Montana", "value": "Montana" },
        { "label": "Nebraska", "value": "Nebraska" },
        { "label": "Nevada", "value": "Nevada" },
        { "label": "New Hampshire", "value": "New Hampshire" },
        { "label": "New Jersey", "value": "New Jersey" },
        { "label": "New Mexico", "value": "New Mexico" },
        { "label": "New York", "value": "New York" },
        { "label": "North Carolina", "value": "North Carolina" },
        { "label": "North Dakota", "value": "North Dakota" },
        { "label": "Ohio", "value": "Ohio" },
        { "label": "Oklahoma", "value": "Oklahoma" },
        { "label": "Oregon", "value": "Oregon" },
        { "label": "Pennsylvania", "value": "Pennsylvania" },
        { "label": "Rhode Island", "value": "Rhode Island" },
        { "label": "South Carolina", "value": "South Carolina" },
        { "label": "South Dakota", "value": "South Dakota" },
        { "label": "Tennessee", "value": "Tennessee" },
        { "label": "Texas", "value": "Texas" },
        { "label": "Utah", "value": "Utah" },
        { "label": "Vermont", "value": "Vermont" },
        { "label": "Virginia", "value": "Virginia" },
        { "label": "Washington", "value": "Washington" },
        { "label": "West Virginia", "value": "West Virginia" },
        { "label": "Wisconsin", "value": "Wisconsin" },
        { "label": "Wyoming", "value": "Wyoming" },
    ]);

export const plsReinstatementPersonalInformationTitleData = ([
    { "label": "Mr.", "value": "Mr." },
    { "label": "Mrs.", "value": "Mrs." },
    { "label": "Ms.", "value": "Ms." },
    { "label": "Dr.", "value": "Dr." }
]);
export const titleData = ([
    { "label": "Mr.", "value": "Mr." },
    { "label": "Mrs.", "value": "Mrs." },
    { "label": "Ms.", "value": "Ms." },
    { "label": "Dr.", "value": "Dr." }
]);
export const suffixData = ([
    { "label": "D.Eng", "value": "D.Eng" },
    { "label": "II", "value": "II" },
    { "label": "II, Ph.D.", "value": "II, Ph.D." },
    { "label": "III", "value": "III" },
    { "label": "III, Ph.D.", "value": "III, Ph.D." },
    { "label": "IV", "value": "IV" },
    { "label": "IV, Ph.D.", "value": "IV, Ph.D." },
    { "label": "Jr.", "value": "Jr." },
    { "label": "Jr., Ph.D.", "value": "Jr., Ph.D." },
    { "label": "Ph.D.", "value": "Ph.D." },
    { "label": "Sr.", "value": "Sr." },
    { "label": "Sr., Ph.D.", "value": "Sr., Ph.D." },
]);

export const yesNoData = ([
    { "label": "Yes", "value": true },
    { "label": "No", "value": false }
])

export const plsReinstatementPersonalInformationSuffixData = ([
    { "label": "D.Eng", "value": "D.Eng" },
    { "label": "II", "value": "II" },
    { "label": "II, Ph.D.", "value": "II, Ph.D." },
    { "label": "III", "value": "III" },
    { "label": "III, Ph.D.", "value": "III, Ph.D." },
    { "label": "IV", "value": "IV" },
    { "label": "IV, Ph.D.", "value": "IV, Ph.D." },
    { "label": "Jr.", "value": "Jr." },
    { "label": "Jr., Ph.D.", "value": "Jr., Ph.D." },
    { "label": "Ph.D.", "value": "Ph.D." },
    { "label": "Sr.", "value": "Sr." },
    { "label": "Sr., Ph.D.", "value": "Sr., Ph.D." },
]);
export const employmentTypeData = ([
    { "label": "Non-Engineering/Non-Surveying", "value": "Non-Engineering/Non-Surveying" },
    { "label": "Engineering/Surveying Experience Prior to FE/FS Exam", "value": "Engineering/Surveying Experience Prior to FE/FS Exam" },
    { "label": "Engineering/Surveying Subsequent to FE/FS Exam and Prior to PE/PS Exam", "value": "Engineering/Surveying Subsequent to FE/FS Exam and Prior to PE/PS Exam" },
    { "label": "Engineering/Surveying Subsequent to PE/PS Exam", "value": "Engineering/Surveying Subsequent to PE/PS Exam" },
    { "label": "Academic Engagement", "value": "Academic Engagement" },

])
export const degreeTypeData = ([
    { "label": "Associate's Degree", "value": "Associate's Degree" },
    { "label": "Bachelor's Degree", "value": "Bachelor's Degree" },
    { "label": "Master's Degree", "value": "Master's Degree" },
    { "label": "Doctoral Degree", "value": "Doctoral Degree" },
    { "label": "Non Degree", "value": "Non Degree" },

])
export const plsRenewalLicenseStatuses = ([
    { "label": "Active", "value": "Active" },
    { "label": "Active - New License", "value": "Active - New License" },
    { "label": "Inactive", "value": "Inactive" },
])

export const getInternalDashboardPendingTasksGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    invoiceNum: `${2020}-${faker.random.number() % 999}`,
    applicant: faker.name.findName(),
    applicationType: faker.random.boolean() ? "Engineering Intern" : "Professional Engineer",
    dateCreated: faker.date.past().toLocaleDateString(),
    dateSubmitted: faker.date.past().toLocaleDateString(),
    status: faker.random.boolean() ? "In Review" : "Pending"
})))
export const getInternalMyPendingTasksGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    invoiceNum: `${2020}-${faker.random.number() % 999}`,
    applicant: faker.name.findName(),
    applicationType: faker.random.boolean() ? "Engineering Intern" : "Professional Engineer",
    dateCreated: faker.date.past().toLocaleDateString(),
    dateSubmitted: faker.date.past().toLocaleDateString(),
    status: "Pending"
})))
export const getInternalClosedTasksGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    invoiceNum: `${2020}-${faker.random.number() % 999}`,
    applicant: faker.name.findName(),
    applicationType: faker.random.boolean() ? "Engineering Intern" : "Professional Engineer",
    dateCreated: faker.date.past().toLocaleDateString(),
    dateSubmitted: faker.date.past().toLocaleDateString(),
    status: "Closed"
})))
export const getInternalUnassignedTasksGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    invoiceNum: `${2020}-${faker.random.number() % 999}`,
    applicant: faker.name.findName(),
    applicationType: faker.random.boolean() ? "Engineering Intern" : "Professional Engineer",
    dateCreated: faker.date.past().toLocaleDateString(),
    dateSubmitted: faker.date.past().toLocaleDateString(),
    status: "Pending"
})))
export const getEmployeeList = async () => ([...new Array(15)].map(() => (
    faker.name.findName()
)))
export const getInternalAllTasksGridData = async () => ([...new Array(15)].map(() => ({
    id: faker.random.uuid(),
    invoiceNum: `${2020}-${faker.random.number() % 999}`,
    applicant: faker.name.findName(),
    applicationType: faker.random.boolean() ? "Engineering Intern" : "Professional Engineer",
    dateCreated: faker.date.past().toLocaleDateString(),
    dateSubmitted: faker.date.past().toLocaleDateString(),
    status: "Pending"
})))
export const getInsititutions = ([
    { "label": "Louisiana State University", "value": "Louisiana State University" },
    { "label": "Xavier University", "value": "Xavier University" },
    { "label": "Harvard Community College", "value": "Harvard Community College" },
    { "label": "Loyola University", "value": "Loyola University" },
    { "label": "Tulane University", "value": "Tulane University" }
])