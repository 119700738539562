import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import LicenseStatus from './LicenseStatus';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import licenseStatusService from '../../../../../services/Internal/Renewal/PLS/licenseStatus.service';
import renewalService from '../../../../../services/Internal/Renewal/renewal.service';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';
import { getRenewingintolist } from '../../../../../services/Shared/codeTable.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function LicenseStatusPage() {
    const { push } = useHistory();
    const renewalId = useSelector(x => x.currentApplicationId);
    const sectionId = 2;
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const routes = useSelector(x => x.currentRoutes);
    const [sectionData, setSectionData] = useState({});
    const [plsRenewalLicenseStatuses, setPlsRenewalLicenseStatuses] = useState([]);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "License Status"
        }
    );

    const [formModel, setFormModel] = useState({
        gridData: [],
        renewingTo: ""
    })
    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        renewingTo: ObjectType().isRequired("This field is required.")
    });

    useEffect(() => {
        getRenewingintolist(renewalId).then(setPlsRenewalLicenseStatuses)
        renewalService.plsGetSection(renewalId, sectionId).then((data) => {
            setSectionData(data.data);
            setIsComplete(data.data.staffReviewComplete)
            renewalService.getIndividualRenewal(renewalId).then((renewalData) => {
                setIsUserEditable(renewalData.data.status.id == 2)
                entityService.getEntity(data.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: renewalData.data?.credential?.formattedLicenseNumber,
                            applicationDate: renewalData.data?.dateSubmitted
                        })
                });
            });
            licenseStatusService.getLicenseStatus(renewalId).then((response) => {
                setFormModel({
                    gridData: [{ credential: response.data?.credential?.formattedLicenseNumber, credentialStatus: response.data?.credential?.status?.status }],
                    renewingTo: response.data.renewingTo ? { id: response.data.renewingTo.id, code: response.data.renewingTo.renewingTo } : null
                })
                setHasLoaded(true);
            })
        })
    }, [])

    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }
    }

    const submitData = (isForSubmit) => {
        licenseStatusService.postLicenseStatus(renewalId, formModel.renewingTo.id).then((response) => {
            if (response.data) {
                renewalService.plsMarkSectionComplete(renewalId, sectionId, isComplete).then((data) => {
                    if (isForSubmit)
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    else
                        Swal.fire("Saved!")
                })
            }
        })
    }

    return (
        <div className="plsRenewalLicenseStatusPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetRenewalInformationContent("licenseStatus")} />
                <br />
                {hasLoaded ?
                    <Form model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <LicenseStatus isReadOnly={!isUserEditable} plsRenewalLicenseStatuses={plsRenewalLicenseStatuses} gridData={formModel.gridData} />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            backToSectionLink="/internal/plsRenewal/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default LicenseStatusPage;