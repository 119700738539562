import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Address from '../../GenericComponents/Address';
function MailingAddress({ isReadOnly, boundValues, isUserEditable, contactInfoLink}) {
    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col lg={6}>
                        <h6>Verify Mailing Address</h6>
                    </Col>
                    
                </Row>
                
            </Card.Header>
            <Card.Body>
                <Address
                    boundValues={boundValues}
                    readOnly={isReadOnly}
                />

            </Card.Body>
        </Card>

    );
}
export default MailingAddress;