import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeReinstatement";

const getPersonalReferences = (reinstatementId) => {
    return axios.get(`${PEAPPLICATIONURL}/personalreferences`, { headers: authHeader(), params: { reinstatementId } });
}
const postPersonalReference = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/personalreference`, data, { headers: authHeader() });
}
const sendPersonalReferenceEmail = (referenceId) => {
    return axios.post(`${PEAPPLICATIONURL}/sendreferenceemail`, null, { headers: authHeader(), params: { referenceId } });
}

const downloadPersonalReferences = (reinstatementId, credType) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/Reports/GetReinstatementPersonalReferences`, { headers: authHeader(), params: { reinstatementId: reinstatementId, credType:credType }, responseType: 'arraybuffer' });
}

export default {
    getPersonalReferences,
    postPersonalReference,
    sendPersonalReferenceEmail,
    downloadPersonalReferences
}