import React from 'react';
import RenderRoutes from "./renderroutes"
import DashboardPage from "./../Public/Firm/Dashboard/DashboardPage"
import ShoppingCartPage from "./../Public/Firm/ShoppingCart/ShoppingCartPage"
import PaymentSuccessPage from "./../Public/Firm/ShoppingCart/PaymentSuccessPage"
import PaymentFailedPage from "./../Public/Firm/ShoppingCart/PaymentFailedPage"
import PaymentErrorPage from "./../Public/Firm/ShoppingCart/PaymentErrorPage"
import ContactPage from "./../Public/Firm/Contact/ContactPage"
import Applications from '../Public/Firm/Applications/Applications';
import Renewals from '../Public/Firm/Renewal/Renewals';
import Reinstatements from '../Public/Firm/Reinstatement/Reinstatements';
import SupervisingProfessionalPage from '../Public/Firm/SupervisingProfessional/SupervisingProfessionalPage';
import InvoicePage from '../Public/Firm/ShoppingCart/InvoicePage';
import InvoiceHistoryPage from '../Shared/InvoiceHistory/InvoiceHistoryPage';
import RedirectToSection from '../Shared/Redirect/RedirectToSection';

//#region VF
//VF App
import VfAppSectionPage from '../Public/Firm/Applications/VF/Index/Index';
import VfAppContactInformationPage from '../Public/Firm/Applications/VF/ContactInformation/ContactInformationPage';
import VfAppSupervisingProfessionalPage from '../Public/Firm/Applications/VF/SupervisingProfessional/SupervisingProfessionalPage';
import VfAppUnlicensedActivityPage from '../Public/Firm/Applications/VF/UnlicensedActivity/UnlicensedActivityPage';
import VfAppViolationsPage from '../Public/Firm/Applications/VF/Violations/ViolationsPage';
import VfAppWaiverCertificatePage from '../Public/Firm/Applications/VF/WaiverCertificate/WaiverCertificatePage';
import VfAppAcknowledgementPage from '../Public/Firm/Applications/VF/Acknowledgement/AcknowledgementPage';
import VfAppReviewPage from '../Public/Firm/Applications/VF/Review/ReviewPage';

//VF Renewals
import VfRenewalSectionPage from '../Public/Firm/Renewal/VF/Index/Index';
import VfRenewalContactInformationPage from '../Public/Firm/Renewal/VF/ContactInformation/ContactInformationPage';
import VfRenewalViolationsPage from '../Public/Firm/Renewal/VF/Violations/ViolationsPage';
import VfRenewalSupervisingProfessionalPage from '../Public/Firm/Renewal/VF/SupervisingProfessional/SupervisingProfessionalPage';
import VfRenewalWaiverCertificatePage from '../Public/Firm/Renewal/VF/WaiverCertificate/WaiverCertificatePage';
import VfRenewalAcknowledgementPage from '../Public/Firm/Renewal/VF/Acknowledgement/AcknowledgementPage';
import VfRenewalReviewPage from '../Public/Firm/Renewal/VF/Review/ReviewPage';

//VF Reinstatements
import VfReinstatementSectionPage from '../Public/Firm/Reinstatement/VF/Index/Index';
import VfReinstatementContactInformationPage from '../Public/Firm/Reinstatement/VF/ContactInformation/ContactInformationPage';
import VfReinstatementSupervisingProfessionalPage from '../Public/Firm/Reinstatement/VF/SupervisingProfessional/SupervisingProfessionalPage';
import VfReinstatementUnlicensedActivityPage from '../Public/Firm/Reinstatement/VF/UnlicensedActivity/UnlicensedActivityPage';
import VfReinstatementViolationsPage from '../Public/Firm/Reinstatement/VF/Violations/ViolationsPage';
import VfReinstatementWaiverCertificatePage from '../Public/Firm/Reinstatement/VF/WaiverCertificate/WaiverCertificatePage';
import VfReinstatementAcknowledgementPage from '../Public/Firm/Reinstatement/VF/Acknowledgement/AcknowledgementPage';
import VfReinstatementReviewPage from '../Public/Firm/Reinstatement/VF/Review/ReviewPage';
//#endregion


//#region EF
//EF App
import EfAppSectionPage from '../Public/Firm/Applications/EF/Index/Index';
import EfAppContactInformationPage from '../Public/Firm/Applications/EF/ContactInformation/ContactInformationPage';
import EfAppSupervisingProfessionalPage from '../Public/Firm/Applications/EF/SupervisingProfessional/SupervisingProfessionalPage';
import EfAppUnlicensedActivityPage from '../Public/Firm/Applications/EF/UnlicensedActivity/UnlicensedActivityPage';
import EfAppViolationsPage from '../Public/Firm/Applications/EF/Violations/ViolationsPage';
import EfAppWaiverCertificatePage from '../Public/Firm/Applications/EF/WaiverCertificate/WaiverCertificatePage';
import EfAppAcknowledgementPage from '../Public/Firm/Applications/EF/Acknowledgement/AcknowledgementPage';
import EfAppReviewPage from '../Public/Firm/Applications/EF/Review/ReviewPage';

//EF Renewals
import EfRenewalSectionPage from '../Public/Firm/Renewal/EF/Index/Index';
import EfRenewalContactInformationPage from '../Public/Firm/Renewal/EF/ContactInformation/ContactInformationPage';
import EfRenewalSupervisingProfessionalPage from '../Public/Firm/Renewal/EF/SupervisingProfessional/SupervisingProfessionalPage';
import EfRenewalViolationsPage from '../Public/Firm/Renewal/EF/Violations/ViolationsPage';
import EfRenewalWaiverCertificatePage from '../Public/Firm/Renewal/EF/WaiverCertificate/WaiverCertificatePage';
import EfRenewalAcknowledgementPage from '../Public/Firm/Renewal/EF/Acknowledgement/AcknowledgementPage';
import EfRenewalReviewPage from '../Public/Firm/Renewal/EF/Review/ReviewPage';

//EF Reinstatements
import EfReinstatementSectionPage from '../Public/Firm/Reinstatement/EF/Index/Index';
import EfReinstatementContactInformationPage from '../Public/Firm/Reinstatement/EF/ContactInformation/ContactInformationPage';
import EfReinstatementSupervisingProfessionalPage from '../Public/Firm/Reinstatement/EF/SupervisingProfessional/SupervisingProfessionalPage';
import EfReinstatementUnlicensedActivityPage from '../Public/Firm/Reinstatement/EF/UnlicensedActivity/UnlicensedActivityPage';
import EfReinstatementViolationsPage from '../Public/Firm/Reinstatement/EF/Violations/ViolationsPage';
import EfReinstatementWaiverCertificatePage from '../Public/Firm/Reinstatement/EF/WaiverCertificate/WaiverCertificatePage';
import EfReinstatementAcknowledgementPage from '../Public/Firm/Reinstatement/EF/Acknowledgement/AcknowledgementPage';
import EfReinstatementReviewPage from '../Public/Firm/Reinstatement/EF/Review/ReviewPage';
import AskLolaPage from '../Shared/AskLola/AskLolaPage';
import UserProfilePage from '../Shared/UserProfile/UserProfilePage';
//#endregion

const FIRM_ROUTES = [{
    path: "/",
    key: "ROOT",
    exact: true,
    component: () => <DashboardPage />
}, {
    path: "/firm",
    key: "PUBLIC",
    component: RenderRoutes,
    routes: [
        {
            path: "/firm",
            key: "PUBLIC_ROOT",
            exact: true,
            component: () => <DashboardPage />
        },
        {
            path: "/firm/dashboard",
            key: "Dashboard",
            exact: true,
            component: () => <DashboardPage />
        },
        {
            path: "/firm/askLola",
            key: "FIRM_ASKLOLA",
            exact: true,
            component: () => <AskLolaPage isInternal={false} />
        },
        {
            path: "/firm/userProfile",
            key: "FIRM_USER_PROFILE",
            exact: true,
            component: () => <UserProfilePage />
        },
        {
            path: "/firm/redirectToSection",
            key: "FIRM_REDIRECT_TO_SECTION",
            exact: true,
            component: () => <RedirectToSection linkPrefix={"firm"} />
        },
        {
            path: "/firm/shoppingcart",
            key: "ShoppingCart",
            exact: true,
            component: () => <ShoppingCartPage />
        },
        {
            path: "/firm/shoppingcart/success",
            key: "ShoppingCart_Success",
            exact: true,
            component: () => <PaymentSuccessPage />
        },
        {
            path: "/firm/shoppingcart/failed",
            key: "ShoppingCart_Failed",
            exact: true,
            component: () => <PaymentFailedPage />
        },
        {
            path: "/firm/shoppingcart/error",
            key: "ShoppingCart_Error",
            exact: true,
            component: () => <PaymentErrorPage />
        },
        {
            path: "/firm/invoice",
            key: "Invoice",
            exact: true,
            component: () => <InvoicePage />
        },
        {
            path: "/firm/invoicehistory",
            key: "InvoiceHistory",
            exact: true,
            component: () => <InvoiceHistoryPage isIndividual={false} />
        },
        {
            path: "/firm/contactinformation",
            key: "PUBLIC_ROOT",
            exact: true,
            component: () => <ContactPage />
        },
        {
            path: "/firm/applications/",
            key: "PUBLIC_APPLICATIONS",
            exact: true,
            component: () => <Applications />
        },
        {
            path: "/firm/renewals/",
            key: "PUBLIC_RENEWALS",
            exact: true,
            component: () => <Renewals />
        },
        {
            path: "/firm/reinstatements/",
            key: "PUBLIC_REINSTATEMENTS",
            exact: true,
            component: () => <Reinstatements />
        },
        {
            path: "/firm/supervisingprofessional/",
            key: "PUBLIC_SUPERVISING_PROFESSIONAL",
            exact: true,
            component: () => <SupervisingProfessionalPage />
        },
        {
            path: "/firm/vfApp/sections",
            key: "PUBLIC_VF_APPLICATIONS_INDEX",
            exact: true,
            component: () => <VfAppSectionPage />
        },
        {
            path: "/firm/vfApp/contactInformation",
            key: "PUBLIC_VF_APPLICATIONS_CONTACTINFORMATION",
            exact: true,
            component: () => <VfAppContactInformationPage />
        },
        {
            path: "/firm/vfApp/waiver",
            key: "PUBLIC_VF_APPLICATIONS_WAIVERCERTIFICATE",
            exact: true,
            component: () => <VfAppWaiverCertificatePage />
        },
        {
            path: "/firm/vfApp/supervisingProfessional",
            key: "PUBLIC_VF_APPLICATIONS_SUPERVISINGPROFESSIONAL",
            exact: true,
            component: () => <VfAppSupervisingProfessionalPage />
        },
        {
            path: "/firm/vfApp/violations",
            key: "PUBLIC_VF_APPLICATIONS_VIOLATIONS",
            exact: true,
            component: () => <VfAppViolationsPage />
        },
        {
            path: "/firm/vfApp/unlicensedActivity",
            key: "PUBLIC_VF_APPLICATIONS_UNLICENSEDACTIVITY",
            exact: true,
            component: () => <VfAppUnlicensedActivityPage />
        },
        {
            path: "/firm/vfApp/acknowledgement",
            key: "PUBLIC_VF_APPLICATIONS_ACKNOWLEDGEMENT",
            exact: true,
            component: () => <VfAppAcknowledgementPage />
        },
        {
            path: "/firm/vfApp/review",
            key: "PUBLIC_VF_APPLICATIONS_REVIEW",
            exact: true,
            component: () => <VfAppReviewPage />
        },
        {
            path: "/firm/vfRenewal/sections",
            key: "PUBLIC_VF_RENEWAL_INDEX",
            exact: true,
            component: () => <VfRenewalSectionPage />
        },
        {
            path: "/firm/vfRenewal/contactInformation",
            key: "PUBLIC_VF_RENEWAL_CONTACTINFORMATION",
            exact: true,
            component: () => <VfRenewalContactInformationPage />
        },
        {
            path: "/firm/vfRenewal/waiver",
            key: "PUBLIC_VF_RENEWAL_WAIVERCERTIFICATE",
            exact: true,
            component: () => <VfRenewalWaiverCertificatePage />
        },
        {
            path: "/firm/vfRenewal/supervisingProfessional",
            key: "PUBLIC_VF_RENEWAL_SUPERVISINGPROFESSIONAL",
            exact: true,
            component: () => <VfRenewalSupervisingProfessionalPage />
        },
        {
            path: "/firm/vfRenewal/violations",
            key: "PUBLIC_VF_RENEWAL_VIOLATIONS",
            exact: true,
            component: () => <VfRenewalViolationsPage />
        },
        {
            path: "/firm/vfRenewal/acknowledgement",
            key: "PUBLIC_VF_RENEWAL_ACKNOWLEDGEMENT",
            exact: true,
            component: () => <VfRenewalAcknowledgementPage />
        },
        {
            path: "/firm/vfRenewal/review",
            key: "PUBLIC_VF_RENEWAL_REVIEW",
            exact: true,
            component: () => <VfRenewalReviewPage />
        },
        {
            path: "/firm/vfReinstatement/sections",
            key: "PUBLIC_VF_REINSTATEMENT_INDEX",
            exact: true,
            component: () => <VfReinstatementSectionPage />
        },
        {
            path: "/firm/vfReinstatement/contactInformation",
            key: "PUBLIC_VF_REINSTATEMENT_CONTACTINFORMATION",
            exact: true,
            component: () => <VfReinstatementContactInformationPage />
        },
        {
            path: "/firm/vfReinstatement/waiver",
            key: "PUBLIC_VF_REINSTATEMENTS_WAIVERCERTIFICATE",
            exact: true,
            component: () => <VfReinstatementWaiverCertificatePage />
        },
        {
            path: "/firm/vfReinstatement/supervisingProfessional",
            key: "PUBLIC_VF_REINSTATEMENTS_SUPERVISINGPROFESSIONAL",
            exact: true,
            component: () => <VfReinstatementSupervisingProfessionalPage />
        },
        {
            path: "/firm/vfReinstatement/violations",
            key: "PUBLIC_VF_REINSTATEMENTS_VIOLATIONS",
            exact: true,
            component: () => <VfReinstatementViolationsPage />
        },
        {
            path: "/firm/vfReinstatement/unlicensedActivity",
            key: "PUBLIC_VF_REINSTATEMENTS_UNLICENSEDACTIVITY",
            exact: true,
            component: () => <VfReinstatementUnlicensedActivityPage />
        },
        {
            path: "/firm/vfReinstatement/acknowledgement",
            key: "PUBLIC_VF_REINSTATEMENTS_ACKNOWLEDGEMENT",
            exact: true,
            component: () => <VfReinstatementAcknowledgementPage />
        },
        {
            path: "/firm/vfReinstatement/review",
            key: "PUBLIC_VF_REINSTATEMENTS_REVIEW",
            exact: true,
            component: () => <VfReinstatementReviewPage />
        },
        {
            path: "/firm/efapp/sections",
            key: "PUBLIC_EF_APPLICATIONS_INDEX",
            exact: true,
            component: () => <EfAppSectionPage />
        },
        {
            path: "/firm/efapp/contactInformation",
            key: "PUBLIC_EF_APPLICATIONS_CONTACTINFORMATION",
            exact: true,
            component: () => <EfAppContactInformationPage />
        },
        {
            path: "/firm/efapp/waiver",
            key: "PUBLIC_EF_APPLICATIONS_WAIVERCERTIFICATE",
            exact: true,
            component: () => <EfAppWaiverCertificatePage />
        },
        {
            path: "/firm/efapp/supervisingprofessional",
            key: "PUBLIC_EF_APPLICATIONS_SUPERVISINGPROFESSIONAL",
            exact: true,
            component: () => <EfAppSupervisingProfessionalPage />
        },
        {
            path: "/firm/efapp/violations",
            key: "PUBLIC_EF_APPLICATIONS_VIOLATIONS",
            exact: true,
            component: () => <EfAppViolationsPage />
        },
        {
            path: "/firm/efapp/unlicensedactivity",
            key: "PUBLIC_EF_APPLICATIONS_UNLICENSEDACTIVITY",
            exact: true,
            component: () => <EfAppUnlicensedActivityPage />
        },
        {
            path: "/firm/efapp/acknowledgement",
            key: "PUBLIC_EF_APPLICATIONS_ACKNOWLEDGEMENT",
            exact: true,
            component: () => <EfAppAcknowledgementPage />
        },
        {
            path: "/firm/efapp/review",
            key: "PUBLIC_EF_APPLICATIONS_REVIEW",
            exact: true,
            component: () => <EfAppReviewPage />
        },
        {
            path: "/firm/efrenewal/sections",
            key: "PUBLIC_EF_RENEWAL_INDEX",
            exact: true,
            component: () => <EfRenewalSectionPage />
        },
        {
            path: "/firm/efrenewal/contactInformation",
            key: "PUBLIC_EF_RENEWAL_CONTACTINFORMATION",
            exact: true,
            component: () => <EfRenewalContactInformationPage />
        },
        {
            path: "/firm/efrenewal/waiver",
            key: "PUBLIC_EF_RENEWAL_WAIVERCERTIFICATE",
            exact: true,
            component: () => <EfRenewalWaiverCertificatePage />
        },
        {
            path: "/firm/efRenewal/supervisingProfessional",
            key: "PUBLIC_EF_RENEWAL_SUPERVISINGPROFESSIONAL",
            exact: true,
            component: () => <EfRenewalSupervisingProfessionalPage />
        },
        {
            path: "/firm/efrenewal/violations",
            key: "PUBLIC_EF_RENEWAL_VIOLATIONS",
            exact: true,
            component: () => <EfRenewalViolationsPage />
        },
        {
            path: "/firm/efrenewal/acknowledgement",
            key: "PUBLIC_EF_RENEWAL_ACKNOWLEDGEMENT",
            exact: true,
            component: () => <EfRenewalAcknowledgementPage />
        },
        {
            path: "/firm/efrenewal/review",
            key: "PUBLIC_EF_RENEWAL_REVIEW",
            exact: true,
            component: () => <EfRenewalReviewPage />
        },
        {
            path: "/firm/efreinstatement/sections",
            key: "PUBLIC_EF_REINSTATEMENT_INDEX",
            exact: true,
            component: () => <EfReinstatementSectionPage />
        },
        {
            path: "/firm/efreinstatement/contactInformation",
            key: "PUBLIC_EF_REINSTATEMENT_CONTACTINFORMATION",
            exact: true,
            component: () => <EfReinstatementContactInformationPage />
        },
        {
            path: "/firm/efreinstatement/waiver",
            key: "PUBLIC_EF_REINSTATEMENTS_WAIVERCERTIFICATE",
            exact: true,
            component: () => <EfReinstatementWaiverCertificatePage />
        },
        {
            path: "/firm/efreinstatement/supervisingprofessional",
            key: "PUBLIC_EF_REINSTATEMENTS_SUPERVISINGPROFESSIONAL",
            exact: true,
            component: () => <EfReinstatementSupervisingProfessionalPage />
        },
        {
            path: "/firm/efreinstatement/violations",
            key: "PUBLIC_EF_REINSTATEMENTS_VIOLATIONS",
            exact: true,
            component: () => <EfReinstatementViolationsPage />
        },
        {
            path: "/firm/efreinstatement/unlicensedactivity",
            key: "PUBLIC_EF_REINSTATEMENTS_UNLICENSEDACTIVITY",
            exact: true,
            component: () => <EfReinstatementUnlicensedActivityPage />
        },
        {
            path: "/firm/efreinstatement/acknowledgement",
            key: "PUBLIC_EF_REINSTATEMENTS_ACKNOWLEDGEMENT",
            exact: true,
            component: () => <EfReinstatementAcknowledgementPage />
        },
        {
            path: "/firm/efreinstatement/review",
            key: "PUBLIC_EF_REINSTATEMENTS_REVIEW",
            exact: true,
            component: () => <EfReinstatementReviewPage />
        }
    ]
}]

export default FIRM_ROUTES