
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import shoppingCartService from '../../../../services/Public/shoppingCart.service';

function PaymentSuccessPage() {
    const [hasFinished, setHasFinished] = useState(false);
    const { push } = useHistory()

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        Swal.fire({
            title: `Processing`,
            didOpen: () => {
                Swal.showLoading();
                shoppingCartService.postOnlinePaymentToken(params.token).then((response) => {
                    if (response.data) {
                        var dataToTransmit = {
                            RenewalIds: response.data.autoApprovedRenewalIds
                        }
                        shoppingCartService.postAutoRenewalPdfs(dataToTransmit).then((data) => {
                            if (data) {
                                Swal.close()
                                setHasFinished(true)
                            }
                        })
                    }
                })
            },
            allowOutsideClick: false
        })
    }, [])

    const returnToDash = () => {
        push('/firm')
    }
    return (
        <>
            {hasFinished ?
                <Card>
                    <Card.Body style={{ textAlign: 'center' }}>
                        <>
                            <FaCheckCircle style={{ height: '10em', width: '10em', color: 'green' }} />
                            <h3 style={{ color: '#3980aa' }}>Your Payment was Successful</h3>
                            <h5 className="mb-3">Thank you for your payment. An automated payment receipt has been sent to your registered email.</h5>
                            <Button onClick={returnToDash}>Back to Dashboard</Button>
                        </>
                    </Card.Body>
                </Card> : <></>
            }
        </>
    );
}

export default PaymentSuccessPage;