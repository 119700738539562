import React, { useState } from 'react';
import { Container, Header, Content, Sidebar } from 'rsuite';
import RenderRoutes from '../../Routes/renderroutes'
import FIRM_ROUTES from '../../Routes/firmroutes';
import PublicFirmSidebar from '../Firm/Sidebar/Sidebar';
import PublicFirmTopNavbar from '../Firm/TopNavbar/TopNavbar'

function Firm() {
  return (
        <Container>
            <Sidebar>
              <PublicFirmSidebar />
            </Sidebar>
            <Container>
                <Header>
                  <PublicFirmTopNavbar />
                </Header>
                <Content className="mainContent">
                  <RenderRoutes routes={FIRM_ROUTES} />
                </Content>
            </Container>
        </Container>
    );
}

export default Firm;