import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";



const getBoardRecommendations = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/boardrecommendations`, { headers: authHeader(), params: { applicationId } });
}
const getBoardResponses = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/boardmemberresponses`, { headers: authHeader(), params: { applicationId } });
}
const postBoardRecommendation = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/boardrecommendation`, data, { headers: authHeader() });
}
const postBoardResponse = (data) => {
    var header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    return axios.post(`${PEAPPLICATIONURL}/boardmemberresponse`, data, { headers: { ...header } });
}
export default {
    getBoardRecommendations,
    getBoardResponses,
    postBoardRecommendation,
    postBoardResponse
}