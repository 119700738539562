import axios from "axios";
import authHeader from '../authheader'

const ATTACHMENTURL = process.env.REACT_APP_APIURL + "/api/Attachment";
const GETFILEURL = process.env.REACT_APP_APIURL + "/api/Attachment/file"
const ATTACHMENTTYPES = "audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.ms-powerpoint,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

const getFiles = (parentId, parentTypeId) => {
    return axios.get(`${ATTACHMENTURL}/files`, { headers: authHeader(), params: { parentId, parentTypeId } });
}
const getFilesForEntity = (entityId) => {
    return axios.get(`${ATTACHMENTURL}/filesforentity`, { headers: authHeader(), params: { entityId } });
}
const getFilesForExternalSp = () => {
    return axios.get(`${ATTACHMENTURL}/filesforexternalsp`, { headers: authHeader() });
}
const postFile = (data, parentTypeId, parentId, documentTypeId, entityId = 0) => {
    var header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    var formData = new FormData();
    if (data.length) {
        for (var i = 0; i < data.length; i++) {
            formData.append(`files`, data[i].blobFile, data[i].name);
        }
    }
    else
        formData.append(`files`, data.blobFile, data.name);
    return axios.post(ATTACHMENTURL, formData, { headers: { ...header }, params: { parentTypeId, parentId, documentTypeId, entityId } });
}
const deleteFile = (attachmentId) => {
    return axios.post(`${ATTACHMENTURL}/deletefile`, null, { headers: authHeader(), params: { id: attachmentId } });
}
const openFile = (attachmentId) => {
    axios.get(`${ATTACHMENTURL}/file`, { headers: authHeader(), params: { id: attachmentId }, responseType: 'arraybuffer' }).then((response) => {
        var name = new String(response.headers['content-disposition']);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        var fileName = name.slice(name.indexOf('filename=') + 9, name.lastIndexOf(';')).replace(/^["'](.+(?=["']$))["']$/, '$1');
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}

//This Method will be used to call the buiness to retrieve all the doc types that are allowed for a section
//Not every param will always have a value due to some attachments not having a section as a parent type
const getDocTypes = (parentTypeId, credentialTypeId, sectionId, isByComity, isByExam, attachmentSection) => {
    return axios.get(`${ATTACHMENTURL}/getdoctypes`, { headers: authHeader(), params: { parentTypeId, credentialTypeId, sectionId, isByComity, isByExam, attachmentSection } }).then((response) => {
        return response.data.map(data => { return { "label": `${data.docTypeDisplayName}`, "value": data.docTypeId } })
    });

}
const getAllDocTypes = (isIndividual) => {
    return axios.get(`${ATTACHMENTURL}/getalldoctypes`, { headers: authHeader(), params: { isIndividual } }).then((response) => {
        return response.data.map(data => { return { "label": `${data.docTypeDisplayName}`, "value": data.docTypeId } })
    });

}
const getFileData = (attachmentId) => {
    return axios.get(`${ATTACHMENTURL}/file`, { headers: authHeader(), params: { id: attachmentId }, responseType: 'arraybuffer' });
}



export default {
    getFiles,
    postFile,
    deleteFile,
    openFile,
    GETFILEURL,
    ATTACHMENTTYPES,
    getFilesForExternalSp,
    getDocTypes,
    getAllDocTypes,
    getFilesForEntity,
    getFileData
}