import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { FormControl } from 'rsuite'
import RedStar from '../GenericComponents/RedStar'

function Acknowledgement() {
    return (
        <Card>
            <Card.Header><h6>Acknowledgement</h6></Card.Header>
            <Card.Body>
                <p>
                    I certify that I have read the contents of this application and to the best of my knowledge, information and belief the
                    statements and information in this application are true and correct in substance and effect and are made in good faith. I
                    have not omitted any information requested in this application. I am aware that such an omission or untrue response
                    may constitute fraud, deceit, material misstatement, perjury or the giving of false or forged evidence under Louisiana
                    Revised Statutes 37:698 and 37:700.
                </p>
                <hr />
                <Row>
                    <Col sm={4}>
                        <h5>Electronic Signature of Applicant:<RedStar /></h5>
                        <span>(Type full name)</span>
                    </Col>
                    <Col sm={8}><FormControl name="electronicSignature" /></Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
export default Acknowledgement