import React from 'react'
import { Card, Row, Col, Badge, } from 'react-bootstrap'
import { FormControl } from 'rsuite'
import RedStar from '../../../../../Shared/GenericComponents/RedStar'
import DocumentUpload from '../../../../../Shared/Upload/DocumentUpload';

function Courses(props) {
    return (
        <Card>
            <Card.Header>
                    <h6>Courses</h6>
            </Card.Header>
            <Card.Body>
                <div>
                    <DocumentUpload
                        isUserEditable={props.formModel.isUserEditable}
                        credentialTypeId={6}
                        sectionId={5}
                        parentId={props.formModel.id}
                        parentTypeId={2}
                        entityId={props.formModel.entityId}
                        fileUploadTitle={<>CPD Records must include the <a href="https://www.lapels.com/cpd.html" target="_blank">LAPELS approved CPD log</a> with all certificates/proof of attendance uploaded in the section below.
                            You may also choose to upload your CPD documentation in your NCEES CPC locker at ncees.org.
                            <br />
                            <RedStar />Course records must cover the last 24 months prior to starting this reinstatement application.
                        </>}
                        setHasFiles={props.setHasFiles}
                    />
                    {!props.hasFiles && <div className="text-danger">Please upload at least 1 file.</div>}
                </div>
                <hr />
                <p>
                    The CPD credits listed herein are true and correct, and accurately state the professional development hours I have earned.
                </p>
                <br />
                <Row>
                    <Col sm={4}>
                        <h5>Electronic Signature of Applicant:<RedStar /></h5>
                        <span>(Type full name)</span>
                    </Col>
                    <Col sm={8}><FormControl name="electronicSignature" /></Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
export default Courses

