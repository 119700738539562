import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../Shared/Card/Issues';
import { useSelector } from 'react-redux';
import ViolationService from '../../../../../services/Internal/Application/EI/violation.service';
import eiApplicationService from '../../../../../services/Internal/Application/EI/eiApplication.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/EiInformation';
import Information from '../../../../Shared/Card/Information';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import { getCodeViolationAnswers } from '../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../Shared/Card/Violations';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 4;
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Application",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Violations"
        }
    );
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        signature: StringType().isRequired('This field is required.'),
        yesAnswerExplanation: StringType().isRequired('This field is required.'),
    });
    useEffect(() => {
        setHasLoaded(false);
        eiApplicationService.eiGetSection(store.currentApplicationId, sectionId).then((response) => {
            ViolationService.getViolation(store.currentApplicationId).then((data) => {
                if (data.data) {
                    updateFormData({ ...data.data, issues: formData.issues, files: formData.files, filesForDisplay: formData.filesForDisplay, signature: response.data.electronicSignature });
                } else {
                    getCodeViolationAnswers(1).then((questions) => {
                        updateFormData({ ...formData, answers: [...questions] })
                    })
                }
                setSectionData(response.data);
                setIsComplete(response.data.staffReviewComplete);
                applicationService.getIndividualApplication(store.currentApplicationId).then((appData) => {
                    setIsUserEditable(appData.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                                applicationDate: appData.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                })
            })
        })

    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        signature: undefined,
        yesAnswerExplanation: "",
        files: [],
        filesForDisplay: [],
        issues: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);
    const downloadViolations = () => {
        ViolationService.downloadViolationFile(store.currentApplicationId)
    }
    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            setShowFormErrors(true)
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }

            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            if (formData.signature) {
                if (showAdditionalFields === false || val) {
                    var dataTransfer = { ...formData, applicationId: store.currentApplicationId, entityId: store.userInfo.EntityId };
                    ViolationService.postViolation(dataTransfer).then((response) => {
                        eiApplicationService.eiPostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                            eiApplicationService.eiMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((data) => {
                                if (response.data) {
                                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                                }
                            })
                        })
                    });
                }
            }
        }
    }

    return (
        <div className="eiAppViolationsPage">
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col> <Information content={GetAppInformationContent("violations")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <GenericViolations
                                        showFormErrors={showFormErrors}
                                        showAdditionalFields={showAdditionalFields}
                                        hideSignature={true}
                                        downloadViolations={downloadViolations}
                                        isReadOnly={!isUserEditable}
                                        isInternal={true}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        entityId={entityInfo.entityId}
                                        parentTypeId={1}
                                        credentialTypeId={3}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                showSectionComplete={true}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                backToSectionLink="/internal/eiapp/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    )
}
export default Violations;