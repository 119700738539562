import axios from "axios";
import authHeader from '../../../authheader'

const PEREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPeReinstatement";

const getViolation = (reinstatementId) => {
    return axios.get(`${PEREINSTATEMENTURL}/violation`, { headers: authHeader(), params: { reinstatementId } });
}
const postViolation = (data) => {
    return axios.post(`${PEREINSTATEMENTURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}