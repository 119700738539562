import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import { Table } from 'rsuite';
import renewalService from '../../../../services/Public/Renewal/renewal.service';
import Swal from 'sweetalert2';
import Information from '../../../Shared/Card/Information';
import { GetRenewalInstruction } from '../../../Shared/InformationContent/GeneralInformation';

function Renewals() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [credentialList, setCredentialList] = useState([]);

    const [hasLoadedHistorical, setHasLoadedHistorical] = useState(false);
    const [historicalRecords, setHistoricalRecords] = useState([]);

    const { push } = useHistory();
    const dispatch = useDispatch();

    const navigateAndSetCurrentApp = (rowData) => {
        var credentialTypeId = rowData.credential?.credentialType?.id;
        if (credentialTypeId === 5 ||
            credentialTypeId === 4 ||
            credentialTypeId === 3 ||
            credentialTypeId === 6) {
            dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: rowData.renewalId });
            if (rowData?.sections?.length) {
                setUpRoutes(rowData.sections, credentialTypeId);
            }
            if (credentialTypeId === 5)
                push("/individual/plsRenewal/sections");
            else if (credentialTypeId === 3)
                push("/individual/eirenewal/sections");
            else if (credentialTypeId === 4)
                push("/individual/lsiRenewal/sections");
            else if (credentialTypeId === 6)
                push("/individual/peRenewal/sections");
        }
        else {
            Swal.fire("Not Implemented!");
        }
    }
    const setUpRoutes = (sections, credentialTypeId) => {
        var route = [];
        for (var i = 0; i < sections.length; i++) {
            let sectionId = sections[i].sectionType.id
            var link = "";
            if (credentialTypeId === 5)
                link = "/individual/plsRenewal/";
            else if (credentialTypeId === 4)
                link = "/individual/lsiRenewal/";
            else if (credentialTypeId === 3)
                link = "/individual/eirenewal/";
            else if (credentialTypeId === 6)
                link = "/individual/peRenewal/";
            switch (sectionId) {
                case 1:
                    link += "contactInformation"
                    break;
                case 2:
                    link += "licenseStatus"
                    break;
                case 3:
                    link += "cpdRequirements"
                    break;
                case 4:
                    link += "violations"
                    break;
                case 6:
                    link += "privacy"
                    break;
                case 7:
                    link += "acknowledgement"
                    break;
                case 8:
                    link += "review"
                    break;
                case 10:
                    link += "design"
                    break;
                default:
            }
            route.push({ sectionId: sections[i].sectionType.id, route: link })
        }
        dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: route })
    }

    const createAndSetCurrentApp = (rowData) => {
        var credentialTypeId = rowData.credential?.credentialType?.id;
        if (credentialTypeId === 5 ||
            credentialTypeId === 4 ||
            credentialTypeId === 3 ||
            credentialTypeId === 6) {
            renewalService.createRenewal(rowData.credential.credentialType.id, rowData.credential.id).then((response) => {
                renewalService.getIndividualRenewal(response.data).then((data) => {
                    dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: response.data });
                    setUpRoutes(data.data.sections, credentialTypeId);
                    if (credentialTypeId === 4)
                        push("/individual/lsiRenewal/sections");
                    else if (credentialTypeId === 3)
                        push("/individual/eirenewal/sections");
                    else if (credentialTypeId === 5)
                        push("/individual/plsRenewal/sections");
                    else if (credentialTypeId === 6)
                        push("/individual/peRenewal/sections");
                })
            })
        }
        else {
            Swal.fire("Not Implemented!");
        }
    }
    useEffect(() => {
        renewalService.getPendingRenewals().then((response) => {
            if (response.data.length) {
                setCredentialList(response.data);
            }
            setHasLoaded(true);
        });
        renewalService.getHistoricalRenewals().then((response) => {
            if (response.data.length)
                setHistoricalRecords(response.data.sort((a, b) => { return new Date(b.dateSubmitted) - new Date(a.dateSubmitted) }));
            setHasLoadedHistorical(true);
        })
    }, [])
    return (
        <div className="renewalPage">
            <Container fluid>
                <h4 className="mb-3">Renewals</h4>
                <Row>
                    <Col><Information content={GetRenewalInstruction()} /></Col>
                </Row>
                <br />
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Header><h6>Renew a license</h6></Card.Header>
                            <Card.Body>
                                {hasLoaded ?
                                    <Table wordWrap autoHeight data={credentialList}>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (
                                                    <Button onClick={() => {
                                                        if (rowData.renewalId)
                                                            navigateAndSetCurrentApp(rowData);
                                                        else
                                                            createAndSetCurrentApp(rowData);
                                                    }}>
                                                        {rowData.dateSubmitted
                                                            ? rowData.sections.filter(x => x.isUserEditable == true).length
                                                                ? "Continue"
                                                                : "View"
                                                            : (rowData.renewalId
                                                                ? "Continue"
                                                                : "Start")}
                                                    </Button>)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={3}>
                                            <Table.HeaderCell>
                                                License
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.formattedLicenseNumber)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Expiration Date
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.expirationDate ? new Date(rowData.credential?.expirationDate).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Created
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Submitted
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateSubmitted ? new Date(rowData.dateSubmitted).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Review Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>

                                    </Table> : <LoadingSpinner />
                                }
                            </Card.Body>
                        </Card></Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header><h6>Historical Renewals</h6></Card.Header>
                            <Card.Body>
                                {hasLoadedHistorical ?
                                    <Table wordWrap autoHeight data={historicalRecords}>
                                        {/*<Table.Column flexGrow={1}>*/}
                                        {/*    <Table.HeaderCell>*/}
                                        {/*    </Table.HeaderCell>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        {rowData => (*/}
                                        {/*            <Button onClick={() => {*/}
                                        {/*                if (rowData.renewalId) {*/}
                                        {/*                }*/}
                                        {/*                    navigateAndSetCurrentApp(rowData);*/}
                                        {/*                //else*/}
                                        {/*                //    createAndSetCurrentApp(rowData);*/}
                                        {/*            }}>*/}
                                        {/*                <Icon icon="file-pdf-o" /> View*/}
                                        {/*            </Button>*/}
                                        {/*        )}*/}
                                        {/*    </Table.Cell>*/}
                                        {/*</Table.Column>*/}
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.formattedLicenseNumber)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Expiration Date
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.expirationDate ? new Date(rowData.credential?.expirationDate).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Created
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Submitted
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateSubmitted ? new Date(rowData.dateSubmitted).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Approved/Declined
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateActioned ? new Date(rowData.dateActioned).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Review Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>

                                    </Table> : <LoadingSpinner />
                                }
                            </Card.Body>
                        </Card></Col>
                </Row>
            </Container>
        </div>
    )
}
export default Renewals;