import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Information from '../../../../Shared/Card/Information';
import Swal from 'sweetalert2';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons'
import reinstatementService from '../../../../../services/Internal/Reinstatement/reinstatement.service';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import Courses from './Courses'
import { useSelector } from 'react-redux';
import Issues from '../../../../Shared/Card/Issues';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function CpdRequirementsPage() {
    const { push } = useHistory();

    const [formModel, setFormModel] = useState({
        electronicSignature: "",
        hasCpd: ''
    });
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);
    const sectionId = 5;
    const routes = useSelector(x => x.currentRoutes);
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.'),
    });
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "CPD Requirements"
        }
    );
    useEffect(() => {
        reinstatementService.plsGetSection(reinstatementId, sectionId).then((response) => {
            setFormModel(response.data);
            setIsComplete(response.data.staffReviewComplete)
            reinstatementService.getIndividualReinstatement(reinstatementId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])
    const formSubmit = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            submitData(true);
        }

    }
    const formSave = (event) => {
        submitData();
    }
    const submitData = (isForSubmit) => {
        reinstatementService.plsPostSection(reinstatementId, sectionId, formModel.electronicSignature).then((response) => {
            reinstatementService.plsMarkSectionComplete(reinstatementId, sectionId, isComplete).then((data) => {
                if (isForSubmit && data.data.id) {
                    push(routes[routes.map(function (x) { return x.sectionId }).indexOf(sectionId) + 1].route)
                }
                else if (data.data.id) {
                    Swal.fire("Saved!");
                }
                else {
                    Swal.fire("Error saving cpd")
                }
            })
        })
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetReinstatementInformationContent("cpdRequirements")} />
                <br />
                {hasLoaded ?
                    <Form model={model} formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} fluid>
                        <Courses
                            isUserEditable={isUserEditable}
                            formModel={formModel}
                            setHasFiles={setHasFiles}
                            hasFiles={hasFiles} />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={formModel.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            backToSectionLink="/internal/plsReinstatement/sections"
                            handleSave={formSave}
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                        />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}

export default CpdRequirementsPage