import axios from "axios";
import authHeader from '../../../authheader'

const PLSREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsReinstatement";

const getContactInformation = (reinstatementId) => {
    return axios.get(`${PLSREINSTATEMENTURL}/contactinformation`, { headers: authHeader(), params: { reinstatementId } });
}
export default {
    getContactInformation
}