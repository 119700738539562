import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import logo from '../../../images/LAPELS-LOLA-Logo.png'
import { useHistory } from 'react-router-dom';
function RegisterSuccessPage() {
    const history = useHistory();

    const imageStyle = {
        height: '10rem',
        width: '10rem',
        margin: 'auto'
    };
    const centerText = {
        textAlign: 'center'
    }
    const buttonStyle = {
        width: "100%"
    }
    function backToLoginClick() {
        history.push("/login");
    }

    return (
        <div className="registerSuccessPage">
            <Card style={{ width: '25rem', paddingTop: '1rem' }}>
                <Card.Img variant="top" src={logo} alt="Lapels Seal" style={imageStyle} />
                <Card.Body>
                    <Card.Title style={centerText}>Register Success</Card.Title>
                    <p>You've successfully registered for an account. Please check your email to confirm.</p>
                    <br />
                        <Button
                            variant="primary"
                        type="button"
                        onClick={backToLoginClick}
                        style={buttonStyle}>
                            Back to Login
                        </Button>
                </Card.Body>
            </Card>
        </div>
    )
}
export default RegisterSuccessPage;