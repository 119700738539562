import React from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'rsuite';
import DocumentUpload from '../../../Shared/Upload/DocumentUpload';

function DocumentUploadsCard(props) {
    return (
        <Card>
            <Card.Header>
                <h6>Document Uploads</h6>
            </Card.Header>
            <Card.Body>
                <Form>
                    <DocumentUpload
                        isIndividual={props.isIndividual}
                        parentTypeId={10}
                        parentId={props.entityId}
                        entityId={props.entityId}
                        isUserEditable={true}
                    />
                </Form>
            </Card.Body>
        </Card>
    )
}


export default DocumentUploadsCard;