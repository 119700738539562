import axios from "axios";
import authHeader from '../../../authheader'

const PLSREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsReinstatement";

const getEmployment = (reinstatementId) => {
    return axios.get(`${PLSREINSTATEMENTURL}/employment`, { headers: authHeader(), params: { reinstatementId } });
}
const postEmployment = (data) => {
    return axios.post(`${PLSREINSTATEMENTURL}/employment`, data, { headers: authHeader() });
}
export default {
    getEmployment,
    postEmployment
}