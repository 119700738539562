import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Icon, Table } from 'rsuite';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import EducationService from '../../../../../services/Public/Application/PE/education.service'
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function EducationRecord(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [displayLength, setDisplayLength] = useState(20);
    const [page, setPage] = useState(1);

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }
    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }
    useEffect(() => {
        reloadGrid();
    }, [props.reloadGrid])
    const reloadGrid = () => {
        setHasLoaded(false);
        EducationService.getEducationRecords(props.applicationId).then((response) => {
            setGridData(response.data)
            setHasLoaded(true);
        });
    }
    const getData = () => {
        return gridData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        })
    }
    const filteredData = getData();

    return (
        <div className="peAppsEducationInfo">
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>Education Records</h6>
                        </Col>
                        {(!props.isReadOnly && props.hideGrid == false) &&
                            <Col>
                                <Button className="float-right" onClick={props.openModal}>
                                    <Icon icon="plus" /> Add New</Button>
                            </Col>
                        }
                    </Row>
                </Card.Header>
                <Card.Body >
                    <p>
                        <RedStar /> I have education records verified in MyNCEES account.
                    </p>
                    <br />
                    <YesNoRadioOption readOnly={props.isReadOnly} radioName="hasMyNceesEducation" handleChange={props.handleMyNceesEducationChange} />
                    {(props.hideGrid === false) &&
                        <>
                            {hasLoaded ?
                                <>
                                <span className="badge badge-danger" style={{ padding: "0.5rem" }}>Transcript(s) need to be verified in MyNCEES. <b>Transcript(s) are not accepted from applicants.</b ></span>
                                    <br />
                                    <br />
                                    <Table wordWrap autoHeight data={filteredData}>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                <b>Institution</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => (rowData.school.name)}</Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1} >
                                            <Table.HeaderCell>
                                                <b>Dates From/To</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => (`${dayjs(rowData.attendanceStartDate).format('MMM YYYY')} - ${dayjs(rowData.attendanceEndDate).format('MMM YYYY')}`)}</Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1} >
                                            <Table.HeaderCell>
                                                <b>Graduation Date</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => (`${rowData.graduationDate ? new Date(rowData.graduationDate).toLocaleDateString() : ''}`)}</Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1} >
                                            <Table.HeaderCell>
                                                <b>Major</b>
                                            </Table.HeaderCell>
                                            <Table.Cell dataKey="major" />
                                        </Table.Column>
                                        <Table.Column flexGrow={1} >
                                            <Table.HeaderCell>
                                                <b>Degree</b>
                                            </Table.HeaderCell>
                                            <Table.Cell>{rowData => (`${rowData.degree ? rowData.degree.degree : ''}`)}</Table.Cell>
                                        </Table.Column>
                                        {
                                            !props.isReadOnly &&
                                            <Table.Column width={100}>
                                                <Table.HeaderCell>
                                                </Table.HeaderCell>
                                                <Table.Cell dataKey="id">
                                                    {rowData => {
                                                        function handleAction() {

                                                            props.openModal(rowData);
                                                        }
                                                        function handleDelete() {
                                                            Swal.fire({
                                                                title: 'Are you sure?',
                                                                text: "You won't be able to revert this!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Yes, delete it!'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    EducationService.postEducationRecord({ ...rowData, deleted: true }).then((response) => {
                                                                        reloadGrid();
                                                                    })
                                                                }
                                                            })
                                                        }
                                                        return (
                                                            <span>
                                                                <Icon icon="edit2" size="2x" onClick={handleAction} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                                                <Icon icon="trash" size="2x" onClick={handleDelete} style={{ cursor: "pointer" }} />
                                                            </span>
                                                        );
                                                    }}
                                                </Table.Cell>
                                            </Table.Column>
                                        }
                                    </Table>
                                    <Table.Pagination lengthMenu={[
                                        {
                                            value: 10,
                                            label: 10
                                        },
                                        {
                                            value: 20,
                                            label: 20
                                        }
                                    ]}
                                        activePage={page}
                                        displayLength={displayLength}
                                        total={gridData.length}
                                        onChangePage={handleChangePage}
                                        onChangeLength={handleChangeLength} />
                                </> : <LoadingSpinner />}
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}


export default EducationRecord;