import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Form, FormControl, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import reinstatementService from '../../../../../../services/Public/Reinstatement/VF/vfReinstatement.service';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Issues from '../../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import RedStar from '../../../../../Shared/GenericComponents/RedStar';

function AcknowledgementPage() {
    const [hasLoaded, setHasLoaded] = useState(false)
    const { push } = useHistory();
    const { StringType } = Schema.Types;
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 9; // Revisit
    const [formModel, setFormModel] = useState({})
    useEffect(() => {
        reinstatementService.vfGetSection(reinstatementId, sectionId).then((response) => {
            setFormModel(response.data);
            setHasLoaded(true);
        })
    }, [])
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });

    const formSubmit = (validation, event) => {
        if (!formModel.isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            if (validation) {
                submitData(true);
            }
        }
    }
    const submitData = (isForSubmit) => {
        reinstatementService.vfPostSection(reinstatementId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <b>Surveying Firm Reinstatement</b>
                <h4 className="mb-3">Acknowledgement</h4>
                {hasLoaded ?
                    <Form readOnly={!formModel.isUserEditable} model={model} fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit}>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header><h6>Requirements/Information/Acknowledgement</h6></Card.Header>
                                    <Card.Body>
                                        <p>
                                            Fields marked with an asterisk * are required.
                                        </p>
                                        <br/>
                                        <p>
                                            I certify that I have read the contents of this application and to the best of my knowledge, information and belief the
                                            statements and information in this application are true and correct in substance and effect and are made in good faith. I
                                            have not omitted any information requested in this application. I am aware that such an omission or untrue response
                                            may constitute fraud, deceit, material misstatement, perjury or the giving of false or forged evidence under Louisiana
                                            Revised Statutes 37:698 and 37:700.
                                        </p>
                                        <hr />
                                        <Row>
                                            <Col sm={4}>
                                                <h5>Electronic Signature of Applicant:<RedStar /></h5>
                                                <span>(Type full name)</span>
                                            </Col>
                                            <Col sm={8}><FormControl name="electronicSignature" /></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Issues isUserEditable={true} type={2} sectionId={formModel.id} />
                        <FormActionButtons isReadOnly={!formModel.isUserEditable} backToSectionLink="/firm/vfReinstatement/sections" />
                    </Form> : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default AcknowledgementPage