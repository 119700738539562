export const codeApplicationType = [
    {
        id: 1,
        applicationType: "Engineer Intern",
        abbreviation: "EI"
    },
    {
        id: 2,
        applicationType: "Land Surveyor Intern",
        abbreviation: "LSI"
    },
    {
        id: 3,
        applicationType: "Professional Engineer By Comity",
        abbreviation: "PE"
    },
    {
        id: 4,
        applicationType: "Professional Engineer By Exam",
        abbreviation: "PE"
    },
    {
        id: 5,
        applicationType: "Professional Land Surveyor By Comity",
        abbreviation: "PLS"
    },
    {
        id: 6,
        applicationType: "Professional Land Surveyor By Exam",
        abbreviation: "PLS"
    },
    {
        id: 7,
        applicationType: "Engineering Firm",
        abbreviation: "EF"
    },
    {
        id: 8,
        applicationType: "Surveying Firm",
        abbreviation: "VF"
    },
];