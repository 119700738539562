import bsCustomFileInput from 'bs-custom-file-input';
import React from 'react'
import { useState, useEffect } from 'react'
import { Button, Row, Col, Modal, } from 'react-bootstrap'
import { ControlLabel, Form, FormControl, FormGroup, InputNumber, Schema, SelectPicker, Uploader, Message } from 'rsuite'
import Swal from 'sweetalert2';
import attachmentService from '../../../../services/Shared/attachment.service';
import { getAreas } from '../../../../services/Shared/codeTable.service';
import cpdReqService from '../../../../services/Shared/cpdReq.service';
import RedStar from '../../../Shared/GenericComponents/RedStar';
import GenericAttachmentGrid from '../../../Shared/Grid/GenericAttachmentGrid';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function AddEditCoursesModal(props) {
    bsCustomFileInput.init();
    const [blockUi, setBlockUi] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [courseModel, setCourseModel] = useState({
        id: 0,
        date: null,
        dateCrte: null,
        deleted: false,
        userCrte: "",
        title: "",
        instructor: "",
        area: null,
        hours: "",
        classType: null,
        description: "",
        files: [],
        filesForDisplay: []
    })
    const [areas, setAreas] = useState([])

    const handleChange = (val, e) => {
        if (!e) {
            var newFiles = courseModel.files;
            if (!newFiles.includes(val)) {
                newFiles.push(val);
            }
            setCourseModel({ ...courseModel, files: [...newFiles] })
            validateFileSizeLimit();
        }
    };

    const validateFileSizeLimit = (newFiles) => {
        var fileSizeLimit = 78643200;
        
        var currentFileSize = newFiles != null ?
            newFiles.map(file => file?.blobFile?.size ?? 0).reduce((prev, current) => prev + current, 0) :
            courseModel.files.map(file => file?.blobFile?.size ?? 0).reduce((prev, current) => prev + current, 0);
        if (currentFileSize > fileSizeLimit) {
            setIsValid(false);
        }
        else {
            setIsValid(true);
        }
    }

    const deleteFile = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var newFiles = courseModel.files.filter(x => x.fileKey != id);
                setCourseModel({ ...courseModel, files: [...newFiles] });
                validateFileSizeLimit(newFiles);
            }
        })
    }

    useEffect(() => {
        setCourseModel(props.modaldata);
        getAreas().then(setAreas);
    }, [props.modaldata])

    const { StringType, ObjectType, NumberType, DateType, ArrayType } = Schema.Types;
    const model = Schema.Model({
        date: DateType().isRequired('This field is required.'),
        title: StringType().isRequired('This field is required.'),
        instructor: StringType().isRequired('This field is required.'),
        area: ObjectType().isRequired('This field is required.'),
        classType: ObjectType().isRequired('This field is required.'),
        hours: NumberType().isRequired('This field is required.'),
        classType: ObjectType().isRequired('This field is required.'),
        description: StringType().isRequired('This field is required.'),

    })


    const formSubmit = (validation, event) => {
        if (validation && !props.isModalAdd || (validation && (props.isModalAdd && courseModel.files.length > 0))) {
            var formData = new FormData();
            var courseRecord = {
                area: courseModel.area,
                classType: courseModel.classType,
                courseDate: (new Date(courseModel.date)).toISOString(),
                courseTitle: courseModel.title,
                description: courseModel.description,
                hours: courseModel.hours,
                entityId: props.entityId,
                id: courseModel.id,
                instructor: courseModel.instructor,
                dateCrte: courseModel.dateCrte,
                userCrte: courseModel.userCrte,
                deleted: courseModel.deleted
            }
            formDataAppender('courseRecord', courseRecord, formData);
            for (var i = 0; i < courseModel.files.length; i++) {
                formData.append('files', courseModel.files[i].blobFile);
            }

            setBlockUi(true);
            if (props.isModalAdd) {
                cpdReqService.postCreateCpd(formData).then((response) => {
                    props.refreshGrid();
                    props.onHide();
                    setBlockUi(false);
                });
            }
            else // Edit
            {
                cpdReqService.postUpdateCpd(formData).then((response) => {
                    props.refreshGrid();
                    props.onHide();
                    setBlockUi(false);
                });
            }
        }
    }

    const formDataAppender = (passedKey, model, formData) => {
        for (let key in model) {
            if (typeof (model[key]) === 'object') {
                formDataAppender(`${passedKey}.${key}`, model[key], formData); // Recursive key for objects
            }
            else {
                if (passedKey)
                    formData.append(`${passedKey}.${key}`, model[key]);
                else
                    formData.append(`${key}`, model[key]);
            }
        }
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <BlockUi blocking={blockUi}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CPD Course Locker
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form model={model} id="courseForm" formValue={courseModel} fluid onChange={courseModel => setCourseModel(courseModel)} onSubmit={formSubmit}>
                        <FormGroup className="mx-3">
                            <Row >
                                <ControlLabel>
                                    <strong>
                                        Date (MM/DD/YYYY)&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl type="date" block name="date" />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Course Title&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl name="title" />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Instructor&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl name="instructor" />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Area&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl
                                    accepter={SelectPicker}
                                    placeholder="-- Area --"
                                    block
                                    searchable={false}
                                    data={areas}
                                    name="area" />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Hours&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl name="hours" accepter={InputNumber} scrollable={false} min={0} />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Class Type&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl
                                    accepter={SelectPicker}
                                    placeholder="-- Class Type --"
                                    block
                                    searchable={false}
                                    data={[
                                        { label: "Face to Face", value: { id: 1, classType: "Face to Face" } },
                                        { label: "Online", value: { id: 2, classType: "Online" } }
                                    ]}
                                    name="classType" />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Description/Learning Objectives&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl name="description" componentClass="textarea" rows={4} />
                            </Row>
                        </FormGroup>
                        <FormGroup className="mx-3">
                            <Row>
                                <ControlLabel>
                                    <strong>
                                        Document/Certificates of Completion &nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Row>
                            <Row>
                                <FormControl
                                    accepter={Uploader}
                                    listType="text"
                                    onUpload={handleChange}
                                    fileListVisible={false}
                                    multiple
                                    accept={attachmentService.ATTACHMENTTYPES}
                                />
                            </Row>
                            <Row>
                                <Col sm="12">
                                    {courseModel?.files?.length > 0 &&
                                        <div>
                                            <br />
                                            <FormControl
                                                accepter={GenericAttachmentGrid}
                                                deleteFileCallback={deleteFile}
                                                data={courseModel.files.map((value, i) => {
                                                    return {
                                                        id: value.fileKey,
                                                        timestamp: new Date().toLocaleDateString(),
                                                        file: value.blobFile.name
                                                    }
                                                })}
                                                isSync={true}
                                                tStyle="table-bordered"
                                                name="filesForDisplay" />
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                    {isValid ? "" : <Message type="error" description="Collective file size limit of 75MB has been exceeded." />}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" form="courseForm" variant="success" disabled={!isValid}>Save</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </BlockUi>
        </Modal>
    );
}

export default AddEditCoursesModal;