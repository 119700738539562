import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import ReviewPdf from '../../../../Shared/Card/ReviewPdf';
import renewalService from '../../../../../services/Internal/Renewal/renewal.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Swal from 'sweetalert2';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/PlsInformation';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ReviewPage() {
    const [hasLoaded, setHasLoaded] = useState(true)
    const appId = useSelector(x => x.currentApplicationId);
    const [violationIssuesHtml, setViolationIssuesHtml] = useState();
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Land Surveyor Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Review"
        }
    );
    const sectionId = 8;
    useEffect(() => {
        renewalService.plsGetSection(appId, sectionId).then((response) => {
            setIsComplete(response.data.staffReviewComplete);
            renewalService.getIndividualRenewal(appId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])


    const formSubmit = () => {
        renewalService.plsMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
            if (data.data)
                Swal.fire("Success!", "You have saved successfully.", 'success')
        })
    }


    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Row>
                    <Col>
                        <Information content={GetRenewalInformationContent("review")} className="mb-3" />
                    </Col>
                </Row>
                <br />

                <ReviewPdf id={appId} parentTypeId={3} />
                <br />
                <br />
                {hasLoaded ?
                    <>
                        {violationIssuesHtml}
                        <Container fluid>
                            <Row className="mt-3">
                                <Col style={{ display: "flex", alignItems: "center" }}>
                                    <label className="switch">
                                        <input id="completeSectionCheckbox" checked={isComplete} onChange={() => { if (isUserEditable) setIsComplete(!isComplete); }} type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>&nbsp;
                                    <label htmlFor="completeSectionCheckbox">
                                        <b>Mark Section as Complete</b>
                                    </label>
                                    &nbsp;&nbsp;
                                    <Button name="backToSections" as={Link} to="/internal/plsrenewal/sections" variant="primary" className="mr-2">Back to Sections</Button>
                                    {isUserEditable &&
                                        <Button name="save" onClick={formSubmit} variant="danger" className="mr-2">Save</Button>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </>
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ReviewPage