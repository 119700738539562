import { loremParagraphs } from "../../../fakeapi";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import renewalService from "../../../services/Public/Renewal/renewal.service";
import * as dayjs from "dayjs";

export const GetGeneralContactInformationInstructions = () => {
    return (
        <ul>
            <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
            <li>Mandatory fields are indicated with "*" (asterisk) to the left of the field</li>
            <li>Louisiana Revised Statute 37:23 requires that every application by an individual for a professional license, permit or certificate shall require the applicant's social security number.
                Disclosure of your social security number is mandatory for this purpose and for the purpose of establishing, modifying or enforcing family and child support obligations and locating an individual who is under an obligation to pay family or child support under La. R.S. 46:236.1.1, et seq.
                Failure to provide your social security number for these mandatory purposes will result in the denial of your licensure, permit or certificatef application.
                Disclosure of your social security number is voluntary for disclosure to other state regulatory agencies, testing and examination vendors, law enforcement agencies, and other private federations and associations involved in professional regulation.
                Such disclosure is for identification purposes only. Your social security number will not be released for any other purpose not provided by law.</li>
        </ul>
    );
}

export const GetFirmGeneralContactInformationInstructions = () => {
    return (
        <ul>
            <li>Information already in the system will appear in the fields below. You can make changes, and they will take effect once the application is complete and payment is accepted</li>
            <li>Mandatory fields are indicated with "*" (asterisk) to the left of the field</li>
        </ul>
    );
}

export const GetAppInstruction = () => {
    const [fakeData, setFakeData] = useState("");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    return (
        <>


            <h5>Select the Start a New Application button below and update all required information. </h5>
            <span>Please read carefully the “Requirements/Information” card at the top of each section.  You must complete each section before you can submit to LAPELS staff for review.</span>
            <ul>
                <li>
                    If additional information is requested to complete your application, you must respond within <i>one year</i> of the date you were first notified.
                </li>
                <li>
                    After one year with no response, your application will expire and no further action will be taken by the Board.
                </li>
                <li>
                    After one year you will have to complete a new application if you want to pursue licensure in Louisiana.
                </li>
            </ul>

        </>
    );
}
export const GetReinstateInstruction = () => {
    const [fakeData, setFakeData] = useState("");
    useEffect(() => {
        loremParagraphs().then(setFakeData);
    }, [])

    return (
        <>
            <h5 className="mb-2">Please read carefully the “Requirements/Information” card at the top of each section.  You must complete each section before you can submit to LAPELS staff for review.</h5>

            <ul>
                <li>Apply to reinstate your expired EI/LSI certification, or your expired PE/PLS license.</li>
                <li>There are two applications for PLS/PE licensure reinstatement:</li>
                <ul>
                    <li>Application if expired more than 120 days but less than 5 years</li>
                    <li>Application if expired more than 5 years</li>
                </ul>
                <li>The type of reinstatement application that appears on the next page will be automatically selected based on your license expiration date.</li>
            </ul>

        </>
    );
}

export const GetRenewalInstruction = () => {
    const [banner, setBanner] = useState()
    useEffect(() => {
        renewalService.getRenewalBanner().then((response) => {
            if (response.data) {
                setBanner(response.data)
            }
        })
    }, [])

    return (
        <>
            <h5>Select the Start button below and update all required information. </h5>
            <span>Please read carefully the “Requirements/Information” card at the top of each section.  You must complete each section before you can submit your renewal.</span>
            <br /><br />
            {banner &&
                <div className="bg-danger text-white rounded p-3" dangerouslySetInnerHTML={{ __html: banner }}>
                </div>
            }
        </>
    );
}

export const GetSupervisingProfessionalIndividualInstructions = () => {
    return (<>
        The full text of the Licensure Law and the Rules of the Board are available from the Board's website at <a href="https://www.lapels.com/lawsrules.html" target="_blank">https://www.lapels.com/lawsrules.html</a>.
        <br />
        <p className="pl-4" >§2305. Supervising Professional</p>
        <ol>
            <li>
                Each firm licensed with the board shall designate one or more supervising professionals. Each supervising professional shall be a licensed professional:
            </li>
            <ul>
                <li>
                    a. whose primary employment is with the firm on a full-time basis; or
                </li>
                <li>
                    b. whose secondary employment is with the firm, provided the supervising professional is an owner of the firm.
                </li>
            </ul>
            <li>
                The supervising professionals of an engineering firm shall be professional engineers. The supervising professionals of a land surveying firm shall be professional land surveyors.
            </li>
            <li>
                The responsibilities of a supervising professional include:
            </li>
            <ul>
                <li>a. renewal of the firm’s license and notification to the board of any change in the firm’s supervising professionals;</li>
                <li>b. institution of and adherence to policies of the firm that are in accordance with the licensure law and the rules of the board; and</li>
                <li>c. ensuring that all professional services provided by the firm are performed by or under the responsible charge of licensed professionals.</li>
            </ul>

            <li>
                Nothing herein shall prohibit a supervising professional from also being in responsible charge of professional services provided by the firm.
            </li>
            <li> A failure to comply with any of the provisions of this Chapter may subject both the licensed firm and the supervising professional to disciplinary action by the board.
            </li>


        </ol>


        <p>
            I acknowledge my responsibility to keep my SP locker current. I further understand that failure to comply with such requirements of any false statements made by me, on behalf of the firm, could be cause for disciplinary action. I acknowledge my responsibility to inform the Board, in writing, within thirty (30) days of any change of my employment or supervising professional status with the firm.
        </p>


        <p>Please complete the information as requested.  If you have any questions, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.</p>
        <hr />
        <p className="mb-1">If at any time you would like to be removed from the firm below, click the “Inactivate” button to remove yourself as SP of the firm. After doing so, notifications will be the sent to your registered email,
            the firm’s registered email, and <a href="mailto:cande@lapels.com">cande@lapels.com</a> that you have removed yourself as SP of the firm below.</p>
    </>
    );
}

export const GetSupervisingProfessionalFirmInstructions = () => {
    return (<>
        <p>Under the provisions of Louisiana Administrative Code, Title 46, Part LXI, Chapter 23, all professional services in the State of Louisiana shall be executed by or under the
            responsible charge of a licensed Professional Engineer or Professional Land Surveyor who is designated by the firm as a supervising professional. Such licensed professional
            shall be an active employee of the firm (a)whose primary employments with the firm on a full-time basis or (b) whose secondary employment is with the firm, the supervising
            professional is an owner of the firm. Such licensed professional shall be licensed in Louisiana and in the same profession as the firm (e.g., a Professional Engineer in an
            engineering firm)
        </p>
        <br />
        <p>Please complete the information as requested. LAPELS does not accept handwritten forms. If you have any questions, please email <a href="mailto:cande@lapels.com">cande@lapels.com</a>.</p>
    </>
    );
}

export const GetCartInstructions = () => {
    return (<>
        <h4>Online</h4>
        <p>Your online payment will include a payment processing fee. A breakdown of the fees associated with all online payments is listed below:</p>
        <ul>
            <li>Payment via credit/debit card: $2.00 service fee + ((License fee + $2.00 service)* .025)</li>
            <li>Payment via ACH/E-Check: $3.00 service fee</li>
            <li>Checks and money orders have no service fee.</li>
        </ul>
        <h4>Mail-In</h4>
        Print off invoice below and mail to LAPELS. Mail-in process.
    </>
    );
}

export const GetInvoiceInstructions = () => {
    return (<>
        <p>Need language</p>
    </>
    );
}