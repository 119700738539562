import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { InputPicker, Form, ControlLabel, Schema, FormControl, FormGroup } from 'rsuite';
import Swal from 'sweetalert2';
import RedStar from '../../../../Shared/GenericComponents/RedStar';
import examTabService from '../../../../../services/Internal/Credential/examTab.service';
import { getExamDiscipline, getExamStatuses } from '../../../../../services/Shared/codeTable.service';

function ExamsTabModal(props) {
    const [exams, setExams] = useState([]);
    const [examStatuses, setExamStatuses] = useState([]);
    const [recordModel, setRecordModel] = useState({
        dateCrte: new Date(),
        deleted: false,
        entityId: '',
        examDate: null,
        id: '',
        comments: '',
        examDiscipline: null,
        status: null,
        userCrte: '',
        modalType: "Add"
    })
    useEffect(() => {
        getExamDiscipline().then(setExams);
        getExamStatuses().then(setExamStatuses);
    }, [])
    useEffect(() => {
        setRecordModel({
            ...props.modaldata
        });
    }, [props.modaldata])
    const { ObjectType, StringType } = Schema.Types;
    const model = Schema.Model({
        examDiscipline: ObjectType().isRequired('This field is required.'),
        examDate: StringType().isRequired('This field is required.')
    })
    const formSubmit = (validation, event) => {
        if (validation) {
            var dataToTransmit = {
                ...recordModel,
                id: recordModel.id === '' ? 0 : recordModel.id,
                attendanceEndDate: recordModel.attendanceEndDate ? new Date(recordModel.attendanceEndDate) : null,
                attendanceStartDate: recordModel.attendanceStartDate ? new Date(recordModel.attendanceStartDate) : null,
                graduationDate: recordModel.graduationDate ? new Date(recordModel.graduationDate) : null
            };
            delete dataToTransmit.modalType;
            examTabService.saveExam(dataToTransmit).then((response) => {
                if (response.data.id) {
                    Swal.fire("Success!", "You have successfully updated an exam record.", "success");
                    props.updateGrid();
                    props.onHide();
                }
            }).catch((response) => {
            })
        }
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {recordModel.modalType} an Exam Record
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="recordForm" fluid model={model} formValue={recordModel} onChange={recordModel => setRecordModel(recordModel)} onSubmit={formSubmit} >
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>Exam Date&nbsp;<RedStar /></strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="date"
                                    block="true"
                                    name="examDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Exam&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block
                                    name="examDiscipline"
                                    placeholder="Select an Exam"
                                    accepter={InputPicker}
                                    data={exams} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Status
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block
                                    name="status"
                                    placeholder="Select a Status"
                                    accepter={InputPicker}
                                    data={examStatuses} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Comments
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    rows={5} name="comments" maxLength={500} />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="recordForm" variant="success">Save</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>)
}
export default ExamsTabModal;