import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ControlLabel, FormControl, FormGroup, Input, InputPicker, SelectPicker } from 'rsuite';
import { titleData, suffixData } from '../../../../fakeapi'
import NumberFormat from 'react-number-format';
import RedStar from '../../GenericComponents/RedStar';
import YesNoRadioOption from '../../RadioButton/YesNoRadioOption';
import { getCountries, getStates } from '../../../../services/Shared/codeTable.service'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import PhoneNumberMask from '../../GenericComponents/PhoneNumberMask';

const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};

function PersonalInformation({ isUserEditable, isForApp, isReadOnly, isNonUsBirthState, isPeByComity, showVisaGreenCardError, isExternal }) {
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])

    useEffect(() => {
        getCountries().then(setCountries);
        getStates().then(setStates);
    }, [])
    return (
        <Card>
            <Card.Header>

                <Row>
                    <Col lg={6}>
                        <h6>Personal Information</h6>
                    </Col>
                    {(isReadOnly && isUserEditable) &&
                        <Col lg={6}>
                            <Row>
                                <Col lg={9}>
                                    <b style={{ color: "red" }}>NOTE:</b> By clicking the Update Contact Information button, the Update Contact Information section will open in a new tab. After you save your changes in this new tab, close this tab and refresh your page to continue your application, renewal, or reinstatement.</Col>
                                <Col lg={3}><Button as={Link} to="/individual/contactinformation" variant="dark" target="_blank">
                                    Update Contact Information</Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>


            </Card.Header>
            <Card.Body>
                {isExternal ? <>
                    {isForApp ?
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            If approved, full name displayed on certificate?
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl readOnly={!isUserEditable} name="certificateName" />
                                </Col>
                            </Row>
                        </FormGroup> :
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Name
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl plaintext tabIndex="-1" name="formattedName" />
                                </Col>
                            </Row>
                        </FormGroup>
                    }
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Maiden Name (if different from last name)
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl plaintext name="maidenName" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Date of Birth&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    name="dateOfBirth"
                                    type="date"
                                    plaintext
                                    tabIndex="-1"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </>
                    : <>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Title&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        accepter={InputPicker}
                                        placeholder=" "
                                        block
                                        data={titleData}
                                        name="title"
                                        tabIndex="-1"
                                        plaintext />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            First Name&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl plaintext name="firstName" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Middle Name (birth name)
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl plaintext name="middleName" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Last Name&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl plaintext name="lastName" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Maiden Name (if different from last name)
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl plaintext={isReadOnly} name="maidenName" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Suffix
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        placeholder=" "
                                        block
                                        data={suffixData}
                                        name="suffix"
                                        accepter={InputPicker}
                                        tabIndex="-1"
                                        plaintext
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        {isForApp &&
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                If approved, full name displayed on certificate?
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col sm={9}>
                                        <FormControl readOnly={!isUserEditable} name="certificateName" />
                                    </Col>
                                </Row>
                            </FormGroup>
                        }
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Date of Birth&nbsp;<RedStar />
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        name="dateOfBirth"
                                        type="date"
                                        plaintext
                                        tabIndex="-1"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </>}
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    City of Birth&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext name="birthCity" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    {isNonUsBirthState ?
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        State/Province/Region
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    name="nonUsBirthState"
                                    plaintext
                                    tabIndex="-1"
                                />
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        State of Birth
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    accepter={InputPicker}
                                    placeholder="Please Select"
                                    block
                                    data={states}
                                    name="birthState"
                                    searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                    tabIndex="-1"
                                    plaintext />
                            </Col>
                        </Row>
                    }
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Country of Birth&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                accepter={InputPicker}
                                placeholder="Please Select"
                                block
                                data={countries}
                                name="birthCountry"
                                tabIndex="-1"
                                plaintext />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>SSN&nbsp;<RedStar /></strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext name="federalId" />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>Email Address&nbsp;<RedStar /></strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext={isReadOnly} name="primaryEmail" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    MyNCEES#
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            {
                                isReadOnly
                                    ?
                                    <FormControl
                                        accepter={NumberFormat}
                                        name="myNceesNumber"
                                        plaintext
                                        format="##-###-##"
                                        displayType={'text'}
                                    />
                                    :
                                    <FormControl
                                        accepter={PhoneNumberMask}
                                        name="myNceesNumber"
                                        format="##-###-##"
                                        mask="_"
                                    />
                            }
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Alternate Email Address
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext={isReadOnly} name="altEmail" />
                        </Col>
                    </Row>
                </FormGroup>



                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Mobile Phone&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            {
                                isReadOnly
                                    ?
                                    <FormControl
                                        accepter={NumberFormat}
                                        name="cellPhoneNum"
                                        plaintext
                                        format="(###) ###-####"
                                        displayType={'text'}
                                    />
                                    :
                                    <FormControl
                                        accepter={PhoneNumberMask}
                                        name="cellPhoneNum"
                                        format="(###) ###-####"
                                        mask="_"
                                    />
                            }
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Work Phone
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            {
                                isReadOnly
                                    ?
                                    <FormControl
                                        accepter={NumberFormat}
                                        name="workPhoneNum"
                                        plaintext
                                        format="(###) ###-####"
                                        displayType={'text'}
                                    />
                                    :
                                    <FormControl
                                        accepter={PhoneNumberMask}
                                        name="workPhoneNum"
                                        format="(###) ###-####"
                                        mask="_"
                                    />
                            }
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Home Phone
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            {
                                isReadOnly
                                    ?
                                    <FormControl
                                        accepter={NumberFormat}
                                        name="homePhoneNum"
                                        plaintext
                                        format="(###) ###-####"
                                        displayType={'text'}
                                    />
                                    :
                                    <FormControl
                                        accepter={PhoneNumberMask}
                                        name="homePhoneNum"
                                        format="(###) ###-####"
                                        mask="_"
                                    />
                            }
                        </Col>
                    </Row>
                </FormGroup>


                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Are you a U.S. Citizen?&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <YesNoRadioOption plaintext radioName="isUsCitizen" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Visa/Green Card No.
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl plaintext={isReadOnly}
                                errorMessage={showVisaGreenCardError ? "This field is required." : ""}
                                name="visaGreenCardNumber" />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    If No, what country?
                                </strong>
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                accepter={InputPicker}
                                placeholder="-"
                                block
                                data={countries}
                                name="citizenshipCountry"
                                tabIndex="-1"
                                plaintext />
                        </Col>
                    </Row>
                </FormGroup>
                {isPeByComity &&
                    <>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Active military stationed in Louisiana
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <YesNoRadioOption isReadOnly={!isUserEditable} radioName="isActiveMilitary" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            I am stationed at (select one)
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl accepter={SelectPicker}
                                        placeholder="Select Station"
                                        searchable={false}
                                        block
                                        readOnly={!isUserEditable}
                                        data={[{ "label": "Camp Beauregard", "value": "Camp Beauregard" }, { "label": "Fort Polk", "value": "Fort Polk" }, { "label": "Barksdale Air Force Base", "value": "Barksdale Air Force Base" }]}
                                        name="baseStationed" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            Spouse of active military stationed in Louisiana
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <YesNoRadioOption isReadOnly={!isUserEditable} radioName="isActiveMilitarySpouse" />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={3}>
                                    <ControlLabel>
                                        <strong>
                                            They are stationed at (select one)
                                        </strong>
                                    </ControlLabel>
                                </Col>
                                <Col sm={9}>
                                    <FormControl accepter={SelectPicker}
                                        placeholder="Select Station"
                                        searchable={false}
                                        block
                                        readOnly={!isUserEditable}
                                        data={[{ "label": "Camp Beauregard", "value": "Camp Beauregard" }, { "label": "Fort Polk", "value": "Fort Polk" }, { "label": "Barksdale Air Force Base", "value": "Barksdale Air Force Base" }]}
                                        name="spouseBaseStationed" />
                                </Col>
                            </Row>
                        </FormGroup>
                    </>
                }
                <FormGroup>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>
                                <strong>
                                    Additional Information
                                </strong><br />
                                <em>Max 1000 characters including spaces</em>

                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                accepter={Input}
                                componentClass="textarea"
                                rows={5}
                                name="additionalInformation"
                                plaintext={isReadOnly}
                                maxLength={1000}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </Card.Body>
        </Card>
    );
}
export default PersonalInformation;