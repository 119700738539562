import axios from "axios";
import authHeader from '../../../authheader'

const VFAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/FirmVfApplication";

const getViolation = (applicationId) => {
    return axios.get(`${VFAPPLICATIONURL}/violation`, { headers: authHeader(), params: { applicationId } });
}
const postViolation = (data) => {
    return axios.post(`${VFAPPLICATIONURL}/violation`, data, { headers: authHeader() });
}
export default {
    getViolation,
    postViolation
}