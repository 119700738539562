import React, { useState } from 'react';
import { Card, Col, FormGroup, Row } from "react-bootstrap";
import { FormControl, Uploader } from 'rsuite';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';
import RedStar from "../GenericComponents/RedStar";
import GenericAttachmentGrid from '../Grid/GenericAttachmentGrid';
import YesNoRadioOption from '../RadioButton/YesNoRadioOption';
import attachmentService from '../../../services/Shared/attachment.service';
import DocumentUpload from '../Upload/DocumentUpload';
function UnlicensedActivity(props) {
    return (
        <div className="vfAppUnlicensedActivity">
            <Card>
                <Card.Header><h6>Unlicensed Activity in Louisiana</h6></Card.Header>
                <Card.Body>
                    <p><RedStar />Has your firm ever practiced or offered to practice engineering or land surveying in Louisiana?</p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasUnlicensedActivity" />



                    {props.showAdditionalFields ?
                        <>
                            <p><RedStar /> If the answer to any of these questions is "Yes", explain in detail the facts surrounding such practicing or offering
                                to practice and submit a project list, with offer date, and start/completion dates of all engineering and land surveying work
                                which the firm has offered and/or performed in Louisiana during the past five years. Upload additional pages if necessary. You may
                                be required to submit additional information on these projects at a later date.</p>
                            <FormGroup>
                                <FormControl readOnly={props.isReadOnly} name="explanation" componentClass="textarea" rows={4} />
                            </FormGroup>

                            <DocumentUpload
                                isUserEditable={!props.isReadOnly}
                                credentialTypeId={props.prefix == 'EF' ? 1 : 2}
                                sectionId={props.sectionId}
                                //appTypeId={ }
                                //refreshData={ }
                                parentId={props.sectionDataId}
                                parentTypeId={props.parentTypeId}
                                entityId={props.entityId}
                                fileUploadTitle={"Additional Pages/Court Documents"}
                            />
                        </>
                        :
                        null}
                    <br />

                </Card.Body>
            </Card>
        </div>
    )
}
export default UnlicensedActivity;