import React from 'react';
import { Card } from 'react-bootstrap'
import { FormGroup, RadioGroup, FormControl, Radio} from 'rsuite'
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function JournalPrivacyCard() {
    return (
        <div className="peRenewalPrivacyCard">
            <Card>
                <Card.Header><h6>Journal & Privacy (Professional)</h6></Card.Header>
                <Card.Body>
                    <p><RedStar /> Please send me the Louisiana Engineer & Land Surveyor Journal via: </p>
                    <br />
                    <FormGroup controlId="formBasicCheckbox">
                        <FormControl name="receiveMethod" inline accepter={RadioGroup}>
                            <Radio value="email">Email</Radio>
                            <Radio value="mail">Mail</Radio>
                            <Radio value="none">Do Not Send</Radio>
                        </FormControl>
                    </FormGroup>
                    <p><RedStar /> Do you want your email address(es) and telephone number(s), which you have provided us, to be kept private and not made available to vendors requesting rosters for distribution
                        of engineering/land surveying CPD information or to other third parties?</p>
                    <br />
                    <FormGroup controlId="formBasicCheckbox">
                        <FormControl name="emailPhonePrivacy" inline accepter={RadioGroup}>
                            <Radio value={true}>Yes, keep private</Radio>
                            <Radio value={false}>No, share when requested</Radio>
                        </FormControl>
                    </FormGroup>
                </Card.Body>
            </Card>
        </div>
    )
}

export default JournalPrivacyCard;