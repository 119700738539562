import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { InputPicker, SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup } from 'rsuite';
import Swal from 'sweetalert2';
import educationTabService from '../../../../../services/Internal/Credential/educationTab.service';
import { getDegrees, getSchools, getStatuses } from '../../../../../services/Shared/codeTable.service';
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function EducationTabModal(props) {
    const [schools, setSchools] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [recordModel, setRecordModel] = useState({
        attendanceEndDate: null,
        attendanceStartDate: null,
        legacyDatesAttended: '',
        legacyDegreeOrSpecialty: '',
        dateCrte: null,
        degree: null,
        deleted: false,
        entityId: '',
        graduationDate: null,
        id: 0,
        major: '',
        school: null,
        userCrte: '',
        modalType: "Add"
    })

    useEffect(() => {
        getSchools().then(setSchools);
        getDegrees().then(setDegrees);
        getStatuses().then(setStatuses);
    }, [])
    useEffect(() => {
        setRecordModel({
            ...props.modaldata
        });
    }, [props.modaldata])

    const { ObjectType } = Schema.Types;
    const model = Schema.Model({
        school: ObjectType().isRequired('This field is required.')
    })
    const formSubmit = (validation, event) => {
        if (validation)
            submitData();
    }
    const submitData = () => {
        var dataToTransmit = {
            ...recordModel,
            id: recordModel.id === '' ? 0 : recordModel.id,
            attendanceEndDate: recordModel.attendanceEndDate ? new Date(recordModel.attendanceEndDate) : null,
            attendanceStartDate: recordModel.attendanceStartDate ? new Date(recordModel.attendanceStartDate) : null,
            graduationDate: recordModel.graduationDate ? new Date(recordModel.graduationDate) : null
        };
        delete dataToTransmit.modalType;
        educationTabService.saveEducation(dataToTransmit).then((response) => {
            if (response.data.id) {
                Swal.fire("Success!", "You have successfully updated an education record.", "success");
                props.updateGrid();
                props.onHide();
            }
        }).catch((response) => {
        })
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {recordModel.modalType} an Education Record
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="recordForm" fluid model={model} formValue={recordModel} onChange={recordModel => setRecordModel(recordModel)} onSubmit={formSubmit} >
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Institution Name&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block
                                    name="school"
                                    placeholder="Select an Institution"
                                    accepter={InputPicker}
                                    data={schools} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Status
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block
                                    name="schoolStatus"
                                    placeholder="Select a Status"
                                    accepter={InputPicker}
                                    data={statuses} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Date From
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="month"
                                    block="true"
                                    name="attendanceStartDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Date To
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="month"
                                    block="true"
                                    name="attendanceEndDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>Legacy Dates Attended</strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block="true"
                                    maxLength={50}
                                    name="legacyDatesAttended" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>Graduation Date</strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    type="date"
                                    block="true"
                                    name="graduationDate" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Degree
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl accepter={SelectPicker}
                                    placeholder="Select a Degree"
                                    searchable={false}
                                    block
                                    data={degrees}
                                    name="degree" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>Legacy Degree</strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    block="true"
                                    maxLength={50}
                                    name="legacyDegreeOrSpecialty" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <ControlLabel>
                                    <strong>
                                        Major
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={9}>
                                <FormControl name="major" maxLength={250} />
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="recordForm" variant="success">Save</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EducationTabModal;