import React from "react"
import Table from 'react-bootstrap/Table'
import GenericGridAction from './GenericGridAction'
import GenericGridStatus from "./GenericGridStatus";

// Will take in a json as data and use a 2D array for the columns
function GenericGrid({ columns, data, theadStyle, tStyle }) {
    let names = [];
    let props = [];
    for (var i = 0; i < columns.length; i++) {
        names.push(columns[i][0]);
        props.push(columns[i][1]);
    }
    let headers = <tr>{names.map((key, index) => { return <th key={index}>{key}</th> })}</tr>;
    let rows = data.map((item, i) => {
        return (
            <tr key={item.id} id={i}>{props.map((prop, p) => {
                return (prop == "actions" && item[prop]) ?
                    <td key={p}>{item[prop].map((command, c) => {
                        if (command.actionText)
                            return <GenericGridAction actionName={command.actionText} actionUrl={command.actionUrl} key={c} />
                        else
                            return <GenericGridAction actionName={command} actionUrl="" key={c} />
                    })}</td>
                    :
                    (
                        ((prop == "status" || prop == "licStatus") && item[prop]) ? <td key={p}>
                            <GenericGridStatus status={item[prop].status} extraInfo={item[prop].extraInfo} />
                        </td> :
                            <td key={p}>{item[prop]}</td>
                    )
            })}</tr>
        )
    })

    return (
        <Table hover className={tStyle}>
            <thead className={theadStyle}>
                {headers}
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}

export default GenericGrid;