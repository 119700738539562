import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import Issues from '../../../../Shared/Card/Issues';
import { useSelector } from 'react-redux';
import ViolationService from '../../../../../services/Internal/Reinstatement/EF/violation.service';
import reinstatementService from '../../../../../services/Internal/Reinstatement/EF/efReinstatement.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/EFInformation';
import Information from '../../../../Shared/Card/Information';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import { getReinstatementCodeViolationAnswers } from '../../../../../services/Shared/codeTable.service';
import GenericViolations from '../../../../Shared/Card/Violations';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function Violations() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 3;
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineering Firm Reinstatement",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Violations"
        }
    );
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        yesAnswerExplanation: StringType().isRequired('This field is required.'),
    });
    useEffect(() => {
        setHasLoaded(false);
        reinstatementService.efGetSection(store.currentApplicationId, sectionId).then((response) => {
            setIsComplete(response.data.staffReviewComplete);
            reinstatementService.getFirmReinstatement(store.currentApplicationId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                });
                ViolationService.getViolation(store.currentApplicationId).then((data) => {
                    if (data.data) {
                        updateFormData({ ...data.data, files: formData.files, filesForDisplay: formData.filesForDisplay });
                    }
                    else {
                        getReinstatementCodeViolationAnswers(1).then((questions) => {
                            updateFormData({ ...formData, answers: [...questions] })
                        })
                    }
                    setSectionData(response.data);
                    setHasLoaded(true);
                })
            });
        })
    }, [])
    const store = useSelector(x => x);

    const initialFormData = Object({
        id: 0,
        yesAnswerExplanation: "",
        files: [],
        filesForDisplay: []
    });

    const { push } = useHistory();
    const [formData, updateFormData] = useState(initialFormData);
    const showAdditionalFields = formData?.answers?.findIndex(x => x.answer == true) >= 0;
    const [showFormErrors, setShowFormErrors] = useState(false);

    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            setShowFormErrors(true)
            var validationObject = model.check(formData);
            for (const key in validationObject) {
                if (validationObject[key].hasError && (!key.includes("yesAnswerExplanation") || (showAdditionalFields && key.includes("yesAnswerExplanation")))) {
                    return;
                }

            }
            if (formData?.answers?.findIndex(x => x.answer !== true && x.answer !== false) >= 0) {//undefined check
                return;
            }
            if (showAdditionalFields === false || val) {
                var dataTransfer = { ...formData, reinstatementId: store.currentApplicationId, entityId: store.currentApplicationEntityId };
                ViolationService.postViolation(dataTransfer).then((response) => {
                    reinstatementService.efPostSection(store.currentApplicationId, sectionId, formData.signature).then((response) => {
                        reinstatementService.efMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((data) => {
                            if (response.data && data.data) {
                                push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                            }
                        })
                    })
                });
            }
        }
    }

    return (
        <div className="efReinstatementViolationsPage">
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={updateFormData} fluid formValue={formData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col> <Information content={GetReinstatementInformationContent("violations")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <GenericViolations
                                        showFormErrors={showFormErrors}
                                        showAdditionalFields={showAdditionalFields}
                                        hideSignature={true}
                                        isReadOnly={!isUserEditable}
                                        isInternal={true}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        entityId={entityInfo.entityId}
                                        parentTypeId={2}
                                        credentialTypeId={1}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                showSectionComplete={true}
                                backToSectionLink="/internal/efreinstatement/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    )
}
export default Violations;