import React, { useState } from 'react';
import RegisterNewMemberForm from './RegisterNewMemberForm';


function RegisterNewMemberPage() {
    const bg = {
        backgroundColor: '#2976a3',
        padding: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"

    }
    return (
        <div style={bg}>
            <RegisterNewMemberForm />
        </div>)
}

export default RegisterNewMemberPage;