import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function FormActionButtons(props) {

    return (
        <Container fluid>
            <Row className="mt-3">
                {props.showSectionComplete === true ?
                    <Col style={{ display: "flex", alignItems: "center" }}>
                        <label className="switch">
                            <input id="completeSectionCheckbox" checked={props.isComplete} onChange={() => { if (props.isUserEditable) props.isCompleteCallback(!props.isComplete); }} type="checkbox" />
                            <span className="slider round"></span>
                        </label>&nbsp;
                        <label htmlFor="completeSectionCheckbox">
                            <b>Mark Section as Complete</b>
                        </label> &nbsp;&nbsp;
                        <Button name="backToSections" as={Link} to={props.backToSectionLink} variant="primary" className="mr-2">Back to Sections</Button>
                        {
                            props.submitFormName ?
                                <Button name="nextSection" type="submit" form={props.submitFormName} variant="dark" className="mr-2">Next Section</Button>
                                :
                                <Button name="nextSection" type="submit" variant="dark" className="mr-2">Next Section</Button>
                        }
                    </Col>
                    :
                    <Col>
                        <div className="float-right">
                            <Button name="backToSections" as={Link} to={props.backToSectionLink} variant="primary" className="mr-2">Back to Sections</Button>
                            {
                                props.submitFormName ?
                                    <Button name="nextSection" type="submit" form={props.submitFormName} variant="dark" className="mr-2">Next Section</Button>
                                    :
                                    <Button name="nextSection" type="submit" variant="dark" className="mr-2">Next Section</Button>
                            }
                        </div>
                    </Col>
                }
            </Row>
        </Container>
    );
}


export default FormActionButtons;