import axios from "axios";
import authHeader from '../../authheader'

const INDIVIDUALAPP = process.env.REACT_APP_APIURL + "/api/IndividualApplication";



const getPendingApplications = async () => {
    return axios.get(`${INDIVIDUALAPP}/pendingApplications`, { headers: authHeader() });;
};
const getIndividualApplication = async (applicationId) => {
    return axios.get(`${INDIVIDUALAPP}/application`, { headers: authHeader(), params: { applicationId } });;
};

const createApplication = (applicationTypeId) => {
    return axios.post(`${INDIVIDUALAPP}/create`, null, { headers: authHeader(), params: { applicationTypeId } })
        .then((response) => {
            return response;
        });
}
const validateApplication = (applicationId) => {
    return axios.post(`${INDIVIDUALAPP}/validate`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const submitApplication = (applicationId) => {
    return axios.post(`${INDIVIDUALAPP}/submit`, null, { headers: authHeader(), params: { applicationId } })
        .then((response) => {
            return response;
        }).catch((res) => {
        });
}
const plsGetSection = (applicationId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/section`, { headers: authHeader(), params: { applicationId, sectionId } });
}
const plsPostSection = (applicationId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/signsection`, null, { headers: authHeader(), params: { applicationId, sectionId, electronicSignature } });
}
const plsPostSectionHasRef = (applicationId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/setsectionhasreferences`, null, { headers: authHeader(), params: { applicationId, sectionId, hasReferences } });
}

const plsPostSectionHasExperience = (applicationId, sectionId, hasMyNceesExperience) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/setsectionhasmynceesexperience`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesExperience } });
}

//const plsPostSectionHasMyNceesEducation = (applicationId, sectionId, hasMyNceesEducation) => {
//    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/setsectionhasmynceeseducation`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation } });
//}

const plsPostEducationSection = (applicationId, sectionId, hasMyNceesEducation, hasAnsacDegree, hasFourYearDegree) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/seteducationsection`, null, { headers: authHeader(), params: { applicationId, sectionId, hasMyNceesEducation, hasAnsacDegree, hasFourYearDegree } });
}
const plsMarkSectionComplete = (applicationId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPlsApplication/marksectioncomplete`, null, { headers: authHeader(), params: { applicationId, sectionId, complete } })
}

const postStaffApproval = (applicationId, isApproved) => {
    return axios.post(`${INDIVIDUALAPP}/staffApproval`, null, { headers: authHeader(), params: { applicationId, isApproved } });
}
const postApplicationPdf = (applicationId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/Reports/AutoAttachApplicationPdf`, null, { headers: authHeader(), params: { applicationId, isApproved } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Complete this section providing all required contact information.",
    },
    {
        id: 2,
        sectionTitle: "Education",
        sectionContent: "Complete this section listing all colleges and universities attended where a degree was conferred.",
    },
    {
        id: 3,
        sectionTitle: "Personal References",
        sectionContent: "Complete this section listing all personal references.",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 5,
        sectionTitle: "Employment",
        sectionContent: "Verify Louisiana Employment Firm licensure",
    },
    {
        id: 6,
        sectionTitle: "Quizzes",
        sectionContent: "Quiz content here",
    },
    {
        id: 7,
        sectionTitle: "Experience Record",
        sectionContent: "Total experience record summary",
    },
    {
        id: 8,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 9,
        sectionTitle: "Review",
        sectionContent: 'Review your answers. If you need to change an answer, click the "Section" above to go back to the appropriate section',
    },
    {
        id: 10,
        sectionTitle: "Board/Staff Recommendations",
        sectionContent: 'Waiting on text',
    }
])

export default {
    getPendingApplications,
    getIndividualApplication,
    createApplication,
    plsGetSection,
    plsPostSection,
    plsPostSectionHasRef,
    //plsPostSectionHasMyNceesEducation,
    plsPostSectionHasExperience,
    validateApplication,
    submitApplication,
    plsMarkSectionComplete,
    postStaffApproval,
    postApplicationPdf,
    plsPostEducationSection
}