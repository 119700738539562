import React from 'react'

function LoadingSpinner() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="loadingSpinner">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}
export default LoadingSpinner;