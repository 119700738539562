import axios from "axios";
import authHeader from '../../authheader'

const STAFFDASHBOARDURL = process.env.REACT_APP_APIURL + "/api/Staff";

const getDashboardNumbers = async () => {
    return axios.get(`${STAFFDASHBOARDURL}/dashboard`, { headers: authHeader() });;
};

export default {
    getDashboardNumbers
}
