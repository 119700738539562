import React from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import GenericGrid from '../../../Shared/Grid/GenericGrid';
function PendingApplications({ data, schema }) {
    const pendingAppSchema =  [
        ['License Type', 'licenseType'],
        ['Status', 'pendingApplicationStatus']
    ]
    return (
        <div className="profilePendingApps">
            <Card>
                <Card.Header>
                    <Card.Title>
                        <b>Pending Applications</b>
                        <span style={{ float: 'right' }}>
                            <Button as={Link} to="/individual/applications" variant="success" className="text-uppercase">Check Status</Button>
                        </span>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <GenericGrid data={data} columns={pendingAppSchema}/>
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default PendingApplications;