import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Sidenav, Nav, Dropdown } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import * as FaIcons from 'react-icons/fa';
import logo from '../../../images/LAPELS-LOLA-Logo.png'
import Swal from 'sweetalert2';
import batchService from '../../../services/Internal/Revenue/batch.service';
import RecentContacts from './RecentContacts';
import RecentQueue from './RecentQueue';

function SideNav(props) {
    const [activeKey, setActiveKey] = React.useState();
    const { push } = useHistory();
    useEffect(() => {
        if (props.activeKey) {
            setActiveKey(props.activeKey);
        } else {
            setActiveKey("1");
        }
    }, [])
    const clearCacheOnNav = () => {
        localStorage.removeItem("searchCache")
        localStorage.removeItem("currentPageCache")
        localStorage.removeItem("displayLengthCache")
    }
    function createBatchClick() {
        batchService.hasOpenedBatchForDay().then(response => {
            if (response.data) {
                Swal.fire({
                    title: 'Cannot Create Batch',
                    text: 'You have already created a batch today.',
                    icon: 'error',
                    confirmButtonText: `Close`,
                }).then((result) => {

                })
            }
            else {
                batchService.checkBatchOpened().then(checkBatch => {
                    if (checkBatch.data) {
                        Swal.fire({
                            title: 'Cannot Create Batch',
                            text: 'You have an open batch. Go to to the open batch to view.',
                            icon: 'error',
                            confirmButtonText: `Close`,
                        }).then((result) => {

                        })
                    }
                    else {
                        batchService.createBatch().then(response => {
                            if (response.data?.id) {
                                push("/internal/revenue/createbatch?batchId=" + response.data.id);
                            }
                        })
                    }
                })
            }
        })
    }
    return (
        <div className='sidenav'>
            <Sidenav activeKey={activeKey} onSelect={setActiveKey}>
                <Sidenav.Header>
                    <div className='heads'>
                        <img src={logo} style={{ width: '60px' }} />
                        <b> LOLA</b>
                    </div>
                </Sidenav.Header>
                <Sidenav.Body>
                    <Nav >
                        <Nav.Item eventKey="1" icon={<FaIcons.FaTh className="rs-icon sideBarIcon" />} componentClass={Link} to="/internal/dashboard">
                            Dashboard
                        </Nav.Item>
                        <Nav.Item eventKey="2" icon={<FaIcons.FaServer className="rs-icon sideBarIcon" />} componentClass={Link} to="/internal/queue">
                            Queue
                        </Nav.Item>
                        <Nav.Item eventKey="3" icon={<FaIcons.FaSearch className="rs-icon sideBarIcon" />} componentClass={Link} onClick={() => { clearCacheOnNav() }} to="/internal/search/">
                            Search
                        </Nav.Item>
                        {/*<Dropdown eventKey="4" title="New" icon={<FaIcons.FaPlusCircle className="rs-icon sideBarIcon" />}>*/}
                        {/*    <Dropdown.Item eventKey="4-1">Link 1</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="4-2">Link 2</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="4-3">Link 4</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="4-4">Link 5</Dropdown.Item>*/}
                        {/*</Dropdown>*/}
                        {/*<Dropdown eventKey="5" title="Enforcement" icon={<FaIcons.FaFlag className="rs-icon sideBarIcon" />}>*/}
                        {/*    <Dropdown.Item eventKey="5-1">Link 1</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="5-2">Link 2</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="5-3">Link 4</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="5-4">Link 5</Dropdown.Item>*/}
                        {/*</Dropdown>*/}
                        <Dropdown eventKey="6" title="Revenue" icon={<FaIcons.FaCreditCard className="rs-icon sideBarIcon" />}>
                            <Dropdown.Item eventKey="6-1" onClick={createBatchClick}>Create Batch</Dropdown.Item>
                            <Dropdown.Item eventKey="6-2" componentClass={Link} to="/internal/revenue/batchsearch">Batch Search</Dropdown.Item>
                            <Dropdown.Item eventKey="6-3" componentClass={Link} to="/internal/revenue/paymentsearch">Payment Search</Dropdown.Item>
                            <Dropdown.Item eventKey="6-4" componentClass={Link} to="/internal/revenue/createinvoice">Create Invoice</Dropdown.Item>
                            <Dropdown.Item eventKey="6-5" componentClass={Link} to="/internal/revenue/invoicesearch">Invoice Search</Dropdown.Item>
                            <Dropdown.Item eventKey="6-6" componentClass={Link} to="/internal/revenue/dailyrefundsearch">Daily Refunds</Dropdown.Item>
                            <Dropdown.Item eventKey="6-7" componentClass={Link} to="/internal/revenue/dailypaymentsbyaccount">Daily Payments By Account</Dropdown.Item>
                        </Dropdown>
                        {/*<Dropdown eventKey="7" title="Reports" icon={<FaIcons.FaCopy className="rs-icon sideBarIcon" />}>*/}
                        {/*    <Dropdown.Item eventKey="7-1">Link 1</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="7-2">Link 2</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="7-3">Link 4</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="7-4">Link 5</Dropdown.Item>*/}
                        {/*</Dropdown>*/}
                        {/*<Dropdown eventKey="8" title="Admin" icon={<FaIcons.FaUserCog className="rs-icon sideBarIcon" />}>*/}
                        {/*    <Dropdown.Item eventKey="8-1">Link 1</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="8-2">Link 2</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="8-3">Link 4</Dropdown.Item>*/}
                        {/*    <Dropdown.Item eventKey="8-4">Link 5</Dropdown.Item>*/}
                        {/*</Dropdown>*/}
                    </Nav>
                    <RecentQueue />
                    <RecentContacts />
                </Sidenav.Body>
            </Sidenav>
        </div>
    );
}

export default SideNav;
