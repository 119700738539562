import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getContactInformation = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/contactinformation`, { headers: authHeader(), params: {applicationId} });
}
const postContactInformation = (applicationId, certDisplayName, isActiveMilitary, baseStationed, isActiveMilitarySpouse, spouseBaseStationed) => {
    return axios.post(`${PEAPPLICATIONURL}/contactinformation`, null, { headers: authHeader(), params: { applicationId, certDisplayName, isActiveMilitary, baseStationed, isActiveMilitarySpouse, spouseBaseStationed } });
}
export default {
    getContactInformation,
    postContactInformation
}