import axios from "axios";
import authHeader from '../../../authheader'

const peGetSection = (renewalId, sectionId) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/api/IndividualPeRenewal/section`, { headers: authHeader(), params: { renewalId, sectionId } });
}
const pePostSection = (renewalId, sectionId, electronicSignature) => {

    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeRenewal/signsection`, null, { headers: authHeader(), params: { renewalId, sectionId, electronicSignature } });
}
const pePostSectionHasRef = (renewalId, sectionId, hasReferences) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeRenewal/setsectionhasreferences`, null, { headers: authHeader(), params: { renewalId, sectionId, hasReferences } });
}
const pePostSectionHasCpd = (renewalId, sectionId, hasCpd) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeRenewal/setsectionhascpd`, null, { headers: authHeader(), params: { renewalId, sectionId, hasCpd } });
}
const peMarkSectionComplete = (renewalId, sectionId, complete) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeRenewal/marksectioncomplete`, null, { headers: authHeader(), params: { renewalId, sectionId, complete } })
}

const postStaffApproval = (renewalId, isApproved) => {
    return axios.post(`${process.env.REACT_APP_APIURL}/api/IndividualPeRenewal/staffApproval`, null, { headers: authHeader(), params: { renewalId, isApproved } });
}

export const getSections = async () => ([
    {
        id: 1,
        sectionTitle: "Contact Information",
        sectionContent: "Ensure your contact information, including your mailing and public addresses are up to date.  Ensure you put LAPELS emails on your safe sender list.",
    },
    {
        id: 2,
        sectionTitle: "License Status",
        sectionContent: "Verify the staus of your license.",
    },
    {
        id: 3,
        sectionTitle: "CPD Requirements",
        sectionContent: "Verify CPD requirements for Active status for each of the calendar years.",
    },
    {
        id: 10,
        sectionTitle: "Building Design ",
        sectionContent: "Complete this section verifing your building design experience..",
    },
    {
        id: 4,
        sectionTitle: "Violations",
        sectionContent: "Subject to disciplinary action by the licensing authority.",
    },
    {
        id: 5,
        sectionTitle: "Supervising Professional",
        sectionContent: "Certify if you are a supervising professional on behalf of a firm.",
    },
    {
        id: 6,
        sectionTitle: "Journal & Phone Privacy Options",
        sectionContent: "Verify your subscription and distribution options.",
    },
    {
        id: 7,
        sectionTitle: "Acknowledgment",
        sectionContent: "Electronically sign (type your name) and date.",
    },
    {
        id: 8,
        sectionTitle: "Review",
        sectionContent: "Review certificate renewal.",
    }
])

export default {
    peGetSection,
    pePostSection,
    pePostSectionHasRef,
    pePostSectionHasCpd,
    peMarkSectionComplete,
    postStaffApproval
}