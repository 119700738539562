import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/VFInformation';
import renewalService from '../../../../../services/Internal/Renewal/VF/vfRenewal.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import { Form, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import WavierCertificate from '../../../../Shared/Card/WaiverCertificate';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Issues from '../../../../Shared/Card/Issues';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function WaiverCertificatePage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [sectionData, setSectionData] = useState({});
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Surveying Firm Renewal",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Certificate of Authority"
        }
    );
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 9;
    const { StringType, BooleanType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.')
    });
    useEffect(() => {
        setHasLoaded(false);
        renewalService.vfGetSection(store.currentApplicationId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete);
            renewalService.getFirmRenewal(store.currentApplicationId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })


    }, [])
    const store = useSelector(x => x);

    const { push } = useHistory();

    const handleSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (val) {
            renewalService.vfPostSection(store.currentApplicationId, sectionId, sectionData.electronicSignature).then((response) => {
                if (response.data) {
                    renewalService.vfMarkSectionComplete(store.currentApplicationId, sectionId, isComplete).then((markSectionResponse) => {
                        if (markSectionResponse.data) {
                            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                        }
                        else
                            Swal.fire("Failed to mark section as complete");
                    });
                }
            });
        }
    }

    return (
        <div>
            <Container fluid>
                <Form model={model} onSubmit={handleSubmit} onChange={setSectionData} fluid formValue={sectionData}>
                    <FormEntityInformation {...entityInfo} />
                    <Row className="mb-3">
                        <Col> <Information content={GetRenewalInformationContent("waiver")} /></Col>
                    </Row>
                    {hasLoaded ?
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <WavierCertificate
                                        isReadOnly={!isUserEditable}
                                        isRenewal={true}
                                        isInternal={true}
                                        sectionId={sectionId}
                                        sectionDataId={sectionData.id}
                                        entityId={entityInfo.entityId}
                                        parentTypeId={3}
                                        credentialTypeId={2}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Issues isUserEditable={isUserEditable} isInternal={true} type={3} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                            <FormActionButtons
                                isUserEditable={isUserEditable}
                                isComplete={isComplete}
                                isCompleteCallback={setIsComplete}
                                showSectionComplete={true}
                                backToSectionLink="/internal/vfRenewal/sections" />
                        </> : <LoadingSpinner />
                    }
                </Form>
            </Container>
        </div>
    );
}
export default WaiverCertificatePage