import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import { Table } from 'rsuite';
import reinstatementService from '../../../../services/Public/Reinstatement/reinstatement.service';
import Swal from 'sweetalert2';
import Information from '../../../Shared/Card/Information';
import { GetReinstateInstruction } from '../../../Shared/InformationContent/GeneralInformation';

function Reinstatements() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [credentialList, setCredentialList] = useState([]);

    const [hasLoadedHistorical, setHasLoadedHistorical] = useState(false);
    const [historicalRecords, setHistoricalRecords] = useState([]);

    const { push } = useHistory();
    const dispatch = useDispatch();

    const navigateAndSetCurrentApp = (rowData) => {
        var credentialTypeId = rowData.credential?.credentialType?.id;
        if (credentialTypeId === 5 || credentialTypeId === 6 || credentialTypeId == 3 || credentialTypeId == 4) {
            dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: rowData.reinstatementId });
            if (rowData?.sections?.length) {
                setUpRoutes(rowData.sections);
            }
            if (credentialTypeId === 5)
                push("/individual/plsReinstatement/sections");
            else if (credentialTypeId === 6)
                push("/individual/peReinstatement/sections");
            else if (credentialTypeId === 3)
                push("/individual/eiReinstatement/sections");
            else if (credentialTypeId === 4)
                push("/individual/lsiReinstatement/sections");
        }
        else {
            Swal.fire("Not implemented");
        }
    }

    const setUpRoutes = (sections, credentialTypeId) => {
        var route = [];
        for (var i = 0; i < sections.length; i++) {
            let sectionId = sections[i].sectionType.id
            var link = "";
            if (credentialTypeId === 5)
                link = "/individual/plsReinstatement/";
            else if (credentialTypeId === 6)
                link = "/individual/peReinstatement/";
            else if (credentialTypeId === 3)
                link = "/individual/eiReinstatement/";
            else if (credentialTypeId === 4)
                link = "/individual/lsiReinstatement/";
            switch (sectionId) {
                case 1:
                    link += "contactInformation"
                    break;
                case 2:
                    link += "employment"
                    break;
                case 3:
                    link += "violations"
                    break;
                case 4:
                    link += "quizzes"
                    break;
                case 5:
                    link += "cpdRequirements"
                    break;
                case 6:
                    link += "personalReferences"
                    break;
                case 7:
                    link += "experience"
                    break;
                case 8:
                    link += "disclosure"
                    break;
                case 9:
                    link += "acknowledgement"
                    break;
                case 10:
                    link += "review"
                    break;
                case 15:
                    link += "design"
                    break;
                case 16:
                    link += "education"
                    break;
                case 17:
                    link += "examination"
                    break;
                default:
            }
            route.push({ sectionId: sections[i].sectionType.id, route: link })
        }
        dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: route })
    }

    const createAndSetCurrentApp = (rowData) => {
        var credentialTypeId = rowData.credential?.credentialType?.id;
        if (credentialTypeId === 5 || credentialTypeId === 6 || credentialTypeId === 3 || credentialTypeId === 4) {
            reinstatementService.createReinstatement(rowData.credential.credentialType.id, rowData.credential.id).then((response) => {
                reinstatementService.getIndividualReinstatement(response.data).then((data) => {
                    dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: response.data });
                    setUpRoutes(data.data.sections, credentialTypeId);
                    if (credentialTypeId === 5)
                        push("/individual/plsReinstatement/sections");
                    else if (credentialTypeId === 6)
                        push("/individual/peReinstatement/sections");
                    else if (credentialTypeId === 3)
                        push("/individual/eiReinstatement/sections");
                    else if (credentialTypeId === 4)
                        push("/individual/lsiReinstatement/sections");
                })
            })
        }
        else {
            Swal.fire("Not implemented");
        }
    }

    useEffect(() => {
        reinstatementService.getPendingReinstatements().then((response) => {
            if (response.data.length)
                setCredentialList(response.data);
            setHasLoaded(true);
        })
        reinstatementService.getHistoricalReinstatements().then((response) => {
            if (response.data.length)
                setHistoricalRecords(response.data.sort((a, b) => { return new Date(b.dateSubmitted) - new Date(a.dateSubmitted) }));
            setHasLoadedHistorical(true);
        })
    }, [])

    return (
        <div className="reinstatementPage">
            <Container fluid>
                <h4 className="mb-3">Reinstatements</h4>
                <Row>
                    <Col><Information content={GetReinstateInstruction()} /></Col>
                </Row>
                <br />
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Header><h6>Reinstate a license</h6></Card.Header>
                            <Card.Body>
                                {hasLoaded ?
                                    <Table wordWrap autoHeight data={credentialList}>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (<Button onClick={() => {
                                                    if (rowData.reinstatementId)
                                                        navigateAndSetCurrentApp(rowData);
                                                    else
                                                        createAndSetCurrentApp(rowData);
                                                }}>
                                                    {
                                                        rowData.dateSubmitted
                                                            ? rowData.sections.filter(x => x.isUserEditable == true).length
                                                                ? "Continue"
                                                                : "View"
                                                            : (rowData.reinstatementId
                                                                ? "Continue"
                                                                : "Start")}</Button>)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={3}>
                                            <Table.HeaderCell>
                                                License
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.formattedLicenseNumber)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Expiration Date
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.expirationDate ? new Date(rowData.credential?.expirationDate).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Created
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Submitted
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateSubmitted ? new Date(rowData.dateSubmitted).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Review Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>

                                    </Table> : <LoadingSpinner />
                                }
                            </Card.Body>
                        </Card></Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header><h6>Historical Reinstatements</h6></Card.Header>
                            <Card.Body>
                                {hasLoadedHistorical ?
                                    <Table wordWrap autoHeight data={historicalRecords}>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.formattedLicenseNumber)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                License Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Expiration Date
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.credential?.expirationDate ? new Date(rowData.credential?.expirationDate).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Created
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateCrte ? new Date(rowData.dateCrte).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Submitted
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateSubmitted ? new Date(rowData.dateSubmitted).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Date Approved/Declined
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.dateActioned ? new Date(rowData.dateActioned).toLocaleDateString() : '')}
                                            </Table.Cell>
                                        </Table.Column>
                                        <Table.Column flexGrow={1}>
                                            <Table.HeaderCell>
                                                Review Status
                                            </Table.HeaderCell>
                                            <Table.Cell>
                                                {rowData => (rowData.status?.status)}
                                            </Table.Cell>
                                        </Table.Column>

                                    </Table> : <LoadingSpinner />
                                }
                            </Card.Body>
                        </Card></Col>
                </Row>
            </Container>
        </div>
    )
}
export default Reinstatements;