import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Information from '../../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../../Shared/InformationContent/PlsInformation';
import applicationService from '../../../../../../services/Public/Application/application.service';
import plsApplicationService, { getSections } from '../../../../../../services/Public/Application/PLS/plsApplication.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import ReviewPdf from '../../../../../Shared/Card/ReviewPdf';
import { List } from 'rsuite';
import shoppingCartService from '../../../../../../services/Public/shoppingCart.service';
import Swal from 'sweetalert2';

function ReviewPage() {
    const [hasLoaded, setHasLoaded] = useState(true)
    const [hasIssues, setHasIssues] = useState(true)
    const { push } = useHistory();
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 5 ? "By Comity" : appTypeId == 6 ? "By Exam" : "";
    const [violationIssuesHtml, setViolationIssuesHtml] = useState();
    const sectionId = 9;
    const [allSections, setAllSections] = useState([]);
    const [sectionData, setSectionData] = useState({});
    useEffect(() => {
        getSections().then(setAllSections);
        plsApplicationService.plsGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
        })
    }, [])

    const navigateToPage = (sectionId) => {
        push(routes.filter(x => x.sectionId == (sectionId))[0].route)
    }
    const validateApplication = () => {
        setHasLoaded(false);
        applicationService.validateApplication(appId).then((response) => {
            let errors = response.data.map((error, i) => {
                return <List.Item key={i} index={i} onClick={() => { navigateToPage(error.sectionId) }}>
                    Error{error.errors.length > 1 ? 's' : ''} in <b>{allSections.filter(x => x.id == error.sectionId)[0].sectionTitle}</b>: {error.errors.join(', ')}
                </List.Item>
            })
            if (response.data?.length)
                setViolationIssuesHtml(
                    <List hover bordered>
                        {errors}
                    </List>
                )
            else {
                setViolationIssuesHtml('')
                setHasIssues(false);
                Swal.fire("Validation Successful", `Your application passed all validation checks.  You may now download a PDF version to keep for your records.`, 'success')
            }
            setHasLoaded(true);
        })
    }
    const submitApplication = () => {
        setHasLoaded(false);
        applicationService.getIndividualApplication(appId).then((response) => {
            shoppingCartService.addToShoppingCart(appId, response.data.applicationType.id == 5 ? 10 : 9).then((data) => {
                if (data.data) { push('/individual/shoppingcart'); }

            })
        })

        //applicationService.submitApplication(appId).then((data) => {
        //    if (data.data?.length) {
        //        setHasIssues(true);
        //        let errors = data.data.map((error, i) => {
        //            return <List.Item key={i} index={i} onClick={() => { navigateToPage(error.sectionId) }}>
        //                Error{error.errors.length > 1 ? 's' : ''} in <b>{allSections.filter(x => x.id == error.sectionId)[0].sectionTitle}</b>: {error.errors.join(', ')}
        //            </List.Item>
        //        })
        //        setViolationIssuesHtml(
        //            <List hover bordered>
        //                {errors}
        //            </List>
        //        )
        //        setHasLoaded(true);
        //    }
        //    else {
        //        setHasIssues(false);
        //        setViolationIssuesHtml("")
        //        plsApplicationService.plsGetSection(appId, sectionId).then((response) => {
        //            setSectionData(response.data);
        //            setHasLoaded(true);
        //        })
        //    }
        //})
    }

    return (
        <div>
            <Container fluid>
                <b>Professional Land Surveyor Application {appType}</b>
                <h4 className="mb-3">Review</h4>
                <Row>
                    <Col>
                        <Information content={GetAppInformationContent("review")} className="mb-3" />
                    </Col>
                </Row>
                <br />
                {sectionData.isUserEditable ?
                    <Button onClick={validateApplication} name="reviewApplication">Validate Application</Button> : <ReviewPdf id={appId} parentTypeId={1} />
                }
                {hasLoaded ?
                    <>
                        {
                            (!hasIssues && sectionData.isUserEditable) && <>
                                <span className="ml-2"><ReviewPdf id={appId} parentTypeId={1} /></span>
                                <Button name="addToCartBtn" onClick={submitApplication} variant="success" className="ml-2">Submit Application</Button>
                            </>
                        }
                        <Button name="backToSections" as={Link} to="/individual/plsApp/sections" variant="primary" className="ml-2" >Back to Sections</Button>
                        <br/>
                        <br/>
                        {violationIssuesHtml}
                    </>
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ReviewPage