import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Icon } from 'rsuite';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
import RedStar from '../../../../Shared/GenericComponents/RedStar';

function EducationRecord(props) {
    return (
        <div className="lsiReinstatementEducationInfo">
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h6>Education Records</h6>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body >
                    <p>
                        <RedStar /> I have education transcript(s) verified in MyNCEES record.
                    </p>
                    <br />
                    <YesNoRadioOption isReadOnly={props.isReadOnly} radioName="hasMyNceesEducation" handleChange={props.handleMyNceesEducationChange} />
                    {(props.hideGrid === false) &&
                        <span className="badge badge-light" style={{ padding: "0.5rem", textWrap: "wrap", wordWrap: "", color: "red" }}>
                            <i>Please go to <a href="https://ncees.org" target="_blank">ncees.org</a> to have transcripts verified. Your application cannot be processed until your are a record holder and transcripts are verified in your MYNCEES account.</i>
                        </span>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}


export default EducationRecord;