import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { FaEdit, FaUser } from 'react-icons/fa';
import { ControlLabel, Form, FormControl, InputPicker, SelectPicker } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import { emailPhonePrivacy, journalDropdown, subscribeDropdown } from '../../../../services/Shared/codeTable.service';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import PhoneNumberMask from '../../../Shared/GenericComponents/PhoneNumberMask';
import EditIndGeneralInformation from './EditIndGeneralInformationModal';

function GeneralInfoCard(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});
    const [genInfoFormData, setGenInfoFormData] = useState({});

    useEffect(() => {
        updateCard();
    }, [])

    const updateCard = () => {
        setHasLoaded(false);
        credentialService.getIndGeneralInfo(props.entityId).then((response) => {
            setData(response.data);
            setGenInfoFormData({
                userId: response.data.userName ?? '',
                dateOfBirth: response.data.dateOfBirth ? new Date(response.data.dateOfBirth).toLocaleDateString() : '',
                dateOfDeath: response.data.dateOfDeath ? new Date(response.data.dateOfDeath).toLocaleDateString() : '',
                birthCity: response.data.birthCity ?? '',
                birthState: response.data.birthState?.state ?? '',
                nonUsBirthState: response.data.nonUsBirthState ?? '',
                birthCountry: response.data.birthCountry?.country ?? '',
                federalId: response.data.federalId ?? '',
                visaGreenCardNumber: response.data.visaGreenCardNumber ?? '',
                myNceesNumber: response.data.myNceesNumber ?? '',
                isUsCitizen: response.data.isUsCitizen ? "Yes" : "No",
                email: response.data.primaryEmailAddress?.contactEmailAddress ?? '',
                alternateEmail: response.data.alternateEmailAddress?.contactEmailAddress ?? '',
                homePhone: response.data.homePhone?.contactPhoneNumber ?? '',
                mobilePhone: response.data.cellPhone?.contactPhoneNumber ?? '',
                workPhone: response.data.workPhone?.contactPhoneNumber ?? '',
                amountDue: response.data.amountDue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                additionalInformation: response.data.additionalInformation ?? '',
                citizenshipCountry: response.data.citizenshipCountry?.country ?? '',
                certificateName: response.data.certificateName ?? '',
                journalSubscription: response.data.journalSubscription,
                pressReleases: response.data.pressReleases,
                webinar: response.data.webinar,
                emailPhonePrivacy: response.data.emailPhonePrivacy,

            })
            setHasLoaded(true);
        })
    }
    const saveGenInfo = () => {
        updateCard();
        Swal.fire('Success!', "You have successfully updated this individual's general information.", 'success');
    }
    const setAddress = (address) => {
        if (address?.streetAddress) {
            var addresses = address.streetAddress.split("\n").filter(x => (x != "") && (x != "\r"));
            return (
                addresses.map((addressComponents) => {
                    return (<><span>{addressComponents}</span><br /></>);
                })
            );
        }
    }
    return (
        <>
            <EditIndGeneralInformation saveDataCallback={saveGenInfo} show={showModal} onHide={() => setShowModal(false)} entityid={props.entityId} />
            <Card className="w-100">
                <Card.Header className="text-center" style={{ background: 'white' }}>
                    <a href={"internal/contactChangeLog?entityId=" + props.entityId} target="_blank" className="changeLog">
                        <span className="fakeH6">GENERAL</span>
                        <br/>
                        <FaUser fontSize='42' />
                        </a>
                </Card.Header>
                <Card.Body>{
                    hasLoaded ?
                        <>
                            <div className="text-center mb-3">
                                <h4>{data.formattedName}</h4>
                                <span>LOLA #</span><br />
                                <span>{data.lolaNumber}</span>
                                <hr />
                                <h6>Address Information</h6>
                            </div>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Header>Mailing Address</Card.Header>
                                        <Card.Body>
                                            {data.mailingAddress && <>
                                                {setAddress(data.mailingAddress)}
                                                {data.mailingAddress?.country?.country == 'United States' ?
                                                    <span>{data.mailingAddress?.city}, {data.mailingAddress?.state?.code} {data.mailingAddress?.postalCode} </span> :
                                                    <span>{data.mailingAddress?.city}, {data.mailingAddress?.provinceRegion} {data.mailingAddress?.postalCode} </span>}
                                                <br />
                                                <span>{data.mailingAddress?.country?.country} </span><br />
                                            </>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Header>Public Address</Card.Header>
                                        <Card.Body>
                                            {data.publicAddress &&
                                                <>
                                                    {setAddress(data.publicAddress)}
                                                    {data.publicAddress?.country?.country == 'United States' ?
                                                        <span>{data.publicAddress?.city}, {data.publicAddress?.state?.code} {data.publicAddress?.postalCode} </span> :
                                                        <span>{data.publicAddress?.city}, {data.publicAddress?.provinceRegion} {data.publicAddress?.postalCode} </span>}
                                                    <br />
                                                    <span>{data.publicAddress?.country?.country} </span><br />
                                                </>}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <hr />
                            <Form fluid formValue={genInfoFormData}>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>UserId</b></ControlLabel>
                                        <FormControl
                                            name="userId"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Date of Birth</b></ControlLabel>
                                        <FormControl
                                            name="dateOfBirth"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Date of Death</b></ControlLabel>
                                        <FormControl
                                            name="dateOfDeath"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>City of Birth</b></ControlLabel>
                                        <FormControl
                                            name="birthCity"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        {genInfoFormData.birthCountry == "United States" &&
                                            <>
                                                <ControlLabel><b>State of Birth</b></ControlLabel>
                                                <FormControl
                                                    name="birthState"
                                                    disabled
                                                />
                                            </>
                                        }
                                        {genInfoFormData.birthCountry != "United States" &&
                                            <>
                                                <ControlLabel><b>State/Province/Region</b></ControlLabel>
                                                <FormControl
                                                    name="nonUsBirthState"
                                                    disabled
                                                />
                                            </>
                                        }
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Country of Birth</b></ControlLabel>
                                        <FormControl
                                            name="birthCountry"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>SSN/FEIN</b></ControlLabel>
                                        <FormControl
                                            name="federalId"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Visa/Green Card No.</b></ControlLabel>
                                        <FormControl
                                            name="visaGreenCardNumber"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>NCEES ID #</b></ControlLabel>
                                        <FormControl
                                            name="myNceesNumber"
                                            disabled
                                        />
                                    </
                                    Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Email/Phone Privacy</b></ControlLabel>
                                        <FormControl
                                            name="emailPhonePrivacy"
                                            data={emailPhonePrivacy}
                                            accepter={InputPicker}
                                            block
                                            disabled

                                        />

                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Email</b></ControlLabel>
                                        {genInfoFormData.email ? <div className="rs-form-control-wrapper"><a href={"mailto:" + genInfoFormData.email + ""}>{genInfoFormData.email}</a></div> : <FormControl
                                            name="email"
                                            disabled
                                        />}
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Alternate Email</b></ControlLabel>
                                        {genInfoFormData.alternateEmail ? <div className="rs-form-control-wrapper"><a href={"mailto:" + genInfoFormData.alternateEmail + ""}>{genInfoFormData.alternateEmail}</a></div> : <FormControl
                                            name="alternateEmail"
                                            disabled
                                        />}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Mobile Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            format="(###) ###-####"
                                            name="mobilePhone"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Work Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            format="(###) ###-####"
                                            name="workPhone"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Home Phone</b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            format="(###) ###-####"
                                            name="homePhone"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>US Citizen</b></ControlLabel>
                                        <FormControl
                                            name="isUsCitizen"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Certificate Name</b></ControlLabel>
                                        <FormControl
                                            name="certificateName"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Amount Due</b></ControlLabel>
                                        <FormControl
                                            name="amountDue"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                {
                                    data.isUsCitizen == false &&
                                    <Row className="mb-3">
                                        <Col>
                                            <>
                                                <ControlLabel><b>Citizenship Country</b></ControlLabel>
                                                <FormControl
                                                    name="citizenshipCountry"
                                                    disabled
                                                />
                                            </>
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                }
                                <Row className='mb-3'>
                                    <Col>
                                        <ControlLabel><b>Additional Information</b></ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="additionalInformation"
                                            disabled
                                        /></Col>

                                </Row>
                                <hr />
                                <Row className='mb-3'>
                                    <Col>
                                        <ControlLabel><b>Journal</b></ControlLabel>
                                        <FormControl
                                            name="journalSubscription"
                                            data={journalDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Press Releases</b></ControlLabel>
                                        <FormControl
                                            name="pressReleases"
                                            data={subscribeDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Webinar</b></ControlLabel>
                                        <FormControl
                                            name="webinar"
                                            data={subscribeDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </> : <LoadingSpinner />
                }
                </Card.Body>
                <Card.Footer className='text-right'>
                    <Button variant='light' onClick={() => setShowModal(true)}><FaEdit /></Button>
                </Card.Footer>
            </Card >
        </>
    )
}


export default GeneralInfoCard;