import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup, Table } from 'rsuite';
import Swal from 'sweetalert2';
import paymentService from '../../../services/Internal/Revenue/payment.service';
import { getPaymentTypes } from '../../../services/Shared/codeTable.service'
import CurrencyMask from '../../Shared/GenericComponents/CurrencyMask';
import LoadingSpinner from '../../Shared/GenericComponents/LoadingSpinner';
import RedStar from '../../Shared/GenericComponents/RedStar';
import GenericPagination from '../../Shared/Grid/GenericPagination';


function PaymentInfoModal({ paymentId, show, onHide, refreshInvoicesRefunds }) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [refundList, setRefundList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [showRefund, setShowRefund] = useState(false);
    const [paymentInfoModel, setPaymentInfoModel] = useState();
    const [refundModel, setRefundModel] = useState({});

    useEffect(() => {
        getPaymentTypes().then(setPaymentTypes);
    }, [])

    useEffect(() => {
        setRefundList([]);
        setInvoiceList([]);
        reloadPaymentModel();
    }, [show])

    const reloadPaymentModel = () => {
        if (show && paymentId) {
            setHasLoaded(false);
            paymentService.getPayment(paymentId).then((response) => {
                if (response.data) {
                    setPaymentInfoModel({
                        ...response.data,
                    })
                }
            }).then(() => {
                paymentService.getRefunds(paymentId).then((response) => {
                    if (response.data) {
                        setRefundList([...response.data]);
                    }
                    else {
                        setRefundList([]);
                    }
                }).then(() => {
                    paymentService.getInvoicesForPayment(paymentId).then((response) => {
                        if (response.data) {
                            setInvoiceList([...response.data]);
                        }
                        else {
                            setInvoiceList([]);
                        }
                    }).then(() => {
                        setShowRefund(false);
                        setRefundModel({
                            paymentId: paymentId,
                            refundAmount: "",
                            refundReason: "",
                            id: 0
                        })
                        setHasLoaded(true);

                    })
                })
            })
        }
    }

    const { StringType, ObjectType, NumberType } = Schema.Types;
    const model = Schema.Model({
        paymentType: ObjectType().isRequired('This field is required.'),
        nicAuthCode: StringType().isRequired('This field is required.'),
        remitter: StringType().isRequired('This field is required.')
    });
    const refundValidationModel = Schema.Model({
        refundAmount: NumberType().isRequired('This field is required.').min(.01, 'Please enter an amount greater than 0').max(paymentInfoModel?.availableAmount, 'Cannot exceed the avaiable amount'),
        refundReason: StringType().isRequired('This field is required.'),
    });
    const formSubmit = (validation, event) => {
        if (validation) {
            paymentService.updatePayment(paymentInfoModel).then((response) => {
                if (response.data) {
                    onHide();
                }
            })
        }
    }
    const submitRefund = (validation, event) => {
        if (validation) {
            setHasLoaded(false);
            paymentService.createRefund(refundModel).then((response) => {
                if (response.data) {
                    reloadPaymentModel();
                }
            })

        }
    }
    const [invoiceDisplayLength, setInvoiceDisplayLength] = useState(10);
    const [invoicePage, setInvoicePage] = useState(1);
    const getData = () => {
        return invoiceList.filter((v, i) => {
            const start = invoiceDisplayLength * (invoicePage - 1);
            const end = start + invoiceDisplayLength;
            return i >= start && i < end;
        })
    }
    const getInvoiceData = getData();
    return (
        <Modal
            show={show}
            onHide={onHide}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Payment #{paymentInfoModel?.id} | Batch #{paymentInfoModel?.batchId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hasLoaded ? <>
                    <Form
                        className="mb-3"
                        id="paymentInfoForm"
                        fluid
                        model={model}
                        formValue={paymentInfoModel}
                        onChange={paymentInfoModel => setPaymentInfoModel(paymentInfoModel)}
                        onSubmit={formSubmit} >
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Payment Type&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                            <FormControl
                                block="true"
                                name="paymentType"
                                placeholder="-- Select --"
                                accepter={SelectPicker}
                                searchable={false}
                                data={paymentTypes} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Amount&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                            <FormControl
                                name="amount"
                                accepter={CurrencyMask}
                                disabled />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Available&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                            <FormControl accepter={CurrencyMask} name="availableAmount" disabled />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Check#/CC Auth Number&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                            <FormControl name="nicAuthCode" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Remitter&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                            <FormControl name="remitter" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Contact&nbsp;<RedStar />
                                </strong>
                            </ControlLabel>
                            <FormControl name="entityDetails" disabled />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <strong>
                                    Comments
                            </strong>
                            </ControlLabel>
                            <FormControl name="comments" />
                        </FormGroup>
                    </Form>
                    <Row>
                        <Col>
                            {
                                !showRefund && paymentInfoModel.availableAmount > 0 &&
                                <Button disabled={!paymentInfoModel.isInUsersOpenBatch && !paymentInfoModel.isOnline} onClick={() => { setShowRefund(true) }} variant="primary">Start Refund</Button>
                            }
                        </Col>
                        <Col style={{ textAlign: 'end' }}>
                            <Button disabled={!paymentInfoModel.isInUsersOpenBatch} type="submit" form="paymentInfoForm" variant="primary">Save</Button>
                        </Col>
                    </Row>
                </> : <LoadingSpinner />}
                <hr />
                {
                    showRefund &&
                    <>
                        <Form id="refundForm"
                            fluid
                            model={refundValidationModel}
                            formValue={refundModel}
                            onChange={refundModel => setRefundModel(refundModel)}
                            onSubmit={submitRefund}
                            className="bg-light p-4"
                        >
                            <Row className="mb-3">
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>
                                            <strong>
                                                Refund Amount&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            accepter={CurrencyMask}
                                            name="refundAmount" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <ControlLabel>
                                            <strong>
                                                Refund Reason&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="textArea"
                                            name="refundReason" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'end' }}>
                                    <Button type="submit" variant="warning">Process Refund</Button>
                                </Col>
                            </Row>
                        </Form>
                        <br />
                    </>
                }
                {refundList.length > 0 &&
                    <> <h3>Refunds</h3>
                        <Table wordWrap autoHeight data={refundList} bordered={true}>
                            <Table.Column width={100}>
                                <Table.HeaderCell>
                                    <b>Refund Date</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.refundDate ? new Date(rowData.refundDate).toLocaleDateString() : '')}</Table.Cell>
                            </Table.Column>
                            <Table.Column width={150}>
                                <Table.HeaderCell>
                                    <b>Refund Amount</b>
                                </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.refundAmount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }))}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={2}>
                                <Table.HeaderCell>
                                    <b>Refund Reason</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.refundReason)}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1.5}>
                                <Table.HeaderCell>
                                    <b>User</b>
                                </Table.HeaderCell>
                                <Table.Cell>{rowData => (rowData.userName)}</Table.Cell>
                            </Table.Column>
                        </Table></>

                }

                <> <br />
                    <h3>Invoices</h3>
                    <Table wordWrap autoHeight data={getInvoiceData} bordered={true}>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Invoice Date</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.invoiceDate ? new Date(rowData.invoiceDate).toLocaleDateString() : '')}</Table.Cell>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>
                                <b>Line Item(s)</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (<div>{rowData.lineItems}</div>)}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Amount Due</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.balance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }))}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Amount Paid</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.amountPaid.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }))}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell>
                                <b>Date Paid</b>
                            </Table.HeaderCell>
                            <Table.Cell>{rowData => (rowData.datePaid ? new Date(rowData.datePaid).toLocaleDateString() : '')}</Table.Cell>
                        </Table.Column>
                        <Table.Column width={150}>
                            <Table.HeaderCell>
                                <b>Action(s)</b>
                            </Table.HeaderCell>
                            <Table.Cell dataKey="id">
                                {rowData => {
                                    function invoiceDeallocate() {
                                        paymentService.deallocatePayment(paymentId, rowData.id).then((response) => {
                                            if (response.data) {
                                                reloadPaymentModel();
                                                if (refreshInvoicesRefunds)
                                                    refreshInvoicesRefunds();
                                            }
                                        })
                                    }
                                    function invoicePay() {
                                        paymentService.allocatePayment(paymentId, rowData.id).then((response) => {
                                            if (response.data) {
                                                if (response.data.autoApprovedRenewalIds?.length) {
                                                    Swal.fire({
                                                        title: 'Saving PDF(s)',
                                                        didOpen: () => {
                                                            Swal.showLoading();
                                                            var dataToTransmit = {
                                                                RenewalIds: response.data.autoApprovedRenewalIds
                                                            }
                                                            paymentService.postAutoRenewalPdfs(dataToTransmit).then((data) => {
                                                                if (data) {
                                                                    reloadPaymentModel();
                                                                    Swal.close()
                                                                }
                                                            })
                                                        },
                                                        allowOutsideClick: false
                                                    })
                                                }
                                                else { reloadPaymentModel(); }
                                            }
                                        })
                                    }
                                    return (
                                        <>
                                            {rowData.datePaid ?
                                                <Button type="button" disabled={!paymentInfoModel.isInUsersOpenBatch && !paymentInfoModel.isOnline} onClick={invoiceDeallocate} variant="dark">Deallocate</Button>
                                                :
                                                <Button type="button" disabled={!paymentInfoModel.isInUsersOpenBatch && !paymentInfoModel.isOnline} onClick={invoicePay} variant="primary">Pay</Button>
                                            }
                                        </>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                    <GenericPagination dataLength={invoiceList.length} setPage={setInvoicePage} setDisplayLength={setInvoiceDisplayLength} />
                </>


            </Modal.Body>
        </Modal>
    );
}

export default PaymentInfoModal;