import axios from "axios";
import authHeader from '../../../authheader'

const PLSREINSTATEMENTURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsReinstatement";

const getExperienceRecords = (reinstatementId) => {
    return axios.get(`${PLSREINSTATEMENTURL}/experiencerecords`, { headers: authHeader(), params: { reinstatementId } });
}
const postExperienceRecord = (data) => {
    return axios.post(`${PLSREINSTATEMENTURL}/experiencerecord`, data, { headers: authHeader() });
}
const downloadExperiences = (reinstatementId, credType) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/Reports/GetReinstatementExperiences`, { headers: authHeader(), params: { reinstatementId: reinstatementId, credType: credType }, responseType: 'arraybuffer' });
}
export default {
    getExperienceRecords,
    postExperienceRecord,
    downloadExperiences
}