import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { FormControl } from 'rsuite'
import RedStar from '../GenericComponents/RedStar'

function Acknowledgement(props) {
    return (
        <Card>
            <Card.Header><h6>Acknowledgement</h6></Card.Header>
            <Card.Body>
                <ul>
                    <li>
                        I certify that all information provided in this online {props.appType} is true and correct to the best of my knowledge, information and belief.
                    </li>
                    <li>
                        I am aware that an omission or untrue response may constitute fraud, deceit, material misstatement, perjury or the giving of false or forged evidence under Louisiana Revised Statutes 37:698 and 37:700
                    </li>

                    <li>
                        <b className="colorRed">IMPORTANT: Payments are non-refundable.</b> If you believe that you will be reimbursed for your fee, verify this BEFORE proceeding further
                    </li>
                    <li>
                        If you are unsure about getting reimbursed by your firm, please stop the application process here and come back after you verify. Your answers will be saved.
                    </li>
                </ul>
                <hr />
                <Row>
                    <Col sm={4}>
                        <h5>Electronic Signature of Applicant:<RedStar /></h5>
                        <span>(Type full name)</span>
                    </Col>
                    <Col sm={8}><FormControl name="electronicSignature" /></Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
export default Acknowledgement