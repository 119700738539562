import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import { Input, SelectPicker, Form, ControlLabel, Schema, FormControl, FormGroup, Icon } from 'rsuite';
import experienceService from '../../../../../../services/Public/Application/PE/experience.service';
//import reinstatementExperienceService from '../../../../../../services/Public/Reinstatement/PLS/experience.service';
import { getEmploymentTypes } from '../../../../../../services/Shared/codeTable.service';
import PhoneNumberMask from '../../../../../Shared/GenericComponents/PhoneNumberMask';
import RedStar from '../../../../../Shared/GenericComponents/RedStar';

const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};

function AddEditExperienceRecordsModal(props) {
    const [recordModel, setRecordModel] = useState({
        applicationId: 0,
        comments: "",
        dateCrte: null,
        dateFrom: null,
        dateTo: null,
        deleted: false,
        employerName: "",
        employerPosition: "",
        employerEmail: "",
        employerPhone: "",
        verifierName: "",
        verifierPosition: "",
        verifierEmail: "",
        verifierPhone: "",
        employmentType: null,
        entityId: 0,
        id: 0,
        userCrte: "",
        position: "",
        location: "",
        description: "",
        acceptableDescription: "",
        //showErrors: false,
        emailAddresses: [
            {
                contactEmailAddress: '',
                emailAddressParentType: {
                    id: 10,
                    emailAddressParentType: "Application Experience Employer"
                },
                emailAddressType: {
                    id: 1,
                    emailAddressType: ''
                },
                id: 0,
                entityId: 0,
                parentId: 0,
                userCrte: '',
                dateCrte: null
            },
            {
                contactEmailAddress: '',
                emailAddressParentType: {
                    id: 11,
                    emailAddressParentType: "Application Experience Verifier"
                },
                emailAddressType: {
                    id: 1,
                    emailAddressType: ''
                },
                id: 0,
                entityId: 0,
                parentId: 0,
                userCrte: '',
                dateCrte: null
            }
        ],
        phoneNumbers: [
            {
                contactPhoneNumber: '',
                parentType: {
                    id: 10,
                    phoneParentType: "Application Experience Employer"
                },
                phoneType: {
                    id: 3,
                    phoneType: ''
                },
                id: 0,
                entityId: 0,
                parentId: 0,
                userCrte: '',
                dateCrte: null
            },
            {
                contactPhoneNumber: '',
                parentType: {
                    id: 11,
                    phoneParentType: "Application Experience Verifier"
                },
                phoneType: {
                    id: 3,
                    phoneType: ''
                },
                id: 0,
                entityId: 0,
                parentId: 0,
                userCrte: '',
                dateCrte: null
            }
        ],
        modalType: "Add"
    })
    const [employmentTypes, setEmploymentTypes] = useState([])
    useEffect(() => {
        getEmploymentTypes().then(setEmploymentTypes);
        setRecordModel(props.modaldata);
    }, [props.modaldata])

    const { StringType, ObjectType, NumberType } = Schema.Types;
    const model = Schema.Model({
        position: StringType().isRequired('This field is required.'),
        employerName: StringType().isRequired('This field is required.'),
        employerPosition: StringType().isRequired('This field is required.'),
        employerPhone: NumberType().pattern(/^[0-9]{10}/, "Please enter a valid Phone Number").isRequired("This field is required."),
        employerEmail: StringType().isRequired('This field is required.').isEmail("Enter a valid email"),
        verifierName: StringType().isRequired('This field is required.'),
        verifierPosition: StringType().isRequired('This field is required.'),
        verifierPhone: NumberType().pattern(/^[0-9]{10}/, "Please enter a valid Phone Number").isRequired("This field is required."),
        verifierEmail: StringType().isRequired('This field is required.').isEmail("Enter a valid email"),

        location: StringType().isRequired('This field is required.'),
        description: StringType().isRequired('This field is required.'),
        acceptableDescription: StringType().isRequired('This field is required.'),
        employmentType: ObjectType().isRequired('This field is required.'),
        dateFrom: StringType().isRequired('This field is required.'),
        dateTo: StringType().isRequired('This field is required.')
    })

    const formSubmit = (validation, event) => {
        var hasErrors = false;
        var validationObject = model.check(recordModel);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                hasErrors = true;
                return;
            }
        }
        if (!hasErrors) {
            submitData();
        }

    }
    const submitData = () => {
        var dataToTransmit = {
            ...recordModel,
            phoneNumbers: [
                {
                    ...recordModel.phoneNumbers[0],
                    contactPhoneNumber: recordModel.employerPhone
                },
                {
                    ...recordModel.phoneNumbers[1],
                    contactPhoneNumber: recordModel.verifierPhone
                }
            ],
            emailAddresses: [
                {
                    ...recordModel.emailAddresses[0],
                    contactEmailAddress: recordModel.employerEmail

                },
                {
                    ...recordModel.emailAddresses[1],
                    contactEmailAddress: recordModel.verifierEmail
                }
            ],
            dateFrom: new Date(recordModel.dateFrom),
            dateTo: new Date(recordModel.dateTo),
            id: recordModel.id === '' ? 0 : recordModel.id,
        };


        delete dataToTransmit.modalType;
        delete dataToTransmit.verifierEmail;
        delete dataToTransmit.verifierPhone;
        delete dataToTransmit.employerPhone;
        delete dataToTransmit.employerEmail;
        //delete dataToTransmit.showErrors;
        //if (props.appType === "reinstatement") {
        //    reinstatementExperienceService.postExperienceRecord(dataToTransmit).then((response) => {
        //        if (response.data.id) {
        //            props.updateGrid();
        //            props.onHide();
        //        }
        //    })
        //}
        //else {
        experienceService.postExperienceRecord(dataToTransmit).then((response) => {
            if (response.data.id) {
                props.updateGrid();
                props.onHide();
            }
        })
        //}
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {recordModel.modalType} an Experience Record
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="recordForm" fluid model={model} formValue={recordModel} onChange={recordModel => setRecordModel(recordModel)} onSubmit={formSubmit} >
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        Title of Position&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl name="position" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        Location of Assignment/Engagement&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl name="location" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        Description of Assignment&nbsp;
                                    </strong>
                                      indicate character of work and degree of responsibility <RedStar />
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    accepter={Input}
                                    componentClass="textarea"
                                    name="description"
                                    rows={3}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        If not a licensed professional engineer/surveyor, explain why the experience should be considered acceptable. Otherwise, enter N/A.&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    accepter={Input}
                                    componentClass="textarea"
                                    name="acceptableDescription"
                                    rows={3}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        Employment Type&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    accepter={SelectPicker}
                                    placeholder="Select Type"
                                    searchable={false}
                                    block
                                    data={employmentTypes}
                                    name="employmentType" />

                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        Date From&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    type="month"
                                    block="true"
                                    name="dateFrom" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>
                                    <strong>
                                        Date To&nbsp;<RedStar />
                                    </strong>
                                </ControlLabel>
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    type="month"
                                    block="true"
                                    name="dateTo" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <hr />
                    <Row className="mb-3 mx-1">
                        <FormGroup>
                            <ControlLabel>
                                <strong style={{ color: 'white', padding: '12px', backgroundColor: '#3c4249' }}>
                                    Employer Information <RedStar />
                                </strong>
                            </ControlLabel>
                        </FormGroup>
                    </Row>
                    <Row className="ml-4">
                        <Col>
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Name&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl name="employerName" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Title&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl name="employerPosition" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Phone&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="employerPhone"
                                            format="(###) ###-####"
                                            mask="_"
                                            checkTrigger="blur"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Email&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl name="employerEmail" checkTrigger="blur"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />
                    <Row className="mb-3 mx-1">
                        <FormGroup>
                            <ControlLabel>
                                <strong style={{ color: 'white', padding: '12px', backgroundColor: '#3c4249' }}>
                                    Verifier Information <RedStar />
                                </strong>
                            </ControlLabel>
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col>

                            <FormGroup>
                                <Row className="ml-4">
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Name&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl
                                            name="verifierName" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="ml-4">
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Title&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl
                                            name="verifierPosition" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="ml-4">
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Phone&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="verifierPhone"
                                            format="(###) ###-####"
                                            mask="_"
                                            checkTrigger="blur"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="ml-4">
                                    <Col sm={3}>
                                        <ControlLabel>
                                            <strong>
                                                Email&nbsp;<RedStar />
                                            </strong>
                                        </ControlLabel>
                                    </Col>
                                    <Col>
                                        <FormControl
                                            name="verifierEmail" checkTrigger="blur"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>


            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" variant="success" form="recordForm">Save</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditExperienceRecordsModal;