import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/VFInformation';
import vfRenewalService from '../../../../../services/Internal/Renewal/VF/vfRenewal.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import ReviewPdf from '../../../../Shared/Card/ReviewPdf';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import { Form } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ReviewPage() {
    const [hasLoaded, setHasLoaded] = useState(true)
    const { push } = useHistory();
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const [violationIssuesHtml, setViolationIssuesHtml] = useState();
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Surveying Firm Renewal",
            isIndividual: false,
            entityId: 0,
            applicationDate: "",
            currentSection: "Review"
        }
    );
    const sectionId = 8;
    useEffect(() => {
        vfRenewalService.vfGetSection(appId, sectionId).then((response) => {
            setIsComplete(response.data.staffReviewComplete);
            vfRenewalService.getFirmRenewal(appId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])

    const formSubmit = () => {
        if (!isUserEditable) {
            push("/internal/vfRenewal/sections");
        }
        else {
            vfRenewalService.vfMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                if (data.data)
                    push("/internal/vfRenewal/sections");
            })
        }
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Row>
                    <Col>
                        <Information content={GetRenewalInformationContent("review")} className="mb-3" />
                    </Col>
                </Row>
                <br />
                <ReviewPdf id={appId} parentTypeId={3} />
                <br />
                <br />
                {hasLoaded ?
                    <>
                        {violationIssuesHtml}
                        <Container fluid>
                            <Form onSubmit={formSubmit}>
                                <FormActionButtons
                                    isUserEditable={isUserEditable}
                                    isComplete={isComplete}
                                    isCompleteCallback={setIsComplete}
                                    showSectionComplete={true}
                                    backToSectionLink="/internal/vfRenewal/sections" />
                            </Form>
                        </Container>
                    </>
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ReviewPage