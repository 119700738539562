import axios from "axios";
import authHeader from '../../../authheader'

const PENOTIFICATIONPREFERENCEURL = process.env.REACT_APP_APIURL + "/api/NotificationPreferences";


const getPrivacyData = (entityId) => {
    return axios.get(`${PENOTIFICATIONPREFERENCEURL}/journalandprivacy`, { headers: authHeader(), params: {entityId} });
}
const postPrivacyData = (data) => {
    return axios.post(`${PENOTIFICATIONPREFERENCEURL}/journalandprivacy`, data, { headers: authHeader() });
}
export default {
    getPrivacyData,
    postPrivacyData
}