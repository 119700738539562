import axios from "axios";
import authHeader from '../../../authheader'

const PLSAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPlsReinstatement";

const getPersonalReferences = (reinstatementId) => {
    return axios.get(`${PLSAPPLICATIONURL}/personalreferences`, { headers: authHeader(), params: { reinstatementId } });
}
const postPersonalReference = (data) => {
    return axios.post(`${PLSAPPLICATIONURL}/personalreference`, data, { headers: authHeader() });
}
const sendPersonalReferenceEmail = (referenceId) => {
    return axios.post(`${PLSAPPLICATIONURL}/sendreferenceemail`, null, { headers: authHeader(), params: { referenceId } });
}
const downloadPersonalReferences = (reinstatementId, credType) => {
    return axios.get(`${process.env.REACT_APP_APIURL}/Reports/GetReinstatementPersonalReferences`, { headers: authHeader(), params: { reinstatementId: reinstatementId, credType: credType }, responseType: 'arraybuffer' });
}
export default {
    getPersonalReferences,
    postPersonalReference,
    sendPersonalReferenceEmail,
    downloadPersonalReferences
}