import axios from "axios";
import authHeader from '../../../authheader'

const PERENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualPeRenewal";

const getCpdRequirements = (renewalId) => {
    return axios.get(`${PERENEWALURL}/cpd`, { headers: authHeader(), params: { renewalId } });
}
const postCpdRequirements = (data) => {
    return axios.post(`${PERENEWALURL}/cpd`, data, { headers: authHeader() });
}
const isCpdRequired = (renewalId) => {
    return axios.get(`${PERENEWALURL}/iscpdrequired`, { headers: authHeader(), params: { renewalId } });
}
const isRetiredToActive = (renewalId) => {
    return axios.get(`${PERENEWALURL}/isretiredtoactive`, { headers: authHeader(), params: { renewalId } });
}
const isNonActiveToActive = (renewalId) => {
    return axios.get(`${PERENEWALURL}/isnonactivetoactive`, { headers: authHeader(), params: { renewalId } });
}
export default {
    getCpdRequirements,
    postCpdRequirements,
    isCpdRequired,
    isRetiredToActive,
    isNonActiveToActive
}