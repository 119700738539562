import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon, Table } from 'rsuite';
import LoadingSpinner from '../GenericComponents/LoadingSpinner';

// requires isInternal, gridData
function InvoiceHistory(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [gridData, setGridData] = useState([]);
    var dispatch = useDispatch();
    const { push } = useHistory();
    useEffect(() => {
        setGridData(props.gridData)
        setHasLoaded(true);
    }, [props.gridData])

    const navigateToInvoice = (rowData) => {
        dispatch({ type: "PERSIST_INVOICE_ID", payload: rowData.id });
        push(props.isIndividual ? "/individual/invoice" : "/firm/invoice");
    }

    return (
        <div className="invoiceHistory">
            <Card className="mb-3">
                <Card.Body>
                    {hasLoaded ?
                        <>
                            <Table wordWrap autoHeight data={gridData}>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => (
                                            <Button onClick={() => navigateToInvoice(rowData)}>View</Button>
                                        )}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Invoice Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.invoiceDate ? new Date(rowData.invoiceDate).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Invoice #</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.invoiceNumber)}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Total</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.total.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })) }</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Amount Paid</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.amountPaid.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }))}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Paid Date</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.paidDate ? new Date(rowData.paidDate).toLocaleDateString() : '')}</Table.Cell>
                                </Table.Column>
                                <Table.Column flexGrow={1}>
                                    <Table.HeaderCell>
                                        <b>Amount Due</b>
                                    </Table.HeaderCell>
                                    <Table.Cell>{rowData => (rowData.amountDue.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }))}</Table.Cell>
                                </Table.Column>
                            </Table>
                        </> : <LoadingSpinner/>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}



export default InvoiceHistory;