import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import designService from '../../../../../services/Internal/Reinstatement/PE/design.service';
import peReinstatementService from '../../../../../services/Internal/Reinstatement/PE/peReinstatement.service';
import Information from '../../../../Shared/Card/Information';
import Issues from '../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import RedStar from '../../../../Shared/GenericComponents/RedStar';
import { GetReinstatementInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function BuildingDesignPage() {
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const entityId = useSelector(x => x.userInfo.EntityId)
    const [model, setModel] = useState({
        hasLaBuildingDesign: '',
        hasSafetyCodeHours: '',
        reinstatementId: reinstatementId,
        entityId: entityId
    });
    const { push } = useHistory();
    const sectionId = 15;
    const [hasLoaded, setHasLoaded] = useState(false);
    const routes = useSelector(x => x.currentRoutes);
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Reinstatement",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Building Design"
        }
    );
    useEffect(() => {
        peReinstatementService.peGetSection(reinstatementId, sectionId).then((data) => {
            setSectionData(data.data);
            setIsComplete(data.data.staffReviewComplete);
            peReinstatementService.getIndividualReinstatement(reinstatementId).then((reinstatementData) => {
                setIsUserEditable(reinstatementData.data.status.id == 2)
                entityService.getEntity(data.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: reinstatementData.data?.credential?.formattedLicenseNumber,
                            applicationDate: reinstatementData.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
                designService.getBuildingDesign(reinstatementId).then((response) => {
                    if (response.data)
                        setModel({
                            ...response.data
                        })
                })
            });
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const validationModel = Schema.Model({
        hasLaBuildingDesign: BooleanType().isRequired("This field is required."),
        hasSafetyCodeHours: BooleanType().isRequired("This field is required.")
    });
    const formSubmit = (val, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (val || model.hasLaBuildingDesign == false) {
            designService.postBuildingDesign(model).then((response) => {
                peReinstatementService.peMarkSectionComplete(reinstatementId, sectionId, isComplete).then((data) => {
                    if (data.data)
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                })
            })
        }
    }

    return (
        <div className="peReinstatementDesignPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetReinstatementInformationContent("design")} />
                <br />
                {hasLoaded ?
                    <Form readOnly={!isUserEditable} formValue={model} onSubmit={formSubmit} onChange={setModel} model={validationModel}>
                        <Card>
                            <Card.Header><h6>Building Design in Louisiana</h6></Card.Header>
                            <Card.Body>
                                <p className="mb-3"><RedStar /> Have you designed buildings or building systems in Louisiana since your license was no longer in active status?</p>
                                <YesNoRadioOption radioName="hasLaBuildingDesign" />
                                {model.hasLaBuildingDesign == true && <>
                                    <hr />
                                    <h6 className="mb-3">Safety Code Hours</h6>
                                    <p className="mb-3">Do you have 4 PDH’s of Life Safety Code, building codes, and/or Americans with Disabilities Act Accessibility Guidelines <strong>per calendar year(s)</strong> when you designed buildings or buildings systems in Louisiana?</p>
                                    <YesNoRadioOption radioName="hasSafetyCodeHours" /></>
                                }
                            </Card.Body>
                        </Card>
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={2} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            backToSectionLink="/internal/pereinstatement/sections"
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete} />
                    </Form> : <LoadingSpinner />
                }
            </Container >
        </div >
    )
}
export default BuildingDesignPage;