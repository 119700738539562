import axios from "axios";
import authHeader from '../../../authheader'

const PERENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualPeRenewal";

const getBuildingDesign = (renewalId) => {
    return axios.get(`${PERENEWALURL}/buildingdesign`, { headers: authHeader(), params: { renewalId } });
}
const postBuildingDesign = (data) => {
    return axios.post(`${PERENEWALURL}/buildingdesign`, data, { headers: authHeader() });
}
const isBuildingDesignRequired = (renewalId) => {
    return axios.get(`${PERENEWALURL}/isbuildingdesignrequired`, { headers: authHeader(), params: { renewalId } });
}
const isNonactiveToActive = (renewalId) => {
    return axios.get(`${PERENEWALURL}/isnonactivetoactive`, { headers: authHeader(), params: { renewalId } });
}

export default {
    getBuildingDesign,
    postBuildingDesign,
    isBuildingDesignRequired,
    isNonactiveToActive
}