import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import renewalService, { getSections } from '../../../../../services/Internal/Renewal/LSI/lsiRenewal.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Information from '../../../../Shared/Card/Information';
import { GetRenewalInformationContent } from '../../../../Shared/InformationContent/LsiInformation';
import Section from '../../../../Shared/Sections/Section';
import InternalTaskInfo from '../../../Shared/InternalTaskInfo';
import FormEntityInformation from '../../../Shared/FormEntityInformation';
import recentQueueService from '../../../../../services/Internal/Recent/recentQueue.service'
import issuesService from '../../../../../services/Shared/issues.service';

function Index() {
    const marginRight = {
        marginRight: '1rem'
    }
    const [showSectionButton, setShowSectionButton] = useState(true);
    const [sections, setSections] = useState(null);
    const dispatch = useDispatch();
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Land Surveyor Intern Renewal",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: ""
        }
    );
    const [hasLoaded, setHasLoaded] = useState(false);
    const selector = useSelector(x => x);
    useEffect(() => {
        var routes = JSON.parse(localStorage.getItem('PERSIST_CURRENT_ROUTE')) ?? selector.currentRoutes;
        var currentAppId = localStorage.getItem('PERSIST_CURRENT_APP_ID') ?? selector.currentApplicationId;
        var currentEntityID = localStorage.getItem('PERSIST_CURRENT_APP_ENTITY_ID') ?? selector.currentApplicationEntityId;
        var currentAppTypeId = localStorage.getItem('PERSIST_CURRENT_APP_TYPE_ID') ?? selector.currentApplicationTypeId;
        if (localStorage.getItem('PERSIST_CURRENT_APP_ID')) {
            localStorage.removeItem('PERSIST_CURRENT_ROUTE');
            localStorage.removeItem('PERSIST_CURRENT_APP_ID');
            localStorage.removeItem('PERSIST_CURRENT_APP_ENTITY_ID');
            localStorage.removeItem('PERSIST_CURRENT_APP_TYPE_ID');

            dispatch({ type: "PERSIST_CURRENT_ROUTE", payload: routes });
            dispatch({ type: "PERSIST_CURRENT_APP_ID", payload: currentAppId });
            dispatch({ type: "PERSIST_CURRENT_APP_ENTITY_ID", payload: currentEntityID });
            dispatch({ type: "PERSIST_CURRENT_APP_TYPE_ID", payload: currentAppTypeId });
        }
        //Add to recent Queue
        recentQueueService.updateRecentQueue({
            entityId: currentEntityID,
            parentId: currentAppId,
            parentTypeId: 3,
            appTypeId: 2,
            routes: JSON.stringify(routes)
        }).then((data) => {
            var token = parseInt(localStorage.getItem('updateRecentQueue')) ? parseInt(localStorage.getItem('updateRecentQueue')) : 0;
            localStorage.setItem('updateRecentQueue', token + 1)
            dispatch({ type: "PERSIST_CURRENT_RECENT_QUEUE", payload: parseInt(localStorage.getItem('updateRecentQueue')) });
        })

        renewalService.getIndividualRenewal(currentAppId).then((sectionResponse) => {
            entityService.getEntity(currentEntityID).then((entity) => {
                setEntityInfo(
                    {
                        ...entityInfo,
                        lolaNum: entity.data.lolaNumber,
                        entityName: entity.data.formattedName,
                        entityId: entity.data.entityId,
                        licenseNumber: sectionResponse.data?.credential?.formattedLicenseNumber,
                        applicationDate: sectionResponse.data?.dateSubmitted
                    })
            });
            setHasLoaded(true);
            if (sectionResponse.data.sections.findIndex((element) => element.staffReviewComplete === false) !== -1 || sectionResponse.data.status.id !== 2) {// status id 2 = pending
                setShowSectionButton(false);
            }
            getSections().then((response) => {
                issuesService.getIssuesForSections(2, currentAppId).then((data) => {
                    setSections(routes != null ? routes.map((value, i) => {
                        var section = {
                            ...response.filter(x => x.id == value.sectionId)[0]
                        };
                        if (section?.id) {
                            section.link = value.route
                            var index = sectionResponse.data.sections.findIndex(x => x.sectionType?.id == section.id);
                            if (index !== -1) {
                                var issues = data.data.filter(x => x.sectionId == section.id);
                                if (sectionResponse.data.sections[index].staffReviewComplete)
                                    section.status = "COMPLETE";
                                else if (issues.length) {
                                    section.status = `${issues[0].openIssueCount} Issue${issues[0].openIssueCount > 1 ? 's' : ''}`;
                                }
                                return <Section data={{ ...section }} key={i} />
                            }

                            return <Section data={section} key={i} />
                        }
                        return;
                    }) : []);
                })
            })
        })
    }, [])

    const sendStaffApproval = (isApproved) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to ${isApproved ? 'license' : 'decline'} this renewal? You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `${isApproved ? 'Licensing' : 'Declining'} Renewal`,
                    didOpen: () => {
                        Swal.showLoading();
                        renewalService.postStaffApproval(selector.currentApplicationId, isApproved).then((response) => {
                            if (response.data) {
                                setShowSectionButton(false);
                                Swal.fire({
                                    title: `Saving PDF`,
                                    didOpen: () => {
                                        Swal.showLoading();
                                        renewalService.postRenewalPdf(response.data.id, isApproved).then((data) => {
                                            if (data.data) {
                                                Swal.fire("Saved", `The renewal was successfully ${isApproved ? 'approved' : 'declined'}!`, "success")
                                            }
                                        })
                                    },
                                    allowOutsideClick: false
                                })
                            }
                        })
                    },
                    allowOutsideClick: false
                })
            }
        })
    }

    return (
        <div className="lsiRenewalIndexPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <br />
                <Row>
                    <Col><Information content={GetRenewalInformationContent("index")} /></Col>
                </Row>
                <br />
                {hasLoaded &&
                    <InternalTaskInfo parentTypeId={3} parentId={selector.currentApplicationId} />
                }
                <Row>
                    <Col>
                        <h4>Sections to Complete</h4>
                        <ListGroup>
                            {sections}
                        </ListGroup>
                    </Col>
                </Row>
                {showSectionButton &&
                    <div className="float-right" style={{ marginTop: '1rem' }}>
                        <Button name="approveBtn" type="submit" variant="success" onClick={() => { sendStaffApproval(true) }} style={marginRight}>Renew</Button>
                        <Button name="disapproveBtn" variant="danger" onClick={() => { sendStaffApproval(false) }} style={marginRight}>Decline</Button>
                    </div>
                }
            </Container>
        </div>
    )
}
export default Index;