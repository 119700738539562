import axios from "axios";
import authHeader from '../../authheader.js'

const INDIVIDUALURL = process.env.REACT_APP_APIURL + "/api/Individual";

const getEducations = async (entityId) => {
    return axios.get(`${INDIVIDUALURL}/geteducations`, { headers: authHeader(), params: { entityId } });
}
const saveEducation = async (data) => {
    return axios.post(`${INDIVIDUALURL}/saveeducation`, data, { headers: authHeader() });
}
const deleteEducation = async (id) => {
    return axios.post(`${INDIVIDUALURL}/deleteeducation`, null, { headers: authHeader(), params: { id } });

}
export default {
    getEducations,
    saveEducation,
    deleteEducation
}