import React from "react";
import RenderRoutes from "./renderroutes"
import DashboardPage from "./../Internal/Dashboard/DashboardPage";
import QueuePage from "./../Internal/Queue/QueuePage";
import AskLolaPage from "../Shared/AskLola/AskLolaPage"
import UserProfilePage from "../Shared/UserProfile/UserProfilePage"
import EntitySearchPage from "./../Internal/Search/EntitySearchPage"
import FirmCredentialPage from "./../Internal/Credential/Firm/CredentialPage";
import IndCredentialPage from "./../Internal/Credential/Individual/CredentialPage";
import AllRecentContacts from "./../Internal/History/AllRecentContacts";
import AllRecentQueue from "./../Internal/History/AllRecentQueue";
import ContactChangeLog from "./../Internal/History/ContactChangeLog";
import CredentialChangeLog from "./../Internal/History/CredentialChangeLog";
import RedirectToSection from "../Shared/Redirect/RedirectToSection";

//#region PLS 
//PLS App
import PlsAppSection from "../Internal/Applications/PLS/Sections";
import PlsAppContactPage from "./../Internal/Applications/PLS/ContactInformation/ContactInformationPage";
import PlsAppEducationPage from "./../Internal/Applications/PLS/Education/EducationPage";
import PlsAppReferencesPage from "./../Internal/Applications/PLS/PersonalReferences/PersonalReferencesPage";
import PlsAppViolations from '../Internal/Applications/PLS/Violations/Violations'
import PlsAppEmploymentPage from "./../Internal/Applications/PLS/Employment/EmploymentPage";
import PlsAppQuizzesPage from "./../Internal/Applications/PLS/Quizzes/QuizzesPage";
import PlsAppExperiencePage from "../Internal/Applications/PLS/Experience/ExperiencePage";
import PlsAppAckPage from "./../Internal/Applications/PLS/Acknowledgement/AcknowledgementPage";
import PlsAppReviewPage from "./../Internal/Applications/PLS/Review/ReviewPage";
import PlsAppBoardApprovalPage from "./../Internal/Applications/PLS/BoardApproval/BoardApprovalPage";
//PLS Reinstatement
import PlsReinstatementSections from "./../Internal/Reinstatements/PLS/Sections";
import PlsReinstatementContactPage from "./../Internal/Reinstatements/PLS/ContactInformation/ContactInformationPage";
import PlsReinstatementEmploymentPage from "./../Internal/Reinstatements/PLS/Employment/EmploymentPage";
import PlsReinstatementViolationsPage from "./../Internal/Reinstatements/PLS/Violations/Violations";
import PlsReinstatementQuizzesPage from "./../Internal/Reinstatements/PLS/Quizzes/QuizzesPage";
import PlsReinstatementCpdPage from "./../Internal/Reinstatements/PLS/CpdRequirements/CpdRequirementsPage";
import PlsReinstatementPersonalReferencePage from "./../Internal/Reinstatements/PLS/PersonalReferences/PersonalReferencesPage";
import PlsReinstatementExperiencePage from "./../Internal/Reinstatements/PLS/Experience/ExperiencePage";
import PlsReinstatementDisclosurePage from "./../Internal/Reinstatements/PLS/Disclosure/DisclosurePage";
import PlsReinstatementAckPage from "./../Internal/Reinstatements/PLS/Acknowledgement/AcknowledgementPage";
import PlsReinstatementReviewPage from "./../Internal/Reinstatements/PLS/Review/ReviewPage";
import PlsReinstatementBoardApprovalPage from "./../Internal/Reinstatements/PLS/BoardApproval/BoardApprovalPage";
//PLS Renewal
import PlsRenewalSections from "./../Internal/Renewals/PLS/Sections";
import PlsRenewalContactPage from "./../Internal/Renewals/PLS/ContactInformation/ContactInformationPage";
import PlsRenewalLicenseStatusPage from "./../Internal/Renewals/PLS/LicenseStatus/LicenseStatusPage";
import PlsRenewalsCpdPage from "./../Internal/Renewals/PLS/CpdRequirements/CpdRequirementsPage";
import PlsRenewalsViolationsPage from "./../Internal/Renewals/PLS/Violations/Violations";
//import PlsRenewalSupervisingProfessional from "./../Internal/Renewals/PLS/SupervisingProfessional/SupervisingProfessionalPage";
import PlsRenewalPrivacyPage from "./../Internal/Renewals/PLS/Privacy/Privacy";
import PlsRenewalAckPage from "./../Internal/Renewals/PLS/Acknowledgement/AcknowledgementPage";
import PlsRenewalReviewPage from "./../Internal/Renewals/PLS/Review/ReviewPage";
//#endregion

//#region LSI
//LSI App
import LsiAppSection from "../Internal/Applications/LSI/Index/Index";
import LsiAppContactPage from "./../Internal/Applications/LSI/ContactInformation/ContactInformationPage";
import LsiAppEducationPage from "./../Internal/Applications/LSI/Education/EducationPage";
import LsiAppViolationsPage from '../Internal/Applications/LSI/Violations/ViolationsPage'
import LsiAppExamVerificationPage from '../Internal/Applications/LSI/ExaminationVerification/ExaminationVerificationPage'
import LsiAppRecommendationPage from '../Internal/Applications/LSI/Recommendation/RecommendationPage'
import LsiAppAckPage from "./../Internal/Applications/LSI/Acknowledgement/AcknowledgementPage";
import LsiAppReviewPage from "./../Internal/Applications/LSI/Review/ReviewPage";
import LsiAppBoardApprovalPage from "./../Internal/Applications/LSI/BoardApproval/BoardApprovalPage";
//LSI Renewal
import LsiRenewalsIndexPage from "./../Internal/Renewals/LSI/Index/Index";
import LsiRenewalContactPage from "./../Internal/Renewals/LSI/ContactInformation/ContactInformationPage";
import LsiRenewalsViolationsPage from "./../Internal/Renewals/LSI/Violations/ViolationsPage";
import LsiRenewalsPrivacyPage from "./../Internal/Renewals/LSI/Privacy/PrivacyPage";
import LsiRenewalAckPage from "./../Internal/Renewals/LSI/Acknowledgement/AcknowledgementPage";
import LsiRenewalReviewPage from "./../Internal/Renewals/LSI/Review/ReviewPage";
import LsiRenewalBoardApprovalPage from "./../Internal/Renewals/LSI/BoardApproval/BoardApprovalPage";
//Lsi Reinstatement
import LsiReinstatementIndexPage from "./../Internal/Reinstatements/LSI/Index/Index";
import LsiReinstatementContactPage from "./../Internal/Reinstatements/LSI/ContactInformation/ContactInformationPage";
import LsiReinstatementEducationPage from "./../Internal/Reinstatements/LSI/Education/EducationPage";
import LsiReinstatementExaminationPage from "./../Internal/Reinstatements/LSI/ExaminationVerification/ExaminationVerification";
import LsiReinstatementViolationsPage from "./../Internal/Reinstatements/LSI/Violations/Violations";
import LsiReinstatementAckPage from "./../Internal/Reinstatements/LSI/Acknowledgment/AcknowledgmentPage";
import LsiReinstatementReviewPage from "./../Internal/Reinstatements/LSI/Review/ReviewPage";
import LsiReinstatementBoardApprovalPage from "./../Internal/Reinstatements/LSI/BoardApproval/BoardApprovalPage";
//#endregion

//#region VF

//VF App
import VfAppSection from "../Internal/Applications/VF/Index";
import VfAppContactPage from "./../Internal/Applications/VF/ContactInformation/ContactInformationPage";
import VfAppViolationsPage from '../Internal/Applications/VF/Violations/ViolationsPage'
import VfAppSupervisingProfessionalPage from "./../Internal/Applications/VF/SupervisingProfessional/SupervisingProfessionalPage";
import VfAppUnlicensedActivityPage from "./../Internal/Applications/VF/UnlicensedActivity/UnlicensedActivityPage";
import VfAppWaiverCertificatePage from "./../Internal/Applications/VF/WaiverCertificate/WaiverCertificatePage";
import VfAppAckPage from "./../Internal/Applications/VF/Acknowledgement/AcknowledgementPage";
import VfAppReviewPage from "./../Internal/Applications/VF/Review/ReviewPage";
import VfAppBoardApprovalPage from "./../Internal/Applications/VF/BoardApproval/BoardApprovalPage";

//VF Reinstatement
import VfReinstatementSection from "../Internal/Reinstatements/VF/Index";
import VfReinstatementContactPage from "./../Internal/Reinstatements/VF/ContactInformation/ContactInformationPage";
import VfReinstatementViolationsPage from '../Internal/Reinstatements/VF/Violations/ViolationsPage'
import VfReinstatementSupervisingProfessionalPage from "./../Internal/Reinstatements/VF/SupervisingProfessional/SupervisingProfessionalPage";
import VfReinstatementUnlicensedActivityPage from "./../Internal/Reinstatements/VF/UnlicensedActivity/UnlicensedActivityPage";
import VfReinstatementWaiverCertificatePage from "./../Internal/Reinstatements/VF/WaiverCertificate/WaiverCertificatePage";
import VfReinstatementAckPage from "./../Internal/Reinstatements/VF/Acknowledgement/AcknowledgementPage";
import VfReinstatementReviewPage from "./../Internal/Reinstatements/VF/Review/ReviewPage";
import VfReinstatementBoardApprovalPage from "./../Internal/Reinstatements/VF/BoardApproval/BoardApprovalPage";

//VF Renewal
import VfRenewalSection from "../Internal/Renewals/VF/Index";
import VfRenewalContactPage from "./../Internal/Renewals/VF/ContactInformation/ContactInformationPage";
import VfRenewalViolationsPage from '../Internal/Renewals/VF/Violations/ViolationsPage'
import VfRenewalSupervisingProfessionalPage from '../Internal/Renewals/VF/SupervisingProfessional/SupervisingProfessionalPage';
import VfRenewalAckPage from "./../Internal/Renewals/VF/Acknowledgement/AcknowledgementPage";
import VfRenewalWaiverCertificatePage from "./../Internal/Renewals/VF/WaiverCertificate/WaiverCertificatePage";
import VfRenewalReviewPage from "./../Internal/Renewals/VF/Review/ReviewPage";
//#endregion

//#region EF

//EF App
import EfAppSection from "../Internal/Applications/EF/Index";
import EfAppContactPage from "./../Internal/Applications/EF/ContactInformation/ContactInformationPage";
import EfAppViolationsPage from '../Internal/Applications/EF/Violations/ViolationsPage'
import EfAppSupervisingProfessionalPage from "./../Internal/Applications/EF/SupervisingProfessional/SupervisingProfessionalPage";
import EfAppUnlicensedActivityPage from "./../Internal/Applications/EF/UnlicensedActivity/UnlicensedActivityPage";
import EfAppWaiverCertificatePage from "./../Internal/Applications/EF/WaiverCertificate/WaiverCertificatePage";
import EfAppAckPage from "./../Internal/Applications/EF/Acknowledgement/AcknowledgementPage";
import EfAppReviewPage from "./../Internal/Applications/EF/Review/ReviewPage";
import EfAppBoardApprovalPage from "./../Internal/Applications/EF/BoardApproval/BoardApprovalPage";

//EF Reinstatement
import EfReinstatementSection from "../Internal/Reinstatements/EF/Index";
import EfReinstatementContactPage from "./../Internal/Reinstatements/EF/ContactInformation/ContactInformationPage";
import EfReinstatementViolationsPage from '../Internal/Reinstatements/EF/Violations/ViolationsPage'
import EfReinstatementSupervisingProfessionalPage from "./../Internal/Reinstatements/EF/SupervisingProfessional/SupervisingProfessionalPage";
import EfReinstatementUnlicensedActivityPage from "./../Internal/Reinstatements/EF/UnlicensedActivity/UnlicensedActivityPage";
import EfReinstatementWaiverCertificatePage from "./../Internal/Reinstatements/EF/WaiverCertificate/WaiverCertificatePage";
import EfReinstatementAckPage from "./../Internal/Reinstatements/EF/Acknowledgement/AcknowledgementPage";
import EfReinstatementReviewPage from "./../Internal/Reinstatements/EF/Review/ReviewPage";
import EfReinstatementBoardApprovalPage from "./../Internal/Reinstatements/EF/BoardApproval/BoardApprovalPage";

//EF Renewal
import EfRenewalSection from "../Internal/Renewals/EF/Index";
import EfRenewalContactPage from "./../Internal/Renewals/EF/ContactInformation/ContactInformationPage";
import EfRenewalViolationsPage from '../Internal/Renewals/EF/Violations/ViolationsPage'
import EfRenewalSupervisingProfessionalPage from '../Internal/Renewals/EF/SupervisingProfessional/SupervisingProfessionalPage';
import EfRenewalAckPage from "./../Internal/Renewals/EF/Acknowledgement/AcknowledgementPage";
import EfRenewalWaiverCertificatePage from "./../Internal/Renewals/EF/WaiverCertificate/WaiverCertificatePage";
import EfRenewalReviewPage from "./../Internal/Renewals/EF/Review/ReviewPage";
//#endregion

//#region PE
//PE Application
import PeAppSectionPage from '../Internal/Applications/PE/Index/Index';
import PeAppContactPage from "./../Internal/Applications/PE/ContactInformation/ContactInformationPage";
import PeAppEducationPage from "./../Internal/Applications/PE/Education/EducationPage";
import PeAppReferencesPage from "./../Internal/Applications/PE/PersonalReferences/PersonalReferencesPage";
import PeAppExperiencePage from "./../Internal/Applications/PE/Experience/ExperiencePage";
import PeAppViolations from '../Internal/Applications/PE/Violations/Violations'
import PeAppQuizzesPage from "./../Internal/Applications/PE/Quizzes/QuizzesPage";
import PeAppCoopExperiencePage from "../Internal/Applications/PE/CoopExperiences/CoopExperiencePage";
import PeAppResidencyPage from "../Internal/Applications/PE/Residency/ResidencyPage";
import PeAppEmployementPage from "../Internal/Applications/PE/Employment/EmploymentPage";
import PeAppAckPage from "./../Internal/Applications/PE/Acknowledgement/AcknowledgementPage";
import PeAppReviewPage from "./../Internal/Applications/PE/Review/ReviewPage";
import PeAppBoardApprovalPage from "./../Internal/Applications/PE/BoardApproval/BoardApprovalPage";

//PE Renewal
import PeRenewalIndexPage from "./../Internal/Renewals/PE/Index/Index";
import PeRenewalContactPage from "./../Internal/Renewals/PE/ContactInformation/ContactInformationPage";
import PeRenewalLicenseStatusPage from "./../Internal/Renewals/PE/LicenseStatus/LicenseStatusPage";
import PeRenewalCpdRequirementsPage from "./../Internal/Renewals/PE/CpdRequirements/CpdRequirementsPage";
import PeRenewalDesignPage from '../Internal/Renewals/PE/Design/BuildingDesignPage';
import PeRenewalViolationsPage from "./../Internal/Renewals/PE/Violations/Violations";
import PeRenewalPrivacyPage from "./../Internal/Renewals/PE/Privacy/Privacy";
import PeRenewalAckPage from '../Internal/Renewals/PE/Acknowledgement/AcknowledgementPage';
import PeRenewalReviewPage from "./../Internal/Renewals/PE/Review/ReviewPage";

//PE Reinstatement
import PeReinstatementIndexPage from "./../Internal/Reinstatements/PE/Index/Index";
import PeReinstatementContactPage from "./../Internal/Reinstatements/PE/ContactInformation/ContactInformationPage";
import PeReinstatementDesignPage from './../Internal/Reinstatements/PE/Design/BuildingDesignPage';
import PeReinstatementEmploymentPage from "./../Internal/Reinstatements/PE/Employment/EmploymentPage";
import PeReinstatementViolationsPage from "./../Internal/Reinstatements/PE/Violations/Violations";
import PeReinstatementQuizzesPage from "./../Internal/Reinstatements/PE/Quizzes/QuizzesPage";
import PeReinstatementCpdPage from "./../Internal/Reinstatements/PE/CpdRequirements/CpdRequirementsPage";
import PeReinstatementPersonalReferencePage from "./../Internal/Reinstatements/PE/PersonalReferences/PersonalReferencesPage";
import PeReinstatementExperiencePage from "./../Internal/Reinstatements/PE/Experience/ExperiencePage";
import PeReinstatementDisclosurePage from "./../Internal/Reinstatements/PE/Disclosure/DisclosurePage";
import PeReinstatementAckPage from "./../Internal/Reinstatements/PE/Acknowledgement/AcknowledgementPage";
import PeReinstatementReviewPage from "./../Internal/Reinstatements/PE/Review/ReviewPage";
import PeReinstatementBoardApprovalPage from "./../Internal/Reinstatements/PE/BoardApproval/BoardApprovalPage";
//#endregion

//#region EI
//Ei App
import EiAppSectionPage from '../Internal/Applications/EI/Index/Index';
import EiAppContactPage from "./../Internal/Applications/EI/ContactInformation/ContactInformationPage";
import EiAppEducationPage from "./../Internal/Applications/EI/Education/EducationPage";
import EiAppRecommendationPage from "./../Internal/Applications/EI/Recommendation/RecommendationPage";
import EiAppExperiencePage from "./../Internal/Applications/EI/Experience/ExperiencePage";
import EiAppViolations from '../Internal/Applications/EI/Violations/ViolationsPage'
import EiAppExaminationPage from "./../Internal/Applications/EI/ExaminationVerification/ExaminationVerificationPage";
import EiAppAckPage from "./../Internal/Applications/EI/Acknowledgement/AcknowledgementPage";
import EiAppReviewPage from "./../Internal/Applications/EI/Review/ReviewPage";
import EiAppBoardApprovalPage from "./../Internal/Applications/EI/BoardApproval/BoardApprovalPage";

//Ei Renewal
import EiRenewalContactPage from "./../Internal/Renewals/EI/ContactInformation/ContactInformationPage";
import EiRenewalsViolationsPage from "./../Internal/Renewals/EI/Violations/ViolationsPage";
import EiRenewalsPrivacyPage from "./../Internal/Renewals/EI/Privacy/PrivacyPage";
import EiRenewalsIndexPage from "./../Internal/Renewals/EI/Index/Index";
import EiRenewalAckPage from "./../Internal/Renewals/EI/Acknowledgement/AcknowledgementPage";
import EiRenewalReviewPage from "./../Internal/Renewals/EI/Review/ReviewPage";
import EiRenewalBoardApprovalPage from "./../Internal/Renewals/EI/BoardApproval/BoardApprovalPage";

//Ei Reinstatement
import EiReinstatementIndexPage from "./../Internal/Reinstatements/EI/Index/Index";
import EiReinstatementContactPage from "./../Internal/Reinstatements/EI/ContactInformation/ContactInformationPage";
import EiReinstatementEducationPage from "./../Internal/Reinstatements/EI/Education/EducationPage";
import EiReinstatementExaminationPage from "./../Internal/Reinstatements/EI/ExaminationVerification/ExaminationVerification";
import EiReinstatementViolationsPage from "./../Internal/Reinstatements/EI/Violations/Violations";
import EiReinstatementAckPage from "./../Internal/Reinstatements/EI/Acknowledgment/AcknowledgmentPage";
import EiReinstatementReviewPage from "./../Internal/Reinstatements/EI/Review/ReviewPage";
import EiReinstatementBoardApprovalPage from "./../Internal/Reinstatements/EI/BoardApproval/BoardApprovalPage";
//#endregion

//#region Revenue
import PaymentSearchPage from "../Internal/Revenue/PaymentSearch/PaymentSearchPage";
import BatchSearchPage from "../Internal/Revenue/BatchSearchPage";
import CreateBatchPage from "./../Internal/Revenue/CreateBatch/CreateBatchPage";
import DailyRefundSearchPage from "../Internal/Revenue/DailyRefundSearchPage";
import DailyPaymentsByAccountPage from "../Internal/Revenue/DailyPaymentsByAccountPage";
import CreateInvoicePage from "./../Internal/Revenue/Invoice/CreateInvoicePage";
import InvoicePage from "./../Internal/Revenue/Invoice/InvoicePage";
import InvoiceSearchPage from "./../Internal/Revenue/Invoice/InvoiceSearchPage";
//#endregion
const INTERNAL_ROUTES = [

    {
        path: "/",
        key: "ROOT",
        exact: true,
        component: () => <DashboardPage />
    },
    {
        path: "/internal",
        key: "INTERNAL",
        component: RenderRoutes,
        routes: [
            {
                path: "/internal",
                key: "INTERNAL_ROOT",
                exact: true,
                component: () => <DashboardPage />
            },
            {
                path: "/internal/dashboard",
                key: "INTERNAL_DASHBOARD",
                exact: true,
                component: () => <DashboardPage />
            },
            {
                path: "/internal/askLola",
                key: "INTERNAL_ASKLOLA",
                exact: true,
                component: () => <AskLolaPage isInternal={true} />
            },
            {
                path: "/internal/allRecentContacts",
                key: "INTERNAL_ALL_RECENT_CONTACTS",
                exact: true,
                component: () => <AllRecentContacts />
            },
            {
                path: "/internal/allRecentQueue",
                key: "INTERNAL_ALL_RECENT_QUEUE",
                exact: true,
                component: () => <AllRecentQueue />
            },
            {
                path: "/internal/contactChangeLog",
                key: "INTERNAL_CONTACT_CHANGE_LOG",
                exact: true,
                component: () => <ContactChangeLog />
            },
            {
                path: "/internal/credentialChangeLog",
                key: "INTERNAL_CREDENTIAL_CHANGE_LOG",
                exact: true,
                component: () => <CredentialChangeLog />
            },
            {
                path: "/internal/redirectToSection",
                key: "INTERNAL_REDIRECT_TO_SECTION",
                exact: true,
                component: () => <RedirectToSection linkPrefix={"internal"} />
            },
            {
                path: "/internal/userProfile",
                key: "INTERNAL_USER_PROFILE",
                exact: true,
                component: () => <UserProfilePage />
            },
            {
                path: "/internal/queue",
                key: "INTERNAL_DASHBOARD",
                exact: true,
                component: () => <QueuePage />
            },
            {
                path: "/internal/search",
                key: "INTERNAL_SEARCH",
                exact: true,
                component: () => <EntitySearchPage />
            },
            {
                path: "/internal/firmcredential",
                key: "INTERNAL_FIRM_CREDENTIAL",
                exact: true,
                component: () => <FirmCredentialPage />
            },
            {
                path: "/internal/indcredential",
                key: "INTERNAL_INDIVIDUAL_CREDENTIAL",
                exact: true,
                component: () => <IndCredentialPage />
            },
            {
                path: "/internal/plsApp/sections",
                key: "INTERNAL_PLS_APPLICATIONS_SECTIONS",
                exact: true,
                component: () => <PlsAppSection />
            },
            {
                path: "/internal/plsApp/contactInformation",
                key: "INTERNAL_PLS_APPLICATIONS_CONTACTINFORMATION",
                exact: true,
                component: () => <PlsAppContactPage />
            },
            {
                path: "/internal/plsApp/education",
                key: "INTERNAL_PLS_APPLICATIONS_EDUCATION",
                exact: true,
                component: () => <PlsAppEducationPage />
            },
            {
                path: "/internal/plsApp/personalReferences",
                key: "INTERNAL_PLS_APPLICATIONS_PERSONALREFERENCES",
                exact: true,
                component: () => <PlsAppReferencesPage />
            },
            {
                path: "/internal/plsApp/violations",
                key: "INTERNAL_PLS_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <PlsAppViolations />
            },
            {
                path: "/internal/plsApp/employment",
                key: "INTERNAL_PLS_APPLICATIONS_EMPLOYMENT",
                exact: true,
                component: () => <PlsAppEmploymentPage />
            },
            {
                path: "/internal/plsApp/quizzes",
                key: "INTERNAL_PLS_APPLICATIONS_QUIZZES",
                exact: true,
                component: () => <PlsAppQuizzesPage />
            },
            {
                path: "/internal/plsApp/experience",
                key: "INTERNAL_PLS_APPLICATIONS_EXPERIENCE",
                exact: true,
                component: () => <PlsAppExperiencePage />
            },
            {
                path: "/internal/plsApp/acknowledgement",
                key: "INTERNAL_PLS_APPLICATIONS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <PlsAppAckPage />
            },
            {
                path: "/internal/plsApp/review",
                key: "INTERNAL_PLS_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <PlsAppReviewPage />
            },
            {
                path: "/internal/plsApp/boardApproval",
                key: "INTERNAL_PLS_APPLICATIONS_BOARDAPPROVAL",
                exact: true,
                component: () => <PlsAppBoardApprovalPage />
            },
            {
                path: "/internal/plsReinstatement/sections",
                key: "INTERNAL_PLS_REINSTATEMENTS_SECTIONS",
                exact: true,
                component: () => <PlsReinstatementSections />
            },
            {
                path: "/internal/plsReinstatement/contactInformation",
                key: "INTERNAL_PLS_REINSTATEMENTS_CONTACT_INFORMATION",
                exact: true,
                component: () => <PlsReinstatementContactPage />
            },
            {
                path: "/internal/plsReinstatement/quizzes",
                key: "INTERNAL_PLS_REINSTATEMENTS_QUIZZES",
                exact: true,
                component: () => <PlsReinstatementQuizzesPage />
            },
            {
                path: "/internal/plsReinstatement/cpdRequirements",
                key: "INTERNAL_PLS_REINSTATEMENTS_CPD_REQUIREMENTS",
                exact: true,
                component: () => <PlsReinstatementCpdPage />
            },
            {
                path: "/internal/plsReinstatement/acknowledgement",
                key: "INTERNAL_PLS_REINSTATEMENTS_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PlsReinstatementAckPage />
            },
            {
                path: "/internal/plsReinstatement/review",
                key: "INTERNAL_PLS_REINSTATEMENTS_REVIEW",
                exact: true,
                component: () => <PlsReinstatementReviewPage />
            },
            {
                path: "/internal/plsReinstatement/boardApproval",
                key: "INTERNAL_PLS_REINSTATEMENTS_BOARD_APPROVAL",
                exact: true,
                component: () => <PlsReinstatementBoardApprovalPage />
            },
            {
                path: "/internal/plsReinstatement/employment",
                key: "INTERNAL_PLS_REINSTATEMENTS_EMPLOYMENT",
                exact: true,
                component: () => <PlsReinstatementEmploymentPage />
            },
            {
                path: "/internal/plsReinstatement/personalReferences",
                key: "INTERNAL_PLS_REINSTATEMENTS_PERSONAL_REFERENCE",
                exact: true,
                component: () => <PlsReinstatementPersonalReferencePage />
            },
            {
                path: "/internal/plsReinstatement/disclosure",
                key: "INTERNAL_PLS_REINSTATEMENTS_DISCLOSURE",
                exact: true,
                component: () => <PlsReinstatementDisclosurePage />
            },
            {
                path: "/internal/plsReinstatement/violations",
                key: "INTERNAL_PLS_REINSTATEMENTS_VIOLATIONS",
                exact: true,
                component: () => <PlsReinstatementViolationsPage />
            },
            {
                path: "/internal/plsReinstatement/experience",
                key: "INTERNAL_PLS_REINSTATEMENTS_EXPERIENCE",
                exact: true,
                component: () => <PlsReinstatementExperiencePage />
            },
            {
                path: "/internal/plsRenewal/sections",
                key: "INTERNAL_PLS_RENEWAL_SECTIONS",
                exact: true,
                component: () => <PlsRenewalSections />
            },
            {
                path: "/internal/plsRenewal/contactInformation",
                key: "INTERNAL_PLS_RENEWAL_CONTACT_INFORMATION",
                exact: true,
                component: () => <PlsRenewalContactPage />
            },
            {
                path: "/internal/plsRenewal/cpdRequirements",
                key: "INTERNAL_PLS_RENEWAL_CPD_REQUIREMENTS",
                exact: true,
                component: () => <PlsRenewalsCpdPage />
            },
            {
                path: "/internal/plsRenewal/licenseStatus",
                key: "INTERNAL_PLS_RENEWAL_LICENSE_STATUS",
                exact: true,
                component: () => <PlsRenewalLicenseStatusPage />
            },
            {
                path: "/internal/plsRenewal/violations",
                key: "INTERNAL_PLS_RENEWAL_VIOLATIONS",
                exact: true,
                component: () => <PlsRenewalsViolationsPage />
            },
            //{
            //    path: "/internal/plsRenewal/supervisingProfessional",
            //    key: "INTERNAL_PLS_RENEWAL_SUPERVISING_PROFESSIONAL",
            //    exact: true,
            //    component: () => <PlsRenewalSupervisingProfessional />
            //},
            {
                path: "/internal/plsRenewal/privacy",
                key: "INTERNAL_PLS_RENEWAL_PRIVACY",
                exact: true,
                component: () => <PlsRenewalPrivacyPage />
            },
            {
                path: "/internal/plsRenewal/acknowledgement",
                key: "INTERNAL_PLS_RENEWAL_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PlsRenewalAckPage />
            },
            {
                path: "/internal/plsRenewal/review",
                key: "INTERNAL_PLS_RENEWAL_REVIEW",
                exact: true,
                component: () => <PlsRenewalReviewPage />
            },
            {
                path: "/internal/vfApp/boardApproval",
                key: "INTERNAL_VF_APPLICATIONS_BOARDAPPROVAL",
                exact: true,
                component: () => <VfAppBoardApprovalPage />
            },
            {
                path: "/internal/vfApp/contactInformation",
                key: "INTERNAL_VF_APPLICATIONS_CONTACT_INFORMATION",
                exact: true,
                component: () => <VfAppContactPage />
            },
            {
                path: "/internal/vfApp/review",
                key: "INTERNAL_VF_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <VfAppReviewPage />
            },
            {
                path: "/internal/vfApp/supervisingProfessional",
                key: "INTERNAL_VF_APPLICATIONS_SUPERVISING_PROFESSIONAL",
                exact: true,
                component: () => <VfAppSupervisingProfessionalPage />
            },
            {
                path: "/internal/vfApp/unlicensedActivity",
                key: "INTERNAL_VF_APPLICATIONS_UNLICENSED_ACTIVITY",
                exact: true,
                component: () => <VfAppUnlicensedActivityPage />
            },
            {
                path: "/internal/vfApp/violations",
                key: "INTERNAL_VF_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <VfAppViolationsPage />
            },
            {
                path: "/internal/vfApp/waiver",
                key: "INTERNAL_VF_APPLICATIONS_WAIVER",
                exact: true,
                component: () => <VfAppWaiverCertificatePage />
            },
            {
                path: "/internal/vfApp/sections",
                key: "INTERNAL_VF_APPLICATIONS_SECTIONS",
                exact: true,
                component: () => <VfAppSection />
            },
            {
                path: "/internal/vfApp/acknowledgement",
                key: "INTERNAL_VF_APPLICATIONS_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <VfAppAckPage />
            },
            //VF Reinstatement
            {
                path: "/internal/vfReinstatement/boardapproval",
                key: "INTERNAL_VF_REINSTATEMENTS_BOARDAPPROVAL",
                exact: true,
                component: () => <VfReinstatementBoardApprovalPage />
            },
            {
                path: "/internal/vfReinstatement/contactInformation",
                key: "INTERNAL_VF_REINSTATEMENTS_CONTACT_INFORMATION",
                exact: true,
                component: () => <VfReinstatementContactPage />
            },
            {
                path: "/internal/vfReinstatement/review",
                key: "INTERNAL_VF_REINSTATEMENTS_REVIEW",
                exact: true,
                component: () => <VfReinstatementReviewPage />
            },
            {
                path: "/internal/vfReinstatement/supervisingProfessional",
                key: "INTERNAL_VF_REINSTATEMENTS_SUPERVISING_PROFESSIONAL",
                exact: true,
                component: () => <VfReinstatementSupervisingProfessionalPage />
            },
            {
                path: "/internal/vfReinstatement/unlicensedActivity",
                key: "INTERNAL_VF_REINSTATEMENTS_UNLICENSED_ACTIVITY",
                exact: true,
                component: () => <VfReinstatementUnlicensedActivityPage />
            },
            {
                path: "/internal/vfReinstatement/violations",
                key: "INTERNAL_VF_REINSTATEMENTS_VIOLATIONS",
                exact: true,
                component: () => <VfReinstatementViolationsPage />
            },
            {
                path: "/internal/vfReinstatement/waiver",
                key: "INTERNAL_VF_REINSTATEMENTS_WAIVER",
                exact: true,
                component: () => <VfReinstatementWaiverCertificatePage />
            },
            {
                path: "/internal/vfReinstatement/sections",
                key: "INTERNAL_VF_REINSTATEMENTS_SECTIONS",
                exact: true,
                component: () => <VfReinstatementSection />
            },
            {
                path: "/internal/vfReinstatement/acknowledgement",
                key: "INTERNAL_VF_REINSTATEMENTS_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <VfReinstatementAckPage />
            },
            //VF Renewal
            {
                path: "/internal/vfRenewal/acknowledgement",
                key: "INTERNAL_VF_RENEWAL_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <VfRenewalAckPage />
            },
            {
                path: "/internal/vfRenewal/contactInformation",
                key: "INTERNAL_VF_RENEWAL_CONTACT_INFORMATION",
                exact: true,
                component: () => <VfRenewalContactPage />
            },
            {
                path: "/internal/vfRenewal/review",
                key: "INTERNAL_VF_RENEWAL_REVIEW",
                exact: true,
                component: () => <VfRenewalReviewPage />
            },
            {
                path: "/internal/vfRenewal/violations",
                key: "INTERNAL_VF_RENEWAL_VIOLATIONS",
                exact: true,
                component: () => <VfRenewalViolationsPage />
            },
            {
                path: "/internal/vfRenewal/supervisingprofessional",
                key: "INTERNAL_VF_RENEWAL_SUPERVISINGPROFESSIONAL",
                exact: true,
                component: () => <VfRenewalSupervisingProfessionalPage />
            },
            {
                path: "/internal/vfRenewal/waiver",
                key: "INTERNAL_VF_RENEWAL_WAIVER",
                exact: true,
                component: () => <VfRenewalWaiverCertificatePage />
            },
            {
                path: "/internal/vfRenewal/sections",
                key: "INTERNAL_VF_RENEWAL_SECTIONS",
                exact: true,
                component: () => <VfRenewalSection />
            },
            // EF
            {
                path: "/internal/efapp/boardapproval",
                key: "INTERNAL_EF_APPLICATIONS_BOARDAPPROVAL",
                exact: true,
                component: () => <EfAppBoardApprovalPage />
            },
            {
                path: "/internal/efapp/contactinformation",
                key: "INTERNAL_EF_APPLICATIONS_CONTACT_INFORMATION",
                exact: true,
                component: () => <EfAppContactPage />
            },
            {
                path: "/internal/efapp/review",
                key: "INTERNAL_EF_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <EfAppReviewPage />
            },
            {
                path: "/internal/efapp/supervisingprofessional",
                key: "INTERNAL_EF_APPLICATIONS_SUPERVISING_PROFESSIONAL",
                exact: true,
                component: () => <EfAppSupervisingProfessionalPage />
            },
            {
                path: "/internal/efapp/unlicensedactivity",
                key: "INTERNAL_EF_APPLICATIONS_UNLICENSED_ACTIVITY",
                exact: true,
                component: () => <EfAppUnlicensedActivityPage />
            },
            {
                path: "/internal/efapp/violations",
                key: "INTERNAL_EF_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <EfAppViolationsPage />
            },
            {
                path: "/internal/efapp/waiver",
                key: "INTERNAL_EF_APPLICATIONS_WAIVER",
                exact: true,
                component: () => <EfAppWaiverCertificatePage />
            },
            {
                path: "/internal/efapp/sections",
                key: "INTERNAL_EF_APPLICATIONS_SECTIONS",
                exact: true,
                component: () => <EfAppSection />
            },
            {
                path: "/internal/efapp/acknowledgement",
                key: "INTERNAL_EF_APPLICATIONS_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <EfAppAckPage />
            },
            //VF Reinstatement
            {
                path: "/internal/efreinstatement/boardapproval",
                key: "INTERNAL_EF_REINSTATEMENTS_BOARDAPPROVAL",
                exact: true,
                component: () => <EfReinstatementBoardApprovalPage />
            },
            {
                path: "/internal/efreinstatement/contactinformation",
                key: "INTERNAL_EF_REINSTATEMENTS_CONTACT_INFORMATION",
                exact: true,
                component: () => <EfReinstatementContactPage />
            },
            {
                path: "/internal/efreinstatement/review",
                key: "INTERNAL_EF_REINSTATEMENTS_REVIEW",
                exact: true,
                component: () => <EfReinstatementReviewPage />
            },
            {
                path: "/internal/efreinstatement/supervisingprofessional",
                key: "INTERNAL_EF_REINSTATEMENTS_SUPERVISING_PROFESSIONAL",
                exact: true,
                component: () => <EfReinstatementSupervisingProfessionalPage />
            },
            {
                path: "/internal/efreinstatement/unlicensedactivity",
                key: "INTERNAL_EF_REINSTATEMENTS_UNLICENSED_ACTIVITY",
                exact: true,
                component: () => <EfReinstatementUnlicensedActivityPage />
            },
            {
                path: "/internal/efreinstatement/violations",
                key: "INTERNAL_EF_REINSTATEMENTS_VIOLATIONS",
                exact: true,
                component: () => <EfReinstatementViolationsPage />
            },
            {
                path: "/internal/efreinstatement/waiver",
                key: "INTERNAL_EF_REINSTATEMENTS_WAIVER",
                exact: true,
                component: () => <EfReinstatementWaiverCertificatePage />
            },
            {
                path: "/internal/efreinstatement/sections",
                key: "INTERNAL_EF_REINSTATEMENTS_SECTIONS",
                exact: true,
                component: () => <EfReinstatementSection />
            },
            {
                path: "/internal/efreinstatement/acknowledgement",
                key: "INTERNAL_EF_REINSTATEMENTS_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <EfReinstatementAckPage />
            },
            //VF Renewal
            {
                path: "/internal/efrenewal/acknowledgement",
                key: "INTERNAL_EF_RENEWAL_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <EfRenewalAckPage />
            },
            {
                path: "/internal/efrenewal/contactinformation",
                key: "INTERNAL_EF_RENEWAL_CONTACT_INFORMATION",
                exact: true,
                component: () => <EfRenewalContactPage />
            },
            {
                path: "/internal/efrenewal/review",
                key: "INTERNAL_EF_RENEWAL_REVIEW",
                exact: true,
                component: () => <EfRenewalReviewPage />
            },
            {
                path: "/internal/efrenewal/violations",
                key: "INTERNAL_EF_RENEWAL_VIOLATIONS",
                exact: true,
                component: () => <EfRenewalViolationsPage />
            },
            {
                path: "/internal/efrenewal/supervisingprofessional",
                key: "INTERNAL_EF_RENEWAL_SUPERVISINGPROFESSIONAL",
                exact: true,
                component: () => <EfRenewalSupervisingProfessionalPage />
            },
            {
                path: "/internal/efRenewal/waiver",
                key: "INTERNAL_EF_RENEWAL_WAIVER",
                exact: true,
                component: () => <EfRenewalWaiverCertificatePage />
            },
            {
                path: "/internal/efRenewal/sections",
                key: "INTERNAL_EF_RENEWAL_SECTIONS",
                exact: true,
                component: () => <EfRenewalSection />
            },
            //#region PE
            //App
            {
                path: "/internal/peapp/sections",
                key: "INTERNAL_PE_APPLICATION_SECTION",
                exact: true,
                component: () => <PeAppSectionPage />
            },
            {
                path: "/internal/peapp/contactinformation",
                key: "INTERNAL_PE_APPLICATION_CONTACTINFO",
                exact: true,
                component: () => <PeAppContactPage />
            },
            {
                path: "/internal/peapp/education",
                key: "INTERNAL_PE_APPLICATION_EDUCATION",
                exact: true,
                component: () => <PeAppEducationPage />
            },
            {
                path: "/internal/peapp/personalreferences",
                key: "INTERNAL_PE_APPLICATION_PERSONALREFERENCE",
                exact: true,
                component: () => <PeAppReferencesPage />
            },
            {
                path: "/internal/peapp/experience",
                key: "INTERNAL_PE_APPLICATION_EXPERIENCE",
                exact: true,
                component: () => <PeAppExperiencePage />
            },
            {
                path: "/internal/peapp/violations",
                key: "INTERNAL_PE_APPLICATION_VIOLATIONS",
                exact: true,
                component: () => <PeAppViolations />
            },
            {
                path: "/internal/peapp/quizzes",
                key: "INTERNAL_PE_APPLICATION_QUIZZES",
                exact: true,
                component: () => <PeAppQuizzesPage />
            },
            {
                path: "/internal/peapp/coopexperience",
                key: "INTERNAL_PE_APPLICATION_COOPEXPERIENCE",
                exact: true,
                component: () => <PeAppCoopExperiencePage />
            },
            {
                path: "/internal/peapp/residency",
                key: "INTERNAL_PE_APPLICATION_RESIDENCY",
                exact: true,
                component: () => <PeAppResidencyPage />
            },
            {
                path: "/internal/peapp/employment",
                key: "INTERNAL_PE_APPLICATION_EMPLOYMENT",
                exact: true,
                component: () => <PeAppEmployementPage />
            },
            {
                path: "/internal/peapp/acknowledgement",
                key: "INTERNAL_PE_APPLICATION_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PeAppAckPage />
            },
            {
                path: "/internal/peapp/review",
                key: "INTERNAL_PE_APPLICATION_REVIEW",
                exact: true,
                component: () => <PeAppReviewPage />
            },
            {
                path: "/internal/peapp/boardapproval",
                key: "INTERNAL_PE_APPLICATION_BOARDAPPROVAL",
                exact: true,
                component: () => <PeAppBoardApprovalPage />
            },

            //Renewal
            {
                path: "/internal/perenewal/sections",
                key: "INTERNAL_PE_RENEWALS_Index",
                exact: true,
                component: () => <PeRenewalIndexPage />
            },
            {
                path: "/internal/perenewal/contactinformation",
                key: "INTERNAL_PE_RENEWAL_CONTACTINFORMATION",
                exact: true,
                component: () => <PeRenewalContactPage />
            },
            {
                path: "/internal/perenewal/licensestatus",
                key: "INTERNAL_PE_RENEWALS_LICENSESTATUS",
                exact: true,
                component: () => <PeRenewalLicenseStatusPage />
            },
            {
                path: "/internal/perenewal/cpdrequirements",
                key: "INTERNAL_PE_RENEWALS_CPDREQUIREMENTS",
                exact: true,
                component: () => <PeRenewalCpdRequirementsPage />
            },
            {
                path: "/internal/perenewal/design",
                key: "INTERNAL_PE_RENEWALS_DESIGN",
                exact: true,
                component: () => <PeRenewalDesignPage />
            },
            {
                path: "/internal/perenewal/violations",
                key: "INTERNAL_PE_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <PeRenewalViolationsPage />
            },
            {
                path: "/internal/perenewal/privacy",
                key: "INTERNAL_PE_RENEWALS_PRIVACY",
                exact: true,
                component: () => <PeRenewalPrivacyPage />
            },
            {
                path: "/internal/perenewal/acknowledgement",
                key: "INTERNAL_PE_RENEWALS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <PeRenewalAckPage />
            },
            {
                path: "/internal/perenewal/review",
                key: "INTERNAL_PE_RENEWALS_REVIEW",
                exact: true,
                component: () => <PeRenewalReviewPage />
            },
            //Reinstatement
            {
                path: "/internal/pereinstatement/sections",
                key: "INTERNAL_PE_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <PeReinstatementIndexPage />
            },
            {
                path: "/internal/pereinstatement/contactInformation",
                key: "INTERNAL_PE_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <PeReinstatementContactPage />
            },
            {
                path: "/internal/pereinstatement/design",
                key: "INTERNAL_PE_REINSTATEMENT_DESIGN",
                exact: true,
                component: () => <PeReinstatementDesignPage />
            },
            {
                path: "/internal/pereinstatement/employment",
                key: "INTERNAL_PE_REINSTATEMENTS_EMPLOYMENT",
                exact: true,
                component: () => <PeReinstatementEmploymentPage />
            },
            {
                path: "/internal/pereinstatement/violations",
                key: "INTERNAL_PE_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <PeReinstatementViolationsPage />
            },
            {
                path: "/internal/pereinstatement/quizzes",
                key: "INTERNAL_PE_REINSTATEMENT_QUIZZES",
                exact: true,
                component: () => <PeReinstatementQuizzesPage />
            },
            {
                path: "/internal/pereinstatement/cpdrequirements",
                key: "INTERNAL_PE_REINSTATEMENT_CPDREQUIREMENTS",
                exact: true,
                component: () => <PeReinstatementCpdPage />
            },
            {
                path: "/internal/pereinstatement/personalreferences",
                key: "INTERNAL_PE_REINSTATEMENT_REFERENCES",
                exact: true,
                component: () => <PeReinstatementPersonalReferencePage />
            },
            {
                path: "/internal/pereinstatement/experience",
                key: "INTERNAL_PE_REINSTATEMENT_EXPERIENCE",
                exact: true,
                component: () => <PeReinstatementExperiencePage />
            },
            {
                path: "/internal/pereinstatement/disclosure",
                key: "INTERNAL_PE_REINSTATEMENTS_DISCLOSURE",
                exact: true,
                component: () => < PeReinstatementDisclosurePage />
            },
            {
                path: "/internal/pereinstatement/acknowledgement",
                key: "INTERNAL_PE_REINSTATEMENT_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <PeReinstatementAckPage />
            },
            {
                path: "/internal/pereinstatement/review",
                key: "INTERNAL_PE_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <PeReinstatementReviewPage />
            },
            {
                path: "/internal/pereinstatement/boardapproval",
                key: "INTERNAL_PE_REINSTATEMENT_BOARDAPPROVAL",
                exact: true,
                component: () => <PeReinstatementBoardApprovalPage />
            },
            //#endregion

            //#region EI
            //EI App
            {
                path: "/internal/eiapp/contactinformation",
                key: "INTERNAL_EI_APPLICATIONS_CONTACTINFORMATION",
                exact: true,
                component: () => <EiAppContactPage />
            },
            {
                path: "/internal/eiapp/acknowledgement",
                key: "INTERNAL_EI_APPLICATIONS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <EiAppAckPage />
            },
            {
                path: "/internal/eiapp/review",
                key: "INTERNAL_EI_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <EiAppReviewPage />
            },
            {
                path: "/internal/eiapp/violations",
                key: "INTERNAL_EI_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <EiAppViolations />
            },
            {
                path: "/internal/eiapp/examinationverification",
                key: "INTERNAL_EI_APPLICATIONS_EXAMINATION",
                exact: true,
                component: () => <EiAppExaminationPage />
            },
            {
                path: "/internal/eiapp/education",
                key: "INTERNAL_EI_APPLICATIONS_EDUCATION",
                exact: true,
                component: () => <EiAppEducationPage />
            },
            {
                path: "/internal/eiapp/recommendation",
                key: "INTERNAL_EI_APPLICATIONS_RECOMMENDATION",
                exact: true,
                component: () => <EiAppRecommendationPage />
            },
            {
                path: "/internal/eiapp/experience",
                key: "INTERNAL_EI_APPLICATIONS_EXPERIENCE",
                exact: true,
                component: () => <EiAppExperiencePage />
            },
            {
                path: "/internal/eiapp/sections",
                key: "INTERNAL_EI_APPLICATIONS_INDEX",
                exact: true,
                component: () => <EiAppSectionPage />
            },
            {
                path: "/internal/eiapp/boardapproval",
                key: "INTERNAL_EI_APPLICATIONS_BOARDAPPROVAL",
                exact: true,
                component: () => <EiAppBoardApprovalPage />
            },
            //EI Renewal
            {
                path: "/internal/eirenewal/sections",
                key: "INTERNAL_EI_RENEWALS_INDEX",
                exact: true,
                component: () => <EiRenewalsIndexPage />
            },
            {
                path: "/internal/eirenewal/contactinformation",
                key: "INTERNAL_EI_RENEWALS_CONTACTINFORMATION",
                exact: true,
                component: () => <EiRenewalContactPage />
            },
            {
                path: "/internal/eirenewal/violations",
                key: "INTERNAL_EI_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <EiRenewalsViolationsPage />
            },
            {
                path: "/internal/eirenewal/privacy",
                key: "INTERNAL_EI_RENEWALS_PRIVACY",
                exact: true,
                component: () => <EiRenewalsPrivacyPage />
            },
            {
                path: "/internal/eirenewal/acknowledgement",
                key: "INTERNAL_EI_RENEWALS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <EiRenewalAckPage />
            },
            {
                path: "/internal/eirenewal/review",
                key: "INTERNAL_EI_RENEWALS_REVIEW",
                exact: true,
                component: () => <EiRenewalReviewPage />
            },
            {
                path: "/internal/eirenewal/boardapproval",
                key: "INTERNAL_EI_RENEWALS_BOARDAPPROVAL",
                exact: true,
                component: () => <EiRenewalBoardApprovalPage />
            },
            //EI Reinstatement
            {
                path: "/internal/eiReinstatement/sections",
                key: "INTERNAL_EI_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <EiReinstatementIndexPage />
            },
            {
                path: "/internal/eiReinstatement/contactInformation",
                key: "INTERNAL_EI_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <EiReinstatementContactPage />
            },
            {
                path: "/internal/eiReinstatement/examination",
                key: "INTERNAL_EI_REINSTATEMENT_EXAMINATION",
                exact: true,
                component: () => <EiReinstatementExaminationPage />
            },
            {
                path: "/internal/eiReinstatement/education",
                key: "INTERNAL_EI_REINSTATEMENT_EDUCATION",
                exact: true,
                component: () => <EiReinstatementEducationPage />
            },
            {
                path: "/internal/eiReinstatement/violations",
                key: "INTERNAL_EI_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <EiReinstatementViolationsPage />
            },
            {
                path: "/internal/eiReinstatement/acknowledgement",
                key: "INTERNAL_EI_REINSTATEMENT_ACKNOWLEDGMENT",
                exact: true,
                component: () => <EiReinstatementAckPage />
            },
            {
                path: "/internal/eiReinstatement/review",
                key: "INTERNAL_EI_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <EiReinstatementReviewPage />
            },
            {
                path: "/internal/eireinstatement/boardapproval",
                key: "INTERNAL_EI_REINSTATEMENT_BOARDAPPROVAL",
                exact: true,
                component: () => <EiReinstatementBoardApprovalPage />
            },
            //#endregion

            //#region LSI
            //LSI App
            {
                path: "/internal/lsiapp/contactinformation",
                key: "INTERNAL_LSI_APPLICATIONS_CONTACTINFORMATION",
                exact: true,
                component: () => <LsiAppContactPage />
            },
            {
                path: "/internal/lsiapp/acknowledgement",
                key: "INTERNAL_LSI_APPLICATIONS_ACKNOWLEDGEMENTS",
                exact: true,
                component: () => <LsiAppAckPage />
            },
            {
                path: "/internal/lsiapp/review",
                key: "INTERNAL_LSI_APPLICATIONS_REVIEW",
                exact: true,
                component: () => <LsiAppReviewPage />
            },
            {
                path: "/internal/lsiapp/violations",
                key: "INTERNAL_LSI_APPLICATIONS_VIOLATIONS",
                exact: true,
                component: () => <LsiAppViolationsPage />
            },
            {
                path: "/internal/lsiapp/examinationVerification",
                key: "INTERNAL_LSI_APPLICATIONS_EXAMINATION",
                exact: true,
                component: () => <LsiAppExamVerificationPage />
            },
            {
                path: "/internal/lsiapp/education",
                key: "INTERNAL_LSI_APPLICATIONS_EDUCATION",
                exact: true,
                component: () => <LsiAppEducationPage />
            },
            {
                path: "/internal/lsiapp/recommendation",
                key: "INTERNAL_LSI_APPLICATIONS_RECOMMENDATION",
                exact: true,
                component: () => <LsiAppRecommendationPage />
            },
            {
                path: "/internal/lsiapp/sections",
                key: "INTERNAL_LSI_APPLICATIONS_INDEX",
                exact: true,
                component: () => <LsiAppSection />
            },
            {
                path: "/internal/lsiapp/boardapproval",
                key: "INTERNAL_LSI_APPLICATIONS_BOARDAPPROVAL",
                exact: true,
                component: () => <LsiAppBoardApprovalPage />
            },
            //LSI Renewal
            {
                path: "/internal/lsirenewal/sections",
                key: "INTERNAL_LSI_RENEWALS_INDEX",
                exact: true,
                component: () => <LsiRenewalsIndexPage />
            },
            {
                path: "/internal/lsirenewal/contactinformation",
                key: "INTERNAL_LSI_RENEWALS_CONTACTINFORMATION",
                exact: true,
                component: () => <LsiRenewalContactPage />
            },
            {
                path: "/internal/lsiRenewal/violations",
                key: "INTERNAL_LSI_RENEWALS_VIOLATIONS",
                exact: true,
                component: () => <LsiRenewalsViolationsPage />
            },
            {
                path: "/internal/lsirenewal/privacy",
                key: "INTERNAL_LSI_RENEWALS_PRIVACY",
                exact: true,
                component: () => <LsiRenewalsPrivacyPage />
            },
            {
                path: "/internal/lsiRenewal/acknowledgement",
                key: "INTERNAL_LSI_RENEWALS_ACKNOWLEDGMENTS",
                exact: true,
                component: () => <LsiRenewalAckPage />
            },
            {
                path: "/internal/lsirenewal/review",
                key: "INTERNAL_LSI_RENEWALS_REVIEW",
                exact: true,
                component: () => <LsiRenewalReviewPage />
            },
            {
                path: "/internal/lsirenewal/boardapproval",
                key: "INTERNAL_LSI_RENEWALS_BOARDAPPROVAL",
                exact: true,
                component: () => <LsiRenewalBoardApprovalPage />
            },
            //LSI Reinstatement
            {
                path: "/internal/lsiReinstatement/sections",
                key: "INTERNAL_LSI_REINSTATEMENT_INDEX",
                exact: true,
                component: () => <LsiReinstatementIndexPage />
            },
            {
                path: "/internal/lsiReinstatement/contactInformation",
                key: "INTERNAL_LSI_REINSTATEMENT_CONTACTINFORMATION",
                exact: true,
                component: () => <LsiReinstatementContactPage />
            },
            {
                path: "/internal/lsiReinstatement/examination",
                key: "INTERNAL_LSI_REINSTATEMENT_EXAMINATION",
                exact: true,
                component: () => <LsiReinstatementExaminationPage />
            },
            {
                path: "/internal/lsiReinstatement/education",
                key: "INTERNAL_LSI_REINSTATEMENT_EDUCATION",
                exact: true,
                component: () => <LsiReinstatementEducationPage />
            },
            {
                path: "/internal/lsiReinstatement/violations",
                key: "INTERNAL_LSI_REINSTATEMENT_VIOLATIONS",
                exact: true,
                component: () => <LsiReinstatementViolationsPage />
            },
            {
                path: "/internal/lsiReinstatement/acknowledgement",
                key: "INTERNAL_LSI_REINSTATEMENT_ACKNOWLEDGEMENT",
                exact: true,
                component: () => <LsiReinstatementAckPage />
            },
            {
                path: "/internal/lsiReinstatement/review",
                key: "INTERNAL_LSI_REINSTATEMENT_REVIEW",
                exact: true,
                component: () => <LsiReinstatementReviewPage />
            },
            {
                path: "/internal/lsiReinstatement/boardApproval",
                key: "INTERNAL_LSI_REINSTATEMENT_BOARD_APPROVAL",
                exact: true,
                component: () => <LsiReinstatementBoardApprovalPage />
            },
            //#endregion
            //#region Revenue
            {
                path: "/internal/revenue/paymentsearch",
                key: "INTERNAL_REVENUE_PAYMENT_SEARCH",
                exact: true,
                component: () => <PaymentSearchPage />
            },
            {
                path: "/internal/revenue/createinvoice",
                key: "INTERNAL_REVENUE_CREATE_INVOICE",
                exact: true,
                component: () => <CreateInvoicePage />
            },
            {
                path: "/internal/revenue/invoice",
                key: "INTERNAL_REVENUE_CREATE_INVOICE",
                exact: true,
                component: () => <InvoicePage />
            },
            {
                path: "/internal/revenue/invoicesearch",
                key: "INTERNAL_REVENUE_INVOICE_SEARCH",
                exact: true,
                component: () => <InvoiceSearchPage />
            },
            {
                path: "/internal/revenue/dailyrefundsearch",
                key: "INTERNAL_REVENUE_REFUND_SEARCH",
                exact: true,
                component: () => <DailyRefundSearchPage />
            },
            {
                path: "/internal/revenue/dailypaymentsbyaccount",
                key: "INTERNAL_REVENUE_PAYMENTS_BY_ACCOUNT",
                exact: true,
                component: () => <DailyPaymentsByAccountPage />
            },
            {
                path: "/internal/revenue/createbatch",
                key: "INTERNAL_REVENUE_PENDING_BATCH",
                exact: true,
                component: () => <CreateBatchPage />
            },
            {
                path: "/internal/revenue/batchsearch",
                key: "INTERNAL_REVENUE_BATCH_SEARCH",
                exact: true,
                component: () => <BatchSearchPage />
            },
            //#endregion
        ]
    }
]

export default INTERNAL_ROUTES;