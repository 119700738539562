import React, { useEffect, useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'
import { Form } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PeInformation'
import Information from '../../../../Shared/Card/Information';
import { useHistory } from 'react-router-dom';
import PersonalInformation from '../../../../Shared/Card/Contact/PersonalInformation';
import MailingAddress from '../../../../Shared/Card/Contact/MailingAddress';
import PublicAddress from '../../../../Shared/Card/Contact/PublicAddress';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import publicApplicationService from '../../../../../services/Public/Application/PE/peApplication.service';
import { useSelector } from 'react-redux';
import contactService from '../../../../../services/Public/Application/PE/contact.service';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import Swal from 'sweetalert2';
import Issues from '../../../../Shared/Card/Issues';
import dayjs from 'dayjs';
import peApplicationService from '../../../../../services/Internal/Application/PE/peApplication.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ContactInformationPage() {
    const sectionId = 1;
    const routes = useSelector(x => x.currentRoutes);
    const { push } = useHistory();
    const [hasLoaded, setHasLoaded] = useState(false);
    const appId = useSelector(x => x.currentApplicationId);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const [formModel, setFormModel] = useState({});
    const [tabPane, setTabPane] = useState("personalInformation")
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);

    const [isUserEditable, setIsUserEditable] = useState(true);

    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Contact Information"
        }
    );
    useEffect(() => {
        contactService.getContactInformation(appId).then((response) => {
            var data = response.data;

            setFormModel({
                ...data,
                dateOfBirth: data.dateOfBirth != null ? dayjs(data.dateOfBirth).format('YYYY-MM-DD') : null,
                altEmail: data.alternateEmailAddress?.emailAddress,
                primaryEmail: data.primaryEmailAddress?.emailAddress,
                cellPhoneNum: data.cellPhone?.phoneNumber,
                workPhoneNum: data.workPhone?.phoneNumber,
                homePhoneNum: data.homePhone?.phoneNumber,
                isActiveMilitary: data.isActiveMilitary == "True" ? true : data.isActiveMilitary == "False" ? false : null,
                isActiveMilitarySpouse: data.isActiveMilitarySpouse == "True" ? true : data.isActiveMilitarySpouse == "False" ? false : null,
            })
            publicApplicationService.peGetSection(appId, sectionId).then((response) => {
                setSectionData(response.data);
                setIsComplete(response.data.staffReviewComplete)
                applicationService.getIndividualApplication(appId).then((data) => {
                    setIsUserEditable(data.data.status.id == 2)
                    entityService.getEntity(response.data.entityId).then((entity) => {
                        setEntityInfo(
                            {
                                ...entityInfo,
                                lolaNum: entity.data.lolaNumber,
                                entityName: entity.data.formattedName,
                                entityId: entity.data.entityId,
                                licenseNumber: data.data?.credential?.formattedLicenseNumber,
                                applicationDate: data.data?.dateSubmitted
                            })
                        setHasLoaded(true);
                    });
                });
            })
        })
    }, [])

    const formSubmit = (validation, event) => {
        submitData(true);
    }

    const submitData = (isForSubmit) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            contactService.postContactInformation(appId, formModel.certificateName, formModel.isActiveMilitary, formModel.baseStationed, formModel.isActiveMilitarySpouse, formModel.spouseBaseStationed).then((response) => {
                peApplicationService.peMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                    if (isForSubmit && response.data) {
                        push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
                    }
                    else if (response.data) {
                        Swal.fire("Saved!");
                    }
                })
            })
        }
    }

    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("contact")} />
                <br />
                <Card className="mb-3">
                    <Card.Body>
                        {hasLoaded ?
                            <Tabs style={{ float: 'right' }} activeKey={tabPane} transition={false} onSelect={(selected) => { setTabPane(selected) }}>
                                <Tab eventKey="personalInformation" title="Personal Information">
                                    <br />
                                    <br />
                                    <Form id="personalInformationForm" fluid formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} >
                                        <PersonalInformation isReadOnly={true} isUserEditable={isUserEditable} isForApp={true} isPeByComity={appTypeId == 3} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="mailingAddress" title="Mailing Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.mailingAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, mailingAddress: { ...value } }) }}
                                        id="mailingAddressForm">
                                        <MailingAddress isReadOnly={true} boundValues={formModel.mailingAddress} contactInfoLink={"/individual/contactInformation"} />
                                    </Form>
                                </Tab>
                                <Tab eventKey="publicAddress" title="Public Address">
                                    <br />
                                    <br />
                                    <Form fluid
                                        formValue={formModel.publicAddress}
                                        onChange={(value) => { setFormModel({ ...formModel, publicAddress: { ...value } }) }}
                                        id="publicAddressForm">
                                        <PublicAddress isReadOnly={true} boundValues={formModel.publicAddress} contactInfoLink={"/individual/contactInformation"} />
                                    </Form>
                                </Tab>
                            </Tabs> : <LoadingSpinner />
                        }
                    </Card.Body>
                </Card>
                {hasLoaded &&
                    <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                }
                <FormActionButtons
                    isUserEditable={isUserEditable}
                    showSectionComplete={true}
                    isComplete={isComplete}
                    isCompleteCallback={setIsComplete}
                    submitFormName="personalInformationForm"
                    backToSectionLink="/internal/peapp/sections"
                />
            </Container>
        </div>
    );
}
export default ContactInformationPage