import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import Swal from 'sweetalert2';
import reinstatementService from '../../../../../../services/Public/Reinstatement/PE/peReinstatement.service';
import Information from '../../../../../Shared/Card/Information';
import Issues from '../../../../../Shared/Card/Issues';
import FormActionButtons from '../../../../../Shared/FormAction/FormActionButtons'
import LoadingSpinner from '../../../../../Shared/GenericComponents/LoadingSpinner';
import { GetReinstatementInformationContent } from '../../../../../Shared/InformationContent/PeInformation';
import Courses from './Courses'

function CpdRequirementsPage() {
    const { push } = useHistory();
    const [formModel, setFormModel] = useState({
        electronicSignature: "",
    });
    const reinstatementId = useSelector(x => x.currentApplicationId);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);
    const sectionId = 5;
    const routes = useSelector(x => x.currentRoutes);
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        electronicSignature: StringType().isRequired('This field is required.'),
    });

    useEffect(() => {
        reinstatementService.peGetSection(reinstatementId, sectionId).then((response) => {
            setFormModel(response.data);
            setHasLoaded(true);
        })
    }, []);

    const formSubmit = (validation, event) => {
        if (!formModel.isUserEditable) {
            push(routes[routes.map(function (x) { return x.sectionId }).indexOf(sectionId) + 1].route)
        }
        else {
            if (validation && hasFiles) {
                submitData(true);
            }
        }
    }
    const formSave = (event) => {
        submitData();
    }
    const submitData = (isForSubmit) => {
        reinstatementService.pePostSection(reinstatementId, sectionId, formModel.electronicSignature).then((response) => {
            if (isForSubmit && response.data.id) {
                push(routes[routes.map(function (x) { return x.sectionId }).indexOf(sectionId) + 1].route)
            }
            else if (response.data.id) {
                Swal.fire("Saved!");
            }
            else {
                Swal.fire("Error saving cpd")
            }
        })
    }

    return (
        <div>
            <Container fluid>
                <strong>Professional Engineer Reinstatement</strong>
                <h4 className="mb-3">
                    CPD Requirements
                </h4>
                <Information content={GetReinstatementInformationContent("cpdRequirements")} />
                <br />
                { hasLoaded ?
                    <Form readOnly={ !formModel.isUserEditable} model={model} formValue={formModel} onChange={formModel => setFormModel(formModel)} onSubmit={formSubmit} fluid>
                        <Courses
                            formModel={formModel}
                            setHasFiles={setHasFiles}
                            hasFiles={hasFiles} />
                        <br />
                        <Issues isUserEditable={true} type={2} sectionId={formModel.id} />
                        <FormActionButtons isReadOnly={!formModel.isUserEditable} backToSectionLink="/individual/peReinstatement/sections" handleSave={formSave} />
                    </Form> : <LoadingSpinner/>
                }
            </Container>
        </div>
    );
}

export default CpdRequirementsPage