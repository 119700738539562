import axios from "axios";
import authHeader from '../../authheader.js'

const INDIVIDUALURL = process.env.REACT_APP_APIURL + "/api/Individual";

const getExams = async (entityId) => {
    return axios.get(`${INDIVIDUALURL}/getexams`, { headers: authHeader(), params: { entityId } });
}
const saveExam = async (data) => {
    return axios.post(`${INDIVIDUALURL}/saveexam`, data, { headers: authHeader() });
}
const deleteExam = async (id) => {
    return axios.post(`${INDIVIDUALURL}/deleteexam`, null, { headers: authHeader(), params: { id } });

}
export default {
    getExams,
    saveExam,
    deleteExam
}