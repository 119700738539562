import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/PeInformation';
import peApplicationService from '../../../../../services/Internal/Application/PE/peApplication.service';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import ReviewPdf from '../../../../Shared/Card/ReviewPdf';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import { Form } from 'rsuite';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import applicationService from '../../../../../services/Internal/Application/application.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function ReviewPage() {
    const [hasLoaded, setHasLoaded] = useState(true)
    const { push } = useHistory();
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const appTypeId = useSelector(x => x.currentApplicationTypeId);
    const appType = appTypeId == 3 ? "By Comity" : appTypeId == 4 ? "By Exam" : "";
    const [violationIssuesHtml, setViolationIssuesHtml] = useState();
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Professional Engineer Application " + appType,
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Review"
        }
    );
    const sectionId = 9;
    useEffect(() => {
        peApplicationService.peGetSection(appId, sectionId).then((response) => {
            setIsComplete(response.data.staffReviewComplete);
            applicationService.getIndividualApplication(appId).then((appData) => {
                setIsUserEditable(appData.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: appData.data?.credential?.formattedLicenseNumber,
                            applicationDate: appData.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            });
        })
    }, [])


    const formSubmit = () => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else {
            peApplicationService.peMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                if (data.data)
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
            })
        }
    }



    return (
        <div>
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Row>
                    <Col>
                        <Information content={GetAppInformationContent("review")} className="mb-3" />
                    </Col>
                </Row>
                <br />

                <ReviewPdf id={appId} parentTypeId={1} />
                <br />
                <br />
                {hasLoaded ?
                    <>
                        {violationIssuesHtml}
                        <Container fluid>
                            <Form onSubmit={formSubmit}>
                                <FormActionButtons
                                    isUserEditable={isUserEditable}
                                    isComplete={isComplete}
                                    isCompleteCallback={setIsComplete}
                                    showSectionComplete={true}
                                    backToSectionLink="/internal/peapp/sections"
                                />
                            </Form>
                        </Container>
                    </>
                    : <LoadingSpinner />
                }
            </Container>
        </div>
    );
}
export default ReviewPage
