import axios from "axios";
import authHeader from '../../../authheader'

const PEAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualPeApplication";

const getCoopExperience = (applicationId) => {
    return axios.get(`${PEAPPLICATIONURL}/coopexperience`, { headers: authHeader(), params: { applicationId } });
}
const postCoopExperience = (data) => {
    return axios.post(`${PEAPPLICATIONURL}/coopexperience`, data, { headers: authHeader() });
}
export default {
    getCoopExperience,
    postCoopExperience
}