import axios from "axios";
import authHeader from "../authheader";

const REDIRECTURL = process.env.REACT_APP_APIURL + "/api/Redirection";

const getSectionData = (sectionParentTypeId, sectionId) => {
    return axios.get(`${REDIRECTURL}/getsectiondata`, { headers: authHeader(), params: { sectionParentTypeId, sectionId } });
}
const getSectionDataFromIssueId = (issueId) => {
    return axios.get(`${REDIRECTURL}/getsectiondatafromissueid`, { headers: authHeader(), params: { issueId } });
}
const getTaskData = (parentTypeId, parentId) => {
    return axios.get(`${REDIRECTURL}/gettaskdata`, { headers: authHeader(), params: { parentTypeId, parentId } });
}

export default {
    getSectionData,
    getSectionDataFromIssueId,
    getTaskData
}