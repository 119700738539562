import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormActionButtons from '../../../../Shared/FormAction/FormActionButtons';
import EducationRecord from './EducationRecords';
import { Form, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Information from '../../../../Shared/Card/Information';
import { GetAppInformationContent } from '../../../../Shared/InformationContent/EiInformation';
import AddEditEducationRecordsModal from '../../../../Shared/Modal/AddEditEducationRecordsModal';
import { useSelector } from 'react-redux';
import eiApplicationService from '../../../../../services/Internal/Application/EI/eiApplication.service';
import applicationService from '../../../../../services/Internal/Application/application.service';
import LoadingSpinner from '../../../../Shared/GenericComponents/LoadingSpinner';
import Issues from '../../../../Shared/Card/Issues';
import dayjs from 'dayjs';
import entityService from '../../../../../services/Internal/Staff/entity.service';
import FormEntityInformation from '../../../Shared/FormEntityInformation';

function EducationPage() {
    const [hideGrids, setHideGrids] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});
    const { push } = useHistory();
    const entityId = useSelector(x => x.currentApplicationEntityId)
    const appId = useSelector(x => x.currentApplicationId);
    const routes = useSelector(x => x.currentRoutes);
    const sectionId = 2;
    const [sectionData, setSectionData] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isUserEditable, setIsUserEditable] = useState(true);
    const [entityInfo, setEntityInfo] = useState(
        {
            lolaNum: null,
            entityName: '',
            licenseNumber: '',
            formType: "Engineer Intern Application",
            isIndividual: true,
            entityId: 0,
            applicationDate: "",
            currentSection: "Education"
        }
    );
    useEffect(() => {
        setHasLoaded(false)
        eiApplicationService.eiGetSection(appId, sectionId).then((response) => {
            setSectionData(response.data);
            setIsComplete(response.data.staffReviewComplete)
            setHideGrids(response.data.hasMyNceesEducation ?? true);
            applicationService.getIndividualApplication(appId).then((data) => {
                setIsUserEditable(data.data.status.id == 2)
                entityService.getEntity(response.data.entityId).then((entity) => {
                    setEntityInfo(
                        {
                            ...entityInfo,
                            lolaNum: entity.data.lolaNumber,
                            entityName: entity.data.formattedName,
                            entityId: entity.data.entityId,
                            licenseNumber: data.data?.credential?.formattedLicenseNumber,
                            applicationDate: data.data?.dateSubmitted
                        })
                    setHasLoaded(true);
                });
            })
        })
    }, [])
    const { BooleanType } = Schema.Types;
    const model = Schema.Model({
        hasMyNceesEducation: BooleanType().isRequired('This field is required.')
    });
    const handleMyNceesEducationChange = (val) => {
        setHideGrids(val);
    }
    const openModal = (values) => {
        if (values.id) {
            setModalData(
                {
                    ...values,
                    attendanceEndDate: values.attendanceEndDate ? dayjs(values.attendanceEndDate).format('YYYY-MM') : null,
                    attendanceStartDate: values.attendanceStartDate ? dayjs(values.attendanceStartDate).format('YYYY-MM') : null,
                    graduationDate: values.graduationDate ? dayjs(values.graduationDate).format('YYYY-MM-DD') : null,
                    modalType: 'Edit'
                });
        }
        else {
            setModalData({
                applicationId: appId,
                attendanceEndDate: null,
                attendanceStartDate: null,
                dateCrte: new Date(),
                degree: null,
                deleted: false,
                didGraduate: '',
                entityId: entityId,
                graduationDate: null,
                id: '',
                major: '',
                school: null,
                userCrte: '',
                modalType: "Add"
            })
        }
        setModalShow(true);
    }

    const [reloadGrid, setReloadGrid] = useState(1);

    const submitForm = (validation, event) => {
        if (!isUserEditable) {
            push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route)
        }
        else if (validation) {
            eiApplicationService.eiPostSectionHasMyNceesEducation(appId, sectionId, sectionData.hasMyNceesEducation).then(() => {
                eiApplicationService.eiMarkSectionComplete(appId, sectionId, isComplete).then((data) => {
                    push(routes[routes.findIndex(el => { if (el.sectionId == sectionId) { return true; } }) + 1].route);
                });
            })
        }
    }
    return (
        <div className="eiAppEducationPage">
            <Container fluid>
                <FormEntityInformation {...entityInfo} />
                <Information content={GetAppInformationContent("education")} />
                <br />
                {hasLoaded ? <>
                    {
                        modalShow &&
                        <AddEditEducationRecordsModal updateGrid={() => { var data = 1 + reloadGrid; setReloadGrid(data) }} modaldata={modalData} show={modalShow} onHide={() => setModalShow(false)} proptitle="Add" />
                    }
                    <Form
                        model={model}
                        fluid
                        formValue={sectionData}
                        onSubmit={submitForm}
                        onChange={data => setSectionData(data)}
                    >
                        <EducationRecord
                            isReadOnly={!isUserEditable}
                            reloadGrid={reloadGrid}
                            applicationId={appId}
                            openModal={openModal}
                            hideGrid={hideGrids}
                            handleMyNceesEducationChange={handleMyNceesEducationChange}
                        />
                        <br />
                        <Issues isUserEditable={isUserEditable} isInternal={true} type={1} sectionId={sectionData.id} setIsComplete={setIsComplete} />
                        <FormActionButtons
                            isUserEditable={isUserEditable}
                            showSectionComplete={true}
                            isComplete={isComplete}
                            isCompleteCallback={setIsComplete}
                            backToSectionLink="/internal/eiapp/sections" />
                    </Form></> : <LoadingSpinner />
                }
            </Container>
        </div>
    )
}
export default EducationPage;