import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import Swal from 'sweetalert2';
import ViolationIssueComment from '../Comments/ViolationIssueComment';
import IssueService from '../../../services/Shared/issues.service';
import ChangeLogService from '../../../services/Shared/changeLog.service';
import { Table } from 'rsuite';
import { sort_by } from '../HelperFunctions';
import { FaEdit} from 'react-icons/fa';

function Issues({ type, sectionId, isInternal, setIsComplete, changeLog, isUserEditable }) {
    // sectionId is the section.id
    //type: 1-> Application, 2-> Reinstatement, 3-> Renewal
    const [issues, updateIssues] = useState([]);
    const [changeLogData, updateChangeLogData] = useState([]);

    useEffect(() => {
        updateIssuesList()
    }, [sectionId])

    const updateIssuesList = () => {
        if (sectionId)
            IssueService.getIssues(type, sectionId).then((response) => {
                if (response.data) {
                    updateIssues(response.data);
                    getChangeLogData()
                }
            })
    }

    const getChangeLogData = () => {
        if (changeLog) {
            ChangeLogService.getChangeLogData(type, changeLog.CurrentId, changeLog.SectionId).then((response) => {

                updateChangeLogData(response.data.sort(sort_by("dateMod", true)))
            })
        }
    }

    const addIssue = () => {
        Swal.fire({
            title: 'Add issue for applicant here',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Add Issue',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                return !value && 'Please enter an issue.'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                IssueService.postIssue(result.value, type, sectionId).then((response) => {
                    if (response.data) {
                        updateIssuesList()
                        setIsComplete(false);
                        Swal.fire(
                            'Issue Successfully Submitted!',
                            `You submitted this issue:  ${result.value}!`,
                            'success'
                        )
                    }
                });
            }
        })
    }
    const updateIssue = (issueId, currentIssue) => {
        Swal.fire({
            title: 'Update issue for applicant here',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            inputValue: currentIssue,
            showCancelButton: true,
            confirmButtonText: 'Edit Issue',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                return !value && 'Please enter an issue.'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                IssueService.updateIssue(issueId, result.value, type, sectionId).then((response) => {
                    if (response.data) {
                        updateIssuesList()
                        setIsComplete(false);
                        Swal.fire(
                            'Issue Successfully Changed!',
                            `You changed this issue from ${currentIssue} to ${result.value}!`,
                            'success'
                        )
                    }
                });
            }
        })
    }
    const toggleStatus = (event) => {
        var id = event.value;
        IssueService.toggleIssue(type, id, event.checked).then((response) => {
            if (response.data) {
                updateIssuesList();
                setIsComplete(false);
            }
        });
    }
    const issuesMarkup = issues.map((item, i) => {
        return (<div key={i}>
            <Row className="mb-3">
                <Col>
                    <h5>{item.formattedName}</h5>
                </Col>
                <Col>
                    <div style={{ float: "right", display: "flex", alignItems: "center" }}>
                        {isInternal ?
                            <>
                                <label className="issueSwitch">
                                    <input id={"markedClosedCheckbox" + i} type="checkbox" value={item.id} defaultChecked={item.closed ? "checked" : ""} disabled={!isUserEditable ? "disabled" : ""} onClick={(event) => toggleStatus(event.currentTarget)} />
                                    <span className="issueSlider round"></span>
                                </label>&nbsp;
                                <label htmlFor={"markedClosedCheckbox" + i}>
                                    <b>Mark Issue as Closed</b>
                                </label>
                            </>
                            :
                            <>
                                {item.closed &&
                                    <label className="issueStatus">Status: Closed</label>
                                }
                            </>
                        }
                    </div>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <strong className="blueCommentDate">Issue created on {new Date(item.dateCrte).toLocaleString()} &nbsp;
                        {(!item.closed && isInternal) && <FaEdit onClick={() => updateIssue(item.id, item.text)} className={'issueEditIcon'} />}
                    </strong>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <strong>{item.text}</strong>
                </Col>
            </Row>
            <div className="issuesCommentSection"><ViolationIssueComment comments={item.issueResponses} issueClosed={item.closed} issueId={item.id} type={type} isUserEditable={isUserEditable} /></div>
            <hr />
        </div>)
    });


    return (
        <>
            {((!isInternal && issues.length) || isInternal) ?
                <>
                    {(changeLog != null) &&

                        <Card className="mb-3">
                            <Card.Header>
                                <h5>{changeLog.Header}</h5>
                            </Card.Header>
                            <Card.Body>
                                {changeLog.BodyText}
                                <br />

                                <Table
                                    wordWrap
                                    autoHeight
                                    data={changeLogData}>
                                    <Table.Column minWidth={100} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>Date | Time</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="dateMod">{rowData => (new Date(rowData.dateMod).toLocaleString())}</Table.Cell>
                                    </Table.Column>
                                    <Table.Column minWidth={100} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>Field</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="fieldName" />
                                    </Table.Column>
                                    <Table.Column minWidth={100} flexGrow={2}>
                                        <Table.HeaderCell>
                                            <b>Old Value</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="oldValue" />
                                    </Table.Column>
                                    <Table.Column minWidth={100} flexGrow={2}>
                                        <Table.HeaderCell>
                                            <b>New Value</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="newValue" />
                                    </Table.Column>
                                    <Table.Column minWidth={100} flexGrow={1}>
                                        <Table.HeaderCell>
                                            <b>User</b>
                                        </Table.HeaderCell>
                                        <Table.Cell dataKey="userName" />
                                    </Table.Column>
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                    < Card className="w-100" >
                        <Card.Header>
                            <Row>
                                <Col md={10}>
                                    <h5>Issue(s) - <i><b className="text-danger">For each issue below, you <u>MUST</u> add a comment and click “Submit” so staff knows that you responded. Do <u>NOT</u> respond to staff via email. Changes will <u><i>NOT</i></u> be saved until you click “Next Section”.</b></i>
                                    </h5>
                                </Col>
                                <Col>
                                    {(isInternal && isUserEditable) && <span><Button variant="primary" className="float-right" onClick={addIssue}>Add Issue</Button></span>}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {issues.length ?
                                <>
                                    {issuesMarkup}
                                </>
                                : <h5>No Issues</h5>
                            }
                        </Card.Body>
                    </Card >
                </>
                :
                ""
            }
        </>
    )
}


export default Issues;