import axios from "axios";
import authHeader from '../../../authheader'

const PERENEWALURL = process.env.REACT_APP_APIURL + "/api/IndividualPeRenewal";

const getContactInformation = (renewalId) => {
    return axios.get(`${PERENEWALURL}/contactinformation`, { headers: authHeader(), params: { renewalId } });
}
export default {
    getContactInformation
}