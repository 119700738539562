import * as dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { Form, FormGroup, ControlLabel, FormControl, InputPicker, SelectPicker, Toggle } from 'rsuite';
import Swal from 'sweetalert2';
import additionalInformationService from '../../../../../services/Internal/Credential/additionalInformation.service';
import { getStates, getSurveyingExamDiscipline } from '../../../../../services/Shared/codeTable.service';
import YesNoRadioOption from '../../../../Shared/RadioButton/YesNoRadioOption';
function AdditionalSurveyorInformationForm({ entityId, active }) {
    const [states, setStates] = useState([])
    const [examDiscipline, setExamDiscipline] = useState([])
    const [surveyModel, setSurveyModel] = useState({});
    const [isAct2, setIsAct2] = useState(false)
    const [isByEndorsement, setIsByEndorsement] = useState(false)

    useEffect(() => {
        getStates().then(setStates);
        getSurveyingExamDiscipline().then(setExamDiscipline);
    }, [])

    useEffect(() => {
        if (active) {
            additionalInformationService.getSurveyingAdditionalInfo(entityId).then((response) => {
                if (response.data) {
                    setSurveyModel({
                        ...response.data,
                        entityId: entityId,
                        lsiCertDate: response.data.lsiCertDate != null ? dayjs(response.data.lsiCertDate).format('YYYY-MM-DD') : null,
                        fsExamDate: response.data.fsExamDate != null ? dayjs(response.data.fsExamDate).format('YYYY-MM-DD') : null,
                        plsExamDate: response.data.plsExamDate != null ? dayjs(response.data.plsExamDate).format('YYYY-MM-DD') : null,
                        plsLicenseDate: response.data.plsLicenseDate != null ? dayjs(response.data.plsLicenseDate).format('YYYY-MM-DD') : null,
                        oralExamDate: response.data.oralExamDate != null ? dayjs(response.data.oralExamDate).format('YYYY-MM-DD') : null,
                        laLawsExamDate: response.data.laLawsExamDate != null ? dayjs(response.data.laLawsExamDate).format('YYYY-MM-DD') : null,
                    });
                    setIsAct2(response.data.isAct2);
                    setIsByEndorsement(response.data.isByEndorsement);
                }
            })
        }
    }, [active])

    const formSubmit = () => {
        var dataToTransmit = {
            ...surveyModel,
            lsiCertDate: surveyModel.lsiCertDate == '' ? null : surveyModel.lsiCertDate,
            fsExamDate: surveyModel.fsExamDate == '' ? null : surveyModel.fsExamDate,
            plsExamDate: surveyModel.plsExamDate == '' ? null : surveyModel.plsExamDate,
            plsLicenseDate: surveyModel.plsLicenseDate == '' ? null : surveyModel.plsLicenseDate,
            oralExamDate: surveyModel.oralExamDate == '' ? null : surveyModel.oralExamDate,
            laLawsExamDate: surveyModel.laLawsExamDate == '' ? null : surveyModel.laLawsExamDate,
            isAct2: isAct2,
            isByEndorsement: isByEndorsement
        }

        additionalInformationService.postSurveyingAdditionalInfo(dataToTransmit).then((response) => {
            if (response.data)
                Swal.fire('Success!', 'You have successfully saved additional information for this LSI/PLS.', 'success')
        })
    }
    const stateSearch = (userInput, label, dataItem) => {
        if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput)
            return true;
    }
    return (
        <Form fluid formValue={surveyModel} onChange={setSurveyModel} onSubmit={formSubmit}>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Comity State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="comityState" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>LSI Cert. Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="lsiCertDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>LSI Cert #</b></ControlLabel>
                        <FormControl name="lsiCertNumber" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>FS Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="fsExamDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>FS Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="fsExamState" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PLS Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="plsExamDate"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PLS Exam State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="plsExamState" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PLS Lic Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="plsLicenseDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PLS Lic State</b></ControlLabel>
                        <FormControl
                            accepter={InputPicker}
                            block
                            data={states}
                            searchBy={stateSearch}
                            name="plsLicenseState" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">

                <Col>
                    <FormGroup>
                        <ControlLabel><b>PLS Lic #</b></ControlLabel>
                        <FormControl name="plsLicenseNumber" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Surveying Exam Discipline</b></ControlLabel>
                        <FormControl
                            data={examDiscipline}
                            accepter={InputPicker}
                            searchable={true}
                            block
                            name="plsExamDiscipline" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Oral Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="oralExamDate" />
                    </FormGroup>
                </Col>

            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>LA Laws Exam Date</b></ControlLabel>
                        <FormControl
                            type='date'
                            name="laLawsExamDate"
                        />
                    </FormGroup>
                </Col>
                <Col>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Model Law</b></ControlLabel>
                        <YesNoRadioOption radioName="isModelLaw" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>Act 2</b></ControlLabel>
                        <FormControl accepter={Toggle} size={'lg'} checked={isAct2} onChange={(val) => { setIsAct2(val); if (val) setIsByEndorsement(false); }} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <ControlLabel><b>PE by Endorsement</b></ControlLabel>
                        <FormControl accepter={Toggle} size={'lg'} checked={isByEndorsement} onChange={(val) => { setIsByEndorsement(val); if (val) setIsAct2(false); }} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="text-right"><Button type="submit">Save</Button></Col>
            </Row>
        </Form>
    )
}
export default AdditionalSurveyorInformationForm;