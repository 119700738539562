import axios from "axios";
import authHeader from '../authheader'

const CHANGELOGURL = process.env.REACT_APP_APIURL + "/api/ChangeLog";

const getChangeLogData = (parentTypeId, parentId, sectionTypeId) => {
    return axios.get(`${CHANGELOGURL}/changelogentries`, { headers: authHeader(), params: { parentTypeId, parentId, sectionTypeId } });
}
const getContactChangeLogData = (entityId) => {
    return axios.get(`${CHANGELOGURL}/contactchangelog`, { headers: authHeader(), params: { entityId } });
}
const getCredentialChangeLogData = (entityId) => {
    return axios.get(`${CHANGELOGURL}/credentialchangelog`, { headers: authHeader(), params: { entityId } });
}

export default {
    getChangeLogData,
    getContactChangeLogData,
    getCredentialChangeLogData
}