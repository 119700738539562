import axios from "axios";
import authHeader from '../../../authheader'

const EIAPPLICATIONURL = process.env.REACT_APP_APIURL + "/api/IndividualEiApplication";

const getEducationRecords = (applicationId) => {
    return axios.get(`${EIAPPLICATIONURL}/educationrecords`, { headers: authHeader(), params:{ applicationId} });
}
//const getApprovedCourseRecords = (applicationId) => {
//    return axios.get(`${EIAPPLICATIONURL}/approvedcompletedcourses`, { headers: authHeader(), params:{ applicationId} });
//}
//const getNonApprovedCourseRecords = (applicationId) => {
//    return axios.get(`${EIAPPLICATIONURL}/notapprovedcompletedcourses`, { headers: authHeader(), params: { applicationId } });
//}
const postEducationRecord = (data) => {
    return axios.post(`${EIAPPLICATIONURL}/educationrecord`, data, { headers: authHeader() });
}
//const postApprovedCourseRecord = (data) => {
//    return axios.post(`${EIAPPLICATIONURL}/approvedcompletedcourse`, data, { headers: authHeader() });
//}
//const postNonApprovedCourseRecord = (data) => {
//    return axios.post(`${EIAPPLICATIONURL}/notapprovedcompletedcourse`, data, { headers: authHeader() });
//}
export default {
    getEducationRecords,
    //getNonApprovedCourseRecords,
    //getApprovedCourseRecords,
    postEducationRecord,
    //postApprovedCourseRecord,
    //postNonApprovedCourseRecord
}