import axios from "axios";
import authHeader from '../authheader'

const CPDURL = process.env.REACT_APP_APIURL + "/api/ProfessionalDevelopment";

const getCpd = (entityId) => {
    return axios.get(`${CPDURL}`, { headers: authHeader(), params: { entityId } });
}

const postCreateCpd = (data) => {
    var header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    return axios.post(`${CPDURL}/cpd`, data, { headers: { ...header }});
}

const postUpdateCpd = (data) => {
    var header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    return axios.post(`${CPDURL}/cpdupdate`, data, { headers: { ...header } });
}

export default {
    getCpd,
    postCreateCpd,
    postUpdateCpd
}