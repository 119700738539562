import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import { ControlLabel, Form, FormControl, FormGroup, InputPicker, Schema, SelectPicker } from 'rsuite';
import Swal from 'sweetalert2';
import credentialService from '../../../../services/Internal/Credential/credential.service';
import { emailPhonePrivacy, getCountries, getFirmTypes, getStates, journalDropdown, subscribeDropdown } from '../../../../services/Shared/codeTable.service';
import MailingAddress from '../../../Shared/Card/Contact/MailingAddress';
import Address from '../../../Shared/GenericComponents/Address';
import LoadingSpinner from '../../../Shared/GenericComponents/LoadingSpinner';
import PhoneNumberMask from '../../../Shared/GenericComponents/PhoneNumberMask';
import RedStar from '../../../Shared/GenericComponents/RedStar';


const InputMask = ({ onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            className="form-control"
            onChange={event => {
                onChange(event.target.value);
            }}
        />
    );
};

function EditFirmGeneralInformation(props) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [data, setData] = useState({});
    const [genInfoFormData, setGenInfoFormData] = useState({});
    const [genInfoFormDataCache, setGenInfoFormDataCache] = useState({});
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [firmTypes, setFirmTypes] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        credentialService.getFirmGeneralInfo(props.entityId).then((response) => {
            setData(response.data);
            getCountries().then(setCountries);
            getStates().then(setStates);
            getFirmTypes().then(setFirmTypes);
            setGenInfoFormData({
                firmName: response.data.name,
                lolaNumber: response.data.lolaNumber,
                mailingAddressStreet: response.data.mailingAddress?.streetAddress,
                mailingAddressCity: response.data.mailingAddress?.city,
                mailingAddressState: response.data.mailingAddress?.state,
                mailingAddressStateProvidence: response.data.mailingAddress?.provinceRegion,
                mailingAddressPostalCode: response.data.mailingAddress?.postalCode,
                mailingAddressCountry: response.data.mailingAddress?.country,
                publicAddressStreet: response.data.publicAddress?.streetAddress,
                publicAddressCity: response.data.publicAddress?.city,
                publicAddressState: response.data.publicAddress?.state,
                publicAddressStateProvidence: response.data.publicAddress?.provinceRegion,
                publicAddressPostalCode: response.data.publicAddress?.postalCode,
                publicAddressCountry: response.data.publicAddress?.country,
                userId: response.data.userName,
                federalId: response.data.federalId,
                companyEmail: response.data.primaryEmailAddress?.contactEmailAddress,
                businessPhone: response.data.phone?.contactPhoneNumber?.toString(),
                companyWebsite: response.data.url,
                contactName: response.data.contactName,
                firmType: response.data.organizationType ? { id: response.data.organizationType?.id, code: response.data.organizationType?.organizationType } : null,
                domicileFirmState: response.data.domicileState,
                dateFormed: response.data.dateOfFormation != null ? dayjs(response.data.dateOfFormation).format('YYYY-MM-DD') : null,
                amountDue: response.data.amountDue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                servicesDesc: response.data.additionalInformation,
                journalSubscription: response.data.journalSubscription,
                pressReleases: response.data.pressReleases,
                webinar: response.data.webinar,
                emailPhonePrivacy: response.data.emailPhonePrivacy,

            })
            setGenInfoFormDataCache({
                firmName: response.data.name,
                lolaNumber: response.data.lolaNumber,
                mailingAddressStreet: response.data.mailingAddress?.streetAddress,
                mailingAddressCity: response.data.mailingAddress?.city,
                mailingAddressState: response.data.mailingAddress?.state,
                mailingAddressStateProvidence: response.data.mailingAddress?.provinceRegion,
                mailingAddressPostalCode: response.data.mailingAddress?.postalCode,
                mailingAddressCountry: response.data.mailingAddress?.country,
                publicAddressStreet: response.data.publicAddress?.streetAddress,
                publicAddressCity: response.data.publicAddress?.city,
                publicAddressState: response.data.publicAddress?.state,
                publicAddressStateProvidence: response.data.publicAddress?.provinceRegion,
                publicAddressPostalCode: response.data.publicAddress?.postalCode,
                publicAddressCountry: response.data.publicAddress?.country,
                userId: response.data.userName,
                federalId: response.data.federalId,
                companyEmail: response.data.primaryEmailAddress?.contactEmailAddress,
                businessPhone: response.data.phone?.contactPhoneNumber?.toString(),
                companyWebsite: response.data.url,
                contactName: response.data.contactName,
                firmType: response.data.organizationType ? { id: response.data.organizationType?.id, code: response.data.organizationType?.organizationType } : null,
                domicileFirmState: response.data.domicileState,
                dateFormed: response.data.dateOfFormation != null ? dayjs(response.data.dateOfFormation).format('YYYY-MM-DD') : null,
                amountDue: response.data.amountDue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                servicesDesc: response.data.additionalInformation,
                journalSubscription: response.data.journalSubscription,
                pressReleases: response.data.pressReleases,
                webinar: response.data.webinar,
                emailPhonePrivacy: response.data.emailPhonePrivacy,

            })
            setHasLoaded(true);
        })
    }, [])

    const { StringType, ObjectType, NumberType } = Schema.Types;
    const validationModel = Schema.Model({
        firmName: StringType().isRequired('This field is required.'),
        //mailingAddressStreet: StringType().isRequired('This field is required.'),
        //mailingAddressCity: StringType().isRequired('This field is required.'),
        //mailingAddressState: ObjectType().isRequired('This field is required.'),
        //mailingAddressStateProvidence: StringType().isRequired('This field is required.'),
        //mailingAddressPostalCode: StringType().isRequired('This field is required.'),
        //mailingAddressCountry: ObjectType().isRequired('This field is required.'),
        //publicAddressStreet: StringType().isRequired('This field is required.'),
        //publicAddressCity: StringType().isRequired('This field is required.'),
        //publicAddressState: ObjectType().isRequired('This field is required.'),
        //publicAddressStateProvidence: StringType().isRequired('This field is required.'),
        //publicAddressPostalCode: StringType().isRequired('This field is required.'),
        //publicAddressCountry: ObjectType().isRequired('This field is required.'),
        businessPhone: NumberType().pattern(/^[0-9]{10,15}/, "Please enter a valid Phone Number"),
        userId: StringType().isRequired('This field is required.'),
        federalId: StringType().pattern(/^([0-9]{2}[-][0-9]{7})/, "Please enter a valid FEIN"),//.isRequired('This field is required.').pattern(/^([0-9]{2}[-][0-9]{7})/, "Please enter a valid FEIN"),
        //companyEmail: StringType().isRequired('This field is required.'),
        //contactName: StringType().isRequired('This field is required.'),
        //firmType: ObjectType().isRequired('This field is required.'),
        //domicileFirmState: ObjectType().isRequired('This field is required.'),
        //dateFormed: StringType().isRequired('This field is required.'),
        //servicesDesc: StringType().isRequired('This field is required.'),
        businessPhone: StringType().pattern(/^([(]\d{3}[)][ ]\d{3}[-]\d{4})|[0-9]{10}/, "Please enter a valid Phone Number")//.isRequired('This field is required.').pattern(/^([(]\d{3}[)][ ]\d{3}[-]\d{4})|[0-9]{10}/, "Please enter a valid Phone Number"),
    })

    const inputHasError = (cacheInputVal, formInputVal) => {
        if ((cacheInputVal && cacheInputVal.length !== 0) && (!formInputVal || formInputVal.length === 0))
            return true;
        return false;
    }

    const conditionalValidation = () => {
        var hasErrors = false;
        if (inputHasError(genInfoFormDataCache.mailingAddressStreet, genInfoFormData.mailingAddressStreet) ||
            inputHasError(genInfoFormDataCache.mailingAddressCity, genInfoFormData.mailingAddressCity) ||
            inputHasError(genInfoFormDataCache.mailingAddressState, genInfoFormData.mailingAddressState) ||
            inputHasError(genInfoFormDataCache.mailingAddressStateProvidence, genInfoFormData.mailingAddressStateProvidence) ||
            inputHasError(genInfoFormDataCache.mailingAddressPostalCode, genInfoFormData.mailingAddressPostalCode) ||
            inputHasError(genInfoFormDataCache.mailingAddressCountry, genInfoFormData.mailingAddressCountry) ||
            inputHasError(genInfoFormDataCache.publicAddressStreet, genInfoFormData.publicAddressStreet) ||
            inputHasError(genInfoFormDataCache.publicAddressCity, genInfoFormData.publicAddressCity) ||
            inputHasError(genInfoFormDataCache.publicAddressState, genInfoFormData.publicAddressState) ||
            inputHasError(genInfoFormDataCache.publicAddressStateProvidence, genInfoFormData.publicAddressStateProvidence) ||
            inputHasError(genInfoFormDataCache.publicAddressPostalCode, genInfoFormData.publicAddressPostalCode) ||
            inputHasError(genInfoFormDataCache.publicAddressCountry, genInfoFormData.publicAddressCountry) ||

            inputHasError(genInfoFormDataCache.companyEmail, genInfoFormData.companyEmail) ||
            inputHasError(genInfoFormDataCache.contactName, genInfoFormData.contactName) ||
            inputHasError(genInfoFormDataCache.firmType, genInfoFormData.firmType) ||
            inputHasError(genInfoFormDataCache.domicileFirmState, genInfoFormData.domicileFirmState) ||
            inputHasError(genInfoFormDataCache.dateFormed, genInfoFormData.dateFormed) ||
            inputHasError(genInfoFormDataCache.servicesDesc, genInfoFormData.servicesDesc))
            hasErrors = true;

        if ((genInfoFormData.mailingAddressCountry?.country === "United States" && inputHasError(genInfoFormDataCache.mailingAddressState, genInfoFormData.mailingAddressState)) ||
            (genInfoFormData.mailingAddressCountry?.country !== "United States") && inputHasError(genInfoFormDataCache.mailingAddressStateProvidence, genInfoFormData.mailingAddressStateProvidence) ||
            (genInfoFormData.publicAddressCountry?.country !== "United States") && inputHasError(genInfoFormDataCache.publicAddressState, genInfoFormData.publicAddressState) ||
            (genInfoFormData.publicAddressCountry?.country !== "United States") && inputHasError(genInfoFormDataCache.publicAddressStateProvidence, genInfoFormData.publicAddressStateProvidence))
            hasErrors = true;
        return hasErrors;
    }

    const submitModal = (validation, event) => {
        var validationObject = validationModel.check(genInfoFormData);
        for (const key in validationObject) {
            if (validationObject[key].hasError) {
                if (key == "publicAddressStateProvidence" || key == "publicAddressState") {
                    if (!(key == "publicAddressStateProvidence" && genInfoFormData.publicAddressCountry?.country === "United States") &&
                        !(key == "publicAddressState" && genInfoFormData.publicAddressCountry?.country !== "United States")) {
                        return;
                    }
                }
                else if (key == "mailingAddressStateProvidence" || key == "mailingAddressState") {
                    if (!(key == "mailingAddressStateProvidence" && genInfoFormData.mailingAddressCountry?.country === "United States") &&
                        !(key == "mailingAddressState" && genInfoFormData.mailingAddressCountry?.country !== "United States")) {
                        return;
                    }
                }
                else if (key == "userId" && !data.userName) {
                    continue;
                }
                else {
                    return;
                }
            }
        }
        if (conditionalValidation())
        {
            setShowErrors(true);
            return;
        }
        
        var dataToTransmit = {
            ...data,
            name: genInfoFormData.firmName,
            lolaNumber: genInfoFormData.lolaNumber,
            mailingAddress: {
                ...data.mailingAddress,
                state: genInfoFormData.mailingAddressState,
                country: genInfoFormData.mailingAddressCountry,
                city: genInfoFormData.mailingAddressCity,
                postalCode: genInfoFormData.mailingAddressPostalCode,
                streetAddress: genInfoFormData.mailingAddressStreet,
                provinceRegion: genInfoFormData.mailingAddressStateProvidence
            },
            publicAddress: {
                ...data.publicAddress,
                state: genInfoFormData.publicAddressState,
                country: genInfoFormData.publicAddressCountry,
                city: genInfoFormData.publicAddressCity,
                postalCode: genInfoFormData.publicAddressPostalCode,
                streetAddress: genInfoFormData.publicAddressStreet,
                provinceRegion: genInfoFormData.publicAddressStateProvidence
            },
            userName: genInfoFormData.userId,
            federalId: genInfoFormData.federalId,
            primaryEmailAddress: {
                ...data.primaryEmailAddress,
                contactEmailAddress: genInfoFormData.companyEmail
            },
            phone: {
                ...data.phone,
                contactPhoneNumber: genInfoFormData.businessPhone == '' ? null : genInfoFormData.businessPhone
            },
            url: genInfoFormData.companyWebsite,
            contactName: genInfoFormData.contactName,
            organizationType: {
                id: genInfoFormData.firmType?.id,
                organizationType: genInfoFormData.firmType?.code
            },
            domicileState: genInfoFormData.domicileFirmState,
            dateOfFormation: genInfoFormData.dateFormed != null ? new Date(genInfoFormData.dateFormed) : null,
            additionalInformation: genInfoFormData.servicesDesc,
            journalSubscription: genInfoFormData.journalSubscription,
            pressReleases: genInfoFormData.pressReleases,
            webinar: genInfoFormData.webinar,
            emailPhonePrivacy: genInfoFormData.emailPhonePrivacy,
        }

        credentialService.postFirmGeneralInfo(dataToTransmit).then((response) => {
            props.onHide();
            props.saveDataCallback();
        }).catch((reason) => {
            if (reason.response.status == 400)
                Swal.fire("Error!", reason.response.data, 'error');
            else
                Swal.fire("Error!", 'An error occured. Please try again.', 'error');
        })
    }
    return (
        <Modal
            {...props}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4>General Information</h4>
            </Modal.Header>
            <Modal.Body>
                {
                    hasLoaded ?
                        <>
                            <Form id='genInfoEditForm' fluid formValue={genInfoFormData} model={validationModel} onChange={setGenInfoFormData} onSubmit={submitModal}>
                                <Row>
                                    <Col sm={8}>
                                        <ControlLabel>
                                            <strong>
                                                Firm Name  <RedStar />
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name='firmName'
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>
                                            <strong>
                                                LOLA #
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            name='lolaNumber'
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h6>Mailing Address</h6>
                                        <hr />
                                        <ControlLabel>
                                            <strong>
                                                Country  
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            block
                                            name="mailingAddressCountry"
                                            accepter={InputPicker}
                                            data={countries}
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressCountry,genInfoFormData.mailingAddressCountry) && showErrors) ? "This field is required" : null}
                                            className='mb-1'
                                        />
                                        <ControlLabel>
                                            <strong>Address</strong> 
                                        </ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="mailingAddressStreet"
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressStreet, genInfoFormData.mailingAddressStreet) && showErrors) ? "This field is required" : null}
                                            className='mb-1'
                                        />
                                        <ControlLabel>
                                            <strong>City </strong> 
                                        </ControlLabel>
                                        <FormControl
                                            name="mailingAddressCity"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressCity, genInfoFormData.mailingAddressCity) && showErrors) ? "This field is required" : null}
                                        />
                                        {
                                            genInfoFormData.mailingAddressCountry?.country === "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    accepter={InputPicker}
                                                    placeholder="Please Select"
                                                    block
                                                    className='mb-1'
                                                    data={states}
                                                    name="mailingAddressState"
                                                    searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                                    errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressState,genInfoFormData.mailingAddressState) && showErrors) ? "This field is required" : null}
                                                />
                                            </>
                                        }
                                        {
                                            genInfoFormData.mailingAddressCountry?.country !== "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State/Province/Region </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    name="mailingAddressStateProvidence"
                                                    className='mb-1'
                                                    errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressStateProvidence,genInfoFormData.mailingAddressStateProvidence) && showErrors) ? "This field is required" : null}
                                                />
                                            </>
                                        }
                                        <ControlLabel>
                                            <strong>Zip/Postal Code </strong> 
                                        </ControlLabel>
                                        <FormControl
                                            name="mailingAddressPostalCode"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.mailingAddressPostalCode, genInfoFormData.mailingAddressPostalCode) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <h6>Public Address</h6>
                                        <hr />
                                        <ControlLabel>
                                            <strong>
                                                Country 
                                            </strong>
                                        </ControlLabel>
                                        <FormControl
                                            block
                                            name="publicAddressCountry"
                                            accepter={InputPicker}
                                            data={countries}
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressCountry,genInfoFormData.publicAddressCountry) && showErrors) ? "This field is required" : null}
                                        />
                                        <ControlLabel>
                                            <strong>Address</strong> 
                                        </ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="publicAddressStreet"
                                            className='mb-1'
                                            errorMessage={((inputHasError(genInfoFormDataCache.publicAddressStreet,genInfoFormData.publicAddressStreet)) && showErrors) ? "This field is required" : null}
                                        />
                                        <ControlLabel>
                                            <strong>City </strong> 
                                        </ControlLabel>
                                        <FormControl
                                            name="publicAddressCity"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressCity, genInfoFormData.publicAddressCity) && showErrors) ? "This field is required" : null}
                                        />
                                        {
                                            genInfoFormData.publicAddressCountry?.country === "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State </strong>
                                                </ControlLabel>
                                                <FormControl
                                                    accepter={InputPicker}
                                                    placeholder="Please Select"
                                                    block
                                                    className='mb-1'
                                                    data={states}
                                                    name="publicAddressState"
                                                    searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                                    errorMessage={(inputHasError(genInfoFormDataCache.publicAddressState, genInfoFormData.publicAddressState) && showErrors) ? "This field is required" : null}
                                                />
                                            </>
                                        }
                                        {
                                            genInfoFormData.publicAddressCountry?.country !== "United States" &&
                                            <>
                                                <ControlLabel>
                                                    <strong>State/Province/Region </strong> 
                                                </ControlLabel>
                                                <FormControl
                                                    name="publicAddressStateProvidence"
                                                    className='mb-1'
                                                    errorMessage={(inputHasError(genInfoFormDataCache.publicAddressStateProvidence, genInfoFormData.publicAddressStateProvidence) && showErrors) ? "This field is required" : null}
                                                />
                                            </>
                                        }
                                        <ControlLabel>
                                            <strong>Zip/Postal Code </strong> 
                                        </ControlLabel>
                                        <FormControl
                                            name="publicAddressPostalCode"
                                            className='mb-1'
                                            errorMessage={(inputHasError(genInfoFormDataCache.publicAddressPostalCode,genInfoFormData.publicAddressPostalCode) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    {
                                        !data.userName ?
                                            <Col>
                                                <ControlLabel><b>UserId</b></ControlLabel>
                                                <FormControl
                                                    disabled
                                                />
                                            </Col> :
                                            <Col>
                                                <ControlLabel><b>UserId</b><RedStar /></ControlLabel>
                                                <FormControl
                                                    name="userId"
                                                />
                                            </Col>
                                    }
                                    <Col>
                                        <ControlLabel><b>FEIN</b></ControlLabel>
                                        <FormControl
                                            accepter={InputMask}
                                            name="federalId"
                                            format="##-#######"
                                            mask="_"
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Company Email</b></ControlLabel>
                                        <FormControl
                                            name="companyEmail"
                                            errorMessage={(inputHasError(genInfoFormDataCache.companyEmail, genInfoFormData.companyEmail) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Business Phone </b></ControlLabel>
                                        <FormControl
                                            accepter={PhoneNumberMask}
                                            name="businessPhone"
                                            format="(###) ###-####x#####"
                                            mask="_"
                                            checkTrigger='blur'
                                            errorMessage={(inputHasError(genInfoFormDataCache.businessPhone, genInfoFormData.businessPhone) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Company Website</b></ControlLabel>
                                        <FormControl
                                            name="companyWebsite"

                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Contact Person</b></ControlLabel>
                                        <FormControl
                                            name="contactName"
                                            errorMessage={(inputHasError(genInfoFormDataCache.contactName, genInfoFormData.contactName) && showErrors) ? "This field is required" : null}
                                        />
                                    </
                                    Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Firm Type</b></ControlLabel>
                                        <FormControl
                                            block
                                            name="firmType"
                                            placeholder="Select a Firm Type"
                                            accepter={InputPicker}
                                            data={firmTypes}
                                            errorMessage={(inputHasError(genInfoFormDataCache.firmType, genInfoFormData.firmType) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Domicile Firm State</b></ControlLabel>
                                        <FormControl
                                            accepter={InputPicker}
                                            placeholder="Please Select"
                                            block
                                            data={states}
                                            name="domicileFirmState"
                                            searchBy={(userInput, label, dataItem) => { if (label.toLowerCase().startsWith(userInput.toLowerCase()) || !userInput) return true; }}
                                            errorMessage={(inputHasError(genInfoFormDataCache.domicileFirmState, genInfoFormData.domicileFirmState) && showErrors) ? "This field is required" : null}
                                        />

                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Date Formed/Incorporated</b></ControlLabel>
                                        <FormControl
                                            name="dateFormed"
                                            type='date'
                                            errorMessage={(inputHasError(genInfoFormDataCache.dateFormed, genInfoFormData.dateFormed) && showErrors) ? "This field is required" : null}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <ControlLabel><b>Amount Due</b></ControlLabel>
                                        <FormControl
                                            name="amountDue"
                                            disabled
                                        /></Col>
                                    <Col>
                                        <ControlLabel><b>Email/Phone Privacy</b></ControlLabel>
                                        <FormControl
                                            name="emailPhonePrivacy"
                                            data={emailPhonePrivacy}
                                            accepter={InputPicker}
                                            block
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ControlLabel><b>Brief description of Services to be provided by the firm in Louisiana </b></ControlLabel>
                                        <FormControl
                                            rows={3}
                                            componentClass="textarea"
                                            name="servicesDesc"
                                            maxLength={1000}
                                            errorMessage={(inputHasError(genInfoFormDataCache.servicesDesc, genInfoFormData.servicesDesc) && showErrors) ? "This field is required" : null}
                                        /></Col>

                                </Row>
                                <hr />

                                <Row className='mb-3'>
                                    <Col>
                                        <ControlLabel><b>Journal</b></ControlLabel>

                                        <FormControl
                                            name="journalSubscription"
                                            data={journalDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Press Releases</b></ControlLabel>
                                        <FormControl
                                            name="pressReleases"
                                            data={subscribeDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                        />
                                    </Col>
                                    <Col>
                                        <ControlLabel><b>Webinar</b></ControlLabel>
                                        <FormControl
                                            name="webinar"
                                            data={subscribeDropdown}
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </> : <LoadingSpinner />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.onHide(); }}>Close</Button>
                <Button type="submit" form="genInfoEditForm" >Save</Button>
            </Modal.Footer>
        </Modal >
    )
}
export default EditFirmGeneralInformation;