export function flattenObject(oldObject) {
    const newObject = {};

    flattenHelper(oldObject, newObject, '');

    return newObject;
}

function flattenHelper(currentObject, newObject, previousKeyName) {
    for (let key in currentObject) {
        let value = currentObject[key];

        if (!value || value.constructor !== Object) {
            if (previousKeyName == null || previousKeyName == '') {
                newObject[key] = value;
            } else {
                if (key == null || key == '') {
                    newObject[previousKeyName] = value;
                } else {
                    newObject[previousKeyName + key.charAt(0).toUpperCase() + key.substring(1)] = value;
                }
            }
        } else {
            if (previousKeyName == null || previousKeyName == '') {
                flattenHelper(value, newObject, key);
            } else {
                flattenHelper(value, newObject, previousKeyName + key);
            }
        }
    }
}
export function sort_by(field, reverse, primer) {
    const key = primer ?
        function (x) {
            return primer(x[field])
        } :
        function (x) {
            return x[field] ?? '';
        };
    reverse = !reverse ? 1 : -1;
    return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}
